import * as React from 'react'
import styled, { css } from 'styled-components'
import Spinner from './Spinner'

type FTProps = {
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
  onClick?: (...args: Array<any>) => any
  primary?: boolean
  styleAsDisabled?: boolean
  color?: string;
}
const getBorderColor = ({ primary, color }: FTProps) => {
  if (color) return color;
  return primary ? '#0194d7' : '#dadada';
};

const getBackgroundColor = ({ primary, color }: FTProps) => {
  if (color) return color;
  return primary ? '#0194d7' : '#f5f5f5';
};

const getTextColor = ({ primary, color }: FTProps) => {
  if (color) return '#ffffff';
  return primary ? '#ffffff' : '#7f7f7f';
};

const disabledStyles = css`
  cursor: default;
  background-color: ${({ primary }) => primary && '#a2deff'};
  border-color: ${({ primary }) => primary && '#a2deff'};
`
export const ButtonStyles = styled.button`
  border: 1px solid ${getBorderColor};
  background-color: ${getBackgroundColor};
  color: ${getTextColor};

  line-height: 36px;
  border-radius: 6px;
  text-transform: uppercase;
  padding: 0 44px;
  font-weight: 600;
  letter-spacing: 0.85px;
  text-align: center;

  font-size: 14px;
  min-width: 145px;
  cursor: pointer;
  user-select: none;

  &:disabled {
    ${disabledStyles};
  }

  ${({ styleAsDisabled }) => styleAsDisabled && disabledStyles}

  & .Spinner {
    width: 15px;
    height: 15px;
    border-width: 4px;
    top: 6px;
    right: 9px;
  }
`
const Button = React.forwardRef<FTProps, any>((props: FTProps, ref) => {
  const { styleAsDisabled, loading, ...buttonProps } = props
  return (
    <ButtonStyles
      {...buttonProps}
      disabled={loading || props.disabled}
      styleAsDisabled={styleAsDisabled}
      ref={ref}
    >
      {props.children}
      {loading && <Spinner size='tiny' />}
    </ButtonStyles>
  )
})
// @ts-expect-error
Button.defaultProps = {
  disabled: false,
  loading: false,
  onClick: undefined,
  primary: false,
  styleAsDisabled: false,
  color: undefined,
}
export default Button
