// flow
import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { combineReducers } from 'redux'
import { schema } from 'normalizr'
import { getEnergyUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { handleSagaError } from '../../sagas/utils'
import { isVariantActive } from '../../utils'
// Flow types
export type FTFetchEmissionRateAction = {
  countryCode?: string
  postalCode?: string
  year?: number
  latitude?: number
  longitude?: number
  state?: string
}
type FTEmissionRateResponse = {
  postalCode: string
  state: string
  country: string
  egridRate: number
  year: number
}
const emissionRateMockData = {
  postalCode: 'NA',
  state: 'NA',
  country: 'NA',
  egridRate: 0,
  year: 2023,
}
// Action Types
export const types = {
  FETCH_EMISSION_RATE: 'FETCH_EMISSION_RATE',
  FETCH_EMISSION_RATE_SUCCESS: 'FETCH_EMISSION_RATE_SUCCESS',
  FETCH_EMISSION_RATE_ERROR: 'FETCH_EMISSION_RATE_ERROR',
}
// Actions
export const actions = {
  fetchEmissionRate: (props: FTFetchEmissionRateAction) => ({
    type: types.FETCH_EMISSION_RATE,
    ...props,
  }),
}
export type FTEmissionRateMetaState = {
  error: string
  loading: boolean
}
export type FTEmissionRateEntityState = {
  emissionRateData: FTEmissionRateResponse
  meta: FTEmissionRateMetaState
}
type FTState = {
  entities: {
    emission: FTEmissionRateEntityState
  }
}
// State
export const initialState = {
  meta: {
    error: '',
    loading: false,
  },
  emissionRateData: {
    postalCode: '',
    state: '',
    country: '',
    egridRate: null,
    year: -1,
  },
}
export const entitySchema = new schema.Entity('emissionRateMockData')

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_EMISSION_RATE:
      return {
        error: '',
        loading: true,
      }

    case types.FETCH_EMISSION_RATE_SUCCESS:
      return {
        error: '',
        loading: false,
      }

    default:
      return state
  }
}

function emissionRateData(state = initialState.emissionRateData, action) {
  switch (action.type) {
    case types.FETCH_EMISSION_RATE:
      return initialState.emissionRateData

    case types.FETCH_EMISSION_RATE_SUCCESS:
      return action.payload

    default:
      return state
  }
}

export default combineReducers({
  emissionRateData,
  meta,
}) // API

const API = {
  fetchEmissionRate: (params: FTFetchEmissionRateAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(emissionRateMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const currentYear = new Date().getFullYear()
    const queryParams = {
      year: currentYear,
      ...params,
    }
    const url = `${getEnergyUrl()}/api/emission-rates`
    return axios
      .post(url, queryParams, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTEmissionRateResponse }) => data)
      .catch(handleAxiosError)
  },
}

// Sagas
function* fetchEmissionRateSaga({
  type,
  ...params
}: FTFetchEmissionRateAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTEmissionRateResponse = yield call(
      API.fetchEmissionRate,
      params,
    )
    yield put({
      type: types.FETCH_EMISSION_RATE_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_EMISSION_RATE_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_EMISSION_RATE, fetchEmissionRateSaga),
]
// Selectors
export const selectEmissionRateEntity = (
  state: FTState,
): FTEmissionRateEntityState => state.entities.emissionRate
