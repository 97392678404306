/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { colors } from '../../../utils/themes'
import { zIndices } from '../../../utils'
import InvoiceComponent from './InvoiceComponent'
import { Col1Styles } from '../LightingAsBuiltsManagerPage'
import CloseOutline from '../../../components/Icons/svg/CloseOutline'
import UtilityComponent from './UtilityComponent'
import UtilityAccountsComponent from './UtilityAccountsComponent'

const ModalOuterStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }

  ${Col1Styles} {
    margin-right: 0px;
  }
`
export const ModalInnerStyled = styled.div`
  background-color: white;
  border-radius: 4px;
  position: relative;
`
export const CloseDivStyled = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
export const UploadSuccessStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SuccessArrowIconStyles = styled.span`
  div {
    color: #7bcc76;
  }
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`
export const ButtonsStyles = styled.div`
  display: flex;
  min-width: 300px;
  justify-content: center;

  button {
    padding: 0 10px;
    margin: 0 5px;
  }
`

const UtilityDataModal = ({ location: { pathname }, onClose }) => {
  const modalName = pathname.split('/').reverse()[0]

  const getComponent = () => {
    if (modalName === 'invoice') return <InvoiceComponent onClose={onClose} />
    if (modalName === 'basic') return <UtilityComponent onClose={onClose} />
    if (modalName === 'profile')
      return <UtilityAccountsComponent onClose={onClose} />
    return null
  }

  return (
    <ModalOuterStyled>
      <ModalInnerStyled>
        <CloseDivStyled onClick={onClose}>
          <CloseOutline />
        </CloseDivStyled>
        {getComponent()}
      </ModalInnerStyled>
    </ModalOuterStyled>
  )
}

export default withRouter(UtilityDataModal)
