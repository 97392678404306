import { PureComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Title from '../components/Title'
import Description from '../components/Description'

type FTProps = {
  error: string | null | undefined
}
const Styles = styled.div`
  a[href] {
    color: #337ab7;
  }
`

class NotAuthorizedPage extends PureComponent<FTProps> {
  render() {
    const { error } = this.props

    if (error) {
      return (
        <Styles>
          <Title>Something went wrong</Title>
          <Description>
            Something went wrong in verifying your access to Grand Central.
          </Description>
          <Description>
            Please{' '}
            <Link onClick={() => window.reload()} to='/'>
              click here
            </Link>{' '}
            to try again.
          </Description>
        </Styles>
      )
    }

    return (
      <Styles>
        <Title>You are not authorized to view this page</Title>
        <Description>
          Please contact a Redaptive administrator to request access.
        </Description>
      </Styles>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.auth.error,
})

export default connect(mapStateToProps)(NotAuthorizedPage)
