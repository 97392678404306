import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import type { FTHistory } from '../types'
import '../types'
import './SimpleBackButton.css'

type FTProps = {
  history: FTHistory
  onClick: ((...args: Array<any>) => any) | null | undefined
} // eslint-disable-next-line

class SimpleBackButton extends Component<FTProps> {
  render() {
    const { history, onClick } = this.props

    const defaultHandler = () => history.goBack()

    const handler = onClick || defaultHandler
    return (
      <div className='SimpleBackButton' onClick={handler}>
        <span className='icon mi mi-keyboard-arrow-left' />
        Back
      </div>
    )
  }
}

export default withRouter(SimpleBackButton)
