import { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import {
  actions as billingItemsActions,
  selectBillingThisMonthItemsEntity,
} from '../../ducks/billing/billingThisMonth/billingItems'
import type { FTBillingSendToReviewBulkModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import { colors } from '../../utils/themes'
import Button2 from '../Button2'
import KeyboardDoubleArrowDownIcon from '../Icons/KeyboardDoubleArrowDownIcon'
import KeyboardDoubleArrowUpIcon from '../Icons/KeyboardDoubleArrowUpIcon'
import {
  ModalConfirmBodyStyled,
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'
import RedaptiveReactTable7, {
  ReactTableWrapperStyles,
  RedaptiveReactTableStyles,
} from '../RedaptiveReactTable7'

type FTProps = {
  actions: Record<string, any>
  loading: boolean
} & FTBillingSendToReviewBulkModal
const tableMarginBottom = 130
const itemsToggleHeight = 43
const ModalTitleWrapperStyled = styled.div`
  padding: 20px 20px 0px 20px;

  ${ModalConfirmTitleStyled} {
    padding: 0;

    & > span {
      color: ${colors.blue};
    }
  }
`
const SubTitleStyled = styled.h5`
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0;
  text-align: left;
`
const TableWrapperStyled = styled.div`
  font-size: 12px;

  ${RedaptiveReactTableStyles} {
    margin: 0 20px;
  }
  ${ReactTableWrapperStyles} {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    max-height: 195px;
  }
`
const NotBilledWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ItemsToggleStyled = styled.div<{
  itemsVisible: boolean
}>`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    #ffffff 100%
  );
  bottom: ${({ itemsVisible }) =>
    itemsVisible ?
      `${tableMarginBottom - itemsToggleHeight}px`
    : `${tableMarginBottom}px`};
  color: ${colors.blue2};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: ${itemsToggleHeight}px;
  justify-content: center;
  letter-spacing: 0.02em;
  width: 100%;
`
const ItemsToggleIconStyled = styled.div`
  margin-right: 6px;
  position: relative;
  top: 2px;
`
const columns = [
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
  },
  {
    accessor: 'name',
    Header: 'Opportunity Name',
  },
]

const billingSendToReviewBulkModal = ({
  actions,
  loading,
  selectedAction,
  isSelectedActionValid,
  allowOpportunities = [],
  notAllowOpportunities = [],
  setSendToReviewBulkSubmitted,
}: FTProps) => {
  const notAllowOpportunitiesTotal = notAllowOpportunities.length
  const allowOpportunitiesTotal = allowOpportunities.length

  const getOpportunityText = (total) =>
    total === 1 ? 'Opportunity' : 'Opportunities'

  const [isToggleItemsActive, setIsToggleItemsActive] = useState(false)
  const tableData = useMemo(
    () =>
      isToggleItemsActive ?
        notAllowOpportunities
      : notAllowOpportunities.slice(0, 3),
    [isToggleItemsActive],
  )

  const handleClick = () => {
    actions.hideModal()

    if (allowOpportunitiesTotal) {
      setSendToReviewBulkSubmitted(true)
      actions.sendToReviewBulk({
        billingItems: allowOpportunities,
      })
    }
  }

  const NotBilledReasons = () => (
    <NotBilledWrapperStyled>
      <ol type='i'>
        <li>{`Billed on <${selectedAction}> without reason code.`}</li>
        <li>{`Billed on <${selectedAction}> with 0 <${selectedAction}> savings kWh.`}</li>
      </ol>
    </NotBilledWrapperStyled>
  )

  const handleItemsToggleClick = useCallback(() => {
    setIsToggleItemsActive(!isToggleItemsActive)
  }, [isToggleItemsActive])
  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled>
        <ModalTitleWrapperStyled>
          {!!allowOpportunitiesTotal && (
            <ModalConfirmTitleStyled>
              <span>
                {`${allowOpportunitiesTotal} ${getOpportunityText(
                  allowOpportunitiesTotal,
                )}`}
              </span>
              {` will be Billed on <${selectedAction}>, and`}
            </ModalConfirmTitleStyled>
          )}
          <ModalConfirmTitleStyled>
            <span>
              {`${notAllowOpportunitiesTotal} ${getOpportunityText(
                notAllowOpportunitiesTotal,
              )}`}
            </span>
            {` could not be Billed on <${selectedAction}> `}
            {isSelectedActionValid ?
              'without a Reason Code.'
            : `due to one or more of the below reasons. 
            Please review and update for each opportunity individually.`
            }
          </ModalConfirmTitleStyled>
        </ModalTitleWrapperStyled>
        <ModalConfirmBodyStyled>
          <SubTitleStyled>
            {isSelectedActionValid ?
              'Please provide your comments for each Opportunity individually'
            : <NotBilledReasons />}
          </SubTitleStyled>
          {!!notAllowOpportunitiesTotal && (
            <TableWrapperStyled>
              <RedaptiveReactTable7
                columns={columns}
                data={tableData}
                globalFilterable={false}
              />
              {notAllowOpportunities.length > 3 && (
                <ItemsToggleStyled
                  itemsVisible={isToggleItemsActive}
                  onClick={handleItemsToggleClick}
                >
                  {isToggleItemsActive ?
                    <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowUpIcon />
                      </ItemsToggleIconStyled>
                      Hide
                    </>
                  : <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowDownIcon />
                      </ItemsToggleIconStyled>
                      Show All
                    </>
                  }
                </ItemsToggleStyled>
              )}
            </TableWrapperStyled>
          )}
        </ModalConfirmBodyStyled>
        <ModalConfirmButtonsStyled>
          <Button2 onClick={handleClick} loading={loading} type='primary'>
            Close
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const {
    sendToReviewBulkMeta: { loading },
  } = selectBillingThisMonthItemsEntity(state)
  return {
    loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(billingSendToReviewBulkModal)
