import { combineReducers } from 'redux'
import utilityProfileData from './uploadUtilityProfileData'
import utilityUsageData from './uploadUtilityUsageData'
import fetchUtilityData from './fetchUtilityData'

const uploadUtilityData = combineReducers({
  utilityProfileData,
  utilityUsageData,
  fetchUtilityData,
})
export default uploadUtilityData
