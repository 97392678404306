import { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import EntityList from './EntityList'
import type { FTRouterMatch } from '../types'
import '../types'
import type { FTEntity, FTFetchEntityList } from '../ducks/types'
import '../ducks/types'
import { naturallySort } from '../utils'

type FTProps = {
  fetchAllSites: FTFetchEntityList
  match: FTRouterMatch
  siteListEntity: FTEntity
}

class AllSitesTable extends Component<FTProps> {
  fetchDefaults = {
    orderBy: {
      field: 'name',
      sort: 'ASC',
    },
  }

  headers = [
    {
      fieldName: 'name',
      displayName: 'Site Name',
    },
    {
      fieldName: 'opportunityId',
      displayName: 'Opportunity ID',
      sortable: false,
    },
    {
      fieldName: 'customerName',
      displayName: 'Customer',
    },
    {
      fieldName: 'city',
      displayName: 'Location',
    },
    {
      fieldName: 'active',
      displayName: 'Visible In Dashboard',
    },
  ]

  componentDidMount() {
    const { fetchAllSites } = this.props

    fetchAllSites(this.fetchDefaults)
  }

  renderTableRow = ({
    id,
    contracts = [],
    validName,
    customerName,
    city,
    state,
    active,
  }) => {
    const {
      match: { url },
    } = this.props
    const opportunityIds = contracts
      .map(({ opportunityId }) => opportunityId)
      .sort(naturallySort)
      .join(', ')
    return (
      <tr key={id}>
        <td>
          <Link to={`${url}/${id}`} href={`${url}/${id}`}>
            {validName}
          </Link>
        </td>
        <td>{opportunityIds || '--'}</td>
        <td>{customerName || 'Unavailable'}</td>
        <td>{city && state ? `${city}, ${state}` : 'Unavailable'}</td>
        <td>{active ? 'Yes' : 'No'}</td>
      </tr>
    )
  }

  render() {
    const { fetchAllSites, siteListEntity } = this.props
    const description =
      'The following sites are available to the Energy Dashboard. ' +
      'Sites should be reviewed for completeness before making them visible in the dashboard.'
    return (
      <EntityList
        striped
        description={description}
        entity={siteListEntity}
        loadEntity={fetchAllSites}
        loadEntityProps={this.fetchDefaults}
        tableHeaders={this.headers}
        renderTableRow={this.renderTableRow}
      />
    )
  }
}

export default withRouter(AllSitesTable)
