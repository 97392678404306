import { ComponentType, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { zIndices } from '../utils'
import IonIcon from './IonIcon'

const DropdownWrapper = styled.div`
  min-width: 226px;
`
const DropdownMenuWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff5c;
  z-index: -1;
`
const DropdownStyled = styled.div`
  user-select: none;
  height: 36px;
  line-height: 36px;
  padding-left: 12px;
  padding-right: 32px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  width: max-content;
  background-color: #fff;

  & .IonIcon {
    width: 25px;
    height: 18px;
    line-height: unset;

    & > span {
      left: 8px;
      top: -5px;
    }

    font-size: 18px;
  }
`
const DropdownIndicatorStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const DropdownIndicatorLabel = styled.div`
  font-weight: 600;
  margin-right: 6px;
`
const DropdownMenuStyled: ComponentType<{
  open: boolean
}> = styled.div`
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  border-radius: 4px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  padding: 16px;
  position: absolute;
  margin-left: 60px;
  margin-top: 5px;
  top: 40px;
  z-index: ${zIndices.RedaptiveReactTableActionsPopup};
  min-width: 226px;
`
type FTProps = {
  label: string
  Options: () => ReactNode
  itemsSelected: number
}

const FilterDropdown = (props: FTProps) => {
  const { itemsSelected, label, Options } = props
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  return (
    <DropdownWrapper>
      <DropdownIndicatorStyled>
        <DropdownIndicatorLabel>Filter by:</DropdownIndicatorLabel>
        <DropdownStyled
          onClick={() => setIsDropdownOpen((prevState) => !prevState)}
        >
          {itemsSelected ? `- ${label} (${itemsSelected}) -` : `-- ${label} --`}
          <IonIcon iconClass='ion-android-arrow-dropdown' />
        </DropdownStyled>
      </DropdownIndicatorStyled>
      <DropdownMenuStyled open={isDropdownOpen}>
        <DropdownMenuWrapper
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
        <Options />
      </DropdownMenuStyled>
    </DropdownWrapper>
  )
}

export default FilterDropdown
