import Modal from 'react-modal';
import {useCallback, useMemo, useState} from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch,useSelector } from 'react-redux';
import {
    ArrowUpwardIconStyles,
    ButtonsStyles,
} from "../../ToolsAndReports/LightingAsBuiltsManagerPage";
import Button from "../../../components/Button";
import {ModalHeaderWrapper, UploadAssetListItems, modalCustomStyles, ISRFileDropZone, FileNameStyled} from "./styles";
import CloseButton from "../../../components/CloseButton";
import Spinner from "../../../components/Spinner";
import ArrowUpwardIcon from "../../../components/Icons/ArrowUpwardIcon";
import {actions as isrActions, selectISRProcessing} from '../../../ducks/billing/ISR/isrProcessingTool';
import {ErrorMessageDisplay} from "./ErrorMessageDisplay";
import {FILE_NAME_TOO_LONG_MESSAGE, MAX_FILE_LENGTH} from "./utils/utils";


if (document.getElementById('root')) {
    Modal.setAppElement('#root');
}

type FTProps = {
    showDilog: boolean;
    setShowDialog: (show: boolean) => void;
};

const FileUploadModal = ({showDilog, setShowDialog}:FTProps) => {
    const [isrFileName  , setIsrFileName  ] = useState('');
    const dispatch = useDispatch();
    const {isUploading, error: isrUploadError} = useSelector(selectISRProcessing);

    // handlers
    const closeModal = useCallback(() => {
        setShowDialog(false);
        setIsrFileName('')
        dispatch(isrActions.resetISRProcessingState());
        },[setShowDialog]);

    const handleDropISRFile = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                if (file.name.endsWith('.xlsm')) {
                    setIsrFileName(file.name);
                }
            }
            reader.readAsArrayBuffer(file);
        })
    }, []);

    const handleFileUpload = (file: File) => {
        dispatch(isrActions.uploadISRFile({ file }));
        setIsrFileName('');
    };

    // file length validation
    const isFileNameTooLarge = useMemo(() => isrFileName.length > MAX_FILE_LENGTH, [isrFileName]);
    const {
        acceptedFiles: acceptedFilesISRFileUpload,
        getInputProps: getInputPropsISRFileUpload,
        getRootProps: getRootPropsISRFileUpload,
        open: openISRFileUpload,
    } = useDropzone({
        multiple: false,
        noClick: true,
        noKeyboard: true,
        onDrop: handleDropISRFile,
    });

    return (
        <Modal
            isOpen={showDilog}
            onRequestClose={closeModal}
            style={modalCustomStyles}
            contentLabel="ISR File Upload Modal"
        >
            <ModalHeaderWrapper>
                <b>ISR File Processor Upload</b>
                <CloseButton
                    data-testid="isr__upload__close__button"
                    onClick={closeModal}
                />
            </ModalHeaderWrapper>

            <UploadAssetListItems>
                <li>Upload the ISR file here for validation.</li>
                <li>The upload file must be a .xlsm.</li>
            </UploadAssetListItems>

            <ISRFileDropZone
                {...getRootPropsISRFileUpload()}
                data-testid="isr__file__dropzone"
                isError={Boolean(isFileNameTooLarge || isrUploadError)}
            >
                {isFileNameTooLarge && (
                    <ErrorMessageDisplay message={FILE_NAME_TOO_LONG_MESSAGE} />
                )}
                {isrUploadError && (
                    <ErrorMessageDisplay message={isrUploadError} />
                )}

                <input
                    name="ISR file"
                    {...getInputPropsISRFileUpload()}
                />

                {isUploading ? (
                    <div>
                        <Spinner inline size="micro" />
                        <b>Upload in progress...</b>
                    </div>
                ) : (
                    !isFileNameTooLarge &&
                    !isrUploadError && (
                        <ArrowUpwardIconStyles>
                            <ArrowUpwardIcon data-testid="arrow__upward__icon" />
                        </ArrowUpwardIconStyles>
                    )
                )}

                <FileNameStyled isError={Boolean(isFileNameTooLarge || isrUploadError)}>
                    {isrFileName || (!isUploading && "Drag file to upload")}
                </FileNameStyled>

                {!isrFileName && !isrUploadError ? (
                    <Button primary onClick={openISRFileUpload}>
                        Choose File
                    </Button>
                ) : (
                    <ButtonsStyles>
                        <Button onClick={closeModal}>Close</Button>
                        {isrFileName && !isFileNameTooLarge ? (
                            <Button
                                primary
                                onClick={() =>
                                    handleFileUpload(acceptedFilesISRFileUpload[0])
                                }
                            >
                                Upload
                            </Button>
                        ) : (
                            <Button primary onClick={openISRFileUpload}>
                                Re-upload
                            </Button>
                        )}
                    </ButtonsStyles>
                )}
            </ISRFileDropZone>
        </Modal>
    );
};

export default FileUploadModal;
