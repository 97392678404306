import { Switch, Route } from 'react-router-dom'
import type { FTRouterMatch } from '../../types'
import SiteFormPage from './SiteFormPage'
import MeterDetailPage from './MeterDetailPage'
import CircuitDetailPage from './CircuitDetailPage'
import MeterExportFormPage from './MeterExportFormPage'
import SiteDetail from '../../components/SiteDetail'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import features from '../../authorization/features'
import UserDetailPage from './UserDetailPage'

type FTProps = {
  match: FTRouterMatch
}

const SiteDetailPage = ({ match: { path, url } }: FTProps) => (
  <div>
    <UnauthorizedRedirect
      feature={features.meterExport}
      requireAll
      from={`${path}/meter-export`}
      to={url}
    />
    <Switch>
      <Route path={`${path}/edit`} component={SiteFormPage} />
      <Route path={`${path}/users/:userId`} component={UserDetailPage} />
      <Route path={`${path}/meters/:meterId`} component={MeterDetailPage} />
      <Route
        path={`${path}/circuits/:circuitId`}
        component={CircuitDetailPage}
      />
      <Route path={`${path}/meter-export`} component={MeterExportFormPage} />
      <Route component={SiteDetail} />
    </Switch>
  </div>
)

export default SiteDetailPage
