import styled from 'styled-components'
import DesignLibraryPageWrapper from '../../DesignLibraryPageWrapper'
import InfoSection from '../../../../components/InfoSection'

const mockData1 = [
  {
    header: {
      label: 'Title',
      value: 'Value',
    },
    values: [
      {
        label: 'Label1',
        value:
          'value1sdfsdfdsfdsdsfdsfdsfsdfdfd f sdf sdf dsfd dsfdsf df ff sds dfsfdssdfsd dsf sfs',
      },
      {
        label: 'Label2',
        value: 'value3',
      },
      {
        label: 'Label3',
        value: 'value3',
      },
      {
        label: 'Label4',
        value: 'value4',
      },
    ],
  },
  {
    header: {
      label: 'Title2',
      value: 'Value2',
    },
    values: [
      {
        label: 'Label1',
        value: 'value1',
      },
      {
        label: 'Label2',
        value: 'value3',
      },
      {
        label: 'Label3',
        value: 'value3',
      },
      {
        label: 'Label4',
        value: 'value4',
      },
    ],
  },
]
const mockData2 = [
  {
    header: null,
    values: [
      {
        label: 'Label1',
        value: 'value1',
      },
      {
        label: 'Label2',
        value: 'value3',
      },
      {
        label: 'Label3',
        value: 'value3',
      },
      {
        label: 'Label4',
        value: 'value4',
      },
    ],
  },
]
const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/info-section',
    text: 'Info Section',
  },
]
const MainContentStyled = styled.div`
  padding-bottom: 200px;
  padding-left: 100px;
  display: flex;

  h2 {
    padding-bottom: 30px;
  }

  > * {
    margin-bottom: 16px;
  }
`
export default () => (
  <DesignLibraryPageWrapper breadcrumbs={breadcrumbs} Title='Info Section'>
    <MainContentStyled>
      <div>
        <h2> With Titles</h2>
        <InfoSection info={mockData1} />
      </div>
      <div>
        <h2> Without Titles</h2>
        <InfoSection info={mockData2} />
      </div>
    </MainContentStyled>
  </DesignLibraryPageWrapper>
)
