import { PureComponent } from 'react'
import { connect } from 'react-redux'
import Feature from '../models/feature'

/* FeatureValidator is the primary mechanism for conditional rendering based on
the user's permissions.

It is used by wrapping an element or tree of elements with it and specifying a
feature.

If the feature does not match the user's permissions, none of the children
wrapped by FeatureValidator will render.

Additionally, you can pass a render function in `onInvalid`, which will invoke
and return your function (instead of null) if the feature does not match the
user's permissions.
*/
type FTProps = {
  loading: boolean
  children: JSX.Element
  permissions: Record<string, any>
  feature: Feature
  requireAll?: boolean
  onInvalid?: () => JSX.Element
}

class FeatureValidator extends PureComponent<FTProps> {
  featureIsValid() {
    const { permissions, feature, requireAll } = this.props

    if (requireAll) {
      return feature.allMatchWithPermissions(permissions)
    }

    return feature.anyMatchesWithPermissions(permissions)
  }

  render() {
    const { children, loading, onInvalid } = this.props

    if (loading) {
      return <></>
    }

    if (!this.featureIsValid()) {
      return onInvalid ? onInvalid() : <></>
    }

    return children
  }
}

FeatureValidator.defaultProps = {
  onInvalid: undefined,
  requireAll: false,
}

const mapStateToProps = (state) => {
  const {
    auth: { permissions, loading },
  } = state
  return {
    loading,
    permissions,
  }
}

export default connect(mapStateToProps)(FeatureValidator)
