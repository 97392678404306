import { useEffect, useCallback, useState } from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { bindActionCreators } from 'redux'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Breadcrumbs'
import FormSection from '../../components/FormSection'
import Title from '../../components/Title'
import Button from '../../components/Button'
import ArrowUpwardIcon from '../../components/Icons/ArrowUpwardIcon'
import CloudDownloadIcon from '../../components/Icons/CloudDownloadIcon'
import OpenInNewWindowIcon from '../../components/Icons/OpenInNewWindowIcon'
import features from '../../authorization/features'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import type { FTHvacAssetState } from '../../ducks/hvacAsset'
import { actions as authActions, selectHvacAsset } from '../../ducks/hvacAsset'
import { isVariantActive, naturallySort } from '../../utils'
import { consoleApiUrl } from '../../api'
import Spinner from '../../components/Spinner'
import SuccessArrowIcon from '../../components/Icons/SuccessArrowIcon'
import ReportProblemIcon from '../../components/Icons/ReportProblemIcon'

const linkColor = '#337ab7'
const ArrowUpwardIconStyles = styled.div`
  align-items: center;
  border: 2px solid #4a4a4a;
  border-radius: 6px;
  display: flex;
  padding: 6px;
`
const SuccessArrowIconStyles = styled.span`
  color: #7bcc76;
  position: relative;
  top: 6px;
`
const UploadAnotherFileStyles = styled.div`
  cursor: pointer;
  color: ${linkColor};
  padding: 30px 0 10px;
  text-align: center;
`
const TryAgainStyles = styled.div`
  color: ${linkColor};
  padding: 30px 0 10px;
`
const FilePathStyled = styled.p`
  margin-bottom: 24px;
`
const ButtonsStyles = styled.div`
  button {
    display: inline-block;
    margin: 0 20px;
  }
`
const LinkIconStyles = styled.span`
  bottom: -5px;
  color: ${linkColor};
  left: 4px;
  line-height: 0;
  position: relative;

  > * {
    font-size: 20px;
  }
`

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }

  if (props.isDragReject) {
    return '#ff1744'
  }

  if (props.isDragActive) {
    return '#2196f3'
  }

  return '#ccc'
}

const DropzoneStyles = styled.div`
  align-items: center;
  background-color: #fff;
  border-color: ${(props) => getColor(props)};
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  flex: 1;
  outline: none;
  padding: 40px;
  transition: border 0.24s ease-in-out;
`
const FormStyles = styled.div`
  flex: 2;
  margin-right: 1%;
`
const MainStyles = styled.div`
  display: flex;
  margin-top: 20px;

  a {
    color: ${linkColor};
  }
`
const SecondaryContentStyles = styled.div`
  flex: 1;
  margin-left: 1%;
`
const FormSectionInnerStyles = styled.div`
  padding: 8px 0;
`
const ErrorMessagesHeadingStyles = styled.div`
  margin-bottom: 20px;
`
const ErrorMessagesListStyles = styled.div`
  line-height: 2;
  max-height: 400px;
  overflow-y: auto;
`
const ErrorMessagesListHeadingStyles = styled.div`
  margin-bottom: 13px;
`
const ReportProblemIconStyled = styled(ReportProblemIcon)`
  position: relative;
  top: 6px;
`
const UploadAssetRecordsListStyled = styled.ul`
  line-height: 2;
  margin-bottom: 26px;
`
type FTProps = {
  actions: any
  hvacAsset: FTHvacAssetState
}
const mockAxios = axios.create()
const mock = new MockAdapter(mockAxios, {
  delayResponse: 500,
  onNoMatch: 'passthrough',
})
const HvacAssetUploadJobUrlBase = `${consoleApiUrl()}/admin/jobs`
const HvacAssetUploadJobUrlRegex = new RegExp(`${HvacAssetUploadJobUrlBase}/*`)
mock
  .onHead(HvacAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 0.3,
      'job-status': 'INPROGRESS',
    },
  )
  .onHead(HvacAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 0.6,
      'job-status': 'INPROGRESS',
    },
  )
  .onHead(HvacAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 0.9,
      'job-status': 'INPROGRESS',
    },
  )
  .onHead(HvacAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 1,
      'job-status': 'SUCCESS',
    },
  )
const mockErrorAxios = axios.create()
const mockError = new MockAdapter(mockErrorAxios, {
  delayResponse: 500,
  onNoMatch: 'passthrough',
})
mockError.onHead(HvacAssetUploadJobUrlRegex).reply(
  200,
  {},
  {
    'job-progress': 0,
    'job-status': 'ERROR',
    'job-reason': 'MeasureCode with id F43ILLAA not found',
  },
)

const HvacAssetManagerPage = (props: FTProps) => {
  const [fileName, setFileName] = useState('')
  const handleDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setFileName(file.name)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {
    acceptedFiles,
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleDrop,
  })
  const { actions, hvacAsset } = props
  const { errors, isCheckingJob, isPosting, job } = hvacAsset
  const {
    asset: assetErrors,
    company: companyErrors,
    hvacEquipment: hvacEquipmentErrors,
    site: siteErrors,
  } = errors
  const errorMessages = [
    ...assetErrors,
    ...companyErrors,
    ...hvacEquipmentErrors,
    ...siteErrors,
  ]
    .sort((message1, message2) =>
      naturallySort(message1.prettyMessage, message2.prettyMessage),
    )
    .map((message, index) => {
      const { prettyMessage = '' } = message
      return {
        message: prettyMessage,
        key: index,
      }
    })

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isPosting && !errorMessages && job.status !== 'ERROR' && job.id) {
      if (isVariantActive('hvac-asset-manager-mock')) {
        actions.fetchHvacAssetUploadJobMock({
          jobId: job.id,
          mockAxios,
        })
      } else if (isVariantActive('hvac-asset-manager-mock-job-error')) {
        actions.fetchHvacAssetUploadJobMockError({
          jobId: job.id,
          mockAxios: mockErrorAxios,
        })
      } else {
        actions.fetchHvacAssetUploadJob({
          jobId: job.id,
        })
      }
    }
  }, [isPosting, errorMessages, job])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      !isCheckingJob &&
      job.progress < 1 &&
      job.status !== 'ERROR' &&
      job.id
    ) {
      setTimeout(() => {
        if (isVariantActive('hvac-asset-manager-mock')) {
          actions.fetchHvacAssetUploadJobMock({
            jobId: job.id,
            mockAxios,
          })
        } else if (isVariantActive('hvac-asset-manager-mock-job-error')) {
          actions.fetchHvacAssetUploadJobMockError({
            jobId: job.id,
            mockAxios: mockErrorAxios,
          })
        } else {
          actions.fetchHvacAssetUploadJob({
            jobId: job.id,
          })
        }
      }, 2000)
    }
  }, [isCheckingJob, job])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => actions.resetHvacAssetState, [])

  const resetHvacAssetForm = () => {
    setFileName('')
    actions.resetHvacAssetState()
  }

  return (
    <>
      <UnauthorizedRedirect
        feature={features.uploadHvac}
        requireAll
        from='/tools/hvac-asset-manager'
        to='/'
      />
      <Breadcrumbs
        items={[
          {
            href: '/reports',
            text: 'Tools & Reports',
          },
          {
            href: '/tools/hvac-asset-manager',
            text: 'HVAC Asset Manager',
          },
        ]}
      />
      <Title>HVAC Asset Manager</Title>
      <MainStyles>
        <FormStyles>
          <FormSection>
            <FormSectionInnerStyles>
              {(!!errorMessages.length || job.status === 'ERROR') && (
                <>
                  <ErrorMessagesHeadingStyles>
                    <ReportProblemIconStyled color='#C70D08' inline />
                    <b>
                      {fileName}
                      {' upload failed.'}
                    </b>
                  </ErrorMessagesHeadingStyles>
                  <ErrorMessagesListHeadingStyles>
                    No records were stored. Please fix the following errors and
                    try again:
                  </ErrorMessagesListHeadingStyles>
                  <ErrorMessagesListStyles>
                    {errorMessages.map((errorMessage) => (
                      <div key={errorMessage.key}>{errorMessage.message}</div>
                    ))}
                    {job.status === 'ERROR' && <div>{job.reason}</div>}
                  </ErrorMessagesListStyles>
                  <TryAgainStyles>
                    <Button onClick={resetHvacAssetForm} primary>
                      Reset
                    </Button>
                  </TryAgainStyles>
                </>
              )}
              {!errorMessages.length && job.status !== 'ERROR' && (
                <>
                  <b>Upload asset records</b>
                  <UploadAssetRecordsListStyled>
                    <li>The upload file must be a .csv.</li>
                    <li>
                      All assets in the file must belong to the same customer.
                    </li>
                    <li>
                      Site codes are expected to be unique to the customer.
                    </li>
                    <li>Asset codes are expected to be unique to the site.</li>
                    <li>
                      If a required field is not found, the upload will be
                      rejected.
                    </li>
                    <li>
                      If a provided field cannot be converted to the expected
                      type, the upload will be rejected.
                    </li>
                    <li>
                      If an asset or site is uploaded more than once, the
                      properties of the most recent upload will override any
                      previous upload.
                    </li>
                    <li>
                      DO NOT CLOSE THIS WINDOW while upload is in progress,
                      otherwise you will lose access to the upload status.
                    </li>
                  </UploadAssetRecordsListStyled>
                  <DropzoneStyles
                    {...getRootProps({
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    })}
                  >
                    <input name='file' {...getInputProps()} />
                    {(isPosting || (job.id && job.status !== 'SUCCESS')) && (
                      <div>
                        <Spinner inline size='micro' />{' '}
                        <b>Upload in progress...</b>
                        {job.progress > 0 && ` ${job.progress * 100}%`}
                      </div>
                    )}
                    {!isPosting && !job.id && (
                      <ArrowUpwardIconStyles>
                        <ArrowUpwardIcon />
                      </ArrowUpwardIconStyles>
                    )}
                    <FilePathStyled>
                      {fileName || 'Drag file to upload'}
                    </FilePathStyled>
                    {job.id && job.status === 'SUCCESS' && (
                      <div>
                        <SuccessArrowIconStyles>
                          <SuccessArrowIcon />
                        </SuccessArrowIconStyles>{' '}
                        <b>Upload Complete</b>
                        <UploadAnotherFileStyles onClick={resetHvacAssetForm}>
                          Upload another file
                        </UploadAnotherFileStyles>
                      </div>
                    )}
                    {!fileName && (
                      <Button primary onClick={open}>
                        Choose File
                      </Button>
                    )}
                    {fileName && !job.id && !isPosting && (
                      <ButtonsStyles>
                        <Button onClick={resetHvacAssetForm}>Cancel</Button>
                        <Button
                          primary
                          onClick={() => {
                            actions.postHvacAsset({
                              file: acceptedFiles[0],
                            })
                          }}
                        >
                          Upload
                        </Button>
                      </ButtonsStyles>
                    )}
                  </DropzoneStyles>
                </>
              )}
            </FormSectionInnerStyles>
          </FormSection>
        </FormStyles>
        <SecondaryContentStyles>
          <b>What is the HVAC asset database?</b>
          <br />
          <p>
            The HVAC asset database is where we store everything we know about
            HVAC equipment found in the field. It contains records that our
            customers, prospective customers, and partners have given us as well
            as records that we collected during on-site audits.
          </p>
          <p>
            Its purpose is to provide our proposals, sales, and solutions teams
            with as much asset data as possible so that they can build accurate
            models and sales proposals.
          </p>
          <ul>
            <li>
              <Link to='#'>
                Example .csv file
                <LinkIconStyles>
                  <CloudDownloadIcon />
                </LinkIconStyles>
              </Link>
            </li>
            <li>
              <a
                href='https://redaptiveinc.atlassian.net/wiki/spaces/RDP/pages/926187552/HVAC+Data+Fields+File+Upload'
                rel='noopener noreferrer'
                target='_blank'
              >
                Expected data fields & descriptions
                <LinkIconStyles>
                  <OpenInNewWindowIcon />
                </LinkIconStyles>
              </a>
            </li>
            <li>
              <Link to='#'>
                How to view the dataset with Tableau
                <LinkIconStyles>
                  <OpenInNewWindowIcon />
                </LinkIconStyles>
              </Link>
            </li>
            <li>
              <Link to='#'>
                How to upload a .csv using Postman
                <LinkIconStyles>
                  <OpenInNewWindowIcon />
                </LinkIconStyles>
              </Link>
            </li>
          </ul>
        </SecondaryContentStyles>
      </MainStyles>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authActions, dispatch),
})

const mapStateToProps = (state) => ({
  hvacAsset: selectHvacAsset(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HvacAssetManagerPage)
