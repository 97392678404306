import styled from 'styled-components'
import { ReactNode, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from './Icons/KeyboardArrowDownIcon'
import KeyboardArrowUpIcon from './Icons/KeyboardArrowUpIcon'
import Spinner from './Spinner'

type FTProps = {
  title: string | ReactNode
  isExpandedInitially?: boolean
  Content: () => ReactNode
  closeText: string
  downloadText: string
  viewText: string
  onDownload: () => void
  showDownload: boolean
  downloading: boolean
}

const MainContainerStyled = styled.div`
  width: 100%;
`

const HeaderStyled = styled.div`
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`
const TitleStyled = styled.div`
  padding-bottom: 10px;
  color: #4a4a4a;
`
const IndicatorStyled = styled.div`
  color: #337ab7;
  cursor: pointer;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  margin-left: 15px;
  ${(props) =>
    props.disabled &&
    `
    color: grey;
    cursor: auto;
  `}
`

const RightView = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DownloadAccordion = ({
  title,
  Content,
  isExpandedInitially = false,
  closeText,
  downloadText,
  viewText,
  onDownload,
  showDownload,
  downloading,
}: FTProps) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedInitially)
  useEffect(() => {
    setIsExpanded(isExpandedInitially)
  }, [isExpandedInitially])
  return (
    <MainContainerStyled>
      <HeaderStyled>
        <TitleStyled>{title}</TitleStyled>
        <RightView>
          {downloading && <Spinner size='tiny' />}
          {showDownload && (
            <IndicatorStyled
              onClick={downloading ? () => {} : onDownload}
              disabled={downloading}
            >
              {downloadText}
            </IndicatorStyled>
          )}
          <IndicatorStyled onClick={() => setIsExpanded((current) => !current)}>
            {isExpanded ?
              <span>
                {closeText}
                <KeyboardArrowUpIcon />
              </span>
            : <span>
                {viewText}
                <KeyboardArrowDownIcon />
              </span>
            }
          </IndicatorStyled>
        </RightView>
      </HeaderStyled>
      {isExpanded ?
        <Content />
      : <div />}
    </MainContainerStyled>
  )
}

export default DownloadAccordion
