import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  actions as meterActions,
  selectMeterEntity,
  selectUtilityMeterModelsItems,
} from '../../../ducks/meters'
import Button2 from '../../Button2'
import type { FTModalKfactorProps } from './commons'
import {
  ModalContentStyled,
  ModalBodyStyled,
  ModalFooterStyled,
  CloseIconStyled,
  HeaderLabelStyled,
  HeaderStyled,
  BorderedContainerStyled,
  InputStyled,
  FieldContainerStyled,
  LabelStyled,
  TextAreaStyled,
  RequiredMarkStyled,
  ClearBtnStyled,
  CALCULATION_TYPE,
  FieldErrorStyled,
} from './commons'
import SyncFilled from '../../Icons/svg/SyncFilled'
import ConfirmationMessage from './ConfirmationMessage'

const ManualKFactorForm = (props: FTModalKfactorProps) => {
  const {
    meterId,
    closeModal,
    meterEntity,
    actions: { fetchUtilityMeterModels, addKFactor },
  } = props
  useEffect(() => {
    fetchUtilityMeterModels()
  }, [])
  const [kfactor, setKFactor] = useState('')
  const [comments, setComments] = useState('')
  const [kFactorError, setKFactorError] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)
  const loading = meterEntity?.meta?.loading
  const errorMsg = meterEntity?.meta?.error
  const isFormValid = !!kfactor
  const isFormDirty = !!kfactor || !!comments

  const displayError = () => {
    const kfactorNumber = Number(kfactor)

    if (Number.isNaN(kfactorNumber)) {
      setKFactorError('K-factor must be a number greater than zero')
      return
    }

    if (kfactorNumber <= 0) {
      setKFactorError('K-factor values cannot be set to zero or negative')
    }
  }

  const submitKfactor = () => {
    const isKFactorValid =
      !!kfactor && !Number.isNaN(Number(kfactor)) && Number(kfactor) > 0

    if (!isKFactorValid) {
      displayError()
      return
    }

    addKFactor({
      meterId,
      calculationType: CALCULATION_TYPE.MANUAL,
      kFactor: kfactor,
      comment: comments,
    })
    closeModal()
  }

  const resetForm = () => {
    setKFactor('')
    setComments('')
    setKFactorError('')
  }

  return (
    <ModalContentStyled>
      <CloseIconStyled onClick={closeModal}>
        <span className='ion-android-close' />
      </CloseIconStyled>
      {!isConfirming && (
        <>
          <ModalBodyStyled>
            <HeaderStyled>
              <div>
                <HeaderLabelStyled>Add a K-Factor Manually</HeaderLabelStyled>
              </div>
            </HeaderStyled>
            <BorderedContainerStyled>
              <FieldContainerStyled>
                <LabelStyled>
                  Enter k-factor
                  <RequiredMarkStyled>*</RequiredMarkStyled>
                </LabelStyled>
                <InputStyled
                  type='text'
                  value={kfactor}
                  onChange={(e) => setKFactor(e.target.value)}
                />
                {kFactorError && (
                  <FieldErrorStyled>{kFactorError}</FieldErrorStyled>
                )}
              </FieldContainerStyled>
              <FieldContainerStyled>
                <LabelStyled>Comments</LabelStyled>
                <TextAreaStyled
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </FieldContainerStyled>
            </BorderedContainerStyled>
          </ModalBodyStyled>
          <ModalFooterStyled>
            <ClearBtnStyled disabled={!isFormDirty} onClick={resetForm}>
              <SyncFilled />
              <span>Reset</span>
            </ClearBtnStyled>
            <Button2
              disabled={!isFormValid}
              type='primary'
              onClick={submitKfactor}
            >
              Save & Mark as active
            </Button2>
          </ModalFooterStyled>
        </>
      )}
      {isConfirming && (
        <ConfirmationMessage
          handleSaveKFactor={submitKfactor}
          handleCancel={() => setIsConfirming(false)}
          loading={loading}
          error={errorMsg}
        />
      )}
    </ModalContentStyled>
  )
}

const mapStateToProps = (state, props) => {
  const entity = selectUtilityMeterModelsItems(state)
  return {
    utilityMeterModels: entity,
    meterEntity: selectMeterEntity(state, props.meterId),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(meterActions, dispatch) },
})

export default connect(mapStateToProps, mapDispatchToProps)(ManualKFactorForm)
