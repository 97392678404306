import NavigationPrompt from 'react-router-navigation-prompt'
import Modal from './Modal'

type FTProps = {
  when: boolean
  message?: string
  primaryActionText?: string
  secondaryActionText?: string
  confirmActions?: () => void
}

const UnsavedChanges = ({
  when,
  message,
  primaryActionText,
  secondaryActionText,
  confirmActions,
}: FTProps) => (
  <NavigationPrompt when={when}>
    {({ onConfirm, onCancel }) => (
      <Modal
        title={message}
        acceptText={primaryActionText}
        declineText={secondaryActionText}
        onClose={onCancel}
        onDecline={() => {
          onConfirm()
          if (confirmActions) confirmActions()
        }}
        onAccept={onCancel}
        content={[]}
        modalWidth='420px'
        hideClose
      />
    )}
  </NavigationPrompt>
)

UnsavedChanges.defaultProps = {
  message: 'This page has unsaved changes. Are you sure you want to exit?',
  primaryActionText: 'No',
  secondaryActionText: 'Yes',
}

export default UnsavedChanges
