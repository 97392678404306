import styled from 'styled-components'
import Input from './Input'
import MetaText from './MetaText'

export const InlineInputLabelSectionStyled = styled.div`
  width: 460px;
`
const FieldTitle = styled.div`
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 7px;
`
export const InlineInputSectionStyled = styled.div`
  margin-left: 150px;
`
export const InlineInputStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
type FTProps = {
  fieldTitle: string
  description?: string
  className?: string
  fieldComponent?: React$AbstractComponent<any, any>
}

const InlineInput = ({
  fieldTitle,
  description,
  className,
  fieldComponent: FieldComponent = Input,
  ...fieldProps
}: FTProps) => (
  <InlineInputStyled className={className}>
    <InlineInputLabelSectionStyled>
      <FieldTitle>{fieldTitle}</FieldTitle>
      <MetaText>{description}</MetaText>
    </InlineInputLabelSectionStyled>
    <InlineInputSectionStyled>
      <FieldComponent {...fieldProps} />
    </InlineInputSectionStyled>
  </InlineInputStyled>
)

InlineInput.defaultProps = {
  fieldComponent: Input,
  description: '',
  className: '',
}
export default InlineInput
