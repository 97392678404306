import styled from 'styled-components'
import Base, { Item, Label, Value } from './Base'

export default styled(Base)`
  column-gap: 60px;
  //margin-left: 20px;

  //column-gap: 42px;
  ${Value} {
    text-align: right;
  }

  ${Label} {
    font-weight: 500;
  }

  ${Item} {
    color: #4a4a4a;
    line-height: 46px;
    padding: 0 20px;
    margin-bottom: 0;
    & ${Value}, ${Label} {
      padding-bottom: 0;
    }
  }

  ${Item}:nth-child(odd) {
    background-color: #f7f7f7;
  }
`
