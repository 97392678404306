import styled from 'styled-components'
import { ComponentType } from 'react'
import Table from '../Table'

const StyledTable = styled(Table)`
  &:first-child {
    margin-right: 30px;
    flex: 1;
  }
  &:last-child {
    margin-left: 30px;
    flex: 1;
  }

  td:first-child {
    width: 700px;
  }

  td:nth-child(2) {
    width: 480px;
  }
`
const Styles = styled.div`
  display: flex;
  justify-content: space-between;
`
type FTProps = {
  items: Array<Record<string, any>>
  tableComponent?: ComponentType<any>
  className?: string
  tableHeaders1?: Array<string>
  tableHeaders2?: Array<string>
}

const SplitTable = ({
  items: itemsOriginal,
  className,
  tableComponent: TableComponent = StyledTable,
  tableHeaders1,
  tableHeaders2,
  ...props
}: FTProps) => {
  if (!TableComponent) {
    throw new Error('tableComponent was not set')
  }

  const items = itemsOriginal || []
  const midpoint = Math.ceil(items.length / 2)
  const leftProps = {
    ...props,
    items: items.slice(0, midpoint),
    tableHeaders: tableHeaders1,
  }
  const rightProps = {
    ...props,
    items: items.slice(midpoint, items.length),
    showNoResults: false,
    tableHeaders: tableHeaders2,
  }
  return (
    <Styles className={className}>
      <TableComponent {...leftProps} />
      <TableComponent {...rightProps} />
    </Styles>
  )
}

SplitTable.defaultProps = {
  tableComponent: StyledTable,
  tableHeaders1: [],
  tableHeaders2: [],
  className: '',
}
export default SplitTable
