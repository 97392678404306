import styled from 'styled-components'
import ReportProblemIcon from '../Icons/ReportProblemIcon'

export const CTOrientationCellStyles = styled.span<{ warning: boolean }>`
  color: ${({ warning }) => (warning ? '#C70D08' : 'inherit')};
`
export const ReportProblemIconStyled = styled(ReportProblemIcon)`
  font-size: 18px;
  margin-right: 2px;
  position: relative;
  top: 4px;
`
