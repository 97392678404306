import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { defaultUtilityInflationRate } from '../../pages/ProposalOperations/ProposalsEngine/utils'
import { handleSagaError } from '../../sagas/utils'

export type InflationRateItem = {
  id: string
  stateName: string
  stateCode: string
  electricityInflationRate: number
}

export type FTFetchUtilityInflationRateDataResp = Array<InflationRateItem>

const types = {
  FETCH_UTILITY_INFLATION_RATE_DATA: 'FETCH_UTILITY_INFLATION_RATE_DATA',
  FETCH_UTILITY_INFLATION_RATE_DATA_SUCCESS:
    'FETCH_UTILITY_INFLATION_RATE_DATA_SUCCESS',
  FETCH_UTILITY_INFLATION_RATE_DATA_ERROR:
    'FETCH_UTILITY_INFLATION_RATE_DATA_ERROR',
}

export const actions = {
  fetchUtilityInflationRateData: () => ({
    type: types.FETCH_UTILITY_INFLATION_RATE_DATA,
  }),
}

type FTUtilityInflationRateMetaState = {
  error: string
  loading: boolean
}
type FTUtilityInflationRateEntityState = {
  utilityInflationRateData: FTFetchUtilityInflationRateDataResp
  meta: FTUtilityInflationRateMetaState
}
type FTState = {
  entities: FTUtilityInflationRateEntityState
}

const initialState = {
  meta: {
    error: '',
    loading: false,
  },
  utilityInflationRateData: {
    electricityInflationRate: defaultUtilityInflationRate,
  },
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_UTILITY_INFLATION_RATE_DATA:
      return {
        error: '',
        loading: true,
      }

    case types.FETCH_UTILITY_INFLATION_RATE_DATA_SUCCESS:
      return {
        error: '',
        loading: false,
      }

    default:
      return state
  }
}

function utilityInflationRateData(
  state = initialState.utilityInflationRateData,
  action,
) {
  switch (action.type) {
    case types.FETCH_UTILITY_INFLATION_RATE_DATA:
      return initialState.utilityInflationRateData

    case types.FETCH_UTILITY_INFLATION_RATE_DATA_SUCCESS:
      return action.payload

    default:
      return state
  }
}

export default combineReducers({
  utilityInflationRateData,
  meta,
})

const API = {
  fetchUtilityInflationRateData: () => {
    const url = `${consoleBaseUrl()}/proposal/api/utility-inflation-rate`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTFetchUtilityInflationRateDataResp }) => data)
      .catch(handleAxiosError)
  },
}

// Sagas
function* fetchUtilityInflationRateDataSaga(): Generator<any, void, any> {
  try {
    const response: FTFetchUtilityInflationRateDataResp = yield call(
      API.fetchUtilityInflationRateData,
    )
    yield put({
      type: types.FETCH_UTILITY_INFLATION_RATE_DATA_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_UTILITY_INFLATION_RATE_DATA_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_UTILITY_INFLATION_RATE_DATA,
    fetchUtilityInflationRateDataSaga,
  ),
]
// Selectors
export const selectUtilityInflationRateDataEntity = (
  state: FTState,
): FTUtilityInflationRateEntityState => state.entities.utilityInflationRateData
