import { v4 as uuidv4 } from 'uuid'
import { combineReducers } from 'redux'
import { schema, normalize } from 'normalizr'
import { takeLatest, takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import { Value } from 'classnames'
import { consoleApiUrl, defaultHeaders } from '../api'
import {
  addMetaToResponse,
  makeActionTypes,
  makeApiQueryString,
  makeSagaPayload,
} from './utils'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import type { FTSortable } from '../types'
import '../types'
import { actions as modalActions } from './modal'
import { filterEmptyValues, isVariantActive } from '../utils'
import panelsMockData from '../mockData/orion/panelsMockData'
import type { FTPanelCircuit } from './panelCircuits'

export type FTPanel = {
  description?: string
  id: string
  name: string
  renderFunc?: () => React.ReactNode
  siteId: string
  type?: string
  voltage?: string
  parentPanelName?: string
  parentPanelId?: string
  panelLevel?: string
  location?: string
}
export type FTPanelSummary = {
  id: string
  name: string
  description?: string
  type?: string
  voltage?: string
  amperage?: string
  location?: string
  meterIds?: Array<string>
  parentPanelName?: string
  parentPanelId?: string
  panelLevel?: string
  auditDate?: Date
  created?: Date
  modified?: Date
  auditorName?: string
  powerSourceLevel?: string
  panelFeed?: Array<Record<string, Record<string, string>>>
  networkAvailability?: boolean
  networkStrength?: string
  isNumbered?: boolean
  auditNotes?: string
  numberOfCircuits?: number
  numberOfSwitches?: number
  floorNumber?: string
  opportunityId?: string
  fromPanelModal?: boolean
  photosUpdated?: boolean
}
export type FTPanelFeed = {
  hardwareId: string
  channelName: string
  ctSize: string
  phase: string
}
export type FTAddPanelAction = {
  id?: string
  name: string
  description?: string
  siteId?: string
  type?: string
  voltage?: string
  location?: string
  amperage?: string
  circuits?: Array<FTPanelCircuit>
  parentPanelId?: string
  powerSourceLevel?: string
  opportunityId?: string
  floorNumber?: string
  networkAvailability?: boolean
  networkStrength?: string
  isNumbered?: boolean
  auditorName?: string
  auditDate?: string
  auditNotes?: string
}
type FTAddPanelSuccessResponse = {
  description: string
  id: string
  name: string
  siteId: string
  type: string
  voltage: string
}
type FTUpdatePanelAction = {
  name: string
  description?: string
  siteId?: string
  type?: string
  voltage?: string
  location?: string
  amperage?: string
  circuits?: Array<FTPanelCircuit>
  parentPanelId?: string
  powerSourceLevel?: string
  opportunityId?: string
  floorNumber?: string
  networkAvailability?: boolean
  networkStrength?: string
  isNumbered?: boolean
  auditorName?: string
  auditDate?: string
  auditNotes?: string
}
export type FTDeletePanelAction = {
  id: string
  name?: string
}
export type FTSetPanelAction = {
  values: Record<string, any>
}

type FTUploadPhotosAction = {
  values: Record<string, any>
}

export enum FILE_UPLOAD_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
export type FTPhotoObject = {
  id?: string
  objectId: string
  objectType: string
  fileName: string
  fileUploadPath?: string
  preSignedUrl?: string
  presignedThumbnailUrl?: string
  responseStatus?: string
  status?: FILE_UPLOAD_STATUS
  file: File
}

// Entity Name
const entity = 'panels'
// Action Types
const types = {
  ...makeActionTypes('FETCH_PANEL_LIST'),
  ...makeActionTypes('FETCH_ALL_PANELS'),
  ...makeActionTypes('FETCH_PANEL'),
  ...makeActionTypes('ADD_PANEL'),
  ...makeActionTypes('UPDATE_PANEL'),
  ...makeActionTypes('DELETE_PANEL'),
  ...makeActionTypes('SET_PANEL'),
  ...makeActionTypes('UPLOAD_PHOTOS'),
  ...makeActionTypes('DELETE_PHOTOS'),
  SET_TEMP_DATA: 'SET_TEMP_DATA',
  RESET_PANEL: 'RESET_PANEL',
  ADD_PANEL_SUCCESS_NOCONFIG: 'ADD_PANEL_SUCCESS_NOCONFIG',
  SET_ADDED_PANEL_ID: 'SET_ADDED_PANEL_ID',
}
export type FTPanelListEntityMetaState = {
  addLoaded: boolean
  addLoading: boolean
  addedPanelId: string
  deleteLoaded: boolean
  deleteLoading: boolean
  error: string
  allLoaded: boolean
  allLoading: boolean
  loaded: boolean
  loading: boolean
  next: string | null | undefined
  pageNumber: number | null | undefined
  pageSize: number | null | undefined
  previous: string | null | undefined
  updateLoaded: boolean
  updateLoading: boolean
  deletePhotosLoading: boolean
  deletePhotosLoaded: boolean
  updates: Array<string>
  uploadingPhotos: boolean
  uploadingPhotosStatus: boolean
  temp: Record<string, any>
}
type FTPanelEntityState = {
  byId: Record<string, FTPanel>
  ids: Array<string>
  allIds: Array<string>
  photos: Array<FTPhotoObject>
  allById: Record<string, FTPanel>
  meta: FTPanelListEntityMetaState
}
type FTPanelListEntity = {
  items: Array<FTPanel>
  allItems: Array<FTPanel>
  meta: FTPanelListEntityMetaState
  allById: Record<string, FTPanel>
}

export type FTPanelState = {
  entities: {
    panels: FTPanelEntityState
  }
}
// Reducers
const initialState: FTPanelEntityState = {
  byId: {},
  ids: [],
  allIds: [],
  allById: {},
  photos: [],
  meta: {
    addLoaded: false,
    addLoading: false,
    addedPanelId: '',
    deleteLoaded: false,
    deleteLoading: false,
    deletePhotosLoading: false,
    deletePhotosLoaded: false,
    error: '',
    loaded: false,
    loading: false,
    allLoaded: false,
    allLoading: false,
    next: null,
    pageNumber: 1,
    pageSize: 20,
    previous: null,
    updateLoaded: false,
    updateLoading: false,
    updates: [],
    uploadingPhotos: false,
    uploadingPhotosStatus: false,
    temp: {},
  },
}
export const entitySchema = new schema.Entity(entity)

function entityById(action, state) {
  return { ...state, ...action.payload.entities[entity] }
}

function entityAllIds(action, state) {
  return [...new Set(state.concat(action.payload.result))]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PANEL_LIST:
    case types.FETCH_PANEL:
      return {}

    case types.FETCH_PANEL_LIST_SUCCESS:
    case types.FETCH_PANEL_SUCCESS:
    case types.ADD_PANEL_SUCCESS:
    case types.ADD_PANEL_SUCCESS_NOCONFIG:
    case types.UPDATE_PANEL_SUCCESS:
      return entityById(action, state)

    case types.DELETE_PANEL_SUCCESS:
      if (action.payload.id) {
        const { [action.payload.id]: deleted, ...newState } = state
        return newState
      }

      return state

    default:
      return state
  }
}

function allById(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_ALL_PANELS:
      return {}

    case types.FETCH_ALL_PANELS_SUCCESS:
    case types.ADD_PANEL_SUCCESS:
    case types.ADD_PANEL_SUCCESS_NOCONFIG:
    case types.UPDATE_PANEL_SUCCESS:
      return entityById(action, state)

    case types.DELETE_PANEL_SUCCESS:
      if (action.payload.id) {
        const { [action.payload.id]: deleted, ...newState } = state
        return newState
      }

      return state

    default:
      return state
  }
}

function ids(state = initialState.ids, action) {
  switch (action.type) {
    case types.FETCH_PANEL_LIST:
    case types.FETCH_PANEL:
      return []

    case types.FETCH_PANEL_LIST_SUCCESS:
    case types.FETCH_PANEL_SUCCESS:
    case types.ADD_PANEL_SUCCESS:
    case types.ADD_PANEL_SUCCESS_NOCONFIG:
    case types.UPDATE_PANEL_SUCCESS:
      return entityAllIds(action, state)

    case types.DELETE_PANEL_SUCCESS:
      if (action.payload.id) {
        return state.filter((p) => p !== action.payload.id)
      }

      return state

    default:
      return state
  }
}

function allIds(state = initialState.ids, action) {
  switch (action.type) {
    case types.FETCH_ALL_PANELS:
      return []

    case types.FETCH_ALL_PANELS_SUCCESS:
    case types.ADD_PANEL_SUCCESS:
    case types.ADD_PANEL_SUCCESS_NOCONFIG:
      return entityAllIds(action, state)

    case types.DELETE_PANEL_SUCCESS:
      if (action.payload.id) {
        return state.filter((p) => p !== action.payload.id)
      }

      return state

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PANEL_LIST:
    case types.FETCH_PANEL:
      return {
        ...state,
        loaded: false,
        loading: true,
        deleteLoaded: false,
        deleteLoading: false,
        error: '',
      }

    case types.FETCH_PANEL_LIST_ERROR:
    case types.FETCH_PANEL_ERROR:
      return { ...state, error: action.error, loaded: false, loading: false }

    case types.FETCH_PANEL_LIST_SUCCESS:
    case types.FETCH_PANEL_SUCCESS:
      return { ...state, ...action.payload.meta, loaded: true, loading: false }

    case types.FETCH_ALL_PANELS:
      return { ...state, allLoaded: false, allLoading: true }

    case types.FETCH_ALL_PANELS_ERROR:
      return {
        ...state,
        allLoading: false,
        allLoaded: false,
        error: action?.error,
      }

    case types.FETCH_ALL_PANELS_SUCCESS:
      return { ...state, allLoaded: true, allLoading: false }

    case types.ADD_PANEL:
      return { ...state, addLoaded: false, addLoading: true, addedPanelId: '' }

    case types.ADD_PANEL_SUCCESS_NOCONFIG:
      return {
        ...state,
        addLoaded: true,
        addLoading: false,
        addedPanelId: null,
      }
    case types.ADD_PANEL_SUCCESS:
      return {
        ...state,
        addLoaded: true,
        addLoading: false,
        addedPanelId: action.payload.result[0],
      }

    case types.ADD_PANEL_ERROR:
      return {
        ...state,
        addLoaded: false,
        addLoading: false,
        error: action.error,
        addedPanelId: '',
      }

    case types.SET_PANEL:
      return { ...state, addLoaded: false, addLoading: true, temp: {} }

    case types.SET_PANEL_SUCCESS:
      return {
        ...state,
        addLoaded: true,
        addLoading: false,
        temp: action.payload,
      }

    case types.SET_PANEL_ERROR:
      return {
        ...state,
        addLoaded: false,
        addLoading: false,
        error: action.error,
        temp: {},
      }

    case types.RESET_PANEL:
      return {
        ...state,
        temp: {},
        addedPanelId: '',
        uploadingPhotosStatus: false,
        deletePhotosLoading: false,
        deletePhotosLoaded: false,
        updateLoaded: false,
        updateLoading: false,
      }

    case types.UPDATE_PANEL:
      return {
        ...state,
        updateLoaded: false,
        updateLoading: true,
        updates: [...state.updates, action.id],
      }

    case types.UPDATE_PANEL_SUCCESS:
      return {
        ...state,
        updateLoaded: true,
        updateLoading: false,
        updates: state.updates.filter((id) => id !== action.payload.result[0]),
      }

    case types.UPDATE_PANEL_ERROR:
      return {
        ...state,
        updateLoaded: false,
        updateLoading: false,
        addedPanelId: '',
        updates: state.updates.filter((id) => id !== action.id),
        error: action.error,
      }

    case types.DELETE_PANEL:
      return { ...state, deleteLoaded: false, deleteLoading: true }

    case types.DELETE_PANEL_SUCCESS:
      return { ...state, deleteLoaded: true, deleteLoading: false }

    case types.DELETE_PANEL_ERROR:
      return {
        ...state,
        deleteLoaded: false,
        deleteLoading: false,
        error: action.error,
      }
    case types.SET_TEMP_DATA:
      return { ...state, temp: action.payload }

    case types.UPLOAD_PHOTOS:
      return { ...state, uploadingPhotos: true }

    case types.UPLOAD_PHOTOS_SUCCESS:
      return {
        ...state,
        uploadingPhotos: false,
        uploadingPhotosStatus: action.payload,
      }

    case types.DELETE_PHOTOS:
      return { ...state, deletePhotosLoading: true, deletePhotosLoaded: false }

    case types.DELETE_PHOTOS_SUCCESS:
      return { ...state, deletePhotosLoading: false, deletePhotosLoaded: true }

    case types.SET_ADDED_PANEL_ID:
      return { ...state, addedPanelId: action.payload }

    default:
      return state
  }
}

function photos(state = initialState.photos, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default combineReducers({
  byId,
  ids,
  allById,
  allIds,
  meta,
  photos,
}) // Action Creators

export const actions = {
  fetchAllPanels: (params: { siteId: string }) => ({
    type: types.FETCH_ALL_PANELS,
    pageSize: 1000,
    ...params,
  }),
  fetchPanelList: (params: { siteId: string; orderBy?: FTSortable }) => ({
    type: types.FETCH_PANEL_LIST,
    ...params,
  }),
  fetchPanel: (props: { id: string }) => ({
    type: types.FETCH_PANEL,
    ...props,
  }),
  addPanel: (props: FTAddPanelAction) => ({
    type: types.ADD_PANEL,
    params: { ...props },
  }),
  updatePanel: (props: FTUpdatePanelAction) => ({
    type: types.UPDATE_PANEL,
    params: { ...props },
  }),
  deletePanel: (props: FTDeletePanelAction) => ({
    type: types.DELETE_PANEL,
    ...props,
  }),
  setPanel: (props: FTSetPanelAction) => ({
    type: types.SET_PANEL,
    ...props,
  }),
  resetPanel: () => ({
    type: types.RESET_PANEL,
  }),
  setTempData: (data: Record<string, Value>) => ({
    type: types.SET_TEMP_DATA,
    payload: data,
  }),
  uploadPhotos: (photosArray: Array<FTPhotoObject>) => ({
    type: types.UPLOAD_PHOTOS,
    payload: photosArray,
  }),
  deletePhotos: (photoIds: Array<string>) => ({
    type: types.DELETE_PHOTOS,
    payload: photoIds,
  }),
  setAddedPanelId: (panelId: string) => ({
    type: types.SET_ADDED_PANEL_ID,
    payload: panelId,
  }),
}
// SELECTORS
export const selectPanelList = (state: FTPanelState): Array<FTPanel> =>
  state.entities[entity].ids.map((id) => state.entities[entity].byId[id])
export const selectAllPanelsList = (state: FTPanelState): Array<FTPanel> =>
  state.entities[entity].allIds.map((id) => state.entities[entity].allById[id])
export const selectPanel = (state: FTPanelState, id: string) =>
  state.entities[entity].byId[id]
export const selectById = (state: FTPanelState) => state.entities[entity].byId
export const selectAllById = (state: FTPanelState) =>
  state.entities[entity].allById
export const selectPanelListEntity = (
  state: FTPanelState,
): FTPanelListEntity => ({
  items: selectPanelList(state),
  allItems: selectAllPanelsList(state),
  allById: state.entities[entity].allById,
  byId: state.entities[entity].byId,
  meta: state.entities[entity].meta,
})
export const selectPanelEntity = (state: FTPanelState, id: string) => ({
  item: selectPanel(state, id),
  meta: state.entities[entity].meta,
})

export const selectTempMeta = (state: FTPanelState) =>
  state.entities[entity].meta.temp

const PanelDeleteConflictException = ({ meters, panelName }) => ({
  name: 'PanelDeleteConflict',
  meters,
  panelName,
  toString: `Panel Delete Conflict: ${panelName}`,
})

const handleDeletePanelAxiosError =
  ({ panelId, panelName = '' }) =>
  (error: Record<string, any>) => {
    const { response } = error || {}
    const { data: meters = [], status } = response

    if (status === 409) {
      throw PanelDeleteConflictException({
        meters,
        panelName: panelName || panelId,
      })
    } else {
      handleAxiosError(error)
    }
  }

// API
class API {
  static fetchPanelList(params: Record<string, any>) {
    const { siteId } = params

    if (isVariantActive('4095mock')) {
      return Promise.resolve(panelsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const query = makeApiQueryString(params)
    const baseUrl = `${consoleApiUrl()}/panels/site/${siteId}`
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }

  static fetchPanel({ id }: { id: string }) {
    const url = `${consoleApiUrl()}/panels/${id}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static addPanel(params: FTAddPanelAction) {
    const url = `${consoleApiUrl()}/panels`
    let body = {
      ...params,
    }
    body = filterEmptyValues(body)

    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static updatePanel(params: FTUpdatePanelAction) {
    const { id, ...fields } = params
    const url = `${consoleApiUrl()}/panels/${id}`
    const body = filterEmptyValues(fields)
    return axios
      .patch(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static deletePanel({ id, name = '' }: FTDeletePanelAction) {
    const url = `${consoleApiUrl()}/panels/${id}`
    return axios
      .delete(url, {
        headers: defaultHeaders(),
      })
      .then(() => ({
        id,
        success: true,
      }))
      .catch(
        handleDeletePanelAxiosError({
          panelId: id,
          panelName: name,
        }),
      )
  }

  static async uploadPhotos(
    photosData: FTPhotoObject[],
  ): Promise<Record<string, any>> {
    const url = `${consoleApiUrl()}/files/metadata`
    const filesData = []
    const fileBlobs = []
    const contentTypes = []

    if (photosData.length > 0) {
      photosData.forEach((photo) => {
        const preSignedUrlRequestData = {
          id: uuidv4(),
          objectType: photo.objectType,
          objectId: photo.objectId,
          fileName: photo.file.name,
        }
        filesData.push(preSignedUrlRequestData)
        fileBlobs.push(
          new Blob([photo.file], {
            type: photo.file.type,
          }),
        )
        contentTypes.push(photo.file.type)
      })
    }

    const { data } = await axios.post(url, filesData, {
      headers: defaultHeaders(),
    })

    if (data.length > 0) {
      const uploadPromises = []
      data.forEach((fileData, index) => {
        const { preSignedUrl } = fileData
        const raw = fileBlobs[index]
        const requestOptions = {
          headers: {
            'Content-Type': contentTypes[index],
          },
          method: 'PUT',
          body: raw,
          redirect: 'follow',
        }
        uploadPromises.push(fetch(preSignedUrl, requestOptions))
      })
      const uploadResponse = await Promise.all(uploadPromises)
      const updateStatusPromises = []
      uploadResponse.forEach((response, index) => {
        if (response.status !== 200) {
          throw new Error('Upload failed')
        }

        const fileId = data[index].id
        const tempPromise = axios.patch(
          `${consoleApiUrl()}/files/${fileId}/status`,
          {
            status: 'SUCCESS',
          },
          {
            headers: defaultHeaders(),
          },
        )
        updateStatusPromises.push(tempPromise)
      })
      return Promise.all(updateStatusPromises)
    }
    return 500
  }

  static async deletePhotos(
    photoIds: Array<string>,
  ): Promise<Record<string, any>> {
    const url = `${consoleApiUrl()}/files/metadata`

    return axios.delete(url, {
      headers: defaultHeaders(),
      data: photoIds,
    })
  }
}

function* fetchAllPanelsSaga(params: {
  siteId: string
}): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchPanelList, params)
    const payload = makeSagaPayload(response, entitySchema)
    yield put({
      type: types.FETCH_ALL_PANELS_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_ALL_PANELS_ERROR, e)
  }
}

function* fetchPanelListSaga(params: {
  siteId: string
  orderBy: FTSortable | null | undefined
}): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchPanelList, params)
    const payload = makeSagaPayload(response, entitySchema)
    yield put({
      type: types.FETCH_PANEL_LIST_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PANEL_LIST_ERROR, e)
  }
}

function* fetchPanelSaga(params: { id: string }): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchPanel, params)
    const payload = normalize([response], [entitySchema])
    yield put({
      type: types.FETCH_PANEL_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PANEL_ERROR, e)
  }
}

function* addPanelSaga({
  params,
}: {
  params: FTAddPanelAction
}): Generator<any, void, any> {
  try {
    const response: FTAddPanelSuccessResponse = yield call(API.addPanel, params)
    const payload = normalize([response], [entitySchema])
    if (params.opportunityId) {
      yield put({
        type: types.ADD_PANEL_SUCCESS,
        payload,
      })
    } else {
      yield put({
        type: types.ADD_PANEL_SUCCESS_NOCONFIG,
        payload,
      })
    }
  } catch (e) {
    yield handleSagaError(types.ADD_PANEL_ERROR, e)
  }
}

function* updatePanelSaga({
  params,
}: {
  params: FTUpdatePanelAction
}): Generator<any, void, any> {
  const { id } = params

  try {
    const response = yield call(API.updatePanel, params)
    const payload = normalize([response], [entitySchema])
    yield put({
      type: types.UPDATE_PANEL_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PANEL_ERROR, e, {
      id,
    })
  }
}

function* deletePanelSaga(
  params: FTDeletePanelAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.deletePanel, params)
    yield put({
      type: types.DELETE_PANEL_SUCCESS,
      payload,
    })
  } catch (e) {
    if (e.name && e.name === 'PanelDeleteConflict') {
      const { meters, panelName } = e
      yield put(
        modalActions.showModalPanelDeleteConflict({
          meters,
          panelName,
        }),
      )
    }

    yield handleSagaError(types.DELETE_PANEL_ERROR, e)
  }
}

function* setPanelSaga(params: FTSetPanelAction): Generator<any, void, any> {
  try {
    yield put({
      type: types.SET_PANEL_SUCCESS,
      payload: params.values,
    })
  } catch (e) {
    yield handleSagaError(types.SET_PANEL_ERROR, e)
  }
}

function* uploadPhotos({ payload }): Generator<any, void, any> {
  try {
    const photoResponse: any[] = yield call(API.uploadPhotos, [
      ...payload,
    ]) as any[]
    if (photoResponse === 500) {
      yield put({
        type: types.UPLOAD_PHOTOS_ERROR,
        error: 'Error uploading photo',
      })
    }
    const status = photoResponse.every((photo) => photo.status === 200)
    yield put({
      type: types.UPLOAD_PHOTOS_SUCCESS,
      payload: status,
    })
  } catch (e) {
    yield handleSagaError(types.UPLOAD_PHOTOS_ERROR, e)
  }
}

function* deletePhotos({ payload }): Generator<any, void, any> {
  try {
    const response = yield call(API.deletePhotos, payload)
    yield put({
      type: types.DELETE_PHOTOS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.DELETE_PHOTOS_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_ALL_PANELS, fetchAllPanelsSaga),
  takeLatest(types.FETCH_PANEL_LIST, fetchPanelListSaga),
  takeLatest(types.FETCH_PANEL, fetchPanelSaga),
  takeEvery(types.ADD_PANEL, addPanelSaga),
  takeEvery(types.UPDATE_PANEL, updatePanelSaga),
  takeEvery(types.DELETE_PANEL, deletePanelSaga),
  takeEvery(types.SET_PANEL, setPanelSaga),
  takeLatest(types.UPLOAD_PHOTOS, uploadPhotos),
  takeLatest(types.DELETE_PHOTOS, deletePhotos),
]
