import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import type { FTModalPanelForm2 } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import {
  actions as panelActions,
  type FTPanel,
  selectPanelListEntity,
} from '../../ducks/panels'
import Modal from '../Modal'
import FormikForm from './FormikForm'

const WrapperStyles = styled.div`
  .Modal {
    .box {
      margin: 2% auto;
      padding: 20px 16px;
    }

    .content {
      border: 1px solid #c6c6c6;
      padding: 20px 24px 24px;
    }
  }

  .header .title {
    margin-bottom: 20px;
  }
`
type FTProps = {
  addLoaded: boolean
  addLoading: boolean
  addPanel: Function
  dispatch: Function
  error: string
  goBack?: Function
  handleSuccess?: Function
  hideModal: Function
  updateLoaded: boolean
  updateLoading: boolean
  updatePanel: Function
  allPanels: Array<FTPanel>
} & FTModalPanelForm2

const ModalPanelForm2 = (props: FTProps) => {
  const {
    addPanel,
    addLoading,
    addLoaded,
    amperage,
    conditionalFields,
    description,
    error,
    goBack: goBackProp,
    handleSuccess,
    hideModal,
    id,
    meterIds,
    location,
    name,
    siteId,
    type,
    updateLoading,
    updateLoaded,
    updatePanel,
    voltage,
    parentPanelId,
    panelLevel,
    allPanels,
    panelsById,
  } = props

  const goBack = goBackProp || hideModal

  const loaded = id ? updateLoaded : addLoaded
  const loading = id ? updateLoading : addLoading
  const loadingRef = useRef(false)
  const { current: loadingPrev } = loadingRef

  useEffect(() => {
    if (loadingPrev && !loading && !error && loaded) {
      if (handleSuccess) {
        handleSuccess()
      }

      hideModal()
      return
    }

    loadingRef.current = loading
  })

  const getError = () => (loadingPrev && !loading ? error : '')

  const submitAction = (submission) => {
    if (id) {
      updatePanel(submission)
    } else {
      addPanel(submission)
    }
  }

  const renderContent = () => (
    <FormikForm
      conditionalFields={conditionalFields}
      description={description}
      id={id}
      meterIds={meterIds}
      error={getError()}
      name={name}
      siteId={siteId}
      submitAction={submitAction}
      goBack={goBack}
      type={type}
      voltage={voltage}
      amperage={amperage}
      location={location}
      parentPanelId={parentPanelId}
      panelLevel={panelLevel}
      panels={allPanels}
      panelsById={panelsById}
    />
  )

  return (
    <WrapperStyles>
      <Modal
        {...props}
        modalWidth='500px'
        onClose={goBack}
        renderContent={renderContent}
        title={id ? 'Edit Panel' : 'Add Panel'}
      />
    </WrapperStyles>
  )
}

ModalPanelForm2.defaultProps = {
  goBack: null,
  handleSuccess: null,
}

const mapStateToProps = (state) => ({
  ...selectPanelListEntity(state).meta,
  allPanels: selectPanelListEntity(state).allItems,
  panelsById: selectPanelListEntity(state).allById,
})

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({
  addPanel(values) {
    return dispatch(
      panelActions.setPanel({
        values,
      }),
    )
  },

  hideModal() {
    return dispatch(modalActions.hideModal())
  },

  updatePanel(values) {
    // Remove the derived and unnecessary fields from the request body
    // If panel type and voltage needs to be updated, pass 'type' and 'voltage' to updatePanel
    const {
      leftPanelOrder,
      rightPanelOrder,
      phasingPattern,
      numberOfCircuits,
      parentPanelName,
      siteId,
      meterIds,
      ...request
    } = values
    return dispatch(panelActions.updatePanel({ ...request }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalPanelForm2)
