/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { bindActionCreators } from 'redux'
import { CSVLink } from 'react-csv'
import features from '../../../authorization/features'
import FeatureValidator from '../../../authorization/components/FeatureValidator'
import FormSection from '../../../components/FormSection'
import Button from '../../../components/Button'
import Spinner from '../../../components/Spinner'
import ArrowUpwardIcon from '../../../components/Icons/ArrowUpwardIcon'
import {
  ArrowUpwardIconStyles,
  Col1Styles,
  DropzoneStyles,
  ErrorMessagesHeadingStyles,
  ErrorMessagesListHeadingStyles,
  ErrorMessagesListStyles,
  FilePathStyled,
  FormSectionInnerStyles,
  ReportProblemIconStyled,
  TryAgainStyles,
  UploadAssetRecordsListStyled,
} from '../LightingAsBuiltsManagerPage'
import {
  actions as uploadUtilityDataActions,
  selectUtilityUsageDataEntity,
} from '../../../ducks/utilityData/uploadUtilityUsageData'
import sampleInvoiceData from '../../../mockData/utilityData/sampleInvoiceFile'
import { ButtonsStyles } from './UploadModal'
import usePrevious from '../../../hooks/usePrevious'

const InvoiceComponent = (props) => {
  const { actions, error, invoiceDataUploadResponse, isUploading, onClose } =
    props
  const [invoiceFilename, setInvoiceFilename] = useState('')
  const downloadSampleInvoiceFileRef = React.useRef()
  const prevValue = usePrevious({
    isUploading,
  })

  const downloadSampleInvoiceFile = () =>
    downloadSampleInvoiceFileRef.current.link.click()

  const handleInvoiceData = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setInvoiceFilename(file.name)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {
    acceptedFiles: acceptedFilesInvoice,
    getInputProps: getInputPropsInvoice,
    getRootProps: getRootPropsInvoice,
    isDragAccept: isDragAcceptInvoice,
    isDragActive: isDragActiveInvoice,
    isDragReject: isDragRejectInvoice,
    open: openInvoice,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleInvoiceData,
  })

  const resetInvoiceForm = () => {
    setInvoiceFilename('')
    actions.resetInvoiceDataState()
  }

  useEffect(() => {
    if (prevValue && prevValue.isUploading && !isUploading && !error) {
      onClose({
        refetchList: true,
      })
    }
  }, [prevValue])
  return (
    <FeatureValidator feature={features.uploadInvoiceData}>
      <Col1Styles>
        <FormSection>
          <FormSectionInnerStyles>
            {!!error && (
              <>
                <ErrorMessagesHeadingStyles>
                  <ReportProblemIconStyled color='#C70D08' inline />
                  <b>
                    {invoiceFilename}
                    {' upload failed.'}
                  </b>
                </ErrorMessagesHeadingStyles>
                <ErrorMessagesListHeadingStyles>
                  No records were stored. Please fix the following errors and
                  try again:
                </ErrorMessagesListHeadingStyles>
                <ErrorMessagesListStyles>
                  <div>{error}</div>
                </ErrorMessagesListStyles>
                <TryAgainStyles>
                  <Button onClick={resetInvoiceForm} primary>
                    Reset
                  </Button>
                </TryAgainStyles>
              </>
            )}
            {!error && (
              <>
                <b>Upload Invoice Data</b>
                <UploadAssetRecordsListStyled>
                  <li>Files with invoices</li>
                  <li>
                    Download the sample file
                    <Link to='#' onClick={downloadSampleInvoiceFile}>
                      {' '}
                      here
                    </Link>
                  </li>
                  <CSVLink
                    data={sampleInvoiceData}
                    ref={downloadSampleInvoiceFileRef}
                    filename='invoice-and-usage-upload.csv'
                  />
                  <li>The upload file must be a .csv.</li>
                  <li>
                    Any rows with missing required fields will be skipped.
                  </li>
                  <li>
                    DO NOT CLOSE THIS WINDOW while upload is in progress,
                    otherwise you will lose access to the upload status.
                  </li>
                </UploadAssetRecordsListStyled>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <DropzoneStyles
                  {...getRootPropsInvoice({
                    isDragActiveInvoice,
                    isDragAcceptInvoice,
                    isDragRejectInvoice,
                  })}
                >
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <input name='file' {...getInputPropsInvoice()} />
                  {isUploading && (
                    <div>
                      <Spinner inline size='micro' />{' '}
                      <b>Upload in progress...</b>
                    </div>
                  )}
                  {!isUploading && (
                    <ArrowUpwardIconStyles>
                      <ArrowUpwardIcon />
                    </ArrowUpwardIconStyles>
                  )}
                  <FilePathStyled>
                    {invoiceFilename || 'Drag file to upload'}
                  </FilePathStyled>
                  {!invoiceFilename && (
                    <Button primary onClick={openInvoice}>
                      Choose File
                    </Button>
                  )}
                  {invoiceFilename &&
                    !isUploading &&
                    !invoiceDataUploadResponse && (
                      <ButtonsStyles>
                        <Button onClick={resetInvoiceForm}>Cancel</Button>
                        <Button
                          primary
                          onClick={() => {
                            actions.postInvoiceData({
                              file: acceptedFilesInvoice[0],
                            })
                          }}
                        >
                          Upload
                        </Button>
                      </ButtonsStyles>
                    )}
                </DropzoneStyles>
              </>
            )}
          </FormSectionInnerStyles>
        </FormSection>
      </Col1Styles>
    </FeatureValidator>
  )
}

const mapStateToProps = (state) => {
  const entity = selectUtilityUsageDataEntity(state)
  const { invoiceData } = entity
  const {
    uploadResponse: invoiceDataUploadResponse,
    meta: { error, isUploading },
  } = invoiceData
  return {
    error,
    isUploading,
    invoiceDataUploadResponse,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(uploadUtilityDataActions, dispatch) },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceComponent),
)
