import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import isEqual from 'lodash.isequal'
import { FormikProps, withFormik } from 'formik'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { RouteComponentProps } from 'react-router'
import Button from '../Button'
import FormFieldInteger from '../FormFieldInteger'
import StyledRadioButton from '../RadioButton'
import Spinner from '../Spinner'
import UnsavedChanges from '../UnsavedChanges'
import MeterConfig, {
  METER_BOILERPLATE,
  TSESElectricMeters,
  TSESGasMeters,
  TSMeterConfigProps,
} from './MeterConfig'
import Modal from '../Modal'
import { DropdownValues } from './staticData'
import {
  TSEnergyStarEntityState,
  TSEnergyStarSiteStatus,
} from '../../ducks/energyStarIntegration/types'
import { FTMeter } from '../../ducks/meters'
import { uniquePropertyTest } from '../SiteForm/validationSchema'

const FieldTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 16px;
`
const FormFieldWrapper = styled.div`
  width: 300px;
  .input__error {
    position: static;
  }
`
const RadioButtonTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 16px;
  margin-left: 8px;
  cursor: pointer;
`
const FieldValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
`
const FieldWrapper = styled.div`
  margin-bottom: 24px;
`
const RadioButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 30px;
`
const ButtonStyled = styled(Button)`
  margin-right: 20px;
  margin-top: 10px;
`
const ConfirmModalWrapper = styled.div`
  margin-left: -60px;
  .Modal {
    text-align: center;
  }
`
const ModalTitle = styled.p`
  font-weight: bold;
`

interface TSProps {
  energyStarEntity: TSEnergyStarEntityState
  onSubmit: (props: TSEnergyStarSiteStatus) => void
  onMeterDelete: (id: string) => void
  refreshSiteData: () => void
  meters: FTMeter[]
}

const reCheckRdpMeterIds = (
  data: TSEnergyStarSiteStatus,
  activeElectricMeters: TSESElectricMeters[],
) => {
  const newMeters = data.meters?.map((m) => {
    const tempMeter = { ...m }

    if (m.dataAggregation === 'meter' && m.resource === 'electricity') {
      tempMeter.rdpMeterId =
        activeElectricMeters?.find(({ id }) => id === tempMeter.selection)
          ?.rdpMeterId ?? null
    }

    if (m.meterAutoCreation) {
      tempMeter.energyStarId = null
    }

    return tempMeter
  })
  return { ...data, meters: newMeters }
}

const ESSiteStatus = (
  props: TSProps & FormikProps<TSEnergyStarSiteStatus> & RouteComponentProps,
) => {
  const {
    values,
    setFieldValue,
    handleReset,
    history,
    isValid,
    errors,
    initialValues,
    touched,
    refreshSiteData,
    energyStarEntity: {
      meta: { loading, error, entityId },
      siteStatus,
    },
    onSubmit,
    onMeterDelete,
    meters,
    location: { pathname },
    match: { url },
  } = props

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showModal, setShowModal] = useState<null | string>(null)
  const [meterErrors, setMeterErrors] = useState<string[]>([])
  const [selectedIndexToDelete, setSelectedIndexToDelete] = useState<
    null | number
  >(null)

  const hasChanged = () => !isEqual(initialValues, values)

  const waterMeterList = siteStatus.waterMeterList || []
  const activeElectricMeters: TSESElectricMeters[] = useMemo(
    () =>
      meters
        .filter(
          ({ active, verified, resource }) =>
            active && verified && resource === 'ELECTRICITY',
        )
        .map((em) => ({
          name: em.name,
          id: em.name,
          rdpMeterId: em.id,
        })),
    [meters],
  )

  const activeGasMeters: TSESGasMeters[] = useMemo(
    () =>
      meters
        .filter(({ active, resource }) => active && resource === 'NATURAL_GAS')
        .map((em) => ({
          name: em.name,
          id: em.name,
          rdpMeterId: em.id,
        })),
    [meters],
  )

  const errorMeterId = (entityId || 0).toString()
  const isEditMode = useMemo(
    () => pathname.split('/').reverse()[0] === 'edit',
    [pathname],
  )
  useEffect(() => {
    setIsSubmitting(false)
  }, [isEditMode])
  useEffect(() => {
    if (isEditMode && !values.meters) {
      setFieldValue('meters', [METER_BOILERPLATE])
    }
  }, [isEditMode])

  const handleSubmit = () => {
    setShowModal(null)
    setIsSubmitting(true)
    onSubmit(reCheckRdpMeterIds(values, activeElectricMeters))
  }

  const onDeleteClick = (index: number) => {
    setShowModal('delete')
    setSelectedIndexToDelete(index)
  }

  const handleDelete = () => {
    const meter = values.meters?.find((_, i) => i === selectedIndexToDelete)
    setFieldValue(
      'meters',
      values.meters?.filter((_, i) => i !== selectedIndexToDelete),
    )
    setShowModal(null)

    if (meter && meter.id) {
      onMeterDelete(meter.id)
    }
  }

  const dataAggregationWholeSiteCheck = () => {
    let hasError = false
    const enabledMeters = values.meters?.filter(({ enabled }) => enabled) ?? []
    const resourceTypes = DropdownValues.resource.map(({ id }) => id)
    resourceTypes.forEach((resourceType) => {
      const firstMeterIndex =
        values.meters?.findIndex(({ resource }) => resource === resourceType) ??
        -1

      if (firstMeterIndex !== -1) {
        const dataAggregationType =
          values.meters?.[firstMeterIndex].dataAggregation

        if (dataAggregationType === 'wholeSite') {
          const selectedMeters = enabledMeters.filter(
            ({ resource }) => resource === resourceType,
          )
          if (selectedMeters.length > 1) hasError = true
        }
      }
    })
    return hasError
  }

  const isMeterConfigValid = () => {
    const errorList = []

    if (dataAggregationWholeSiteCheck()) {
      errorList.push(
        'A site cannot have more than one whole site meter of the same resource type. ' +
          'Delete/Disable the existing meter before adding new.',
      )
    }

    if (errorList.length !== 0) {
      setShowModal('meterError')
      setMeterErrors(errorList)
      return false
    }

    return true
  }

  const handleOnSaveClick = () => {
    if (isMeterConfigValid()) setShowModal('save')
  }

  const renderModal = () => {
    let title = 'Are you sure you want to remove this meter?'
    let onAccept = handleDelete
    let renderBody = null

    if (showModal === 'save') {
      title = 'Are you sure you want to save these changes?'
      onAccept = handleSubmit
    }

    if (showModal === 'meterError') {
      renderBody = () => (
        <>
          <ModalTitle>
            Meter values are incorrect. Please correct the following issues.
          </ModalTitle>
          <ul
            style={{
              textAlign: 'left',
            }}
          >
            {meterErrors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        </>
      )

      onAccept = () => {
        setShowModal(null)
        setMeterErrors([])
      }
    }

    return (
      <ConfirmModalWrapper>
        <Modal
          title={title}
          acceptText={showModal === 'meterError' ? 'Ok' : 'Yes'}
          declineText={showModal !== 'meterError' ? 'No' : undefined}
          onDecline={() => setShowModal(null)}
          onAccept={onAccept}
          content={[]}
          renderBody={renderBody}
          modalWidth='420px'
          hideClose
        />
      </ConfirmModalWrapper>
    )
  }

  const renderMeterConfig = () => {
    if (!values.meters) return null
    let newProps: TSMeterConfigProps = {
      onDeleteClick,
      isEditMode,
      setFieldValue,
      values: values.meters,
      errors: errors.meters,
      loading,
      waterMeterList,
      electricMeters: activeElectricMeters,
      gasMeters: activeGasMeters,
    }

    if (isEditMode) {
      newProps = {
        ...newProps,
        errorMeterId:
          errorMeterId !== values.energyStarId?.toString() ?
            errorMeterId
          : undefined,
        errorMessage: error,
      }
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MeterConfig {...newProps} />
  }

  if (!isEditMode) {
    if (loading) {
      return <Spinner size='tiny' />
    }

    return (
      <>
        <FieldWrapper>
          <FieldTitle>Site Status</FieldTitle>
          <FieldValue>{values.enabled ? 'Enabled' : 'Disabled'}</FieldValue>
        </FieldWrapper>
        <FieldWrapper>
          <FieldTitle>Energy Star Property ID</FieldTitle>
          <FieldValue>{values.energyStarId}</FieldValue>
        </FieldWrapper>

        {values.meters && renderMeterConfig()}
      </>
    )
  }

  return (
    <>
      {showModal && renderModal()}
      <UnsavedChanges
        when={
          !loading &&
          hasChanged() &&
          (!isSubmitting || (isSubmitting && !!error))
        }
        confirmActions={() => {
          handleReset()
          refreshSiteData()
        }}
      />
      <FieldWrapper>
        <FieldTitle>Site Status</FieldTitle>
        <RadioButtonWrapper
          onClick={() => !loading && setFieldValue('enabled', true)}
        >
          <StyledRadioButton
            disabled={loading}
            name='customerStatus'
            checked={values.enabled}
          />
          <RadioButtonTitle>Enable</RadioButtonTitle>
        </RadioButtonWrapper>
        <RadioButtonWrapper
          onClick={() => !loading && setFieldValue('enabled', false)}
        >
          <StyledRadioButton
            name='customerStatus'
            disabled={loading}
            checked={!values.enabled}
          />
          <RadioButtonTitle>Disable</RadioButtonTitle>
        </RadioButtonWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <FieldTitle>Energy Star Property ID</FieldTitle>
        <FormFieldWrapper>
          <FormFieldInteger
            name='energyStarId'
            isReadOnly={loading}
            error={
              touched.energyStarId &&
              (errors.energyStarId ||
                (errorMeterId === values.energyStarId?.toString() && error))
            }
          />
        </FormFieldWrapper>
      </FieldWrapper>
      {renderMeterConfig()}
      <FieldWrapper>
        <ButtonStyled
          disabled={loading}
          onClick={() => {
            history.push(url)
          }}
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          loading={loading}
          disabled={!isValid || !hasChanged()}
          primary
          onClick={handleOnSaveClick}
        >
          Save
        </ButtonStyled>
      </FieldWrapper>
    </>
  )
}

// See https://github.com/jquense/yup/issues/345#issuecomment-634718990
Yup.addMethod(
  Yup.object,
  'uniqueProperties',
  function uniqueProperties(propertyNames: [string, string][]) {
    return this.test('unique', '', function unique(value) {
      if (value.energyStarId === undefined || value.energyStarId === null) {
        return true
      }

      const errors = propertyNames
        .map(([propertyName, message]) => {
          try {
            return uniquePropertyTest.call(this, value, propertyName, message)
          } catch (error) {
            return error
          }
        })
        .filter((error) => error instanceof Yup.ValidationError)

      if (!isEmpty(errors)) {
        throw new Yup.ValidationError(errors)
      }

      return true
    })
  },
)

const validationSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  energyStarId: Yup.number().required(
    'Please enter a valid energy star site ID',
  ),
  meters: Yup.array().of(
    Yup.object()
      .shape({
        enabled: Yup.boolean().required(),
        meterAutoCreation: Yup.boolean().nullable(),
        energyStarId: Yup.string().when('meterAutoCreation', {
          is: true,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required(),
        }),
        resource: Yup.string().required(),
        dataAggregation: Yup.string().required(),
        positive: Yup.string().required(),
        unit: Yup.string().required(),
        dataSource: Yup.string().required(),
        espmStartDate: Yup.string().nullable(),
        selection: Yup.string().required(),
        rdpMeterId: Yup.string().nullable(),
      })
      .uniqueProperties([['energyStarId', 'Energy star Id must be unique']]),
  ),
})
export default withRouter(
  withFormik<TSProps & RouteComponentProps, TSEnergyStarSiteStatus>({
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: ({ energyStarEntity: { siteStatus } }) => ({
      ...siteStatus,
      meters: siteStatus.meters?.map((m) => ({
        ...m,
        energyStarId: m.energyStarId?.toString() ?? null,
      })),
    }),
    handleSubmit: () => {},
  })(ESSiteStatus),
)
