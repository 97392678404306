import querystring from 'qs'
import {
  ERR_CONNECTIVITY,
  ERR_OTHER,
  ERR_REQUEST_ERROR,
  ERR_REQUEST_TIMEOUT,
  ERR_SERVER_ERROR,
  ERR_SESSION_TIMEOUT,
  ERR_UNAUTHORIZED,
} from '../constants/error'

const NETWORK_ERROR = 'Network Error'

export const handleAxiosError = (error: Record<string, any>) => {
  if (error.request && !error.request.status) {
    throw new Error(ERR_CONNECTIVITY)
  }

  if (error.response) {
    const resp = error.response

    if (resp.status === 403) {
      throw new Error(ERR_UNAUTHORIZED)
    } else if (resp.status === 401) {
      throw new Error(ERR_SESSION_TIMEOUT)
    } else if (resp.status === 504) {
      throw new Error(ERR_REQUEST_TIMEOUT)
    } else if (resp.status >= 500) {
      throw new Error(ERR_SERVER_ERROR)
    } else if (resp.data) {
      throw new Error(resp.data.messages || resp.data.message || ERR_OTHER)
    } else {
      throw new Error(ERR_OTHER)
    }
  } else if (error.request) {
    throw new Error(ERR_REQUEST_ERROR)
  } else if (error.message === NETWORK_ERROR) {
    throw new Error(ERR_SERVER_ERROR)
  } else {
    throw new Error(error.message)
  }
}

export const handleAxiosErrorCreateScenario = (error: Record<string, any>) => {
  if (error.response.status === 304) {
    throw new Error(error.response.status)
  }
  return handleAxiosError(error)
}
export const getTotalPages = (total: number, perPage: number) =>
  Math.ceil(total / perPage)
export const queryStringify = (params: Record<string, any>) => {
  const filtered: Record<string, string> = {}
  Object.keys(params).forEach((key) => {
    const value = params[key]

    if (value === null || value === undefined || value === '') {
      return
    }

    filtered[key] = params[key]
  })

  return querystring.stringify(filtered, { arrayFormat: 'repeat' })
}

export const parseUrlParams = (location: {
  search: string
}): Record<string, any> => {
  const qs = location && location.search ? location.search.split('?') : null
  let params = {}

  if (qs && qs.length === 2) {
    params = { ...querystring.parse(qs[1]) }
  }

  return params
}
