import moment from 'moment'
import styled from 'styled-components'
import * as consts from '../../../../constants'
import DesignLibraryPageWrapper from '../../DesignLibraryPageWrapper'
import DatePicker from '../../../../components/DatePicker'

const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/date-picker',
    text: 'Date Picker',
  },
]
const baseOptions = [
  {
    label: 'Last 7 Days',
    value: consts.LAST_7_DAYS,
  },
  {
    label: 'This Month',
    value: consts.THIS_MONTH,
  },
  {
    label: 'Last Month',
    value: consts.LAST_MONTH,
  },
  {
    label: 'Last 3 Months',
    value: consts.LAST_3_MONTHS,
  },
  {
    label: 'Last 6 Months',
    value: consts.LAST_6_MONTHS,
  },
  {
    label: 'Last 12 Months',
    value: consts.LAST_12_MONTHS,
  },
  {
    label: 'Year to Date',
    value: consts.YEAR_TO_DATE,
  },
  {
    label: 'All',
    value: consts.PROGRAM_TO_DATE,
  },
]
const MainContentStyled = styled.div`
  height: 500px;
  max-width: 1012px;
  margin-right: auto;
  margin-left: auto;
`

const DatePickerComponentPage = () => {
  const format = 'MMM D, YYYY'

  const renderLabel = () => {
    const { start, end } = {
      start: moment('20180620'),
      end: moment(),
    }
    const startDate = moment(start, consts.URL_DATE_FORMAT)
    const startText = startDate.format(format)
    let text = startText
    const endText =
      end ? moment(end, consts.URL_DATE_FORMAT).format(format) : ''

    if (end && endText !== startText) {
      text = `${text} - ${endText}`
    }

    return text
  }

  const getProgramStartDate = () => moment('20180620', consts.URL_DATE_FORMAT)

  return (
    <DesignLibraryPageWrapper breadcrumbs={breadcrumbs} Title='Date Picker'>
      <MainContentStyled>
        <DatePicker
          end={moment('2022-06-01')}
          options={baseOptions}
          period='THIS_MONTH'
          getProgramStartDate={getProgramStartDate}
          start={moment('2018-06-20')}
          text={renderLabel()}
          updateDateRange={null}
        />
      </MainContentStyled>
    </DesignLibraryPageWrapper>
  )
}

export default DatePickerComponentPage
