import moment from 'moment-timezone'
import { FormikProps } from 'formik'
import { useEffect } from 'react'
import CustomDateTimePicker from '../DateTimePicker'
import {
  FieldContainerStyled,
  PanelFieldValues,
  InputStyled,
  LabelStyled,
  LableSupportText,
  PageBodyStyled,
  PageHeaderStyled,
  TextAreaStyled,
} from './commons'

type StepProps = {
  formik: FormikProps<PanelFieldValues>
  initialAuditDate: Date
}
const AuditInfoForm = (props: StepProps) => {
  const { formik } = props
  const { initialAuditDate } = props
  const dateTime = `${moment(initialAuditDate)
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss')}Z`
  const setDate = (date: Date | null) => {
    const dateTime = `${moment(date).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`
    formik.setFieldValue('auditDate', dateTime)
    formik.setFieldTouched('auditDate', true)
  }
  useEffect(() => {
    formik.setFieldValue('auditDate', dateTime)
    formik.setFieldTouched('auditDate', true)
  }, [dateTime])
  return (
    <>
      <PageHeaderStyled>
        <p>Audit Info</p>
        <div />
      </PageHeaderStyled>

      <PageBodyStyled>
        <FieldContainerStyled>
          <LabelStyled>Auditor Name</LabelStyled>
          <LableSupportText>Enter the name of auditor</LableSupportText>
          <InputStyled
            name='auditorName'
            onChange={formik.handleChange}
            value={formik.values.auditorName}
            error={
              formik.touched.auditorName && formik.errors.auditorName ?
                formik.errors.auditorName
              : ''
            }
            onBlur={formik.handleBlur}
          />
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>Survey Date</LabelStyled>
          <LableSupportText>
            Put in the date when the survey was conducted
          </LableSupportText>
          <CustomDateTimePicker
            value={formik.values.auditDate}
            setDate={setDate}
            customCalendarIcon={null}
          />
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>Notes</LabelStyled>
          <LableSupportText>
            Put in the the notes taken during the audit
          </LableSupportText>
          <TextAreaStyled
            name='auditNotes'
            onChange={formik.handleChange}
            value={formik.values.auditNotes}
            error={
              formik.touched.auditNotes && formik.errors.auditNotes ?
                formik.errors.auditNotes
              : ''
            }
            onBlur={formik.handleBlur}
            placeholder='Notes....'
          />
        </FieldContainerStyled>
      </PageBodyStyled>
    </>
  )
}

export default AuditInfoForm
