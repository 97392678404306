import { ReactNode, SyntheticEvent } from 'react'
import Styled from './styles'

type UploadProps = {
  children: ReactNode
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void
}

const UploadButton = ({ children, onChange }: UploadProps) => (
  <Styled.Button>
    <input type='file' onChange={onChange} />
    {children}
  </Styled.Button>
)

export default UploadButton
