import { useState, useEffect } from 'react'
import styled from 'styled-components'
import FilterDropdown from '../FilterDropdown'
import { METER_RESOURCE_TYPES } from '../../ducks/meters/generation'
import RadioButton from '../RadioButton'

type FTProps = {
  onSelect: (...args: Array<any>) => any
  previous: Record<string, any>
  filterField: string
}
const DropdownMenuItemStyled = styled.div`
  padding: 5px;
`
const DropdownMenuItemHeaderStyled = styled.div`
  font-weight: 600;
  margin-bottom: 9px;
`
const DropdownMenuItemBodyStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 9px;
  column-gap: 9px;
  padding-left: 1px;
  align-items: center;
`
const StyledRadioButton = styled(RadioButton)`
  padding-top: 1px;
  & .rc-checkbox-inner {
    box-shadow: none;
  }
  &.rc-checkbox-checked .rc-checkbox-inner {
    box-shadow: none;
  }
`
const meterProperties = {
  resource: {
    displayName: 'Resource Type',
    options: {
      electric: {
        displayName: 'Electricity',
        apiName: 'ELECTRICITY',
      },
      gas: {
        displayName: 'Natural Gas',
        apiName: 'NATURAL_GAS',
      },
    },
  },
}

const MetersDropdown = (props: FTProps) => {
  const { onSelect, filterField, previous } = props
  const [selectedItems, setSelectedItems] = useState([])
  useEffect(() => {
    const { values = [] } = previous || {}
    setSelectedItems(values)
  }, [previous])

  const handleChange = (e, item) => {
    if (e.target.checked) {
      const newSelectedItems = Array.isArray(item) ? [...item] : [item]
      onSelect({
        field: filterField,
        values: newSelectedItems,
      })
      setSelectedItems(newSelectedItems)
    }
  }

  const Options = () => (
    <div>
      {Object.keys(meterProperties).map((category) => (
        <DropdownMenuItemStyled key={category}>
          <DropdownMenuItemHeaderStyled>
            {meterProperties[category].displayName}
          </DropdownMenuItemHeaderStyled>
          <DropdownMenuItemBodyStyled key='Any'>
            <StyledRadioButton
              checked={METER_RESOURCE_TYPES.every((item) =>
                selectedItems.includes(item),
              )}
              onChange={(e) => handleChange(e, METER_RESOURCE_TYPES)}
            />
            <div>Any</div>
          </DropdownMenuItemBodyStyled>
          {Object.keys(meterProperties[category].options).map((key) => {
            const { apiName, displayName } =
              meterProperties[category].options[key]
            return (
              <DropdownMenuItemBodyStyled key={key}>
                <StyledRadioButton
                  checked={
                    selectedItems.includes(apiName) &&
                    selectedItems.length === 1
                  }
                  onChange={(e) => handleChange(e, apiName)}
                />
                <div>{displayName}</div>
              </DropdownMenuItemBodyStyled>
            )
          })}
        </DropdownMenuItemStyled>
      ))}
    </div>
  )

  return (
    <FilterDropdown
      label='Resource'
      Options={Options}
      itemsSelected={selectedItems.length}
    />
  )
}

export default MetersDropdown
