import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { zIndices } from '../../utils'
import { colors } from '../../utils/themes'
import {
  selectors,
  actions as configHistoryActions,
} from '../../ducks/configHistory'
import Spinner from '../Spinner'
import MeterConfigList from './MeterConfigList'
import MeterConfigDetail from './MeterConfigDetail'

const ModalStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};

  * {
    box-sizing: border-box;
  }
`
const ModalContentWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`
const ModalContentStyled = styled.div`
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.16) 0%,
      rgba(224, 224, 224, 0.16) 100%
    ),
    #fff;
  border: 1px solid #dadce0;
  position: relative;
  width: 934px;
  display: flex;
  flex-direction: column;
  padding: 12px 0 0;
  position: relative;
  height: 100vh;
`
const ModalHeaderStyled = styled.div`
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: none none solid;
  border-color: #dadce0;
  border-width: 0px, 0px, 1px, 0px;
  div {
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: #6c6d6e;
      text-transform: uppercase;
      margin: 0px;
      padding: 0px;
    }
    p {
      font-family: Public Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #162447;
      margin: 4px 0;
      padding: 0px;
    }
  }
`
const ModalBodyStyled = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0px 30px;
`
const BackButtonStyled = styled.div`
  span {
    font-size: 24px;
    color: #000;
  }
`

const BackTextStyled = styled.div`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 600;
  color: #337ab7;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoadingContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const FooterStyled = styled.div`
  height: 55px;
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 2px 0px 4px 0px #0000002e;

  z-index: 1;
`
const FooterButtonStyled = styled.button`
  background: transparent;
  border: 0px none transparent;
  font-family: Public Sans;
  font-size: 12px;
  font-weight: 600;
  color: #6C6D6E
  cursor: pointer;
`
export type FTModalConfigHistoryProps = {
  meterId: string
  siteTimezone: string
  currentVersionDetails: Record<string, any>
  previousVersionDetails: Record<string, any>
  closeModal: (...args: Array<any>) => any
}

const MeterConfigHistoryModal = (props: FTModalConfigHistoryProps) => {
  const {
    meterId,
    siteTimezone,
    closeModal,
    configHistoryData,
    loading,
    currentVersionDetails,
    previousVersionDetails,
    actions: {
      fetchMeterConfigHistory,
      fetchMeterConfigComparison,
      updateQueryParams,
    },
  } = props
  const [onlyMeterDataImpacted, setOnlyMeterDataImpacted] = useState(false)
  const [activeVersion, setActiveVersion] = useState(null)
  const { metadata, results } = configHistoryData
  let latestVersion = null

  const fetchListData = useCallback(
    (pageSize, pageNumber, isMeterDataImpacted) => {
      fetchMeterConfigHistory({
        meterId,
        onlyMeterDataImpacted: isMeterDataImpacted,
        pageSize,
        pageNumber,
      })
    },
    [],
  )

  if (results && results.length > 0) {
    latestVersion = results[0].version
  }

  const incrementActiveVersion = () => {
    const nextVersion = activeVersion + 1
    setActiveVersion(nextVersion)
  }

  const decrementActiveVersion = () => {
    const previousVersion = activeVersion - 1
    setActiveVersion(previousVersion)
  }

  useEffect(() => {
    fetchMeterConfigHistory({
      meterId,
      onlyMeterDataImpacted,
      pageSize: 20,
      pageNumber: 1,
    })
  }, [onlyMeterDataImpacted])
  useEffect(() => {
    if (activeVersion) {
      fetchMeterConfigComparison({
        meterId,
        activeVersion,
      })
    }
  }, [activeVersion])

  const handleCloseModal = () => {
    updateQueryParams({
      pageSize: 20,
      pageNumber: 1,
      onlyMeterDataImpacted: false,
    })
    closeModal()
  }
  return (
    <ModalStyled>
      <ModalContentWrapperStyled>
        <ModalContentStyled>
          {loading && (
            <LoadingContainerStyled>
              <Spinner size='medium' />
            </LoadingContainerStyled>
          )}
          {!loading && (
            <>
              <ModalHeaderStyled>
                <div>
                  <h3>Configuration History</h3>
                  <p>{metadata.macAddress}</p>
                  <p>{metadata.siteName}</p>
                </div>
                {!!activeVersion && (
                  <BackTextStyled onClick={() => setActiveVersion(null)}>
                    <span className='material-icons'>arrow_back_ios</span>
                    Configuration History
                  </BackTextStyled>
                )}
                {!activeVersion && (
                  <BackButtonStyled>
                    <span
                      onClick={() => handleCloseModal()}
                      className='ion-android-close'
                    />
                  </BackButtonStyled>
                )}
              </ModalHeaderStyled>
              <ModalBodyStyled>
                {!activeVersion && (
                  <MeterConfigList
                    siteTimezone={siteTimezone}
                    results={results}
                    onlyMeterDataImpacted={onlyMeterDataImpacted}
                    setOnlyMeterDataImpacted={setOnlyMeterDataImpacted}
                    setActiveVersion={setActiveVersion}
                    fetchMeterConfigHistory={fetchListData}
                  />
                )}
                {!!activeVersion && (
                  <MeterConfigDetail
                    siteTimezone={siteTimezone}
                    currentVersionDetails={currentVersionDetails}
                    previousVersionDetails={previousVersionDetails}
                    setActiveVersion={setActiveVersion}
                  />
                )}
              </ModalBodyStyled>
            </>
          )}
          {activeVersion && (
            <FooterStyled>
              {activeVersion >= 2 ?
                <FooterButtonStyled onClick={() => decrementActiveVersion()}>
                  Previous
                </FooterButtonStyled>
              : <FooterButtonStyled />}
              {activeVersion < latestVersion && (
                <FooterButtonStyled onClick={() => incrementActiveVersion()}>
                  Next
                </FooterButtonStyled>
              )}
            </FooterStyled>
          )}
        </ModalContentStyled>
      </ModalContentWrapperStyled>
    </ModalStyled>
  )
}

MeterConfigHistoryModal.propTypes = {
  meterId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  configHistoryData: PropTypes.shape(),
  currentVersionDetails: PropTypes.shape(),
  previousVersionDetails: PropTypes.shape(),
  actions: PropTypes.shape().isRequired,
}

const mapStateToProps = (state) => ({
  configHistoryData: selectors.getData(state),
  currentVersionDetails: selectors.getCurrentVersionDetails(state),
  previousVersionDetails: selectors.getPreviousVersionDetails(state),
  loading: selectors.getLoading(state),
  error: selectors.getError(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(configHistoryActions, dispatch) },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MeterConfigHistoryModal),
)
