import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as authSelectors } from '../../ducks/auth'

function withPermissions(WrappedComponent) {
  return (props) => <WrappedComponent {...props} />
}

export default compose(
  connect((state) => ({
    permissions: authSelectors.selectPermissions(state),
  })),
  withPermissions,
)
