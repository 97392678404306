import { useState } from 'react'
import styled from 'styled-components'
import DesignLibraryPageWrapper from '../../DesignLibraryPageWrapper'
import RedaptiveReactTable7, {
  SelectColumnMultiFilter,
} from '../../../../components/RedaptiveReactTable7'
import mockData from './mockData'
import Button2, { ButtonStyled } from '../../../../components/Button2'
import StyledLink from '../../../../components/StyledLink'
import Checkbox from '../../../../components/Checkbox'

const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/table',
    text: 'Table',
  },
]
const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    Filter: SelectColumnMultiFilter,
    filter: 'includesSome',
  },
  {
    accessor: 'species',
    Header: 'Species',
  },
  {
    accessor: 'description',
    Header: 'Description',
  },
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
  },
  {
    accessor: 'address',
    disableFilters: true,
    Header: 'Address',
  },
  {
    accessor: 'email',
    Cell: ({ value }: { value: string }) => (
      <StyledLink external href={`mailto:${value}`}>
        <b>{value}</b>
      </StyledLink>
    ),
    Header: 'EMail',
  },
]
const HeaderActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ButtonStyled} {
    margin: 0 16px 0 0;
    &:last-child {
      margin: 0;
    }
  }
`

const HeaderActions = () => (
  <HeaderActionsStyled>
    <Button2 type='primary'>Header Action 1</Button2>
    <Button2 type='secondary'>Header Action 2</Button2>
  </HeaderActionsStyled>
)

const TableComponentPage = () => {
  const [hideHeaders, setHideHeaders] = useState(false)
  const initialHiddenColumns = ['email']
  return (
    <DesignLibraryPageWrapper breadcrumbs={breadcrumbs} Title='Table'>
      <>
        <Checkbox
          checked={hideHeaders}
          onChange={({ target: { checked } }) => {
            setHideHeaders(checked)
          }}
        />
        Hide Table Headers
      </>
      <RedaptiveReactTable7
        columns={columns}
        data={mockData}
        enableColumnSettings
        enablePagination
        filterable
        enableRowSelection
        enableColumnHiding
        initialHiddenColumns={initialHiddenColumns}
        HeaderActions={HeaderActions}
        hideHeaders={hideHeaders}
      />
    </DesignLibraryPageWrapper>
  )
}

export default TableComponentPage
