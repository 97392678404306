import styled from 'styled-components'
import Description from '../Description'
import PendingIcon from '../Icons/PendingIcon'
import { colors } from '../../utils/themes'

const ModalPendingIcon = styled(PendingIcon)`
  font-size: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`
const ModalTitle = styled.div`
  font-weight: 600;
  line-height: 20px;
`
const ModalDescription = styled(Description)`
  ul {
    padding-left: 25px;
  }

  li + li {
    margin-top: 15px;
  }
`
const WarningListItem = styled.li`
  color: ${colors.red};
`

const configPendingTitle = 'Your meter configuration changes are now pending.'
const configConfirmTitle =
  'Are you sure you want to change the meter configuration?'

const EVED_WARNING_MESSAGE = `Warning: This meter has already been Electron Verified. Changing the
          configuration of this meter may result in changes to customer-facing
          data.`

const renderPendingNotice = () => (
  <>
    <ModalPendingIcon />
    <ModalTitle>{configPendingTitle}</ModalTitle>
    <ModalDescription>
      <ul>
        <li>Please check back in 24 - 48 hours to verify your changes</li>
      </ul>
    </ModalDescription>
  </>
)

const renderPendingOfflineNotice = () => (
  <>
    <ModalPendingIcon />
    <ModalTitle>
      {'Your meter configuration changes are now pending, however the ' +
        'meter is offline.'}
    </ModalTitle>
    <ModalDescription>
      <ul>
        <li>
          The meter will update with the new configuration values the next time
          it connects to the network.
        </li>
      </ul>
    </ModalDescription>
  </>
)

const renderNebulaPendingNotice = () => (
  <>
    <ModalPendingIcon />
    <ModalTitle>{configPendingTitle}</ModalTitle>
    <ModalDescription>
      <ul>
        <li>Please check back in 3 - 5 minutes to verify your changes.</li>
      </ul>
    </ModalDescription>
  </>
)

const renderConfirm = () => (
  <>
    <ModalTitle>{configConfirmTitle}</ModalTitle>
    <ModalDescription>
      <ul>
        <li>
          It may take 24 - 48 hours for the meter to begin operating with the
          new settings
        </li>
        {/* TODO: Uncomment once RDP-5092 is completed */}
        {/* <li> */}
        {/* {'All data collected by this meter to date will be adjusted after the ' +*/}
        {/* 'new settings take effect'} */}
        {/* </li> */}
      </ul>
    </ModalDescription>
  </>
)

const renderEVdConfirm = () => (
  <>
    <ModalTitle>{configConfirmTitle}</ModalTitle>
    <ModalDescription>
      <ul>
        <WarningListItem>
          {EVED_WARNING_MESSAGE}
        </WarningListItem>
        <li>
          It may take 24 - 48 hours for the meter to begin operating with the
          new settings
        </li>
        {/* TODO: Uncomment once RDP-5092 is completed */}
        {/* <li> */}
        {/* {'All data collected by this meter to date will be adjusted after the ' +*/}
        {/* 'new settings take effect'} */}
        {/* </li> */}
      </ul>
    </ModalDescription>
  </>
)

const renderNebulaOrionConfirm = () => (
  <>
    <ModalTitle>{configConfirmTitle}</ModalTitle>
    <ModalDescription>
      <ul>
        <li>
          {'If the meter is currently online, it will reboot and begin operating with the new configuration within ' +
            'a few minutes.'}
        </li>
        <li>
          {'If the meter is currently offline, it will begin operating with the new configuration the next time it is ' +
            'powered on and has a network connection.'}
        </li>
        <li>
          Please verify the channel tags if any of the circuit breaker mapping
          has changed.
        </li>
      </ul>
    </ModalDescription>
  </>
)

const renderNebulaOrionEVdConfirm = () => (
  <>
    <ModalTitle>{configConfirmTitle}</ModalTitle>
    <ModalDescription>
      <ul>
        <WarningListItem>
          {EVED_WARNING_MESSAGE}
        </WarningListItem>
        <li>
          {'If the meter is currently online, it will reboot and begin operating with the new configuration within ' +
            'a few minutes.'}
        </li>
        <li>
          {'If the meter is currently offline, it will begin operating with the new configuration the next time it is ' +
            'powered on and has a network connection.'}
        </li>
        <li>
          Please verify the channel tags if any of the circuit breaker mapping
          has changed.
        </li>
      </ul>
    </ModalDescription>
  </>
)

const renderAutoFillConfirm = () => (
  <>
    <ModalTitle>
      Are you sure you want to apply the selected configuration?
    </ModalTitle>
    <ModalDescription>
      <ul>
        <li>
          Applying the selected configuration may override the edits you have
          already made to this form.
        </li>
      </ul>
    </ModalDescription>
  </>
)

const renderResetFormConfirm = () => (
  <>
    <ModalTitle>Are you sure you want to reset circuit breakers?</ModalTitle>
    <ModalDescription />
  </>
)

export default {
  renderConfirm,
  renderEVdConfirm,
  renderNebulaOrionConfirm,
  renderNebulaOrionEVdConfirm,
  renderPendingNotice,
  renderNebulaPendingNotice,
  renderPendingOfflineNotice,
  renderAutoFillConfirm,
  renderResetFormConfirm,
}
