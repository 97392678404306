import styled from 'styled-components'
import IonIcon from './IonIcon'

const Styles = styled.div`
  & .IonIcon {
    width: 20px;
  }
  & .IonIcon > span {
    color: ${({ isYes }) => (isYes ? '#0193d7' : '#c70d08')};
    top: 0;
  }
`
const Display = styled.span`
  margin-left: 20px;
`
type FTProps = {
  isYes: boolean
  label?: string
}

const CheckedLabel = (props: FTProps) => {
  const { isYes, label } = props
  const display = label || (isYes ? 'Yes' : 'No')
  return (
    <Styles isYes={isYes}>
      {isYes && <IonIcon iconClass='ion-checkmark' />}
      {!isYes && <IonIcon iconClass='ion-close' />}
      <Display>{display}</Display>
    </Styles>
  )
}

CheckedLabel.defaultProps = {
  label: '',
}
export default CheckedLabel
