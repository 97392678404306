import { $Values } from 'utility-types'
import { takeEvery, put, call, take, race, delay } from 'redux-saga/effects'
import axios from 'axios'
import FileSaver from 'file-saver'
import { consoleApiUrl, defaultHeaders } from '../api'
import { makeActionTypes } from './utils'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { ERR_OTHER } from '../constants/error'
import type { FTSaga, FTErrorAction } from '../types'
import '../types'

export type FTMeterExportState = {
  loading: boolean
  downloaded: boolean
  error: string | null | undefined
  job: {
    id: string | null | undefined
    size: number | null | undefined
    progress: number | null | undefined
    etag: string | null | undefined
    contentType: string | null | undefined
    status: 'SUCCESS' | 'INPROGRESS' | 'ERROR' | 'UNKNOWN' | null
  }
}
export const JOB_TYPES = Object.freeze({
  allSites: 'customerreport',
  oneMinute: 'one-minute-meter-data-report',
  singleSite: 'report',
})
export type FTSubmitJob = {
  siteId: string
  startDate: string
  endDate: string
  jobType: $Values<typeof JOB_TYPES>
  measurementTypes: string
}
export type FTGetJob = {
  jobId: string
}
export type FTGetJobStatusResponse = {
  size: string
  progress: string
  etag: string
  contentType: string
  status: string
}
export type FTGetJobResults = {
  jobId: string
  filename?: string
}
// Action Types
export const types = {
  ...makeActionTypes('SUBMIT_JOB'),
  ...makeActionTypes('GET_JOB_STATUS'),
  GET_JOB_STATUS_UPDATE: 'GET_JOB_STATUS_UPDATE',
  ...makeActionTypes('GET_JOB_RESULTS'),
  CLEAR_JOB: 'CLEAR_JOB',
}
export const initialState = {
  loading: false,
  downloaded: false,
  error: null,
  job: {
    id: null,
    size: null,
    progress: null,
    etag: null,
    contentType: null,
    status: null,
  },
}
// Action Creators
export const actions = {
  submitJob: (payload: FTSubmitJob) => ({
    type: types.SUBMIT_JOB,
    ...payload,
  }),
  submitJobSuccess: (payload: FTGetJobStatusResponse) => ({
    type: types.SUBMIT_JOB_SUCCESS,
    payload,
  }),
  submitJobError: (payload: FTErrorAction) => ({
    type: types.SUBMIT_JOB_ERROR,
    payload,
  }),
  getJobStatus: (payload: FTGetJob) => ({
    type: types.GET_JOB_STATUS,
    ...payload,
  }),
  getJobStatusSuccess: (payload: FTGetJobStatusResponse) => ({
    type: types.GET_JOB_STATUS_SUCCESS,
    payload,
  }),
  getJobStatusUpdate: (payload: FTGetJobStatusResponse) => ({
    type: types.GET_JOB_STATUS_UPDATE,
    payload,
  }),
  getJobStatusError: (payload: FTErrorAction) => ({
    type: types.GET_JOB_STATUS_ERROR,
    payload,
  }),
  getJobResults: (payload: FTGetJobResults) => ({
    type: types.GET_JOB_RESULTS,
    ...payload,
  }),
  getJobResultsSuccess: (payload: FTGetJobStatusResponse) => ({
    type: types.GET_JOB_RESULTS_SUCCESS,
    payload,
  }),
  getJobResultsError: (payload: FTErrorAction) => ({
    type: types.GET_JOB_RESULTS_ERROR,
    payload,
  }),
  clearJob: () => ({
    type: types.CLEAR_JOB,
  }),
}
export default function reducer(
  state: FTMeterExportState = initialState,
  action: Record<string, any>,
) {
  switch (action.type) {
    case types.SUBMIT_JOB:
      return {
        ...state,
        loading: true,
        downloaded: false,
        job: initialState.job,
      }

    case types.SUBMIT_JOB_ERROR:
      return { ...state, loading: false, error: action.payload.error }

    case types.SUBMIT_JOB_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        job: { ...state.job, ...action.payload },
      }

    case types.GET_JOB_STATUS:
      return { ...state, loading: true }

    case types.GET_JOB_STATUS_ERROR:
      return { ...state, loading: false, error: action.payload.error }

    case types.GET_JOB_STATUS_SUCCESS:
    case types.GET_JOB_STATUS_UPDATE:
      return {
        ...state,
        error: null,
        loading: false,
        job: { ...state.job, ...action.payload },
      }

    case types.GET_JOB_RESULTS:
      return { ...state, loading: true }

    case types.GET_JOB_RESULTS_ERROR:
      return { ...state, loading: false, error: action.payload.error }

    case types.GET_JOB_RESULTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        downloaded: true,
        job: { ...state.job, ...action.payload },
      }

    case types.CLEAR_JOB:
      return initialState

    default:
      return state
  }
} // Utils

export const utils = {
  getStatusHeaders: (headers: Record<string, any>) => {
    const filtered = {}
    Object.keys(headers)
      .filter((k) => k.toLowerCase().startsWith('job-'))
      .forEach((k) => {
        const value = headers[k]
        filtered[k.toLowerCase().replace('job-', '')] = value
      })
    return filtered
  },
}
// API
export class API {
  static submitJob({
    siteId: site,
    startDate: start,
    endDate: end,
    jobType,
    measurementTypes,
  }: FTSubmitJob) {
    const url = `${consoleApiUrl()}/admin/jobs`
    let body = {
      job: jobType,
      start,
      end,
      measurementTypes,
    }

    // Only include site if provided, otherwise is All Customers, All Sites
    if (site) {
      body = { ...body, site }
    }

    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static getJobStatus({ jobId }: FTGetJob) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return axios
      .head(url, {
        headers: defaultHeaders(),
      })
      .then(({ headers }) => ({ ...utils.getStatusHeaders(headers) }))
      .catch(handleAxiosError)
  }

  static getJobResults({ jobId, filename }: FTGetJobResults) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    const name = filename || `meter-data-${jobId}`
    return axios
      .get(url, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => FileSaver.saveAs(response.data, `${name}.zip`))
      .catch(handleAxiosError)
  }
}

// Sagas
function* submitJobSaga(payload): FTSaga {
  try {
    const response = yield call(API.submitJob, payload)
    yield put(actions.submitJobSuccess(response))
    yield delay(500)
    yield put(
      actions.getJobStatus({
        jobId: response.id,
      }),
    )
  } catch (error) {
    yield handleSagaError(actions.submitJobError, error)
  }
}

function* getJobStatusSaga(payload): FTSaga {
  while (true) {
    try {
      const response = yield call(API.getJobStatus, payload)

      if (response.status === 'SUCCESS') {
        yield put(actions.getJobStatusSuccess(response))
      } else if (response.status === 'ERROR') {
        yield handleSagaError(
          actions.getJobStatusError,
          new Error(response.reason || ERR_OTHER),
        )
      } else {
        yield put(actions.getJobStatusUpdate(response))
      }

      yield delay(5 * 1000)
    } catch (error) {
      yield handleSagaError(actions.getJobStatusError, error)
    }
  }
}

export function* pollJobStatusSaga(): FTSaga {
  while (true) {
    const payload = yield take(types.GET_JOB_STATUS)
    yield race([
      take(types.CLEAR_JOB),
      call(getJobStatusSaga, payload),
      take(types.GET_JOB_STATUS_SUCCESS),
      take(types.GET_JOB_STATUS_ERROR),
    ])
  }
}

function* getJobResultsSaga(payload): FTSaga {
  try {
    const response = yield call(API.getJobResults, payload)
    yield put(actions.getJobResultsSuccess(response))
  } catch (error) {
    yield handleSagaError(actions.getJobResultsError, error)
  }
}

export const sagas = [
  takeEvery(types.SUBMIT_JOB, submitJobSaga),
  takeEvery(types.GET_JOB_RESULTS, getJobResultsSaga),
]
