import axios from 'axios'
import { schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError, queryStringify } from '../../api/utils'
import {
  meterInstallSubmissionDetails as meterInstallSubmissionDetailsMockData,
  meterInstallSubmissionPhotos as meterInstallSubmissionPhotosMockData,
} from '../../mockData/meterInstallSubmissions/meterInstallSubmissionDetailsMockData'
import { handleSagaError } from '../../sagas/utils'
import '../../types/meterInstallSubmissions/MeterInstallSubmissionDetailsTypes'
import type {
  FTMeterInstallSubmissionDetailsResponse,
  FTMeterInstallSubmissionPhoto,
} from '../../types/meterInstallSubmissions/MeterInstallSubmissionDetailsTypes'
import { isVariantActive } from '../../utils'

export type FTFetchMeterInstallSubmissionDetailsAction = {
  meterSubmissionId: string
}
export type FTFetchMeterInstallSubmissionPhotosAction = {
  macAddress: string
  jobCode: string
  userId: string
}
export type FTMeterInstallSubmissionDetailsMetaState = {
  error: string
  loading: boolean
}
export type FTMeterInstallSubmissionPhotosMetaState = {
  error: string
  loading: boolean
}
export type FTMeterInstallSubmissionDetails =
  FTMeterInstallSubmissionDetailsResponse
export type FTMeterInstallSubmissionPhotos =
  Array<FTMeterInstallSubmissionPhoto>
export type FTMeterInstallSubmissionDetailsEntityState = {
  details: FTMeterInstallSubmissionDetails
  detailsMeta: FTMeterInstallSubmissionDetailsMetaState
  photos: FTMeterInstallSubmissionPhotos
  photosMeta: FTMeterInstallSubmissionPhotosMetaState
}
type FTState = {
  entities: {
    meterInstallSubmissionDetails: FTMeterInstallSubmissionDetailsEntityState
  }
}
export const types = {
  FETCH_METER_INSTALL_SUBMISSION_DETAILS:
    'FETCH_METER_INSTALL_SUBMISSION_DETAILS',
  FETCH_METER_INSTALL_SUBMISSION_DETAILS_SUCCESS:
    'FETCH_METER_INSTALL_SUBMISSION_DETAILS_SUCCESS',
  FETCH_METER_INSTALL_SUBMISSION_DETAILS_ERROR:
    'FETCH_METER_INSTALL_SUBMISSION_DETAILS_ERROR',
  FETCH_METER_INSTALL_SUBMISSION_PHOTOS:
    'FETCH_METER_INSTALL_SUBMISSION_PHOTOS',
  FETCH_METER_INSTALL_SUBMISSION_PHOTOS_SUCCESS:
    'FETCH_METER_INSTALL_SUBMISSION_PHOTOS_SUCCESS',
  FETCH_METER_INSTALL_SUBMISSION_PHOTOS_ERROR:
    'FETCH_METER_INSTALL_SUBMISSION_PHOTOS_ERROR',
  RESET_METER_INSTALL_SUBMISSION_STATE: 'RESET_METER_INSTALL_SUBMISSION_STATE',
}
export const actions = {
  fetchMeterInstallSubmissionDetails: (
    props: FTFetchMeterInstallSubmissionDetailsAction,
  ) => ({
    type: types.FETCH_METER_INSTALL_SUBMISSION_DETAILS,
    ...props,
  }),
  fetchMeterInstallSubmissionPhotos: (
    props: FTFetchMeterInstallSubmissionPhotosAction,
  ) => ({
    type: types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS,
    ...props,
  }),
  resetMeterInstallSubmissionState: () => ({
    type: types.RESET_METER_INSTALL_SUBMISSION_STATE,
  }),
}
export const utils = {
  breakerTypes: {
    SINGLE_POLE_BREAKER: 'Single Pole',
    TWO_POLE_SAME_BREAKER: 'Two Pole Ganged',
    TWO_POLE_DIFFERENT_BREAKER: 'Two Pole',
    THREE_POLE_SAME_BREAKER: 'Three Pole Ganged',
    THREE_POLE_DIFFERENT_BREAKER: 'Three Pole',
    EMPTY_SLOT: 'Empty',
    TANDEM_BREAKER: 'Tandem',
    DOUBLE_TAP_BREAKER: 'Double Tap',
    Switchboard: 'Switch board',
  },
  panelConfigurations: {
    SINGLE_PHASE_3_WIRE: '1-Phase 3-Wire',
    THREE_PHASE_4_WIRE_WYE: '3-Phase 4-Wire WYE',
    THREE_PHASE_3_WIRE_DELTA: '3-Phase 3-Wire DELTA',
    THREE_PHASE_4_WIRE_DELTA: '3-Phase 4-Wire DELTA',
    OTHER: 'Other',
  },
  getVoltageString: (voltage: string): string =>
    voltage.split('_').slice(1).join('/'),
}
export const initialState = {
  details: {},
  detailsMeta: {
    error: '',
    loading: true,
  },
  photos: [],
  photosMeta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('meterInstallSubmissionDetails')

function details(state = initialState.details, action) {
  switch (action.type) {
    case types.FETCH_METER_INSTALL_SUBMISSION_DETAILS:
      return initialState.details;

    case types.FETCH_METER_INSTALL_SUBMISSION_DETAILS_SUCCESS:
      return action.payload;

    case types.RESET_METER_INSTALL_SUBMISSION_STATE:
      return initialState.details;

    default:
      return state
  }
}

function photos(state = initialState.photos, action) {
  switch (action.type) {
    case types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS:
      return state;

    case types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS_SUCCESS:
      return action.payload;

    case types.RESET_METER_INSTALL_SUBMISSION_STATE:
      return initialState.photos;
    
    default:
      return state
  }
}

function detailsMeta(state = initialState.detailsMeta, action) {
  switch (action.type) {
    case types.FETCH_METER_INSTALL_SUBMISSION_DETAILS:
      return {
        error: '',
        loading: true,
      }

    case types.FETCH_METER_INSTALL_SUBMISSION_DETAILS_ERROR:
      return {
        error: action.error,
        loading: false,
      }

    case types.FETCH_METER_INSTALL_SUBMISSION_DETAILS_SUCCESS:
      return {
        error: '',
        loading: false,
      }

    case types.RESET_METER_INSTALL_SUBMISSION_STATE:
      return initialState.detailsMeta;

    default:
      return state
  }
}

function photosMeta(state = initialState.photosMeta, action) {
  switch (action.type) {
    case types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS:
      return {
        error: '',
        loading: true,
      }

    case types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS_ERROR:
      return {
        error: action.error,
        loading: false,
      }

    case types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS_SUCCESS:
      return {
        error: '',
        loading: false,
      }

    default:
      return state
  }
}

export default combineReducers({
  details,
  detailsMeta,
  photos,
  photosMeta,
})
export const selectMeterInstallSubmissionDetailsEntity = (
  state: FTState,
): FTMeterInstallSubmissionDetailsEntityState =>
  state.entities.meterInstallSubmissionDetails
const API = {
  fetchMeterInstallSubmissionDetails: ({
    meterSubmissionId,
  }: FTFetchMeterInstallSubmissionDetailsAction) => {
    if (isVariantActive('GRN247mock')) {
      return Promise.resolve(meterInstallSubmissionDetailsMockData).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 200)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/meter/submission/details?submissionId=${meterSubmissionId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(
        ({ data }: { data: FTMeterInstallSubmissionDetailsResponse }) => data,
      )
      .catch(handleAxiosError)
  },
  fetchMeterInstallSubmissionPhotos: (
    params: FTFetchMeterInstallSubmissionPhotosAction,
  ) => {
    if (isVariantActive('GRN247mock')) {
      return Promise.resolve(meterInstallSubmissionPhotosMockData).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 200)
          }),
      )
    }

    const query = queryStringify(params)
    const url = `${consoleBaseUrl()}/api/meter/install/photo?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTMeterInstallSubmissionPhotos }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchMeterInstallSubmissionDetailsSaga({
  type,
  ...params
}: {
  type: string
} & FTFetchMeterInstallSubmissionDetailsAction): Generator<any, void, any> {
  try {
    const response: FTMeterInstallSubmissionDetailsResponse = yield call(
      API.fetchMeterInstallSubmissionDetails,
      params,
    )
    yield put({
      type: types.FETCH_METER_INSTALL_SUBMISSION_DETAILS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_METER_INSTALL_SUBMISSION_DETAILS_ERROR, e)
  }
}

function* fetchMeterInstallSubmissionPhotosSaga({
  type,
  ...params
}: {
  type: string
} & FTFetchMeterInstallSubmissionPhotosAction): Generator<any, void, any> {
  try {
    const response: FTMeterInstallSubmissionPhotos = yield call(
      API.fetchMeterInstallSubmissionPhotos,
      params,
    )
    yield put({
      type: types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_METER_INSTALL_SUBMISSION_DETAILS,
    fetchMeterInstallSubmissionDetailsSaga,
  ),
  takeLatest(
    types.FETCH_METER_INSTALL_SUBMISSION_PHOTOS,
    fetchMeterInstallSubmissionPhotosSaga,
  ),
]
