import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import styled, { css } from 'styled-components'
import DesignLibraryPageWrapper from '../../DesignLibraryPageWrapper'
import RedaptiveReactTable7, {
  DefaultColumnFilter,
  ReactTableStyled,
  ReactTableTopSectionStyles,
  ReactTableWrapperStyles,
  tableBorder,
  TdInnerStyled,
  TdStyled,
} from '../../../../components/RedaptiveReactTable7'
import mockData from './mockData'
import { naturallySortEmptyLastCaseInsensitive } from '../../../../utils'
import type { FTTableHeaderRow } from '../../../../components/RedaptiveReactTable7/TableHead'
import TableHead from '../../../../components/RedaptiveReactTable7/TableHead'
import ChevronUp from '../../../../components/Icons/svg/ChevronUp'
import ChevronDown from '../../../../components/Icons/svg/ChevronDown'

const MainContentStyled = styled.div`
  overflow: auto;

  ${ReactTableWrapperStyles} {
    border-top: 0;
  }

  ${ReactTableTopSectionStyles} {
    margin-bottom: 0;
  }

  ${ReactTableStyled} {
    border-right: 1px solid #e0e0e0;
  }

  ${TdInnerStyled} {
    display: block;
  }
`
const TdStyledStyled = styled(TdStyled)`
  border-bottom: 0;
  border-top: ${tableBorder};
  padding: 0;

  &:first-child {
    border-right: ${({ row }) =>
      row.isExpanded || row.depth ? tableBorder : '0'};
    border-top: ${({ row }) => (row.depth ? '0' : tableBorder)};
  }

  tr:first-child > & {
    border-top: 0;
  }

  tr:last-child > & {
    border-bottom: ${tableBorder};
  }
`
const LocationHeaderStyles = css`
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
`
const LocationHeaderTopStyles = css`
  ${LocationHeaderStyles};
  border-top: 1px solid #e0e0e0;
`
const LocationHeaderBottomStyles = css`
  ${LocationHeaderStyles};
  border-bottom: 1px solid #e0e0e0;
`
const HeaderGroupStyles = css`
  background-color: #ececec;
  border: 1px solid #e0e0e0;
`
const FilterStyles = css`
  vertical-align: middle;
`
const HoursStyles = css`
  border-right: 1px solid #e0e0e0;

  ${FilterStyles};
`
const QuantityStyles = css`
  padding-left: 32px;

  ${FilterStyles};
`
const QuantityCellStyled = styled.div`
  padding-left: 32px;
`
const ExpanderCellStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    position: relative;
    top: 4px;
  }
`
const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/table-with-sub-rows-custom-header',
    text: 'Table With Sub Rows and Custom Header',
  },
]

const ExpandedCell = ({ row, value }: any) =>
  row.isExpanded || row.depth ? value : ''

const QuantityExpandedCell = ({ row, value }: any) =>
  row.isExpanded || row.depth ?
    <QuantityCellStyled>{value}</QuantityCellStyled>
  : <QuantityCellStyled />

const columns = [
  {
    accessor: 'location',
    Header: 'Location',
    Cell: ({ row, value }: any) => {
      if (row.canExpand) {
        return (
          <div {...row.getToggleRowExpandedProps()}>
            <ExpanderCellStyled>
              <span>{value}</span>
              {row.isExpanded && <ChevronUp />}
              {!row.isExpanded && <ChevronDown />}
            </ExpanderCellStyled>
          </div>
        )
      }

      return row.depth ? '' : value
    },
  },
  {
    accessor: 'preRetrofitQuantity',
    Cell: QuantityExpandedCell,
    disableFilters: true,
    Header: 'Quantity',
  },
  {
    accessor: 'preRetrofitWattage',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Wattage',
  },
  {
    accessor: 'preRetrofitHoursWithControls',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Hours w/ Controls',
  },
  {
    accessor: 'postRetrofitQuantity',
    Cell: QuantityExpandedCell,
    disableFilters: true,
    Header: 'Quantity',
  },
  {
    accessor: 'postRetrofitWattage',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Wattage',
  },
  {
    accessor: 'postRetrofitHoursWithControls',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Hours w/ Controls',
  },
]
type FTSort = {
  id: string
  desc: boolean
}
const initialSort = {
  id: '',
  desc: false,
}
export default () => {
  const [locationFilter, setLocationFilter] = useState('')
  const [sort, setSort] = useState(initialSort)
  const [sortLocationDesc, setSortLocationDesc] = useState(false)
  const tableInstanceRef = useRef()
  const handleSortClick = useCallback(
    ({
      target: {
        dataset: { id },
      },
    }) => {
      if (sort.id !== id) {
        setSort({
          id,
          desc: false,
        })
      } else if (sort.desc) {
        setSort(initialSort)
      } else {
        setSort({
          id,
          desc: true,
        })
      }
    },
    [sort],
  )
  const handleLocationSortClick = useCallback(() => {
    if (tableInstanceRef.current) {
      tableInstanceRef.current.toggleAllRowsExpanded(false)
    }

    setSortLocationDesc(!sortLocationDesc)
  }, [sortLocationDesc])

  const handleLocationFilterChange = (
    filterValue: string | null | undefined,
  ) => {
    if (tableInstanceRef.current) {
      tableInstanceRef.current.toggleAllRowsExpanded(false)
    }

    setLocationFilter(filterValue)
  }

  const locations = useMemo(
    () => [...new Set(mockData.map((item) => item.location))],
    [],
  )
  const data = useMemo(
    () =>
      locations
        .sort((location1, location2) =>
          sortLocationDesc ?
            naturallySortEmptyLastCaseInsensitive(
              location2,
              location1,
              sortLocationDesc,
            )
          : naturallySortEmptyLastCaseInsensitive(
              location1,
              location2,
              sortLocationDesc,
            ),
        )
        .filter((location) =>
          location && locationFilter ?
            location.toUpperCase().includes(locationFilter.toUpperCase())
          : true,
        )
        .reduce((acc, cur) => {
          const rows = mockData.filter(({ location }) => location === cur)

          if (sort.id) {
            rows.sort((rowA, rowB) =>
              sort.desc ?
                naturallySortEmptyLastCaseInsensitive(
                  rowB[sort.id],
                  rowA[sort.id],
                  sort.desc,
                )
              : naturallySortEmptyLastCaseInsensitive(
                  rowA[sort.id],
                  rowB[sort.id],
                  sort.desc,
                ),
            )
          }

          const firstRow = rows.shift()
          return [...acc, { ...firstRow, subRows: rows }]
        }, []),
    [locationFilter, sort, sortLocationDesc],
  )
  const locationFilterHeader = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      filterValue: locationFilter,
      id: 'locationFilter',
      minWidth: 266,
      setFilter: handleLocationFilterChange,
      sideMarginWidth: 32,
      sortable: false,
      thStyles: LocationHeaderBottomStyles,
    }),
    [],
  )
  useEffect(() => {
    locationFilterHeader.filterValue = locationFilter
  }, [locationFilter])
  const tableHeaderRows: Array<FTTableHeaderRow> = useMemo(
    () => [
      {
        id: 'row1',
        headers: [
          {
            handleSortClick: handleLocationSortClick,
            id: 'location',
            label: 'Location',
            sideMarginWidth: 32,
            sorted: true,
            sortDesc: sortLocationDesc,
            thStyles: LocationHeaderTopStyles,
          },
          {
            align: 'center',
            colspan: 3,
            id: 'preRetrofitFixture',
            label: 'Pre-Retrofit Fixture',
            sortable: false,
            thStyles: HeaderGroupStyles,
          },
          {
            align: 'center',
            colspan: 3,
            id: 'postRetrofitFixture',
            label: 'Post-Retrofit Fixture',
            sortable: false,
            thStyles: HeaderGroupStyles,
          },
        ],
      },
      {
        id: 'row2',
        headers: [
          locationFilterHeader,
          {
            handleSortClick,
            id: 'preRetrofitQuantity',
            label: 'Quantity',
            minWidth: 120,
            sorted: sort.id === 'preRetrofitQuantity',
            sortDesc: sort.desc,
            thStyles: QuantityStyles,
          },
          {
            handleSortClick,
            id: 'preRetrofitWattage',
            label: 'Wattage',
            minWidth: 120,
            sorted: sort.id === 'preRetrofitWattage',
            sortDesc: sort.desc,
            thStyles: FilterStyles,
          },
          {
            handleSortClick,
            id: 'preRetrofitHoursWithControls',
            label: 'Hours w/ Controls',
            minWidth: 120,
            sorted: sort.id === 'preRetrofitHoursWithControls',
            sortDesc: sort.desc,
            thStyles: HoursStyles,
          },
          {
            handleSortClick,
            id: 'postRetrofitQuantity',
            label: 'Quantity',
            minWidth: 120,
            sorted: sort.id === 'postRetrofitQuantity',
            sortDesc: sort.desc,
            thStyles: QuantityStyles,
          },
          {
            handleSortClick,
            id: 'postRetrofitWattage',
            label: 'Wattage',
            minWidth: 120,
            sorted: sort.id === 'postRetrofitWattage',
            sortDesc: sort.desc,
            thStyles: FilterStyles,
          },
          {
            handleSortClick,
            id: 'postRetrofitHoursWithControls',
            label: 'Hours w/ Controls',
            minWidth: 120,
            sorted: sort.id === 'postRetrofitHoursWithControls',
            sortDesc: sort.desc,
            thStyles: HoursStyles,
          },
        ],
      },
    ],
    [
      handleSortClick,
      handleLocationSortClick,
      sort,
      sortLocationDesc,
      tableInstanceRef,
    ],
  )
  const TableHeadComponent = useCallback(
    () => <TableHead rows={tableHeaderRows} />,
    [tableHeaderRows],
  )
  return (
    <DesignLibraryPageWrapper
      breadcrumbs={breadcrumbs}
      Title='Table With Sub Rows and Custom Header'
    >
      <MainContentStyled>
        <RedaptiveReactTable7
          autoResetExpanded={false}
          columns={columns}
          data={data}
          globalFilterable={false}
          showTableScrollArrows={false}
          TableHead={TableHeadComponent}
          tableInstanceRef={tableInstanceRef}
          TdComponent={TdStyledStyled}
        />
      </MainContentStyled>
    </DesignLibraryPageWrapper>
  )
}
