import styled from 'styled-components'
import { ReactNode } from 'react'
import ArrowBackIcon from './Icons/ArrowBackIcon'
import { colors } from '../utils/themes'
import ActionsDropdown2 from './ActionsDropdown2'
import ColorIndicator from './ColorIndicator'

type FTProps = {
  Actions?: () => ReactNode
  DropdownActions?: () => ReactNode
  handleBackNavigation?: ((...args: Array<any>) => any) | null | undefined
  statusColor?: string
  statusText?: string
  Title: string | (() => ReactNode)
}
const HeaderWrapperStyled = styled.div`
  align-items: center;
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 32px;
`
const ActionsWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const TitleWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  font-family: 'Public Sans', sans-serif;
  color: #162447;

  &,
  h1 {
    font-size: 18px;
    font-weight: 400;
  }

  ${ArrowBackIcon} {
    cursor: pointer;
    font-size: 20px;
    margin-right: 12px;
  }
`
const StatusIndicatorStyled = styled.div`
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 8px;
  display: flex;
  font-size: 12px;
  margin-left: 16px;
  padding: 8px 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;

  ${ColorIndicator} {
    margin-right: 8px;
  }
`
const DropdownActionsStyled = styled.div`
  align-items: center;
  color: ${colors.blue3};
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin-left: 20px;
`
export default ({
  Actions,
  DropdownActions,
  handleBackNavigation = null,
  statusColor,
  statusText,
  Title,
}: FTProps) => (
  <HeaderWrapperStyled>
    <TitleWrapperStyled>
      {!!handleBackNavigation && (
        <ArrowBackIcon onClick={handleBackNavigation} />
      )}
      {typeof Title === 'string' ?
        <h1 className='page-title'>{Title}</h1>
      : <Title />}
      {statusColor && statusText && (
        <StatusIndicatorStyled>
          <ColorIndicator color={statusColor} />
          {statusText}
        </StatusIndicatorStyled>
      )}
    </TitleWrapperStyled>
    <ActionsWrapperStyled>
      {Actions && <Actions />}
      {DropdownActions && DropdownActions() && (
        <DropdownActionsStyled>
          <ActionsDropdown2 Actions={DropdownActions} />
        </DropdownActionsStyled>
      )}
    </ActionsWrapperStyled>
  </HeaderWrapperStyled>
)
