import axios from "axios"
import { call, put, takeLatest } from "redux-saga/effects"
import { combineReducers } from "redux"
import { consoleBaseUrl, defaultHeaders } from "../../api"
import { recCostTable } from "../../pages/ProposalOperations/ProposalsEngine/utils"
import { handleAxiosError } from "../../api/utils"
import { handleSagaError } from "../../sagas/utils"
import { isVariantActive } from "../../utils"

type FTFetchRECYearData = {
    year: number,
    recValue: number
    recEscalator: number
}

export type FTFetchRECDataResp = Array<FTFetchRECYearData>

const types = {
    FETCH_REC_DATA: 'FETCH_REC_DATA',
    FETCH_REC_DATA_SUCCESS: 'FETCH_REC_DATA_SUCCESS',
    FETCH_REC_DATA_ERROR: 'FETCH_REC_DATA_ERROR',
}

export const actions = {
    fetchRECData: () => ({
        type: types.FETCH_REC_DATA
    })
}

type FTRECMetaState = {
  error: string
  loading: boolean
}
type FTRECEntityState = {
    recData: FTFetchRECDataResp
    meta: FTRECMetaState
}
type FTState = {
  entities: FTRECEntityState
}

const initialState = {
    meta: {
        error: '',
        loading: false,
    },
    recData: {}
}

function meta(state = initialState.meta, action) {
    switch (action.type) {
      case types.FETCH_REC_DATA:
        return {
          error: '',
          loading: true,
        }
  
      case types.FETCH_REC_DATA_SUCCESS:
        return {
          error: '',
          loading: false,
        }
  
      default:
        return state
    }
}

function recData(state = initialState.recData, action) {
  switch (action.type) {
    case types.FETCH_REC_DATA:
      return initialState.recData

    case types.FETCH_REC_DATA_SUCCESS:
      return action.payload

    default:
      return state
  }
}

export default combineReducers({
    recData,
    meta,
})

const API = {
    fetchRECData: () => {
      if (isVariantActive('3300mock')) {
        return Promise.resolve(recCostTable).then(
          (data) =>
            new Promise((resolve) => setTimeout(() => resolve(data), 200)),
        )
      }
      const url = `${consoleBaseUrl()}/proposal/api/rec-cost`
      return axios
        .get(url, {
          headers: defaultHeaders(),
        })
        .then(({ data }: { data: FTFetchRECDataResp }) => data)
        .catch(handleAxiosError)
    },
  }

  // Sagas
function* fetchRECDataSaga(): Generator<any, void, any> {
    try {
      const response: FTFetchRECDataResp = yield call(API.fetchRECData)
      yield put({
        type: types.FETCH_REC_DATA_SUCCESS,
        payload: response,
      })
    } catch (e) {
      yield handleSagaError(types.FETCH_REC_DATA_ERROR, e)
    }
  }
  
  export const sagas = [
    takeLatest(types.FETCH_REC_DATA, fetchRECDataSaga),
  ]
  // Selectors
  export const selectRECDataEntity = (
    state: FTState,
  ): FTRECEntityState => state.entities