import { ReactNode, useRef } from 'react'
import styled from 'styled-components'
import useComponentToggle from '../hooks/useComponentToggle'
import { zIndices } from '../utils'
import IonIcon, { IonIconStyles } from './IonIcon'

type FTProps = {
  Actions: () => ReactNode
}
const ActionsCellWrapperStyled = styled.div`
  position: relative;
`
const ActionsIconStyled = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  top: 2px;
  width: 20px;

  ${IonIconStyles} {
    > span {
      position: static;
    }
  }
`
const ActionsPopupStyled = styled.div<{ open: boolean }>`
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  border-radius: 4px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  left: -10px;
  padding: 16px;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  z-index: ${zIndices.RedaptiveReactTableActionsPopup};
`
export default ({ Actions }: FTProps) => {
  const componentRef = useRef(null)
  const toggleRef = useRef(null)
  const { isComponentVisible } = useComponentToggle({
    componentRef,
    toggleRef,
  })
  return (
    <ActionsCellWrapperStyled>
      <ActionsIconStyled ref={toggleRef}>
        <IonIcon fontSize='24px' iconClass='ion-android-more-vertical' />
      </ActionsIconStyled>
      <ActionsPopupStyled open={isComponentVisible} ref={componentRef}>
        <Actions />
      </ActionsPopupStyled>
    </ActionsCellWrapperStyled>
  )
}
