import styled from 'styled-components'
import Breadcrumbs, { BreadcrumbsStyles } from './Breadcrumbs'
import { colors } from '../utils/themes'

const BreadcrumbsWrapper = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  font-family: 'Montserrat', sans-serif;
  padding: 8px 32px;
  position: absolute;
  width: 100%;
  top: 85px;

  ${BreadcrumbsStyles} {
    color: ${colors.blue};
    padding-bottom: 0;
  }
`
export default (props: any) => (
  <BreadcrumbsWrapper>
    <Breadcrumbs {...props} />
  </BreadcrumbsWrapper>
)
