import { PureComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as modalActions } from '../../ducks/modal'
import Modal from '../Modal'
import Spinner from '../Spinner'
import ErrorMessage from '../ErrorMessage'
import CloseButton from '../CloseButton'
import PanelListEditorItem from './PanelListEditorItem'

const StyledModalWrapper = styled.div`
  .Modal .box {
    box-sizing: content-box;
    padding: 16px;
    width: 400px;
  }

  .Spinner.loader {
    border-width: 0.5em;
    height: 15px;
    margin-right: 9px;
    margin-top: 6px;
    width: 15px;
  }

  button {
    background-color: #f5f5f5;
    border-radius: 6px;
    border: 1px solid #dadada;
    color: #7f7f7f;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    height: 36px;
    padding: 9px 44px;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    width: 145px;

    &[disabled] {
      cursor: default;
    }

    &.primary {
      background-color: #0194d7;
      border-color: #0194d7;
      color: #fff;

      &[disabled] {
        background-color: #a2deff;
        border-color: #a2deff;
      }
    }

    + button {
      margin-left: 40px;
    }
  }
`
const StyledTitle = styled.div`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 12px;
  position: relative;
  text-transform: capitalize;

  & .Spinner.loader {
    border-width: 0.4em;
    left: 105px;
    margin: 0;
    position: absolute;
    top: -5px;
  }

  & ${CloseButton} {
    float: right;
  }
`
const StyledListBox = styled.div`
  border-radius: 3px;
  border: 1px solid #c6c6c6;
  height: ${({ isConfirming }) => (isConfirming ? '256px' : '444px')};
  overflow-x: hidden;
  overflow-y: ${({ isConfirming }) => (isConfirming ? 'auto' : 'scroll')};
  width: 400px;
`
const StyledAddLink = styled.div`
  color: #337ab7;
  cursor: pointer;
  margin-top: 16px;
  width: 400px;
`
const StyledConfirmDelete = styled.div`
  margin-top: 30px;
  text-align: center;

  & div:last-child {
    margin-top: 30px;
  }
`
const StyledDeleteEntityName = styled.div`
  color: #4a4a4a;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
`
export type FTProps = {
  actions: any
  conditionalFields: Record<string, any>
  entity: Record<string, any>
  generation: string
  onDeleteEntity: (...args: Array<any>) => any
  onSelectEntity: (...args: Array<any>) => any
  onUpdateEntity: (...args: Array<any>) => any
  reload: (...args: Array<any>) => any
  showModalPanelForm: (...args: Array<any>) => any
  siteId: string
}
type FTState = {
  entityToDelete:
    | {
        id: string
        name: string
      }
    | null
    | undefined
  isConfirming: boolean
}

class ModalPanelListEditor extends PureComponent<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      isConfirming: false,
      entityToDelete: null,
    }
  }

  componentDidUpdate(previousProps) {
    const {
      entity: { meta },
    } = this.props
    const {
      entity: { meta: previousMeta },
    } = previousProps
    const { deleteLoading } = meta
    const { deleteLoading: previousDeleteLoading } = previousMeta

    if (previousDeleteLoading === true && deleteLoading === false) {
      this.setState((prev) => ({
        ...prev,
        entityToDelete: null,
        isConfirming: false,
      }))
    }
  }

  onEntitySelect = (id) => {
    const { actions, onSelectEntity } = this.props
    onSelectEntity(id)
    actions.hideModal()
  }

  editEntity = (id, name) => {
    const { onUpdateEntity } = this.props

    if (id && name) {
      onUpdateEntity({
        id,
        name,
      })
    }
  }

  deleteEntity = () => {
    const { onDeleteEntity } = this.props
    const { entityToDelete } = this.state

    if (entityToDelete) {
      const { id } = entityToDelete
      onDeleteEntity({
        id,
      })
    }
  }

  showConfirmDelete = (entityToDelete) => {
    this.setState((prev) => ({ ...prev, entityToDelete, isConfirming: true }))
  }

  cancelDelete = () => {
    this.setState((prev) => ({
      ...prev,
      entityToDelete: null,
      isConfirming: false,
    }))
  }

  onClickAdd = () => {
    const {
      conditionalFields,
      siteId,
      generation,
      showModalPanelForm,
      reload,
    } = this.props
    showModalPanelForm({
      siteId,
      generation,
      conditionalFields,
      handleSuccess: reload,
      goBack: reload,
    })
  }

  renderConfirmDelete = () => {
    const {
      entity: {
        meta: { deleteLoading },
      },
    } = this.props
    const { entityToDelete } = this.state
    return (
      <StyledConfirmDelete>
        <div>Are you sure you want to delete this panel?</div>
        {entityToDelete && (
          <StyledDeleteEntityName>{entityToDelete.name}</StyledDeleteEntityName>
        )}
        <div>
          All circuits tagged with this panel will be changed to
          &quot;Unassigned&quot;.
        </div>
        <div>
          <button
            type='button'
            onClick={this.deleteEntity}
            disabled={deleteLoading}
          >
            Yes
          </button>
          <button
            type='button'
            className='primary'
            onClick={this.cancelDelete}
            disabled={deleteLoading}
          >
            No
          </button>
        </div>
      </StyledConfirmDelete>
    )
  }

  render() {
    const {
      actions,
      conditionalFields,
      entity: { meta, items },
      generation,
      reload,
    } = this.props
    const { addLoading, deleteLoading, error } = meta
    const { isConfirming, entityToDelete } = this.state
    return (
      <StyledModalWrapper>
        <Modal
          isConfirming={isConfirming}
          renderBody={() => (
            <div>
              <StyledTitle>
                Edit panels
                {deleteLoading && <Spinner size='tiny' />}
                <CloseButton
                  onClick={() => {
                    actions.hideModal()
                  }}
                />
              </StyledTitle>
              {error && <ErrorMessage message={error} />}
              <StyledListBox isConfirming={isConfirming}>
                {!isConfirming &&
                  items.length > 0 &&
                  items.map((entity) => (
                    <PanelListEditorItem
                      key={entity.id}
                      onClick={() => {
                        this.onEntitySelect(entity.id)
                      }}
                      onClickDelete={(event) => {
                        event.stopPropagation()
                        this.showConfirmDelete(entity)
                      }}
                      onClickEdit={(event) => {
                        event.stopPropagation()
                        actions.showModalPanelForm({
                          conditionalFields,
                          description: entity.description,
                          handleSuccess: reload,
                          goBack: reload,
                          id: entity.id,
                          meterGeneration: generation,
                          name: entity.name,
                          siteId: entity.siteId,
                          type: entity.type,
                          voltage: entity.voltage,
                        })
                      }}
                      panel={entity}
                    />
                  ))}
                {isConfirming && entityToDelete && this.renderConfirmDelete()}
              </StyledListBox>
              {!isConfirming && (
                <>
                  {!addLoading && (
                    <StyledAddLink onClick={this.onClickAdd}>
                      + Add
                    </StyledAddLink>
                  )}
                  {addLoading && <Spinner size='tiny' />}
                </>
              )}
            </div>
          )}
        />
      </StyledModalWrapper>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(modalActions, dispatch),
})

export default connect(
  (state, props) => ({
    entity: props.entitySelector(state),
  }),
  mapDispatchToProps,
)(ModalPanelListEditor)
