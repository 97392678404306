import { HiCheck } from 'react-icons/hi'
import { RxBox } from 'react-icons/rx'
import styled from 'styled-components'
import { colors } from '../../utils/themes'

type FTProps = {
  optionsList: Array<{ selected: boolean; name: string }>
  onSelectOption: (i: number) => void
}
const MultiSelectOptionsStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 10px;
`
const OptionStyle = styled.div<{ selected: boolean }>`
  border: 1px solid;
  border-color: ${colors.gray3};
  cursor: pointer;
  background: ${(props) =>
    props.selected ?
      colors.blue
    : 'linear-gradient(0deg, rgba(224, 224, 224, 0.2), rgba(224, 224, 224, 0.2)), #FFFFFF'};
  color: ${(props) => (props.selected ? colors.white : colors.blue5)};
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 6px;
  padding: 6px 8px;
  border-radius: 4px;
`
const CheckIconContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.2),
      rgba(224, 224, 224, 0.2)
    ),
    #ffffff;
  color: ${(props) => (props.selected ? colors.blue : colors.gray3)};
  margin: 0 6px 0 0;
  border-radius: 2px;
`
const Title = styled.p`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: ${colors.blue5};
  span {
    color: ${colors.red6};
  }
`

const MultiSelectOptions = ({ optionsList, onSelectOption }: FTProps) => (
  <>
    <Title>
      Choose at least 1 Issue Owner <span>*</span>
    </Title>
    <MultiSelectOptionsStyle className='modal-issue-owner-dropdown'>
      {optionsList.map((value, index) => (
        <OptionStyle
          selected={value.selected}
          onClick={() => onSelectOption(index)}
        >
          <CheckIconContainer>
            {value.selected ?
              <HiCheck size={16} color={colors.blue} />
            : <RxBox size={16} color={colors.gray3} />}
          </CheckIconContainer>
          <div>{value.name} </div>
        </OptionStyle>
      ))}
    </MultiSelectOptionsStyle>
  </>
)

export default MultiSelectOptions
