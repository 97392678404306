/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import type { FTModalPanelForm } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import {
  actions as panelActions,
  selectPanelListEntity,
} from '../../ducks/panels'
import Modal from '../Modal'
import FormikForm from './FormikForm'

const WrapperStyles = styled.div`
  .Modal {
    .box {
      margin: 2% auto;
      padding: 20px 16px;
    }

    .content {
      border: 1px solid #c6c6c6;
      padding: 20px 24px 24px;
    }
  }

  .header .title {
    margin-bottom: 20px;
  }
`
type FTProps = {
  addLoaded: boolean
  addLoading: boolean
  addPanel: (...args: Array<any>) => any
  dispatch: (...args: Array<any>) => any
  resetPanel: () => void
  error: string
  goBack?: ((...args: Array<any>) => any) | null | undefined
  handleSuccess?: ((...args: Array<any>) => any) | null | undefined
  hideModal: (...args: Array<any>) => any
  updateLoaded: boolean
  updateLoading: boolean
  updatePanel: (...args: Array<any>) => any
} & FTModalPanelForm

const ModalPanelForm = (props: FTProps) => {
  const {
    addPanel,
    addLoading,
    addLoaded,
    conditionalFields,
    description,
    error,
    goBack: goBackProp,
    handleSuccess: handleSuccessProp,
    hideModal,
    id,
    meterGeneration,
    name,
    resetPanel,
    siteId,
    type,
    updateLoading,
    updateLoaded,
    updatePanel,
    voltage,
    parentPanelId,
    panelLevel,
    allPanels,
    panelsById,
  } = props
  const goBack = goBackProp || hideModal
  const handleSuccess = handleSuccessProp || hideModal
  const loaded = id ? updateLoaded : addLoaded
  const loading = id ? updateLoading : addLoading
  const loadingRef = useRef(false)
  const { current: loadingPrev } = loadingRef
  useEffect(() => {
    if (loadingPrev && !loading && !error && loaded) {
      handleSuccess()
      return
    }

    loadingRef.current = loading
  })

  const getError = () => (loadingPrev && !loading ? error : '')

  const submitAction = (submission) => {
    const { parentPanelName, ...submissionWithoutParentPanelName } = submission;
    submissionWithoutParentPanelName.isNumbered = true;
    if (id) {
      updatePanel(submissionWithoutParentPanelName)
    } else {
      addPanel(submissionWithoutParentPanelName)
      resetPanel()
    }
  }

  const renderContent = () => (
    <FormikForm
      conditionalFields={conditionalFields}
      description={description}
      id={id}
      error={getError()}
      meterGeneration={meterGeneration}
      name={name}
      siteId={siteId}
      submitAction={submitAction}
      goBack={goBack}
      type={type}
      voltage={voltage}
      parentPanelId={parentPanelId}
      panelLevel={panelLevel}
      panels={allPanels}
      panelsById={panelsById}
    />
  )

  return (
    <WrapperStyles>
      <Modal
        {...props}
        modalWidth='480px'
        onClose={goBack}
        renderContent={renderContent}
        title={id ? 'Edit Circuit Breaker Panel' : 'Add Circuit Breaker Panel'}
      />
    </WrapperStyles>
  )
}

ModalPanelForm.defaultProps = {
  goBack: null,
  handleSuccess: null,
}

const mapStateToProps = (state) => ({
  ...selectPanelListEntity(state).meta,
  allPanels: selectPanelListEntity(state).allItems,
  panelsById: selectPanelListEntity(state).allById,
})

const mapDispatchToProps = (dispatch) => ({
  addPanel(values) {
    return dispatch(panelActions.addPanel(values))
  },

  hideModal() {
    return dispatch(modalActions.hideModal())
  },
  resetPanel() {
    return dispatch(panelActions.resetPanel())
  },
  updatePanel(values) {
    const { type, voltage, siteId, parentPanelName, ...request } = values
    return dispatch(panelActions.updatePanel(request))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalPanelForm)
