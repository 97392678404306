import { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type Moment from 'moment'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import { START_DATE, END_DATE } from 'react-dates/constants'
import { actions as meterInstallReportActions } from '../../ducks/meterInstallReport'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import Description from '../../components/Description'
import FormField from '../../components/FormField'
import FormSection from '../../components/FormSection'
import Title from '../../components/Title'
import WarningMessage from '../../components/WarningMessage'
import type { FTMeterInstallReportState } from '../../ducks/meterInstallReport'
import { DATE_FORMAT_SIMPLE } from '../../ducks/utils'

const StyledFormSection = styled(FormSection)`
  float: left;
  background-color: purple;
`
const MainStyles = styled.div`
  & ${WarningMessage} {
    margin-top: 20px;
  }

  & .FormButtons {
    margin-top: 42px;
  }

  & .DateInput_input {
    width: 150px;
    font-weight: normal;
  }

  & .DateInput {
    width: auto;
  }
`
const FormStyles = styled.div`
  display: flex;
  flex-direction: row;
  & > ${Description} {
    color: #4a4a4a;
    margin-top: 0;
    margin-left: 40px;

    ul {
      padding-left: 27px;
      line-height: 28px;
      margin-top: 5px;
      li {
        padding-left: 6px;
      }
    }
  }
  & > div:first-child {
    min-width: 390px;
  }
`
const DateRangeStyles = styled.div`
  & .DateRangePicker {
    width: 100%;
  }
  & .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    width: calc(100% - 1px);
    box-sizing: border-box;
  }

  & .DateRangePickerInput input {
    width: 129px;
    font-size: 14px;
    font-family: 'Avenir Next', sans-serif;
    color: #4a4a4a;
    font-weight: 400;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }

  & .DateRangePickerInput_arrow {
    margin-left: 3px;
    margin-right: 10px;
    margin-top: 2px;
    max-width: 13px;
  }

  & .ion-android-arrow-forward {
    font-size: 20px;
    font-weight: 500;
  }

  & .DateRangePickerInput_calendarIcon {
    margin-left: 0;
    margin-right: 0;
    outline: none;
    padding-top: 7px;
    padding-bottom: 8px;
  }
`
const startDateMinimum = moment('July 1, 2018', DATE_FORMAT_SIMPLE)
type FTProps = {
  actions: Record<string, any>
} & FTMeterInstallReportState
type FTState = {
  startDate: Moment | null | undefined
  endDate: Moment | null | undefined
  focusedInput: START_DATE | END_DATE | null
}

class MeterInstallExportFormPage extends PureComponent<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    const start = startDate ? moment(startDate).startOf('day') : null
    const end = endDate ? moment(endDate).startOf('day') : null
    this.setState({
      startDate: start,
      endDate: end,
    })
  }

  onFocusChange = (focusedInput) =>
    this.setState({
      focusedInput,
    })

  onSubmit = () => {
    const { startDate, endDate } = this.state
    const { actions } = this.props

    if (startDate && endDate) {
      const fromDate = startDate.toISOString()
      const toDate = endDate.toISOString()
      const filename = `meter-install-report-${fromDate.replace(
        '.000',
        '',
      )}-${toDate.replace('.000', '')}.csv`
      actions.getInstallReport({
        fromDate,
        toDate,
        filename,
      })
    }
  }

  getErrorMessage = () => {
    const { startDate, endDate } = this.state

    if (!startDate || !endDate) {
      return '* Please select a start date and an end date.'
    }

    return ''
  }

  canSubmit = () => {
    const { startDate, endDate } = this.state
    return !!(startDate && endDate)
  }

  /**
   * Lets the DatePicker know which days are not selectable.
   */
  isOutsideRange = (baseDay, focusedInputOverride = null) => {
    const { startDate: baseStartDate, focusedInput: focusedInputState } =
      this.state
    const focusedInput = focusedInputOverride || focusedInputState
    // Utilize startOf('day') to provide a baseline for comparison
    // the `baseDay` provided by DatePicker is at noon for each day.
    const day = baseDay.clone().startOf('day')
    const today = moment().startOf('day')
    const tomorrow = moment().startOf('day').add(1, 'days')

    // startDate can never be later than today.
    if (
      focusedInput === START_DATE &&
      (day.isAfter(today) || day.isBefore(startDateMinimum))
    ) {
      return true
    }

    if (focusedInput === END_DATE) {
      // endDate can never be later than tomorrow.
      if (day.isAfter(tomorrow)) {
        return true
      }

      if (baseStartDate) {
        const startDate = baseStartDate.clone().startOf('day')

        // endDate can never be on or before startDate
        if (day.isSameOrBefore(startDate)) {
          return true
        }
      }
    }

    return false
  }

  renderDescription = () => (
    <Description>
      <p>
        This tool returns a .csv file containing all Redaptive meters installed
        between the submitted start and end dates. A meter is considered
        installed the first time it is configured. For each meter, the report
        returns:
      </p>
      <ul>
        <li>Opportunity ID</li>
        <li>MAC address</li>
        <li>First configured date</li>
        <li>CT type and count</li>
        <li>Customer and installation location</li>
      </ul>
    </Description>
  )

  renderFormSection = () => {
    const { loading } = this.props
    const warningMessage = this.canSubmit() ? '' : this.getErrorMessage()
    return (
      <FormStyles>
        <StyledFormSection>
          <DateRangeStyles>
            <FormField
              requiredText='*'
              title='Date Range'
              showRequired={false}
              renderField={() => (
                <DateRangePicker
                  startDate={this.state.startDate}
                  startDateId='site-meter-form-start'
                  endDate={this.state.endDate}
                  endDateId='site-meter-form-end'
                  onDatesChange={this.onDatesChange}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={this.onFocusChange}
                  isOutsideRange={this.isOutsideRange}
                  customArrowIcon={<i className='ion-android-arrow-forward' />}
                  showDefaultInputIcon
                />
              )}
            />
          </DateRangeStyles>

          <WarningMessage>{warningMessage}</WarningMessage>

          <Button
            disabled={!this.canSubmit()}
            onClick={this.onSubmit}
            loading={loading}
            primary
            type='submit'
          >
            Download Report
          </Button>
        </StyledFormSection>
        {this.renderDescription()}
      </FormStyles>
    )
  }

  render() {
    return (
      <MainStyles>
        <Breadcrumbs
          items={[
            {
              href: '/reports',
              text: 'Tools & Reports',
            },
            {
              href: '/reports/meter-install',
              text: 'Meter Installation Report',
            },
          ]}
        />
        <Title>Meter Installation Report</Title>
        {this.renderFormSection()}
      </MainStyles>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(meterInstallReportActions, dispatch) },
})

const mapStateToProps = (state) => ({
  loading: state.meterInstall.loading,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeterInstallExportFormPage)
