import moment from 'moment'
import styled from 'styled-components'
import { SingleDatePicker } from 'react-dates'
import { Field } from 'formik'
import { Component, SyntheticEvent, createRef } from 'react'
import FormButtons from '../FormButtons'
import type { FTWithFormik } from '../../types'
import ErrorMessage, { ErrorMessageStyles } from '../ErrorMessage'
import UnsavedChanges from '../UnsavedChanges'
import type { FTModalElectronVerified } from '../../ducks/modal'
import RadioButton from '../RadioButton'
import VerifiedIcon from '../Icons/VerifiedIcon'
import NotVerifiedIcon from '../Icons/NotVerifiedIcon'

const InnerStyles = styled.div``
const FormErrorMessageStyles = styled.div`
  ${ErrorMessageStyles} {
    margin: 0 0 2px;
    height: auto;
  }
`
const InlineVerifiedIcon = styled(VerifiedIcon)`
  font-size: 30px;
  vertical-align: middle;
`
const InlineNotVerifiedIcon = styled(NotVerifiedIcon)`
  font-size: 30px;
  vertical-align: middle;
`
const FieldDescriptionStyles = styled.p`
  color: #7f7f7f;
  font-size: 12px;
`
const RadioVerifiedStyles = styled.div`
  cursor: pointer;
  display: inline-block;
  padding-right: 50px;

  label {
    cursor: pointer;
  }
`
const RadioNotVerifiedStyles = styled.div`
  cursor: pointer;
  display: inline-block;

  label {
    cursor: pointer;
  }
`
const RadiosStyles = styled.div`
  margin-bottom: 40px;
`
const RadioLabelStyles = styled.span`
  display: inline-block;
  padding: 0 0.5em;
`
const VerificationDateStyles = styled.div`
  margin-bottom: 40px;
`
const VerificationDateFieldsStyles = styled.div`
  border: 1px solid #c7c7c7;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 4px;
`
const DatePickerStyles = styled.div`
  .DayPicker {
    top: -38px;
  }

  .DateInput svg {
    display: none;
  }

  .DateInput_input {
    border: 0;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    padding: 0 8px;
    margin: 0;
  }

  .SingleDatePickerInput__withBorder {
    border: 0;
  }

  .DateInput,
  .DateInput_input,
  .SingleDatePicker,
  .SingleDatePickerInput {
    box-sizing: border-box;
    width: 92px;
  }
`
const TimePickerStyles = styled.div`
  align-items: center;
  display: flex;
  margin-left: 3px;

  input {
    border: 0;
    color: #4a4a4a;
    font-size: 14px;
    line-height: 1;
    text-align: right;
    width: 16px;
  }

  select {
    border: 0;
    color: #4a4a4a;
    font-family: 'Avenir Next', serif;
    font-size: 14px;
    height: 20px;
    margin-left: 3px;
  }
`
const FormButtonsStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  .FormButtons {
    margin: 0 auto;

    button {
      padding: 0 20px;
      min-width: 134px;
    }
  }
`
type FTProps = {
  error: string
  goBack: (...args: Array<any>) => any
} & FTWithFormik &
  FTModalElectronVerified
type FTState = {
  datePickerFocused: boolean
}
const now = moment()

const setInputFilter = (textbox: HTMLInputElement, inputFilter: any) => {
  ;[
    'input',
    'keydown',
    'keyup',
    'mousedown',
    'mouseup',
    'select',
    'contextmenu',
    'drop',
  ].forEach((event) => {
    textbox.addEventListener(event, function filterer() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value
        this.oldSelectionStart = this.selectionStart
        this.oldSelectionEnd = this.selectionEnd
      } else if (this.oldValue) {
        this.value = this.oldValue
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
      } else {
        this.value = ''
      }
    })
  })
}

const inputFilterOnlyNumbers = (value) => /^\d*$/.test(value)

export default class Form extends Component<FTProps, FTState> {
  inputRefs: any

  constructor(props: FTProps) {
    super(props)
    this.state = {
      datePickerFocused: false,
    }
    this.inputRefs = {
      hour: createRef(),
      minute: createRef(),
      second: createRef(),
      ampm: createRef(),
    }
  }

  componentDidMount() {
    const { verifiedDate } = this.props

    if (verifiedDate) {
      this.setTimePickerFromDate(moment(verifiedDate))
    } else {
      this.resetTimePicker()
    }

    this.setNumberOnlyEventListeners()
  }

  resetTimePicker = () => {
    if (this.inputRefs.hour.current) {
      this.inputRefs.hour.current.value = ''
    }

    if (this.inputRefs.minute.current) {
      this.inputRefs.minute.current.value = ''
    }

    if (this.inputRefs.second.current) {
      this.inputRefs.second.current.value = ''
    }
  }

  setNumberOnlyEventListeners = () => {
    if (this.inputRefs.hour.current) {
      setInputFilter(this.inputRefs.hour.current, inputFilterOnlyNumbers)
    }

    if (this.inputRefs.minute.current) {
      setInputFilter(this.inputRefs.minute.current, inputFilterOnlyNumbers)
    }

    if (this.inputRefs.second.current) {
      setInputFilter(this.inputRefs.second.current, inputFilterOnlyNumbers)
    }
  }

  setTimePickerFromDate = (date: typeof moment) => {
    const minute = date.minute()
    const second = date.second()
    this.inputRefs.hour.current.value = date.format('h')
    this.inputRefs.minute.current.value =
      parseInt(minute, 10) < 10 ? `0${minute}` : minute
    this.inputRefs.second.current.value =
      parseInt(second, 10) < 10 ? `0${second}` : second
    this.inputRefs.ampm.current.value = date.format('A')
  }

  onCancel = () => {
    const { goBack, setFieldValue, initialValues } = this.props
    setFieldValue('verifiedDate', initialValues)
    goBack()
  }

  onDateFocusChange = ({ focused }: any) =>
    this.setState({
      datePickerFocused: focused,
    })

  isOutsideRange = (day: typeof moment) => day.isAfter(now)

  getHour = (hour: string, ampm: string) => {
    const adjustedHour = hour === '12' ? '0' : hour
    return ampm === 'AM' ? adjustedHour : parseInt(adjustedHour, 10) + 12
  }

  getRefValue = (interval: string) =>
    this.inputRefs[interval].current && this.inputRefs[interval].current.value

  onUpdateDate = (date: typeof moment | null | undefined) => {
    const { setFieldValue } = this.props

    if (!date) {
      setFieldValue('verifiedDate', null)
      return
    }

    const ampm = this.inputRefs.ampm.current.value
    const currentHour = this.getRefValue('hour') || '0'
    const minute = this.getRefValue('minute') || '0'
    const second = this.getRefValue('second') || '0'
    const hour = this.getHour(currentHour, ampm)
    setFieldValue('verifiedDate', date.hour(hour).minute(minute).second(second))
  }

  onBlurHour = (event: SyntheticEvent) => {
    const {
      setFieldValue,
      values: { verifiedDate },
    } = this.props

    if (!verifiedDate) {
      return
    }

    const {
      target: { value },
    } = event
    const ampm = this.inputRefs.ampm.current.value
    const hour = this.getHour(value, ampm)
    setFieldValue('verifiedDate', moment(verifiedDate).hour(hour))
  }

  onBlurMinute = (event: SyntheticEvent) => {
    const {
      setFieldValue,
      values: { verifiedDate },
    } = this.props

    if (!verifiedDate) {
      return
    }

    const {
      target: { value },
    } = event
    setFieldValue('verifiedDate', moment(verifiedDate).minute(value))
  }

  onBlurSecond = (event: SyntheticEvent) => {
    const {
      setFieldValue,
      values: { verifiedDate },
    } = this.props

    if (!verifiedDate) {
      return
    }

    const {
      target: { value },
    } = event
    setFieldValue('verifiedDate', moment(verifiedDate).second(value))
  }

  onChangeLimit =
    (
      timeType: string,
      min: number,
      max: number,
      leadingZero: boolean = false,
    ) =>
    (event: SyntheticEvent) => {
      const {
        target: { value },
      } = event

      if (!value) {
        return
      }

      const intValue = parseInt(value, 10)

      if (intValue < min) {
        this.inputRefs[timeType].current.value =
          leadingZero ? `0${min}` : `${min}`
      } else if (intValue < 10 && leadingZero) {
        this.inputRefs[timeType].current.value = `0${intValue}`
      } else if (intValue > max) {
        // eslint-disable-next-line prefer-destructuring
        const newValue = `${intValue}`.slice(0, -1)
        this.inputRefs[timeType].current.value =
          parseInt(newValue, 10) < 10 && leadingZero ? `0${newValue}` : newValue
      } else {
        this.inputRefs[timeType].current.value = `${intValue}`
      }
    }

  onChangeHour = this.onChangeLimit('hour', 1, 12)

  onChangeMinute = this.onChangeLimit('minute', 0, 59, true)

  onChangeSecond = this.onChangeLimit('second', 0, 59, true)

  onChangeAmpm = (event: SyntheticEvent) => {
    const {
      target: { value },
    } = event
    const {
      setFieldValue,
      values: { verifiedDate },
    } = this.props
    const currentHour = verifiedDate.hour()
    const hour = value === 'PM' ? currentHour + 12 : currentHour - 12
    setFieldValue('verifiedDate', moment(verifiedDate).hour(hour))
  }

  onClickNotVerified = () => {
    const { setValues, values } = this.props
    const { verified } = values

    if (verified) {
      this.resetTimePicker()
      setValues({ ...values, verified: false, verifiedDate: null })
    }
  }

  onClickVerified = () => {
    const { setValues, values } = this.props
    const { verified } = values

    if (!verified) {
      const verifiedDate = moment()
      this.setTimePickerFromDate(verifiedDate)
      setValues({ ...values, verified: true, verifiedDate })
    }
  }

  canSubmit = () => {
    const {
      dirty,
      values: { verified, verifiedDate },
    } = this.props
    return (
      dirty &&
      ((verified &&
        verifiedDate &&
        this.getRefValue('hour') &&
        this.getRefValue('minute') &&
        this.getRefValue('second') &&
        this.inputRefs.ampm.current &&
        this.inputRefs.ampm.current.value) ||
        !verified)
    )
  }

  renderFormBody = () => {
    const { errors, initialValues, values } = this.props
    const { verified, verifiedDate } = values
    return (
      <InnerStyles>
        <RadiosStyles>
          <RadioVerifiedStyles onClick={this.onClickVerified}>
            <Field
              checked={verified}
              component={RadioButton}
              editDotRight='-15px'
              edited={verified !== initialValues.verified}
              error={errors.verified}
              hideEditDotOnHover={false}
              id='verified'
              name='verified'
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor='verified'>
              <RadioLabelStyles>Yes</RadioLabelStyles>
              <InlineVerifiedIcon />
            </label>
          </RadioVerifiedStyles>
          <RadioNotVerifiedStyles onClick={this.onClickNotVerified}>
            <Field
              checked={!verified}
              component={RadioButton}
              description='Verified'
              error={errors.verified}
              hideEditDotOnHover={false}
              id='notVerified'
              name='notVerified'
              defaultChecked={!verified}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor='notVerified'>
              <RadioLabelStyles>No</RadioLabelStyles>
              <InlineNotVerifiedIcon />
            </label>
          </RadioNotVerifiedStyles>
          <FieldDescriptionStyles>
            Select “Yes” only if the meter installation, configuration, channel
            tagging, and measurement data has been verified and approved by a
            team member.
          </FieldDescriptionStyles>
        </RadiosStyles>
        <VerificationDateStyles>
          <p>
            <b>Verification Date and Time</b>
          </p>
          <VerificationDateFieldsStyles>
            <DatePickerStyles>
              <SingleDatePicker
                date={verifiedDate}
                disabled={!verified}
                focused={this.state.datePickerFocused}
                id='electronVerifiedDatePicker'
                isOutsideRange={this.isOutsideRange}
                numberOfMonths={1}
                onDateChange={this.onUpdateDate}
                onFocusChange={this.onDateFocusChange}
                placeholder=''
              />
            </DatePickerStyles>
            <TimePickerStyles>
              <input
                disabled={!verified}
                onBlur={this.onBlurHour}
                onChange={this.onChangeHour}
                ref={this.inputRefs.hour}
              />
              :
              <input
                disabled={!verified}
                onBlur={this.onBlurMinute}
                onChange={this.onChangeMinute}
                ref={this.inputRefs.minute}
              />
              :
              <input
                disabled={!verified}
                onBlur={this.onBlurSecond}
                onChange={this.onChangeSecond}
                ref={this.inputRefs.second}
              />
              <select
                disabled={!verified}
                onChange={this.onChangeAmpm}
                ref={this.inputRefs.ampm}
              >
                <option value='AM'>AM</option>
                <option value='PM'>PM</option>
              </select>
            </TimePickerStyles>
          </VerificationDateFieldsStyles>
          <FieldDescriptionStyles>
            Enter the date and time when Electron Verified status was achieved.
            Time displayed is in your local time zone.
          </FieldDescriptionStyles>
        </VerificationDateStyles>
      </InnerStyles>
    )
  }

  render() {
    const { dirty, error, handleSubmit, isSubmitting } = this.props
    return (
      <>
        <UnsavedChanges when={!isSubmitting && dirty} />
        <FormErrorMessageStyles>
          <ErrorMessage message={error} />
        </FormErrorMessageStyles>
        {this.renderFormBody()}

        <FormButtonsStyles>
          <FormButtons
            cancelText='Cancel'
            isSubmitting={isSubmitting && !error}
            onCancel={this.onCancel}
            onSubmit={handleSubmit}
            submitDisabled={!this.canSubmit()}
            submitText='Submit'
          />
        </FormButtonsStyles>
      </>
    )
  }
}
