import { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import variants from '../utils/variants'
import docCookies from '../utils/docCookies'
import { isVariantActive } from '../utils'

const expireDate = moment().add(24, 'hours').toDate()
const variantIds = [...variants.keys()]
const initialValues = variantIds.filter((variantId) =>
  isVariantActive(variantId),
)
const ListItemStyles = styled.li`
  margin: 6px 0;

  input {
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .variantPageListItemTitle:hover {
    cursor: pointer;
  }
`

const VariantsPage = () => {
  const [activeVariants, setActiveVariants] = useState(initialValues)

  const setVariant = (variantName: string) => {
    const newVariants = [...activeVariants, variantName]
    setActiveVariants(newVariants)
    docCookies.setItem('variants', newVariants.join(','), expireDate, '/')
  }

  const deleteVariant = (variantToDelete: string) => {
    const newStates = activeVariants.filter(
      (variantName) => variantName !== variantToDelete,
    )
    setActiveVariants(newStates)

    if (newStates.length) {
      docCookies.setItem('variants', newStates.join(','), expireDate, '/')
    } else {
      docCookies.removeItem('variants')
    }
  }

  const handleChange = (name) =>
    activeVariants.includes(name) ? deleteVariant(name) : setVariant(name)

  const variantListItems = []
  variants.forEach((variant, key) => {
    variantListItems.push(
      // eslint-disable-next-line react/no-array-index-key
      <ListItemStyles key={key}>
        <input
          checked={activeVariants.includes(key)}
          onChange={() => handleChange(key)}
          type='checkbox'
        />
        <span
          className='variantPageListItemTitle'
          onClick={() => handleChange(key)}
        >
          {`${key}: ${variant.title}`}
        </span>
        <div>{variant.description}</div>
      </ListItemStyles>,
    )
  })
  return (
    <>
      <h1>Variants</h1>

      <h2>About</h2>

      <p>
        For multivariate testing, variations of the site may be turned on and
        off.
      </p>

      <ul>
        <li>
          A variant may be enabled by using the form below or by using the
          variants query parameter, ie.
          <em>?variants=5661 or ?variants=5661,5662.</em>
        </li>
        <li>
          Variants utilize cookies and so only affect the browser session that
          they were enabled in.
        </li>
        <li>The cookie expires in 24 hours.</li>
      </ul>

      <h2>Variants list</h2>

      <p>
        To enable a variant, check the appropriate box. They are enabled
        instantly; no need to save the form.
      </p>

      <ul>{variantListItems}</ul>
    </>
  )
}

export default VariantsPage
