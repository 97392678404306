import { withFormik } from 'formik'
import Form from './Form'
import {
  validAmperageValues,
  validBreakerTypes,
  validCtTypes,
  phaseOptions,
  breakerNumbers,
  removeDerivedFields,
} from '../../utils/panelConfiguration'
import { FTPanelCircuit } from '../../ducks/panelCircuits'

export default withFormik({
  handleSubmit(values, formikBag) {
    const {
      props: { submitAction, circuitsOrder, setErrorMessage, isNumbered },
    } = formikBag
    const { circuitsById, deletedCircuitIds } = values
    // Order them with left circuits first so that the backend calculates phaseGroups correctly
    const sortedCircuits =
      isNumbered ?
        // Object.values(circuitsById).sort((a, b) => {
        //   const orderA = a.sideBreakerOrder || '' // Handle undefined case

        //   const orderB = b.sideBreakerOrder || ''

        //   if (orderA === 'LEFT' && orderB === 'RIGHT') {
        //     return -1 // 'left' comes before 'right'
        //   }

        //   if (orderA === 'RIGHT' && orderB === 'LEFT') {
        //     return 1 // 'right' comes after 'left'
        //   }

        //   return 0 // order remains unchanged
        // })
        [
          ...Object.values(circuitsById)
            .filter((circuit) => circuit.sideBreakerOrder === 'LEFT')
            .sort((a, b) => a.breakerNumber - b.breakerNumber),
          ...Object.values(circuitsById)
            .filter((circuit) => circuit.sideBreakerOrder === 'RIGHT')
            .sort((a, b) => a.breakerNumber - b.breakerNumber),
        ]
      : Object.values(circuitsById)
    let cleanedCircuits = removeDerivedFields(
      sortedCircuits as FTPanelCircuit[],
    )

    const allCircuitshaveBreakerNumbers = cleanedCircuits.every(
      (circuit) => !!circuit.breakerNumber,
    )

    if (!isNumbered && !allCircuitshaveBreakerNumbers) {
      setErrorMessage(
        isNumbered ?
          'All circuits must have a breaker number'
        : 'All switches must have a name',
      )
      return
    }
    if (!isNumbered) {
      cleanedCircuits = cleanedCircuits.map((circuit) => {
        const { breakerType, ...rest } = circuit
        return { ...rest }
      })
    }
    setErrorMessage('') // Clear any previous error messages
    submitAction(
      cleanedCircuits.filter((circuit) => !circuit.isMetered),
      deletedCircuitIds,
    )
  },

  mapPropsToValues(props) {
    const { circuitsLoading, circuitsById } = props
    return {
      circuitsById,
      circuitsLoading,
      newCircuitIds: [],
      deletedCircuitIds: [],
      error: '',
    }
  },

  mapPropsToStatus(props) {
    return {
      goBack: props.goBack,
      validCtTypes,
      phaseOptions,
      validAmperageValues,
      validBreakerTypes,
      // TODO: Use these breakernumbers once phase group is added
      breakerNumbers,
    }
  },
})(Form)
