import type { ComponentType } from 'react'
import { useMemo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Button2 from '../Button2'
import {
  ModalConfirmBodyStyled,
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'
import type { FTBillingRemainingQuantityModal } from '../../ducks/modal'
import '../../ducks/modal'
import RedaptiveReactTable7, {
  RedaptiveReactTableStyles,
  ReactTableWrapperStyles,
} from '../RedaptiveReactTable7'
import KeyboardDoubleArrowUpIcon from '../Icons/KeyboardDoubleArrowUpIcon'
import KeyboardDoubleArrowDownIcon from '../Icons/KeyboardDoubleArrowDownIcon'
import { colors } from '../../utils/themes'
import { REMAINING_SO_QUANTITY } from '../../constants/strings'

type FTProps = {
  onPrimaryAction: (...args: Array<any>) => any
  onSecondaryAction: (...args: Array<any>) => any
  billedOverRemainingQuantity: Record<string, any>
} & FTBillingRemainingQuantityModal
const TABLE_MARGIN_BOTTOM = 130
const ITEMS_TOGGLE_HEIGHT = 43
const ModalTitleWrapperStyled = styled.div`
  padding: 20px 20px 0px 20px;

  ${ModalConfirmTitleStyled} {
    padding: 0;

    & > span {
      color: ${colors.blue};
    }
  }
`
const SubTitleStyled = styled.h5`
  color: ${({ isWarning }) => isWarning? `${colors.orange}` : `${colors.blue3}`};
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0;
  padding: 0 20px;
  text-align: center;
`
const TableWrapperStyled = styled.div`
  font-size: 12px;

  ${RedaptiveReactTableStyles} {
    margin: 0 20px;
  }
  ${ReactTableWrapperStyles} {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    max-height: 195px;
  }
`
const ItemsToggleStyled: ComponentType<{
  itemsVisible: boolean
}> = styled.div`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    #ffffff 100%
  );
  bottom: ${({ itemsVisible }) =>
    itemsVisible ?
      `${TABLE_MARGIN_BOTTOM - ITEMS_TOGGLE_HEIGHT}px`
    : `${TABLE_MARGIN_BOTTOM}px`};
  color: ${colors.blue2};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: ${ITEMS_TOGGLE_HEIGHT}px;
  justify-content: center;
  letter-spacing: 0.02em;
`
const ItemsToggleIconStyled = styled.div`
  margin-right: 6px;
  position: relative;
  top: 2px;
`
const columns = [
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
  },
  {
    accessor: 'name',
    Header: 'Opportunity Name',
  },
  {
    accessor: 'remainingQuantity',
    Header: REMAINING_SO_QUANTITY,
  },
]

const billingRemainingQuantityModal = ({
  billedOverRemainingQuantity = [],
  onPrimaryAction,
  onSecondaryAction,
}: FTProps) => {
  const [isToggleItemsActive, setIsToggleItemsActive] = useState(false)
  const tableData = useMemo(
    () =>
      isToggleItemsActive ?
        billedOverRemainingQuantity
      : billedOverRemainingQuantity.slice(0, 3),
    [isToggleItemsActive],
  )

  const handleContinue = () => {
    onPrimaryAction()
  }

  const handleCancel = () => {
    onSecondaryAction()
  }

  const handleItemsToggleClick = useCallback(() => {
    setIsToggleItemsActive(!isToggleItemsActive)
  }, [isToggleItemsActive])
  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled
        style={{
          width: '600px',
        }}
      >
        <ModalTitleWrapperStyled>
          <ModalConfirmTitleStyled>
            At least one item in the sales order for this project has less
            remaining quantity than the approved invoice quantity.
          </ModalConfirmTitleStyled>
        </ModalTitleWrapperStyled>
        <ModalConfirmBodyStyled>
          <SubTitleStyled>
            An invoice will be created for only the remaining sales order
            quantity wherever necessary.
          </SubTitleStyled>
          {billedOverRemainingQuantity.some(entry => entry.remainingQuantity === 0) && <SubTitleStyled isWarning>
            Note: The sales order for this opportunity has completed its billable quantity.
            Please review the sales order and reach out to the Technology team if you have any questions.
          </SubTitleStyled>}
          {!!billedOverRemainingQuantity && (
            <TableWrapperStyled>
              <RedaptiveReactTable7
                columns={columns}
                data={tableData}
                globalFilterable={false}
              />
              {billedOverRemainingQuantity.length > 3 && (
                <ItemsToggleStyled
                  itemsVisible={isToggleItemsActive}
                  onClick={handleItemsToggleClick}
                >
                  {isToggleItemsActive ?
                    <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowUpIcon />
                      </ItemsToggleIconStyled>
                      Hide
                    </>
                  : <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowDownIcon />
                      </ItemsToggleIconStyled>
                      Show All
                    </>
                  }
                </ItemsToggleStyled>
              )}
            </TableWrapperStyled>
          )}
          <SubTitleStyled>Are you sure you want to continue?</SubTitleStyled>
        </ModalConfirmBodyStyled>
        <ModalConfirmButtonsStyled>
          <Button2 onClick={handleCancel} type='secondary'>
            Cancel
          </Button2>
          <Button2 onClick={handleContinue} type='primary'>
            OK, Continue
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

export default connect()(billingRemainingQuantityModal)
