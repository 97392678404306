import styled from 'styled-components'
import { ReactNode,useEffect, useState } from 'react'
import KeyboardArrowDownIcon from './Icons/KeyboardArrowDownIcon'
import KeyboardArrowUpIcon from './Icons/KeyboardArrowUpIcon'

type FTProps = {
  title: string
  isExpandedInitially?: boolean
  Content: () => ReactNode
}
export const MainContainerStyled = styled.div`
  width: 100%;
  padding: 0 50px;
`
const HeaderStyled = styled.div`
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`
const TitleStyled = styled.div`
  padding-bottom: 10px;
  color: #4a4a4a;
`
const IndicatorStyled = styled.div`
  color: #337ab7;
  cursor: pointer;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const Accordion = ({ title, Content, isExpandedInitially = false }: FTProps) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedInitially);
  useEffect(() => {
    setIsExpanded(isExpandedInitially);
  }, [isExpandedInitially]);
  return (
    <MainContainerStyled>
      <HeaderStyled>
        <TitleStyled>{title}</TitleStyled>
        <IndicatorStyled onClick={() => setIsExpanded((current) => !current)}>
          {isExpanded ?
            <span>
              See Less
              <KeyboardArrowUpIcon />
            </span>
          : <span>
              See More
              <KeyboardArrowDownIcon />
            </span>
          }
        </IndicatorStyled>
      </HeaderStyled>
      {isExpanded ?
        <Content />
      : <div />}
    </MainContainerStyled>
  )
}

export default Accordion
