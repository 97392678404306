import { withFormik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import FormField from '../FormField'
import FormButtons from '../FormButtons'
import StyledRadioButton from '../RadioButton'

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
`
const FieldLabel = styled.div`
  width: 300px;
  font-weight: 600;
`
const FieldValue = styled.div`
  width: 300px;
  margin-left: 20px;
`
const RadioButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 30px;
`
const RadioButtonTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 16px;
  margin-left: 8px;
  cursor: pointer;
`

const UpdateForm = ({ values, setFieldValue, handleSubmit, dirty, status }) => (
  <>
    <FieldWrapper>
      <FieldLabel>Install Location</FieldLabel>
      <FieldValue>
        <FormField name='installLocation' />
      </FieldValue>
    </FieldWrapper>
    <FieldWrapper>
      <FieldLabel>Power Source</FieldLabel>
      <FieldValue>
        <RadioButtonWrapper
          onClick={() => setFieldValue('meterPowerSource', 'Wall Plug')}
        >
          <StyledRadioButton
            name='meterPowerSource'
            value='Wall Plug'
            checked={values.meterPowerSource === 'Wall Plug'}
          />
          <RadioButtonTitle>Wall Plug</RadioButtonTitle>
        </RadioButtonWrapper>
        <RadioButtonWrapper
          onClick={() => setFieldValue('meterPowerSource', 'Voltage Taps')}
        >
          <StyledRadioButton
            name='meterPowerSource'
            value='Voltage Taps'
            checked={values.meterPowerSource === 'Voltage Taps'}
          />
          <RadioButtonTitle>Voltage Taps</RadioButtonTitle>
        </RadioButtonWrapper>
      </FieldValue>
    </FieldWrapper>
    <FieldWrapper>
      <FieldLabel>Voltage Tap Phase A</FieldLabel>
      <FieldValue>
        <FormField name='vtapL1PhaseA' />
      </FieldValue>
    </FieldWrapper>
    <FormButtons
      onSubmit={handleSubmit}
      onCancel={status.cancelHandler}
      submitText='Save & Update'
      cancelText='Cancel'
      submitDisabled={!dirty}
    />
  </>
)

const validationSchema = Yup.object().shape({
  installLocation: Yup.string(),
  meterPowerSource: Yup.string(),
  vtapL1PhaseA: Yup.string(),
})
export default withFormik({
  validationSchema,
  mapPropsToValues: ({ formData }) => ({
    installLocation: formData.installLocation || '',
    meterPowerSource: formData.meterPowerSource || '',
    vtapL1PhaseA: formData.vtapL1PhaseA || '',
  }),
  handleSubmit: (values, { props }) => {
    props.handleUpdate(values)
  },
  mapPropsToStatus: ({ cancelHandler }) => ({
    cancelHandler,
  }),
})(UpdateForm)
