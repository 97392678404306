import { Component, Fragment } from 'react'
import styled from 'styled-components'
import type { FTRenderTableRowFunc } from '../Table'
import '../Table'
import Base from './Base'

const Styles = styled.div`
  // Checkbox glitch if not included, so long as components/Table.jsx is the underlying component
  & table {
    border-spacing: 0;
  }

  // This table should not have the border-right separator
  & tbody tr > td {
    border-right: unset;
  }

  // ./Base.jsx hard codes the width of the first two columns (for now), so override it
  & tr > td {
    width: unset !important;
  }
`
const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #c7c7c7;
`
const SeparatorRow = styled.tr`
  background-color: ${({ rowColor }) => `${rowColor} !important`};
  & > td {
    padding: 0 !important;
  }
`
const ColorableRow = styled.tr`
  background-color: ${({ rowColor }) => `${rowColor} !important`};
`
type FTProps = {
  renderRowBody: FTRenderTableRowFunc
  columnCount: number
  keyField?: string
  groupEvery?: number
  stripeRowColor?: string
  defaultRowColor?: string
  renderTableRow?: any
  className?: string
}
export default class Grouped extends Component<FTProps> {
  componentDidMount() {
    const { columnCount, renderRowBody, renderTableRow } = this.props

    if (!columnCount) {
      throw new Error('Must specify a columnCount')
    }

    if (!renderRowBody) {
      throw new Error('Must provide renderRowBody')
    }

    if (renderTableRow) {
      throw new Error(
        'renderTableRow was provided, but cannot be used with Grouped',
      )
    }
  }

  renderGroupedStripedRow = (rowProps, index) => {
    const {
      renderRowBody,
      keyField,
      groupEvery,
      columnCount,
      stripeRowColor,
      defaultRowColor,
    } = this.props
    const key = rowProps[keyField]
    return (
      <Fragment key={key}>
        {index > 0 && index % groupEvery === 0 && (
          <SeparatorRow rowColor={defaultRowColor}>
            <td colSpan={columnCount}>
              <Separator />
            </td>
          </SeparatorRow>
        )}
        <ColorableRow
          rowColor={index % 2 === 0 ? stripeRowColor : defaultRowColor}
        >
          {renderRowBody(rowProps, index)}
        </ColorableRow>
      </Fragment>
    )
  }

  render() {
    const {
      renderRowBody,
      keyField,
      groupEvery,
      columnCount,
      stripeRowColor,
      defaultRowColor,
      className,
      ...props
    } = this.props
    return (
      <Styles className={className}>
        <Base {...props} renderTableRow={this.renderGroupedStripedRow} />
      </Styles>
    )
  }
}
Grouped.defaultProps = {
  renderTableRow: undefined,
  keyField: 'id',
  groupEvery: 4,
  stripeRowColor: '#f5f5f5',
  defaultRowColor: '#ffffff',
  className: '',
}
