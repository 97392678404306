import { MouseEventHandler, PureComponent } from 'react'
import styled from 'styled-components'
import './Dropdown.css'
import StyledLink, { LinkStyles } from './StyledLink'

export type FTActionOption = {
  external?: boolean
  href?: string
  label: string
  onClick?: MouseEventHandler<HTMLDivElement>
}
export const StyledAction = styled.div`
  cursor: pointer;

  ${LinkStyles};
`
type FTProps = {
  options: Array<FTActionOption>
}
export default class ActionsDropdown extends PureComponent<FTProps> {
  render() {
    const { options } = this.props
    return (
      <div className='Dropdown'>
        <div className='List'>
          {options.map(({ label, href, external = false, onClick }) => {
            if (href) {
              return (
                <StyledLink
                  href={href}
                  to={href}
                  key={label}
                  external={external}
                  onClick={onClick}
                >
                  {label}
                </StyledLink>
              )
            }

            return (
              <StyledAction key={label} onClick={onClick}>
                {label}
              </StyledAction>
            )
          })}
        </div>
      </div>
    )
  }
}
