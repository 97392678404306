import * as React from 'react'
import styled from 'styled-components'
import ActionsDropdownMenu from './ActionsDropdownMenu'
import type { FTActionOption } from './ActionsDropdown'

type FTProps = {
  renderMain: () => React.ReactNode
  actions: Array<FTActionOption>
  title?: string
  icon?: React.ReactNode
  iconBefore?: boolean
  style?: Record<string, any>
}
const StyledActionPaneViewInner = styled.div`
  width: 100%;
`
const StyledActionPaneView = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;

  .ActionPane {
    background-color: #f5f5f5;
    height: 100%;
    padding-left: 42px;
    padding-top: 62px;
    width: 334px;

    a[href] {
      color: #337ab7;
      line-height: 20px;
      text-decoration: underline;
    }
  }

  .ActionPane-item + .ActionPane-item {
    margin-top: 14px;
  }

  .ActionPane-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 30px;
  }
`
export default class ActionPaneView extends React.PureComponent<FTProps> {
  render() {
    const {
      renderMain,
      actions = [],
      title = '',
      icon = null,
      style = null,
      iconBefore = false,
    } = this.props
    return (
      <StyledActionPaneView>
        {actions.length > 0 && (
          <ActionsDropdownMenu
            actions={actions}
            title={title}
            icon={icon}
            style={style}
            iconBefore={iconBefore}
          />
        )}
        <StyledActionPaneViewInner>{renderMain()}</StyledActionPaneViewInner>
      </StyledActionPaneView>
    )
  }
}
