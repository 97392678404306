import { PureComponent } from 'react'
import isEqual from 'lodash.isequal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { withFormik } from 'formik'
import { actions as modalActions } from '../../ducks/modal'
import { selectSiteEntity, actions as siteActions } from '../../ducks/sites'
import {
  getCircuitListEntity,
  actions as circuitActions,
  utils as circuitUtils,
} from '../../ducks/circuits'
import {
  selectCustomerEntity,
  actions as customerActions,
} from '../../ducks/customers'
import { selectMeterEntity, actions as meterActions } from '../../ducks/meters'
import {
  selectPanelListEntity,
  selectAllById as panelSelectById,
  actions as panelActions,
} from '../../ducks/panels'
import {
  actions as phaseGroupActions,
  getPhaseGroups,
  getPhaseGroupsById,
  getPhaseGroupListEntity,
} from '../../ducks/phaseGroups'
import {
  selectEquipmentListEntity,
  selectById as equipmentSelectById,
  actions as equipmentActions,
} from '../../ducks/equipment'
import {
  actions as buildingEquimentActions,
  selectBuildingEquipments,
} from '../../ducks/buildingEquiment'
import {
  selectors as bsSelectors,
  actions as bsActions,
} from '../../ducks/buildingSystems'
import type { FTRouterLocation } from '../../types'
import '../../types'
import Breadcrumbs from '../../components/Breadcrumbs'
import CircuitListForm from '../../components/CircuitListForm'
import './CircuitListFormPage.css'
import withNotice from '../../components/MeterConfiguration/withNotice'
import type { FTCircuit } from '../../ducks/circuits'
import { getConditionalFields } from '../../ducks/meters/generation'

const Component = styled.div`
  .Table tbody td {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`

const validate = (values) => {
  const errors = {}
  const phaseGroupsDependents = {}
  const { circuits } = values
  // For each phase group, the building system and equipment name must be the
  // same.
  circuits.some((circuit: FTCircuit) => {
    const { phaseGroupId } = circuit

    if (!phaseGroupId) {
      return false
    }

    const { buildingSystemId, equipmentId } = circuit
    const prevPhaseGroupDependents = phaseGroupsDependents[phaseGroupId]
    phaseGroupsDependents[phaseGroupId] = {
      error: false,
      dependents: {
        buildingSystemId,
        equipmentId,
      },
    }
    if (
      prevPhaseGroupDependents &&
      !isEqual(prevPhaseGroupDependents, phaseGroupsDependents[phaseGroupId])
    ) {
      phaseGroupsDependents[phaseGroupId].error = true
      errors.circuits =
        'Each phase group must have the same equipment and building system.'
      return true
    }

    return false
  })
  return errors
}

const FormikCircuitListForm = withFormik({
  validate,
  mapPropsToValues(props) {
    return props
  },

  mapPropsToStatus({ meter }) {
    return {
      meter,
    }
  },

  handleSubmit(values, formikBag) {
    const { props } = formikBag
    const { submitAction } = props
    submitAction(values, formikBag)
  },
})(CircuitListForm)
type FTProps = FTRouterLocation

class CircuitListFormPage extends PureComponent<FTProps> {
  tableFields = [
    'id',
    'description',
    'buildingSystemId',
    'panelId',
    'panelFeedId',
    'phaseGroupId',
    'channelId',
    'meterChannel',
    'meteredUpstream',
    'breakerNumber',
    'phase',
    'ctType',
    'ctTypeAmps',
    'equipmentId',
    'buildingArea',
    'name',
    'contractId',
    'flippedCTStatusCurrent',
    'flippedCTLabel',
    'pendingCTTypeAmps',
    'isBigBang',
  ]

  editableFields = [
    'id',
    'description',
    'breakerNumber',
    'buildingSystemId',
    'panelId',
    'panelFeedId',
    'phaseGroupId',
    'equipmentId',
    'buildingArea',
    'contractId',
    'meteredUpstream',
  ]

  componentDidMount() {
    const {
      actions,
      match: {
        params: { customerId, meterId },
      },
    } = this.props
    actions.fetchBuildingSystems({})
    actions.fetchMeter({
      id: meterId,
    })

    if (customerId) {
      actions.fetchCustomer({
        customerId,
      })
    }
  }

  componentDidUpdate(prev: FTProps) {
    const {
      circuitListEntity: {
        meta: { updateLoading: prevUpdateLoading },
      },
      meterEntity,
      history,
    } = prev
    const { item: prevMeter } = meterEntity
    const {
      actions,
      circuitListEntity: {
        meta: { updateLoading: nextUpdateLoading, error },
      },
      meterEntity: { item: meter },
    } = this.props

    if (prevUpdateLoading !== nextUpdateLoading) {
      if (!prevUpdateLoading && nextUpdateLoading === true) {
        actions.showLoadingModal()
      }

      if (prevUpdateLoading === true && nextUpdateLoading === false) {
        actions.hideModal()

        if (!error) {
          history.goBack()
        } else {
          actions.showModalGeneric({
            title: error,
          })
        }
      }
    }

    if (!prevMeter && meter) {
      this.dispatchWithMeter()
    }
  }

  onSubmit = ({ circuits }) => {
    const modifiedCircuits = this.getModifiedItems(circuits)

    if (modifiedCircuits.length > 0) {
      this.props.actions.bulkUpdateCircuits({
        circuits: modifiedCircuits,
      })
    }
  }

  onAddEquipment = (name: string) => {
    const {
      actions,
      meterEntity: { item: meter },
    } = this.props
    const { siteId } = meter
    actions.addEquipment({ siteId, name })
  }

  getEditableFields = (circuits) =>
    circuits.map((item) => {
      const result = {}
      this.editableFields.forEach((f) => {
        result[f] = item[f]
      })
      return result
    })

  getModifiedItems = (submitted) => {
    const {
      circuitListEntity: { items },
    } = this.props
    const initialValues = this.getEditableFields(items)
    const currentValues = this.getEditableFields(submitted)
    const results = []

    for (let i = 0; i < initialValues.length; i += 1) {
      const initial = initialValues[i]
      const current = currentValues[i]
      const result = {}
      // eslint-disable-next-line
      Object.entries(current).forEach(([key, value]) => {
        if (key === 'id') {
          result[key] = value
        } else if (!isEqual(initial[key], value) && (initial[key] || value)) {
          result[key] = value
        }
      })
      results.push(result)
    }

    return results.filter((item) => Object.keys(item).length > 1)
  }

  showPanelEditor = (onSelectEntity) => {
    const {
      actions: {
        addPanel: onAddEntity,
        updatePanel: onUpdateEntity,
        deletePanel: onDeleteEntity,
        showEntityEditorModal,
      },
      meterEntity: {
        item: { siteId },
      },
    } = this.props
    showEntityEditorModal({
      siteId,
      onSelectEntity,
      onAddEntity,
      onUpdateEntity,
      onDeleteEntity,
      entitySelector: selectPanelListEntity,
      singularName: 'panel',
      pluralName: 'panels',
      parentPluralName: 'circuits',
    })
  }

  showModalPanelListEditor = ({ onSelectEntity, reload }) => {
    const {
      actions: {
        addPanel: onAddEntity,
        updatePanel: onUpdateEntity,
        deletePanel: onDeleteEntity,
        showModalPanelListEditor,
        showModalPanelForm,
      },
      meterEntity: { item: meter },
    } = this.props
    const { generation, siteId } = meter
    const conditionalFields = generation ? getConditionalFields(generation) : {}
    showModalPanelListEditor({
      generation,
      conditionalFields,
      entitySelector: selectPanelListEntity,
      onAddEntity,
      onDeleteEntity,
      onSelectEntity,
      onUpdateEntity,
      reload,
      showModalPanelForm,
      siteId,
    })
  }

  showPhaseGroupEditor = (onSelectEntity) => {
    const {
      actions: {
        addPhaseGroup,
        updatePhaseGroup: onUpdateEntity,
        deletePhaseGroup: onDeleteEntity,
        showEntityEditorModal,
      },
      meterEntity: {
        item: { id: meterId, siteId },
      },
    } = this.props

    const onAddEntity = ({ name }: { name: string }) =>
      addPhaseGroup({
        name,
        meterId,
      })

    showEntityEditorModal({
      siteId,
      onSelectEntity,
      onAddEntity,
      onUpdateEntity,
      onDeleteEntity,
      entitySelector: getPhaseGroupListEntity,
      singularName: 'phase group',
      pluralName: 'phase groups',
      parentPluralName: 'circuits',
      addEntityText: 'Add a phase group in the box below.',
    })
  }

  showEquipmentEditor = (onSelectEntity) => {
    const {
      actions: {
        addEquipment: onAddEntity,
        updateEquipment: onUpdateEntity,
        deleteEquipment: onDeleteEntity,
        showEntityEditorModal,
      },
      meterEntity: {
        item: { siteId },
      },
    } = this.props
    showEntityEditorModal({
      siteId,
      onSelectEntity,
      onAddEntity,
      onUpdateEntity,
      onDeleteEntity,
      entitySelector: selectEquipmentListEntity,
      singularName: 'equipment',
      pluralName: 'equipment',
      parentPluralName: 'circuits',
      addEntityText: 'Add equipment in the box below.',
    })
  }

  getCircuitListFetchProps = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const { source, id: meterId } = meter
    return circuitUtils.getCircuitListFetchProps({
      source,
      meterId,
    })
  }

  dispatchWithMeter() {
    const {
      actions,
      meterEntity: { item: meter },
    } = this.props
    const { id: meterId, siteId } = meter
    const orderBy = { field: 'name' }

    actions.fetchSite({ siteId })
    actions.fetchAllPanels({ siteId, orderBy })
    actions.fetchEquipmentList({ siteId, orderBy })
    actions.fetchPhaseGroupList({ meterId })
    actions.loadAllCircuits(this.getCircuitListFetchProps())
  }

  renderBreadcrumbs = () => {
    const {
      location: { pathname },
      match: {
        params: { customerId, siteId },
      },
      customerEntity,
      siteEntity,
      meterEntity: { item: meter },
    } = this.props
    let items = []

    if (meter) {
      items = [
        {
          href: '/account-management',
          text: 'Accounts',
        },
      ]
      const rootPath = pathname.replace(/\/circuits\/edit.*$/, '')
      const circuitsPath = `${rootPath}/circuits`
      const metersPath = rootPath.replace(/\/meters\/.*$/, '/meters')

      if (siteId) {
        const { item: customer } = customerEntity
        const { item: site } = siteEntity
        const siteIdPath = rootPath.replace(/\/meters\/.*$/, '')

        if (customerId) {
          items = [
            ...items,
            {
              href: '/account-management/customers',
              text: 'Customers',
            },
            {
              href: `/account-management/customers/${customerId}`,
              text: (customer && customer.name) || '',
            },
          ]
        } else {
          items = [
            ...items,
            {
              href: '/account-management/sites',
              text: 'Sites',
            },
          ]
        }

        items = [
          ...items,
          {
            href: siteIdPath,
            text: (site && site.validName) || '',
          },
        ]
      }

      items = [
        ...items,
        {
          href: metersPath,
          text: 'Meters',
        },
        {
          href: rootPath,
          text: (meter && meter.name) || '',
        },
        {
          href: circuitsPath,
          text: 'Channel Tags',
        },
      ]
    }

    return <Breadcrumbs items={items} />
  }

  render() {
    const {
      panelListEntity: { allItems: panels },
      panelById,
      equipmentListEntity: { items: equipment },
      equipmentById,
      circuitListEntity,
      meterEntity,
      actions,
      bsListEntity: { items: buildingSystems },
      bsById,
      history,
      phaseGroups,
      phaseGroupsById,
      siteEntity,
    } = this.props
    const { item: site } = siteEntity || {}
    const { meterStartDate } = site || {
      meterStartDate: '',
    }
    const {
      items,
      meta: { loading: circuitsLoading, error },
    } = circuitListEntity
    const {
      item: meter,
      meta: { loading: meterLoading },
    } = meterEntity
    const { generation } = meter || {}

    if (!meterStartDate) {
      return <div>Loading...</div>
    }

    const circuits = items.map((circuit) => ({
      ...this.tableFields.reduce((tableFields, tableFieldName) => {
        let defaultValue = null

        if (
          tableFieldName === 'isBigBang' ||
          tableFieldName === 'meteredUpstream'
        ) {
          defaultValue = false
        }

        if (
          tableFieldName === 'equipmentId' ||
          tableFieldName === 'buildingSystemId'
        ) {
          defaultValue = ''
        }

        return {
          ...tableFields,
          [tableFieldName]: circuit[tableFieldName] || defaultValue,
        }
      }, {}),
    }))
    const formProps = {
      conditionalFields: generation ? getConditionalFields(generation) : {},
      circuits,
      circuitListEntity,
      error,
      panels,
      panelById,
      equipment,
      equipmentById,
      buildingSystems,
      bsById,
      goBack: history.goBack,
      editableFields: this.editableFields,
      showCircuitUpdateConfirm: actions.showConfirmModal,
      loadAllCircuits: actions.loadAllCircuits,
      getCircuitListFetchProps: this.getCircuitListFetchProps,
      submitAction: this.onSubmit,
      showEquipmentEditor: this.showEquipmentEditor,
      showPanelEditor: this.showPanelEditor,
      showPhaseGroupEditor: this.showPhaseGroupEditor,
      showModalPanelForm: actions.showModalPanelForm,
      showModalPanelListEditor: this.showModalPanelListEditor,
      meter,
      meterStartDate,
      phaseGroups,
      phaseGroupsById,
      site,
      buildingEquipments: this.props.buildingEquipments?.data,
      buildingEquipmentsLoading: this.props.buildingEquipments?.loading,
      onAddEquipment: this.onAddEquipment,
      fetchBuildingEquipments: actions.fetchBuildingEquipments,
    }
    const loading = circuitsLoading || meterLoading
    return (
      <Component className='CircuitListFormPage'>
        <div className='CircuitListFormPage-container'>
          {this.renderBreadcrumbs()}
          <div className='CircleListFormPage-title'>Edit Channel Tags</div>

          {!loading && (
            <div>
              {circuits.length ?
                <FormikCircuitListForm {...formProps} />
              : <div>No Channel Tags are associated with this meter</div>}
            </div>
          )}

          {loading && <div>Loading...</div>}
        </div>
      </Component>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(circuitActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(bsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(meterActions, dispatch),
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(phaseGroupActions, dispatch),
    ...bindActionCreators(equipmentActions, dispatch),
    ...bindActionCreators(buildingEquimentActions, dispatch),
  },
})

const mapStateToProps = (
  state,
  {
    match: {
      params: { customerId, meterId },
    },
    meterEntity,
  },
) => {
  const { item: meter } = meterEntity || {}
  const { siteId } = meter || {
    siteId: '',
  }
  return {
    siteEntity: selectSiteEntity(state, siteId),
    customerEntity: selectCustomerEntity(state, customerId),
    bsListEntity: bsSelectors.selectListEntity(state),
    bsById: bsSelectors.selectById(state),
    panelById: panelSelectById(state),
    phaseGroups: getPhaseGroups(state),
    phaseGroupsById: getPhaseGroupsById(state),
    circuitListEntity: getCircuitListEntity(state),
    meterEntity: selectMeterEntity(state, meterId),
    panelListEntity: selectPanelListEntity(state),
    equipmentListEntity: selectEquipmentListEntity(state),
    equipmentById: equipmentSelectById(state),
    buildingEquipments: selectBuildingEquipments(state),
  }
}

export default withNotice(
  connect(mapStateToProps, mapDispatchToProps)(CircuitListFormPage),
)
