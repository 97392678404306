import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import {
  getCircuitEntity,
  actions as circuitActions,
} from '../../ducks/circuits'
import {
  selectCustomerEntity,
  actions as customerActions,
} from '../../ducks/customers'
import { selectSiteEntity, actions as siteActions } from '../../ducks/sites'
import type { FTRouterLocation } from '../../types'
import '../../types'
import Breadcrumbs from '../../components/Breadcrumbs'
import ActionPaneView from '../../components/ActionPaneView'
import VerticalTable from '../../components/VerticalTable'

type FTProps = FTRouterLocation

class CircuitDetailPage extends PureComponent<FTProps> {
  componentDidMount() {
    const {
      match: {
        params: { circuitId, customerId, siteId },
      },
    } = this.props
    this.props.actions.loadCircuit({
      circuitId,
    })

    if (customerId) {
      this.props.actions.fetchCustomer({
        customerId,
      })
    }

    if (siteId) {
      this.props.actions.fetchSite({
        siteId,
      })
    }
  }

  renderBreadcrumbs = () => {
    let items = [
      {
        href: '/account-management',
        text: 'Accounts',
      },
      {
        href: '/account-management/customers',
        text: 'Customers',
      },
    ]
    const { customerEntity, siteEntity } = this.props

    if (customerEntity.item && siteEntity.item) {
      const { item: customer } = customerEntity
      const { item: site } = siteEntity
      items = [
        ...items,
        {
          href: `/account-management/customers/${customer.id}`,
          text: customer.name,
        },
        {
          href: `/account-management/customers/${customer.id}/sites/${site.id}`,
          text: site.display,
        },
      ]
    }

    return <Breadcrumbs items={items} />
  }

  renderMain = () => {
    const {
      circuitEntity: { item: circuit },
    } = this.props
    let fields = []

    if (circuit) {
      const {
        name,
        description,
        meterSummary,
        buildingSystemSummary,
        modified,
        breakerNumber,
        externalId,
        source,
      } = circuit
      const bsName =
        buildingSystemSummary ? buildingSystemSummary.name : 'Unavailable'
      const meterName = meterSummary ? meterSummary.name : 'Unavailable'
      const lastReported =
        modified ? moment(modified).format('YYYY-MM-DD') : 'Unavailable'
      fields = [
        {
          value: name,
          label: 'Name',
          editable: false,
        },
        {
          value: description,
          label: 'Description',
          editable: false,
        },
        {
          value: bsName,
          label: 'Building System',
          editable: false,
        },
        {
          value: meterName,
          label: 'Meter',
          editable: false,
        },
        {
          value: breakerNumber,
          label: 'Breaker Number',
          editable: false,
        },
        {
          value: source,
          label: 'Source',
          editable: false,
        },
        {
          value: externalId,
          label: 'External ID',
          editable: false,
        },
        {
          value: lastReported,
          label: 'Last Reported',
          editable: false,
        },
      ]
    }

    return (
      <div className='CircuitDetailPage-main'>
        {this.renderBreadcrumbs()}
        {circuit && <VerticalTable.Basic fields={fields} columned={false} />}
      </div>
    )
  }

  render() {
    return (
      <div className='CircuitDetailPage'>
        <ActionPaneView actions={[]} renderMain={this.renderMain} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(circuitActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
  },
})

const mapStateToProps = (state, props) => ({
  circuitEntity: getCircuitEntity(state, props.match.params.circuitId),
  customerEntity: selectCustomerEntity(state, props.match.params.customerId),
  siteEntity: selectSiteEntity(state, props.match.params.siteId),
})

export default connect(mapStateToProps, mapDispatchToProps)(CircuitDetailPage)
