import moment from 'moment'
import { getLatLng } from 'react-places-autocomplete'
import { queryStringify } from '../../api/utils'

const TIMEZONE_API_URL = 'https://maps.googleapis.com/maps/api/timezone/json'
const TIMEZONE_API_KEY = 'AIzaSyDM106DbXvMaxQQvMZQrSNpN8CcDTUACXs'
export const tryAgainMessage = 'Please try again or adjust the address.'
const placeTypesLookup = {
  route: 'long_name',
  street_number: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_3: 'long_name',
  country: 'short_name',
  postal_code: 'long_name',
  postal_town: 'long_name',
}
export const extractPlaceAddressFields = (place) => {
  const { address_components: components } = place
  return components.reduce((response, current) => {
    const { types } = current
    // types is typically ['locality', 'political']
    // where 'locality' is what we want, and 'political' is a sort of secondary
    // tag. The documentation indicates that the array is unordered, hence we
    // need to search.
    types.forEach((t) => {
      const value = placeTypesLookup[t]

      if (value) {
        response[t] = current[value]
      }
    })
    return {
      ...response,
      locality:
        response.locality ||
        response.administrative_area_level_3 ||
        response.postal_town,
    }
  }, {})
}
export const validateRequiredAddressFields = (address) => {
  const required = [['country', 'Country']]
  required.forEach(([field, name]) => {
    if (!address[field]) {
      throw new Error(`Missing required field: ${name}. ${tryAgainMessage}`)
    }
  })
}

const coercePlaceToAddress = (place) => {
  const {
    route: street = '',
    street_number: streetNumber = '',
    locality: city = '',
    administrative_area_level_1: state = '',
    country,
    postal_code: postalCode = '',
  } = place
  const address1 = street ? `${streetNumber} ${street}` : ''
  const address = {
    streetNumber,
    street,
    address1,
    address2: '',
    city,
    state,
    postalCode,
    country,
  }
  validateRequiredAddressFields(address)
  return address
}

export const getAddressFromPlace = (place) =>
  coercePlaceToAddress(extractPlaceAddressFields(place))
export const fetchTimezoneId = async (place) => {
  const { lat, lng } = await getLatLng(place)
  const epochNow = moment().format('X')
  const params = queryStringify({
    key: TIMEZONE_API_KEY,
    location: `${lat},${lng}`,
    timestamp: epochNow,
  })
  const tzResponse = await fetch(`${TIMEZONE_API_URL}?${params}`)
  const tz = await tzResponse.json()

  if (tz.status === 'OVER_QUERY_LIMIT') {
    throw new Error(
      'Request failed; API quota reached. Please contact the Redaptive administrator.',
    )
  } else if (tz.status !== 'OK') {
    throw new Error(`Unable to lookup timezone. ${tryAgainMessage}`)
  }

  if (!tz || !tz.timeZoneId) {
    throw new Error(`Unable to extract timezone. ${tryAgainMessage}`)
  }

  return tz.timeZoneId
}
