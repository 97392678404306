import moment from 'moment'
import { defaultUtilityInflationRate } from '../pages/ProposalOperations/ProposalsEngine/utils'
import { InflationRateItem } from '../ducks/proposals/utilityInflationRateData'

export const hasUtilityInflationRateData = () => {
  const cachedData = localStorage.getItem(UTILITY_INFLATION_RATE_DATA)
  const cachedDataDate = localStorage.getItem(UTILITY_INFLATION_RATE_DATE)
  const todayDate = moment().format(YYYY_MM_DD)
  if (cachedData?.length && cachedDataDate === todayDate) return cachedData
  return false
}

export const getUtilityInflationRate = (shippingStateCode: string) => {
  const inflationRateDataString = hasUtilityInflationRateData()
  if (inflationRateDataString && shippingStateCode) {
    const inflationRateData = JSON.parse(inflationRateDataString)
    const inflationRate =
      inflationRateData.find(
        (item: InflationRateItem) => item.stateCode === shippingStateCode,
      )?.electricityInflationRate ?? defaultUtilityInflationRate
    return inflationRate
  }
  return defaultUtilityInflationRate
}

export const UTILITY_INFLATION_RATE_DATA = 'utilityInflationRateData'
export const UTILITY_INFLATION_RATE_DATE = 'utilityInflationRateDate'
export const YYYY_MM_DD = 'YYYY-MM-DD'
