import { useEffect, useMemo } from 'react'
import { SelectColumnMultiFilterListSelectorStyled } from '../../../../components/RedaptiveReactTable7'
import type { MultiSelectProps } from '../types'
import '../types'

export default function MultiSelectFilter({
  selectedFilter,
  setSelectedFilter,
  column: { filterValue = selectedFilter, setFilter, preFilteredRows, id },
  tableData,
  filterKey,
}: MultiSelectProps) {
  const finalFilterKey = filterKey || id
  useEffect(() => {
    if (selectedFilter) {
      setFilter(selectedFilter)
    }
  }, [selectedFilter])
  const items = useMemo(() => {
    const data = [
      ...new Set(
        preFilteredRows ?
          preFilteredRows?.map((row) =>
            Array.isArray(row?.values?.[finalFilterKey]) ?
              row?.values?.[finalFilterKey]?.[0]
            : row?.values?.[finalFilterKey],
          )
        : tableData.map((row) => row[finalFilterKey]),
      ),
    ]
    const filteredOptions = data.filter(
      (item) => item !== null && item !== undefined,
    )
    return filteredOptions.map((value) => ({
      id: value,
      name: value,
    }))
  }, [preFilteredRows, tableData])
  return (
    <SelectColumnMultiFilterListSelectorStyled
      isMulti
      items={items}
      unsettable={false}
      updateValueMulti={(newValues) => {
        const newselectedFilter = newValues.map((newValue) => newValue.value)
        setFilter(newselectedFilter)
        setSelectedFilter(newselectedFilter)
      }}
      selectedItems={items.filter((item) =>
        item && filterValue ? filterValue.includes(item.id) : false,
      )}
    />
  )
}
