// All values are actuals from the Wesco NTP model, LIGHT - Wesco - N59 W13855 Manhardt Drive - Menomonee Falls - WI.
export default {
  annualEnergyPayment: 12243.6,
  insuranceForEveryHundredDollarsOfProjectCost: 0,
  annualMaintenanceSavings: 655,
  transferRate: 10,
  baseCost: 79163.46,
  capexMarginSeek: 0,
  contingencyCost: 1583.27,
  contingencyInPercentage: 2,
  contractRate: 0.1216,
  costReduction: 0,
  dealType: 'EAAS',
  energyContractValue: 122435.95,
  materialCosts: 31262.89,
  estimatedSalesTax: '',
  estimatedSalesTaxInPercentage: 0,
  utilityRate: 0.125,
  grossMargin: 39873.65,
  grossMarginInPercentage: 31.1,
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  incentives: 0,
  laborCosts: 20913.62,
  insuranceFee: 0,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong
  // and the IRR calculation has been updated to use the Cyrus model.
  internalRateOfReturn: 7.44,
  lightingAddFixtureCount: 11,
  totalProposedFixtureCount: 131,
  lightingNoChange: 0,
  annualPostBurnHours: 61,
  annualPreBurnHours: 108,
  lightingRelampBypassBallastCount: 15,
  lightingRelampCount: 5,
  lightingRemoveFixtures: 0,
  lightingReplaceFixtureCount: 45,
  lightingRetrofitKitCount: 55,
  lightingSensorsOnly: 0,
  maintenanceContractValue: 5764,
  maintenanceObligation: 0,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  maintenanceRepairAndOperationsAnnualPayment: 576.4,
  // This field differs from the Wesco model because the calculation in the Wesco
  // model was wrong.
  maintenanceSavingsPerFixture: 5,
  maintenanceRetainedSavingsInPercentage: 12,
  measurementAndVerificationCostAsPerProjectCosts: 3,
  costPerMeter: 1800.0,
  measurementAndVerificationSource: 'METER_OPS',
  meterCount: 0,
  name: 'LIGHT - Wesco - N59 W13855 Manhardt Drive - Menomonee Falls - WI',
  netCost: 88326.3,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  netMarginInDollars: -11526.4,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  netMarginInPercentage: -15.01,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  npvNetRevenue: 76799.9,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  npvOngoingCosts: 4042.1,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  npvRevenue: 80842,
  operationsAndMaintenanceBasis: 'NPV_REVENUE',
  operationsAndMaintenance: 5,
  opportunityId: 'R00010259',
  annualEnergySavingsInDollars: 12590.68,
  otherCost: 26986.95,
  postKw: 1000,
  annualPostKwh: 60570.57,
  preKw: 1500,
  annualPreKwh: 161296.02,
  partnerFee: 7916.35,
  partnerFeeInPercentage: 10,
  initialRebateAmount: 4521,
  rebateCollection: '-- None --',
  rebateHcInPercentage: 5,
  finalRebateAmount: 4294.95,
  reduction: 0,
  referralCost: 3958.17,
  referralFeeBasis: 'PRE_TAX_GROSS_COST',
  referralFeeInPercentage: 5,
  salesforceSiteId: '0014o00001nJw3oAAC',
  vendorSalesTax: 0,
  energyRetainedSavingsInPercentage: 12,
  annualSavedKwh: 100725.45,
  simplePaybackEnergyAndMaintenance: 6.67,
  status: 'PENDING',
  contractTermMonths: 120,
  totalNte: 79163.46,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  preTaxContractValue: 128199.95,
  totalContractValueWithSalesTax: 128199.95,
  // This field differs from the Wesco model because the calculation for
  // maintenanceSavingsPerFixture in the Wesco model was wrong.
  totalOngoingContractValue: 128199.95,
  ttmUtilityUsage: 69892,
  trailing12MonthsUtilityBill: 10142.45,
  ttmBillReductionInPercentage: 144.12,
  upFrontPayment: 0,
  upFrontMeasurementAndVerification: 0,
  referral2Cost: 0,
  blockKwh: 1007254.5,
  savedDollarsOnAvoidedRecPurchase: 0,
  savedKW: 500,
  metricTonsOfCo2AvoidedAnnually: 0.05,
  metricTonsOfCo2AvoidedContractTerm: 0.5,
  emissionRate: 1,
  simplePaybackEnergyOnly: 7.02,
  postTotalMaintenanceCost: 0,
  preTotalMaintenanceCost: 0,
  kWhSavingsByEUL: 0,
  metricTonsOfCo2AvoidedOverEul: 0,
  percentInKwhReduction: 62.45,
  monthlyPostBurnHours: 5,
  monthlyPreBurnHours: 9,
  monthlyPaymentToRedaptive: 1068.33,
}
