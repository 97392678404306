export default [
  {
    id: '3d7c8e3d-56e3-4ef3-9f24-d3eb660e1ea5',
    firstName: 'Oscar',
    lastName: 'Lee',
    postedTime: '2021-12-09T09:49:44.169',
    comment: 'Looks good to me.',
    action: 'REASSIGNED_TO',
    issueOwners: 'Meter Design',
  },
  {
    id: '5a4c8e3d-56e3-4ef3-9f24-d3eb660e352c',
    firstName: 'Susan',
    lastName: 'Fake',
    postedTime: '2021-12-08T08:49:44.169',
    comment: 'This is a changelog comment',
    action: 'ASSIGNED_TO',
    issueOwners: 'Meter Maintenance',
  },
  {
    id: '3d7c8e3d-56e3-4ef3-9f24-d3eb660e1ea6',
    firstName: 'Susan',
    lastName: 'Fake',
    postedTime: '2021-12-08T09:49:44.169',
    comment:
      'Selected non suggested billing action, Actual, due to Meter Issues: this site needs attention',
    action: 'SEND',
    issueOwners: null,
  },
  {
    id: '3d7c8e3d-56e3-4ef3-9f24-d3eb660e1ebz',
    firstName: 'Susan',
    lastName: 'Fake',
    postedTime: '2021-12-06T09:49:44.169',
    comment: 'Everything looks good for this bill',
    action: 'Sent to Approval',
    issueOwners: null,
  },
]
