import { takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import { combineReducers } from 'redux'
import { defaultHeaders, consoleBaseUrl } from '../api'
import { handleSagaError } from '../sagas/utils'
import { handleAxiosError } from '../api/utils'

export type FTUploadFileAction = {
  file: any,
  siteId: string | number
}
type FTAuditVendorMeta = {
  error: string
  isUploading: boolean
}
type FTAuditVendorState = {
    uploadResponse: string
    meta: FTAuditVendorMeta
}
type FTState = {
    auditVendor: FTAuditVendorState
}
const types = {
  UPLOAD_AUDIT_FILE: 'UPLOAD_AUDIT_FILE',
  UPLOAD_AUDIT_FILE_ERROR: 'UPLOAD_AUDIT_FILE_ERROR',
  UPLOAD_AUDIT_FILE_SUCCESS: 'UPLOAD_AUDIT_FILE_SUCCESS',
  RESET_AUDIT_FILE_STATE: 'RESET_AUDIT_FILE_STATE',
}

export const selectAuditVendorEntity = (
  state: FTState,
): FTAuditVendorState => state.auditVendor
const initialState: FTAuditVendorState = {
    uploadResponse: "",
    meta: {
      error: '',
      isUploading: false,
    },
}

const auditVendor = (
  state = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.UPLOAD_AUDIT_FILE:
      return {
        ...state,
        uploadResponse: null,
        meta: {
          error: '',
          isUploading: true,
        },
      }

    case types.UPLOAD_AUDIT_FILE_ERROR:
      return {
        ...state,
        meta: {
          error: action.error,
          isUploading: false,
        },
        uploadResponse: null,
      }

    case types.UPLOAD_AUDIT_FILE_SUCCESS:
      return {
        ...state,
        meta: {
          error: '',
          isUploading: false,
        },
        uploadResponse: action.payload,
      }
    case types.RESET_AUDIT_FILE_STATE:
      return initialState  

    default:
      return state
  }
}

export default combineReducers({
  auditVendor,
})

export const actions = {
  uploadAuditFile: (params: FTUploadFileAction) => ({
    type: types.UPLOAD_AUDIT_FILE,
    ...params,
  }),
  resetAuditFileState: () => ({
    type: types.RESET_AUDIT_FILE_STATE,
  }),
}

// API
class API {
  static uploadAuditFile({ file, siteId }: Record<string, any>) {
    const url = `${consoleBaseUrl()}/proposal/api/vendor-file/validate?siteId=${siteId}`
    const formData = new FormData()
    formData.set('file', file)
    return axios({
      method: 'post',
      url,
      data: formData,
      headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
      responseType: 'blob'
    })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* uploadAuditFileSagas(
  params: FTUploadFileAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.uploadAuditFile, params)
    yield put({
      type: types.UPLOAD_AUDIT_FILE_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.UPLOAD_AUDIT_FILE_ERROR, error)
  }
}

export const sagas = [
  takeEvery(types.UPLOAD_AUDIT_FILE, uploadAuditFileSagas),
]
