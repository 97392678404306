import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ConnectedCustomerForm from '../../components/CustomerForm'
import {
  actions as customerActions,
  selectCustomerEntity,
} from '../../ducks/customers'
import * as status from '../../constants/status'
import type { FTHistory } from '../../types'
import '../../types'

type FTProps = {
  error: string
  loading?: boolean
  history: FTHistory
  customer: Record<string, any> | null | undefined
  customerId: string | null | undefined
  success: boolean
  actions: Record<string, any>
}

class CustomerFormPage extends Component<FTProps> {
  static defaultProps = {
    loading: false,
  }

  componentDidMount() {
    const { customerId, actions } = this.props

    if (customerId) {
      actions.fetchCustomer({
        customerId,
      })
    }
  }

  componentDidUpdate() {
    const { error, success } = this.props

    if (!error && success) {
      const { customer, history } = this.props
      const redirect =
        customer && customer.id ?
          `/account-management/customers/${customer.id}`
        : '/account-management/customers'
      history.push(redirect)
    }
  }

  componentWillUnmount() {
    this.props.actions.clearCustomerForm()
  }

  handleSubmit = (body) => {
    if (this.props.customer) {
      this.props.actions.updateCustomer(this.props.customer.id, body)
    } else {
      this.props.actions.addCustomer(body)
    }
  }

  render() {
    const { error, loading, customer } = this.props
    return (
      <ConnectedCustomerForm
        onSubmit={this.handleSubmit}
        formError={error}
        loading={loading}
        customer={customer}
        initialValues={customer}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(customerActions, dispatch),
})

const mapStateToProps = (state, props) => {
  const { requestStatus } = state
  const { status: dataStatus } = requestStatus.customers
  const { customerId } = props.match.params
  const customerEntity = selectCustomerEntity(state, customerId)
  const {
    form: { loading: formLoading, error, success },
    item: customer,
  } = customerEntity
  return {
    customerId,
    customer,
    error,
    success,
    loading: dataStatus === status.LOADING || formLoading,
    customerEntity,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFormPage)
