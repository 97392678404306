import axios from 'axios'
import { consoleApiUrl, defaultHeaders } from './index'
import { queryStringify, handleAxiosError } from './utils'
import type { FTAuthCreds } from '../types'
import '../types'

const PasswordExpiredException = (passwordResetId: string) => ({
  message: 'Password Expired',
  name: 'PasswordExpiredException',
  passwordResetId,
  toString: 'PasswordExpiredException',
})

const handleAuthenticationError = (error: Record<string, any>) => {
  const { response } = error || {}
  const { status, data } = response || {}
  const { message } = data || {}

  if (status === 419 && message === 'Password Expired') {
    const { passwordResetId } = data
    throw PasswordExpiredException(passwordResetId)
  } else {
    handleAxiosError(error)
  }
}

class RedaptiveApi {
  static authenticate({ username, password }: FTAuthCreds) {
    const query = queryStringify({
      username,
      password,
    })
    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    return axios
      .post(`${consoleApiUrl()}/sessions`, query, headers)
      .then(({ data }) => data)
      .catch(handleAuthenticationError)
  }

  static logout({ loginToken }: { loginToken: string }) {
    return axios
      .delete(`${consoleApiUrl()}/sessions/${loginToken}`, {
        headers: defaultHeaders(),
      }) // eslint-disable-next-line no-console
      .catch((error) => console.error(error.message))
  }
}

export default RedaptiveApi
