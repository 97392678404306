/* eslint-disable @typescript-eslint/default-param-last */
import axios from 'axios'
import { AnyAction, combineReducers } from 'redux'
import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { handleSagaError } from '../../sagas/utils'
import { isVariantActive } from '../../utils'
import {
  monthlySummaryMapper,
  statusCodeMapper,
  submissionsMapper,
} from './mapper'
import variantData from './variantData.json'
import {
  TSEnergyStarSubmissions,
  TSEnergyStarEntityState,
  TSEnergyStarSubmissionPayload,
  TSEnergyStarCustomerStatus,
  TSEnergyStarSiteStatus,
  TSEnergyStarWaterMeterResponse,
  TSEnergyStarAlltimeSummary,
  TSEnergyStarMonthlySummary,
} from './types'
// Action Types
const types = {
  FETCH_SUBMISSION_DATA: 'FETCH_SUBMISSION_DATA',
  FETCH_SUBMISSION_DATA_SUCCESS: 'FETCH_SUBMISSION_DATA_SUCCESS',
  FETCH_SUBMISSION_DATA_ERROR: 'FETCH_SUBMISSION_DATA_ERROR',
  UPLOAD_SUBMISSION_DATA: 'UPLOAD_SUBMISSION_DATA',
  UPLOAD_SUBMISSION_DATA_SUCCESS: 'UPLOAD_SUBMISSION_DATA_SUCCESS',
  UPLOAD_SUBMISSION_DATA_ERROR: 'UPLOAD_SUBMISSION_DATA_ERROR',
  FETCH_ES_CUSTOMER_ID_STATUS: 'FETCH_ES_CUSTOMER_ID_STATUS',
  FETCH_ES_CUSTOMER_ID_STATUS_SUCCESS: 'FETCH_ES_CUSTOMER_ID_STATUS_SUCCESS',
  FETCH_ES_CUSTOMER_ID_STATUS_ERROR: 'FETCH_ES_CUSTOMER_ID_STATUS_ERROR',
  UPDATE_ES_CUSTOMER_ID_STATUS: 'UPDATE_ES_CUSTOMER_ID_STATUS',
  UPDATE_ES_CUSTOMER_ID_STATUS_SUCCESS: 'UPDATE_ES_CUSTOMER_ID_STATUS_SUCCESS',
  UPDATE_ES_CUSTOMER_ID_STATUS_ERROR: 'UPDATE_ES_CUSTOMER_ID_STATUS_ERROR',
  FETCH_ES_SITE_ID_STATUS: 'FETCH_ES_SITE_ID_STATUS',
  FETCH_ES_SITE_ID_STATUS_SUCCESS: 'FETCH_ES_SITE_ID_STATUS_SUCCESS',
  FETCH_ES_SITE_ID_STATUS_ERROR: 'FETCH_ES_SITE_ID_STATUS_ERROR',
  UPDATE_ES_SITE_ID_STATUS: 'UPDATE_ES_SITE_ID_STATUS',
  UPDATE_ES_SITE_ID_STATUS_SUCCESS: 'UPDATE_ES_SITE_ID_STATUS_SUCCESS',
  UPDATE_ES_SITE_ID_STATUS_ERROR: 'UPDATE_ES_SITE_ID_STATUS_ERROR',
  DELETE_ES_METER: 'DELETE_ES_METER',
  DELETE_ES_METER_SUCCESS: 'DELETE_ES_METER_SUCCESS',
  DELETE_ES_METER_ERROR: 'DELETE_ES_METER_ERROR',
  FETCH_SUMMARY_ALL_TIME: 'FETCH_SUMMARY_ALL_TIME',
  FETCH_SUMMARY_ALL_TIME_SUCCESS: 'FETCH_SUMMARY_ALL_TIME_SUCCESS',
  FETCH_SUMMARY_ALL_TIME_ERROR: 'FETCH_SUMMARY_ALL_TIME_ERROR',
  FETCH_SUMMARY_MONTHLY: 'FETCH_SUMMARY_MONTHLY',
  FETCH_SUMMARY_MONTHLY_SUCCESS: 'FETCH_SUMMARY_MONTHLY_SUCCESS',
  FETCH_SUMMARY_MONTHLY_ERROR: 'FETCH_SUMMARY_MONTHLY_ERROR',
  RE_SYNC_SUBMISSION_DATA: 'RE_SYNC_SUBMISSION_DATA',
  RE_SYNC_SUBMISSION_DATA_SUCCESS: 'RE_SYNC_SUBMISSION_DATA_SUCCESS',
  RE_SYNC_SUBMISSION_DATA_ERROR: 'RE_SYNC_SUBMISSION_DATA_ERROR',
  FETCH_WATER_METER: 'FETCH_WATER_METER',
  FETCH_WATER_METER_SUCCESS: 'FETCH_WATER_METER_SUCCESS',
  FETCH_WATER_METER_ERROR: 'FETCH_WATER_METER_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_ERROR_SUCCESS: 'CLEAR_ERROR_SUCCESS',
  CLEAR_ERROR_ERROR: 'CLEAR_ERROR_ERROR',
}

export const energyStarSubmissionsEntity = (state): TSEnergyStarEntityState =>
  state.energyStarIntegration

const initialState: TSEnergyStarEntityState = {
  customerStatus: {
    enabled: false,
  },
  siteStatus: {
    enabled: false,
  },
  submissionsData: {
    items: [],
    exportData: {
      submitted: [],
      'not-submitted': [],
    },
  },
  summaryData: {
    allTime: null,
    monthly: null,
  },
  meta: {
    error: '',
    loading: true,
    suppressLoading: false,
  },
}
export const actions = {
  fetchSubmissionsData: ({ yearMonth }: { yearMonth: string }) => ({
    type: types.FETCH_SUBMISSION_DATA,
    yearMonth,
  }),
  fetchSummaryAllTime: ({ yearMonth }: { yearMonth: string }) => ({
    type: types.FETCH_SUMMARY_ALL_TIME,
    yearMonth,
  }),
  fetchSummaryMonthly: ({ yearMonth }: { yearMonth: string }) => ({
    type: types.FETCH_SUMMARY_MONTHLY,
    yearMonth,
  }),
  uploadSubmissionsData: (params: TSEnergyStarSubmissionPayload) => ({
    type: types.UPLOAD_SUBMISSION_DATA,
    ...params,
  }),
  reSyncSubmissionsData: (params: TSEnergyStarSubmissionPayload) => ({
    type: types.RE_SYNC_SUBMISSION_DATA,
    ...params,
  }),
  getESCustomerIDStatus: (params: { customerId: string }) => ({
    type: types.FETCH_ES_CUSTOMER_ID_STATUS,
    ...params,
  }),
  updateESCustomerIDStatus: (params: {
    customerId: string
    reqData: TSEnergyStarCustomerStatus
  }) => ({
    type: types.UPDATE_ES_CUSTOMER_ID_STATUS,
    ...params,
  }),
  getESSiteIDStatus: (params: { redaptiveSiteId: string }) => ({
    type: types.FETCH_ES_SITE_ID_STATUS,
    ...params,
  }),
  updateESSiteIDStatus: (params: {
    redaptiveSiteId: string
    reqData: TSEnergyStarSiteStatus
  }) => ({
    type: types.UPDATE_ES_SITE_ID_STATUS,
    ...params,
  }),
  deleteESMeter: (params: { meterId: string }) => ({
    type: types.DELETE_ES_METER,
    ...params,
  }),
  fetchWaterMeter: (params: { customerId: string }) => ({
    type: types.FETCH_WATER_METER,
    ...params,
  }),
  clearError: () => ({ type: types.CLEAR_ERROR }),
}

function summaryData(state = initialState.summaryData, action: AnyAction) {
  switch (action.type) {
    case types.FETCH_SUMMARY_ALL_TIME_SUCCESS:
      return { ...state, allTime: { ...action.payload } }

    case types.FETCH_SUMMARY_MONTHLY_SUCCESS:
      return { ...state, monthly: [...action.payload] }

    default:
      return state
  }
}

function customerStatus(
  state = initialState.customerStatus,
  action: AnyAction,
) {
  switch (action.type) {
    case types.FETCH_ES_CUSTOMER_ID_STATUS_SUCCESS:
    case types.UPDATE_ES_CUSTOMER_ID_STATUS_SUCCESS:
      return { ...action.payload }

    default:
      return state
  }
}

function submissionsData(
  state = initialState.submissionsData,
  action: AnyAction,
) {
  switch (action.type) {
    case types.FETCH_SUBMISSION_DATA_SUCCESS:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

function siteStatus(state = initialState.siteStatus, action: AnyAction) {
  switch (action.type) {
    case types.FETCH_ES_SITE_ID_STATUS: {
      return initialState.siteStatus
    }
    case types.FETCH_ES_SITE_ID_STATUS_SUCCESS:
      return { ...state, ...action.payload }

    case types.FETCH_WATER_METER_SUCCESS:
      return { ...state, waterMeterList: [...action.payload] }

    default:
      return state
  }
}

function meta(state = initialState.meta, action: AnyAction) {
  switch (action.type) {
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      }
    case types.RE_SYNC_SUBMISSION_DATA:
      return { ...state, error: '', suppressLoading: true }

    case types.FETCH_SUBMISSION_DATA:
    case types.FETCH_ES_CUSTOMER_ID_STATUS:
    case types.UPDATE_ES_CUSTOMER_ID_STATUS:
    case types.FETCH_ES_SITE_ID_STATUS:
    case types.UPDATE_ES_SITE_ID_STATUS:
    case types.UPLOAD_SUBMISSION_DATA:
    case types.DELETE_ES_METER:
    case types.FETCH_SUMMARY_ALL_TIME:
    case types.FETCH_SUMMARY_MONTHLY:
      return {
        ...state,
        error: '',
        loading: true,
        entityId: null,
        entityName: null,
      }

    case types.FETCH_SUBMISSION_DATA_ERROR:
    case types.FETCH_ES_CUSTOMER_ID_STATUS_ERROR:
    case types.UPDATE_ES_CUSTOMER_ID_STATUS_ERROR:
    case types.FETCH_ES_SITE_ID_STATUS_ERROR:
    case types.UPDATE_ES_SITE_ID_STATUS_ERROR:
    case types.UPLOAD_SUBMISSION_DATA_ERROR:
    case types.DELETE_ES_METER_ERROR:
    case types.FETCH_SUMMARY_ALL_TIME_ERROR:
    case types.FETCH_SUMMARY_MONTHLY_ERROR:
    case types.RE_SYNC_SUBMISSION_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        suppressLoading: false,
        entityId: action.energyStarId,
        entityName: action.entityName,
      }

    case types.FETCH_SUBMISSION_DATA_SUCCESS:
    case types.FETCH_ES_CUSTOMER_ID_STATUS_SUCCESS:
    case types.UPDATE_ES_CUSTOMER_ID_STATUS_SUCCESS:
    case types.FETCH_ES_SITE_ID_STATUS_SUCCESS:
    case types.UPDATE_ES_SITE_ID_STATUS_SUCCESS:
    case types.DELETE_ES_METER_SUCCESS:
    case types.FETCH_SUMMARY_MONTHLY_SUCCESS:
    case types.RE_SYNC_SUBMISSION_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        suppressLoading: false,
        entityId: null,
        entityName: null,
      }

    default:
      return state
  }
}

export default combineReducers({
  customerStatus,
  submissionsData,
  siteStatus,
  summaryData,
  meta,
})
const API = {
  getSubmissionsData: (
    yearMonth: string,
  ): Promise<{ items: TSEnergyStarSubmissions[] }> => {
    if (isVariantActive('ORN38mock')) {
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              items: variantData.fetchSubmissions as TSEnergyStarSubmissions[],
            }),
          500,
        )
      })
    }

    const url = `${consoleBaseUrl()}/api/energy-star/submissions?yearMonth=${yearMonth}`
    return axios
      .get<TSEnergyStarSubmissions[]>(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => ({
        items: data,
      }))
      .catch(handleAxiosError)
  },
  reSyncSubmissions: (
    reqData: Pick<TSEnergyStarSubmissionPayload, 'submissionIds'>,
  ): Promise<void> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve().then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 2000)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/energy-star/submissions/resync`
    return axios
      .post<void>(url, reqData, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  uploadSubmissions: (
    reqData: Pick<TSEnergyStarSubmissionPayload, 'submissionIds'>,
  ): Promise<void> => {
    const url = `${consoleBaseUrl()}/api/energy-star/submissions/upload`
    return axios
      .post<void>(url, reqData, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  getESCustomerIDStatus: async (
    customerId: string,
  ): Promise<TSEnergyStarCustomerStatus> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve({
        enabled: true,
        energyStarId: 123321,
      }).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 5000)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/energy-star/customers/${customerId}`
    let data: TSEnergyStarCustomerStatus = {
      enabled: false,
      sendMonthlyReport: false,
    }

    try {
      const response = await axios.get<TSEnergyStarCustomerStatus>(url, {
        headers: defaultHeaders(),
      })
      data = {
        sendMonthlyReport: false,
        ...response.data,
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        data = {
          enabled: false,
          sendMonthlyReport: false,
        }
      } else {
        if (error?.response?.data?.statusCode) {
          throw error.response.data
        }
        handleAxiosError(error)
      }
    }

    return data
  },
  updateESCustomerIDStatus: ({
    reqData,
    customerId,
  }: {
    reqData: TSEnergyStarCustomerStatus
    customerId: string
  }): Promise<void> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve().then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 5000)
          }),
      )
    }
    const url = `${consoleBaseUrl()}/api/energy-star/customers/${customerId}`
    return axios
      .post<void>(url, reqData, { headers: defaultHeaders() })
      .then(({ data }) => data)
      .catch((e) => {
        if (e?.response?.data?.statusCode) {
          throw e.response.data
        }
        handleAxiosError(e)
      })
  },
  getESSiteIDStatus: async (
    redaptiveSiteId: string,
  ): Promise<TSEnergyStarSiteStatus> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve({
        enabled: true,
        energyStarId: 123321,
      }).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 5000)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/energy-star/sites/${redaptiveSiteId}`
    let data

    try {
      const response = await axios.get(url, {
        headers: defaultHeaders(),
      })
      data = { ...response.data }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        data = {
          enabled: false,
        }
      } else {
        if (error?.response?.data?.statusCode) {
          throw error.response.data
        }
        handleAxiosError(error)
      }
    }

    return data
  },
  updateESSiteIDStatusSaga: ({
    reqData,
    redaptiveSiteId,
  }: {
    reqData: TSEnergyStarSiteStatus
    redaptiveSiteId: string
  }): Promise<void> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve().then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 5000)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/energy-star/sites/${redaptiveSiteId}`
    return axios
      .post<void>(url, reqData, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch((e) => {
        if (e?.response?.data?.statusCode) {
          throw e.response.data
        }
        handleAxiosError(e)
      })
  },
  deleteESMeter: (meterId: string): Promise<void> => {
    const url = `${consoleBaseUrl()}/api/energy-star/meters/${meterId}`
    return axios
      .delete<void>(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch((e) => {
        if (e?.response?.data?.statusCode) {
          throw e.response.data
        }
        handleAxiosError(e)
      })
  },
  fetchWaterMeter: (
    siteId: string,
  ): Promise<TSEnergyStarWaterMeterResponse[]> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve().then(
        () =>
          new Promise((resolve) => {
            setTimeout(() => resolve(variantData.waterMeters), 1000)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/energy-star/sites/${siteId}/meters`
    return axios
      .get<TSEnergyStarWaterMeterResponse[]>(url, {
        headers: defaultHeaders(),
        params: {
          resource: 'water',
        },
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchSummaryAllTime: (): Promise<TSEnergyStarAlltimeSummary> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve({
        enabledCustomers: 9,
        totalSites: 529,
        enabledSites: 443,
        failedSubmissions: 443,
      }).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 5000)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/energy-star/submissions/summary/all-time`
    return axios
      .get<TSEnergyStarAlltimeSummary>(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchSummaryMonthly: (
    yearMonth: string,
  ): Promise<TSEnergyStarMonthlySummary> => {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve([
        {
          customerName: 'ALG',
          enabledSites: 20,
          sitesSubmittedForElectricity: 15,
          totalSitesForElectricity: 20,
          sitesSubmittedForWater: 5,
          totalSitesForWater: 10,
          sitesSubmittedForGas: 3,
          totalSitesForGas: 5,
          sitesNotSubmittedSuccessfully: 12,
        },
        {
          customerName: 'Aramark',
          enabledSites: 150,
          sitesSubmittedForElectricity: 130,
          totalSitesForElectricity: 150,
          sitesSubmittedForWater: 20,
          totalSitesForWater: 30,
          sitesSubmittedForGas: 10,
          totalSitesForGas: 20,
          sitesNotSubmittedSuccessfully: 40,
        },
      ]).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(monthlySummaryMapper(data)), 5000)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/api/energy-star/submissions/summary/monthly-summary`
    return axios
      .get<TSEnergyStarMonthlySummary[]>(url, {
        headers: defaultHeaders(),
        params: {
          yearMonth,
          origin: 'grand-central',
        },
      })
      .then(({ data }) => monthlySummaryMapper(data))
      .catch(handleAxiosError)
  },
}

function* getSubmissionsDataSaga({
  yearMonth,
}: {
  yearMonth: string
  type: string
}): Generator<
  CallEffect<{ items: TSEnergyStarSubmissions[] }> | PutEffect<AnyAction>,
  void,
  { items: TSEnergyStarSubmissions[] }
> {
  try {
    const response = yield call(API.getSubmissionsData, yearMonth)
    yield put({
      type: types.FETCH_SUBMISSION_DATA_SUCCESS,
      payload: submissionsMapper(response),
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_SUBMISSION_DATA_ERROR, e)
  }
}

function* uploadSubmissionsDataSaga({
  type,
  yearMonth,
  ...data
}: {
  type: string
} & TSEnergyStarSubmissionPayload): Generator<
  | CallEffect<{ items: TSEnergyStarSubmissions[] } | void>
  | PutEffect<AnyAction>,
  void,
  { items: TSEnergyStarSubmissions[] }
> {
  try {
    const response = yield call(API.uploadSubmissions, data)
    yield put({
      type: types.UPLOAD_SUBMISSION_DATA_SUCCESS,
      payload: response,
    })
    const getSubmissionsResponse = yield call(API.getSubmissionsData, yearMonth)
    yield put({
      type: types.FETCH_SUBMISSION_DATA_SUCCESS,
      payload: submissionsMapper(getSubmissionsResponse),
    })
  } catch (e) {
    yield handleSagaError(types.UPLOAD_SUBMISSION_DATA_ERROR, e)
  }
}

function* reSyncSubmissionsSaga({
  type,
  yearMonth,
  ...data
}: {
  type: string
} & TSEnergyStarSubmissionPayload): Generator<
  | CallEffect<{ items: TSEnergyStarSubmissions[] } | void>
  | PutEffect<AnyAction>,
  void,
  { items: TSEnergyStarSubmissions[] }
> {
  try {
    const response = yield call(API.reSyncSubmissions, data)
    yield put({
      type: types.RE_SYNC_SUBMISSION_DATA_SUCCESS,
      payload: response,
    })
    const getSubmissionsResponse = yield call(API.getSubmissionsData, yearMonth)
    yield put({
      type: types.FETCH_SUBMISSION_DATA_SUCCESS,
      payload: submissionsMapper(getSubmissionsResponse),
    })
  } catch (e) {
    yield handleSagaError(types.UPLOAD_SUBMISSION_DATA_ERROR, e)
  }
}

function* getESCustomerIDStatusSaga({
  customerId,
}: {
  customerId: string
  type: string
}): Generator<
  CallEffect<TSEnergyStarCustomerStatus> | PutEffect<AnyAction>,
  void,
  TSEnergyStarCustomerStatus
> {
  try {
    const response = yield call(API.getESCustomerIDStatus, customerId)
    yield put({
      type: types.FETCH_ES_CUSTOMER_ID_STATUS_SUCCESS,
      payload: response,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    e.message = statusCodeMapper(e)
    yield handleSagaError(
      types.FETCH_ES_CUSTOMER_ID_STATUS_ERROR,
      e,
      undefined,
      undefined,
      e.customVariables,
    )
  }
}

function* getESSiteIDStatusSaga({
  redaptiveSiteId,
}: {
  redaptiveSiteId: string
  type: string
}): Generator<
  CallEffect<TSEnergyStarSiteStatus> | PutEffect<AnyAction>,
  void,
  TSEnergyStarSiteStatus
> {
  try {
    const response = yield call(API.getESSiteIDStatus, redaptiveSiteId)
    yield put({
      type: types.FETCH_ES_SITE_ID_STATUS_SUCCESS,
      payload: response,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    e.message = statusCodeMapper(e)
    yield handleSagaError(
      types.FETCH_ES_SITE_ID_STATUS_ERROR,
      e,
      undefined,
      undefined,
      e.customVariables,
    )
  }
}

function* updateESCustomerIDStatusSaga(data: {
  reqData: TSEnergyStarCustomerStatus
  customerId: string
  type: string
}): Generator<
  CallEffect<TSEnergyStarCustomerStatus | void> | PutEffect<AnyAction>,
  void,
  void
> {
  try {
    yield call(API.updateESCustomerIDStatus, data)
    const response = yield call(API.getESCustomerIDStatus, data.customerId)
    yield put({
      type: types.FETCH_ES_CUSTOMER_ID_STATUS_SUCCESS,
      payload: response,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    e.message = statusCodeMapper(e)
    yield handleSagaError(
      types.UPDATE_ES_CUSTOMER_ID_STATUS_ERROR,
      e,
      undefined,
      undefined,
      e.customVariables,
    )
  }
}

function* updateESSiteIDStatusSaga(data: {
  reqData: TSEnergyStarSiteStatus
  redaptiveSiteId: string
  type: string
}): Generator<
  CallEffect<TSEnergyStarSiteStatus | void> | PutEffect<AnyAction>,
  void,
  TSEnergyStarSiteStatus
> {
  try {
    yield call(API.updateESSiteIDStatusSaga, data)
    const response = yield call(API.getESSiteIDStatus, data.redaptiveSiteId)
    yield put({
      type: types.FETCH_ES_SITE_ID_STATUS_SUCCESS,
      payload: response,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    e.message = statusCodeMapper(e)
    yield handleSagaError(
      types.UPDATE_ES_SITE_ID_STATUS_ERROR,
      e,
      undefined,
      undefined,
      e.customVariables,
    )
  }
}

function* deleteESMeterSaga({
  meterId,
}: {
  meterId: string
  type: string
}): Generator<CallEffect<void> | PutEffect<AnyAction>, void, void> {
  try {
    const response = yield call(API.deleteESMeter, meterId)
    yield put({
      type: types.DELETE_ES_METER_SUCCESS,
      payload: response,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    e.message = statusCodeMapper(e)
    yield handleSagaError(
      types.DELETE_ES_METER_ERROR,
      e,
      undefined,
      undefined,
      e.customVariables,
    )
  }
}

function* fetchWaterMeterSaga({
  siteId,
}: {
  siteId: string
  type: string
}): Generator<
  CallEffect<TSEnergyStarWaterMeterResponse[]> | PutEffect<AnyAction>,
  void,
  void
> {
  try {
    const response = yield call(API.fetchWaterMeter, siteId)
    yield put({
      type: types.FETCH_WATER_METER_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_WATER_METER_ERROR, e)
  }
}

function* fetchSummaryAllTimeSaga(): Generator<
  CallEffect<TSEnergyStarAlltimeSummary> | PutEffect<AnyAction>,
  void,
  void
> {
  try {
    const response = yield call(API.fetchSummaryAllTime)
    yield put({
      type: types.FETCH_SUMMARY_ALL_TIME_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_SUMMARY_ALL_TIME_ERROR, e)
  }
}

function* fetchSummaryMonthlySaga({
  yearMonth,
}: {
  yearMonth: string
  type: string
}): Generator<
  CallEffect<TSEnergyStarMonthlySummary> | PutEffect<AnyAction>,
  void,
  void
> {
  try {
    const response = yield call(API.fetchSummaryMonthly, yearMonth)
    yield put({
      type: types.FETCH_SUMMARY_MONTHLY_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_SUMMARY_MONTHLY_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_SUBMISSION_DATA, getSubmissionsDataSaga),
  takeLatest(types.UPLOAD_SUBMISSION_DATA, uploadSubmissionsDataSaga),
  takeLatest(types.RE_SYNC_SUBMISSION_DATA, reSyncSubmissionsSaga),
  takeLatest(types.FETCH_ES_CUSTOMER_ID_STATUS, getESCustomerIDStatusSaga),
  takeLatest(types.UPDATE_ES_CUSTOMER_ID_STATUS, updateESCustomerIDStatusSaga),
  takeLatest(types.FETCH_ES_SITE_ID_STATUS, getESSiteIDStatusSaga),
  takeLatest(types.UPDATE_ES_SITE_ID_STATUS, updateESSiteIDStatusSaga),
  takeLatest(types.DELETE_ES_METER, deleteESMeterSaga),
  takeLatest(types.FETCH_SUMMARY_ALL_TIME, fetchSummaryAllTimeSaga),
  takeLatest(types.FETCH_SUMMARY_MONTHLY, fetchSummaryMonthlySaga),
  takeLatest(types.FETCH_WATER_METER, fetchWaterMeterSaga),
]
