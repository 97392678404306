import { PureComponent } from 'react'
import styled from 'styled-components'
import ErrorMessage from './ErrorMessage'

const Component = styled.div`
  position: relative;
`
export const InputComponent = styled.input`
  box-sizing: border-box;
  padding-left: 11px;
  height: 36px;
  border: ${({ alwaysDraw }) =>
    alwaysDraw ? '1px solid #c7c7c7' : '1px solid transparent'};

  border-radius: ${({ expanded }) => (expanded ? '3px 3px 0px 0px' : '3px')};
  border-bottom: ${({ expanded }) => expanded && '0'};

  background-color: ${({ alwaysDraw }) =>
    alwaysDraw ? 'white' : 'transparent'};

  color: #464a54;
  box-shadow: unset;

  font-family: 'Avenir Next';
  font-size: 14px;
  line-height: 19px;

  text-overflow: ellipsis;

  &:focus,
  &:hover {
    background-color: white;
    outline: none;
    border: 1px solid #c7c7c7;
  }

  &:focus {
    border: 1px solid #0193d7;
  }

  border: ${({ error }) => error && '1px solid #D0021B'};
`
export const Marker = styled.div`
  display: ${({ edited }) => (edited ? 'inline-block' : 'none')};
  ${InputComponent}:focus + &,
  ${InputComponent}:hover + & {
    display: ${({ hideOnHover }) => hideOnHover && 'none'};
  }

  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  width: 6px;
  height: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
`
export const InputErrorMessageStyles = styled(ErrorMessage)`
  display: inline-block;
  position: absolute;
  left: 330px;
  top: -11px;

  &:before {
    content: '*\\a0 ';
  }
`
export type FTInputProps = {
  edited?: boolean
  editDotColor?: string
  editDotLeft?: string
  editDotRight?: string
  editDotTop?: string
  error?: string
  hideEditDotOnHover?: boolean
  alwaysDraw?: boolean
  className?: string
  expanded?: boolean
  input?: Record<string, any>
  // To support ReduxForm input props object
  field?: Record<string, any>
  // To support Formik field object
  form?: Record<string, any>
  // To support Formik form object
  inputRef?: HTMLInputElement | null | undefined
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string | number
  defaultValue?: string
  placeholder?: string
  required?: boolean
  type?: string
  autoFocus?: boolean
  min?: number
  max?: number
  name?: string
  disabled?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}
export default class Input extends PureComponent<FTInputProps> {
  static defaultProps = {
    edited: false,
    editDotColor: '#c70d08',
    editDotLeft: '',
    editDotRight: '',
    editDotTop: '15px',
    error: '',
    hideEditDotOnHover: true,
    alwaysDraw: true,
    className: '',
    input: {},
    field: {},
    form: {},
    expanded: false,
    inputRef: null,
  }

  render() {
    const {
      edited,
      alwaysDraw,
      className,
      input,
      field,
      form: _,
      editDotColor,
      editDotLeft,
      editDotRight,
      editDotTop,
      error,
      hideEditDotOnHover,
      inputRef,
      disabled,
      ...rest
    } = this.props
    return (
      <Component className={className}>
        <InputComponent
          {...rest}
          {...input}
          {...field}
          alwaysDraw={alwaysDraw}
          className={className}
          ref={inputRef}
          disabled={disabled}
        />
        <Marker
          edited={edited}
          color={editDotColor}
          left={editDotLeft}
          right={editDotRight}
          top={editDotTop}
          hideOnHover={hideEditDotOnHover}
        />
        {error && (
          <InputErrorMessageStyles message={error} className='input__error' />
        )}
      </Component>
    )
  }
}
