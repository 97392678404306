import styled from 'styled-components'
import DesignLibraryPageWrapper from '../../DesignLibraryPageWrapper'
import Accordion from '../../../../components/Accordion'
import RedaptiveReactTable7, {
  SelectColumnMultiFilter,
} from '../../../../components/RedaptiveReactTable7'
import mockData from '../TableComponentPage/mockData'
import StyledLink from '../../../../components/StyledLink'

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    Filter: SelectColumnMultiFilter,
    filter: 'includesSome',
  },
  {
    accessor: 'species',
    Header: 'Species',
  },
  {
    accessor: 'description',
    Header: 'Description',
  },
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
  },
  {
    accessor: 'address',
    disableFilters: true,
    Header: 'Address',
  },
  {
    accessor: 'email',
    Cell: ({ value }: { value: string }) => (
      <StyledLink external href={`mailto:${value}`}>
        <b>{value}</b>
      </StyledLink>
    ),
    Header: 'EMail',
  },
]
const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/accordion',
    text: 'Accordion',
  },
]
const MainContentStyled = styled.div`
  padding-bottom: 200px;

  > * {
    margin-bottom: 16px;
  }
`

const getContent = () => (
  <div>
    <RedaptiveReactTable7
      columns={columns}
      data={mockData}
      enableColumnSettings
      enablePagination
      filterable
      enableRowSelection
      enableColumnHiding
    />
  </div>
)

export default () => (
  <DesignLibraryPageWrapper breadcrumbs={breadcrumbs} Title='Accordion'>
    <MainContentStyled>
      <Accordion title='Sample' Content={getContent} />
    </MainContentStyled>
  </DesignLibraryPageWrapper>
)
