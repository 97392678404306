/* eslint-disable react/no-array-index-key */
import type { ComponentType } from 'react'
import styled from 'styled-components'
import VerticalTable from './VerticalTable'
import { BaseTable, Label, Value } from './VerticalTable/Base'

type FTField = {
  label: string
  value: string
}
type FTInfoObject = {
  header: null | FTField
  values: Array<FTField>
}
type FTProps = {
  info: Array<FTInfoObject>
  width?: number
  notAvailableText?: string
}
const ContentContainerStyled: ComponentType<{
  indent?: boolean
}> = styled.div`
  padding-left: ${({ indent }) => (indent ? '16px' : '')};
`
const TitleContainerStyled = styled.div`
  ${Label} {
    margin-right: 40px;
  }
`
const MainContainerStyled: ComponentType<{
  width?: number
}> = styled.div`
  width: ${({ width }) => `${width}px`};
`
const SectionContainerStyled = styled.div`
  ${BaseTable} {
    margin-bottom: 16px;
  }
  ${Value} {
    max-width: 400px;
  }
`

function InfoSection(props: FTProps) {
  const { info, width = 800, notAvailableText = '--' } = props
  return (
    <MainContainerStyled width={width}>
      {info.map((section, i) => {
        const { header, values } = section
        const hasTitle = !!header && !!header.label
        return (
          <SectionContainerStyled key={`${header?.label || ''} ${i}`}>
            {hasTitle && (
              <TitleContainerStyled>
                <VerticalTable.Basic
                  columned={false}
                  fields={[header]}
                  notAvailableText={notAvailableText}
                  index={i}
                />
              </TitleContainerStyled>
            )}
            <ContentContainerStyled indent={hasTitle}>
              <VerticalTable.Basic
                columned={false}
                fields={values}
                isFieldWithoutTitle={!hasTitle}
                notAvailableText={notAvailableText}
                index={i}
              />
            </ContentContainerStyled>
          </SectionContainerStyled>
        )
      })}
    </MainContainerStyled>
  )
}

export default InfoSection
