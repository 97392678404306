import { useEffect, useCallback, useState } from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { bindActionCreators } from 'redux'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Breadcrumbs'
import FormSection from '../../components/FormSection'
import Title from '../../components/Title'
import Button from '../../components/Button'
import ArrowUpwardIcon from '../../components/Icons/ArrowUpwardIcon'
import CloudDownloadIcon from '../../components/Icons/CloudDownloadIcon'
import OpenInNewWindowIcon from '../../components/Icons/OpenInNewWindowIcon'
import type { FTLightingAsBuiltsAssetState } from '../../ducks/lightingAsBuiltsAsset'
import {
  actions as authActionsLightingAsBuilts,
  selectLightingAsBuiltsAsset,
} from '../../ducks/lightingAsBuiltsAsset'
import type { FTLightingControlsTypeGuideAssetState } from '../../ducks/lightingControlsTypeGuideAsset'
import {
  actions as authActionsLightingControlsTypeGuide,
  selectLightingControlsTypeGuideAsset,
} from '../../ducks/lightingControlsTypeGuideAsset'
import type { FTMeasureCodeGuideAssetState } from '../../ducks/measureCodeGuideAsset'
import {
  actions as authActionsMeasureCodeGuide,
  selectMeasureCodeGuideAsset,
} from '../../ducks/measureCodeGuideAsset'
import { isVariantActive, naturallySort } from '../../utils'
import { consoleApiUrl } from '../../api'
import Spinner from '../../components/Spinner'
import SuccessArrowIcon from '../../components/Icons/SuccessArrowIcon'
import ReportProblemIcon from '../../components/Icons/ReportProblemIcon'
import features from '../../authorization/features'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import FeatureValidator from '../../authorization/components/FeatureValidator'

const linkColor = '#337ab7'
export const ArrowUpwardIconStyles = styled.div`
  align-items: center;
  border: 2px solid #4a4a4a;
  border-radius: 6px;
  display: flex;
  padding: 6px;
`
const SuccessArrowIconStyles = styled.span`
  color: #7bcc76;
  position: relative;
  top: 6px;
`
const UploadAnotherFileStyles = styled.div`
  cursor: pointer;
  color: ${linkColor};
  padding: 30px 0 10px;
  text-align: center;
`
export const TryAgainStyles = styled.div`
  color: ${linkColor};
  padding: 30px 0 10px;
`
export const FilePathStyled = styled.p`
  margin-bottom: 24px;
`
export const ButtonsStyles = styled.div`
  button {
    display: inline-block;
    margin: 0 20px;
  }
`
const LinkIconStyles = styled.span`
  bottom: -5px;
  color: ${linkColor};
  left: 4px;
  line-height: 0;
  position: relative;

  > * {
    font-size: 20px;
  }
`

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }

  if (props.isDragReject) {
    return '#ff1744'
  }

  if (props.isDragActive) {
    return '#2196f3'
  }

  return '#ccc'
}

export const DropzoneStyles = styled.div`
  align-items: center;
  background-color: #fff;
  border-color: ${(props) => getColor(props)};
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  flex: 1;
  outline: none;
  padding: 40px;
  transition: border 0.24s ease-in-out;
`
export const Col1Styles = styled.div`
  flex: 1;
  margin-right: 2%;
`
const Col2Styles = styled.div`
  flex: 1;
  margin-right: 2%;
`
const MainStyles = styled.div`
  display: flex;
  margin-top: 20px;

  a {
    color: ${linkColor};
  }
`
const SecondaryContentStyles = styled.div`
  flex: 1;
`
export const FormSectionInnerStyles = styled.div`
  padding: 8px 0;
  a {
    color: ${linkColor};
  }
`
export const ErrorMessagesHeadingStyles = styled.div`
  margin-bottom: 20px;
`
export const ErrorMessagesListStyles = styled.div`
  line-height: 2;
  max-height: 400px;
  overflow-y: auto;
`
export const ErrorMessagesListHeadingStyles = styled.div`
  margin-bottom: 13px;
`
export const ReportProblemIconStyled = styled(ReportProblemIcon)`
  position: relative;
  top: 6px;
`
export const UploadAssetRecordsListStyled = styled.ul`
  line-height: 2;
  margin-bottom: 26px;
`
type FTProps = {
  actionsLightingAsBuilts: any
  actionsLightingControlsTypeGuide: any
  actionsMeasureCodeGuide: any
  lightingAsBuiltsAsset: FTLightingAsBuiltsAssetState
  lightingControlsTypeGuideAsset: FTLightingControlsTypeGuideAssetState
  measureCodeGuideAsset: FTMeasureCodeGuideAssetState
}
const mockAxios = axios.create()
const mock = new MockAdapter(mockAxios, {
  delayResponse: 500,
  onNoMatch: 'passthrough',
})
const LightingAsBuiltsAssetUploadJobUrlBase = `${consoleApiUrl()}/admin/jobs`
const LightingAsBuiltsAssetUploadJobUrlRegex = new RegExp(
  `${LightingAsBuiltsAssetUploadJobUrlBase}/*`,
)
mock
  .onHead(LightingAsBuiltsAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 0.3,
      'job-status': 'INPROGRESS',
    },
  )
  .onHead(LightingAsBuiltsAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 0.6,
      'job-status': 'INPROGRESS',
    },
  )
  .onHead(LightingAsBuiltsAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 0.9,
      'job-status': 'INPROGRESS',
    },
  )
  .onHead(LightingAsBuiltsAssetUploadJobUrlRegex)
  .replyOnce(
    200,
    {},
    {
      'job-progress': 1,
      'job-status': 'SUCCESS',
    },
  )
const mockErrorAxios = axios.create()
const mockError = new MockAdapter(mockErrorAxios, {
  delayResponse: 500,
  onNoMatch: 'passthrough',
})
mockError.onHead(LightingAsBuiltsAssetUploadJobUrlRegex).reply(
  200,
  {},
  {
    'job-progress': 0,
    'job-status': 'ERROR',
    'job-reason': 'MeasureCode with id F43ILLAA not found',
  },
)

const LightingAsBuiltsManagerPage = (props: FTProps) => {
  const [fileNameLightingAsBuilts, setFileNameLightingAsBuilts] = useState('')
  const [
    fileNameLightingControlsTypeGuide,
    setFileNameLightingControlsTypeGuide,
  ] = useState('')
  const [fileNameMeasureCodeGuide, setFileNameMeasureCodeGuide] = useState('')
  const handleDropLightingAsBuilts = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setFileNameLightingAsBuilts(file.name)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])
  const handleDropLightingControlsTypeGuide = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setFileNameLightingControlsTypeGuide(file.name)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])
  const handleDropMeasureCodeGuide = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setFileNameMeasureCodeGuide(file.name)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {
    acceptedFiles: acceptedFilesLightingAsBuilts,
    getInputProps: getInputPropsLightingAsBuilts,
    getRootProps: getRootPropsLightingAsBuilts,
    isDragAccept: isDragAcceptLightingAsBuilts,
    isDragActive: isDragActiveLightingAsBuilts,
    isDragReject: isDragRejectLightingAsBuilts,
    open: openLightingAsBuilts,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleDropLightingAsBuilts,
  })
  const {
    acceptedFiles: acceptedFilesLightingControlsTypeGuide,
    getInputProps: getInputPropsLightingControlsTypeGuide,
    getRootProps: getRootPropsLightingControlsTypeGuide,
    isDragAccept: isDragAcceptLightingControlsTypeGuide,
    isDragActive: isDragActiveLightingControlsTypeGuide,
    isDragReject: isDragRejectLightingControlsTypeGuide,
    open: openLightingControlsTypeGuide,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleDropLightingControlsTypeGuide,
  })
  const {
    acceptedFiles: acceptedFilesMeasureCodeGuide,
    getInputProps: getInputPropsMeasureCodeGuide,
    getRootProps: getRootPropsMeasureCodeGuide,
    isDragAccept: isDragAcceptMeasureCodeGuide,
    isDragActive: isDragActiveMeasureCodeGuide,
    isDragReject: isDragRejectMeasureCodeGuide,
    open: openMeasureCodeGuide,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleDropMeasureCodeGuide,
  })
  const {
    actionsLightingAsBuilts,
    actionsLightingControlsTypeGuide,
    actionsMeasureCodeGuide,
    lightingAsBuiltsAsset,
    lightingControlsTypeGuideAsset,
    measureCodeGuideAsset,
  } = props
  const {
    errors: errorsLightingAsBuilts,
    isCheckingJob: isCheckingJobLightingAsBuilts,
    isPosting: isPostingLightingAsBuilts,
    job: jobLightingAsBuilts,
  } = lightingAsBuiltsAsset
  const {
    errors: errorsLightingControlsTypeGuide,
    isCheckingJob: isCheckingJobLightingControlsTypeGuide,
    isPosting: isPostingLightingControlsTypeGuide,
    job: jobLightingControlsTypeGuide,
  } = lightingControlsTypeGuideAsset
  const {
    errors: errorsMeasureCodeGuide,
    isCheckingJob: isCheckingJobMeasureCodeGuide,
    isPosting: isPostingMeasureCodeGuide,
    job: jobMeasureCodeGuide,
  } = measureCodeGuideAsset
  const errorMessagesLightingAsBuilts = [...errorsLightingAsBuilts]
    .sort((message1, message2) =>
      naturallySort(message1.message, message2.message),
    )
    .map(({ message }, index) => ({
      message,
      key: index,
    }))
  const errorMessagesLightingControlsTypeGuide = [
    ...errorsLightingControlsTypeGuide,
  ]
    .sort((message1, message2) =>
      naturallySort(message1.message, message2.message),
    )
    .map(({ message }, index) => ({
      message,
      key: index,
    }))
  const errorMessagesMeasureCodeGuide = [...errorsMeasureCodeGuide]
    .sort((message1, message2) =>
      naturallySort(message1.message, message2.message),
    )
    .map(({ message }, index) => ({
      message,
      key: index,
    }))

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      !isPostingLightingAsBuilts &&
      !errorMessagesLightingAsBuilts &&
      jobLightingAsBuilts.status !== 'ERROR' &&
      jobLightingAsBuilts.id
    ) {
      if (isVariantActive('lighting-as-builts-manager-mock')) {
        actionsLightingAsBuilts.fetchLightingAsBuiltsAssetUploadJobMock({
          jobId: jobLightingAsBuilts.id,
          mockAxios,
        })
      } else if (
        isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
      ) {
        actionsLightingAsBuilts.fetchLightingAsBuiltsAssetUploadJobMockError({
          jobId: jobLightingAsBuilts.id,
          mockAxios: mockErrorAxios,
        })
      } else {
        actionsLightingAsBuilts.fetchLightingAsBuiltsAssetUploadJob({
          jobId: jobLightingAsBuilts.id,
        })
      }
    }

    if (
      !isPostingLightingControlsTypeGuide &&
      !errorMessagesLightingControlsTypeGuide &&
      jobLightingControlsTypeGuide.status !== 'ERROR' &&
      jobLightingControlsTypeGuide.id
    ) {
      if (isVariantActive('lighting-as-builts-manager-mock')) {
        actionsLightingControlsTypeGuide.fetchLightingControlsTypeGuideAssetUploadJobMock(
          {
            jobId: jobLightingControlsTypeGuide.id,
            mockAxios,
          },
        )
      } else if (
        isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
      ) {
        actionsLightingControlsTypeGuide.fetchLightingControlsTypeGuideAssetUploadJobMockError(
          {
            jobId: jobLightingControlsTypeGuide.id,
            mockAxios: mockErrorAxios,
          },
        )
      } else {
        actionsLightingControlsTypeGuide.fetchLightingControlsTypeGuideAssetUploadJob(
          {
            jobId: jobLightingControlsTypeGuide.id,
          },
        )
      }
    }

    if (
      !isPostingMeasureCodeGuide &&
      !errorMessagesMeasureCodeGuide &&
      jobMeasureCodeGuide.status !== 'ERROR' &&
      jobMeasureCodeGuide.id
    ) {
      if (isVariantActive('lighting-as-builts-manager-mock')) {
        actionsMeasureCodeGuide.fetchMeasureCodeGuideAssetUploadJobMock({
          jobId: jobMeasureCodeGuide.id,
          mockAxios,
        })
      } else if (
        isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
      ) {
        actionsMeasureCodeGuide.fetchMeasureCodeGuideAssetUploadJobMockError({
          jobId: jobMeasureCodeGuide.id,
          mockAxios: mockErrorAxios,
        })
      } else {
        actionsMeasureCodeGuide.fetchMeasureCodeGuideAssetUploadJob({
          jobId: jobMeasureCodeGuide.id,
        })
      }
    }
  }, [
    errorMessagesLightingAsBuilts,
    errorMessagesLightingControlsTypeGuide,
    errorMessagesMeasureCodeGuide,
    isPostingLightingAsBuilts,
    isPostingLightingControlsTypeGuide,
    isPostingMeasureCodeGuide,
    jobLightingAsBuilts,
    jobLightingControlsTypeGuide,
    jobMeasureCodeGuide,
  ])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      !isCheckingJobLightingAsBuilts &&
      jobLightingAsBuilts.progress < 1 &&
      jobLightingAsBuilts.status !== 'ERROR' &&
      jobLightingAsBuilts.id
    ) {
      setTimeout(() => {
        if (isVariantActive('lighting-as-builts-manager-mock')) {
          actionsLightingAsBuilts.fetchLightingAsBuiltsAssetUploadJobMock({
            jobId: jobLightingAsBuilts.id,
            mockAxios,
          })
        } else if (
          isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
        ) {
          actionsLightingAsBuilts.fetchLightingAsBuiltsAssetUploadJobMockError({
            jobId: jobLightingAsBuilts.id,
            mockAxios: mockErrorAxios,
          })
        } else {
          actionsLightingAsBuilts.fetchLightingAsBuiltsAssetUploadJob({
            jobId: jobLightingAsBuilts.id,
          })
        }
      }, 2000)
    }

    if (
      !isCheckingJobLightingControlsTypeGuide &&
      jobLightingControlsTypeGuide.progress < 1 &&
      jobLightingControlsTypeGuide.status !== 'ERROR' &&
      jobLightingControlsTypeGuide.id
    ) {
      setTimeout(() => {
        if (isVariantActive('lighting-as-builts-manager-mock')) {
          actionsLightingControlsTypeGuide.fetchLightingControlsTypeGuideAssetUploadJobMock(
            {
              jobId: jobLightingControlsTypeGuide.id,
              mockAxios,
            },
          )
        } else if (
          isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
        ) {
          actionsLightingControlsTypeGuide.fetchLightingControlsTypeGuideAssetUploadJobMockError(
            {
              jobId: jobLightingControlsTypeGuide.id,
              mockAxios: mockErrorAxios,
            },
          )
        } else {
          actionsLightingControlsTypeGuide.fetchLightingControlsTypeGuideAssetUploadJob(
            {
              jobId: jobLightingControlsTypeGuide.id,
            },
          )
        }
      }, 2000)
    }

    if (
      !isCheckingJobMeasureCodeGuide &&
      jobMeasureCodeGuide.progress < 1 &&
      jobMeasureCodeGuide.status !== 'ERROR' &&
      jobMeasureCodeGuide.id
    ) {
      setTimeout(() => {
        if (isVariantActive('lighting-as-builts-manager-mock')) {
          actionsMeasureCodeGuide.fetchMeasureCodeGuideAssetUploadJobMock({
            jobId: jobMeasureCodeGuide.id,
            mockAxios,
          })
        } else if (
          isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
        ) {
          actionsMeasureCodeGuide.fetchMeasureCodeGuideAssetUploadJobMockError({
            jobId: jobMeasureCodeGuide.id,
            mockAxios: mockErrorAxios,
          })
        } else {
          actionsMeasureCodeGuide.fetchMeasureCodeGuideAssetUploadJob({
            jobId: jobMeasureCodeGuide.id,
          })
        }
      }, 2000)
    }
  }, [
    isCheckingJobLightingControlsTypeGuide,
    jobLightingControlsTypeGuide,
    isCheckingJobLightingAsBuilts,
    jobLightingAsBuilts,
    isCheckingJobMeasureCodeGuide,
    jobMeasureCodeGuide,
  ])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => actionsLightingAsBuilts.resetLightingAsBuiltsAssetState, [])
  useEffect(
    () =>
      actionsLightingControlsTypeGuide.resetLightingControlsTypeGuideAssetState,
    [],
  )
  useEffect(() => actionsMeasureCodeGuide.resetMeasureCodeGuideAssetState, [])

  const resetLightingAsBuiltsForm = () => {
    setFileNameLightingAsBuilts('')
    actionsLightingAsBuilts.resetLightingAsBuiltsAssetState()
  }

  const resetLightingControlsTypeGuideForm = () => {
    setFileNameLightingControlsTypeGuide('')
    actionsLightingControlsTypeGuide.resetLightingControlsTypeGuideAssetState()
  }

  const resetMeasureCodeGuideForm = () => {
    setFileNameMeasureCodeGuide('')
    actionsMeasureCodeGuide.resetMeasureCodeGuideAssetState()
  }

  return (
    <>
      <UnauthorizedRedirect
        feature={features.lightingAsBuiltsManager}
        from='/tools/lighting-as-builts-manager'
        to='/'
      />
      <Breadcrumbs
        items={[
          {
            href: '/reports',
            text: 'Tools & Reports',
          },
          {
            href: '/tools/lighting-as-builts-manager',
            text: 'Lighting As-Builts Manager',
          },
        ]}
      />
      <Title>Lighting As-Builts Manager</Title>
      <MainStyles>
        <FeatureValidator feature={features.uploadLightingAsBuilts}>
          <Col1Styles>
            <FormSection>
              <FormSectionInnerStyles>
                {(!!errorMessagesLightingAsBuilts.length ||
                  jobLightingAsBuilts.status === 'ERROR') && (
                  <>
                    <ErrorMessagesHeadingStyles>
                      <ReportProblemIconStyled color='#C70D08' inline />
                      <b>
                        {fileNameLightingAsBuilts}
                        {' upload failed.'}
                      </b>
                    </ErrorMessagesHeadingStyles>
                    <ErrorMessagesListHeadingStyles>
                      No records were stored. Please fix the following errors
                      and try again:
                    </ErrorMessagesListHeadingStyles>
                    <ErrorMessagesListStyles>
                      {errorMessagesLightingAsBuilts.map((errorMessage) => (
                        <div key={errorMessage.key}>{errorMessage.message}</div>
                      ))}
                      {jobLightingAsBuilts.status === 'ERROR' && (
                        <div>{jobLightingAsBuilts.reason}</div>
                      )}
                    </ErrorMessagesListStyles>
                    <TryAgainStyles>
                      <Button onClick={resetLightingAsBuiltsForm} primary>
                        Reset
                      </Button>
                    </TryAgainStyles>
                  </>
                )}
                {!errorMessagesLightingAsBuilts.length &&
                  jobLightingAsBuilts.status !== 'ERROR' && (
                    <>
                      <b>Upload Lighting As-Built Records</b>
                      <UploadAssetRecordsListStyled>
                        <li>The upload file must be a .csv.</li>
                        <li>
                          Any rows with missing required fields will be skipped.
                        </li>
                        <li>
                          Site codes are expected to be unique to the customer.
                        </li>
                        <li>
                          Data rows being updated require the reference UUID.
                        </li>
                        <li>
                          If a required field is not found, the upload will be
                          rejected.
                        </li>
                        <li>
                          DO NOT CLOSE THIS WINDOW while upload is in progress,
                          otherwise you will lose access to the upload status.
                        </li>
                      </UploadAssetRecordsListStyled>
                      <DropzoneStyles
                        {...getRootPropsLightingAsBuilts({
                          isDragActiveLightingAsBuilts,
                          isDragAcceptLightingAsBuilts,
                          isDragRejectLightingAsBuilts,
                        })}
                      >
                        <input
                          name='file'
                          {...getInputPropsLightingAsBuilts()}
                        />
                        {(isPostingLightingAsBuilts ||
                          (jobLightingAsBuilts.id &&
                            jobLightingAsBuilts.status !== 'SUCCESS')) && (
                          <div>
                            <Spinner inline size='micro' />{' '}
                            <b>Upload in progress...</b>
                            {jobLightingAsBuilts.progress > 0 &&
                              ` ${jobLightingAsBuilts.progress * 100}%`}
                          </div>
                        )}
                        {!isPostingLightingAsBuilts &&
                          !jobLightingAsBuilts.id && (
                            <ArrowUpwardIconStyles>
                              <ArrowUpwardIcon />
                            </ArrowUpwardIconStyles>
                          )}
                        <FilePathStyled>
                          {fileNameLightingAsBuilts || 'Drag file to upload'}
                        </FilePathStyled>
                        {jobLightingAsBuilts.id &&
                          jobLightingAsBuilts.status === 'SUCCESS' && (
                            <div>
                              <SuccessArrowIconStyles>
                                <SuccessArrowIcon />
                              </SuccessArrowIconStyles>{' '}
                              <b>Upload Complete</b>
                              <UploadAnotherFileStyles
                                onClick={resetLightingAsBuiltsForm}
                              >
                                Upload another file
                              </UploadAnotherFileStyles>
                            </div>
                          )}
                        {!fileNameLightingAsBuilts && (
                          <Button primary onClick={openLightingAsBuilts}>
                            Choose File
                          </Button>
                        )}
                        {fileNameLightingAsBuilts &&
                          !jobLightingAsBuilts.id &&
                          !isPostingLightingAsBuilts && (
                            <ButtonsStyles>
                              <Button onClick={resetLightingAsBuiltsForm}>
                                Cancel
                              </Button>
                              <Button
                                primary
                                onClick={() => {
                                  actionsLightingAsBuilts.postLightingAsBuiltsAsset(
                                    {
                                      file: acceptedFilesLightingAsBuilts[0],
                                    },
                                  )
                                }}
                              >
                                Upload
                              </Button>
                            </ButtonsStyles>
                          )}
                      </DropzoneStyles>
                    </>
                  )}
              </FormSectionInnerStyles>
            </FormSection>
          </Col1Styles>
        </FeatureValidator>
        <Col2Styles>
          <FeatureValidator feature={features.uploadMeasureCodeGuideForm}>
            <FormSection>
              <FormSectionInnerStyles>
                {(!!errorMessagesMeasureCodeGuide.length ||
                  jobMeasureCodeGuide.status === 'ERROR') && (
                  <>
                    <ErrorMessagesHeadingStyles>
                      <ReportProblemIconStyled color='#C70D08' inline />
                      <b>
                        {fileNameMeasureCodeGuide}
                        {' upload failed.'}
                      </b>
                    </ErrorMessagesHeadingStyles>
                    <ErrorMessagesListHeadingStyles>
                      No records were stored. Please fix the following errors
                      and try again:
                    </ErrorMessagesListHeadingStyles>
                    <ErrorMessagesListStyles>
                      {errorMessagesMeasureCodeGuide.map((errorMessage) => (
                        <div key={errorMessage.key}>{errorMessage.message}</div>
                      ))}
                      {jobMeasureCodeGuide.status === 'ERROR' && (
                        <div>{jobMeasureCodeGuide.reason}</div>
                      )}
                    </ErrorMessagesListStyles>
                    <TryAgainStyles>
                      <Button onClick={resetMeasureCodeGuideForm} primary>
                        Reset
                      </Button>
                    </TryAgainStyles>
                  </>
                )}
                {!errorMessagesMeasureCodeGuide.length &&
                  jobMeasureCodeGuide.status !== 'ERROR' && (
                    <>
                      <b>Update Measure Code Guide</b>
                      <UploadAssetRecordsListStyled>
                        <li>The upload file must be a .csv.</li>
                        <li>Additions must have a unique Fixture Code.</li>
                        <li>
                          DO NOT CLOSE THIS WINDOW while upload is in progress,
                          otherwise you will lose access to the upload status.
                        </li>
                      </UploadAssetRecordsListStyled>
                      <DropzoneStyles
                        {...getRootPropsMeasureCodeGuide({
                          isDragActiveMeasureCodeGuide,
                          isDragAcceptMeasureCodeGuide,
                          isDragRejectMeasureCodeGuide,
                        })}
                      >
                        <input
                          name='file'
                          {...getInputPropsMeasureCodeGuide()}
                        />
                        {(isPostingMeasureCodeGuide ||
                          (jobMeasureCodeGuide.id &&
                            jobMeasureCodeGuide.status !== 'SUCCESS')) && (
                          <div>
                            <Spinner inline size='micro' />{' '}
                            <b>Upload in progress...</b>
                            {jobMeasureCodeGuide.progress > 0 &&
                              ` ${jobMeasureCodeGuide.progress * 100}%`}
                          </div>
                        )}
                        {!isPostingMeasureCodeGuide &&
                          !jobMeasureCodeGuide.id && (
                            <ArrowUpwardIconStyles>
                              <ArrowUpwardIcon />
                            </ArrowUpwardIconStyles>
                          )}
                        <FilePathStyled>
                          {fileNameMeasureCodeGuide || 'Drag file to upload'}
                        </FilePathStyled>
                        {jobMeasureCodeGuide.id &&
                          jobMeasureCodeGuide.status === 'SUCCESS' && (
                            <div>
                              <SuccessArrowIconStyles>
                                <SuccessArrowIcon />
                              </SuccessArrowIconStyles>{' '}
                              <b>Upload Complete</b>
                              <UploadAnotherFileStyles
                                onClick={resetMeasureCodeGuideForm}
                              >
                                Upload another file
                              </UploadAnotherFileStyles>
                            </div>
                          )}
                        {!fileNameMeasureCodeGuide && (
                          <Button primary onClick={openMeasureCodeGuide}>
                            Choose File
                          </Button>
                        )}
                        {fileNameMeasureCodeGuide &&
                          !jobMeasureCodeGuide.id &&
                          !isPostingMeasureCodeGuide && (
                            <ButtonsStyles>
                              <Button onClick={resetMeasureCodeGuideForm}>
                                Cancel
                              </Button>
                              <Button
                                primary
                                onClick={() => {
                                  actionsMeasureCodeGuide.postMeasureCodeGuideAsset(
                                    {
                                      file: acceptedFilesMeasureCodeGuide[0],
                                    },
                                  )
                                }}
                              >
                                Upload
                              </Button>
                            </ButtonsStyles>
                          )}
                      </DropzoneStyles>
                    </>
                  )}
              </FormSectionInnerStyles>
            </FormSection>
          </FeatureValidator>
          <FeatureValidator feature={features.uploadLightingControlsTypeGuide}>
            <FormSection>
              <FormSectionInnerStyles>
                {(!!errorMessagesLightingControlsTypeGuide.length ||
                  jobLightingControlsTypeGuide.status === 'ERROR') && (
                  <>
                    <ErrorMessagesHeadingStyles>
                      <ReportProblemIconStyled color='#C70D08' inline />
                      <b>
                        {fileNameLightingControlsTypeGuide}
                        {' upload failed.'}
                      </b>
                    </ErrorMessagesHeadingStyles>
                    <ErrorMessagesListHeadingStyles>
                      No records were stored. Please fix the following errors
                      and try again:
                    </ErrorMessagesListHeadingStyles>
                    <ErrorMessagesListStyles>
                      {errorMessagesLightingControlsTypeGuide.map(
                        (errorMessage) => (
                          <div key={errorMessage.key}>
                            {errorMessage.message}
                          </div>
                        ),
                      )}
                      {jobLightingControlsTypeGuide.status === 'ERROR' && (
                        <div>{jobLightingControlsTypeGuide.reason}</div>
                      )}
                    </ErrorMessagesListStyles>
                    <TryAgainStyles>
                      <Button
                        onClick={resetLightingControlsTypeGuideForm}
                        primary
                      >
                        Reset
                      </Button>
                    </TryAgainStyles>
                  </>
                )}
                {!errorMessagesLightingControlsTypeGuide.length &&
                  jobLightingControlsTypeGuide.status !== 'ERROR' && (
                    <>
                      <b>Update Lighting Controls Type Guide</b>
                      <UploadAssetRecordsListStyled>
                        <li>The upload file must be a .csv.</li>
                        <li>Additions must have a unique Control Type name.</li>
                        <li>
                          DO NOT CLOSE THIS WINDOW while upload is in progress,
                          otherwise you will lose access to the upload status.
                        </li>
                      </UploadAssetRecordsListStyled>
                      <DropzoneStyles
                        {...getRootPropsLightingControlsTypeGuide({
                          isDragActiveLightingControlsTypeGuide,
                          isDragAcceptLightingControlsTypeGuide,
                          isDragRejectLightingControlsTypeGuide,
                        })}
                      >
                        <input
                          name='file'
                          {...getInputPropsLightingControlsTypeGuide()}
                        />
                        {(isPostingLightingControlsTypeGuide ||
                          (jobLightingControlsTypeGuide.id &&
                            jobLightingControlsTypeGuide.status !==
                              'SUCCESS')) && (
                          <div>
                            <Spinner inline size='micro' />{' '}
                            <b>Upload in progress...</b>
                            {jobLightingControlsTypeGuide.progress > 0 &&
                              ` ${
                                jobLightingControlsTypeGuide.progress * 100
                              }%`}
                          </div>
                        )}
                        {!isPostingLightingControlsTypeGuide &&
                          !jobLightingControlsTypeGuide.id && (
                            <ArrowUpwardIconStyles>
                              <ArrowUpwardIcon />
                            </ArrowUpwardIconStyles>
                          )}
                        <FilePathStyled>
                          {fileNameLightingControlsTypeGuide ||
                            'Drag file to upload'}
                        </FilePathStyled>
                        {jobLightingControlsTypeGuide.id &&
                          jobLightingControlsTypeGuide.status === 'SUCCESS' && (
                            <div>
                              <SuccessArrowIconStyles>
                                <SuccessArrowIcon />
                              </SuccessArrowIconStyles>{' '}
                              <b>Upload Complete</b>
                              <UploadAnotherFileStyles
                                onClick={resetLightingControlsTypeGuideForm}
                              >
                                Upload another file
                              </UploadAnotherFileStyles>
                            </div>
                          )}
                        {!fileNameLightingControlsTypeGuide && (
                          <Button
                            primary
                            onClick={openLightingControlsTypeGuide}
                          >
                            Choose File
                          </Button>
                        )}
                        {fileNameLightingControlsTypeGuide &&
                          !jobLightingControlsTypeGuide.id &&
                          !isPostingLightingControlsTypeGuide && (
                            <ButtonsStyles>
                              <Button
                                onClick={resetLightingControlsTypeGuideForm}
                              >
                                Cancel
                              </Button>
                              <Button
                                primary
                                onClick={() => {
                                  actionsLightingControlsTypeGuide.postLightingControlsTypeGuideAsset(
                                    {
                                      file: acceptedFilesLightingControlsTypeGuide[0],
                                    },
                                  )
                                }}
                              >
                                Upload
                              </Button>
                            </ButtonsStyles>
                          )}
                      </DropzoneStyles>
                    </>
                  )}
              </FormSectionInnerStyles>
            </FormSection>
          </FeatureValidator>
        </Col2Styles>
        <SecondaryContentStyles>
          <p>
            <strong>How can I access the Lighting As-Builts data?</strong>
          </p>
          <p>
            Data is stored in our Redaptive internal database (formerly known as
            the Finance DB).
          </p>
          <p>Data can be accessed via any SQL client or Tableau.</p>
          <p>To receive access, please reach out to the Product team.</p>
          <ul>
            <li>
              <Link to='#'>
                Example .csv file
                <LinkIconStyles>
                  <CloudDownloadIcon />
                </LinkIconStyles>
              </Link>
            </li>
            <li>
              <Link to='#'>
                Expected data fields & descriptions
                <LinkIconStyles>
                  <OpenInNewWindowIcon />
                </LinkIconStyles>
              </Link>
            </li>
            <li>
              <Link to='#'>
                How to view the dataset with Tableau
                <LinkIconStyles>
                  <OpenInNewWindowIcon />
                </LinkIconStyles>
              </Link>
            </li>
            <li>
              <Link to='#'>
                How to upload a .csv using Postman
                <LinkIconStyles>
                  <OpenInNewWindowIcon />
                </LinkIconStyles>
              </Link>
            </li>
          </ul>
        </SecondaryContentStyles>
      </MainStyles>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actionsLightingAsBuilts: bindActionCreators(
    authActionsLightingAsBuilts,
    dispatch,
  ),
  actionsLightingControlsTypeGuide: bindActionCreators(
    authActionsLightingControlsTypeGuide,
    dispatch,
  ),
  actionsMeasureCodeGuide: bindActionCreators(
    authActionsMeasureCodeGuide,
    dispatch,
  ),
})

const mapStateToProps = (state) => ({
  lightingAsBuiltsAsset: selectLightingAsBuiltsAsset(state),
  lightingControlsTypeGuideAsset: selectLightingControlsTypeGuideAsset(state),
  measureCodeGuideAsset: selectMeasureCodeGuideAsset(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LightingAsBuiltsManagerPage)
