import { phaseLabels } from '../../ducks/meters/config'
import { ColumnsType, FTColumn, QualityTestType } from './types'

const checkNameMap: Record<string, string> = {
  LOW_AND_HIGH_CURRENT_TEST: 'Parallel Conductor',
  POWER_CONNECTION_TEST: 'Voltage Mismatch',
  CT_INSTALL_ERROR_TEST: 'CT install Error',
  INCORRECT_PHASE_TEST: 'Incorrect Phase',
  REVERSED_CT_TEST: 'Reversed CT',
  CT_NOT_CONNECTED_TEST: 'CT not connected',
  CAPACITOR_DATA_ERROR_TEST: 'Capacitor Data Error',
  UNDERSIZED_CT_TEST: 'Undersized CT',
  NON_UNIQUE_PANEL_TEST: 'Non-Unique Panel',
  INCONSISTENT_BREAKER_TEST: 'Inconsistent Breaker',
  INCONSISTENT_CT_TYPE_TEST: 'Inconsistent CT Type',
  MISSING_BREAKER_TEST: 'Missing Breaker',
  OVERSIZED_CT_LIGHTING_TEST: 'Oversized CT Lighting',
  NON_UNIQUE_BREAKER_TEST: 'Non-Unique Breaker',
  MISSING_CIRCUIT_DESCRIPTION_TEST: 'Missing Circuit Description',
  INCONSISTENT_BUILDING_SYSTEM_TEST: 'Inconsistent Building Systen',
  INCONSISTENT_EQUIPMENT_NAME_TEST: 'Inconsistent Equipment Name',
  INCORRECT_BUILDING_SYSTEM_TEST: 'Incorrect Building System',
  MISSING_PANEL_TEST: 'Missing Panel',
}

export const transformPhase = (obj: Record<string, string>) => {
  for (const key in obj) {
    if (key === 'phase') {
      obj = { ...obj, phase: phaseLabels.get(obj[key]) || 'A' }
    }
    if (key === 'ctSize') {
      obj = { ...obj, ctSize: `${obj.ctSize}A` }
    }
  }
  return obj
}

export const getFailedChecks = (qualityTests: Array<QualityTestType>) => {
  const newArr: any = []
  qualityTests?.forEach((test: QualityTestType) => {
    const { failedChannels } = test
    if (failedChannels) {
      const keys = Object.keys(test.failedChannels)
      for (const key of keys) {
        newArr.push({
          name: checkNameMap[test.testName],
          meterChannel: `${key[0]}-${key[1]}`,
          ...transformPhase(failedChannels[key]),
        })
      }
    }
  })
  return newArr
}

export const dataCheckColumns = (
  meterChannelComp: (row: any) => void,
  CellWithToolTip: any,
): ColumnsType => [
  {
    accessor: 'name',
    Header: 'Flag Name',
    minWidth: 160,
    maxWidth: 160,
    Cell: CellWithToolTip,
  },
  {
    accessor: 'breaker',
    Header: 'Breaker',
    minWidth: 140,
    maxWidth: 140,
  },
  {
    accessor: 'meterChannel',
    Cell: (data) => meterChannelComp(data.row),
    Header: 'Meter Channel',
    minWidth: 154,
    maxWidth: 154,
  },
  {
    accessor: 'phase',
    Header: 'Phase',
    minWidth: 153,
    maxWidth: 153,
  },
  {
    accessor: 'ctSize',
    Header: 'CT Size',
    minWidth: 153,
    maxWidth: 153,
  },
  {
    accessor: 'metric',
    Header: 'Metric',
    minWidth: 153,
    maxWidth: 153,
    Cell: CellWithToolTip,
  },
  {
    accessor: 'measurement',
    Header: 'Measurement',
    minWidth: 163,
    maxWidth: 163,
    Cell: CellWithToolTip,
  },
]

export const labelCheckColumns = (
  meterChannelComp?: (row: any) => void,
  CellWithToolTip?: any,
): ColumnsType => [
  {
    accessor: 'name',
    Header: 'Flag Name',
    minWidth: 160,
    maxWidth: 160,
    Cell: CellWithToolTip,
  },
  {
    accessor: 'breaker',
    Header: 'Breaker',
    maxWidth: 86,
    minWidth: 86,
  },
  {
    accessor: 'meterChannel',
    Cell:
      meterChannelComp ?
        (data: { row: FTColumn }) => meterChannelComp(data.row)
      : undefined,
    Header: 'Meter Channel',
    maxWidth: 116,
    minWidth: 116,
  },
  {
    accessor: 'phase',
    Header: 'Phase',
    maxWidth: 100,
    minWidth: 104,
  },
  {
    accessor: 'ctSize',
    Header: 'CT Size',
    maxWidth: 90,
    minWidth: 90,
  },
  {
    accessor: 'panelName',
    Header: 'Panel Name',
    maxWidth: 146,
    minWidth: 146,
  },
  {
    accessor: 'circuitDescription',
    Header: 'Circuit Description',
    maxWidth: 166,
    minWidth: 166,
    Cell: CellWithToolTip,
  },
  {
    accessor: 'buildingSystem',
    Header: 'Building System',
    maxWidth: 166,
    minWidth: 166,
    Cell: CellWithToolTip,
  },
  {
    accessor: 'equipmentName',
    Header: 'Equipment Name',
    maxWidth: 166,
    minWidth: 166,
    Cell: CellWithToolTip,
  },
]
