import { withFormik } from 'formik'
import moment from 'moment'
import Form from './Form'

export default withFormik({
  handleSubmit(values, formikBag) {
    const {
      props: { submitAction },
    } = formikBag
    submitAction(values)
  },

  mapPropsToValues(props) {
    const { id, verified, verifiedDate: verifiedDateProp } = props
    const verifiedDate = verifiedDateProp ? moment(verifiedDateProp) : null
    return {
      hour: verifiedDate ? verifiedDate.format('h') : '',
      minute: verifiedDate ? verifiedDate.minute() : '',
      second: verifiedDate ? verifiedDate.second() : '',
      ampm: verifiedDate ? verifiedDate.format('A') : '',
      id,
      verified,
      verifiedDate,
    }
  },
})(Form)
