import type { Moment } from 'moment'
import moment from 'moment'
import { ComponentType, useState, useEffect, useCallback } from 'react'
import { DateRangePicker } from 'react-dates'
import { END_DATE, START_DATE } from 'react-dates/constants'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import styled, { css } from 'styled-components'
import * as consts from '../constants'
import useWindowSize from '../hooks/useWindowSize'
import getOptionsDates from '../utils/optionsDates'

type FTProps = {
  end: Moment
  hideDropdown: (...args: Array<any>) => any
  options: Array<any>
  period: string
  getProgramStartDate: (...args: Array<any>) => any
  start: Moment
  updateDateRange: (...args: Array<any>) => any
}
const theme = {
  colors: {
    primary: '#485DA0',
  },
  fontFamily: 'Arial',
}
const DateRangePickerStyles: ComponentType<{
  windowWidth: number
}> = styled.div`
  position: relative;
  text-align: left;
  padding: 15px;
  height: 500px;

  .DayPickerNavigation_button__horizontalDefault {
    padding: 3px 2px;
  }

  .CalendarDay {
    font-size: 12px;
  }

  .DayPicker_weekHeader {
    top: 53px;
  }

  .DayPicker_weekHeader_ul {
    left: -8px;
    position: relative;
  }

  .CalendarMonth_caption {
    font-size: 14px;
  }

  .DateInput {
    width: 268px;
    padding-right: 20px;

    &:last-child {
      border-left: 1px solid #e0e0e0;
      padding-left: 20px;
    }
  }

  .DateInput_fang {
    display: none;
  }

  .DateRangePicker {
    width: ${({ windowWidth }) => (windowWidth <= 800 ? '300px' : '100%')};
  }

  .DayPicker__withBorder {
    box-shadow: none;
  }

  .DateRangePickerInput__withBorder {
    display: flex;
    justify-content: space-evenly;
    border-radius: 0;
    border: 0;
  }

  .DateRangePickerInput input {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    color: #485da0;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    letter-spacing: 0.02em;
    line-height: 20px;
    padding-bottom: 2px;
    padding-top: 6px;
  }

  .DateRangePickerInput_arrow {
    display: none;
  }
  .DateRangePickerInput_calendarIcon {
    display: none;
  }

  .ion-android-arrow-forward {
    display: none;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: -9px;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 28px;
  }

  .DayPicker_weekHeaders__horizontal {
    margin-left: -1px;
  }

  .DayPicker_transitionContainer {
    left: -18px;
  }

  .DayPicker {
    top: -20px;
    left: 9px;
  }

  .DayPicker__horizontal,
  .CalendarMonthGrid,
  .DateRangePicker_picker {
    background-color: transparent;
    left: '0 !important';
  }

  .DayPickerNavigation_button__default {
    border: 0;
  }

  .DayPickerNavigation_svg__horizontal {
    fill: #000000;
  }

  .CalendarDay__default {
    border: 0;
    background: #e0e0e0;
    border-radius: 4px;

    &:hover {
      background: #485da0;
      border-radius: 4px;
      border: 0;
      color: #fff;
      opacity: 0.7;
    }
  }

  .CalendarDay__selected_span {
    background: #485da0;
    border: 0;
    border-radius: 4px;
    color: #fff;
    opacity: 0.7;
  }

  .CalendarDay__selected {
    &,
    &:active,
    &:hover {
      background: #485da0;
      border-radius: 4px;
      border: 0;
      color: #fff;
    }
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #485da0;
    border-radius: 4px;
    border: 0;
    color: #fff;
    opacity: 0.7;
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #f9f9f9;
    border: 0;
    border-radius: 4px;
    color: #000000;
    opacity: 0.6;
  }

  .CalendarMonth_caption {
    font-family: 'Montserrat';
    font-size: 18px;
    color: #162447;
  }
`
const SelectedItemStyles = css`
  background-color: ${() => theme.colors.primary};
  color: #fff;
`
const DateOptionsItemStyles: ComponentType<{
  selected: boolean
}> = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #000000;
  font-family: ${() => theme.fontFamily};
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  letter-spacing: normal;
  line-height: 20px;
  padding: 8px 19px 6px 19px;
  text-align: left;

  &:hover {
    background-color: #337ab7cc;
    color: #fff;
    cursor: pointer;
  }

  ${({ selected }) => selected && SelectedItemStyles};

  :not(:last-child) {
    margin-bottom: 10px;
  }
`
const DateOptionsStyles = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 0px 8px 8px 0px;
  padding: 15px;
  width: 180px;
`
const DateButtonsStyles: ComponentType<{
  windowWidth: number
}> = styled.div`
  position: absolute;
  bottom: ${({ windowWidth }) => (windowWidth >= 800 ? '15px' : '0')};
  margin: 10px 5px;
  right: ${({ windowWidth }) => (windowWidth >= 800 ? '20px' : '35px')};
  top: ${({ windowWidth }) => windowWidth <= 800 && ''};
  z-index: 1;

  button {
    background-color: #9dbd79;
    border-radius: 4px;
    border: none;
    color: #fff;
    height: 36px;
    margin: 0 5px;
    width: 72px;

    &:hover {
      background-color: #f2a91c;
      cursor: pointer;
    }

    &:first-child {
      background-color: #e0e0e0;
      color: #6c6d6e;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;

      &:hover {
        background-color: #818b96;
        color: #fff;
      }
    }
  }
`
export const DropdownStyles = styled.div`
  position: relative;
  text-transform: none;
  user-select: none;
`
export const DropdownInnerStyles = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  box-shadow: 1px 2px 4px 0px rgba(84, 91, 99, 0.46);
  display: flex;
  position: absolute;
  z-index: 2;
`

const getEndDate = (date: Moment) => {
  const endDate = moment(date, consts.URL_DATE_FORMAT)
  return Math.round(endDate.diff(moment(), 'days')) >= 1 ? moment() : endDate
}

const getStartDate = (date: Moment) => moment(date, consts.URL_DATE_FORMAT)

export default ({
  end = moment(),
  hideDropdown = () => null,
  options = [],
  period = '',
  getProgramStartDate = () => null,
  start = moment(),
  updateDateRange = () => null,
}: FTProps) => {
  const windowSize = useWindowSize()
  const programStartDate = getProgramStartDate && getProgramStartDate()
  const [endDate, setEndDate] = useState(getEndDate(end))
  const [startDate, setStartDate] = useState(getStartDate(start))
  const [focusedInput, setFocusedInput] = useState(START_DATE)
  const [calendarOrientation, setCalendarOrientation] = useState('horizontal')
  const windowSizeWidth = windowSize[0]
  useEffect(() => {
    if (windowSize[0] <= 800) {
      setCalendarOrientation('vertical')
    } else {
      setCalendarOrientation('horizontal')
    }
  }, [windowSize])
  // eslint-disable-next-line no-shadow
  const handleFocusChange: any = useCallback((focusedInput: string) => {
    if ([START_DATE, END_DATE].includes(focusedInput)) {
      setFocusedInput(focusedInput)
    } else {
      setFocusedInput(END_DATE)
    }
  }, [])

  const handleCancelClick: any = () => {
    setEndDate(getEndDate(end))
    setStartDate(getStartDate(start))

    if (hideDropdown) {
      hideDropdown()
    }
  }

  const handleApplyClick: any = () => {
    if (updateDateRange) {
      updateDateRange({
        endDate: endDate || getEndDate(end),
        startDate: startDate || getStartDate(start),
      })
    }
  }

  const handleOptionSelect: any = useCallback(
    (value: string) => {
      const optionsDates = getOptionsDates({
        programStartDate: getProgramStartDate && getProgramStartDate(),
      })
      // eslint-disable-next-line no-shadow
      const [startDate, endDate] = optionsDates[value]

      if (updateDateRange) {
        updateDateRange({
          startDate,
          endDate,
          isPtd: value === consts.PROGRAM_TO_DATE,
          isYtd: value === consts.YEAR_TO_DATE,
          selectedOptionDate: value,
        })
      }
    },
    [startDate, endDate],
  )

  // eslint-disable-next-line no-shadow
  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  // eslint-disable-next-line no-shadow
  const isOutsideRange: any =
    (programStartDate: any) =>
    (baseDay: any, focusedInputOverride: string | null = null) => {
      const focusedInputState = focusedInputOverride || focusedInput
      // Utilize startOf('day') to provide a baseline for comparison
      // the `baseDay` provided by DatePicker is at noon for each day.
      const day = baseDay.clone().startOf('day')
      const today = moment().startOf('day')

      // startDate can never be later than today.
      if (
        focusedInputState === START_DATE &&
        (day.isAfter(today) || day.isBefore(programStartDate))
      ) {
        return true
      }

      if (focusedInputState === END_DATE) {
        // endDate can never be later than today.
        if (day.isAfter(today)) {
          return true
        }

        if (programStartDate) {
          // eslint-disable-next-line no-shadow
          const startDate = programStartDate.clone().startOf('day')

          // endDate can never be before startDate.
          if (day.isBefore(startDate)) {
            return true
          }
        }
      }

      return false
    }

  return (
    <DropdownStyles>
      <DropdownInnerStyles>
        <DateRangePickerStyles windowWidth={windowSizeWidth}>
          <DateRangePicker
            isOutsideRange={isOutsideRange(programStartDate)}
            daySize={35}
            endDate={endDate}
            endDateId='date-range-form-end'
            focusedInput={focusedInput}
            keepOpenOnDateSelect
            hideKeyboardShortcutsPanel
            onDatesChange={handleDateChange}
            onFocusChange={handleFocusChange}
            showDefaultInputIcon
            startDate={startDate}
            startDateId='date-range-form-start'
            orientation={calendarOrientation}
            numberOfMonths={calendarOrientation === 'vertical' ? 1 : 2}
            verticalHeight={370}
          />
          <DateButtonsStyles windowWidth={windowSizeWidth}>
            <button type='button' onClick={handleCancelClick}>
              Cancel
            </button>
            <button type='button' onClick={handleApplyClick}>
              Apply
            </button>
          </DateButtonsStyles>
        </DateRangePickerStyles>
        {calendarOrientation === 'horizontal' && (
          <DateOptionsStyles>
            {options.map((option) => {
              const isSelected = period === option.value
              return (
                <DateOptionsItemStyles
                  selected={isSelected}
                  onClick={() => handleOptionSelect(option.value)}
                  key={option.value}
                >
                  {option.label}
                </DateOptionsItemStyles>
              )
            })}
          </DateOptionsStyles>
        )}
      </DropdownInnerStyles>
    </DropdownStyles>
  )
}
