/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { actions as modalActions } from '../../../ducks/modal'
import {
  actions as fetchUtilityDataActions,
  fetchUtilityDataEntity,
} from '../../../ducks/utilityData/fetchUtilityData'
import ActionPaneView from '../../../components/ActionPaneView'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Title from '../../../components/Title'
import RedaptiveReactTable7, {
  SelectColumnMultiFilterListSelectorStyled,
} from '../../../components/RedaptiveReactTable7'
import MenuIcon from '../../../components/Icons/MenuIcon'
import {
  LoadingSpinnerWrapperStyled,
  TableWrapperStyled,
} from '../../IntegrationSubmissions/styles'
import Spinner from '../../../components/Spinner'

const GetSitesStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #337ab7;
  }
  ${MenuIcon} {
    color: #337ab7;
  }
`
const TableWrapperFullWidth = styled(TableWrapperStyled)`
  margin-right: -60px;
  margin-left: -60px;
`
const StyledLink = styled.div`
  color: #337ab7;
  font-weight: bold;
  cursor: pointer;
`
const HiddenLink = styled.a`
  display: hidden;
`

const ManageUtilityData = ({
  match: { url },
  actions,
  modalType,
  history,
  data,
  meta: { isLoading },
}) => {
  const linkRef = useRef(null)
  const [pageable, setPageable] = useState(data.pageable)
  const [sort, setSort] = useState(data.sort)
  const [fileName, setFileName] = useState('')
  const [filterValues, setFilterValues] = useState({})
  const modaltypes = ['profile', 'basic', 'invoice']
  const modalName = url?.split('/')?.reverse()?.[0]
  const breadcrumbs = [
    {
      href: '/reports',
      text: 'Tools & Reports',
    },
    {
      href: '/reports/manage-utility-data',
      text: 'Manage Utility Data',
    },
  ]
  const dropdownActions = [
    {
      href: `${url}/profile`,
      label: 'Profile Upload',
    },
    {
      href: `${url}/basic`,
      label: 'Basic Upload',
    },
    {
      href: `${url}/invoice`,
      label: 'Invoice Upload',
    },
  ]

  const renderCell = ({
    value,
    column: { id },
    cell: {
      row: { original },
    },
  }) => {
    if (id === 'status') {
      const statusMapper = {
        PASSED: 'Passed',
        FAILED: 'Failed',
        PARTIAL: 'Partial',
        'IN PROGRESS': 'In Progress',
      }
      return statusMapper[value] ?? value
    }

    if (id === 'uploadType') {
      const uploadTypeMapping = {
        utility: 'Utility',
        basic: 'Basic',
        invoice: 'Invoice',
      }
      return uploadTypeMapping[value.toLowerCase()] ?? '-'
    }

    if (id === 'outputFileUploadPath' || id === 'fileName') {
      return (
        <StyledLink
          onClick={() => {
            actions.fetchPreSignedUrls({
              id: original.id,
            })
            setFileName(
              id === 'outputFileUploadPath' ?
                original.fileName.replace('.csv', '-result.csv')
              : original.fileName,
            )
          }}
        >
          {id === 'outputFileUploadPath' ? 'Link' : value}
        </StyledLink>
      )
    }

    if (id === 'created') {
      return moment(value).format('M/D/YYYY, h:mm:ss A [UTC]')
    }

    return value ?? '-'
  }

  const MutipleSelectFilter = ({
    column: { setFilter, preFilteredRows, id },
  }) => {
    useEffect(() => {
      setFilter(filterValues[id])
    }, [])
    const items = useMemo(
      () =>
        [...new Set(preFilteredRows.map((row) => row.values[id]))].map(
          (value) => ({
            id: value,
            name: value,
          }),
        ),
      [data.items],
    )
    return (
      <SelectColumnMultiFilterListSelectorStyled
        isMulti
        items={items}
        unsettable={false}
        selectedItems={items.filter((item) =>
          item && filterValues[id] ? filterValues[id].includes(item.id) : false,
        )}
        selectedItem={
          filterValues[id] ?
            items.find((item) => filterValues[id][0] === item.id)
          : null
        }
        updateValueMulti={(values) => {
          filterValues[id] = values.map(({ value }) => value)
          setFilterValues(filterValues)
          setFilter(filterValues[id])
        }}
        updateValue={({ value }) => {
          filterValues[id] = [value]
          setFilterValues(filterValues)
          setFilter(filterValues[id])
        }}
        menuShouldBlockScroll
      />
    )
  }

  const MultipleSelectFilterFunction = useCallback(
    (rows, [id], filterValue) =>
      rows.filter(({ original }) => {
        if (!filterValue || !filterValue.length) return rows
        return filterValue.includes(original[id])
      }),
    [],
  )
  const columns = [
    {
      accessor: 'fileName',
      Header: 'File Name',
      Cell: renderCell,
    },
    {
      accessor: 'uploadType',
      Header: 'Type of Upload',
      Cell: renderCell,
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: renderCell,
    },
    {
      accessor: 'totalRecordCount',
      Header: 'Total No. of Records',
      Cell: renderCell,
      disableFilters: true,
    },
    {
      accessor: 'passedRecordCount',
      Header: 'Passed',
      Cell: renderCell,
      disableFilters: true,
    },
    {
      accessor: 'failedRecordCount',
      Header: 'Failed',
      Cell: renderCell,
      disableFilters: true,
    },
    {
      accessor: 'customerNames',
      Header: 'Customer Name',
      Cell: renderCell,
    },
    {
      accessor: 'createdBy',
      Header: 'Uploaded By',
      Cell: renderCell,
      filter: MultipleSelectFilterFunction,
      Filter: MutipleSelectFilter,
    },
    {
      accessor: 'created',
      Header: 'Upload Date-Time',
      Cell: renderCell,
    },
    {
      accessor: 'outputFileUploadPath',
      Header: 'Result File',
      Cell: renderCell,
      disableFilters: true,
    },
  ]

  const renderMain = () => (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <Title>Manage Utility Data</Title>
    </div>
  )

  const handleModalClose = ({ refetchList }) => {
    actions.hideModal()
    history.push('/reports/manage-utility-data')

    if (refetchList) {
      actions.fetchUtilityData({
        pageable,
        sort,
      })
    }
  }

  const HeaderActionButtons = useCallback(
    () => (
      <Link to='/reports/manage-utility-data/customer-sites'>
        <GetSitesStyled>
          <MenuIcon />
          <p>Get Sites</p>
        </GetSitesStyled>
      </Link>
    ),
    [],
  )

  const onSortChange = (sortBy) => {
    if (sortBy && sortBy[0]) {
      const newSort = {
        property: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      }

      if (JSON.stringify(newSort) !== JSON.stringify(sort)) {
        actions.changeSort(newSort)
        setSort(newSort)
      }
    }
  }

  const onPageParamsChange = ({ pageIndex, pageSize }) => {
    if (
      (pageable.page === pageIndex && pageable.size === pageSize) ||
      isLoading
    )
      return
    const newPageable = {
      ...data.pageable,
      page: pageable.size !== pageSize ? 0 : pageIndex,
      size: pageSize,
    }
    actions.changePageable(newPageable)
    setPageable(newPageable)
  }

  useEffect(() => {
    if (modaltypes.includes(modalName)) {
      actions.showUtilityDataModal({
        onClose: handleModalClose,
      })
    }
  }, [modaltypes, modalName])
  useEffect(() => {
    if (url === '/reports/manage-utility-data' && modalType) {
      actions.hideModal()
    }
  }, [url, modalType])
  useEffect(() => {
    if (!data.downloadUrls || !linkRef.current) return
    const fileUrl =
      fileName.includes('-result') ?
        data.downloadUrls.outputFileUrl
      : data.downloadUrls.inputFileUrl
    linkRef.current.href = fileUrl
    linkRef.current.click()
  }, [data.downloadUrls])
  useEffect(() => {
    actions.fetchUtilityData({
      pageable,
      sort,
    })
  }, [sort, pageable])
  return (
    <div className='CustomerDetailPage'>
      <HiddenLink ref={linkRef} />
      <ActionPaneView
        renderMain={renderMain}
        title='Upload'
        actions={dropdownActions}
      />
      {isLoading ?
        <LoadingSpinnerWrapperStyled>
          <Spinner size='small' />
        </LoadingSpinnerWrapperStyled>
      : <TableWrapperFullWidth>
          <RedaptiveReactTable7
            defaultSort={[
              {
                id: data.sort.property,
                desc: data.sort.order === 'desc',
              },
            ]}
            onSortChange={onSortChange}
            columns={columns}
            data={data.items}
            filterable
            HeaderActions={HeaderActionButtons}
            globalFilterable={false}
            enablePagination
            customTotalPages={data.pageable.totalPages}
            customPageIndex={data.pageable.page}
            customPageSize={data.pageable.size}
            onPageParamsChange={onPageParamsChange}
          />
        </TableWrapperFullWidth>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const entity = fetchUtilityDataEntity(state)
  const modalEntity = state.modal
  return { ...entity, modalType: modalEntity.modalType }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(fetchUtilityDataActions, dispatch),
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageUtilityData),
)
