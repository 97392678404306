import axios from 'axios'
import { capitalCase } from 'capital-case'
import { countries } from 'countries-list'
import moment from 'moment-timezone'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { consoleApiUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import * as globalTypes from '../../constants/actionTypes'
import * as globalStatus from '../../constants/status'
import { handleError, handleSagaError } from '../../sagas/utils'
import '../../types'
import type {
  FTContract,
  FTRequestStatus,
  FTSite,
  FTSiteExternalResource,
  FTSiteHealth,
  FTSitesHealth,
  FTSortable,
  FTUpdateSiteExternalResource,
} from '../../types'
import { getItemsByIdFromItems, naturallySortEmptyLast } from '../../utils'
import { actions as flasherActions } from '../messageFlasher'
import type { FTFilterBy } from '../types'
import {
  DATE_FORMAT_SIMPLE,
  addMetaToResponse,
  initialMeta,
  makeActionTypes,
  makeApiQueryString,
  makeSagaPayload,
  parseTimestamp,
  renderTimestamp,
} from '../utils'
import countryNameLookup from './countryNameLookup'

export const naturallySortContracts = (a: FTContract, b: FTContract) =>
  naturallySortEmptyLast(a.opportunityId, b.opportunityId)
// Action Types
const types = {
  ...makeActionTypes('FETCH_ALL_SITES'),
  ...makeActionTypes('FETCH_SITE'),
  ...makeActionTypes('FETCH_SITE_HEALTH'),
  ...makeActionTypes('FETCH_SITE_EXTERNAL_RESOURCES'),
  ...makeActionTypes('UPDATE_SITE_EXTERNAL_RESOURCE'),
  ...makeActionTypes('FETCH_SITES_HEALTH'),
  ...makeActionTypes('ADD_SITE'),
  ...makeActionTypes('UPDATE_SITE'),
  ...makeActionTypes('DELETE_SITE'),
  ...makeActionTypes('FETCH_OPPORTUNITY'),
  CLEAR_SITE_FORM: 'CLEAR_SITE_FORM',
  CLEAR_SITES: 'CLEAR_SITES',
  FETCH_OPPORTUNITY_LOADING_STATUS: 'FETCH_OPPORTUNITY_LOADING_STATUS',
  CLEAR_NEW_OPPORTUNITY: 'CLEAR_NEW_OPPORTUNITY',
}
type FTFetchAllSitesAction = {
  pageNumber: number | null | undefined
  pageSize: number | null | undefined
  orderBy?: FTSortable
  customerId?: string
  filterBy?: FTFilterBy
  withHealth?: boolean
  isFresh?: boolean
}
export type FTFetchSiteAction = {
  siteId: string
}
type FTFetchSiteHealthAction = FTFetchSiteAction
type FTFetchSitesHealthAction = {
  siteIds: Array<string>
}
type FTFetchOpportunityAction = {
  opportunityId: string
  contractIndex: number
}
export type FTSiteEntityState = {
  byId: Record<string, FTSite>
  allIds: Array<string>
  meta: {
    pageNumber: number | null | undefined
    pageSize: number | null | undefined
    next: string | null | undefined
    previous: string | null | undefined
    error: string | null | undefined
    orderBy?: FTSortable
    filterBy?: FTFilterBy
  }
  siteHealth: FTSiteHealth
  sitesHealth: FTSitesHealth
  siteExternalResources: Array<FTSiteExternalResource>
}
type FTState = {
  entities: {
    sites: FTSiteEntityState
  }
  requestStatus: Record<
    string,
    {
      status: FTRequestStatus
    }
  >
}
type FTCountryData = {
  language: string
  currency: string
}
const validLockedSubmitProperties = [
  // Site Name & Customer
  'display',
  'customerId',
  'theme',
  'active', // Site Address
  'address1',
  'address2',
  'city',
  'latLng',
  'locale',
  'currencyCode',
  'state',
  'postalCode',
  'country',
  'timezone', // Other
  'id',
  'enableEquipmentView',
  'contracts',
  'electricUtilityRate',
]
const validSubmitProperties = [
  ...validLockedSubmitProperties, // Meter Data
  'ingestionDataStart',
  'ingestionDataStartElectricity',
  'ingestionDataStartNaturalGas',
  'ingestionDataStartWater',
  'ingestionDataEnd', // Contract Details & Billing Method
  'squareFootage', // Other
  'ingestionSource',
  'source',
  'ingestionSiteId',
]

const cleanFormData = (data, validProperties = validSubmitProperties) => ({
  ...Object.keys(data)
    .filter((item) => validProperties.includes(item))
    .reduce((newData, item) => ({ ...newData, [item]: data[item] }), {}),
})

export const CONTRACT_TYPES = [
  {
    id: 'LIGHTING',
    name: 'Lighting',
  },
  {
    id: 'HVAC',
    name: 'HVAC',
  },
  {
    id: 'METERING',
    name: 'Metering',
  },
]
const contractTypeItemsById = getItemsByIdFromItems(CONTRACT_TYPES)
export const METER_SOURCES = [
  'REDAPTIVE',
  'LEVITON', // Disabled until the backend supports it:
  // 'ENERTIV',
]
export const isRedaptiveMeter = (source: string) =>
  !!(source && source.toLowerCase() === 'redaptive')
export const prettifyCountry = (c: string) => {
  if (!c) {
    return ''
  }

  const countryCode = c.toUpperCase()
  return countryNameLookup[countryCode] || countryCode
}

const prettifyTimezone = (timezone: string) =>
  timezone ? timezone.replace('_', ' ').replace('/', ' / ') : ''

export const getCountryData = (countryCode: string): FTCountryData => {
  const countryData = countries[countryCode.toUpperCase()] || {}
  const { currency: currencies = 'USD', languages = [] } = countryData
  const currency = currencies.split(',')[0]
  return {
    currency,
    language: languages[0] || 'en',
  }
}
export const enhanceSite = (site: FTSite) => {
  if (!site) {
    return undefined
  }

  const { contracts = [] } = site
  const prettyCountry = prettifyCountry(site.country)
  let meterStartMonth
  let meterStartDay
  let meterStartYear
  const meterStartDate = parseTimestamp(site.ingestionDataStart, 'UTC')

  if (meterStartDate) {
    meterStartMonth = meterStartDate.month() + 1
    meterStartDay = meterStartDate.date()
    meterStartYear = meterStartDate.year()
  }

  return {
    ...site,
    address: [
      site.address1,
      site.address2,
      `${site.city}, ${site.state || ''} ${site.postalCode}`,
      prettyCountry,
    ],
    // @Todo RDP-4331: Require customer name in BE response once RDP-5724 is Done.
    customerName: site.customerName || '',
    prettyCountry,
    contracts,
    dateCreated: renderTimestamp(site.created, site.timezone),
    lastUpdated: renderTimestamp(site.modified, site.timezone),
    meterStartDate: renderTimestamp(
      site.ingestionDataStart,
      'UTC',
      DATE_FORMAT_SIMPLE,
    ),
    meterStartMonth,
    meterStartDay,
    meterStartYear,
    meterType: site.source ? capitalCase(site.source) : '',
    prettyTimezone: prettifyTimezone(site.timezone),
  }
}
const enhanceContract = (contract: FTContract) => {
  if (!contract) {
    return undefined
  }

  return { ...contract }
}
export const makeMeterStartDate = ({
  meterStartDay,
  meterStartMonth,
  meterStartYear,
}: {
  meterStartDay: number | string
  meterStartMonth: number | string
  meterStartYear: number | string
}) => {
  let meterStartDate
  const fields = [meterStartDay, meterStartMonth, meterStartYear]

  if (fields.every((f) => f)) {
    const formedDate = moment.utc(
      `${meterStartDay} ${meterStartMonth} ${meterStartYear}`,
      'DD MM YYYY',
    )

    if (formedDate.isValid()) {
      meterStartDate = formedDate.format()
    }
  } else if (fields.every((f) => f === '')) {
    meterStartDate = ''
  }

  return meterStartDate
}
export const utils = {
  enhanceSite,
  enhanceContract,
  makeMeterStartDate,
  prettifyTimezone,
  prettifyCountry,
}
// REDUCERS
const initialState = {
  meta: { ...initialMeta },
  siteExternalResources: [],
}
const siteSchema = new schema.Entity('sites')
const contractSchema = new schema.Entity('sites')

function siteById(action) {
  return action.payload.entities.sites || {}
}

function siteAllIds(action) {
  return [...new Set(action.payload.result)]
}

function byId(state = {}, action) {
  switch (action.type) {
    case types.CLEAR_SITES:
      return {}

    case types.FETCH_ALL_SITES_SUCCESS:
    case types.FETCH_SITE_SUCCESS:
      return siteById(action)

    default:
      return state
  }
}

const siteHealthInitialState: FTSiteHealth = {
  siteHealthStatusLastMonth: '',
  siteHealthStatusCurrentMonth: '',
  healthStatusPerMeterLastMonth: {},
  healthStatusPerMeterCurrentMonth: {},
}

function siteHealth(state = siteHealthInitialState, action) {
  switch (action.type) {
    case types.FETCH_SITE_HEALTH_SUCCESS:
      return action.payload.data

    default:
      return state
  }
}

const sitesHealthInitialState: FTSitesHealth = {
  healthStatusPerSitesLastMonth: {},
  healthStatusPerSitesCurrentMonth: {},
}

function sitesHealth(state = sitesHealthInitialState, action) {
  switch (action.type) {
    case types.FETCH_SITES_HEALTH_SUCCESS:
      return action.payload.data

    default:
      return state
  }
}

function siteExternalResources(
  state = initialState.siteExternalResources,
  action,
) {
  switch (action.type) {
    case types.FETCH_SITE_EXTERNAL_RESOURCES_SUCCESS:
      return action.payload.data

    default:
      return state
  }
}

function allIds(state = [], action) {
  switch (action.type) {
    case types.CLEAR_SITES:
      return []

    case types.FETCH_ALL_SITES_SUCCESS:
    case types.FETCH_SITE_SUCCESS:
      return siteAllIds(action)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_ALL_SITES:
    case types.FETCH_SITE:
      return { ...state, loading: true, error: '' }

    case types.FETCH_ALL_SITES_ERROR:
    case types.FETCH_SITE_ERROR:
      return { ...state, loading: false, error: action.error }

    case types.FETCH_ALL_SITES_SUCCESS:
    case types.FETCH_SITE_SUCCESS:
      return {
        ...state,
        ...action.payload.meta,
        newOpportunity: null,
        loading: false,
        error: '',
      }

    case types.ADD_SITE:
    case types.UPDATE_SITE:
      return { ...state, formError: '' }

    case types.ADD_SITE_ERROR:
    case types.UPDATE_SITE_ERROR:
    case types.UPDATE_SITE_EXTERNAL_RESOURCE_ERROR:
      return { ...state, formError: action.error, loading: false }

    case types.CLEAR_SITE_FORM:
      return { ...state, formError: null, loading: false }

    case types.CLEAR_NEW_OPPORTUNITY:
      return { ...state, newOpportunity: null }

    case types.CLEAR_SITES:
      return initialState.meta

    case types.FETCH_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        newOpportunity: action.payload,
        loading: false,
        error: '',
      }

    case types.FETCH_OPPORTUNITY_LOADING_STATUS:
      return { ...state, isSearchingOpportunity: action.payload }

    case types.FETCH_OPPORTUNITY_ERROR:
      return {
        ...state,
        newOpportunity: {
          message: action.error,
          error: true,
          id: '',
          opportunityId: '',
          type: 'LIGHTING',
          newOpportunityIndex: action.newOpportunityIndex,
        },
        loading: false,
        error: '',
      }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
  siteHealth,
  sitesHealth,
  siteExternalResources,
}) // ACTION CREATORS

export const actions = {
  fetchAllSites: (params: FTFetchAllSitesAction) => ({
    type: types.FETCH_ALL_SITES,
    ...params,
  }),
  fetchSite: (params: FTFetchSiteAction) => ({
    type: types.FETCH_SITE,
    ...params,
  }),
  fetchSiteHealth: (params: FTFetchSiteHealthAction) => ({
    type: types.FETCH_SITE_HEALTH,
    ...params,
  }),
  fetchSitesHealth: (params: FTFetchSitesHealthAction) => ({
    type: types.FETCH_SITES_HEALTH,
    ...params,
  }),
  fetchSiteExternalResources: (params: { siteId: string }) => ({
    type: types.FETCH_SITE_EXTERNAL_RESOURCES,
    ...params,
  }),
  // Can only update Hydropoint for now
  updateSiteExternalResource: ({
    body,
    siteId,
  }: {
    siteId: string
    body: FTUpdateSiteExternalResource
  }) => ({
    type: types.UPDATE_SITE_EXTERNAL_RESOURCE,
    siteId,
    body,
  }),
  addSite: (customerId: string, site: FTSite) => ({
    type: types.ADD_SITE,
    customerId,
    site,
  }),
  updateSite: (siteId: string, site: FTSite) => ({
    type: types.UPDATE_SITE,
    siteId,
    site,
  }),
  deleteSite: (siteId: string) => ({
    type: types.DELETE_SITE,
    siteId,
  }),
  clearSiteForm: () => ({
    type: types.CLEAR_SITE_FORM,
  }),
  clearSites: () => ({
    type: types.CLEAR_SITES,
  }),
  fetchOpportunity: (params: FTFetchOpportunityAction) => ({
    type: types.FETCH_OPPORTUNITY,
    ...params,
  }),
  clearNewOpportunity: () => ({
    type: types.CLEAR_NEW_OPPORTUNITY,
  }),
}
// Selectors
export const selectSites = (state: FTState): Array<FTSite> =>
  state.entities.sites.allIds.map((id) => state.entities.sites.byId[id])
export const selectSitesById = (state: FTState): Record<string, FTSite> =>
  state.entities.sites.byId
const selectSite = (state: FTState, siteId: string): FTSite =>
  siteId ? state.entities.sites.byId[siteId] : {}
export const selectSitesRequestStatus = ({
  requestStatus: {
    sites: { status },
  },
}: FTState): string => status || ''
export const selectSiteHealthRequestStatus = ({
  requestStatus: { fetchSiteHealth },
}: FTState): FTRequestStatus => (fetchSiteHealth ? fetchSiteHealth.status : '')
export const selectSitesHealthRequestStatus = ({
  requestStatus: { fetchSitesHealth },
}: FTState): FTRequestStatus =>
  fetchSitesHealth ? fetchSitesHealth.status : ''
export const selectSiteHealth = (state: FTState) =>
  state.entities.sites.siteHealth
export const selectSiteExternalResources = (state: FTState) =>
  state.entities.sites.siteExternalResources

const selectSitesHealth = (state: FTState) => state.entities.sites.sitesHealth

const selectSitesMeta = (state: FTState) => state.entities.sites.meta

export const selectSiteListEntity = (state: FTState) => ({
  items: selectSites(state),
  meta: selectSitesMeta(state),
})
export const selectSiteListEntityWithHealth = (state: FTState) => {
  const sitesHealthData = selectSitesHealth(state)
  const items: Array<FTSite> = selectSites(state).map((item) => {
    const {
      healthStatusForElectricMeterTypesPerSitesLastMonth = {},
      healthStatusForElectricMeterTypesPerSitesCurrentMonth = {},
      healthStatusForGasMeterTypesPerSitesLastMonth = {},
      healthStatusForGasMeterTypesPerSitesCurrentMonth = {},
    } = sitesHealthData
    return {
      ...item,
      healthStatusElectricMetersLastMonth:
        healthStatusForElectricMeterTypesPerSitesLastMonth[item.id],
      healthStatusElectricMetersCurrentMonth:
        healthStatusForElectricMeterTypesPerSitesCurrentMonth[item.id],
      healthStatusGasMetersLastMonth:
        healthStatusForGasMeterTypesPerSitesLastMonth[item.id],
      healthStatusGasMetersCurrentMonth:
        healthStatusForGasMeterTypesPerSitesCurrentMonth[item.id],
    }
  })
  return {
    items,
    meta: selectSitesMeta(state),
  }
}
export const selectSiteEntity = (state: FTState, id: string) => ({
  byId: state.entities.sites.byId,
  item: selectSite(state, id),
  meta: selectSitesMeta(state),
})
// API
class API {
  static getSites(params: FTFetchAllSitesAction) {
    const { customerId, withHealth } = params
    const query = makeApiQueryString(params, {
      customerId,
      withHealth,
    })
    const url = `${consoleApiUrl()}/admin/sites?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }

  static createSite(customerId: string, body: FTSite) {
    return axios
      .post(`${consoleApiUrl()}/admin/sites`, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => ({
        id: data.id,
      }))
      .catch(handleAxiosError)
  }

  static getSite({ siteId }: FTFetchSiteAction) {
    const url = `${consoleApiUrl()}/admin/sites/${siteId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }): FTSite => data)
      .catch(handleAxiosError)
  }

  static getSiteHealth({ siteId }: FTFetchSiteHealthAction) {
    const url = `${consoleApiUrl()}/admin/health-status-summary/site?siteId=${siteId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response): FTSiteHealth => response)
      .catch(handleAxiosError)
  }

  static getSitesHealth({ siteIds }: FTFetchSitesHealthAction) {
    const url = `${consoleApiUrl()}/admin/health-status-summary/customer`
    return axios
      .post(
        url,
        {
          siteIds,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then((response): FTSitesHealth => response)
      .catch(handleAxiosError)
  }

  static getSiteExternalResources({ siteId }: { siteId: string }) {
    const url = `${consoleApiUrl()}/admin/sites/${siteId}/external-resources`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response): Array<FTSiteExternalResource> => response)
      .catch(handleAxiosError)
  }

  static updateSiteExternalResource(
    siteId: string,
    body: FTUpdateSiteExternalResource,
  ) {
    return axios
      .post(
        `${consoleApiUrl()}/admin/sites/${siteId}/external-resources`,
        body,
        {
          headers: defaultHeaders(),
        },
      )
      .then((response) => response)
      .catch(handleAxiosError)
  }

  static updateSite(siteId: string, body: FTSite) {
    return axios
      .patch(`${consoleApiUrl()}/admin/sites/${siteId}`, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => ({
        id: data.id,
      }))
      .catch(handleAxiosError)
  }

  static deleteSite(siteId: string) {
    return axios
      .delete(`${consoleApiUrl()}/admin/sites/${siteId}`, {
        headers: defaultHeaders(),
      })
      .then(() => null)
      .catch(handleAxiosError)
  }

  static getOpportunity({ opportunityId }: FTFetchOpportunityAction) {
    const url = `${consoleApiUrl()}/admin/sites/opportunity/${opportunityId}/loa-contract`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }): FTContract => data)
      .catch(handleAxiosError)
  }
}
function* fetchAllSitesSaga(
  params: FTFetchAllSitesAction,
): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      sites: {
        status: globalStatus.LOADING,
      },
    })
    const response = yield call(API.getSites, params)
    const payload = makeSagaPayload(response, siteSchema, utils.enhanceSite)
    yield put({
      type: types.FETCH_ALL_SITES_SUCCESS,
      payload,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      sites: {
        status: globalStatus.LOADED,
      },
    })
  } catch (e) {
    yield handleError('fetchAllSites', e)
    yield handleSagaError(types.FETCH_ALL_SITES_ERROR, e)
  }
}
function* fetchSiteHealthSaga(
  params: FTFetchSiteHealthAction,
): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      fetchSiteHealth: {
        status: globalStatus.LOADING,
      },
    })
    const response = yield call(API.getSiteHealth, params)
    yield put({
      type: types.FETCH_SITE_HEALTH_SUCCESS,
      payload: response,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      fetchSiteHealth: {
        status: globalStatus.LOADED,
      },
    })
  } catch (e) {
    yield handleError('fetchSiteHealth', e)
  }
}
function* fetchSitesHealthSaga(
  params: FTFetchSitesHealthAction,
): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      fetchSitesHealth: {
        status: globalStatus.LOADING,
      },
    })
    const response = yield call(API.getSitesHealth, params)
    yield put({
      type: types.FETCH_SITES_HEALTH_SUCCESS,
      payload: response,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      fetchSitesHealth: {
        status: globalStatus.LOADED,
      },
    })
  } catch (e) {
    yield handleError('fetchSitesHealth', e)
  }
}
function* fetchSiteSaga(params: FTFetchSiteAction): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      sites: {
        status: globalStatus.LOADING,
      },
    })
    const response = yield call(API.getSite, params)
    const results = [utils.enhanceSite(response)]
    const normalized = normalize(results, [siteSchema])
    yield put({
      type: types.FETCH_ALL_SITES_SUCCESS,
      payload: normalized,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      sites: {
        status: globalStatus.LOADED,
      },
    })
  } catch (e) {
    yield handleError('sites', e)
    yield handleSagaError(types.FETCH_SITE_ERROR, e)
  }
}

function* addSiteSaga({
  customerId,
  site,
}: {
  customerId: string
  site: Record<string, any>
}): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      siteForm: {
        status: globalStatus.LOADING,
      },
    })
    const payload = cleanFormData(site)
    const { id } = yield call(API.createSite, customerId, payload)
    yield put({
      type: types.ADD_SITE_SUCCESS,
      id,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      siteForm: {
        status: globalStatus.LOADED,
        createdSiteId: id,
      },
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      siteForm: {
        status: null,
        createdSiteId: null,
      },
    })
    yield put(
      flasherActions.showMessage({
        title: `New Site ${site.display} created`,
      }),
    )
  } catch (e) {
    yield handleSagaError(types.ADD_SITE_ERROR, e)
  }
}

function* updateSiteSaga({
  siteId,
  site,
}: {
  siteId: string
  site: FTSite
}): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      siteForm: {
        status: globalStatus.LOADING,
      },
    })
    const validProperties = validSubmitProperties
    const payload = cleanFormData(site, validProperties)
    yield call(API.updateSite, siteId, payload)
    yield put({
      type: types.UPDATE_SITE_SUCCESS,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      siteForm: {
        status: globalStatus.LOADED,
        editedSiteId: siteId,
      },
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      siteForm: {
        status: null,
        editedSiteId: null,
      },
    })
    yield put(
      flasherActions.showMessage({
        title: `Site ${site.display} updated`,
      }),
    )
  } catch (e) {
    yield handleSagaError(types.UPDATE_SITE_ERROR, e)
  }
}
function* updateSiteExternalResourceSaga({
  siteId,
  body,
}: {
  siteId: string
  body: FTUpdateSiteExternalResource
}): Generator<any, void, any> {
  try {
    yield call(API.updateSiteExternalResource, siteId, body)
    yield put({
      type: types.UPDATE_SITE_EXTERNAL_RESOURCE_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_SITE_EXTERNAL_RESOURCE_ERROR, e)
  }
}
function* fetchSiteExternalResourcesSaga(params: {
  siteId: string
}): Generator<any, void, any> {
  try {
    const response = yield call(API.getSiteExternalResources, params)
    yield put({
      type: types.FETCH_SITE_EXTERNAL_RESOURCES_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleError('fetchSiteExternalResources', e)
  }
}
function* fetchOpportunitySaga(
  params: FTFetchOpportunityAction,
): Generator<any, void, any> {
  try {
    yield put({
      type: types.FETCH_OPPORTUNITY_LOADING_STATUS,
      payload: true,
    })
    const response = yield call(API.getOpportunity, params)
    const results = [utils.enhanceContract(response)]
    // const normalized = normalize(results, [siteSchema]);
    yield put({
      type: types.FETCH_OPPORTUNITY_SUCCESS,
      payload: { ...results[0], newOpportunityIndex: params.contractIndex },
    })
    yield put({
      type: types.FETCH_OPPORTUNITY_LOADING_STATUS,
      payload: false,
    })
  } catch (e) {
    yield put({
      type: types.FETCH_OPPORTUNITY_LOADING_STATUS,
      payload: false,
    })
    yield handleError('sites', e)
    yield handleSagaError(types.FETCH_OPPORTUNITY_ERROR, e, {
      newOpportunityIndex: params.contractIndex,
    })
  }
}
export const sagas = [
  takeEvery(types.UPDATE_SITE, updateSiteSaga),
  takeEvery(
    types.UPDATE_SITE_EXTERNAL_RESOURCE,
    updateSiteExternalResourceSaga,
  ),
  takeEvery(types.ADD_SITE, addSiteSaga),
  takeLatest(types.FETCH_ALL_SITES, fetchAllSitesSaga),
  takeEvery(types.FETCH_SITE, fetchSiteSaga),
  takeLatest(types.FETCH_SITE_HEALTH, fetchSiteHealthSaga),
  takeLatest(types.FETCH_SITES_HEALTH, fetchSitesHealthSaga),
  takeLatest(
    types.FETCH_SITE_EXTERNAL_RESOURCES,
    fetchSiteExternalResourcesSaga,
  ),
  takeLatest(types.FETCH_OPPORTUNITY, fetchOpportunitySaga),
]
export { default as themes } from './themes'
