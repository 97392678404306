import { PureComponent } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { STORAGE_LOGIN_TOKEN } from '../constants'
import type { FTRouteProps } from '../types'
import '../types'

export default class LoginRoute extends PureComponent<FTRouteProps> {
  static defaultProps = {
    nextState: null,
  }

  render() {
    const { component: LoginComponent, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={(props) =>
          !localStorage.getItem(STORAGE_LOGIN_TOKEN) ?
            <LoginComponent {...props} />
          : <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
        }
      />
    )
  }
}
