import styled from 'styled-components'
import ListSelector from '../../../../../components/ListSelector'
import { StyledLabel } from './../../styles'

const SelectContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  ${StyledLabel} {
    margin: 0 0 8px;
  }
`
const StyledSelect = styled(ListSelector)`
  width: 300px;
  height: 36px;
`

type CustomerAndSiteIdSelectorProps = {
  customerId: string | null
  setCustomerId: (customerId: string) => void
  siteId: string | null
  setSiteId: (siteId: string) => void
  customerListEntity: Record<string, any>
  siteListEntity: Record<string, any>
  disabled?: boolean
}

const CustomerAndSiteIdSelector = (props: CustomerAndSiteIdSelectorProps) => {
  const {
    customerListEntity,
    siteListEntity,
    setCustomerId,
    setSiteId,
    customerId,
    siteId,
    disabled,
  } = props
  const { items: customerItems, meta: customerMeta } = customerListEntity
  const { items: siteItems, meta: siteMeta } = siteListEntity
  const customerOptions = customerItems.map((customer) => ({
    id: customer.id,
    name: customer.validName,
  }))
  const siteOptions = siteItems.map((site) => ({
    id: site.id,
    name: site.validName,
  }))
  const selectedCustomerItem = customerOptions.find(
    (customer) => customer.id === customerId,
  )
  const selectedSiteItem = siteOptions.find((site) => site.id === siteId)
  return (
    <div>
      <SelectContainerStyled>
        <StyledLabel>Customer</StyledLabel>
        <StyledSelect
          items={customerOptions}
          selectedItem={selectedCustomerItem}
          disabled={disabled || customerMeta.loading}
          notSetLabelText={
            customerMeta.loading ? 'Loading...' : 'Select Option'
          }
          updateValue={(value) => setCustomerId(value.value)}
        />
      </SelectContainerStyled>
      <SelectContainerStyled>
        <StyledLabel>Site</StyledLabel>
        <StyledSelect
          items={siteOptions}
          selectedItem={selectedSiteItem}
          disabled={!customerId || siteMeta.loading || disabled}
          notSetLabelText={siteMeta.loading ? 'Loading...' : 'Select Option'}
          updateValue={(value) => setSiteId(value.value)}
        />
      </SelectContainerStyled>
    </div>
  )
}

export default CustomerAndSiteIdSelector
