import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { handleAxiosError, queryStringify } from '../../api/utils'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleSagaError } from '../../sagas/utils'
import { isVariantActive } from '../../utils'
import customerMetricsMockData from '../../mockData/proposals/customerMetrics'

type FTProposalCustomerMetricsResponse = {
  batchAnalysesInProgressCount: number
  opportunitiesBillingAndMonitoring: number
  pendingOpportunities: number
  salesforceCustomerId: string
  totalFixtures: number
}
export type FTProposalCustomerMetrics = FTProposalCustomerMetricsResponse & {
  id: string
}
export type FTFetchProposalCustomerMetricsAction = {
  salesforceCustomerId: string
}
export type FTProposalCustomerMetricsMetaState = {
  error: string
  loading: boolean
}
export type FTProposalCustomerMetricsEntityState = {
  byId: Record<string, FTProposalCustomerMetrics>
  items: Array<FTProposalCustomerMetrics>
  meta: FTProposalCustomerMetricsMetaState
}
type FTState = {
  entities: {
    proposalCustomerMetrics: FTProposalCustomerMetricsEntityState
  }
}
// Action Types
export const types = {
  FETCH_PROPOSAL_CUSTOMER_METRICS: 'FETCH_PROPOSAL_CUSTOMER_METRICS',
  FETCH_PROPOSAL_CUSTOMER_METRICS_SUCCESS:
    'FETCH_PROPOSAL_CUSTOMER_METRICS_SUCCESS',
  FETCH_PROPOSAL_CUSTOMER_METRICS_ERROR:
    'FETCH_PROPOSAL_CUSTOMER_METRICS_ERROR',
}
export const actions = {
  fetchProposalCustomerMetrics: (
    props: FTFetchProposalCustomerMetricsAction,
  ) => ({
    type: types.FETCH_PROPOSAL_CUSTOMER_METRICS,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('proposalCustomerMetrics')

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalCustomerMetrics }
}

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalCustomerMetrics> = Object.values(
    action.payload.entities.proposalCustomerMetrics,
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_METRICS:
      return initialState.byId

    case types.FETCH_PROPOSAL_CUSTOMER_METRICS_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_METRICS:
      return initialState.items

    case types.FETCH_PROPOSAL_CUSTOMER_METRICS_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_METRICS:
      return { ...state, error: '', loading: true }

    case types.FETCH_PROPOSAL_CUSTOMER_METRICS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PROPOSAL_CUSTOMER_METRICS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
export const selectProposalCustomerMetricsEntity = (
  state: FTState,
): FTProposalCustomerMetricsEntityState =>
  state.entities.proposalCustomerMetrics
const utils = {
  enhanceProposalCustomerMetrics: (
    customerMetrics: FTProposalCustomerMetricsResponse,
  ): FTProposalCustomerMetrics => ({
    ...customerMetrics,
    id: customerMetrics.salesforceCustomerId,
  }),
}
export const API = {
  fetchProposalCustomerMetrics: (
    params: FTFetchProposalCustomerMetricsAction,
  ) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(customerMetricsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const query = queryStringify(params)
    const baseUrl = `${consoleBaseUrl()}/proposal/api/customer/stats`
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalCustomerMetricsResponse }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchProposalCustomerMetricsSaga({
  type,
  ...params
}: FTFetchProposalCustomerMetricsAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTProposalCustomerMetricsResponse = yield call(
      API.fetchProposalCustomerMetrics,
      params,
    )
    const normalized = normalize(
      [utils.enhanceProposalCustomerMetrics(response)],
      [entitySchema],
    )
    yield put({
      type: types.FETCH_PROPOSAL_CUSTOMER_METRICS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_CUSTOMER_METRICS_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_PROPOSAL_CUSTOMER_METRICS,
    fetchProposalCustomerMetricsSaga,
  ),
]
