import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { handleAxiosError, queryStringify } from '../../../api/utils'
import { consoleBaseUrl, defaultHeaders } from '../../../api'
import { handleSagaError } from '../../../sagas/utils'
import { isVariantActive } from '../../../utils'
import opportunitiesMockData from '../../../mockData/proposals/multisite/opportunities'
import type { FTProposalScenario } from '../scenarios'
import '../scenarios'

type FTProposalOpportunityStatuses = 'NTP_GENERATED' | 'SCOPING'
type FTProposalOpportunityResponse = {
  auditVendor: string
  batchAnalysisTotalCount: number
  currencyCode: string
  externalId: string
  name: string
  opportunityStatus: FTProposalOpportunityStatuses
  opportunityType: string
  programOpportunity: string
  redaptiveOpportunityId: string
  salesforceSiteId: string
  scenarios: Array<FTProposalScenario>
  scenarioTotalCount: number
  selectedInBatchAnalysis: boolean
  selectedScenarioIdInBatch: string
  stageName: string
}
type FTProposalOpportunitiesResponse = Array<FTProposalOpportunityResponse>
export type FTProposalOpportunity = FTProposalOpportunityResponse & {
  id: string
}
export type FTFetchProposalOpportunitiesAction = {
  opportunityIds: Array<string>
}
export type FTProposalOpportunitiesMetaState = {
  error: string
  loading: boolean
}
export type FTProposalOpportunitiesEntityState = {
  byId: Record<string, FTProposalOpportunity>
  items: Array<FTProposalOpportunity>
  meta: FTProposalOpportunitiesMetaState
  statusUpdateMeta: FTProposalOpportunitiesMetaState
}
type FTState = {
  entities: {
    proposalOpportunities: FTProposalOpportunitiesEntityState
  }
}
// Action Types
export const types = {
  FETCH_PROPOSAL_OPPORTUNITIES: 'FETCH_PROPOSAL_OPPORTUNITIES',
  FETCH_PROPOSAL_OPPORTUNITIES_SUCCESS: 'FETCH_PROPOSAL_OPPORTUNITIES_SUCCESS',
  FETCH_PROPOSAL_OPPORTUNITIES_ERROR: 'FETCH_PROPOSAL_OPPORTUNITIES_ERROR',
}
export const actions = {
  fetchProposalOpportunities: (props: FTFetchProposalOpportunitiesAction) => ({
    type: types.FETCH_PROPOSAL_OPPORTUNITIES,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
  statusUpdateMeta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('proposalOpportunities')

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalOpportunity> = Object.values(
    action.payload.entities.proposalOpportunities,
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalOpportunities }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITIES:
      return initialState.byId

    case types.FETCH_PROPOSAL_OPPORTUNITIES_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITIES:
      return initialState.items

    case types.FETCH_PROPOSAL_OPPORTUNITIES_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITIES:
      return { ...state, error: '', loading: true }

    case types.FETCH_PROPOSAL_OPPORTUNITIES_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PROPOSAL_OPPORTUNITIES_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
export const selectProposalOpportunitiesEntity = (
  state: FTState,
): FTProposalOpportunitiesEntityState => state.entities.proposalOpportunities
const utils = {
  enhanceOpportunity: (
    opportunity: FTProposalOpportunityResponse,
  ): FTProposalOpportunity => ({ ...opportunity, id: opportunity.externalId }),
}
export const API = {
  fetchProposalOpportunities: (params: FTFetchProposalOpportunitiesAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(opportunitiesMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const query = queryStringify(params)
    const url = `${consoleBaseUrl()}/proposal/api/opportunity/list?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalOpportunitiesResponse }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchProposalOpportunitiesSaga({
  type,
  ...params
}: FTFetchProposalOpportunitiesAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTProposalOpportunitiesResponse = yield call(
      API.fetchProposalOpportunities,
      params,
    )
    const normalized = normalize(response.map(utils.enhanceOpportunity), [
      entitySchema,
    ])
    yield put({
      type: types.FETCH_PROPOSAL_OPPORTUNITIES_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_OPPORTUNITIES_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_PROPOSAL_OPPORTUNITIES,
    fetchProposalOpportunitiesSaga,
  ),
]
