import { combineReducers } from 'redux'
import { schema, normalize } from 'normalizr'
import { takeLatest, takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import { consoleApiUrl, defaultHeaders } from '../api'
import { makeActionTypes, orderByToString, makeSagaPayload } from './utils'
import { handleAxiosError, queryStringify, getTotalPages } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import type { FTSortable } from '../types'
import '../types'

export type FTEquipmentEntity = {
  name: string
  description?: string
  siteId: string
  id: string
}
export type FTEquipmentSummary = {
  id: string
  name: string
}
export type FTFetchEquipmentAction = {
  siteId?: string
  meterId?: string
  pageNumber?: number
  pageSize?: number
  orderBy?: FTSortable
}
export type FTAddEquipmentAction = {
  name: string
  description?: string
  siteId: string
}
export type FTUpdateEquipmentAction = {
  id: string
  name: string
  description?: string
}
export type FTDeleteEquipmentAction = {
  id: string
}
// Entity Name
const entity = 'equipment'
// Action Types
export const types = {
  ...makeActionTypes('FETCH_EQUIPMENT_LIST'),
  ...makeActionTypes('FETCH_EQUIPMENT'),
  ...makeActionTypes('ADD_EQUIPMENT'),
  ...makeActionTypes('UPDATE_EQUIPMENT'),
  ...makeActionTypes('DELETE_EQUIPMENT'),
}
// Utils
export const utils = {}
type FTEquipmentEntityState = {
  byId: Record<string, any>
  allIds: Array<string>
  meta: {
    pageNumber: number | null | undefined
    pageSize: number | null | undefined
    next: string | null | undefined
    previous: string | null | undefined
    error: string | null | undefined
    loading: boolean
    addLoading: boolean
    updateLoading: boolean
    deleteLoading: boolean
  }
}
type FTState = {
  entities: {
    equipment: FTEquipmentEntityState
  }
}
// Reducers
export const initialState = {
  byId: {},
  allIds: [],
  meta: {
    pageNumber: 1,
    pageSize: 20,
    next: null,
    previous: null,
    loading: false,
    addLoading: false,
    updateLoading: false,
    deleteLoading: false,
    updates: [],
  },
}
export const entitySchema = new schema.Entity(entity)

function entityById(action, state) {
  return { ...state, ...action.payload.entities[entity] }
}

function entityAllIds(action, state) {
  return [...new Set(state.concat(action.payload.result))]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_EQUIPMENT_LIST:
    case types.FETCH_EQUIPMENT:
      return {}

    case types.FETCH_EQUIPMENT_LIST_SUCCESS:
    case types.FETCH_EQUIPMENT_SUCCESS:
    case types.ADD_EQUIPMENT_SUCCESS:
    case types.UPDATE_EQUIPMENT_SUCCESS:
      return entityById(action, state)

    case types.DELETE_EQUIPMENT_SUCCESS:
      if (action.payload.id) {
        const { [action.payload.id]: deleted, ...newState } = state
        return newState
      }

      return state

    default:
      return state
  }
}

function allIds(state = initialState.allIds, action) {
  switch (action.type) {
    case types.FETCH_EQUIPMENT_LIST:
    case types.FETCH_EQUIPMENT:
      return []

    case types.FETCH_EQUIPMENT_LIST_SUCCESS:
    case types.FETCH_EQUIPMENT_SUCCESS:
    case types.ADD_EQUIPMENT_SUCCESS:
    case types.UPDATE_EQUIPMENT_SUCCESS:
      return entityAllIds(action, state)

    case types.DELETE_EQUIPMENT_SUCCESS:
      if (action.payload.id) {
        return state.filter((p) => p !== action.payload.id)
      }

      return state

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_EQUIPMENT_LIST:
    case types.FETCH_EQUIPMENT:
      return { ...state, loading: true, error: '' }

    case types.FETCH_EQUIPMENT_LIST_ERROR:
    case types.FETCH_EQUIPMENT_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_EQUIPMENT_LIST_SUCCESS:
    case types.FETCH_EQUIPMENT_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    case types.ADD_EQUIPMENT:
      return { ...state, addLoading: true, error: '' }

    case types.ADD_EQUIPMENT_SUCCESS:
      return { ...state, addLoading: false }

    case types.ADD_EQUIPMENT_ERROR:
      return { ...state, addLoading: false, error: action.error }

    case types.UPDATE_EQUIPMENT:
      return {
        ...state,
        updateLoading: true,
        updates: [...state.updates, action.id],
        error: '',
      }

    case types.UPDATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updates: state.updates.filter((id) => id !== action.payload.result[0]),
      }

    case types.UPDATE_EQUIPMENT_ERROR:
      return {
        ...state,
        updateLoading: false,
        updates: state.updates.filter((id) => id !== action.id),
        error: action.error,
      }

    case types.DELETE_EQUIPMENT:
      return { ...state, deleteLoading: true, error: '' }

    case types.DELETE_EQUIPMENT_SUCCESS:
      return { ...state, deleteLoading: false }

    case types.DELETE_EQUIPMENT_ERROR:
      return { ...state, deleteLoading: false, error: action.error }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
}) // Action Creators

export const actions = {
  fetchEquipmentList: (params: FTFetchEquipmentAction) => ({
    type: types.FETCH_EQUIPMENT_LIST,
    ...params,
  }),
  addEquipment: (props: FTAddEquipmentAction) => ({
    type: types.ADD_EQUIPMENT,
    ...props,
  }),
  updateEquipment: (props: FTUpdateEquipmentAction) => ({
    type: types.UPDATE_EQUIPMENT,
    ...props,
  }),
  deleteEquipment: (props: FTDeleteEquipmentAction) => ({
    type: types.DELETE_EQUIPMENT,
    ...props,
  }),
}
// SELECTORS
export const selectEquipmentList = (state: FTState): Array<FTEquipmentEntity> =>
  state.entities[entity].allIds.map((id) => state.entities[entity].byId[id])
export const selectEquipment = (state: FTState, id: string) =>
  state.entities[entity].byId[id]
export const selectById = (state: FTState) => state.entities[entity].byId
export const selectEquipmentListEntity = (state: FTState) => ({
  items: selectEquipmentList(state),
  meta: state.entities[entity].meta,
})
export const selectEquipmentEntity = (state: FTState, id: string) => ({
  item: selectEquipment(state, id),
  meta: state.entities[entity].meta,
})
// API
export class API {
  static fetchEquipmentList({
    siteId,
    meterId,
    pageNumber,
    pageSize: perPage,
    orderBy,
  }: Record<string, any>) {
    const order = orderByToString(orderBy)
    const pageSize = perPage || 10000
    const query = queryStringify({
      meterId,
      siteId,
      pageNumber,
      pageSize,
      order,
    })
    const baseUrl = `${consoleApiUrl()}/equipment`
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => {
        const { data } = response
        const { totalCount } = data
        return {
          ...data,
          totalPages: getTotalPages(totalCount, pageSize),
          pageNumber,
          pageSize,
        }
      })
      .catch(handleAxiosError)
  }

  static addEquipment({ siteId, name, description }: FTAddEquipmentAction) {
    const url = `${consoleApiUrl()}/equipment`
    const body: {
      siteId: string
      name: string
      description?: string
    } = {
      siteId,
      name,
    }

    if (description) {
      body.description = description
    }

    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static updateEquipment({ id, name, description }: FTUpdateEquipmentAction) {
    const url = `${consoleApiUrl()}/equipment/${id}`
    const body: {
      name: string
      description?: string
    } = {
      name,
    }

    if (description) {
      body.description = description
    }

    return axios
      .patch(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static deleteEquipment({ id }: FTDeleteEquipmentAction) {
    const url = `${consoleApiUrl()}/equipment/${id}`
    return axios
      .delete(url, {
        headers: defaultHeaders(),
      })
      .then(() => ({
        id,
        success: true,
      }))
      .catch(handleAxiosError)
  }
}

function* fetchEquipmentListSaga(
  params: FTFetchEquipmentAction,
): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchEquipmentList, params)
    const payload = makeSagaPayload(response, entitySchema, utils.enhanceSite)
    yield put({
      type: types.FETCH_EQUIPMENT_LIST_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_EQUIPMENT_LIST_ERROR, e)
  }
}

function* addEquipmentSaga(
  params: FTAddEquipmentAction,
): Generator<any, void, any> {
  try {
    const response = yield call(API.addEquipment, params)
    const payload = normalize([response], [entitySchema])
    yield put({
      type: types.ADD_EQUIPMENT_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.ADD_EQUIPMENT_ERROR, e)
  }
}

function* updateEquipmentSaga(
  params: FTUpdateEquipmentAction,
): Generator<any, void, any> {
  const { id } = params

  try {
    const response = yield call(API.updateEquipment, params)
    const payload = normalize([response], [entitySchema])
    yield put({
      type: types.UPDATE_EQUIPMENT_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_EQUIPMENT_ERROR, e, {
      id,
    })
  }
}

function* deleteEquipmentSaga(
  params: FTDeleteEquipmentAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.deleteEquipment, params)
    yield put({
      type: types.DELETE_EQUIPMENT_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.DELETE_EQUIPMENT_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_EQUIPMENT_LIST, fetchEquipmentListSaga),
  takeEvery(types.ADD_EQUIPMENT, addEquipmentSaga),
  takeEvery(types.UPDATE_EQUIPMENT, updateEquipmentSaga),
  takeEvery(types.DELETE_EQUIPMENT, deleteEquipmentSaga),
]
