import { ReactNode } from 'react'
import styled from 'styled-components'

export type FTPlotViewField = {
  label: string
  value: number | string | Record<string, any>
  key: string
  renderField?: () => ReactNode
}
type FTProps = {
  editMode?: boolean
  handleClick?: (label: string, key: string) => void
  fields: Array<FTPlotViewField>
}
const TableStyled = styled.table`
  min-width: 70%;
`
const RowStyled = styled.tr`
  cursor: ${({ hasClick }: { hasClick: boolean }) =>
    hasClick ? 'pointer' : 'auto'};
`
const cellPadding = '8px'
export const LabelCellStyled = styled.td`
  font-weight: 600;
  padding: 0 30px 0 ${cellPadding};
`
const ValueCellStyled = styled.td`
  padding: 0 ${cellPadding};
`
const CellInnerStyled = styled.div`
  display: flex;
  align-items: center;
  min-height: ${({ editMode }) => (editMode ? '42px' : '35px')};
`

const PlotViewFieldList = ({ editMode, fields, handleClick }: FTProps) => (
  <TableStyled>
    <tbody>
      {fields.map(({ label, value, renderField, key }) => (
        <RowStyled
          key={label}
          onClick={!!handleClick ? () => handleClick(label, key) : () => {}}
          hasClick={!!handleClick}
        >
          <LabelCellStyled>
            <CellInnerStyled editMode={editMode}>{label}</CellInnerStyled>
          </LabelCellStyled>
          <ValueCellStyled>
            {renderField && renderField()}
            {!renderField && <CellInnerStyled>{value}</CellInnerStyled>}
          </ValueCellStyled>
        </RowStyled>
      ))}
    </tbody>
  </TableStyled>
)

PlotViewFieldList.defaultProps = {
  editMode: false,
}
export default PlotViewFieldList
