import styled from 'styled-components'
import { StyledLabel } from './styles'
import Input from '../../../components/Input'

const StyledInputContainer = styled.div`
  ${StyledLabel} {
    margin-bottom: 6px;
  }
`
const StyledInput = styled(Input)`
  width: 300px;
`
type InputContainerProps = {
  onInputChange: (value: string) => void
  placeholder: string
  label: string
  value: string | null
  disabled?: boolean
}

const InputContainer = (props: InputContainerProps) => {
  const { onInputChange, placeholder, label, value, disabled } = props
  return (
    <StyledInputContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        placeholder={placeholder}
        value={value || ''}
        onChange={(event) => onInputChange(event.currentTarget.value)}
        disabled={!!disabled}
      />
    </StyledInputContainer>
  )
}

export default InputContainer
