import styled from 'styled-components'
import { ChangeEvent, useRef } from 'react'
import { ClearBtnStyled } from './commons'
import AddPhoto from '../Icons/svg/AddPhoto'
import CloseOutline from '../Icons/svg/CloseOutline'
import Accordion, { MainContainerStyled } from '../Accordion'
import { FTPhotoDownloadUrl } from '../../ducks/panelPhotos'

const ItemWrapperStyled = styled.div`
  display: flex;
  margin-bottom: 10px;
  position: relative;
  width: 130px;
  height: 130px;
  object-fit: cover;
  margin-right: 20px;
  ${ClearBtnStyled} {
    margin-right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: -9px;
    right: -8px;
    background-color: #e0e0e0;
    svg {
      margin: 0;
    }
  }
`

const StyledImgThumb = styled.img`
  width: 130px;
  height: 130px;
`

const ItemRowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  
}
`

const AccordianWrapperStyled = styled.div`
  margin-top: 20px;
  ${MainContainerStyled} {
    padding: 0;
  }
`

const AddBtnStyled = styled.button`
  width: 130px;
  height: 130px;
  border: 1px dashed #337ab7;
  border-radius: 2px;
  p {
    margin: 0;
  }
  input {
    display: none;
  }
`

type ExpandablePhotoHandlerProps = {
  title: string;
  items: File[];
  isAllExpanded: boolean;
  availablePhotos: FTPhotoDownloadUrl[];
  onPhotoInputChange: (e: ChangeEvent) => void;
  onRemove: (fileName: string, fileFor: string, fileId?: string) => void;
}
const ExpandablePhotoHandler = (props: ExpandablePhotoHandlerProps) => {
  const { title, isAllExpanded, items, availablePhotos = [], onPhotoInputChange, onRemove } = props
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const onAdd = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }
  const renderComponentinAccordian = () => (
    <ItemRowStyled>
      {
        availablePhotos.map((photo) => (
          <ItemWrapperStyled key={photo.id}>
            <a href={photo.preSignedUrl} target="_blank" rel="noreferrer">
              <StyledImgThumb src={photo.preSignedThumbnailUrl} alt='thumbnail' />
            </a>
            <ClearBtnStyled onClick={() => onRemove(photo.fileName, title, photo.id)}>
              <CloseOutline />
            </ClearBtnStyled>
          </ItemWrapperStyled>
        ))
      }
      {
        // image thumbnails with remove or cross button on top right
        items.map((item) => (
          <ItemWrapperStyled key={item.name}>
            <a href={URL.createObjectURL(item)} target="_blank" rel="noreferrer">
              <StyledImgThumb src={URL.createObjectURL(item)} alt='thumbnail' />
            </a>
            <ClearBtnStyled onClick={() => onRemove(item.name, title)}>
              <CloseOutline />
            </ClearBtnStyled>
          </ItemWrapperStyled>
        ))
      }
      <AddBtnStyled type="button" onClick={() => onAdd()}>
        <AddPhoto />
        <p>Click to Upload</p>
        <input ref={hiddenFileInput} type='file' accept='image/*' data-filefor={title} onChange={onPhotoInputChange} multiple />
      </AddBtnStyled>
    </ItemRowStyled>
  )
  return (
    <AccordianWrapperStyled>
    <Accordion
      title={title === 'BreakerTypes' ? 'Breaker Types' : title}
      isExpandedInitially={isAllExpanded}
      Content={renderComponentinAccordian}
    />
    </AccordianWrapperStyled>
  )
}

export default ExpandablePhotoHandler
