import { ComponentType, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SimpleBackButton from './SimpleBackButton'
import { getDashboardUrl, getDenaliUrl } from '../api'
import { selectUsers } from '../ducks/users'

const DescriptionStyles = styled.div`
  font-size: 14px;
`
const DisplayStyles = styled.div`
  margin-top: 20px;
  text-align: center;
`
const PlainStyles = styled.div`
  font-size: 14px;
  margin-top: 40px;
  padding: 10px 6px;
  border: #ccc;
  background-color: #eee;
  border-radius: 6px;
`
const CopyButtonStyles = css`
  button {
    background-color: #0193d7a6;
  }
`
const CopyStyles: ComponentType<{
  copied: boolean
}> = styled.div`
  margin-top: 20px;

  ${({ copied }) => copied && CopyButtonStyles}
`
export const OneTimeLinkStyles: ComponentType<any> = styled.div`
  margin: auto;
  width: 800px;
  background-color: #ffffff;
  padding: 40px 34px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;

  button {
    cursor: pointer;
  }
`
export const OneTimeLinkTitleStyles: ComponentType<any> = styled.h2``
type FTProps = {
  email: string
  goBack?: ((...args: Array<any>) => any) | null | undefined
  isSignup?: boolean
  resetId: string
}

const OneTimeLink = ({
  goBack = null,
  email,
  isSignup = false,
  resetId,
}: FTProps) => {
  const basePath = isSignup ? 'signup' : 'reset-password'
  const title =
    isSignup ? 'One-time Sign Up Link' : 'One-time Password Reset Link'
  const [copiedDashboard, setCopiedDashboard] = useState(false)
  const [copiedDenali, setCopiedDenali] = useState(false)
  const { isDenaliUser, isDashboardUser } =
    useSelector(selectUsers).find((user) => user.email === email) || {}
  const onCopyDashboard = useCallback(() => {
    if (!copiedDashboard) {
      setCopiedDashboard(true)
      setTimeout(() => setCopiedDashboard(false), 1000)
    }
  }, [copiedDashboard])
  const onCopyDenali = useCallback(() => {
    if (!copiedDenali) {
      setCopiedDenali(true)
      setTimeout(() => setCopiedDenali(false), 1000)
    }
  }, [copiedDashboard])
  const linkDashboard = `${getDashboardUrl()}/${basePath}/${resetId}`
  const linkDenali = `${getDenaliUrl()}/${basePath}/${resetId}`
  return (
    <OneTimeLinkStyles>
      {!!goBack && <SimpleBackButton onClick={goBack} />}
      <OneTimeLinkTitleStyles>{title}</OneTimeLinkTitleStyles>
      <DescriptionStyles className='OneTimeLink-description'>
        <span>
          {`Below is the one-time ${
            isSignup ? 'sign up' : 'password reset'
          } link for `}
          <b>{`${email}. `}</b>
          {`Once you navigate away from this page, you will not be able to
           access this link again, and you will have to generate a new one.`}
        </span>
      </DescriptionStyles>
      {(isDashboardUser || !isDenaliUser) && (
        <DisplayStyles>
          <PlainStyles>{linkDashboard}</PlainStyles>
          <CopyStyles copied={copiedDashboard}>
            <CopyToClipboard text={linkDashboard} onCopy={onCopyDashboard}>
              <button type='button' className='btn'>
                {copiedDashboard ? 'Copied' : 'Copy to Clipboard'}
              </button>
            </CopyToClipboard>
          </CopyStyles>
        </DisplayStyles>
      )}
      {isDenaliUser && (
        <DisplayStyles>
          <PlainStyles>{linkDenali}</PlainStyles>
          <CopyStyles copied={copiedDenali}>
            <CopyToClipboard text={linkDenali} onCopy={onCopyDenali}>
              <button type='button' className='btn'>
                {copiedDenali ? 'Copied' : 'Copy to Clipboard'}
              </button>
            </CopyToClipboard>
          </CopyStyles>
        </DisplayStyles>
      )}
    </OneTimeLinkStyles>
  )
}

export default OneTimeLink
