import { PureComponent } from 'react'
import styled from 'styled-components'
import { FastField } from 'formik'
import FormField from '../FormField'
import FormSection from '../FormSection'
import Input from '../Input'
import GooglePlacesSearch from './GooglePlacesSearch'
import { utils as siteUtils } from '../../ducks/sites'
import type { FTWithFormik } from '../../types'

const StyledFormField = styled(FormField)`
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
const CityStateGroup = styled.div`
  ${StyledFormField} {
    display: inline-block;
  }

  ${StyledFormField}:not(:first-child) {
    margin-left: 20px;
  }

  ${StyledFormField}:nth-child(1) {
    width: 293px;
  }

  ${StyledFormField}:nth-child(2) {
    width: 72px;
  }

  ${StyledFormField}:nth-child(3) {
    width: 105px;
    margin-left: 18px;
  }
`
type FTProps = {
  optionalSubtitle: string
} & FTWithFormik
export default class AddressSection extends PureComponent<FTProps> {
  render() {
    const {
      errors,
      optionalSubtitle,
      setFieldValue,
      touched,
      values,
      validateForm,
    } = this.props
    const { country } = values
    const regionTitle = country.toLowerCase() !== 'us' ? 'Province' : 'State'
    const formSectionTitle = 'Site Address & Details'
    return (
      <FormSection title={formSectionTitle}>
        <GooglePlacesSearch
          setFieldValue={setFieldValue}
          validateForm={validateForm}
        />
        <FormField
          name='country'
          title='Country'
          showRequired={touched.country && errors.country}
          isReadOnly
          renderField={() => (
            <div>{siteUtils.prettifyCountry(values.country) || 'Not Set'}</div>
          )}
        />

        <FormField
          name='address1'
          title='Address Line 1'
          showRequired={touched.address1 && errors.address1}
        />

        <FormField
          title='Address Line 2'
          subtitle='- Optional'
          showRequired={touched.address2 && errors.address2}
          renderField={() => <FastField name='address2' component={Input} />}
        />

        <CityStateGroup>
          <StyledFormField name='city' title='City' isReadOnly />
          <StyledFormField name='state' title={regionTitle} isReadOnly />
          <StyledFormField
            name='postalCode'
            title='Postal Code'
            isReadOnly
            requiredText='*'
            showRequired={touched.postalCode && errors.postalCode}
          />
        </CityStateGroup>

        <FormField
          name='timezone'
          title='Time Zone'
          showRequired={touched.timezone && errors.timezone}
          isReadOnly
          renderField={() => (
            <div>
              {siteUtils.prettifyTimezone(values.timezone) || 'Not Set'}
            </div>
          )}
        />

        <FormField
          name='electricUtilityRate'
          title='Utility Rate'
          renderDescription={() => 'Utility Rate, in $/kWh'}
          showRequired={
            touched.electricUtilityRate && errors.electricUtilityRate
          }
          requiredText={
            touched.electricUtilityRate && errors.electricUtilityRate
          }
          readOnlyFormat='0,0'
        />

        <FormField
          name='squareFootage'
          title='Site Square Footage'
          subtitle={optionalSubtitle}
          renderDescription={() =>
            'Total area of site, in square feet. Used to display savings per square foot'
          }
          readOnlyFormat='0,0'
        />
      </FormSection>
    )
  }
}
