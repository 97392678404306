import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { zIndices } from '../utils'
import Button2 from './Button2'
import Title from './Title'
import type { FTModalConfirm2 } from '../ducks/modal'
import { actions as modalActions } from '../ducks/modal'

type FTProps = FTModalConfirm2 & {
  actions: {
    hideModal: () => null
  }
}
export const ModalConfirmWrapperStyled = styled.div`
  align-items: center;
  backdrop-filter: blur(4px);
  background: rgba(200, 200, 200, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalConfirm2};

  * {
    box-sizing: border-box;
  }

  ${Title} {
    margin-bottom: 0;
  }
`
export const ModalConfirmStyled = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  grid-template-rows: auto 1fr auto;
  display: grid;
  height: fit-content;
  text-align: center;
  width: 450px;
`
export const ModalConfirmTitleStyled = styled(Title)`
  color: #162447;
  font-family: 'Public Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  line-height: 22px;
`
export const ModalConfirmBodyStyled = styled.div`
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  height: fit-content;
  margin-bottom: 10px;
`
export const ModalConfirmButtonsStyled = styled.div`
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  display: flex;
  height: 56px;
  justify-content: flex-end;
`

const ModalConfirm2 = ({
  actions,
  renderBody,
  handlePrimaryAction,
  handleSecondaryAction = actions.hideModal,
  loading,
  renderTitle,
  primaryText = 'Confirm',
  secondaryText = 'Cancel',
  showSecondaryButton = true,
}: FTProps) => (
  <ModalConfirmWrapperStyled>
    <ModalConfirmStyled>
      <ModalConfirmTitleStyled>{renderTitle()}</ModalConfirmTitleStyled>
      <ModalConfirmBodyStyled>{renderBody()}</ModalConfirmBodyStyled>
      <ModalConfirmButtonsStyled>
        {showSecondaryButton && (
          <Button2 onClick={handleSecondaryAction} type='transparent'>
            {secondaryText}
          </Button2>
        )}
        <Button2 onClick={handlePrimaryAction} loading={loading} type='primary'>
          {primaryText}
        </Button2>
      </ModalConfirmButtonsStyled>
    </ModalConfirmStyled>
  </ModalConfirmWrapperStyled>
)

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(modalActions, dispatch) },
})

export default connect(null, mapDispatchToProps)(ModalConfirm2)
