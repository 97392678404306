/* eslint-disable no-shadow */
import axios from 'axios'
import FileSaver from 'file-saver'
import moment from 'moment'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { consoleApiUrl, defaultHeaders } from '../../../api'
import { handleAxiosError, queryStringify } from '../../../api/utils'
import billingItemsMockData from '../../../mockData/billing/billingThisMonth/billingItems'
import billingReasonsMockData from '../../../mockData/billing/billingThisMonth/reasons'
import { handleSagaError } from '../../../sagas/utils'
import {
  getNumberFormatMaxFractionDigits2,
  getNumberFormatMinFractionDigits2,
  isVariantActive,
} from '../../../utils'

type FTBillingItemReason = {
  id: string
}
export type FTBillingReason = {
  id: string
  reason: string
}
export type FTBillingItemAction = 'ESTIMATE' | 'ACTUAL'
type FTBillingItemResponse = {
  savingsMonth: string
  calculatedActual: number
  actualPerformance: number
  approved: string
  approvedBy: string
  category: string
  issueOwners: string
  customer: string
  customerInternalId: string
  dataCapture: number
  contractEstimate: number
  externalConversationLink: string
  id: string
  locale: string
  name: string
  oppInternalId: string
  opportunityId: string
  pendingInvestigation: boolean
  reasons: Array<FTBillingItemReason>
  reviewed: string
  reviewedBy: string
  selectedAction: FTBillingItemAction
  suggestedAction: FTBillingItemAction
  loaDate: string
  codDate: string
  owner: string
  billingStructure: string
  actualPreRetrofit: string
  actualPostRetrofit: string
  historical1Month: number
  historical2Month: number
  historical3Month: number
  firstActualBill: boolean
  invoiceNumber: string
  invoiceCreationStatus: string
  invoiceCreatedDateTime: string
  invoiceTimezone: string
  lastCalculationTimestamp: string
  status: string
  salesOrderBilledQuantity: number
  salesOrderTotalQuantity: number
  contractEstimate: number
  calculatedActual: number
}
export type FTBillingItemsResponse = Array<FTBillingItemResponse>
export type FTBillingItem = {
  calculatedActualFormatted: string
  contractEstimateFormatted: string
  loaDateFormatted: string
  codDateFormatted: string
  invoiceCreatedDateTimeFormatted: string
  lastSavingsRunFormatted: string
  invoiceCreationStatusFormatted: string
} & FTBillingItemResponse
export type FTFetchBillingItemsAction = {
  status?: string
  savingsMonth?: string
}
export type FTReCalculateSavingsAction = {
  billIds: Array<string>
  savingsYearMonth: string
}
export type FTSendToVerificationAction = {
  billId: Array<string>
  comment: string
}
export type FTSendToInvestigationAction = {
  billIds: Array<string>
  issueOwnerId: string
  issueOwners: string
  issueTypeId: string
  comment: string
}
export type FTSendToReviewAction = {
  billId: string
  selectedAction: FTBillingItemAction
  comment?: string
  reasons?: Array<FTBillingItemReason>
}
export type FTSendToReviewBulkAction = {
  id: string
  selectedAction: FTBillingItemAction
  comment: string
  reasons: Array<FTBillingItemReason>
}
export type FTSendToNetsuiteAction = {
  savingsMonth: string
  customerId: string
  opportunityList: Array<string>
}
export type FTResendToVerificationAction = {
  savingsMonth: string
  comment: string
  opportunityList: Array<string>
}
export type FTReCalculateSavingsStatusUpdateResponse = {
  id: string
}
export type FTSendToVerificationStatusResponse = {
  billItem: FTBillingItemResponse
}
export type FTSendToInvestigationStatusResponse = {
  billItem: FTBillingItemResponse
}
export type FTSendToReviewStatusResponse = {
  billItem: FTBillingItemResponse
}
export type FTSendToReviewBulkStatusResponse = Array<FTBillingItemResponse>
export type FTFetchReasonsResponse = Array<FTBillingReason>
export type FTFetchBillingAccrualsExcelResponse = Blob
export type FTBillingItemsMetaState = {
  error: string
  loading: boolean
  success: boolean
}
export type FTReCalculateSavingsMetaState = {
  error: string
  loading: boolean
}
export type FTSendToVerificationActionMetaState = {
  error: string
  loading: boolean
}
export type FTSendToInvestigationActionMetaState = {
  error: string
  loading: boolean
}
export type FTSendToReviewActionMetaState = {
  error: string
  loading: boolean
}
export type FTFetchReasonsActionMetaState = {
  error: string
  loading: boolean
}
export type FTSendToReviewBulkActionMetaState = {
  error: string
  loading: boolean
}
export type FTReRunCalculationsActionMetaState = {
  error: string
  loading: boolean
}
export type FTSendToNetsuiteMetaState = {
  error: string
  loading: boolean
}
export type FTResendToVerificationMetaState = {
  error: string
  loading: boolean
}
export type FTBillingItemsEntityState = {
  byId: Record<string, FTBillingItem>
  items: Array<FTBillingItem>
  meta: FTBillingItemsMetaState
  reasons: FTFetchReasonsResponse
  reCalculateSavingsMeta: FTReCalculateSavingsMetaState
  sendToVerificationMeta: FTSendToVerificationActionMetaState
  sendToInvestigationMeta: FTSendToInvestigationActionMetaState
  sendToReviewMeta: FTSendToReviewActionMetaState
  sendToReviewBulkMeta: FTSendToReviewBulkActionMetaState
  sendToNetsuiteMeta: FTSendToNetsuiteMetaState
  resendToVerificationMeta: FTResendToVerificationMetaState
  fetchReasonsMeta: FTFetchReasonsActionMetaState
}
export type BillingEntitiesState = {
  entities: {
    billingThisMonthItems: FTBillingItemsEntityState
  }
}
export const types = {
  BILLING_FETCH_THIS_MONTH_ITEMS: 'BILLING_FETCH_THIS_MONTH_ITEMS',
  BILLING_FETCH_THIS_MONTH_ITEMS_SUCCESS:
    'BILLING_FETCH_THIS_MONTH_ITEMS_SUCCESS',
  BILLING_FETCH_THIS_MONTH_ITEMS_ERROR: 'BILLING_FETCH_THIS_MONTH_ITEMS_ERROR',
  BILLING_RE_CALCULATE_SAVINGS: 'BILLING_RE_CALCULATE_SAVINGS',
  BILLING_RE_CALCULATE_SAVINGS_SUCCESS: 'BILLING_RE_CALCULATE_SAVINGS_SUCCESS',
  BILLING_RE_CALCULATE_SAVINGS_ERROR: 'BILLING_RE_CALCULATE_SAVINGS_ERROR',
  BILLING_SEND_TO_VERIFICATION: 'BILLING_SEND_TO_VERIFICATION',
  BILLING_SEND_TO_VERIFICATION_SUCCESS: 'BILLING_SEND_TO_VERIFICATION_SUCCESS',
  BILLING_SEND_TO_VERIFICATION_ERROR: 'BILLING_SEND_TO_VERIFICATION_ERROR',
  BILLING_SEND_TO_INVESTIGATION: 'BILLING_SEND_TO_INVESTIGATION',
  BILLING_SEND_TO_INVESTIGATION_SUCCESS:
    'BILLING_SEND_TO_INVESTIGATION_SUCCESS',
  BILLING_SEND_TO_INVESTIGATION_ERROR: 'BILLING_SEND_TO_INVESTIGATION_ERROR',
  BILLING_SEND_TO_REVIEW: 'BILLING_SEND_TO_REVIEW',
  BILLING_SEND_TO_REVIEW_SUCCESS: 'BILLING_SEND_TO_REVIEW_SUCCESS',
  BILLING_SEND_TO_REVIEW_ERROR: 'BILLING_SEND_TO_REVIEW_ERROR',
  BILLING_SEND_TO_REVIEW_BULK: 'BILLING_SEND_TO_REVIEW_BULK',
  BILLING_SEND_TO_REVIEW_BULK_SUCCESS: 'BILLING_SEND_TO_REVIEW_BULK_SUCCESS',
  BILLING_SEND_TO_REVIEW_BULK_ERROR: 'BILLING_SEND_TO_REVIEW_BULK_ERROR',
  BILLING_SEND_TO_NETSUITE: 'BILLING_SEND_TO_NETSUITE',
  BILLING_SEND_TO_NETSUITE_SUCCESS: 'BILLING_SEND_TO_NETSUITE_SUCCESS',
  BILLING_SEND_TO_NETSUITE_ERROR: 'BILLING_SEND_TO_NETSUITE_ERROR',
  BILLING_RE_SEND_TO_VERIFICATION: 'BILLING_RE_SEND_TO_VERIFICATION',
  BILLING_RE_SEND_TO_VERIFICATION_SUCCESS:
    'BILLING_RE_SEND_TO_VERIFICATION_SUCCESS',
  BILLING_RE_SEND_TO_VERIFICATION_ERROR:
    'BILLING_RE_SEND_TO_VERIFICATION_ERROR',
  BILLING_FETCH_REASONS: 'BILLING_FETCH_REASONS',
  BILLING_FETCH_REASONS_SUCCESS: 'BILLING_FETCH_REASONS_SUCCESS',
  BILLING_FETCH_REASONS_ERROR: 'BILLING_FETCH_REASONS_ERROR',
  BILLING_FETCH_ACCRUALS_EXCEL: 'BILLING_FETCH_ACCRUALS_EXCEL',
  BILLING_FETCH_ACCRUALS_EXCEL_SUCCESS: 'BILLING_FETCH_ACCRUALS_EXCEL_SUCCESS',
  BILLING_FETCH_ACCRUALS_EXCEL_ERROR: 'BILLING_FETCH_ACCRUALS_EXCEL_ERROR',
}
export const actions = {
  fetchBillingItems: (props: FTFetchBillingItemsAction) => ({
    type: types.BILLING_FETCH_THIS_MONTH_ITEMS,
    ...props,
  }),
  reCalculateSavings: (props: FTReCalculateSavingsAction) => ({
    type: types.BILLING_RE_CALCULATE_SAVINGS,
    ...props,
  }),
  sendToVerification: (props: FTSendToVerificationAction) => ({
    type: types.BILLING_SEND_TO_VERIFICATION,
    ...props,
  }),
  sendToInvestigation: (props: FTSendToInvestigationAction) => ({
    type: types.BILLING_SEND_TO_INVESTIGATION,
    ...props,
  }),
  sendToReview: (props: FTSendToReviewAction) => ({
    type: types.BILLING_SEND_TO_REVIEW,
    ...props,
  }),
  sendToReviewBulk: ({
    billingItems,
  }: {
    billingItems: Array<FTSendToReviewBulkAction>
  }) => ({
    type: types.BILLING_SEND_TO_REVIEW_BULK,
    billingItems,
  }),
  sendToNetsuite: (props: FTSendToNetsuiteAction) => ({
    type: types.BILLING_SEND_TO_NETSUITE,
    ...props,
  }),
  resendToVerification: (props: FTResendToVerificationAction) => ({
    type: types.BILLING_RE_SEND_TO_VERIFICATION,
    ...props,
  }),
  fetchReasons: () => ({
    type: types.BILLING_FETCH_REASONS,
  }),
  fetchBillingAccrualsExcel: () => ({
    type: types.BILLING_FETCH_ACCRUALS_EXCEL,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
  reasons: [],
  reCalculateSavingsMeta: {
    error: '',
    loading: false,
  },
  sendToVerificationMeta: {
    error: '',
    loading: false,
  },
  sendToInvestigationMeta: {
    error: '',
    loading: false,
  },
  sendToReviewMeta: {
    error: '',
    loading: false,
  },
  sendToReviewBulkMeta: {
    error: '',
    loading: false,
  },
  sendToNetsuiteMeta: {
    error: '',
    loading: false,
  },
  resendToVerificationMeta: {
    error: '',
    loading: false,
  },
  fetchReasonsMeta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('billingThisMonthItems')

function entityById(action, state) {
  return { ...state, ...action.payload.entities.billingThisMonthItems }
}

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTBillingItem> = Object.values(
    action.payload.entities.billingThisMonthItems || {},
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.BILLING_FETCH_THIS_MONTH_ITEMS:
      return initialState.byId

    case types.BILLING_FETCH_THIS_MONTH_ITEMS_SUCCESS:
    case types.BILLING_SEND_TO_VERIFICATION_SUCCESS:
    case types.BILLING_SEND_TO_INVESTIGATION_SUCCESS:
    case types.BILLING_SEND_TO_REVIEW_SUCCESS:
    case types.BILLING_SEND_TO_REVIEW_BULK_SUCCESS:
    case types.BILLING_SEND_TO_NETSUITE_SUCCESS:
    case types.BILLING_RE_SEND_TO_VERIFICATION_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.BILLING_FETCH_THIS_MONTH_ITEMS:
      return initialState.items

    case types.BILLING_FETCH_THIS_MONTH_ITEMS_SUCCESS:
    case types.BILLING_SEND_TO_VERIFICATION_SUCCESS:
    case types.BILLING_SEND_TO_INVESTIGATION_SUCCESS:
    case types.BILLING_SEND_TO_REVIEW_SUCCESS:
    case types.BILLING_SEND_TO_REVIEW_BULK_SUCCESS:
    case types.BILLING_SEND_TO_NETSUITE_SUCCESS:
    case types.BILLING_RE_SEND_TO_VERIFICATION_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.BILLING_FETCH_ACCRUALS_EXCEL:
    case types.BILLING_FETCH_THIS_MONTH_ITEMS:
      return { ...state, error: '', loading: true }

    case types.BILLING_FETCH_ACCRUALS_EXCEL_ERROR:
    case types.BILLING_FETCH_THIS_MONTH_ITEMS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_FETCH_ACCRUALS_EXCEL_SUCCESS:
    case types.BILLING_FETCH_THIS_MONTH_ITEMS_SUCCESS:
      return { ...state, error: '', loading: false, success: true }

    default:
      return state
  }
}

function reCalculateSavingsMeta(
  state = initialState.reCalculateSavingsMeta,
  action,
) {
  switch (action.type) {
    case types.BILLING_RE_CALCULATE_SAVINGS:
      return { ...state, error: '', loading: true }

    case types.BILLING_RE_CALCULATE_SAVINGS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_RE_CALCULATE_SAVINGS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function sendToVerificationMeta(
  state = initialState.sendToVerificationMeta,
  action,
) {
  switch (action.type) {
    case types.BILLING_SEND_TO_VERIFICATION:
      return { ...state, error: '', loading: true }

    case types.BILLING_SEND_TO_VERIFICATION_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_SEND_TO_VERIFICATION_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function sendToInvestigationMeta(
  state = initialState.sendToInvestigationMeta,
  action,
) {
  switch (action.type) {
    case types.BILLING_SEND_TO_INVESTIGATION:
      return { ...state, error: '', loading: true }

    case types.BILLING_SEND_TO_INVESTIGATION_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_SEND_TO_INVESTIGATION_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function sendToReviewMeta(state = initialState.sendToReviewMeta, action) {
  switch (action.type) {
    case types.BILLING_SEND_TO_REVIEW:
      return { ...state, error: '', loading: true }

    case types.BILLING_SEND_TO_REVIEW_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_SEND_TO_REVIEW_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function sendToReviewBulkMeta(
  state = initialState.sendToReviewBulkMeta,
  action,
) {
  switch (action.type) {
    case types.BILLING_SEND_TO_REVIEW_BULK:
      return { ...state, error: '', loading: true }

    case types.BILLING_SEND_TO_REVIEW_BULK_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_SEND_TO_REVIEW_BULK_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function sendToNetsuiteMeta(state = initialState.sendToNetsuiteMeta, action) {
  switch (action.type) {
    case types.BILLING_SEND_TO_NETSUITE:
      return { ...state, error: '', loading: true }

    case types.BILLING_SEND_TO_NETSUITE_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_SEND_TO_NETSUITE_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function fetchReasonsMeta(state = initialState.fetchReasonsMeta, action) {
  switch (action.type) {
    case types.BILLING_FETCH_REASONS:
      return { ...state, error: '', loading: true }

    case types.BILLING_FETCH_REASONS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_FETCH_REASONS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function resendToVerificationMeta(
  state = initialState.resendToVerificationMeta,
  action,
) {
  switch (action.type) {
    case types.BILLING_RE_SEND_TO_VERIFICATION:
      return { ...state, error: '', loading: true }

    case types.BILLING_RE_SEND_TO_VERIFICATION_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.BILLING_RE_SEND_TO_VERIFICATION_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function reasons(state = initialState.reasons, action) {
  switch (action.type) {
    case types.BILLING_FETCH_REASONS:
      return initialState.reasons

    case types.BILLING_FETCH_REASONS_SUCCESS:
      return action.payload

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  reasons,
  reCalculateSavingsMeta,
  sendToVerificationMeta,
  sendToInvestigationMeta,
  sendToReviewMeta,
  sendToReviewBulkMeta,
  sendToNetsuiteMeta,
  resendToVerificationMeta,
  fetchReasonsMeta,
})
export const selectBillingThisMonthItemsEntity = (
  state: BillingEntitiesState,
): FTBillingItemsEntityState => state.entities.billingThisMonthItems

const selectBillingThisMonthItem = (
  state: BillingEntitiesState,
  id: string,
): FTBillingItem => state.entities.billingThisMonthItems.byId[id]

const selectBillingThisMonthItems = (
  state: BillingEntitiesState,
  ids: Array<string>,
): Array<FTBillingItem> =>
  ids.map((id) => state.entities.billingThisMonthItems.byId[id])

const selectBillingThisMonthOpportunity = (
  state: BillingEntitiesState,
  opportunitiesIds: Array<string>,
): Array<FTBillingItem> =>
  state.entities.billingThisMonthItems.items.filter((item) =>
    opportunitiesIds.includes(item.opportunityId),
  )

const utils = {
  enhanceBillingItem: (billingEntry: FTBillingItemResponse): FTBillingItem => {
    // If at some point service provides locale field we just need to swap 'es_US' with billingItem.locale
    const locale = 'es_US'.replace('_', '-')
    const numberFormatMinFractionDigits2 =
      getNumberFormatMinFractionDigits2(locale)
    const numberFormatMaxFractionDigits2 =
      getNumberFormatMaxFractionDigits2(locale)
    const billingItem = {
      ...billingEntry,
      remainingQuantity:
        billingEntry.salesOrderTotalQuantity -
        billingEntry.salesOrderBilledQuantity,
    }
    // calculated before returning the formatted value to reuse the raw value
    return {
      ...billingItem,
      calculatedActualFormatted: numberFormatMinFractionDigits2.format(
        billingItem?.calculatedActual,
      ),
      contractEstimateFormatted: numberFormatMaxFractionDigits2.format(
        billingItem?.contractEstimate,
      ),
      actualSavingsFormatted: numberFormatMinFractionDigits2.format(
        billingItem.calculatedActual,
      ),
      estimatedSavingsFormatted: numberFormatMinFractionDigits2.format(
        billingItem.contractEstimate,
      ),
      loaDateFormatted: moment(billingItem.loaDate).format(
        'MMM D, YYYY hh:mm:ss',
      ),
      codDateFormatted: moment(billingItem.codDate).format(
        'MMM D, YYYY hh:mm:ss',
      ),
      lastSavingsRunFormatted:
        billingItem.lastCalculationTimestamp ?
          moment(billingItem.lastCalculationTimestamp).format(
            'MMM D, YYYY hh:mm:ss A z',
          ) + moment.tz(billingItem.lastCalculationTimestamp).format('z')
        : 'Not Available',
      invoiceCreatedDateTimeFormatted:
        billingItem.invoiceCreatedDateTime ?
          moment(billingItem.invoiceCreatedDateTime).format(
            'MMM D, YYYY hh:mm:ss A ',
          ) + moment.tz(billingItem.invoiceTimezone).format('z')
        : 'Not Available',
      locale,
      invoiceCreationStatusFormatted:
        billingItem.invoiceCreationStatus ?
          billingItem.invoiceCreationStatus
        : '-',
      remainingQuantityFormatted: numberFormatMaxFractionDigits2.format(
        billingItem.remainingQuantity,
      ),
    }
  },
}
const baseUrl = `${consoleApiUrl()}/billing/variable-bills`
export const API = {
  fetchBillingItems: (params: FTFetchBillingItemsAction) => {
    if (isVariantActive('3587mock')) {
      return Promise.resolve(billingItemsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const queryParams = { ...params }
    const query = queryStringify(queryParams)
    const url = query ? `${baseUrl}?${query}` : baseUrl
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTBillingItemResponse }) => data)
      .catch(handleAxiosError)
  },
  reCalculateSavings: (params: FTReCalculateSavingsAction) => {
    if (isVariantActive('3587mock')) {
      return Promise.resolve(billingItemsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleApiUrl()}/billing/savings-orc/manual`
    return axios
      .patch(url, params, {
        headers: defaultHeaders(),
      })
      .then(
        ({ data }: { data: FTReCalculateSavingsStatusUpdateResponse }) => data,
      )
      .catch(handleAxiosError)
  },
  sendToVerification: ({ billId, comment }: FTSendToVerificationAction) => {
    const url = `${baseUrl}/resolve`
    const variableBillIds = billId
    const body: {
      issueOwnerId: string
      issueTypeId: string
      comment: string
      variableBillIds: Array<string>
    } = {
      issueOwnerId: '',
      issueOwners: null,
      issueTypeId: '',
      comment,
      variableBillIds,
    }
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  sendToInvestigation: ({
    billIds,
    issueOwnerId,
    issueOwners,
    issueTypeId,
    comment,
  }: FTSendToInvestigationAction) => {
    const url = `${baseUrl}/investigate`
    const body: {
      issueOwnerId: string
      issueOwners: string
      issueTypeId: string
      comment: string
      variableBillIds: Array<string>
    } = {
      issueOwnerId,
      issueOwners,
      issueTypeId,
      comment,
      variableBillIds: billIds,
    }
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  sendToReview: ({
    billId,
    selectedAction,
    comment = '',
    reasons = [],
  }: FTSendToReviewAction) => {
    const url = `${baseUrl}/review/${billId}`
    const body: {
      id: string
      selectedAction: FTBillingItemAction
      reasons: Array<FTBillingItemReason>
      comment: string
    } = {
      id: billId,
      selectedAction,
      reasons,
      comment,
    }
    return axios
      .patch(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  sendToReviewBulk: (billingItems: Array<FTSendToReviewBulkAction>) => {
    const url = `${baseUrl}/review`
    return axios
      .patch(url, billingItems, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  sendToNetsuite: ({
    savingsMonth,
    opportunityList,
  }: FTSendToNetsuiteAction) => {
    const url = `${baseUrl}/approve?savingsMonth=${savingsMonth}`
    const body: {
      opportunityList: Array<string>
    } = {
      opportunityList,
    }
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  resendToVerification: ({
    savingsMonth,
    comment,
    opportunityList,
  }: FTResendToVerificationAction) => {
    const url = `${baseUrl}/return?savingsMonth=${savingsMonth}`
    const body: {
      comment: string
      opportunityList: Array<string>
    } = {
      comment,
      opportunityList,
    }
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchReasons: () => {
    const url = `${baseUrl}/reasons`

    if (isVariantActive('3587mock')) {
      return Promise.resolve(billingReasonsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTFetchReasonsResponse }) => data)
      .catch(handleAxiosError)
  },
  fetchBillingAccrualsExcel: () => {
    const url = `${consoleApiUrl()}/billing/accruals/revenue-excel`

    if (isVariantActive('3587mock')) {
      return Promise.resolve(billingReasonsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    return axios
      .get(url, {
        headers: { ...defaultHeaders() },
        responseType: 'blob',
      })
      .then(({ data }: { data: FTFetchBillingAccrualsExcelResponse }) => {
        const blob = new Blob([data])
        const fileName = `${moment()
          .subtract(1, 'month')
          .format('MMMM')}-accruals-revenue.xlsx`
        FileSaver.saveAs(blob, fileName)
      })
      .catch(async (error) => {
        const response = await error.response.data.text()
        throw new Error(JSON.parse(response).messages[0])
      })
  },
}

function* fetchBillingItemsSaga({
  type,
  ...params
}: {
  type: string
} & FTFetchBillingItemsAction): Generator<any, void, any> {
  try {
    const response: FTBillingItemsResponse = yield call(
      API.fetchBillingItems,
      params,
    )
    const normalized =
      response.length > 0 ?
        normalize(response.map(utils.enhanceBillingItem), [entitySchema])
      : normalize([], [entitySchema])
    yield put({
      type: types.BILLING_FETCH_THIS_MONTH_ITEMS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_FETCH_THIS_MONTH_ITEMS_ERROR, e)
  }
}

function* reCalculateSavingsSaga({
  type,
  ...params
}: FTReCalculateSavingsAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const payload: FTReCalculateSavingsStatusUpdateResponse = yield call(
      API.reCalculateSavings,
      params,
    )
    yield put({
      type: types.BILLING_RE_CALCULATE_SAVINGS_SUCCESS,
      ...payload,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_RE_CALCULATE_SAVINGS_ERROR, e)
  }
}

function* sendToVerificationSaga({
  type,
  ...params
}: FTSendToVerificationAction & {
  type: string
}): Generator<any, void, any> {
  try {
    let billingItemEnhanced

    if (isVariantActive('3587mock')) {
      const billingItem = yield select(
        selectBillingThisMonthItem,
        params.billId,
      )
      billingItemEnhanced = {
        ...billingItem,
        pendingInvestigation: false,
        suggestedAction: billingItem.dataCapture <= 60 ? 'ESTIMATE' : 'ACTUAL',
      }
    } else {
      billingItemEnhanced = yield call(API.sendToVerification, params)
    }

    const normalized = normalize(
      billingItemEnhanced.map(utils.enhanceBillingItem),
      [entitySchema],
    )
    yield put({
      type: types.BILLING_SEND_TO_VERIFICATION_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_SEND_TO_VERIFICATION_ERROR, e)
  }
}

function* sendToInvestigationSaga({
  type,
  ...params
}: FTSendToInvestigationAction & {
  type: string
}): Generator<any, void, any> {
  try {
    let billingItemEnhanced

    if (isVariantActive('3587mock')) {
      const billingItem = yield select(
        selectBillingThisMonthItem,
        params.billIds,
      )
      billingItemEnhanced = {
        ...billingItem,
        pendingInvestigation: true,
        suggestedAction: 'ESTIMATE',
      }
    } else {
      billingItemEnhanced = yield call(API.sendToInvestigation, params)
    }

    const normalized = normalize(
      [billingItemEnhanced].map(utils.enhanceBillingItem),
      [entitySchema],
    )
    yield put({
      type: types.BILLING_SEND_TO_INVESTIGATION_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_SEND_TO_INVESTIGATION_ERROR, e)
  }
}

function* sendToReviewSaga({
  type,
  ...params
}: FTSendToReviewAction & {
  type: string
}): Generator<any, void, any> {
  try {
    let billingItemEnhanced

    if (isVariantActive('3587mock')) {
      const billingItem = yield select(
        selectBillingThisMonthItem,
        params.billId,
      )
      billingItemEnhanced = {
        ...billingItem,
        reviewedBy: 'susan.fake@redaptiveinc.com',
        reviewed: new Date(Date.now()).toISOString(),
        selectedAction: params.selectedAction,
      }
    } else {
      billingItemEnhanced = yield call(API.sendToReview, params)
    }

    const normalized = normalize(
      [billingItemEnhanced].map(utils.enhanceBillingItem),
      [entitySchema],
    )
    yield put({
      type: types.BILLING_SEND_TO_REVIEW_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_SEND_TO_REVIEW_ERROR, e)
  }
}

function* sendToReviewBulkSaga({
  billingItems,
}: {
  billingItems: Array<FTSendToReviewBulkAction>
}): Generator<any, void, any> {
  try {
    let billingItemsEnhanced
    const billIds = billingItems.map((item) => item.id)

    if (isVariantActive('3587mock')) {
      const billingItemsToUpdate = yield select(
        selectBillingThisMonthItems,
        billIds,
      )
      billingItemsEnhanced = billingItemsToUpdate.map((item) => ({
        ...item,
        reviewedBy: 'susan.fake@redaptiveinc.com',
        reviewed: new Date(Date.now()).toISOString(),
        selectedAction: item.selectedAction,
      }))
    } else {
      billingItemsEnhanced = yield call(API.sendToReviewBulk, billingItems)
    }

    const normalized = normalize(
      billingItemsEnhanced.map(utils.enhanceBillingItem),
      [entitySchema],
    )
    yield put({
      type: types.BILLING_SEND_TO_REVIEW_BULK_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_SEND_TO_REVIEW_BULK_ERROR, e)
  }
}

function* sendToNetsuiteSaga({
  savingsMonth,
  customerId,
  opportunityList,
}: FTSendToNetsuiteAction): Generator<any, void, any> {
  try {
    let billingItemsEnhanced

    if (isVariantActive('3587mock')) {
      const billingItemsToUpdate = yield select(
        selectBillingThisMonthOpportunity,
        opportunityList,
      )
      billingItemsEnhanced = billingItemsToUpdate.map((item) => ({
        ...item,
        approvedBy: 'susan.fake@redaptiveinc.com',
        approved: new Date(Date.now()).toISOString(),
      }))
    } else {
      billingItemsEnhanced = yield call(API.sendToNetsuite, {
        savingsMonth,
        customerId,
        opportunityList,
      })
    }

    const normalized = normalize(
      billingItemsEnhanced.map(utils.enhanceBillingItem),
      [entitySchema],
    )
    yield put({
      type: types.BILLING_SEND_TO_NETSUITE_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_SEND_TO_NETSUITE_ERROR, e)
  }
}

function* resendToVerificationSaga({
  savingsMonth,
  comment,
  opportunityList,
}: FTResendToVerificationAction): Generator<any, void, any> {
  try {
    let billingItemsEnhanced

    if (isVariantActive('3587mock')) {
      const billingItem = yield select(
        selectBillingThisMonthOpportunity,
        opportunityList,
      )
      billingItemsEnhanced = billingItem.map((item) => ({
        ...item,
        reviewedBy: null,
        reviewed: null,
      }))
    } else {
      billingItemsEnhanced = yield call(API.resendToVerification, {
        savingsMonth,
        comment,
        opportunityList,
      })
    }

    const normalized = normalize(
      billingItemsEnhanced.map(utils.enhanceBillingItem),
      [entitySchema],
    )
    yield put({
      type: types.BILLING_RE_SEND_TO_VERIFICATION_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_RE_SEND_TO_VERIFICATION_ERROR, e)
  }
}

function* fetchReasonsSaga(): Generator<any, void, any> {
  try {
    const response: FTFetchReasonsResponse = yield call(API.fetchReasons)
    yield put({
      type: types.BILLING_FETCH_REASONS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_FETCH_REASONS_ERROR, e)
  }
}

function* fetchBillingAccrualsSaga(): Generator<any, void, any> {
  try {
    const response: FTFetchReasonsResponse = yield call(
      API.fetchBillingAccrualsExcel,
    )
    yield put({
      type: types.BILLING_FETCH_ACCRUALS_EXCEL_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.BILLING_FETCH_ACCRUALS_EXCEL_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.BILLING_FETCH_THIS_MONTH_ITEMS, fetchBillingItemsSaga),
  takeLatest(types.BILLING_FETCH_REASONS, fetchReasonsSaga),
  takeEvery(types.BILLING_RE_CALCULATE_SAVINGS, reCalculateSavingsSaga),
  takeEvery(types.BILLING_SEND_TO_VERIFICATION, sendToVerificationSaga),
  takeEvery(types.BILLING_SEND_TO_INVESTIGATION, sendToInvestigationSaga),
  takeEvery(types.BILLING_SEND_TO_REVIEW, sendToReviewSaga),
  takeEvery(types.BILLING_SEND_TO_REVIEW_BULK, sendToReviewBulkSaga),
  takeEvery(types.BILLING_SEND_TO_NETSUITE, sendToNetsuiteSaga),
  takeEvery(types.BILLING_RE_SEND_TO_VERIFICATION, resendToVerificationSaga),
  takeEvery(types.BILLING_FETCH_ACCRUALS_EXCEL, fetchBillingAccrualsSaga),
]
