/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { bindActionCreators } from 'redux'
import { CSVLink } from 'react-csv'
import features from '../../../authorization/features'
import FeatureValidator from '../../../authorization/components/FeatureValidator'
import FormSection from '../../../components/FormSection'
import Button from '../../../components/Button'
import Spinner from '../../../components/Spinner'
import ArrowUpwardIcon from '../../../components/Icons/ArrowUpwardIcon'
import SuccessArrowIcon from '../../../components/Icons/SuccessArrowIcon'
import {
  ArrowUpwardIconStyles,
  Col1Styles,
  DropzoneStyles,
  ErrorMessagesHeadingStyles,
  ErrorMessagesListHeadingStyles,
  ErrorMessagesListStyles,
  FilePathStyled,
  FormSectionInnerStyles,
  ReportProblemIconStyled,
  TryAgainStyles,
  UploadAssetRecordsListStyled,
} from '../LightingAsBuiltsManagerPage'
import {
  actions as uploadUtilityDataActions,
  selectUtilityUsageDataEntity,
} from '../../../ducks/utilityData/uploadUtilityUsageData'
import sampleUtilityData from '../../../mockData/utilityData/sampleUtilityFile'
import {
  ButtonsStyles,
  SuccessArrowIconStyles,
  UploadSuccessStyles,
} from './UploadModal'
import usePrevious from '../../../hooks/usePrevious'

const UtilityComponent = (props) => {
  const { actions, error, utilityDataUploadResponse, isUploading, onClose } =
    props
  const [utilityFilename, setUtilityFilename] = useState('')
  const prevValue = usePrevious({
    isUploading,
  })
  const downloadSampleUtilityFileRef = React.useRef()
  const downloadUtilityDataRef = React.useRef()

  const downloadSampleUtilityFile = () =>
    downloadSampleUtilityFileRef.current.link.click()

  const downloadUtilityResponseData = () =>
    downloadUtilityDataRef.current.link.click()

  const resetUtilityForm = () => {
    setUtilityFilename('')
    actions.resetUtilityDataState()
  }

  const handleUtilityData = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setUtilityFilename(file.name)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {
    acceptedFiles: acceptedFilesUtility,
    getInputProps: getInputPropsUtility,
    getRootProps: getRootPropsUtility,
    isDragAccept: isDragAcceptUtility,
    isDragActive: isDragActiveUtility,
    isDragReject: isDragRejectUtility,
    open: openUtility,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleUtilityData,
  })
  useEffect(() => {
    if (prevValue && prevValue.isUploading && !isUploading && !error) {
      onClose({
        refetchList: true,
      })
    }
  }, [prevValue])
  return (
    <FeatureValidator feature={features.uploadUtilityData}>
      <Col1Styles>
        <FormSection>
          <FormSectionInnerStyles>
            {!!error && (
              <>
                <ErrorMessagesHeadingStyles>
                  <ReportProblemIconStyled color='#C70D08' inline />
                  <b>
                    {utilityFilename}
                    {' upload failed.'}
                  </b>
                </ErrorMessagesHeadingStyles>
                <ErrorMessagesListHeadingStyles>
                  No records were stored. Please fix the following errors and
                  try again:
                </ErrorMessagesListHeadingStyles>
                <ErrorMessagesListStyles>
                  <div>{error}</div>
                </ErrorMessagesListStyles>
                <TryAgainStyles>
                  <Button onClick={resetUtilityForm} primary>
                    Reset
                  </Button>
                </TryAgainStyles>
              </>
            )}
            {!error && (
              <>
                <b>Upload Utility Data</b>
                <UploadAssetRecordsListStyled>
                  <li>Files with customer, site, utility and invoice data</li>
                  <li>
                    Download the sample file
                    <Link to='#' onClick={downloadSampleUtilityFile}>
                      {' '}
                      here
                    </Link>
                  </li>
                  <CSVLink
                    data={sampleUtilityData}
                    ref={downloadSampleUtilityFileRef}
                    filename='new-basic-upload-all-details.csv'
                  />
                  <li>The upload file must be a .csv.</li>
                  <li>
                    Any rows with missing required fields will be skipped.
                  </li>
                  <li>
                    DO NOT CLOSE THIS WINDOW while upload is in progress,
                    otherwise you will lose access to the upload status.
                  </li>
                </UploadAssetRecordsListStyled>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <DropzoneStyles
                  {...getRootPropsUtility({
                    isDragActiveUtility,
                    isDragAcceptUtility,
                    isDragRejectUtility,
                  })}
                >
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <input name='file' {...getInputPropsUtility()} />
                  {isUploading && (
                    <div>
                      <Spinner inline size='micro' />{' '}
                      <b>Upload in progress...</b>
                    </div>
                  )}
                  {!isUploading && (
                    <ArrowUpwardIconStyles>
                      <ArrowUpwardIcon />
                    </ArrowUpwardIconStyles>
                  )}
                  <FilePathStyled>
                    {utilityFilename || 'Drag file to upload'}
                  </FilePathStyled>
                  {!!utilityDataUploadResponse && !isUploading && (
                    <UploadSuccessStyles>
                      <SuccessArrowIconStyles>
                        <SuccessArrowIcon />
                        <b>Upload Complete</b>
                      </SuccessArrowIconStyles>
                      <ButtonsStyles>
                        <Button primary onClick={resetUtilityForm}>
                          Upload another file
                        </Button>
                        <Button primary onClick={downloadUtilityResponseData}>
                          Download Result
                        </Button>
                        <CSVLink
                          data={utilityDataUploadResponse}
                          ref={downloadUtilityDataRef}
                          filename={`${
                            utilityFilename.split('.')[0]
                          }-result.csv`}
                        />
                      </ButtonsStyles>
                    </UploadSuccessStyles>
                  )}
                  {!utilityFilename && (
                    <Button primary onClick={openUtility}>
                      Choose File
                    </Button>
                  )}
                  {utilityFilename &&
                    !isUploading &&
                    !utilityDataUploadResponse && (
                      <ButtonsStyles>
                        <Button onClick={resetUtilityForm}>Cancel</Button>
                        <Button
                          primary
                          onClick={() => {
                            actions.postUtilityData({
                              file: acceptedFilesUtility[0],
                            })
                          }}
                        >
                          Upload
                        </Button>
                      </ButtonsStyles>
                    )}
                </DropzoneStyles>
              </>
            )}
          </FormSectionInnerStyles>
        </FormSection>
      </Col1Styles>
    </FeatureValidator>
  )
}

const mapStateToProps = (state) => {
  const entity = selectUtilityUsageDataEntity(state)
  const { utilityData } = entity
  const {
    uploadResponse: utilityDataUploadResponse,
    meta: { error, isUploading },
  } = utilityData
  return {
    utilityDataUploadResponse,
    isUploading,
    error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(uploadUtilityDataActions, dispatch) },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UtilityComponent),
)
