import { PureComponent } from 'react'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import type { FTResourceEntity, FTUserEntity } from '../ducks/authzPlayground'
import { actions as authzActions } from '../ducks/authzPlayground'
import Button from '../components/Button'

type FTProps = {
  email: string
  user: FTUserEntity
  inspect: FTResourceEntity
  create: FTResourceEntity
  partialUpdate: FTResourceEntity
  fullUpdate: FTResourceEntity
  delete: FTResourceEntity
  actions: {
    inspectResource: (...args: Array<any>) => any
    createResource: (...args: Array<any>) => any
    partialUpdateResource: (...args: Array<any>) => any
    fullUpdateResource: (...args: Array<any>) => any
    deleteResource: (...args: Array<any>) => any
    fetchUser: (...args: Array<any>) => any
    addUserGroup: (...args: Array<any>) => any
    deleteUserGroup: (...args: Array<any>) => any
  }
}
const Title = styled.div`
  font-size: 20px;
  padding-bottom: 16px;
  font-weight: 600;
`
const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  max-width: 700px;
  & b {
    font-weight: 600;
  }

  & > div {
    margin-top: 5px;
  }
`
const Section = styled.div`
  margin: 10px 0 20px 0;
`
const Response = styled.div`
  margin: 10px 0px;
  height: 25px;
`
const ErrorMessage = styled.div`
  color: #c70d08;
`
const Styles = styled.div`
  height: 100%;
  user-select: none;
  margin-left: 60px !important;
  margin-right: 60px !important;
`
const GROUP_NAME = 'gc-users'

class AuthorizationPlaygroundPage extends PureComponent<FTProps> {
  componentDidMount() {
    const { actions, email } = this.props
    actions.fetchUser({
      email,
    })
  }

  inspectResource = () => this.props.actions.inspectResource({})

  createResource = () =>
    this.props.actions.createResource({
      param: 'value',
    })

  partialUpdateResource = () =>
    this.props.actions.partialUpdateResource({
      param: 'value',
    })

  fullUpdateResource = () =>
    this.props.actions.fullUpdateResource({
      param: 'value',
    })

  deleteResource = () =>
    this.props.actions.deleteResource({
      param: 'value',
    })

  joinGroup = () => {
    const { user, actions } = this.props

    if (user) {
      actions.addUserGroup({
        userId: user.id,
        email: user.email,
        group: GROUP_NAME,
      })
    }
  }

  leaveGroup = () => {
    const { user, actions } = this.props

    if (user) {
      actions.deleteUserGroup({
        userId: user.id,
        email: user.email,
        group: GROUP_NAME,
      })
    }
  }

  render() {
    const {
      inspect,
      create,
      partialUpdate,
      fullUpdate,
      delete: deleteResource,
      user,
    } = this.props
    const userGroups = user.groups && user.groups.length > 0 ? user.groups : []
    const userInGroup = !!userGroups.find((g) => g === GROUP_NAME)
    const message =
      userInGroup ?
        <div>
          You are a member of the <b>{GROUP_NAME}</b> group
        </div>
      : <div>
          You are not a member of the <b>{GROUP_NAME}</b> group
        </div>
    const buttonText = userInGroup ? 'Leave Group' : 'Join Group'
    const groupAction = userInGroup ? this.leaveGroup : this.joinGroup
    return (
      <Styles>
        <Title>Authorization Playground</Title>
        <Description>
          <div>
            This page can be used to test Authorization Permissions against a
            fake API resource.
          </div>
          <div>
            Any user in the <b>{GROUP_NAME}</b> group has full admin permissions
            on this resource.
          </div>
          <div>
            This means a user in this group can view, create, update, and delete
            the resource
          </div>
          <div>
            On this page, none of the actions take any permanent effect, and the
            actions simply allow you to verify that you have permissions to
            perform them based on your group.
          </div>
          <div>
            You can use this page to add and remove yourself from the{' '}
            {GROUP_NAME} group to see the difference in permissions.
          </div>
        </Description>
        <Section>
          <b>Group Permissions</b>
          <ErrorMessage message={user.error} />
          <Response>
            {!user.error && !user.loading && message}
            {user.error && <ErrorMessage>{user.error}</ErrorMessage>}
          </Response>
          <Button primary onClick={groupAction} disabled={user.loading}>
            {buttonText}
          </Button>
        </Section>

        <Section>
          <b>View Resource</b>
          <Response>
            {!inspect.error &&
              inspect.role &&
              'You have view permissions on this resource.'}
            {inspect.error && <ErrorMessage>{inspect.error}</ErrorMessage>}
          </Response>
          <Button
            primary
            loading={inspect.loading}
            disabled={user.loading}
            onClick={this.inspectResource}
          >
            Inspect
          </Button>
        </Section>

        <Section>
          <b>Create Resource</b>
          <Response>
            {!create.error &&
              create.role &&
              'You have create permissions on this resource.'}
            {create.error && <ErrorMessage>{create.error}</ErrorMessage>}
          </Response>
          <Button
            primary
            loading={create.loading}
            disabled={user.loading}
            onClick={this.createResource}
          >
            Create
          </Button>
        </Section>

        <Section>
          <b>Partial Update Resource</b>
          <Response>
            {!partialUpdate.error &&
              partialUpdate.role &&
              'You have edit permissions on this resource.'}
            {partialUpdate.error && (
              <ErrorMessage>{partialUpdate.error}</ErrorMessage>
            )}
          </Response>
          <Button
            primary
            loading={partialUpdate.loading}
            disabled={user.loading}
            onClick={this.partialUpdateResource}
          >
            Partial Update
          </Button>
        </Section>

        <Section>
          <b>Full Update Resource</b>
          <Response>
            {!fullUpdate.error &&
              fullUpdate.role &&
              'You have edit permissions on this resource.'}
            {fullUpdate.error && (
              <ErrorMessage>{fullUpdate.error}</ErrorMessage>
            )}
          </Response>
          <Button
            primary
            loading={fullUpdate.loading}
            disabled={user.loading}
            onClick={this.fullUpdateResource}
          >
            Full Update
          </Button>
        </Section>

        <Section>
          <b>Delete Resource</b>
          <Response>
            {!deleteResource.error &&
              deleteResource.role &&
              'You have delete permissions on this resource.'}
            {deleteResource.error && (
              <ErrorMessage>{deleteResource.error}</ErrorMessage>
            )}
          </Response>
          <Button
            primary
            loading={deleteResource.loading}
            disabled={user.loading}
            onClick={this.deleteResource}
          >
            Delete
          </Button>
        </Section>
      </Styles>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authzActions, dispatch),
})

const mapStateToProps = (state) => {
  const { authzPlayground } = state
  return { ...authzPlayground, email: state.user.username }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorizationPlaygroundPage)
