import axios from 'axios'
import { takeEvery, put, call } from 'redux-saga/effects'
import { handleSagaError } from '../sagas/utils'
import { handleAxiosError, queryStringify } from '../api/utils'
import { consoleApiUrl, defaultHeaders } from '../api'

const types = {
  FETCH_BUILDING_EQUIPMENTS: 'FETCH_BUILDING_EQUIPMENTS',
  FETCH_BUILDING_EQUIPMENTS_SUCCESS: 'FETCH_BUILDING_EQUIPMENTS_SUCCESS',
  FETCH_BUILDING_EQUIPMENTS_ERROR: 'FETCH_BUILDING_EQUIPMENTS_ERROR',
}

const initialState = {
  loading: false,
  data: {},
  error: '',
}

export const actions = {
  fetchBuildingEquipments: (params) => ({
    type: types.FETCH_BUILDING_EQUIPMENTS,
    params,
  }),
}

class API {
  static fetchBuildingEquipments(params: {
    circuitDescription: string
    customerId: string
  }) {
    const query = queryStringify(params)
    const url = `${consoleApiUrl()}/circuits/classify?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* fetchBuildingTypesSaga({
  params,
}: {
  params: Record<string, string>
}): Generator<any, void, any> {
  try {
    const { circuitDescription, customerId } = params
    const res = yield call(API.fetchBuildingEquipments, params)
    const key = `${customerId}_${circuitDescription.toLowerCase()}`
    const payload = {
      [key]: res[0],
    }
    yield put({
      type: types.FETCH_BUILDING_EQUIPMENTS_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_BUILDING_EQUIPMENTS_ERROR, error)
  }
}

// Selectors
export const selectBuildingEquipments = (state) =>
  state.entities.buildingEquipments

// Reducers
function buildingEquipmentsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_BUILDING_EQUIPMENTS:
      return { ...state, error: '', loading: true }

    case types.FETCH_BUILDING_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        error: '',
        loading: false,
      }

    case types.FETCH_BUILDING_EQUIPMENTS_ERROR:
      return { ...state, error: action.error, loading: false }

    default:
      return state
  }
}

export const sagas = [
  takeEvery(types.FETCH_BUILDING_EQUIPMENTS, fetchBuildingTypesSaga),
]

export default buildingEquipmentsReducer
