import styled from 'styled-components'
import debounce from 'debounce'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { capitalCase } from 'capital-case'
import { PureComponent } from 'react'
import {
  getUserSummaryListEntity,
  actions as userSummaryActions,
} from '../../ducks/userSummaries'
import {
  selectCustomerListEntity,
  actions as customerActions,
} from '../../ducks/customers'
import { selectSiteListEntity, actions as siteActions } from '../../ducks/sites'
import {
  selectMeterListEntity,
  actions as meterActions,
} from '../../ducks/meters'
import {
  selectUnassignedListEntity,
  actions as unassignedActions,
} from '../../ducks/unassignedMeters'
import {
  actions as meterStatusActions,
  selectRTMeterById,
} from '../../ducks/meterStatus'
import { selectors as authSelectors } from '../../ducks/auth'
import features from '../../authorization/features'

import { FTRouterLocation } from '../../types'
import DeprecatedEntityListPane from '../../components/DeprecatedEntityListPane'
import EntityListPane from '../../components/EntityList'
import TabPane from '../../components/Tabs/TabPane'
import ActionPaneView from '../../components/ActionPaneView'
import Title from '../../components/Title'
import IconButton from '../../components/IconButton'
import IonIcon from '../../components/IonIcon'
import SiteTable from '../../components/AllSitesTable'
import UsersTable from '../../components/UsersTable'
import FeatureValidator from '../../authorization/components/FeatureValidator'
import Input from '../../components/Input'
import type { FTFormFieldEvent } from '../../types'
import { normalizeMACAddress } from '../../utils'
import { METER_RESOURCE_TYPES } from '../../ducks/meters/generation'
import StatusIconItem from '../../components/StatusIconItem'
import { renderTimestamp } from '../../ducks/utils'

const Component = styled.div``
const Description = styled.div`
  line-height: 36px;
`
const NoUnassignedMessage = styled.div`
  & .IonIcon {
    margin-right: 21px;
    color: #7bcc76;
    font-size: 20px;
  }

  & .IonIcon > span {
    top: 2px;
  }
`
const UnassignedHeaderStyles = styled.div`
  min-height: 44px;
  display: flex;
  justify-content: space-between;
`
const MeterTypeTdStyles = styled.td`
  min-width: 84px;
`
const MeterIdentifierTdStyles = styled.td`
  min-width: 110px;
`
export const SearchStyles = styled.div`
  width: 400px;
  input {
    width: 400px;
    border: 1px solid #c7c7c7;
    border-radius: 3px;
    background-color: #ffffff;
    color: #4a4a4a;
    font: 14px 'Avenir Next';
    line-height: 19px;
    padding: 9px 11px 8px 11px;
    visibility: ${({ hidden }) => hidden && 'hidden'};
  }
`
type FTProps = FTRouterLocation
type FTState = {
  search: string
  meterStatusFetched: boolean
}

class AccountManagementPage extends PureComponent<FTProps, FTState> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      search: '',
      meterStatusFetched: false,
    }
  }

  unassignedTableFetchProps = {
    orderBy: {
      field: 'firstReportDate',
      sort: 'ASC',
    },
    pageSize: 10 ** 4,
  }

  customerTableFetchProps = {
    orderBy: {
      field: 'name',
      sort: 'ASC',
    },
    pageSize: 10 ** 4,
  }

  customerTableHeaders = [
    {
      fieldName: 'name',
      displayName: 'Customer Name',
    },
    {
      fieldName: 'displayedSitesRatio',
      displayName: 'Sites in Dashboard / Total Sites',
      sortable: false,
    },
    {
      fieldName: 'activeUsersRatio',
      displayName: 'Active Users / Total Users',
      sortable: false,
    },
    {
      fieldName: 'activeMetersRatio',
      displayName: 'Active Meters / Total Meters',
      sortable: false,
    },
  ]

  customerTableDescription =
    'The following customers are available to the Energy Dashboard. A customer should have at ' +
    'least one site displayed in the dashboard before being considered ready to view.'

  componentDidMount() {
    const { permissions, actions } = this.props
    actions.fetchAllCustomers({
      pageSize: 100,
      orderBy: {
        field: 'name',
        sort: 'ASC',
      },
    })
    actions.fetchMeterList({
      orderBy: {
        field: 'customerName',
        sort: 'ASC',
      },
      filterBy: {
        field: 'resource',
        values: METER_RESOURCE_TYPES,
      },
    })

    if (features.inspectMeters.allMatchWithPermissions(permissions)) {
      actions.fetchUnassignedMeterList(this.unassignedTableFetchProps)
    }
  }

  componentDidUpdate() {
    const { meterListEntity, actions } = this.props
    const meterList = meterListEntity

    if (meterList.items && !this.state.meterStatusFetched) {
      const meterIds = Array.from(
        new Set(meterList.items.map((meter) => meter.name)),
      )
      if (meterIds && meterIds.length) {
        actions.getRealTimeMeterStatus({ ids: meterIds })
        this.setState({ meterStatusFetched: true })
      }
    }
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearUserSummaries()
    actions.resetRTForm()
  }

  handleUnassignedSearchKeyUp = (event: FTFormFieldEvent) => {
    this.setState((prev) => ({ ...prev, search: event.target.value }))
  }

  onSearchUnassigned = debounce(this.handleUnassignedSearchKeyUp, 500)

  assignMeters = () => {
    const { history } = this.props
    history.push(`${this.props.match.url}/meter-assignment`)
  }

  refreshUnassigned = () => {
    const {
      actions,
      unassignedListEntity: {
        meta: { pageSize, orderBy },
      },
    } = this.props
    actions.fetchUnassignedMeterList({
      pageSize,
      orderBy,
    })
  }

  renderUsers = () => {
    const description =
      'The following users have been loaded into the Energy Dashboard. Users are granted access to ' +
      'one or more customers. Select a user below to change their customer permissions or generate an invite link.'
    return (
      <UsersTable
        description={description}
        fetchListEntity={this.props.actions.fetchUserSummaries}
        listEntity={this.props.userSummaryListEntity}
      />
    )
  }

  onlineStatusCell = (value: boolean | 'loading') => {
    if (value === 'loading') return <div>Loading...</div>
    return <StatusIconItem status={value} />
  }

  lastMesurementDateCell = (value: string, tz: string) => {
    if (value === 'loading') return <div>Loading...</div>
    if (value.toLowerCase() === 'unavailable') return <div>Unavailable</div>
    const format = 'MMMM DD, YYYY hh:mm:ss A z'
    const date = renderTimestamp(value, tz, format)
    return date
  }

  resetMeterStatusAPIFlag = () => {
    this.setState({ meterStatusFetched: false })
  }

  renderMeters = () => (
    <EntityListPane
      entity={this.props.meterListEntity}
      showMetersFilter
      loadEntity={this.props.actions.fetchMeterList}
      flagResetters={[this.resetMeterStatusAPIFlag]}
      loadEntityProps={{ orderBy: { field: 'customerName', sort: 'ASC' } }}
      key='meters-tab-pane'
      striped
      filterParam='resource'
      hideAllPagination
      paginationSize={[50, 20]}
      tableHeaders={[
        { fieldName: 'name', displayName: 'Meter Identifier' },
        { fieldName: 'customerName', displayName: 'Customer' },
        { fieldName: 'siteName', displayName: 'Site Name' },
        { fieldName: 'panelName', displayName: 'Panel', sortable: false },
        {
          fieldName: 'lastMeasurementDate',
          displayName: 'Last Measurement Date',
          sortable: false,
        },
        {
          fieldName: 'onlineStatus',
          displayName: 'Online Status',
          sortable: false,
        },
        { fieldName: 'source', displayName: 'Meter Type' },
        { fieldName: 'resource', displayName: 'Resource Type' },
      ]}
      renderTableRow={({
        id,
        name,
        customerId,
        customerName,
        siteId,
        siteName,
        panelNames,
        source,
        resource,
        siteTimezone,
      }) => (
        <tr key={id}>
          <MeterIdentifierTdStyles>
            <div
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Link
                to={`${this.props.match.url}/meters/${id}`}
                href={`${this.props.match.url}/meters/${id}`}
              >
                {name}
              </Link>
            </div>
          </MeterIdentifierTdStyles>
          <td>
            <Link
              to={`${this.props.match.url}/customers/${customerId}`}
              href={`${this.props.match.url}/customers/${customerId}`}
            >
              <div
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {customerName}
              </div>
            </Link>
          </td>
          <td>
            <Link
              to={`${this.props.match.url}/sites/${siteId}`}
              href={`${this.props.match.url}/sites/${siteId}`}
            >
              {siteName}
            </Link>
          </td>
          <td>{panelNames && panelNames.length > 0 ? panelNames[0] : '-'}</td>
          <td>
            {this.lastMesurementDateCell(
              this.props.meterStatusById[id] ?
                this.props.meterStatusById[id].originalLastMeasurementDate
              : 'loading',
              siteTimezone,
            )}
          </td>
          <td>
            {this.onlineStatusCell(
              this.props.meterStatusById[id] ?
                this.props.meterStatusById[id].onlineStatus
              : 'loading',
            )}
          </td>
          <MeterTypeTdStyles>{source}</MeterTypeTdStyles>
          <td>{capitalCase(resource || 'Electricity')}</td>
        </tr>
      )}
    />
  )

  renderUnassignedMeters = () => {
    const {
      unassignedListEntity: {
        meta: { loading, error },
        items,
      },
    } = this.props
    const { search } = this.state

    let renderHeader = () => <div />

    let renderNoResults

    if (items.length > 0) {
      renderHeader = () => (
        <>
          <UnassignedHeaderStyles>
            <Description>
              The meters listed below are active but have not been assigned to a
              site.
            </Description>
            <div>
              <IconButton
                onClick={this.refreshUnassigned}
                iconClass='fas fa-sync-alt'
              >
                Look for new meters
              </IconButton>
              <FeatureValidator feature={features.createMeters}>
                <IconButton onClick={this.assignMeters} iconClass='fas fa-plus'>
                  Assign meters to a site
                </IconButton>
              </FeatureValidator>
            </div>
          </UnassignedHeaderStyles>
          <SearchStyles>
            <Input
              placeholder='Search'
              onKeyUp={(e) => {
                e.persist()
                this.onSearchUnassigned(e)
              }}
            />
          </SearchStyles>
        </>
      )
    } else if (!loading) {
      if (error) {
        renderNoResults = () => null
      } else {
        renderNoResults = () => (
          <NoUnassignedMessage>
            <IonIcon iconClass='ion-checkmark-circled' />
            {' There are no unassigned meters at this time.'}
          </NoUnassignedMessage>
        )
      }
    }

    const data = [...items].filter((row) => {
      const searchLower = search.toLowerCase()
      return (
        row.meterType.toLowerCase().includes(searchLower) ||
        normalizeMACAddress(row.name.toLowerCase()).includes(
          normalizeMACAddress(searchLower),
        )
      )
    })
    return (
      <DeprecatedEntityListPane
        striped
        description=''
        showPageSizer={false}
        renderHeader={renderHeader}
        entity={{ ...this.props.unassignedListEntity, items: data }}
        loadEntity={this.props.actions.fetchUnassignedMeterList}
        loadEntityProps={this.unassignedTableFetchProps}
        tableHeaders={[
          {
            fieldName: 'name',
            displayName: 'Meter Identifier',
          },
          {
            fieldName: 'source',
            displayName: 'Meter Type',
          },
          {
            fieldName: 'firstReportDate',
            displayName: 'First Reported',
          },
        ]}
        renderNoResults={renderNoResults}
        renderTableRow={({ id, name, meterType, firstReported }) => (
          <tr key={id}>
            <td>
              <div>
                <Link
                  to={`${this.props.match.url}/meters/${id}`}
                  href={`${this.props.match.url}/meters/${id}`}
                >
                  {name}
                </Link>
              </div>
            </td>
            <td>{meterType}</td>
            <td>{firstReported}</td>
          </tr>
        )}
      />
    )
  }

  renderCustomers = () => {
    const {
      customerListEntity,
      actions,
      match: { url },
    } = this.props

    const renderTableRow = ({ id, name }) => (
      <tr key={id}>
        <td>
          <Link to={`${url}/customers/${id}`} href={`${url}/customers/${id}`}>
            {name || '(Unassigned)'}
          </Link>
        </td>
        <td>Unavailable</td>
        <td>Unavailable</td>
        <td>Unavailable</td>
      </tr>
    )

    return (
      <EntityListPane
        description={this.customerTableDescription}
        entity={customerListEntity}
        key='customers-tab-pane'
        loadEntity={actions.fetchAllCustomers}
        loadEntityProps={this.customerTableFetchProps}
        renderTableRow={renderTableRow}
        striped
        tableHeaders={this.customerTableHeaders}
      />
    )
  }

  renderSites = () => (
    <SiteTable
      fetchAllSites={this.props.actions.fetchAllSites}
      siteListEntity={this.props.siteListEntity}
    />
  )

  renderMain = () => {
    const { permissions } = this.props
    let tabs = [
      {
        text: 'Manage Customers',
        tab: 'customers',
        render: this.renderCustomers,
      },
      {
        text: 'Manage Sites',
        tab: 'sites',
        render: this.renderSites,
      },
      {
        text: 'Manage Meters',
        tab: 'meters',
        render: this.renderMeters,
      },
      {
        text: 'Manage Users',
        tab: 'users',
        render: this.renderUsers,
      },
    ]

    if (features.inspectMeters.allMatchWithPermissions(permissions)) {
      tabs = [
        ...tabs,
        {
          text: 'Unassigned Meters',
          tab: 'unassigned',
          render: this.renderUnassignedMeters,
        },
      ]
    }

    return (
      <Component className='AccountManagementPage'>
        <Title>Welcome</Title>
        <TabPane tabs={tabs} />
      </Component>
    )
  }

  render() {
    const {
      match: { url },
      permissions,
    } = this.props
    const actionsConditional =
      features.createUsers.allMatchWithPermissions(permissions) ?
        [
          {
            href: `${url}/users/create`,
            label: 'Add User',
          },
        ]
      : []
    const actions = [
      {
        href: `${url}/customers/create`,
        label: 'Add Customer',
      },
      {
        href: `${url}/sites/create`,
        label: 'Add Site',
      },
      ...actionsConditional,
    ]
    return (
      <div className='AccountManagementPage'>
        <ActionPaneView renderMain={this.renderMain} actions={actions} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(userSummaryActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(meterActions, dispatch),
    ...bindActionCreators(unassignedActions, dispatch),
    ...bindActionCreators(meterStatusActions, dispatch),
  },
})

const mapStateToProps = (state) => ({
  userSummaryListEntity: getUserSummaryListEntity(state),
  customerListEntity: selectCustomerListEntity(state),
  siteListEntity: selectSiteListEntity(state),
  meterListEntity: selectMeterListEntity(state),
  unassignedListEntity: selectUnassignedListEntity(state),
  permissions: authSelectors.selectPermissions(state),
  meterStatusById: selectRTMeterById(state),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountManagementPage),
)
