import momentTz from 'moment-timezone'
import moment from 'moment'
import { normalize } from 'normalizr'
import type { FTSortable } from '../types'
import '../types'
import { getTotalPages, queryStringify } from '../api/utils'
import type { FTEntityMetaSubmission, FTFilterBy, FTSearchBy } from './types'

export const initialMeta = {
  pageNumber: 1,
  pageSize: 12,
  next: null,
  prev: null,
  orderBy: null,
  filterBy: null,
  loading: false,
  updateLoading: false,
}
export const initialEntityState = {
  byId: {},
  allIds: [],
  meta: initialMeta,
  kFactorHistory: {
    items: [],
    measurementMeta: {},
  },
  utilityMeterModels: {
    items: [],
  },
  stepDownTransformers: {
    items: [],
  },
}
export type FTEntityPayload = {
  byId: Record<string, any>
  allIds: Array<Record<string, any>>
  meta: {
    pageNumber: number
    pageSize: number
    next?: string | null | undefined
    previous?: string | null | undefined
    orderBy?: FTSortable
  }
  kFactorHistory?: {
    items: Array<Record<string, any>>
  }
  utilityMeterModels?: {
    items: Array<Record<string, any>>
  }
}
export type FTAction = {
  type: string
}
export const makeActionTypes = (
  action: string,
  prefix: string | null | undefined = '',
) => {
  if (!action) {
    throw new Error('Must provide an action type name')
  }

  return {
    [`${action}`]: `${prefix}${action}`,
    [`${action}_SUCCESS`]: `${prefix}${action}_SUCCESS`,
    [`${action}_ERROR`]: `${prefix}${action}_ERROR`,
  }
}
export const MIN_PAGE_SIZE = 20
export const MAX_PAGE_SIZE = 1000
export const getPageSizes = (
  total: number,
  hideAll?: boolean,
  limits?: Array<number>,
) => {
  if (total === 0) {
    return []
  }

  const choices = []

  if (total <= MAX_PAGE_SIZE && total > MIN_PAGE_SIZE && !hideAll) {
    choices.unshift({
      label: 'All',
      value: total,
    })
  }

  ;(limits ?? [MAX_PAGE_SIZE, 500, 100, 48, MIN_PAGE_SIZE]).forEach(
    (option) => {
      if (total > option) {
        choices.unshift({
          label: option,
          value: option,
        })
      }
    },
  )
  return choices
}
export const TIMEZONE_DEFAULT = 'America/Los_Angeles'
export const DATE_FORMAT_TIMESTAMP = 'MMMM DD, YYYY hh:mm:ss A z'
export const DATE_FORMAT_TOOLTIP = 'dddd, MMMM D YYYY, h:mm A z'
export const DATE_FORMAT_DATA_API_RESPONSE = 'YYYY-MM-DDTHH:mm:ssZ'
export const DATE_FORMAT_DATA_API_REQUEST = 'YYYY-MM-DDTHH:mm:ssZ'
export const FIELD_STATUS_DATE_FORMAT_REQUEST = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
export const DATE_FORMAT_SIMPLE = 'MMMM D, YYYY'
export const DATE_FORMAT_SIMPLE_WITH_TIME = 'D MMM h:mmA z'

/**
 * Gets a Moment from a timestamp.
 *
 * The BE returns dates using GMT. This function helps to get a moment object
 * that displays correctly for a given time zone.
 *
 * By default, the returned moment uses 'America/Los_Angeles'.
 *
 * @param {string} ts
 *   A UTC timestamp
 * @param {string} tz
 *   (Optional) A time zone. Default is 'America/Los_Angeles'.
 *
 * @return {moment.Moment | null}
 *   Returns a Moment object in the specified time zone or null if a moment
 *   could not be created from the given timestamp.
 */
export const parseTimestamp = (
  ts: string,
  tz: string | null | undefined = '',
): moment.Moment | null => {
  const m = momentTz(ts)

  if (!ts || !m.isValid()) {
    return null
  }

  const timezone = tz || TIMEZONE_DEFAULT
  return m.tz('UTC').tz(timezone)
}
export const renderTimestamp = (
  ts: string,
  tz: string | null | undefined = '',
  format: string | null | undefined = DATE_FORMAT_TIMESTAMP,
) => {
  const parsed = parseTimestamp(ts, tz)

  if (!parsed) {
    return 'Unavailable'
  }

  return parsed.format(format)
}
export const makeSagaPayload = (response, payloadSchema, resultsFunc) => {
  const {
    next,
    previous,
    totalPages,
    totalCount,
    pageNumber,
    pageSize,
    orderBy,
    filterBy,
    searchBy,
  } = response
  let { results } = response

  if (resultsFunc) {
    results = results.map(resultsFunc)
  }

  const normalized =
    results.length > 0 ?
      normalize(results, [payloadSchema])
    : {
        entities: {
          [payloadSchema._key]: {},
        },
        result: [],
      }
  return {
    ...normalized,
    meta: {
      pageNumber,
      pageSize,
      next,
      previous,
      totalPages,
      totalCount,
      orderBy,
      filterBy,
      searchBy,
    },
  }
}
export const orderByToString = (
  orderBy: FTSortable | null | undefined,
): string => {
  if (!orderBy || !orderBy.field) {
    return ''
  }

  const dir = orderBy.sort === 'DESC' ? '-' : ''
  const order = `${dir}${orderBy.field}`
  if (!orderBy.secondaryField) return order
  const secondaryDir = orderBy.secondarySort === 'DESC' ? '-' : ''
  return `${order},${secondaryDir}${orderBy.secondaryField}`
}

const getOptionalFilterBy = (filterBy: FTFilterBy | null | undefined = {}) => {
  const { field, values } = filterBy

  if (!field || !values) {
    return {
      empty: undefined,
    }
  }

  return {
    [field]: values,
  }
}

export const getOptionalSearchBy = (
  searchBy: FTSearchBy | null | undefined = {},
) => {
  const { field, term } = searchBy

  if (!field || !term) {
    return {
      empty: undefined,
    }
  }

  return {
    [field]: term,
  }
}
export function makeApiQueryString(
  params: FTEntityMetaSubmission,
  otherParams: Record<string, any> | null | undefined = {},
) {
  const {
    pageNumber = 1,
    pageSize = 20,
    orderBy,
    filterBy,
    searchBy,
    excludeRedaptiveEmail,
  } = params
  const queryParams = {
    ...otherParams,
    ...getOptionalFilterBy(filterBy),
    ...getOptionalSearchBy(searchBy),
    order: orderByToString(orderBy),
    pageSize,
    pageNumber,
    excludeRedaptiveEmail,
  }
  return queryStringify(queryParams)
}
export function addMetaToResponse(
  params: FTEntityMetaSubmission,
  response: Record<string, any>,
) {
  const { pageNumber = 1, pageSize = 20, orderBy, filterBy, searchBy } = params
  const { data } = response
  const { totalCount = 0 } = data
  return {
    ...data,
    totalPages: getTotalPages(totalCount, pageSize),
    pageNumber,
    pageSize,
    orderBy,
    filterBy,
    searchBy,
  }
}
export const makeHybridField = (
  field,
  pending: Record<string, any>,
  current: Record<string, any>,
  showIfSame = false,
) => {
  const pendingValue = pending ? pending[field] : undefined
  const currentValue = current ? current[field] : undefined
  let computedPending = currentValue !== pendingValue ? pendingValue : undefined

  if (showIfSame) {
    computedPending = pendingValue
  }

  return {
    current: currentValue,
    pending: computedPending,
  }
}
export type FTRange = {
  from: string
  to: string
}
