/* eslint react/jsx-filename-extension: off */
import {
  ErrorBoundary,
  ProviderProps,
  Provider as RollbarProvider,
} from '@rollbar/react'
import { ConnectedRouter } from 'connected-react-router'
import 'core-js/stable'
import { createBrowserHistory } from 'history'
import 'material-icons/iconfont/material-icons.css'
import 'react-calendar/dist/Calendar.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import PortalContainer from './components/PortalContainer'
import './index.css'
import './init'
import App from './pages/App'
import ErrorPage from './pages/ErrorPage'
import configureStore from './store/configureStore'

const history = createBrowserHistory()
export const store = configureStore({}, history)
const root = document.getElementById('root')

const rollbarConfig: ProviderProps['config'] = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: import.meta.env.MODE !== 'development',
  payload: {
    environment: import.meta.env.VITE_DEPLOY_ENV,
    source_map_enabled: true,
    server: {
      root: '../../',
      branch: import.meta.env.VITE_GIT_BRANCH,
    },
    code_version: import.meta.env.VITE_COMMIT_HASH,
    guess_uncaught_frames: true,
  },
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default caching to 20 seconds
      staleTime: 1000 * 20,
    },
  },
})

if (root instanceof Element) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <RollbarProvider config={rollbarConfig}>
            <ErrorBoundary fallbackUI={ErrorPage}>
              <App />
              <PortalContainer />
            </ErrorBoundary>
          </RollbarProvider>
        </QueryClientProvider>
      </ConnectedRouter>
    </Provider>,
    root,
  )
}
