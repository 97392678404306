import styled from 'styled-components'
import { Component, ReactNode } from 'react'
import { SORT_ASC, SORT_DESC, SORT_NONE } from '../constants'

export const SortableFieldStyles = styled.div`
  color: #4a4a4a;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
  padding-right: 10px;
  text-shadow: 0 1px 0 0 #ffffff;
  user-select: none;
`
const BaseSortArrow = styled.div`
  opacity: ${({ orderIsActive }) => (orderIsActive ? '1' : '0')};
  height: 8px;
`
const SortUpArrow = styled(BaseSortArrow).attrs({
  className: 'ion-ios-arrow-up',
})``
const SortDownArrow = styled(BaseSortArrow).attrs({
  className: 'ion-ios-arrow-down',
})``
const Sorter = styled.div`
  bottom: 8px;
  position: absolute;
  right: -6px;
`
const Styles = styled.div`
  cursor: ${({ enabled }) => enabled && 'pointer'};
  position: relative;
`
type FTProps = {
  name: ReactNode
  currentSort:
    | {
        sort: string
        field: string
      }
    | null
    | undefined
  field: string
  onSort: (...args: Array<any>) => any
  enabled: boolean
}
export default class Sortable extends Component<FTProps> {
  getSortDirection = () => {
    const { field, currentSort } = this.props
    return (
      (currentSort && currentSort.field === field && currentSort.sort) ||
      SORT_NONE
    )
  }

  handleSort = () => {
    const { onSort, field, enabled } = this.props
    const sortDirection = this.getSortDirection()

    if (!enabled) {
      return
    }

    const newSort =
      ([SORT_ASC, SORT_NONE].includes(sortDirection) && SORT_DESC) || SORT_ASC
    onSort(field, newSort)
  }

  render() {
    const { name, enabled } = this.props
    const sortDirection = this.getSortDirection()
    return (
      <Styles onClick={this.handleSort} enabled={enabled}>
        <SortableFieldStyles>{name}</SortableFieldStyles>
        {enabled && (
          <Sorter>
            <SortUpArrow orderIsActive={sortDirection !== SORT_DESC} />
            <SortDownArrow orderIsActive={sortDirection !== SORT_ASC} />
          </Sorter>
        )}
      </Styles>
    )
  }
}
