
import moment from 'moment-timezone'
import DateTimePicker from 'react-datetime-picker'

import styled from "styled-components";
import Clock from './Icons/svg/Clock';

const DateTimePickerContainerStyled = styled.div<{
    disabled: boolean
}>`
  width: 100%;
  height: 34px;
  border: 1px solid #C7C7C7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;
  // disabled
  ${({ disabled }) => disabled && 'background-color: #f0f0f0;'}
`;

const TzPickerstyled = styled.select`
  border: none;
  color: #162447;
  font-size: 14px;
  height: 22px;
  background-color: transparent;
  outline: none;
  ${(props) =>
    props.disabled &&
    `
    background-color: #f0f0f0;
  `}
`;

const DateTimePickerStyled = styled(DateTimePicker)`
  height: 100%;
  color: #162447;
  > div {
    border: none;
    input {
      font-size: 14px;
    }
    input:not(.react-datetime-picker__inputGroup__input--hasLeadingZero) {
      padding: 0;
    }
    .react-datetime-picker__inputGroup__input--hasLeadingZero {
      width: 18px !important;
    }
  }
`;
enum DateTimePickerMode {
    DATE = 'date',
    TIME = 'time',
    DATE_TIME = 'datetime',
}
type DateTimePickerProps = {
    disabled?: boolean
    value?: Date
    setDate?: (date: Date | null) => void
    timeZone?: string
    setTimeZone?: (value: string) => void
    customCalendarIcon?: React.ReactNode,
    maxDate?: Date,
    minDate?: Date,
    mode?: DateTimePickerMode
    enableTimeZone?: boolean
}
const CustomDateTimePicker = (props: DateTimePickerProps) => {
    const {
        disabled = false,
        value = new Date(),
        setDate,
        timeZone,
        setTimeZone = () => {},
        customCalendarIcon = <Clock />,
        maxDate,
        minDate,
        enableTimeZone = false,
    } = props
    const momentTZ = moment.tz;
    const timeZones = momentTZ.names()
    const currentShortTimeZone = moment.tz(moment.tz.guess()).format('z')
    const shortTimeZones = timeZones
      .map((tz) => moment.tz(tz).format('z'))
      .filter((tz) => !tz.startsWith('+') && !tz.startsWith('-'))
    const uniqueTimeZones = [...new Set(shortTimeZones)]

    const additionalCalendarOpts = {
        maxDate,
        minDate,
    };

    return (
        <DateTimePickerContainerStyled disabled={disabled}>
            <div>
                <DateTimePickerStyled
                disabled={disabled}
                format='dd-M-y HH:mm'
                disableClock
                onChange={setDate}
                value={value}
                calendarIcon={null}
                clearIcon={null}    
                {...additionalCalendarOpts}        
                />
                {enableTimeZone && <TzPickerstyled
                disabled={disabled}
                onChange={(e) => setTimeZone(e.target.value)}
                value={timeZone || currentShortTimeZone}
                >
                {uniqueTimeZones.map((tz) => (
                    <option key={tz} value={tz}>
                    {tz}
                    </option>
                ))}
                </TzPickerstyled>
                }
            </div>
            {customCalendarIcon}
        </DateTimePickerContainerStyled>
      );
}


export default CustomDateTimePicker
