import styled from 'styled-components'
import { colors } from '../../utils/themes'

const Button = styled.label`
  background-color: transparent;
  color: ${colors.blue3};
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  margin: 10px;
  min-width: 64px;
  padding: 0 16px;
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
  display: flex;
  align-items: center;
`
export default {
  Button,
}
