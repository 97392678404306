import styled from 'styled-components'
import type { FTProposalCustomerGlobalInputs } from '../../../ducks/proposals/customerGlobalInputs'
import '../../../ducks/proposals/customerGlobalInputs'
import {
  USD_CURRENCY,
  enumToCapitalCase,
  getCurrencyFormat,
  getNumberFormatMinFractionDigits2,
  getNumberFormatFractionDigits4,
  isValueSet,
} from '../../../utils'

type FTProps = {
  customerGlobalInputs: FTProposalCustomerGlobalInputs
}
const GlobalInputStyles = styled.div`
  display: inline-block;
  :nth-child(odd) {
    padding-right: 4px;
  }
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`
const GlobalInputTitleStyles = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 14.1px;
  margin-bottom: 8px;
`
const GlobalInputMetricStyles = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 18.8px;
`

const GlobalInput = ({
  metric,
  title,
}: {
  metric: string | number
  title: string
}) => (
  <GlobalInputStyles>
    <GlobalInputTitleStyles>{title}</GlobalInputTitleStyles>
    <GlobalInputMetricStyles>{metric}</GlobalInputMetricStyles>
  </GlobalInputStyles>
)

const globalInputEnumMap: Record<string, string> = {
  NPV_REVENUE: 'NPV Revenue',
  HVAC: 'HVAC',
  'No Maintenance': 'No Maintenance',
  'Parts Only': 'Parts Only',
  'Parts and Labor': 'Parts and Labor',
  'Parts Only + Emergency Carveout': 'Parts Only + Emergency Carveout',
  'Warranty Administration Only': 'Warranty Administration Only',
  'Full Maintenance Coverage 24/7': 'Full Maintenance Coverage 24/7',
  'Preventative Maintenance': 'Preventative Maintenance',
  'Parts Only + 1st year Labor': 'Parts Only + 1st year Labor',
  'Parts Only + 1st year Labor + Emergency Carveout':
    'Parts Only + 1st year Labor + Emergency Carveout',
  'Parts + Labor through vendor': 'Parts + Labor through vendor',
  CAPEX: 'CAPEX',
  EAAS: 'EAAS',
  CONTRACT_VALUE: 'Pre-Tax Contract Value',
  PRE_TAX_GROSS_COST: 'Base Cost',
}

const getEnumValue = (value: string | null | undefined) =>
  value ? globalInputEnumMap[value] || enumToCapitalCase(value) : ''

function formatTerm(term: number) {
  return isValueSet(term) ? `${term} Months` : '--'
}

function getContractTypeValue(contractType: string | null | undefined) {
  return isValueSet(contractType) ? getEnumValue(contractType) : 'EAAS'
}

function getOperationsAndMaintenanceBasisValue(
  operationsAndMaintenanceBasis: string | null | undefined,
) {
  return isValueSet(operationsAndMaintenanceBasis) ?
      getEnumValue(operationsAndMaintenanceBasis)
    : 'NPV_REVENUE'
}

function getReferralFeeBasisValue(referralFeeBasis: string | null | undefined) {
  return isValueSet(referralFeeBasis) ?
      getEnumValue(referralFeeBasis)
    : 'CONTRACT_VALUE'
}

function getMaintenanceObligationValue(
  maintenanceObligation: string | null | undefined,
) {
  return isValueSet(maintenanceObligation) ?
      getEnumValue(maintenanceObligation)
    : 'No Maintenance'
}

const GlobalInputsView = (props: FTProps) => {
  const { customerGlobalInputs } = props
  const {
    annualTransferRate,
    contingency,
    id,
    annualInsurancePremium,
    locale = 'en-US',
    measurementAndVerificationCostAsPerProjectCostsInPercentage,
    measurementAndVerificationCostPerMeter,
    operationsAndMaintenanceBasis,
    operationsAndMaintenanceRate,
    projectManagementLaborAndFee,
    referralFeeBasis,
    referralFeeInPercentage,
    term,
    contractType,
    maintenanceObligation,
    maintenanceRetainedSavings,
    energyRetainedSavings,
  } = customerGlobalInputs
  const currencyFormat = getCurrencyFormat(locale, USD_CURRENCY)
  const numberFormatMinFractionDigits2 =
    getNumberFormatMinFractionDigits2(locale)
  const numberFormatFractionDigits4 = getNumberFormatFractionDigits4(locale)

  if (!id) {
    return <p>Global Inputs have not yet been created for this customer.</p>
  }

  return (
    <Wrapper>
      <GlobalInput metric={formatTerm(term)} title='Contract Term Months' />
      <GlobalInput
        metric={getContractTypeValue(contractType)}
        title='Contract Type'
      />
      <GlobalInput
        metric={
          isValueSet(annualTransferRate) ?
            `${numberFormatMinFractionDigits2.format(annualTransferRate)}%`
          : '--'
        }
        title='Transfer Rate %'
      />
      <GlobalInput
        metric={
          isValueSet(projectManagementLaborAndFee) ?
            `${numberFormatMinFractionDigits2.format(
              projectManagementLaborAndFee,
            )}%`
          : 0
        }
        title='Partner Fee %'
      />
      <GlobalInput
        metric={
          isValueSet(isValueSet) ?
            `${numberFormatMinFractionDigits2.format(
              operationsAndMaintenanceRate,
            )}%`
          : 0
        }
        title='O&M %'
      />
      <GlobalInput
        metric={getOperationsAndMaintenanceBasisValue(
          operationsAndMaintenanceBasis,
        )}
        title='O&M Basis'
      />
      <GlobalInput
        metric={
          isValueSet(referralFeeInPercentage) ?
            `${numberFormatMinFractionDigits2.format(referralFeeInPercentage)}%`
          : 0
        }
        title='Referral Fee %'
      />
      <GlobalInput
        metric={getReferralFeeBasisValue(referralFeeBasis)}
        title='Referral Fee Basis'
      />
      <GlobalInput
        metric={
          isValueSet(energyRetainedSavings) ?
            `${numberFormatMinFractionDigits2.format(energyRetainedSavings)}%`
          : 0
        }
        title='Energy Retained Savings %'
      />
      <GlobalInput
        metric={
          isValueSet(maintenanceRetainedSavings) ?
            `${numberFormatFractionDigits4.format(maintenanceRetainedSavings)}%`
          : 0
        }
        title='Maintenance Retained Savings %'
      />
      <GlobalInput
        metric={getMaintenanceObligationValue(maintenanceObligation)}
        title='Maintenance Obligation'
      />
      <GlobalInput
        metric={
          isValueSet(measurementAndVerificationCostPerMeter) ?
            currencyFormat.format(measurementAndVerificationCostPerMeter)
          : '--'
        }
        title='Cost Per Meter $'
      />
      <GlobalInput
        metric={
          (
            isValueSet(
              measurementAndVerificationCostAsPerProjectCostsInPercentage,
            )
          ) ?
            `${numberFormatMinFractionDigits2.format(
              measurementAndVerificationCostAsPerProjectCostsInPercentage,
            )}%`
          : '--'
        }
        title='M&V Cost Estimate %'
      />
      <GlobalInput
        metric={
          isValueSet(contingency) ?
            `${numberFormatMinFractionDigits2.format(contingency)}%`
          : 0
        }
        title='Contingency %'
      />
      <GlobalInput
        metric={`${numberFormatMinFractionDigits2.format(
          isValueSet(annualInsurancePremium) ? annualInsurancePremium : 0,
        )}%`}
        title='Insurance Fee % of Base Cost'
      />
    </Wrapper>
  )
}

export default GlobalInputsView
