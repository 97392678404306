import * as React from 'react'
import styled from 'styled-components'
import numeral from 'numeral'
import { connect as connectFormik, getIn, FastField } from 'formik'
import Input from './Input'
import Spinner from './Spinner'
import * as consts from '../constants'

export const FormFieldTitle = styled.label`
  font-weight: 600;
  font-size: 14px;
`
const Subtitle = styled.span`
  font-size: 12px;
  font-style: italic;
  font-weight: 200;
`
const Required = styled.div`
  color: #c70d08;
`
export const FormFieldTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  user-select: none;
`
const Description = styled.div`
  color: #7f7f7f;
  user-select: none;

  font-size: 12px;
  line-height: 18px;
  margin-top: 7px;
  font-weight: 400;
  & a[href] {
    display: inline-block;
    margin-left: 5px;
  }
`
export const FormFieldStyles = styled.div<{
  showRequired?: boolean
  width?: string
}>`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 10px;
  }

  & input {
    box-sizing: border-box;
    border-color: ${({ showRequired }) => showRequired && '#c70d08'};
    width: ${({ width }) => width};
  }
`
type FTProps = {
  className?: string
  formik?: Record<string, any>
  inputComponent?: React.ReactNode
  isReadOnly?: boolean
  error?: string
  loading?: boolean
  name: string
  readOnlyFormat?: string
  renderDescription?: () => React.ReactNode
  renderField?: () => React.ReactNode
  requiredText?: string
  showRequired?: boolean
  subtitle?: string
  title?: React.ReactNode
  width?: string
  'data-testid'?: string
}

class FormField extends React.PureComponent<FTProps> {
  static defaultProps = {
    className: '',
    inputComponent: Input,
    isReadOnly: false,
    loading: false,
    readOnlyFormat: undefined,
    renderDescription: undefined,
    renderField: undefined,
    requiredText: '* Required',
    showRequired: false,
    subtitle: '',
    width: '100%',
  }

  renderReadOnly() {
    const { name, formik, readOnlyFormat } = this.props
    let readOnlyValue = consts.NOT_SET
    const formikValues = formik?.values
    const value = getIn(formikValues, name)

    if (value || value === 0) {
      readOnlyValue =
        value && readOnlyFormat ? numeral(value).format(readOnlyFormat) : value
    }

    return <div>{readOnlyValue}</div>
  }

  render() {
    const {
      title,
      subtitle,
      showRequired,
      requiredText,
      renderDescription,
      width,
      renderField,
      className,
      formik,
      inputComponent: InputComponent,
      name,
      isReadOnly,
      loading,
      ...rest
    } = this.props
    return (
      <FormFieldStyles
        width={width}
        showRequired={showRequired}
        className={className}
      >
        <FormFieldTitleSection>
          <FormFieldTitle htmlFor={name}>
            {title} {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </FormFieldTitle>
          {showRequired && <Required>{requiredText}</Required>}
          {loading && <Spinner size='micro' />}
        </FormFieldTitleSection>
        {renderField && renderField()}
        {!renderField && !isReadOnly && (
          <FastField
            id={name}
            name={name}
            component={InputComponent}
            {...rest}
          />
        )}
        {!renderField && isReadOnly && this.renderReadOnly()}
        {renderDescription && <Description>{renderDescription()}</Description>}
      </FormFieldStyles>
    )
  }
}

export default connectFormik(FormField)
