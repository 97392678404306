import type { ComponentType } from 'react'
import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import clickOutside from 'react-click-outside'
// eslint-disable-next-line import/no-extraneous-dependencies
import type { FTActionOption } from './DropdownNew'
import DropdownNew from './DropdownNew'
import ArrowFilledUp from './Icons/svg/ArrowFilledUp'
import ArrowFilledDown from './Icons/svg/ArrowFilledDown'

export const DropdownMenuStyles: ComponentType<{
  open: boolean
  borderOnClose: boolean
  borderColor: string
  textColor: string
  bgColor: string
  iconBefore: boolean
  fontSize: string
  iconBefore: boolean
}> = styled.div`
  user-select: none;
  height: 36px;
  width: 165px;
  line-height: 36px;
  cursor: pointer;
  border: 1px solid;
  border-color: ${({ open, borderOnClose, borderColor }) =>
    !open && !borderOnClose ? 'transparent' : borderColor};
  border-radius: ${({ open }) => (open ? '5px 5px 0 0' : '5px')};
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  flex-wrap: wrap;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '`1`')};
  justify-content: ${({ iconBefore }) =>
    iconBefore ? 'flex-start' : 'space-between'};
  align-items: center;

  &:hover {
    background-color: #f5f5f5;
  }

  & a,
  & a:hover {
    text-decoration: none;
    color: #337ab7;
  }

  & .IonIcon {
    width: 25px;
    height: 18px;
    line-height: unset;

    & > span {
      left: 8px;
      top: -5px;
    }

    font-size: 18px;
  }

  .title {
    padding: ${({ iconBefore }) => (iconBefore ? '0px' : '0 0 0 12px')};
    font-weight: 600;
    color: ${({ textColor, disabled }) => (disabled ? '#6D6D70' : textColor)};
    font-size: ${({ fontSize }) => fontSize};
  }

  .down-icon {
    padding: 0px 12px;
  }

  .down-icon {
    padding: 0px 12px;
  }
`
const DropDownDefaultIconStyles = styled.div`
  margin: 0 12px;
  display: flex;
`
type FTProps = {
  actions: Array<FTActionOption> & {
    value?: number
  }
  title?: string
  defaultValue?: number
  icon?: Record<string, any>
  iconBefore?: boolean
  style?: Record<string, any>
  borderColor?: string
  textColor?: string
  fontSize?: string
  bgColor?: string
  listBgColor?: string
  borderOnClose?: boolean
  showSelected?: boolean
  onSelectedValueChange?: (...args: Array<any>) => any
  overrideToggleState?: boolean
  disabled?: boolean
  openStateIcon?: ComponentType<{}>
  closeStateIcon?: ComponentType<{}>
}

const DropdownMenuNew = ({
  actions,
  title,
  icon,
  iconBefore,
  style,
  borderColor = '#d7d7d7',
  borderOnClose,
  textColor = '#6C6D6E',
  bgColor = '#ffffff',
  fontSize = '14px',
  listBgColor,
  defaultValue,
  showSelected,
  onSelectedValueChange,
  disabled,
  openStateIcon,
  closeStateIcon,
  overrideToggleState,
}: FTProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultValue || -1)

  const toggleMenu = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => setSelectedOption(defaultValue || -1), [defaultValue])

  useEffect(() => {
    if (
      overrideToggleState !== null &&
      typeof overrideToggleState !== 'undefined'
    ) {
      setIsExpanded(!overrideToggleState)
    }
  }, [overrideToggleState])
  useEffect(() => {
    if (onSelectedValueChange && selectedOption && selectedOption !== -1) {
      onSelectedValueChange(selectedOption)
    }
  }, [selectedOption])

  // eslint-disable-next-line
  const handleClickOutside = () => {
    setIsExpanded(false)
  }

  const renderTitle = useMemo(() => {
    if (title) {
      return title
    }

    if (selectedOption && selectedOption !== -1) {
      // $FlowFixMe
      return actions.find((x) => x.value === selectedOption).label
    }

    if (defaultValue && selectedOption && selectedOption === -1) {
      // $FlowFixMe
      return actions.find((x) => x.value === defaultValue).label
    }

    return 'Actions'
  }, [selectedOption, defaultValue])

  const DropdownDefaultIcon = () => (
    <DropDownDefaultIconStyles>
      {isExpanded ?
        openStateIcon || <ArrowFilledUp />
      : closeStateIcon || <ArrowFilledDown />}
    </DropDownDefaultIconStyles>
  )

  const handleOptionSelect = (value) => {
    setSelectedOption(value)
  }

  return (
    <DropdownMenuStyles
      onClick={toggleMenu}
      style={style}
      open={isExpanded}
      borderOnClose={borderOnClose || false}
      borderColor={borderColor}
      textColor={textColor}
      bgColor={bgColor}
      iconBefore={iconBefore || false}
      fontSize={fontSize}
      disabled={disabled}
    >
      {iconBefore && (icon || <DropdownDefaultIcon />)}
      <div className='title'>{renderTitle}</div>
      {!iconBefore && (icon || <DropdownDefaultIcon />)}
      {isExpanded && (
        <DropdownNew
          options={actions}
          borderColor={borderColor}
          style={style}
          textColor={textColor}
          listBgColor={listBgColor}
          showSelected={showSelected}
          selectedOption={selectedOption}
          handleOptionSelect={handleOptionSelect}
          defaultValue={defaultValue}
          fontSize={fontSize}
        />
      )}
    </DropdownMenuStyles>
  )
}

export default clickOutside(DropdownMenuNew)
