import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import type { FTModalResetPasswordLink } from '../ducks/modal'
import { actions as modalActions } from '../ducks/modal'
import Modal, { ModalContentStyles } from './Modal'
import { actions as userActions, selectUserReset } from '../ducks/users'
import OneTimeLink, {
  OneTimeLinkStyles,
  OneTimeLinkTitleStyles,
} from './OneTimeLink'
import Spinner from './Spinner'

const ModalStyles = styled.div`
  ${ModalContentStyles} {
    padding: 0;
  }

  ${OneTimeLinkStyles} {
    border: 0;
    padding: 0;
    width: auto;
  }

  ${OneTimeLinkTitleStyles} {
    margin-top: 0;
    text-align: center;
  }
`
type FTProps = {
  actions: Record<string, any>
  resetEmail: string | null | undefined
  resetId: string | null | undefined
} & FTModalResetPasswordLink

const ModalResetPasswordLink = (props: FTProps) => {
  const { actions, email, resetEmail, resetId } = props
  useEffect(() => {
    actions.resetUser({
      email,
    })
    return actions.clearUserReset
  }, [])

  const onClose = () => {
    actions.hideModal()
  }

  const renderContent = () => {
    if (resetId && resetEmail) {
      return <OneTimeLink email={resetEmail} resetId={resetId} />
    }

    return <Spinner />
  }

  return (
    <ModalStyles>
      <Modal
        modalWidth='720px'
        onAccept={onClose}
        onClose={onClose}
        renderContent={renderContent}
      />
    </ModalStyles>
  )
}

const mapStateToProps = (state) => {
  const { resetId, email: resetEmail } = selectUserReset(state)
  return {
    resetId,
    resetEmail,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(userActions, dispatch),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalResetPasswordLink)
