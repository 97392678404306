import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import Tippy from '@tippyjs/react'
import {
  actions as panelActions,
  FTPanelSummary,
  selectPanelListEntity,
  selectTempMeta,
} from '../../ducks/panels'
import type {
  FTPanelCircuit,
  FTDeletePanelCircuitsAction,
  FTFetchPanelCircuitsAction,
} from '../../ducks/panelCircuits'
import {
  actions as panelCircuitActions,
  selectPanelCircuitsEntity,
} from '../../ducks/panelCircuits'
import type { FTFormFieldEvent, FTRouterMatch, FTWithRouter } from '../../types'
import Title from '../../components/Title'
import VerticalTable from '../../components/VerticalTable'
import Breadcrumbs from '../../components/Breadcrumbs'
import ActionPaneView from '../../components/ActionPaneView'
import { fieldNameMap } from '../../ducks/meters/generation'
import Spinner from '../../components/Spinner'
import EditNoteIconStyled from '../../components/ProposalsEngine/EditNoteIconStyled'
import ListSelector from '../../components/ListSelector'
import type {
  FTAddPanelAction,
  FTDeletePanelAction,
  FTPanel,
  FTPanelFeed,
} from '../../ducks/panels'
import PanelSelectorItem from '../../components/PanelSelectorItem'
import { getValueFromEvent } from '../../utils'
import {
  actions as siteActions,
  FTFetchSiteAction,
  selectSiteEntity,
} from '../../ducks/sites'
import PanelCircuitsTable from '../../components/PanelConfiguration/PanelCircuitsTable'
import FormikForm from '../../components/PanelConfiguration/FormikForm'
import { utils } from '../../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'
import {
  actions as buildingSystemsActions,
  FTBuildingSystemSummary,
  selectors,
} from '../../ducks/buildingSystems'
import { actions as modalActions, FTConfirmModal } from '../../ducks/modal'
import MeteredCircuit from '../../components/Icons/svg/MeteredCircuit'
import UnMeteredCircuit from '../../components/Icons/svg/UnMeteredCircuit'
import type { FTModalPanelForm2, FTModalPanelForm3 } from '../../ducks/modal'
import {
  getCreatedCircuits,
  columnWidths,
  getSortedCircuits,
} from '../../utils/panelConfiguration'
import ErrorMessage from '../../components/ErrorMessage'
import {
  renderCreatePanelConfirm,
  renderCreateSwitchBoardConfirm,
  renderDeletePanelConfirm,
  renderUpdateCircuitsConfirm,
  renderUpdateSwitchesConfirm,
} from '../../components/PanelConfiguration/Modals'
import type { FTEntity } from '../../ducks/types'
import type { FTCustomer, FTFetchCustomerAction } from '../../ducks/customers'
import {
  selectCustomersById,
  actions as customerActions,
} from '../../ducks/customers'

import {
  selectPanelMetaData,
  actions as panelMetaDataActions,
} from '../../ducks/panelPhotos'
import ctSizeMapping from '../../utils/panel'
import { findArrayType } from '../../components/ModalPanelForm3/utils'
import { breakerNumbers } from '../../ducks/circuits'
import {
  FTMessageInput,
  actions as messagesActions,
} from '../../ducks/messages'

const StyledListSelector = styled(ListSelector)`
  width: 80px;
  height: 36px;

  .Select__input,
  .Select__multi-value-wrapper {
    height: 100%;
  }

  .Select__option,
  .Select__value-container {
    align-items: center;
    display: flex;
  }

  .Select__placeholder {
    width: 100%;
  }

  .Select__menu-outer {
    top: auto;
  }
`
const PanelDropdownContainer = styled.div`
  margin-bottom: 44px;
  margin-top: -15px;

  ${StyledListSelector} .Select__menu .Select__option {
    height: auto;
    padding: 10px;
  }
`
const StyledPanelSelector = styled(StyledListSelector)`
  * {
    box-sizing: border-box;
  }
  width: 450px;

  .Select__option,
  .Select__value-container {
    align-items: center;
    display: flex;
    padding: 15px;
  }

  .Select--is-disabled {
    opacity: 0.6;
  }
`
const TextCellStyled = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`
const CircuitSectionStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative;
`
const SectionTitleStyled = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #4a4a4a;
  padding-bottom: 15px;
`
const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #c7c7c7 solid;
`
const EditActionStyled = styled.div`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  color: #337ab7;
  a {
    color: #337ab7;
  }
`

const PanelHeading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dbdbdb;
  color: #4a4a4a;
  padding: 8px 0;
`

export const MeterIconWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
`

const ViewPhotosButton = styled.span`
  color: #337ab7;
  cursor: pointer;
`

const Wrapper = styled.div`
  .panel-info {
    margin-bottom: 30px;
  }
`

type FTProps = {
  actions: {
    addPanel: (params: FTAddPanelAction) => null
    resetPanel: () => null
    fetchPanelList: (params: { siteId: string }) => null
    fetchAllPanels: (params: { siteId: string }) => null
    fetchSite: (params: FTFetchSiteAction) => null
    fetchCustomer: (params: FTFetchCustomerAction) => null
    fetchPanelCircuits: (params: FTFetchPanelCircuitsAction) => null
    fetchBuildingSystems: () => null
    updatePanelCircuits: (params: Arrya<FTPanelCircuit>) => null
    showConfirmModal: (arg0: FTConfirmModal) => null
    showModalPanelForm2: (arg0: FTModalPanelForm2) => null
    showModalPanelForm3: (arg0: FTModalPanelForm3) => null
    hideModal: () => null
    showModalPanelPhotosView: () => null
    hideModal: () => null
    fetchPanelPhotos: ({ objectId: string }) => null
    fetchPhotosDownloadUrl: ({ fileIds: string }) => null
    deletePanel: (arg0: FTDeletePanelAction) => null
    deletePanelCircuits: (arg0: FTDeletePanelCircuitsAction) => null
    showMessage: (arg0: FTMessageInput) => null
    hideMessage: (value: string) => null
  }
  match: FTRouterMatch
  addedPanelId: string
  addLoaded: string
  panels: Array<FTPanelSummary>
  panelsLoading: boolean
  panelsById: Record<string, FTPanelSummary>
  panelEntity: FTEntity
  panelDeleted: boolean
  panelDeleting: boolean
  circuits: Array<FTPanelCircuit>
  circuitsById: Record<string, any>
  circuitsLoading: boolean
  circuitsUpdating: boolean
  circuitsUpdateError: string
  circuitsDeleteError: string
  circuitsDeleting: boolean
  buildingSystems: Array<FTBuildingSystemSummary>
  buildingSystemsById: Record<string, FTBuildingSystemSummary>
  customerEntity: Record<string, FTCustomer>
  siteById: object
  tempPanelObject: FTPanelSummary
} & FTWithRouter

const PanelDetailsPage = (props: FTProps) => {
  const {
    actions,
    addedPanelId,
    addLoaded,
    buildingSystems,
    buildingSystemsById,
    circuits,
    circuitsById,
    circuitsLoading,
    circuitsUpdating,
    circuitsUpdateError,
    circuitsDeleteError,
    circuitsDeleting,
    customerEntity,
    panels,
    panelsById,
    panelsLoading,
    panelEntity,
    panelDeleted,
    panelDeleting,
    siteById,
    location,
    history,
    tempPanelObject,
    match: {
      url,
      params: { siteId, panelId, customerId },
    },
    panelMetaData,
  } = props
  const { pathname } = location
  const inCreateMode =
    pathname.endsWith('/edit') && addedPanelId && addedPanelId.length > 0
  const inEditMode = pathname.endsWith('/edit')
  const { panelPhotos } = panelMetaData

  // error state
  const [error, setError] = React.useState('')

  const panel = panelsById[panelId]
  const isNumbered =
    panel && panel.isNumbered !== undefined ? panel.isNumbered : true

  const firstLoad = React.useRef(true)

  const [isPanelFlagUpdated, setIsPanelFlagUpdated] = React.useState(false)
  const dirtyCircuitsUpdated: boolean =
    !circuitsUpdating &&
    !circuitsUpdateError &&
    !circuitsDeleteError &&
    !circuitsDeleting
  const circuitsUpdatedSuccessfully: boolean =
    isPanelFlagUpdated && dirtyCircuitsUpdated
  const customerName =
    customerId &&
    (customerEntity[customerId]?.validName || customerEntity[customerId]?.name)
  const breadcrumbs = React.useMemo(
    () => [
      {
        href: '/account-management',
        text: 'Accounts',
      },
      ...(customerId ?
        [
          {
            href: '/account-management/customers',
            text: 'Customers',
          },
          {
            href: `/account-management/customers/${customerId}`,
            text: customerName || '--',
          },
          {
            href: `/account-management/customers/${customerId}/sites/${siteId}`,
            text:
              siteById && siteById[siteId] ? siteById[siteId].display : '--',
          },
        ]
      : [
          {
            href: '/account-management/sites',
            text: 'Sites',
          },
          {
            href: `/account-management/sites/${siteId}`,
            text:
              siteById && siteById[siteId] ? siteById[siteId].display : '--',
          },
        ]),
    ],
    [siteById, customerEntity, customerId],
  )
  // Once the panel is created, Redirect to the panel Id and clear the temp state
  React.useEffect(() => {
    if (isPanelFlagUpdated && addedPanelId && addLoaded) {
      history.push(url.split('/').slice(0, -1).concat(addedPanelId).join('/'))
      actions.resetPanel()
    }
  }, [isPanelFlagUpdated, addedPanelId, addLoaded])
  React.useEffect(() => {
    actions.fetchAllPanels({
      siteId,
    })
    actions.fetchSite({
      siteId,
    })
    actions.fetchBuildingSystems({})
    actions.fetchCustomer({
      customerId,
    })
  }, [])
  // Get out of Edit mode once the panel circuits are updated successfully updated
  React.useEffect(() => {
    if (!inCreateMode && circuitsUpdatedSuccessfully) {
      history.goBack()
    }
  }, [circuitsUpdatedSuccessfully, isPanelFlagUpdated])
  // Go to the panels list once the panel is successfully deleted
  React.useEffect(() => {
    if (panelDeleted && !panelDeleting) {
      history.push(url.split('/').slice(0, -1).join('/'))
    }
  }, [panelDeleted, panelDeleting])
  // Fetch the panel circuits whenever the panelId changes or when the panel circuits are updated
  React.useEffect(() => {
    if (panelId) {
      if (firstLoad.current || circuitsUpdatedSuccessfully) {
        actions.fetchPanelCircuits({
          panelId,
        })
        if (panelId) {
          const objectId = `objectId=${panelId}/Location&objectId=${panelId}/Configuration&objectId=${panelId}/Box&objectId=${panelId}/Schedule&objectId=${panelId}/BreakerTypes
          `
          actions.fetchPanelPhotos({ objectId })
        }
      }
      if (firstLoad.current) {
        firstLoad.current = false
      }
    }
  }, [panelId, circuitsUpdatedSuccessfully])

  React.useEffect(() => {
    if (inEditMode) {
      const panel = panelsById[panelId]
      if (tempPanelObject.fromPanelModal) {
        const photosUpdatedMessage =
          tempPanelObject.photosUpdated ?
            `${panel.name} panel is updated & photos are associated to the ${panel.name} panel. `
          : ''

        let updatedMessage = `${panel.name} panel is updated`
        if (inCreateMode) {
          updatedMessage = ''
        }
        const message = `${
          photosUpdatedMessage.length > 0 ?
            photosUpdatedMessage
          : updatedMessage
        }Add any ${
          isNumbered ? 'circuits' : 'switches'
        } to associate to the panel.`
        actions.showMessage({
          messageId: 'panel-photos-updated',
          title: message,
          type: 'success',
          position: 'fixed',
        })
      }
    } else {
      actions.hideMessage('panel-photos-updated')
    }
  }, [tempPanelObject, inEditMode])
  React.useEffect(
    () => () => {
      actions.hideModal()
    },
    [],
  )

  const onShowPanelPhotosView = () => {
    if (panelPhotos.length > 0) {
      const fileIds = panelPhotos.map((item: { id: string }) => item.id)
      actions.fetchPhotosDownloadUrl({ fileIds })
    }
    actions.showModalPanelPhotosView()
  }
  const renderViewPhotos = () => (
    <ViewPhotosButton onClick={onShowPanelPhotosView}>
      View Photos
    </ViewPhotosButton>
  )

  const renderPanelFeed = (panelFeeds) => {
    const hardwareIds = [...new Set(panelFeeds.map((item) => item.hardwareId))]
    const ctSizes = [
      ...new Set(
        panelFeeds.map((item: FTPanelFeed) => ctSizeMapping[item.ctSize].text),
      ),
    ]
    const phaseA = [
      ...new Set(
        panelFeeds
          .filter((item: FTPanelFeed) => item.phase === 'Phase 1')
          .map((item: FTPanelFeed) => item.channelName),
      ),
    ]
    const phaseB = [
      ...new Set(
        panelFeeds
          .filter((item: FTPanelFeed) => item.phase === 'Phase 2')
          .map((item: FTPanelFeed) => item.channelName),
      ),
    ]
    const phaseC = [
      ...new Set(
        panelFeeds
          .filter((item: FTPanelFeed) => item.phase === 'Phase 3')
          .map((item: FTPanelFeed) => item.channelName),
      ),
    ]

    const paneFeedFields = [
      {
        label: 'CT Size (mm)',
        value: ctSizes.join(', ') || '--',
        editable: false,
      },
      {
        label: 'Phase A - CT number(s) used',
        value: phaseA.length ? phaseA.join(', ') : '--',
        editable: false,
      },
      {
        label: 'Phase B  - CT number(s) used',
        value: phaseB.length ? phaseB.join(', ') : '--',
        editable: false,
      },
      {
        label: 'Phase C  - CT number(s) used',
        value: phaseC.length ? phaseC.join(', ') : '--',
        editable: false,
      },
      {
        label: 'Meter MAC',
        value: hardwareIds.join(', ') || '--',
        editable: false,
      },
    ]
    return (
      <>
        <PanelHeading>Panel Feed</PanelHeading>
        <VerticalTable.Basic fields={paneFeedFields} columned={false} />
      </>
    )
  }

  const renderPanelFields = () => {
    const panel = panelsById[panelId]
    const parentPanelName =
      panelsById[panel?.parentPanelId]?.name || panel?.parentPanelName
    if (!panel) return null
    const panelFields = [
      {
        label: 'Panel Name',
        value: panel.name || '--',
        editable: false,
      },
      {
        label: 'Panel Description',
        value: panel.description || '--',
        editable: false,
      },
      {
        label: 'Panel Location',
        value: panel.location || '--',
        editable: false,
      },
      {
        label: 'Panel Type',
        value: (panel.type && fieldNameMap.get(panel.type)) || '--',
        editable: false,
      },
      {
        label: 'Panel Voltage',
        value: (panel.voltage && fieldNameMap.get(panel.voltage)) || '--',
        editable: false,
      },
      {
        label: 'Amperage',
        value: panel.amperage || '--',
        editable: false,
      },
      {
        label: 'Power Source',
        value: panel.powerSourceLevel || '--',
        editable: false,
      },
      {
        label: 'Upstream Panel',
        value: parentPanelName || '--',
        editable: false,
      },
      {
        label: 'Auditor',
        value: panel.auditorName || '--',
        editable: false,
      },
      {
        label: 'Survey Date',
        value:
          panel?.auditDate ? moment(panel.auditDate).format('DD-MMM-YY') : '--',
        editable: false,
      },
      {
        label: 'Creation Date',
        value:
          panel?.created ? moment(panel.created).format('DD-MMM-YY') : '--',
        editable: false,
      },
      {
        label: 'Last Update',
        value:
          panel?.modified ? moment(panel.modified).format('DD-MMM-YY') : '--',
        editable: false,
      },
      {
        label: 'Photos',
        value: renderViewPhotos(),
        editable: false,
      },
    ]
    return (
      <Wrapper>
        <PanelHeading>Panel Info</PanelHeading>
        <VerticalTable.Basic
          fields={panelFields}
          columned
          className='panel-info'
        />
        {panel.panelFeed &&
          panel.panelFeed.length > 0 &&
          renderPanelFeed(panel.panelFeed)}
      </Wrapper>
    )
  }

  const onUpdatePanelId = (event: FTFormFieldEvent) => {
    const value = getValueFromEvent(event)

    if (value && value !== panelId) {
      history.push(url.split('/').slice(0, -1).concat(value).join('/'))

      // to fetch the panel circuits whenever the panelId changes
      firstLoad.current = true
    }
  }

  const renderPanelsDropdown = () => {
    const makePanelListSelectorItem = (panel: FTPanel) => {
      const { id, name } = panel
      return {
        id,
        name,
        renderFunc: () => (
          <PanelSelectorItem
            onClickEdit={() => {}}
            onClickDelete={() => {}}
            panel={panel}
            readOnly
          />
        ),
      }
    }

    const panelSelectorItems = panels.map((panel) =>
      makePanelListSelectorItem(panel),
    )
    const selectedPanelItem =
      panelId && panelsById[panelId] ?
        makePanelListSelectorItem(panelsById[panelId])
      : panelSelectorItems[0]
    return (
      <PanelDropdownContainer>
        <StyledPanelSelector
          items={panelSelectorItems}
          selectedItem={selectedPanelItem}
          updateValue={onUpdatePanelId}
          disabled={inEditMode}
          unsettable={false}
          notSetItemText='--'
          notSetItemValue=''
          notSetLabelText='--'
        />
      </PanelDropdownContainer>
    )
  }

  const handleAddPanelSuccess = () => {
    history.push(`${url}/edit`)
    if (panelId) {
      const objectId = `objectId=${panelId}/Location&objectId=${panelId}/Configuration&objectId=${panelId}/Box&objectId=${panelId}/Schedule&objectId=${panelId}/BreakerTypes
        `
      actions.fetchPanelPhotos({ objectId })
    }
  }

  const getActionPaneActions = () =>
    !inCreateMode && !inEditMode ?
      [
        // TODO: Enable this when needed
        // {
        //   href: '',
        //   external: true,
        //   label: 'Download Meter Data',
        // },
        {
          href: '',
          external: false,
          label: 'Edit Panel Details',
          onClick: (event) => {
            event.preventDefault()
            if (panelPhotos.length > 0) {
              const fileIds = panelPhotos.map((item: { id: string }) => item.id)
              actions.fetchPhotosDownloadUrl({ fileIds })
            }
            actions.showModalPanelForm3({
              siteId,
              panelListEntity: panelEntity,
              panelById: panelsById[panelId],
              circuitsById,
              meterIds: panelsById[panelId]?.meterIds,
              updateMode: true,
              closeModal: actions.hideModal,
              handleSuccess: handleAddPanelSuccess,
            })
          },
        },
        {
          href: '',
          external: false,
          label: 'Delete Panel',
          onClick: (event) => {
            event.preventDefault()
            actions.showConfirmModal({
              modalWidth: '418px',
              onPrimaryAction: undefined,
              onSecondaryAction: () => {
                actions.deletePanel({
                  id: panelId,
                  name: panelsById[panelId].name,
                })
              },
              primaryActionText: 'No',
              renderBody: () =>
                renderDeletePanelConfirm(panelsById[panelId].name),
              secondaryActionText: 'Yes',
            })
          },
        },
      ]
    : []

  const getColWidthForBreakerNumber = () =>
    isNumbered ?
      { ...columnWidths.breakerNumber }
    : { ...columnWidths.switchName }

  let readOnlyColumns = [
    {
      accessor: 'isMetered',
      Header: 'Metered',
      Cell: ({ original: { macAddress, meterId }, value }: FTCell) => (
        <MeterIconWrapperStyled>
          {value ?
            <Tippy content={macAddress}>
              <Link to={`../.../../meters/${meterId}/configuration`}>
                <MeteredCircuit />
              </Link>
            </Tippy>
          : <UnMeteredCircuit />}
        </MeterIconWrapperStyled>
      ),
      ...columnWidths.isMetered,
    },
    {
      accessor: 'breakerNumber',
      Header: isNumbered ? 'Breaker #' : 'Switch Name',
      Cell: ({ value }) =>
        (
          <Tippy content={value} delay={500}>
            <TextCellStyled>{value}</TextCellStyled>
          </Tippy>
        ) || '--',
      ...getColWidthForBreakerNumber(),
    },
    {
      accessor: 'phase',
      Header: 'Phase',
      Cell: ({ value }) =>
        (
          <Tippy content={value} delay={500}>
            <TextCellStyled>{value}</TextCellStyled>
          </Tippy>
        ) || '--',
      ...columnWidths.phase,
    },
    {
      accessor: 'breakerType',
      Header: 'Breaker Type',
      Cell: ({ value }) =>
        (
          <Tippy content={utils.breakerTypes[value]} delay={500}>
            <TextCellStyled>{utils.breakerTypes[value]}</TextCellStyled>
          </Tippy>
        ) || '--',
      ...columnWidths.breakerType,
    },
    {
      accessor: 'phaseGroupSummary',
      Header: 'Phase Group',
      Cell: ({ value }) =>
        (
          <Tippy content={value?.name} delay={500}>
            <TextCellStyled>{value?.name}</TextCellStyled>
          </Tippy>
        ) || '--',
      ...columnWidths.phaseGroup,
    },
    {
      accessor: 'panelFeedName',
      Header: 'Panel Feed To',
      Cell: ({ value }) =>
        (
          <Tippy content={value} delay={500}>
            <TextCellStyled>{value}</TextCellStyled>
          </Tippy>
        ) || '--',
      ...columnWidths.phaseGroup,
    },
    {
      accessor: 'description',
      Header: 'Circuit Description',
      Cell: ({ value }: FTCell) => (
        <Tippy content={value} delay={500}>
          <TextCellStyled>{value}</TextCellStyled>
        </Tippy>
      ),
      ...columnWidths.description,
    },
    {
      accessor: 'buildingSystemId',
      Header: 'Building System',
      Cell: ({ value }) => (
        <Tippy content={buildingSystemsById[value]?.name} delay={500}>
          <TextCellStyled>{buildingSystemsById[value]?.name}</TextCellStyled>
        </Tippy>
      ),
      ...columnWidths.buildingSystemId,
    },
    {
      accessor: 'equipmentName',
      Header: 'Equipment Name',
      Cell: ({ value }: FTCell) => (
        <Tippy content={value} delay={500}>
          <TextCellStyled>{value}</TextCellStyled>
        </Tippy>
      ),
      ...columnWidths.equipmentName,
    },
    {
      accessor: 'buildingArea',
      Header: 'Building Area',
      Cell: ({ value }: FTCell) => (
        <Tippy content={value} delay={500}>
          <TextCellStyled>{value}</TextCellStyled>
        </Tippy>
      ),
      ...columnWidths.buildingArea,
    },
    {
      accessor: 'amperage',
      Header: 'Amperage',
      Cell: ({ value }) => (
        <Tippy content={value} delay={500}>
          <TextCellStyled>{value}</TextCellStyled>
        </Tippy>
      ),
      ...columnWidths.amperage,
    },
    {
      accessor: 'CTSizeExpected',
      Header: 'CT Size Expected',
      Cell: ({ value }) => (
        <Tippy content={value} delay={500}>
          <TextCellStyled>{value}</TextCellStyled>
        </Tippy>
      ),
      ...columnWidths.CTSizeExpected,
    },
  ]

  if (!isNumbered) {
    readOnlyColumns = readOnlyColumns.filter(
      (col) => col.accessor !== 'breakerType',
    )
  }

  const renderMain = () => {
    const circuitsObjectById =
      inCreateMode ?
        getCreatedCircuits(panelEntity.meta, isNumbered)
      : circuitsById

    const circuitsObject = structuredClone(circuitsObjectById)

    if (!isNumbered) {
      Object.keys(circuitsObject).forEach((key) => {
        const circuit = circuitsObject[key]
        if (circuit.switchLabel && circuit.switchLabel.trim() !== '') {
          circuit.breakerNumber = circuit.switchLabel
        }
        circuitsObject[key] = circuit
      })
    }

    let sortedCircuits =
      isNumbered ?
        getSortedCircuits(Object.values(circuitsObject))
      : Object.values(circuitsObject)
    const circuitsOrder = sortedCircuits.map((circuit) => circuit.id)

    if (!isNumbered) {
      sortedCircuits = sortedCircuits.map((circuit) => ({
        ...circuit,
        breakerNumber:
          circuit.switchLabel ? circuit.switchLabel : circuit.breakerNumber,
      }))
    }

    let leftCircuits = sortedCircuits.filter(
      (circuit) => circuit.sideBreakerOrder === 'LEFT',
    )
    let rightCircuits = sortedCircuits.filter(
      (circuit) => circuit.sideBreakerOrder === 'RIGHT',
    )

    if (leftCircuits.length + rightCircuits.length < sortedCircuits.length) {
      const leftSideType = findArrayType(
        leftCircuits.map((circuit) => circuit.breakerNumber),
      )
      const rightSideType = findArrayType(
        rightCircuits.map((circuit) => circuit.breakerNumber),
      )

      if (leftSideType === 'EVEN' || rightSideType === 'ODD') {
        leftCircuits = sortedCircuits.filter(
          (circuit) => circuit.breakerNumber % 2 === 0,
        )
        rightCircuits = sortedCircuits.filter(
          (circuit) => circuit.breakerNumber % 2 === 1,
        )
      }
      if (leftSideType === 'ODD' || rightSideType === 'EVEN') {
        leftCircuits = sortedCircuits.filter(
          (circuit) => circuit.breakerNumber % 2 === 1,
        )
        rightCircuits = sortedCircuits.filter(
          (circuit) => circuit.breakerNumber % 2 === 0,
        )
      }

      if (leftSideType === 'CONSECUTIVE') {
        // half the circuits to left and half to right
        const halfLength = Math.floor(sortedCircuits.length / 2)
        leftCircuits = sortedCircuits.slice(0, halfLength)
        rightCircuits = sortedCircuits.slice(halfLength)
      }
    }

    // If the circuits don't have the mandatory fields.
    // TODO: How to handle Alphanumeric breakerNumber?
    if (
      sortedCircuits.length > 0 &&
      leftCircuits.length === 0 &&
      rightCircuits.length === 0
    ) {
      leftCircuits = sortedCircuits.filter(
        (circuit) => circuit.breakerNumber % 2 === 1,
      )
      rightCircuits = sortedCircuits.filter(
        (circuit) => circuit.breakerNumber % 2 === 0,
      )
    }
    if (!isNumbered) {
      rightCircuits = sortedCircuits
    }

    leftCircuits.forEach((circuit) => {
      circuitsObject[circuit.id] = { ...circuit, sideBreakerOrder: 'LEFT' }
    })

    rightCircuits.forEach((circuit) => {
      circuitsObject[circuit.id] = { ...circuit, sideBreakerOrder: 'RIGHT' }
    })

    const renderFunctions = {
      create: {
        numbered: renderCreatePanelConfirm,
        nonNumbered: renderCreateSwitchBoardConfirm,
      },
      update: {
        numbered: renderUpdateCircuitsConfirm,
        nonNumbered: renderUpdateSwitchesConfirm,
      },
    }

    const mode = inCreateMode ? 'create' : 'update'
    const type = isNumbered ? 'numbered' : 'nonNumbered'

    const renderCircuitDetailsForNumberedPanels = () =>
      (inEditMode && !circuitsLoading && circuits) || inCreateMode ?
        <FormikForm
          goBack={() => {
            actions.resetPanel()
            history.push(
              inCreateMode ? url.split('/').slice(0, -1).join('/') : url,
            )
          }}
          isNumbered={isNumbered}
          inCreateMode={inCreateMode && !addedPanelId}
          panels={panels}
          isUpdateSuccess={circuitsUpdatedSuccessfully}
          siteId={siteId}
          panelId={panelId}
          circuitsLoading={circuitsLoading}
          circuitsById={circuitsObject}
          circuitsOrder={circuitsOrder}
          buildingSystems={buildingSystems}
          buildingSystemsById={buildingSystemsById}
          showConfirmModal={actions.showConfirmModal}
          setErrorMessage={setError}
          deleteCircuitAction={actions.deletePanelCircuits}
          submitAction={(updatedCircuits, deletedCircuitsIds = []) => {
            actions.showConfirmModal({
              modalWidth: '418px',
              onPrimaryAction: undefined,
              onSecondaryAction: () => {
                if (deletedCircuitsIds && deletedCircuitsIds.length) {
                  actions.deletePanelCircuits({
                    circuitIds: deletedCircuitsIds,
                  })
                }
                actions.updatePanelCircuits({
                  panelId,
                  circuits: updatedCircuits.map((circuit) => ({
                    ...circuit,
                    siteId,
                  })),
                  fromPanelModal: !!tempPanelObject.fromPanelModal,
                  panelName: panelsById[panelId].name,
                  isNumbered,
                })
                actions.resetPanel()
                setIsPanelFlagUpdated(true)
              },
              primaryActionText: 'No',
              renderBody: renderFunctions[mode][type],
              secondaryActionText: 'Yes',
            })
          }}
        />
      : <PanelCircuitsTable
          leftCircuits={leftCircuits}
          rightCircuits={rightCircuits}
          isNumbered={isNumbered}
          circuitsLoading={circuitsLoading}
          columns={readOnlyColumns}
        />

    return (
      <>
        <Breadcrumbs items={breadcrumbs} />
        <Title>Panel Details</Title>
        {panelsLoading ?
          <Spinner size='medium' />
        : <div>
            {!!panels?.length && !inCreateMode && renderPanelsDropdown()}
            {renderPanelFields()}
          </div>
        }
        <CircuitSectionStyled>
          <HeaderStyled>
            <SectionTitleStyled>
              {isNumbered ? 'Circuit Breakers' : 'Switch Board'}
            </SectionTitleStyled>
            {!inEditMode && !inCreateMode && (
              <EditActionStyled>
                <EditNoteIconStyled />
                <Link to={`${url}/edit`}>Edit</Link>
              </EditActionStyled>
            )}
          </HeaderStyled>
          <ErrorMessage
            message={circuitsDeleteError || circuitsUpdateError || error}
            collapseWhenEmpty
          />
          {renderCircuitDetailsForNumberedPanels()}
        </CircuitSectionStyled>
      </>
    )
  }

  React.useEffect(
    () => () => {
      actions.resetPanel()
    },
    [],
  )
  return (
    <ActionPaneView renderMain={renderMain} actions={getActionPaneActions()} />
  )
}

const mapStateToProps = (state) => {
  const panelEntity = selectPanelListEntity(state)
  const panelCircuitsEntity = selectPanelCircuitsEntity(state)
  // To get site name for the breadcrumbs
  const siteEntity = selectSiteEntity(state)
  // To get customer name for the breadcrumbs
  const customerEntity = selectCustomersById(state)
  const panelMetaData = selectPanelMetaData(state)
  const buildingSystemsEntity = selectors.selectListEntity(state)
  const buildingSystemsById = selectors.selectById(state)
  const tempPanelObject = selectTempMeta(state)
  const {
    allItems: panels,
    allById: panelsById,
    meta: {
      allLoading: panelsLoading,
      temp,
      addedPanelId,
      addLoaded,
      deleteLoaded: panelDeleted,
      deleteLoading: panelDeleting,
    },
  } = panelEntity
  const {
    items: circuits,
    byId: circuitsById,
    meta: {
      loading: circuitsLoading,
      updateLoading: circuitsUpdating,
      updateError: circuitsUpdateError,
      deleteError: circuitsDeleteError,
      deleteLoading: circuitsDeleting,
    },
  } = panelCircuitsEntity
  const { byId: siteById } = siteEntity
  const { items: buildingSystems } = buildingSystemsEntity
  return {
    addedPanelId,
    addLoaded,
    panels,
    panelsById,
    panelEntity,
    panelDeleted,
    panelDeleting,
    buildingSystems,
    buildingSystemsById,
    panelsLoading,
    circuits,
    circuitsById,
    circuitsLoading,
    circuitsUpdating,
    circuitsUpdateError,
    circuitsDeleteError,
    circuitsDeleting,
    customerEntity,
    siteById,
    tempPanelObject,
    panelMetaData,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(panelCircuitActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(buildingSystemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(panelMetaDataActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PanelDetailsPage),
)
