import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import EntityList from './EntityList'
import type { FTRouterMatch } from '../types'
import '../types'
import type { FTEntity, FTFetchEntityList } from '../ducks/types'
import '../ducks/types'
import FeatureValidator from '../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../authorization/features'
import { fieldNameMap } from '../ducks/meters/generation'
import AddCircleOutlineIcon from './Icons/AddCircleOutlineIcon'

export const AddCircleOutlineIconStyled = styled(AddCircleOutlineIcon)`
  font-size: 22px;
  margin-right: 3px;
`
export const AddPanelActionButtonStyled = styled.button`
  cursor: pointer;
  align-items: center;
  background-color: #fff;
  border: 0px;
  position: absolute;
  right: 0;

  color: #337ab7;
  display: flex;
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 0 16px;
  margin-bottom: 10px;
`
type FTProps = {
  fetchAllPanels: FTFetchEntityList
  fetchPanels: FTFetchEntityList
  match: FTRouterMatch
  panelListEntity: FTEntity
  showModalPanelForm3: (...args: Array<any>) => any
}

const AllPanelsTable = (props: FTProps) => {
  const {
    panelListEntity,
    fetchAllPanels,
    fetchPanels,
    showModalPanelForm3,
    match: {
      url,
      params: { siteId },
    },
  } = props
  const fetchDefaults = {
    siteId,
  }
  const headers = [
    {
      fieldName: 'name',
      displayName: 'Panel Name',
      sortable: false,
    },
    {
      fieldName: 'opportunityId',
      displayName: 'Panel Description',
      sortable: false,
    },
    {
      fieldName: 'location',
      displayName: 'Panel Location',
      sortable: false,
    },
    {
      fieldName: 'type',
      displayName: 'Panel Type',
      sortable: false,
    },
    {
      fieldName: 'voltage',
      displayName: 'Panel Voltage',
      sortable: false,
    },
    {
      fieldName: 'amperage',
      displayName: 'Amperage',
      sortable: false,
    },
    {
      fieldName: 'powerSourceLevel',
      displayName: 'Power Source',
      sortable: false,
    },
    {
      fieldName: 'parentPanelName ',
      displayName: 'Upstream Panel',
      sortable: false,
    },
  ]
  React.useEffect(() => {
    fetchPanels(fetchDefaults)
    fetchAllPanels({
      siteId,
    })
  }, [])
  const renderTableRow = React.useCallback(
    ({
      id,
      name,
      location,
      type,
      parentPanelName,
      powerSourceLevel,
      voltage,
      amperage,
      description,
    }) => (
      <tr key={id}>
        <td>
          <Link to={`${url}/${id}`} href={`${url}/${id}`}>
            {name}
          </Link>
        </td>
        <td>{description || '--'}</td>
        <td>{location || '--'}</td>
        <td>{(type && fieldNameMap.get(type)) || '--'}</td>
        <td>{(voltage && fieldNameMap.get(voltage)) || '--'}</td>
        <td>{amperage || '--'}</td>
        <td>{powerSourceLevel || '--'}</td>
        <td>{parentPanelName || '--'}</td>
      </tr>
    ),
    [url],
  )
  return (
    <FeatureValidator
      requireAll={false}
      feature={AuthorizedFeatures.managePanels}
      onInvalid={() => (
        <div>You do not have permission to access the panels</div>
      )}
    >
      <AddPanelActionButtonStyled onClick={showModalPanelForm3}>
        <AddCircleOutlineIconStyled />
        Add Panel
      </AddPanelActionButtonStyled>
      <EntityList
        striped
        entity={panelListEntity}
        loadEntity={fetchPanels}
        loadEntityProps={fetchDefaults}
        tableHeaders={headers}
        renderTableRow={renderTableRow}
        showSearchBar={false}
        showPageSizer={false}
        renderNoResults={() => <div>There are no panels for this site</div>}
      />
    </FeatureValidator>
  )
}

export default withRouter(AllPanelsTable)
