import { Switch, Route } from 'react-router-dom'
import FeatureValidator from '../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../authorization/features'
import MainPage from './MainPage'
import CustomerDetailPage from './CustomerDetailPage'
import SiteDetailPage from './SiteDetailPage'
import MeterDetailPage from './MeterDetailPage'
import UserDetailPage from './UserDetailPage'
import SiteFormPage from './SiteFormPage'
import CustomerFormPage from './CustomerFormPage'
import MeterAssignmentPage from './MeterAssignmentPage'
import type { FTRouterMatch } from '../../types'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import UserFormPage from './UserFormPage'
import PanelDetailsPage from '../PanelConfiguration/PanelDetailsPage'

type FTProps = {
  match: FTRouterMatch
}

const AccountManagement = ({ match: { path } }: FTProps) => (
  <FeatureValidator feature={AuthorizedFeatures.accountManagement}>
    <UnauthorizedRedirect
      from={`${path}/meter-assignment(.*)`}
      to='/not-authorized'
      feature={AuthorizedFeatures.createMeters}
    />
    <UnauthorizedRedirect
      from={`${path}/users/create(.*)`}
      to='/not-authorized'
      feature={AuthorizedFeatures.createUsers}
    />
    <UnauthorizedRedirect
      from={`${path}/sites/:siteId/panels/:panelId/(.*)`}
      to='/not-authorized'
      feature={AuthorizedFeatures.managePanels}
    />
    <Switch>
      <Route path={`${path}/meters/:meterId`} component={MeterDetailPage} />
      <Route path={`${path}/users/create/:step?`} component={UserFormPage} />
      <Route path={`${path}/users/:userId`} component={UserDetailPage} />
      <Route path={`${path}/customers/create`} component={CustomerFormPage} />
      <Route
        path={`${path}/customers/:customerId`}
        component={CustomerDetailPage}
      />
      <Route path={`${path}/sites/create`} component={SiteFormPage} />
      <Route
        path={`${path}/sites/:siteId/panels/create`}
        component={PanelDetailsPage}
      />
      <Route
        path={`${path}/sites/:siteId/panels/:panelId`}
        component={PanelDetailsPage}
      />
      <Route path={`${path}/sites/:siteId`} component={SiteDetailPage} />
      <Route
        path={`${path}/meter-assignment/:step?`}
        component={MeterAssignmentPage}
      />
      <Route path={`${path}`} component={MainPage} />
    </Switch>
  </FeatureValidator>
)

export default AccountManagement
