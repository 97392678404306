import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import type { FTRouterMatch } from '../../../types'
import CreateBatchPage from './CreateBatchPage'
import CustomerListingPage from './CustomerListingPage'
import CustomerOpportunitiesPage from './CustomerOpportunitiesPage'
import OpportunityAnalysisPage from './OpportunityAnalysisPage'
import AddScenarioPage from './AddScenarioPage'
import BatchAnalysis from './BatchAnalysis'
import EditBatch from './EditBatchPage'

type FTProps = {
  match: FTRouterMatch
}
const ProposalsEngineStyled = styled.div`
  * {
    box-sizing: border-box;
  }
`

const ProposalsEngine = (props: FTProps) => {
  const {
    match: { path },
  } = props
  return (
    <ProposalsEngineStyled>
      <Switch>
        <Route
          exact
          path={`${path}/:salesforceCustomerId/create-new-batch`}
          component={CreateBatchPage}
        />
        <Route
          exact
          path={`${path}/:salesforceCustomerId/edit-batch/:batchId`}
          component={EditBatch}
        />
        <Route
          exact
          path={`${path}/:salesforceCustomerId/batch-analysis/:batchId`}
          component={BatchAnalysis}
        />
        <Route
          exact
          path={`${path}/:salesforceCustomerId/opportunities/:opportunityId`}
          component={OpportunityAnalysisPage}
        />
        <Route
          exact
          path={`${path}/:salesforceCustomerId/opportunities/:opportunityId/add-scenario`}
          component={AddScenarioPage}
        />
        <Route
          path={`${path}/:salesforceCustomerId`}
          component={CustomerOpportunitiesPage}
        />
        <Route component={CustomerListingPage} />
      </Switch>
    </ProposalsEngineStyled>
  )
}

export default ProposalsEngine
