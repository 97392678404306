import Styled from './styles'
import Modal from '../Modal'
import Banner from './Banner'
import { OK } from './labels'

type Props = {
  type: string
  heading: string
  text: string
  onClose: () => void
  showFooter: boolean
}
export default function EditBatchAlert({
  type,
  heading,
  text,
  onClose,
  showFooter,
}: Props) {
  return (
    <Modal modalWidth='462px'>
      <Banner type={type} heading={heading} text={text}/>
      {showFooter && (
        <Styled.Footer>
          {type === 'danger' && (
            <Styled.CancelButton type='warning' onClick={onClose}>
              {OK}
            </Styled.CancelButton>
          )}
        </Styled.Footer>
      )}
    </Modal>
  )
}
