import { SyntheticEvent } from 'react'
import Styled from './styles'
import UploadButton from '../../UploadButton'
import Modal from '../Modal'
import Banner from '../Banner'
import Button2 from '../../Button2'
import { CANCEL, RE_UPLOAD, ADD_SCENARIO } from './labels'
import { ADD_SCENARIO_STATES } from '../../../utils/constants'

type Props = {
  type: string
  heading: string
  text: string
  onClose: () => void
  onSuccess: () => void
  onUpload: (e: SyntheticEvent<HTMLInputElement>) => void
  showFooter: boolean
  fileParsingError?: string
}
const { WARNING, DANGER } = ADD_SCENARIO_STATES
export default function ModalAlerts({
  type,
  heading,
  text,
  onClose,
  onSuccess,
  onUpload,
  showFooter,
  fileParsingError,
}: Props) {
  return (
    <Modal modalWidth='462px'>
      <Banner type={type} heading={heading} text={text} fileParsingError={fileParsingError || ''}/>
      {showFooter && (
        <Styled.Footer>
          {type === DANGER && (
            <Styled.CancelButton type='warning' onClick={onClose}>
              {CANCEL}
            </Styled.CancelButton>
          )}
          {type === DANGER ?
            <UploadButton onChange={onUpload}>{RE_UPLOAD}</UploadButton>
          : <Styled.SuccessButton type='primary' onClick={onSuccess}>
              {ADD_SCENARIO}
            </Styled.SuccessButton>
          }
          {type === WARNING && (
            <Button2 type='transparent' onClick={onClose}>
              {CANCEL}
            </Button2>
          )}
        </Styled.Footer>
      )}
    </Modal>
  )
}
