import { useState, useEffect } from 'react'
import styled from 'styled-components'
import CheckCircleIcon from '../Icons/CheckCircleIcon'
import CircleOutlineIcon from '../Icons/CircleOutlineIcon'

const OptionTitle = styled.span`
  margin-left: 5px;
  font-size: 12px;
`
const OptionBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: 50px;
  min-width: 252px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-right: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  ${CheckCircleIcon} {
    font-size: 17px;
  }
  ${CircleOutlineIcon} {
    font-size: 17px;
  }
  ${({ checked, disabled }) => {
    let bgColor = '#FFFFFF'
    let borderColor = '#E0E0E0'
    let color = '#162447'
    let circleColor = '#FFFFFF'

    if (disabled) {
      bgColor = '#E0E0E0'
      color = '#6C6D6E'
      circleColor = '#E0E0E0'
    }

    if (checked) {
      bgColor = '#485DA0'
      borderColor = '#485DA0'
      color = '#FFFFFF'
    }

    return `
          background:${bgColor};    
          border: 1px solid ${borderColor};
          ${OptionTitle}{
               color: ${color}
          }
          ${CheckCircleIcon}{
               color: ${circleColor}
          }
     `
  }}
`
const SelectOptions = styled.div`
  display: flex;
  flex-flow: row wrap;
`
type FTOptionProps = {
  title: string
  checked?: boolean
}

const Option = ({ title, checked, ...rest }: FTOptionProps) => (
  <OptionBox checked={checked} {...rest}>
    {checked ?
      <CheckCircleIcon />
    : <CircleOutlineIcon />}
    <OptionTitle>{`${title}`}</OptionTitle>
  </OptionBox>
)

Option.defaultProps = {
  checked: false,
}
export type FTSelectOptionsDisplayedProps = {
  options: Array<Record<string, any>>
  onChange: (...args: Array<any>) => any
  disableAll?: boolean
}

const renderOptions = ({
  options,
  setSelectedOption,
  selectedOption,
  disableAll,
}) =>
  options.map((option) => {
    const { id, label, isDisabled } = option

    const handleOptionOnClick = () =>
      !disableAll && !isDisabled && setSelectedOption(option)

    return (
      <Option
        id={id}
        checked={selectedOption?.id === id}
        title={label}
        disabled={isDisabled || disableAll}
        onClick={handleOptionOnClick}
      />
    )
  })

renderOptions.defaultProps = {
  disableAll: false,
}

const SelectOptionsDisplayed = (props: FTSelectOptionsDisplayedProps) => {
  const { options, onChange, disableAll } = props
  const [selectedOption, setSelectedOption] = useState(null)
  useEffect(() => {
    if (onChange) {
      onChange(selectedOption)
    }
  }, [selectedOption])
  return (
    <SelectOptions>
      {renderOptions({
        options,
        selectedOption,
        setSelectedOption,
        disableAll,
      })}
    </SelectOptions>
  )
}

export default SelectOptionsDisplayed
