import styled from 'styled-components'
import { colors } from '../../../utils/themes'

export const FileInput = styled.label`
  input[type='file'] {
    display: none;
  }
  width: 100%;
  display: inline-block;
  height: 100%;
  cursor: pointer;
  text-indent: 10px;
  line-height: 3;
`
export const StyledDropdown = styled.div`
  border: 1px solid ${colors.blue2};
  border-radius: 5px;
  padding: 1px 0;
  && .List {
    border-bottom: 1px solid;
    width: 167px;
  }
  && .DropdownList {
    visibility: ${({ hideMenu }) => (hideMenu ? 'hidden' : 'visible')};
  }
  && .title {
    font-size: 12px;
  }
  &&& .item {
    padding: 0;
    height: 35px;
    font-size: 12px;
    position: relative;
    border: 1px solid ${colors.gray3};
    border-bottom: none;
    border-radius: 0;
    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      text-indent: 10px;
    }
  }
`
