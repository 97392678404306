import Tippy from '@tippyjs/react'
import styled from 'styled-components'
import moment from 'moment-timezone'

import StatusIconItem from '../../../../../components/StatusIconItem'

const CellWithToolTip = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  width: 240px;
`
const convertToSiteTimezone = (date: string, siteTimezone: string) => {
  const convertedDate = moment(date).tz(siteTimezone)
  const formattedDate = convertedDate.format('MMMM DD, YYYY hh:mm:ss A z')
  return formattedDate
}
const CellComponent = ({
  original,
  value,
  column: { id },
}: Record<string, any>) => {
  const renderedValue = value ?? '-'
  if (id === 'siteName') {
    return (
      <td>
        <Tippy content={renderedValue} delay={500}>
          <CellWithToolTip>{renderedValue}</CellWithToolTip>
        </Tippy>
      </td>
    )
  }
  if (id === 'onlineStatus') {
    return (
      <td>
        {value === 'loading' ?
          <div>Loading...</div>
        : <StatusIconItem status={value} />}
      </td>
    )
  }
  if (id === 'verified') {
    return <td>{value ? 'Yes' : 'No'}</td>
  }
  if (id === 'firstReportDate' || id === 'mostRecentConfigurationDate') {
    return (
      <td>
        {(value && convertToSiteTimezone(value, original.siteTimezone)) || '-'}
      </td>
    )
  }
  return <td>{renderedValue}</td>
}

export default CellComponent
