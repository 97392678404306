import styled from 'styled-components'
import type { FTWithRouter } from '../../types'
import StyledLink from '../../components/StyledLink'
import DesignLibraryPageWrapper from './DesignLibraryPageWrapper'

type FTProps = FTWithRouter
const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
]
const MainContentStyled = styled.div`
  margin: 0 32px;
`

const DesignLibraryMainPage = ({ match: { path } }: FTProps) => (
  <DesignLibraryPageWrapper
    breadcrumbs={breadcrumbs}
    Title='Design Library'
    handleBackNavigation={null}
  >
    <MainContentStyled>
      <h2>About</h2>
      <p>
        The design library is a collection of design patterns used on this site.
      </p>
      <h2>Components</h2>
      <ul>
        <li>
          <StyledLink href={`${path}/components/accordion`}>
            Accordion
          </StyledLink>
        </li>
        <li>
          <StyledLink href={`${path}/components/buttons`}>Buttons</StyledLink>
        </li>
        <li>
          <StyledLink href={`${path}/components/date-picker`}>
            Date Picker
          </StyledLink>
        </li>
        <li>
          <StyledLink href={`${path}/components/indicator-card`}>
            Indicator Card
          </StyledLink>
        </li>
        <li>
          <StyledLink href={`${path}/components/info-section`}>
            Info Section
          </StyledLink>
        </li>
        <li>
          <StyledLink href={`${path}/components/page-header`}>
            Page Header
          </StyledLink>
        </li>
        <li>
          <StyledLink href={`${path}/components/table`}>Table</StyledLink>
        </li>
        <li>
          <StyledLink
            href={`${path}/components/table-with-sub-rows-custom-header`}
          >
            Table With Sub Rows and Custom Header
          </StyledLink>
        </li>
      </ul>
    </MainContentStyled>
  </DesignLibraryPageWrapper>
)

export default DesignLibraryMainPage
