/* eslint jsx-a11y/anchor-is-valid: 0 */
import classNames from 'classnames'
import { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import FeatureValidator from '../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../authorization/features'
import { NAV_LOGO_ALT, NAV_SIGN_OUT } from '../constants/strings'
import logo from '../redaptive-logo.svg'
import '../types'
import type { FTHistory } from '../types'
import Drawer from './Drawer'
import MenuIcon from './Icons/svg/Menu'
import './Navbar.css'
import { APP_ENV, PROD_ENV } from '../api'

type FTProps<T = Array> = FTHistory & {
  logoutUser: (...args: T[]) => T
}

type FTState = {
  open: boolean
}
export const navbarHeight = 85
const StyledNavbar = styled.nav`
  height: ${navbarHeight}px;
`
class Navbar extends PureComponent<FTProps, FTState> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      open: false,
    }
  }

  onClose = () => {
    this.setState((prev) => ({ open: !prev.open }))
  }

  render() {
    const { logoutUser, location } = this.props
    const { open } = this.state
    const { pathname } = location
    const customersClass = classNames({
      active: pathname.startsWith('/account-management'),
    })
    const reportsClass = classNames({
      active:
        pathname.startsWith('/reports') ||
        pathname.startsWith('/integration/energy-star/'),
    })

    const billingsClass = classNames({
      active: pathname.startsWith('/billing'),
    })
    const proposalOperationsClass = classNames({
      active: pathname.startsWith('/proposal-operations'),
    })
    return (
      <>
        <StyledNavbar className='Navbar'>
          <div className='items'>
            <Link to='/' href='/'>
              <section className='logo'>
                <img src={logo} alt={NAV_LOGO_ALT} />
                <span className='title'>Grand Central</span>
                {APP_ENV === PROD_ENV && (
                  <span className='env-label'>
                    &nbsp;
                    {APP_ENV}
                    &nbsp;
                  </span>
                )}
              </section>
            </Link>
            <section className='links'>
              <ul>
                <FeatureValidator
                  feature={AuthorizedFeatures.accountManagement}
                >
                  <li>
                    <Link href='/customers' to='/' className={customersClass}>
                      Account Management
                    </Link>
                  </li>
                </FeatureValidator>
                <FeatureValidator feature={AuthorizedFeatures.toolsAndReports}>
                  <li>
                    <Link
                      href='/reports'
                      to='/reports'
                      className={reportsClass}
                    >
                      Tools & Reports
                    </Link>
                  </li>
                </FeatureValidator>
                <FeatureValidator feature={AuthorizedFeatures.billing}>
                  <li>
                    <Link
                      href='/billing'
                      to='/billing'
                      className={billingsClass}
                    >
                      Billing
                    </Link>
                  </li>
                </FeatureValidator>
                <FeatureValidator
                  feature={AuthorizedFeatures.proposalOperations}
                >
                  <li>
                    <Link
                      href='/proposal-operations'
                      to='/proposal-operations'
                      className={proposalOperationsClass}
                    >
                      Proposal Operations
                    </Link>
                  </li>
                </FeatureValidator>
                <li>
                  <Link to='/' onClick={logoutUser}>
                    {NAV_SIGN_OUT}
                  </Link>
                </li>
              </ul>
            </section>
          </div>
          <div className='menu' onClick={this.onClose}>
            <MenuIcon />
          </div>
        </StyledNavbar>
        <Drawer
          open={open}
          onClose={this.onClose}
          location={location}
          logoutUser={logoutUser}
        />
      </>
    )
  }
}

export default withRouter(Navbar)
