import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { pathToRegexp } from 'path-to-regexp'
import { Redirect, withRouter } from 'react-router-dom'
import type { FTUserPermissions } from '../models/userPermissions'
import '../models/userPermissions'
import Feature from '../models/feature'

/*
UnauthorizedRedirect specifies a feature, a path prefix, and a redirect path.
If the feature does not match against the user's permissions, and the user is
currently on a path that matches `from` (path-to-regexp), the page will redirect to the
`to` path.
*/
type FTProps = {
  feature: Feature
  requireAll?: boolean
  from: string
  to: string
  loading: boolean
  location: {
    pathname: string
  }
  permissions: FTUserPermissions
}

class UnauthorizedRedirect extends PureComponent<FTProps> {
  static defaultProps = {
    requireAll: false,
  }

  featureIsValid() {
    const { permissions, feature, requireAll } = this.props

    if (requireAll) {
      return feature.allMatchWithPermissions(permissions)
    }

    return feature.anyMatchesWithPermissions(permissions)
  }

  render() {
    const {
      from,
      to,
      loading,
      location: { pathname },
      permissions,
    } = this.props

    if (!pathToRegexp(from).exec(pathname) || loading) {
      return null
    }

    if (this.featureIsValid()) {
      return null
    }

    if (!permissions) {
      return null
    }

    return <Redirect to={to} />
  }
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  loading: state.auth.loading,
})

export default withRouter(connect(mapStateToProps)(UnauthorizedRedirect))
