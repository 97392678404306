// /* eslint-disable no-unused-vars */
import React, { useMemo, useEffect, useState, ReactNode } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { withFormik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { zIndices, valueSetOrDefault } from '../../../utils'
import type { FTProposalScenario } from '../../../ducks/proposals/scenarios'
import type { FTProposalSite } from '../../../ducks/proposals/sites'
import type { FTWithRouter } from '../../../types'
import { colors } from '../../../utils/themes'
import FormFieldNumber from '../../FormFieldNumber'
import FormField from '../../FormField'
import FinalCalculationBox from './CalculationBox'
import Button from '../../Button2'
import Spinner from '../../Spinner'
import {
  actions as scenarioActions,
  selectProposalScenarioEntity,
  goalSeekByFeildName,
  getScenarioEnhancedWithCalculations,
  getScenarioCalculations,
  defaultScenario,
  scenarioFieldInfo,
} from '../../../ducks/proposals/scenarios'
import SelectOptionsDisplayed from '../../SelectOptionsDisplayed'
import InfoCircleOutlineIcon from '../../Icons/InfoCircleOutlineIcon'
import { selectProposalOpportunitySummariesEntity } from '../../../ducks/proposals/opportunitySummaries'

const MAX_ITERATIONS = 200
const ModalSeekMarginStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};

  * {
    box-sizing: border-box;
  }
`
const SeekMarginWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`
const SeekMarginStyled = styled.div`
  background-color: #fff;
  border: 1px solid #dadce0;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const HeaderStyled = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 24px 38px 24px 24px;
`
const HeaderLabelStyled = styled.div`
  font-size: 18px;
  color: #162447;
`
const CloseIconStyled = styled.div`
  color: #464a54;
  cursor: pointer;
  font-size: 24px;
  line-height: 0;
  margin-right: 12px;
  text-decoration: none;
`
const ScenarioViewStyled = styled.div`
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.16),
      rgba(224, 224, 224, 0.16)
    ),
    #ffffff;
  border: 1px solid #e0e0e0;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 12px;
`
const ScenarioLabelStyled = styled.div`
  color: #6c6d6e;
`
const ScenarioNameStyled = styled.div`
  color: #162447;
`
const SeekMarginForm = styled.div`
  padding: 24px 38px 0 24px;
`
const ModalFieldWrapper = styled.div`
  margin-bottom: 16px;
`
const SeekMarginFooter = styled.div`
  height: 56px;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 32px;
`
const SpinnerBoxStyled = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InfoBoxStyled = styled.div`
  background: linear-gradient(
      0deg,
      rgba(242, 169, 28, 0.16),
      rgba(242, 169, 28, 0.16)
    ),
    #ffffff;
  border: 1px solid #f2a91c;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  margin-top: 4px;
`
const InfoBoxDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #162447;
`

const InfoBox = ({ children }: { children: ReactNode }) => (
  <InfoBoxStyled>
    <InfoCircleOutlineIcon
      style={{
        color: '#E7AC4C',
      }}
    />
    {children}
  </InfoBoxStyled>
)

const siteDependentDisabledFields: Record<
  string,
  (arg0: { scenario: FTProposalScenario; site: FTProposalSite }) => boolean
> = {
  vendorSalesTax: ({
    scenario,
    site,
  }: {
    scenario: FTProposalScenario
    site: FTProposalSite
  }) =>
    scenario.dealType === 'EAAS' ?
      ['Wisconsin', 'Tennessee', 'Indiana', 'Massachusetts'].includes(
        site.shippingState,
      )
    : [
        'California',
        'Connecticut',
        'Hawaii',
        'New Mexico',
        'South Dakota',
        'Washington',
        'West Virginia',
      ].includes(site.shippingState),
}
export type FTModalSeekMargin = {
  closeModal: (...args: Array<any>) => any
  scenarioId: string
  onSubmit: (...args: Array<any>) => any
}
type FTProps = FTModalSeekMargin & FTWithRouter

const ModalSeekMargin = (props: FTProps) => {
  const {
    closeModal,
    scenarioId,
    scenariosById,
    initialValues,
    values,
    onSubmit,
    setFieldValue,
    opportunitySummaries,
    site,
  } = props
  const scenario = scenariosById[scenarioId]
  const [isRunSeekButtonDisabled, setIsRunSeekButtonDisabled] = useState(true)
  const [isCalculating, setIsCalculating] = useState(false)
  const [startCalculating, setStartCalculating] = useState(false)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [variableField, setVariableField] = useState(null)
  const [updatedScenario, setUpdatedScenario] = useState(null)
  const [showInfoBox, setShowInfoBox] = useState(false)
  const scenarioCalculations = useMemo(
    () => getScenarioCalculations(values, site),
    [values],
  )
  const [editedCalculatedFields] = useState(
    (() => {
      const enhancedValues = getScenarioEnhancedWithCalculations(
        initialValues,
        [],
        site,
      )
      return Object.keys(scenarioCalculations).filter(
        (fieldName) =>
          initialValues[fieldName] !== enhancedValues[fieldName] &&
          !scenarioFieldInfo[fieldName]?.readOnly,
      )
    })(),
  )
  const opportunitySummary = React.useMemo(
    () =>
      opportunitySummaries.find(
        (opportunity) => opportunity.externalId === scenario.opportunityId,
      ),
    [opportunitySummaries, scenario.opportunityId],
  )
  const VariableFieldOptions = [
    {
      id: 'upFrontPayment',
      label: 'Upfront Payment',
      measuringUnit: '$',
      fieldType: 'currency',
    },
    {
      id: 'energyRetainedSavingsInPercentage',
      label: 'Energy Retained Savings %',
      measuringUnit: '%',
      fieldType: 'percentage',
    },
    {
      id: 'contractTermMonths',
      label: 'Contract Term Months',
      measuringUnit: ' Months',
      fieldType: 'integer',
    },
    {
      id: 'costReduction',
      label: 'Cost Reduction',
      measuringUnit: '$',
      fieldType: 'currency',
    },
  ]
  useEffect(() => {
    if (parseInt(values.npvMarginForGoalSeek, 10) > 100) {
      setFieldValue('npvMarginForGoalSeek', 100)
    }

    if (parseInt(values.npvMarginForGoalSeek, 10) < 0) {
      setFieldValue('npvMarginForGoalSeek', 0)
    }
  }, [values.npvMarginForGoalSeek])
  useEffect(() => {
    if (values.npvMarginForGoalSeek && variableField && isRunSeekButtonDisabled)
      setIsRunSeekButtonDisabled(false)
    if (
      (!values.npvMarginForGoalSeek || !variableField) &&
      !isRunSeekButtonDisabled
    )
      setIsRunSeekButtonDisabled(true)
  }, [values.npvMarginForGoalSeek, variableField])
  useEffect(() => {
    if (!variableField || !isCalculating) return
    const [foundMatch, updatedValues] = goalSeekByFeildName(
      scenario,
      variableField?.id,
      values.npvMarginForGoalSeek,
      editedCalculatedFields,
      'netMarginInPercentage',
      MAX_ITERATIONS,
      site,
    )
    setTimeout(() => {
      if (!foundMatch) setShowInfoBox(true)
      setIsCalculating(false)
      setStartCalculating(false)
      setUpdatedScenario(updatedValues)
    }, 0)
  }, [isCalculating, startCalculating])
  useEffect(() => {
    if (startCalculating) setIsCalculating(true)
  }, [startCalculating])

  const handleRunSeek = () => {
    if (isFirstTime) setIsFirstTime(false)

    if (updatedScenario) {
      setUpdatedScenario(null)
      setShowInfoBox(false)
    } else {
      setStartCalculating(true)
    }
  }

  const handleVariableFieldChange = (selectedOption) => {
    setVariableField(selectedOption)
  }

  const handleCloseModal = () => {
    if (isCalculating || startCalculating) return
    closeModal()
  }

  const handleUpdateValues = () => {
    closeModal()
    onSubmit(updatedScenario)
  }

  return (
    <ModalSeekMarginStyled>
      <SeekMarginWrapperStyled>
        <SeekMarginStyled>
          <div>
            <HeaderStyled>
              <HeaderLabelStyled>Seek Margin</HeaderLabelStyled>
              <CloseIconStyled onClick={handleCloseModal}>
                <span className='ion-android-close' />
              </CloseIconStyled>
            </HeaderStyled>
            <ScenarioViewStyled>
              <ScenarioLabelStyled>Scenario:&nbsp;</ScenarioLabelStyled>
              <ScenarioNameStyled>{scenario.name}</ScenarioNameStyled>
            </ScenarioViewStyled>
            <SeekMarginForm>
              <ModalFieldWrapper>
                <FormFieldNumber
                  name='npvMarginForGoalSeek'
                  placeholder='Enter margin in percentage'
                  title='Net Margin %'
                  isReadOnly={!!(isCalculating || updatedScenario)}
                />
              </ModalFieldWrapper>
              <ModalFieldWrapper>
                <FormField
                  error=''
                  name='selected-variable'
                  title='Select Variable Field'
                  renderField={() => (
                    <SelectOptionsDisplayed
                      onChange={handleVariableFieldChange}
                      options={VariableFieldOptions}
                      disableAll={!!(isCalculating || updatedScenario)}
                    />
                  )}
                />
              </ModalFieldWrapper>
              {updatedScenario && variableField && !isCalculating && (
                <ModalFieldWrapper>
                  <FormField
                    error=''
                    title='Goal Seek Calculation'
                    renderField={() => (
                      <FinalCalculationBox
                        scenario={scenario}
                        updatedScenario={updatedScenario}
                        selectedVariableField={variableField}
                        opportunitySummary={opportunitySummary}
                      />
                    )}
                  />
                </ModalFieldWrapper>
              )}
              {showInfoBox && (
                <InfoBox>
                  <InfoBoxDescription>
                    The Seek Margin tool has completed its
                    {` ${MAX_ITERATIONS} `}
                    iteration limit, please review the results. You can attempt
                    to seek margin again with additional iterations by updating
                    the values and re-running this tool.
                  </InfoBoxDescription>
                </InfoBox>
              )}
            </SeekMarginForm>
            {(isCalculating || startCalculating) && (
              <SpinnerBoxStyled>
                <Spinner size='small' />
              </SpinnerBoxStyled>
            )}
          </div>
          <SeekMarginFooter>
            <Button
              key='cancel-button'
              type='transparent'
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              key='run-seek-margin-button'
              onClick={handleRunSeek}
              disabled={isRunSeekButtonDisabled || isCalculating}
              type='secondary'
            >
              {updatedScenario && 'Re-'}
              Run Seek Margin
            </Button>
            {updatedScenario && !isCalculating && (
              <Button
                key='update-values-button'
                type='primary'
                onClick={handleUpdateValues}
              >
                Update Values in Scenario
              </Button>
            )}
          </SeekMarginFooter>
        </SeekMarginStyled>
      </SeekMarginWrapperStyled>
    </ModalSeekMarginStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(scenarioActions, dispatch) },
})

const mapStateToProps = (state) => {
  const scenariosEntity = selectProposalScenarioEntity(state)
  const opportunitySummariesEntity =
    selectProposalOpportunitySummariesEntity(state)
  const {
    byId: scenariosById,
    meta: { loading: scenariosLoading },
  } = scenariosEntity
  const { items: opportunitySummaries } = opportunitySummariesEntity
  return {
    scenariosById,
    scenariosLoading,
    opportunitySummaries,
  }
}

export default withRouter(
  withFormik({
    mapPropsToValues: ({ opportunity, scenario, site }: FTProps) => ({
      ...defaultScenario,
      ...Object.keys(scenario).reduce(
        (acc, cur) => ({
          ...acc,
          [cur]:
            (
              siteDependentDisabledFields[cur] &&
              siteDependentDisabledFields[cur]({
                scenario,
                site,
              })
            ) ?
              0
            : valueSetOrDefault(scenario[cur]),
        }),
        {},
      ),
      opportunityId: opportunity.id,
      salesforceSiteId: opportunity.salesforceSiteId,
    }),
  })(connect(mapStateToProps, mapDispatchToProps)(ModalSeekMargin)),
)
