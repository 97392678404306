import { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

import Spinner from '../../../components/Spinner'

const SetupProcess = lazy(
  () => import('./SetupProcess/SetupProcess/SetupProcess'),
)
const DetailsScreen = lazy(() => import('./DetailsScreen/DetailsScreen'))
const ListScreenLayout = lazy(() => import('./ListScreen/Layout'))

const Ernesto = () => (
  <div>
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path='/ernesto' component={ListScreenLayout} />
        <Route path='/ernesto/setup-process' component={SetupProcess} />
        <Route path='/ernesto/analysisDetails/:id' component={DetailsScreen} />
      </Switch>
    </Suspense>
  </div>
)

export default Ernesto
