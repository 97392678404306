import {ErrorMessageContainer, ErrorText} from "./styles";
import InfoCircleOutlineIcon from "../../../components/Icons/InfoCircleOutlineIcon";

type TSProps = {
    message: string;
}

export const ErrorMessageDisplay = ({message}:TSProps) => {
    return (
        <ErrorMessageContainer>
            <InfoCircleOutlineIcon/>
        <ErrorText>{message}</ErrorText>
        </ErrorMessageContainer>
    );
};
