import * as React from 'react'
import styled from 'styled-components'
import type { FTRequestStatus } from '../types'
import * as requestStatusStates from '../constants/status'
import Spinner from './Spinner'

type FTProps = {
  fieldLabel: React.ReactNode
  requestStatus: FTRequestStatus
}
const Styles = styled.div`
  .Spinner {
    margin: 0 !important;
  }
`

const RequestStateFieldLabel = (props: FTProps) => {
  const { fieldLabel, requestStatus } = props

  switch (requestStatus) {
    case requestStatusStates.LOADING:
    case '':
      return (
        <Styles>
          {fieldLabel} <Spinner inline size='micro' />
        </Styles>
      )

    default:
      return fieldLabel
  }
}

export default RequestStateFieldLabel
