// @ see https://github.com/tannerlinsley/react-table/tree/v6
import { PureComponent } from 'react'
import ReactTable from 'react-table-v6'
import styled from 'styled-components'
import { naturallySortEmptyLast } from '../../utils'
import Paginator from '../Paginator'
import PageSizeSelector from '../EntityList/PageSizeSelector'

const cellPadding = '10px'
const HeaderWrapperStyled = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`
const SortIconsStyled = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  padding-left: 1px;
  padding-right: 3px;
`

const Divider = styled.div`
  height: 23px;
  margin-top: 25px;
`
const SortIconDesc = styled.span.attrs({
  className: 'ion-ios-arrow-down',
})``
const SortIconAsc = styled.span.attrs({
  className: 'ion-ios-arrow-up',
})`
  position: relative;
  top: 5px;
`

const HeaderStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 10px;
  margin-bottom: 10px;
`

export const RedaptiveReactTableStyles = styled.div`
  .ReactTable {
    border: 0;
    overflow-x: auto;

    .rt-tbody .rt-tr-group.rt-tr-group-expanded {
      border: 1px solid #ccc;
    }

    .rt-thead {
      &.-header {
        box-shadow: none;
      }

      &.-filters {
        .rt-th {
          padding-top: 0;
        }
      }

      .rt-tr {
        text-align: left;
      }

      .rt-th {
        border-right: 0;
        line-height: 1.5;
        padding: ${cellPadding} 0 ${cellPadding} ${cellPadding};

        &.-sort-asc {
          ${SortIconAsc} {
            top: -8px;
          }

          ${SortIconDesc} {
            display: none;
          }
        }

        &.-sort-desc {
          ${SortIconAsc} {
            top: 0;
          }

          ${SortIconAsc} {
            display: none;
          }
        }

        &.-sort-asc,
        &.-sort-desc {
          box-shadow: none;
        }

        .rt-resizable-header-content {
          height: 100%;
        }
      }
    }

    .rt-td {
      padding-left: ${cellPadding};
      padding-right: 0;
    }

    .rt-th {
      color: #4a4a4a;
      font-size: 13px;
      font-weight: 600;
      white-space: normal;
    }
    &.-config-history {
      .rt-tbody .rt-td {
        border: 0px none transparent;
      }
      .rt-tr {
        background-color: #f7f7f7 !important;
        margin-top: 7px;
      }
      .rt-thead {
        .rt-tr {
          background-color: transparent !important;
        }
      }
      .rt-tbody .rt-tr-group.rt-tr-group-expanded {
        border: 0px none transparent;
        background-color: #f7f7f7 !important;
        margin-top: 7px;
      }
    }
    &.-config-history-detail {
      .rt-tbody .rt-td {
        border: 0px none transparent;
      }
      .rt-tr {
        background-color: transparent;
        margin-top: 7px;
      }
      .rt-thead {
        display: none;
        .rt-tr {
          background-color: transparent;
        }
      }
      .rt-tbody .rt-tr-group {
        border: 0px none transparent;
      }
      .rt-tbody .rt-tr-group.rt-tr-group-expanded {
        border: 0px none transparent;
        background-color: transparent;
        margin-top: 7px;
      }
    }
    &.-config-history-detail-subtable {
      .rt-tr {
        margin-top: 0px;
        &:has(.highlight) {
          background-color: #fff0cb !important;
        }
      }
      .rt-thead {
        display: none;
      }
      &.-striped {
        .rt-tr {
          &.-odd {
            background-color: rgba(0, 0, 0, 0.03) !important;
            &:has(.highlight) {
              background-color: #fff0cb !important;
            }
          }
        }
      }
    }
  }
`
type FTProps = {
  columns: Array<Object>
  data: Array<Object>
  filterable?: boolean
  resizable?: boolean
  sortable?: boolean
  SubComponent?: any
  className?: string
  defaultExpanded?: Array<Object>
  showCustomPagination?: boolean
  pageSize?: number
  pageIndex?: number
  gotoPage?: any
  setPageSize?: any
  totalResults?: number
  paginationSize?: Array<number>
  hideAllPagination?: boolean
}

type FTFilter = {
  id: string
  pivotId?: string
  value: string
}
export const booleanFilter = (filter: FTFilter, row: Record<string, any>) => {
  const id = filter.pivotId || filter.id
  return row[id] !== undefined ?
      (row[id] && filter.value.toUpperCase().startsWith('Y')) ||
        (!row[id] && filter.value.toUpperCase().startsWith('N'))
    : true
}
export const exactFilter = (filter: FTFilter, row: Record<string, any>) => {
  const id = filter.pivotId || filter.id
  return row[id] !== undefined ? String(row[id]) === filter.value : true
}

const caseInsensitiveFuzzyFilter = (
  filter: FTFilter,
  row: Record<string, any>,
) => {
  const id = filter.pivotId || filter.id
  return row[id] !== undefined ?
      String(row[id]).toUpperCase().includes(filter.value.toUpperCase())
    : true
}

const getTrGroupProps = (state, rowInfo = {}) => {
  const { expanded: expandedRowsState } = state
  const { viewIndex = null } = rowInfo
  const isExpanded = viewIndex !== null && expandedRowsState[viewIndex]
  return {
    className: isExpanded ? 'rt-tr-group-expanded' : '',
  }
}

/**
 * Adds sort elements to the table headers.
 */
const enhanceColumns = ({
  columns,
  sortable: tableSortable,
}: {
  columns: Array<Record<string, any>>
  sortable?: boolean
}) =>
  tableSortable ?
    columns.map<Record<string, any>>((column) => {
      const { expander, Header, sortable: columnSortable = true } = column
      // If this is an expander column or the column is not sortable...
      return (
          expander || !columnSortable // Returns the column without sorting.
        ) ?
          {
            ...column,
            Header: () => (
              <HeaderWrapperStyled>
                <div>{Header}</div>
              </HeaderWrapperStyled>
            ),
          } // Returns the column with sorting.
        : {
            ...column,
            Header: () => (
              <HeaderWrapperStyled>
                <div>{Header}</div>
                <SortIconsStyled>
                  <SortIconAsc className='ion-ios-arrow-up' />
                  <SortIconDesc className='ion-ios-arrow-down' />
                </SortIconsStyled>
              </HeaderWrapperStyled>
            ),
          }
    })
  : columns.map<Record<string, any>>((column) => {
      const { Header } = column
      return {
        ...column,
        Header: () => (
          <HeaderWrapperStyled>
            <div>{Header}</div>
          </HeaderWrapperStyled>
        ),
      }
    })

export default class RedaptiveReactTable extends PureComponent<FTProps> {
  static defaultProps = {
    filterable: true,
    resizable: true,
    sortable: true,
    SubComponent: null,
    defaultExpanded: [],
    className: '-striped -highlight',
    showCustomPagination: false,
    pageIndex: 0,
  }

  render() {
    const {
      columns,
      data,
      filterable,
      resizable = true,
      sortable = true,
      defaultExpanded = [],
      SubComponent,
      className,
      showCustomPagination,
      pageIndex = 0,
      gotoPage,
      pageSize = 10000,
      totalResults = 10000,
      setPageSize,
      paginationSize,
      hideAllPagination,
    } = this.props
    const totalPages = Math.ceil(totalResults / pageSize)
    const canNextPage = pageIndex < totalPages - 1
    const canPreviousPage = pageIndex > 0
    return (
      <RedaptiveReactTableStyles>
        {showCustomPagination && (
          <HeaderStyled>
            <PageSizeSelector
              onUpdate={setPageSize}
              total={totalResults}
              currentSize={pageSize}
              paginationSize={paginationSize}
              hideAllPagination={hideAllPagination}
            />
          </HeaderStyled>
        )}
        <ReactTable
          className={className}
          collapseOnDataChange={false}
          columns={enhanceColumns({
            columns,
            sortable,
          })}
          data={data}
          defaultFilterMethod={caseInsensitiveFuzzyFilter}
          defaultSortMethod={naturallySortEmptyLast}
          filterable={filterable}
          getTrGroupProps={getTrGroupProps}
          minRows={1}
          resizable={resizable}
          pageSize={pageSize}
          showPagination={false}
          sortable={sortable}
          SubComponent={SubComponent}
          defaultExpanded={defaultExpanded}
        />
        <Divider>
          {showCustomPagination && (
            <Paginator
              total={totalPages}
              current={pageIndex + 1 || 1}
              next={canNextPage ? 'Next' : null}
              prev={canPreviousPage ? 'Previous' : null}
              browseToPage={gotoPage}
            />
          )}
        </Divider>
      </RedaptiveReactTableStyles>
    )
  }
}
