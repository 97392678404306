import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  MONTHS,
  YEARS_TO_DATE,
  getDaysInMonth,
  getItemsByIdFromItems,
  makeListSelectorItem,
} from '../../utils'
import FormField from '../FormField'
import ListSelector from '../ListSelector'

const ListSelectorStyled = styled(ListSelector)``
const FormFieldStyled = styled(FormField)`
  ${ListSelectorStyled} {
    display: inline-block;
  }

  ${ListSelectorStyled}:not(:first-child) {
    margin-left: 30px;
    width: 78px;
  }

  ${ListSelectorStyled}:first-child {
    width: 146px;
  }

  ${ListSelectorStyled}:nth-child(3) {
    width: 72px;
  }
`
const months = moment.months().map((m, i) => ({
  id: (i + 1).toString().padStart(2, '0'),
  name: m,
}))
const monthItemsById = getItemsByIdFromItems(months)
const years = YEARS_TO_DATE.map(({ id, name }) => ({
  id: id.toString(),
  name,
}))

type Props = {
  description?: string
  error?: string
  id: string
  label?: string
  setValue: (value: string) => void
  subtitle?: string
  value: string
}

const ThreeDropdownDatePicker = ({
  description,
  error,
  id,
  label,
  setValue,
  subtitle,
  value: inputValue,
}: Props) => {
  const date = inputValue ? moment(inputValue).utc() : undefined
  const [year, setYear] = useState(date?.format('YYYY') ?? '')
  const [month, setMonth] = useState(date?.format('MM') ?? '')
  const [day, setDay] = useState(date?.format('DD') ?? '')

  const areDatePartsSet = year || month || day
  useEffect(() => {
    if (areDatePartsSet) {
      setValue(`${year}-${month}-${day}T00:00:00+00:00`)
    } else {
      setValue('')
    }
  }, [year, day, month])
  const daysInMonth = getDaysInMonth(months[0].name).map(
    ({ id: dayId, name }) => ({
      id: dayId.toString().padStart(2, '0'),
      name,
    }),
  )

  return (
    <FormFieldStyled
      title={label}
      name={id}
      subtitle={subtitle}
      showRequired={!!error}
      requiredText={error}
      renderDescription={description ? () => description : undefined}
      renderField={() => (
        <div>
          <ListSelectorStyled
            items={months}
            selectedItem={monthItemsById[month]}
            notSetLabelText='Month'
            notSetItemText='--'
            notSetItemValue=''
            updateValue={({ value }) => {
              setMonth(value)
            }}
            showRequired={!!areDatePartsSet && !month}
          />
          <ListSelectorStyled
            items={daysInMonth}
            selectedItem={makeListSelectorItem(day)}
            notSetLabelText='Day'
            notSetItemText='--'
            notSetItemValue=''
            updateValue={({ value }) => {
              setDay(value)
            }}
            showRequired={!!areDatePartsSet && !day}
          />
          <ListSelectorStyled
            items={years}
            selectedItem={makeListSelectorItem(year)}
            notSetLabelText='Year'
            notSetItemText='--'
            notSetItemValue=''
            updateValue={({ value }) => {
              setYear(value)
            }}
            showRequired={!!areDatePartsSet && !year}
          />
        </div>
      )}
    />
  )
}

export default ThreeDropdownDatePicker
