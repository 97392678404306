import React, { useState } from 'react'
import { FileInput, StyledDropdown } from './styles'
import DropdownMenuNew from '../../DropdownMenuNew'
import { colors } from '../../../utils/themes'
import { DROPDOWN_TEXT, SECOND_OPTION_TEXT, UPLOAD_FILE_TEXT } from './labels'
import ArrowUp from '../../Icons/svg/ArrowUp'
import ArrowDown from '../../Icons/svg/ArrowDown'

type Props = {
  onFileAdded: (e: React.SyntheticEvent<HTMLInputElement>) => void
  onRedirect: () => void
  disabled: boolean | null | undefined
}
export default function AddScenarioDropdown({
  onFileAdded,
  onRedirect,
  disabled = false,
}: Props) {
  const [isFileExplorerClosed, setFileExplorerClosed] = useState(null)
  const [hideMenu, setHideMenu] = useState(false)

  const handleProposalInjection = (
    e: React.SyntheticEvent<HTMLLabelElement>,
  ) => {
    e.stopPropagation()
  }

  const handleAddManualScenario = (): void => {
    onRedirect()
  }

  const onFocus = () => {
    document.body.onfocus = null
    setTimeout(() => {
      setFileExplorerClosed(true)
      setHideMenu(false)
    }, 350)
  }

  const onClick = () => {
    setFileExplorerClosed(null)
    setHideMenu(true)
    document.body.onfocus = onFocus
  }

  const ProposalInjection = (
    <FileInput htmlFor='file-input-id'>
      <input
        type='file'
        onInput={onFileAdded}
        id='file-input-id'
        onClick={onClick}
      />
      {UPLOAD_FILE_TEXT}
    </FileInput>
  )
  const options = [
    {
      label: ProposalInjection,
      onClick: handleProposalInjection,
    },
    {
      label: SECOND_OPTION_TEXT,
      onClick: handleAddManualScenario,
    },
  ]
  return (
    <StyledDropdown hideMenu={hideMenu}>
      <DropdownMenuNew
        title={DROPDOWN_TEXT}
        actions={options}
        borderColor={colors.blue2}
        textColor={colors.blue2}
        style={{
          border: 'none',
        }}
        openStateIcon={hideMenu ? <ArrowDown /> : <ArrowUp />}
        closeStateIcon={<ArrowDown />}
        overrideToggleState={isFileExplorerClosed}
        disabled={disabled}
      />
    </StyledDropdown>
  )
}
