import { Switch, Route, Redirect } from 'react-router-dom'
import type { FTWithRouter } from '../../../types'
import ButtonsComponentPage from './ButtonsComponentPage'
import IndicatorCardComponentPage from './IndicatorCardComponentPage'
import InfoSectionComponentPage from './InfoSectionComponentPage'
import PageHeaderComponentPage from './PageHeaderComponentPage'
import TableComponentPage from './TableComponentPage'
import TableSubRowsCustomHeaderComponentPage from './TableSubRowsCustomHeaderComponentPage'
import DatePickerComponentPage from './DatePickerComponentPage'
import AccordionComponentPage from './AccordionComponentPage'

type FTProps = FTWithRouter

const Components = ({ match: { path } }: FTProps) => (
  <Switch>
    <Route path={`${path}/buttons`} component={ButtonsComponentPage} />
    <Route
      path={`${path}/indicator-card`}
      component={IndicatorCardComponentPage}
    />
    <Route path={`${path}/info-section`} component={InfoSectionComponentPage} />
    <Route path={`${path}/page-header`} component={PageHeaderComponentPage} />
    <Route path={`${path}/date-picker`} component={DatePickerComponentPage} />
    <Route path={`${path}/accordion`} component={AccordionComponentPage} />
    <Route path={`${path}/table`} component={TableComponentPage} />
    <Route
      path={`${path}/table-with-sub-rows-custom-header`}
      component={TableSubRowsCustomHeaderComponentPage}
    />
    <Redirect to='/design-library' />
  </Switch>
)

export default Components
