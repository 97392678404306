import type { Moment } from 'moment'
import moment from 'moment'
import { ComponentType, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import CalendarIcon from './Icons/CalendarIcon'
import DatePickerDropdown, { DropdownInnerStyles } from './DatePickerDropdown'
import useComponentToggle from '../hooks/useComponentToggle'

type FTProps = {
  end: Moment
  options: Array<any>
  period: string
  getProgramStartDate: () => void
  rightAligned?: boolean
  start: Moment
  text: string
  updateDateRange: (...args: Array<any>) => any
}
const DropdownRightAlignedStyles = css`
  right: 0;
`
export const DatePickerStyles: ComponentType<{
  rightAligned: boolean
}> = styled.div`
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  height: 40px;
  position: relative;
  text-align: right;
  user-select: none;
  width: 250px;

  ${DropdownInnerStyles} {
    top: 10px;
    ${({ rightAligned }) => rightAligned && DropdownRightAlignedStyles}
  }
`
const DatePickerRangeStyles = styled.div`
  > span {
    font-size: 12px;
    font-weight: 300;
  }
`
const DatePickerTextStyles = styled.span``
export const DatePickerTextWrapperStyled = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`
export default ({
  end = moment(),
  options = [],
  period = '',
  getProgramStartDate = () => {},
  start = moment(),
  text = '',
  updateDateRange = () => null,
  rightAligned = false,
}: FTProps) => {
  const componentRef = useRef(null)
  const toggleRef = useRef(null)
  const { isComponentVisible, setIsComponentVisible } = useComponentToggle({
    componentRef,
    toggleRef,
  })

  const hideDropdown = () => {
    setIsComponentVisible(false)
  }

  const updateDateRangeSelection = useCallback(
    (value) => {
      hideDropdown()

      if (updateDateRange) {
        updateDateRange(value)
      }
    },
    [updateDateRange],
  )
  return (
    <DatePickerStyles className='DatePicker' rightAligned={rightAligned}>
      <DatePickerRangeStyles>
        <DatePickerTextWrapperStyled ref={toggleRef}>
          <DatePickerTextStyles>{text}</DatePickerTextStyles>
          <CalendarIcon size='16' />
        </DatePickerTextWrapperStyled>
        {isComponentVisible && (
          <div ref={componentRef}>
            <DatePickerDropdown
              hideDropdown={hideDropdown}
              options={options}
              period={period}
              start={start}
              end={end}
              getProgramStartDate={getProgramStartDate}
              updateDateRange={updateDateRangeSelection}
            />
          </div>
        )}
      </DatePickerRangeStyles>
    </DatePickerStyles>
  )
}
