import styled from 'styled-components'
import Button2 from '../Button2'
import { colors } from '../../utils/themes'

export const WrapperStyles = styled.div`
  .Modal {
    .box {
      box-sizing: border-box;
      margin: 2% auto;
      padding: 16px;
      width: 462px;
      top: 30%;
    }
  }
  .header .title {
    margin-bottom: 10px;
  }
`
export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.blue3};
`
export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.blue3};
  margin: 20px 0 12px 0;
`
export const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin: 10px 0;
`
export const InputWrapper = styled.div`
  width: 97%;
  height: 36%;
  position: relative;
  margin: 10px 0;
  input[type='text'] {
    padding-left: 10px;
  }
`
export const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 9px 0;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  &:focus {
    outline: none !important;
    border: 1px solid #c7c7c7;
  }
  color: ${(props) => props.error && colors.red6};
`
export const Footer = styled.div`
  margin: 25px 0 0 0;
`
export const CancelButton = styled(Button2)`
  width: 120px;
  height: 36px;
  margin: 0;
`
export const SaveButton = styled(Button2)`
  background-color: #0193d7;
  color: #fff;
  width: 120px;
  height: 36px;
  margin-left: 30px;
  padding-left: ${(props) => props.loading && '35px'};
`
export const SearchClear = styled.div`
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #0193d7;
  cursor: pointer;
`
export const Error = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.red6};
`
export const OppName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #4a4a4a;
  margin: 17px 0 8px 0;
`
export const OppText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #4a4a4a;
`
export const ConfirmationFooter = styled.div`
  text-align: center;
`
export const NoButton = styled(Button2)`
  width: 120px;
  height: 36px;
`
export const YesButton = styled(Button2)`
  width: 120px;
  height: 36px;
  background-color: #0193d7;
  color: ${colors.white};
  padding-left: ${(props) => props.loading && '35px'};
`
export const ConfirmationContent = styled.div`
  width: 300px;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  margin: 30px 0;
`
export const ConfirmationModal = styled.div`
  .Modal {
    .box {
      height: 168px;
      box-sizing: border-box;
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .header .title {
    // margin-bottom: 20px;
  }
`
