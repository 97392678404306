import { capitalCase } from 'capital-case'
import { METER_SOURCES } from '../../ducks/sites'
import { getItemsByIdFromItems } from '../../utils'
import FormField from '../FormField'
import FormSection from '../FormSection'
import ListSelector from '../ListSelector'
import ThreeDropdownDatePicker from './ThreeDropdownDatePicker'

type Props = {
  isEdit: boolean
  values: Record<string, string>
  touched: Record<string, boolean>
  errors: Record<string, string>
  setFieldValue: (fieldName: string, value: string) => void
}

export default function MeterDataSection(props: Props) {
  const { isEdit, values, touched, errors, setFieldValue } = props

  const meterSources = METER_SOURCES.map((source) => ({
    id: source,
    name: capitalCase(source),
  }))
  const meterSourcesById = getItemsByIdFromItems(meterSources)

  return (
    <FormSection
      title='Meter Data'
      description='The following fields are optional, however they must be completed in order to display meter data in the Energy Dashboard.'
    >
      <FormField
        requiredText='*'
        title='Electric Meter Type'
        name='source'
        showRequired={touched.source && !!errors.source}
        renderField={() => (
          <div>
            {isEdit && <div>{capitalCase(values.source)}</div>}
            {!isEdit && (
              <ListSelector
                items={meterSources}
                selectedItem={meterSourcesById[values.source]}
                unsettable={false}
                updateValue={({ value }) => {
                  setFieldValue('source', value)
                }}
                showRequired={!!errors.source}
              />
            )}
          </div>
        )}
      />

      <ThreeDropdownDatePicker
        label='Electric Start Date'
        id='ingestionDataStartElectricity'
        subtitle='- Optional'
        error={errors.ingestionDataStartElectricity}
        description='Electric meter data will be shown from this date forward'
        value={values.ingestionDataStartElectricity}
        setValue={(value) =>
          setFieldValue('ingestionDataStartElectricity', value)
        }
      />

      <ThreeDropdownDatePicker
        label='Natural Gas Start Date'
        id='ingestionDataStartNaturalGas'
        subtitle='- Optional'
        error={errors.ingestionDataStartNaturalGas}
        description='Natural gas meter data will be shown from this date forward'
        value={values.ingestionDataStartNaturalGas}
        setValue={(value) =>
          setFieldValue('ingestionDataStartNaturalGas', value)
        }
      />

      <ThreeDropdownDatePicker
        label='Water Start Date'
        id='ingestionDataStartWater'
        subtitle='- Optional'
        error={errors.ingestionDataStartWater}
        description='Water meter data will be shown from this date forward'
        value={values.ingestionDataStartWater}
        setValue={(value) => setFieldValue('ingestionDataStartWater', value)}
      />
    </FormSection>
  )
}
