import { RefObject, TextareaHTMLAttributes } from 'react'
import styled from 'styled-components'
import ErrorMessage from './ErrorMessage'

type FTProps = {
  cols?: number
  editDotColor?: string
  editDotLeft?: string
  editDotRight?: string
  edited?: boolean
  error?: string
  field?: Record<string, any>
  // To support Formik field object
  form?: Record<string, any>
  // To support Formik form object
  hideEditDotOnHover?: boolean
  inputRef?: RefObject<HTMLTextAreaElement>
  rows?: number
} & TextareaHTMLAttributes<HTMLTextAreaElement>
export const TextAreaStyled = styled.div`
  position: relative;
`
export const TextAreaInnerStyled = styled.textarea`
  background-color: #fff;
  border: ${({ error }) => (error ? '1px solid #D0021B' : '1px solid #E0E0E0')};
  border-radius: 4px;
  color: #464a54;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 20px;
  padding: 10px 13px;
  width: 100%;

  &:focus,
  &:hover {
    background-color: white;
    outline: none;
    border: 1px solid #c0c0c0;
  }

  &:focus {
    border: 1px solid #485da0;
  }
`
export const Marker = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 10px;
  display: ${({ edited }) => (edited ? 'inline-block' : 'none')};
  height: 6px;
  left: ${({ left }) => left};
  position: absolute;
  right: ${({ right }) => right};
  top: 15px;
  width: 6px;

  ${TextAreaInnerStyled} {
    &:focus + &,
    &:hover + & {
      display: ${({ hideOnHover }) => hideOnHover && 'none'};
    }
  }
`
export const TextareaErrorMessageStyled = styled(ErrorMessage)`
  display: inline-block;
  position: absolute;
  left: 330px;
  top: -11px;

  &:before {
    content: '*\\a0 ';
  }
`

const TextArea = (props: FTProps) => {
  const {
    edited,
    field,
    form: _,
    editDotColor,
    editDotLeft,
    editDotRight,
    error,
    hideEditDotOnHover,
    inputRef,
    ...rest
  } = props
  return (
    <TextAreaStyled>
      <TextAreaInnerStyled {...rest} {...field} ref={inputRef} />
      <Marker
        edited={edited}
        color={editDotColor}
        left={editDotLeft}
        right={editDotRight}
        hideOnHover={hideEditDotOnHover}
      />
      {error && <TextareaErrorMessageStyled message={error} />}
    </TextAreaStyled>
  )
}

TextArea.defaultProps = {
  edited: false,
  editDotColor: '#c70d08',
  editDotLeft: '',
  editDotRight: '',
  error: '',
  hideEditDotOnHover: true,
  field: {},
  form: {},
  inputRef: null,
  rows: 3,
  cols: 20,
}
export default TextArea
