import { withFormik } from 'formik'
import validationSchema from './validationSchema'
import type { FTProposalCustomerGlobalInputs } from '../../../../ducks/proposals/customerGlobalInputs'
import type { FTGlobalInputsFormProps } from './GlobalInputsForm'
import GlobalInputsForm from './GlobalInputsForm'

const proposalCustomerGlobalInputsFormDefaults = {
  annualTransferRate: '',
  capexDealMarginTarget: '',
  contingency: '',
  annualInsurancePremium: '',
  measurementAndVerificationCostAsPerProjectCosts: '',
  measurementAndVerificationCostPerMeter: '',
  measurementAndVerificationMethodology: 'METER_OPS',
  operationsAndMaintenanceBasis: 'NPV_REVENUE',
  operationsAndMaintenanceRate: '',
  projectManagementLaborAndFee: '',
  rebateHaircut: 0,
  referralFeeBasis: 'CONTRACT_VALUE',
  referralFeeInPercentage: '',
  term: '',
  maintenanceObligation: 'No Maintenance',
  contractType: 'EAAS',
  energyRetainedSavings: '',
  maintenanceRetainedSavings: '',
  measurementAndVerificationCostAsPerProjectCostsInPercentage: '',
}
const FormikForm = withFormik({
  validationSchema,

  handleSubmit(values, { props }) {
    const { putProposalCustomerGlobalInputs } = props
    putProposalCustomerGlobalInputs(values)
  },

  mapPropsToValues({
    customerGlobalInputs,
  }: {
    customerGlobalInputs: FTProposalCustomerGlobalInputs
  }) {
    const {
      annualTransferRate,
      capexDealMarginTarget,
      contingency,
      annualInsurancePremium,
      measurementAndVerificationCostAsPerProjectCosts,
      measurementAndVerificationCostPerMeter,
      measurementAndVerificationMethodology,
      operationsAndMaintenanceBasis,
      operationsAndMaintenanceRate,
      projectManagementLaborAndFee,
      rebateHaircut,
      referralFeeBasis,
      referralFeeInPercentage,
      term,
      contractType,
      maintenanceObligation,
      energyRetainedSavings,
      maintenanceRetainedSavings,
      measurementAndVerificationCostAsPerProjectCostsInPercentage,
    } = Object.keys(proposalCustomerGlobalInputsFormDefaults).reduce(
      (acc, cur) =>
        customerGlobalInputs[cur] ?
          { ...acc, [cur]: customerGlobalInputs[cur] }
        : { ...acc, [cur]: proposalCustomerGlobalInputsFormDefaults[cur] },
      {},
    )
    return {
      annualTransferRate,
      capexDealMarginTarget,
      contingency,
      annualInsurancePremium,
      measurementAndVerificationCostAsPerProjectCosts,
      measurementAndVerificationCostPerMeter,
      measurementAndVerificationCostAsPerProjectCostsInPercentage,
      measurementAndVerificationMethodology,
      operationsAndMaintenanceBasis,
      operationsAndMaintenanceRate,
      projectManagementLaborAndFee,
      rebateHaircut,
      referralFeeBasis,
      referralFeeInPercentage,
      term,
      maintenanceRetainedSavings,
      contractType,
      energyRetainedSavings,
      maintenanceObligation,
    }
  },
})(GlobalInputsForm)

const GlobalInputsFormFormik = (props: FTGlobalInputsFormProps) => (
  <FormikForm {...props} />
)

export default GlobalInputsFormFormik
