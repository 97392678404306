import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router'
import { Metric } from '../ProposalOperations/ProposalsEngine/CustomerOpportunitiesPage'
import { colors } from '../../utils/themes'
import {
  ConfirmationScreen,
  ConfirmationTitleStyled,
  LoadingSpinnerWrapperStyled,
  MonthPickerWrapperStyles,
  TableWrapperStyled,
} from './styles'
import RedaptiveReactTable7 from '../../components/RedaptiveReactTable7'
import MonthPicker from '../../components/MonthPicker'
import {
  actions as energyStarIntegrationActions,
  energyStarSubmissionsEntity,
} from '../../ducks/energyStarIntegration'
import { actions as modalActions } from '../../ducks/modal'
import { selectors as authSelectors } from '../../ducks/auth'
import Spinner from '../../components/Spinner'

const SummaryPageStyled = styled.div``
const MetricsWrapper = styled.div`
  padding: 0px 40px;
  gap: 32px;
  display: flex;
  > div {
    flex: initial;
    height: 90px;
    width: 280px;
    border: none;
    > div {
      width: auto;
      min-height: 70px;
    }
  }
  ${Metric} {
    padding: 100px;
  }
`

interface TSProps {
  selectedPageId: string
}

const SummaryPage: FC<PropsFromRedux & RouteComponentProps & TSProps> = ({
  actions,
  energyStarSubmissionsLoading,
  selectedPageId,
  summaryData,
}: PropsFromRedux & RouteComponentProps & TSProps) => {
  const { allTime, monthly } = summaryData
  const [rows, setRows] = useState(monthly || [])
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    moment().subtract(1, 'months').format('YYYY-MM'),
  )
  const [showMonthPickerDropDown, setShowMonthPickerDropDown] = useState(false)

  useEffect(() => {
    actions.fetchSummaryAllTime({
      yearMonth: selectedYearMonth,
    })
  }, [selectedPageId])

  useEffect(() => {
    if (monthly) setRows(monthly)
  }, [monthly])

  useEffect(() => {
    actions.fetchSummaryMonthly({
      yearMonth: selectedYearMonth,
    })
  }, [selectedYearMonth])

  const TableColumns = useMemo(
    () => [
      {
        accessor: 'customerName',
        Header: 'Customer',
        hideSettings: false,
        id: 'customerName',
      },
      {
        accessor: 'enabledSites',
        Header: 'Sites Enabled for Submission',
        hideSettings: false,
        id: 'enabledSites',
      },
      {
        accessor: 'electricSummary',
        Header: 'Submitted for Electric',
        hideSettings: false,
        id: 'electricSummary',
      },
      {
        accessor: 'waterSummary',
        Header: 'Submitted for Water',
        hideSettings: false,
        id: 'waterSummary',
      },
      {
        accessor: 'gasSummary',
        Header: 'Submitted for Gas',
        hideSettings: false,
        id: 'gasSummary',
      },
      {
        accessor: 'sitesNotSubmittedSuccessfully',
        Header: 'Not Submitted/Failed',
        hideSettings: false,
        id: 'sitesNotSubmittedSuccessfully',
      },
    ],
    [],
  )

  const HeaderDownloadButtons = useCallback(
    () => (
      <MonthPickerWrapperStyles>
        <MonthPicker
          activeMonth={selectedYearMonth}
          showMonthPickerDropDown={showMonthPickerDropDown}
          setShowMonthPickerDropDown={setShowMonthPickerDropDown}
          onCancel={() => {
            setShowMonthPickerDropDown(false)
          }}
          onSubmit={(month) => {
            setSelectedYearMonth(month.format('YYYY-MM'))
            setShowMonthPickerDropDown(false)
          }}
        />
      </MonthPickerWrapperStyles>
    ),
    [selectedYearMonth, showMonthPickerDropDown],
  )

  if (energyStarSubmissionsLoading) {
    return (
      <LoadingSpinnerWrapperStyled>
        <Spinner size='medium' />
      </LoadingSpinnerWrapperStyled>
    )
  }

  return (
    <SummaryPageStyled>
      <MetricsWrapper>
        <Metric
          color={colors.blue2}
          metric={allTime?.enabledCustomers ?? 0}
          title='ENABLED CUSTOMERS'
        />
        <Metric
          color={colors.green}
          metric={allTime?.totalSites ?? 0}
          title='TOTAL SITES FOR CUSTOMERS'
        />
        <Metric
          color={colors.orange}
          metric={allTime?.enabledSites ?? 0}
          title='SITES ENABLED FOR CUSTOMERS'
        />
        <Metric
          color={colors.blue4}
          metric={allTime?.failedSubmissions ?? 0}
          title='FAILED SUBMISSIONS TO DATE'
        />
      </MetricsWrapper>
      {rows.length ?
        <TableWrapperStyled>
          <RedaptiveReactTable7
            columns={TableColumns}
            HeaderActions={HeaderDownloadButtons}
            data={rows}
            defaultSort={[
              {
                id: 'customer',
              },
            ]}
            enableColumnHiding
            enablePagination
            filterable
            showSelectAllLabel
            globalFilterable={false}
            fixedHeader
          />
        </TableWrapperStyled>
      : <>
          <HeaderDownloadButtons />
          <ConfirmationScreen>
            <ConfirmationTitleStyled>No Submissions</ConfirmationTitleStyled>
            <h2>No submissions generated yet for this month.</h2>
          </ConfirmationScreen>
        </>
      }
    </SummaryPageStyled>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(energyStarIntegrationActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const entity = energyStarSubmissionsEntity(state)
  const { summaryData } = entity
  const {
    meta: { loading: energyStarSubmissionsLoading },
  } = entity
  const authGroups = authSelectors.selectGroups(state)
  const {
    auth: { permissions },
  } = state
  return {
    authGroups,
    summaryData,
    energyStarSubmissionsLoading,
    permissions,
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SummaryPage)
