import { PureComponent } from 'react'
import styled from 'styled-components'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import ErrorMessage from '../ErrorMessage'
import FormField from '../FormField'
import Input from '../Input'
import {
  getAddressFromPlace,
  fetchTimezoneId,
} from '../../ducks/sites/googleMaps'
import { getCountryData } from '../../ducks/sites'
import { zIndices } from '../../utils'

const PlacesSearch = styled.div`
  position: relative;
`
const PlacesResultList = styled.div`
  position: absolute;
  z-index: ${zIndices.GooglePlacesSearchPlacesResult};
  top: 66px;
  width: 100%;
  border: 1px solid #c7c7c7;
  border-top: 0;
  box-sizing: border-box;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-color: ${({ focused }) => focused && '#0193d7'};
`
const SearchResult = styled.div`
  padding: 10px;
  background-color: #ffffff;
  user-select: none;
  &:only-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`
const PlaceResult = styled(SearchResult)`
  cursor: pointer;
  background-color: ${({ active }) => active && '#fafafa'};
`
const StyledErrorMessage = styled(ErrorMessage)`
  margin: 5px 0px 10px 0px;
`
const Styles = styled.div``
type FTProps = {
  setFieldValue: (...args: Array<any>) => any
  validateForm: (...args: Array<any>) => any
}
type FTAutoCompleteProps = {
  getInputProps: (...args: Array<any>) => any
  getSuggestionItemProps: (...args: Array<any>) => any
  suggestions: Array<Record<string, any>>
  loading: boolean
}
type FTState = {
  address: string
  apiError: string
  noResults: boolean
  focused: boolean
  populating: boolean
}
export default class GooglePlacesSearch extends PureComponent<
  FTProps,
  FTState
> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      address: '',
      apiError: '',
      noResults: false,
      focused: false,
      populating: false,
    }
  }

  onAutoCompleteError = (error: string) => {
    if (error === 'ZERO_RESULTS') {
      this.setState({
        noResults: true,
      })
      return
    }

    this.setState({
      apiError:
        'Something went wrong in completing your request, please try again.',
    })
  }

  onBlur = () => {
    this.setState({
      focused: false,
    })
  }

  onFocus = () => {
    this.setState({
      focused: true,
    })
  }

  onPlacesAutocompleteRef = (component: PlacesAutocomplete) => {
    // Component autoclears results onBlur, resulting in unnecessary additional
    // API queries. Override the onBlur to preserve results and allow refocus.
    if (!component) return
    // eslint-disable-next-line no-param-reassign
    component.handleInputOnBlur = this.onBlur
  }

  handleChange = (address: string) => {
    this.setState({
      address,
      noResults: false,
      apiError: '',
    })
  }

  handleError = (error: Error) => {
    this.setState({
      apiError: `${error.message}`,
      populating: false,
    })
  }

  handleSelect = async (lookup: string) => {
    this.setState({
      address: '',
      populating: true,
    })
    const { setFieldValue, validateForm } = this.props
    let place
    let country
    let state
    let address1
    let city
    let postalCode
    let latLng
    let timezoneId

    try {
      ;[place] = await geocodeByAddress(lookup)
      latLng = await getLatLng(place)
    } catch (error) {
      this.handleError(new Error('Unable to set address.'))
    }

    try {
      const address = getAddressFromPlace(place)
      ;({ country, address1, city, state, postalCode } = address)
    } catch (error) {
      this.handleError(error)
      return
    }

    try {
      timezoneId = await fetchTimezoneId(place)
    } catch (error) {
      this.handleError(error)
      return
    }

    const { language, currency: currencyCode } = getCountryData(country)
    const locale = `${language}_${country}`
    setFieldValue('address1', address1)
    setFieldValue('city', city)
    setFieldValue('country', country)
    setFieldValue('currencyCode', currencyCode)
    setFieldValue('locale', locale)
    setFieldValue('postalCode', postalCode)
    setFieldValue('state', state)
    setFieldValue('timezone', timezoneId)
    setFieldValue('latLng', latLng)
    validateForm()
    this.setState({
      populating: false,
    })
  }

  renderPlacesSearch = (props: FTAutoCompleteProps) => {
    const { getInputProps, suggestions, getSuggestionItemProps, loading } =
      props
    const { noResults, focused, populating } = this.state
    const showResultList =
      focused && (loading || noResults || suggestions.length > 0)
    const inputProps = getInputProps({
      placeholder: 'Search Places ...',
      className: 'location-search-input',
    })
    return (
      <PlacesSearch>
        <div>
          <FormField
            title='Address Search'
            loading={populating}
            renderField={() => (
              <Input
                {...inputProps}
                onFocus={this.onFocus}
                expanded={showResultList}
              />
            )}
          />
        </div>

        {showResultList && (
          <PlacesResultList focused={focused}>
            {loading && <SearchResult>Loading...</SearchResult>}
            {noResults && <SearchResult>No Results Found</SearchResult>}
            {!loading &&
              !noResults &&
              suggestions.map((suggestion) => {
                const className =
                  suggestion.active ?
                    'suggestion-item--active'
                  : 'suggestion-item'
                const suggestionProps = getSuggestionItemProps(suggestion, {
                  className,
                })
                return (
                  <div {...suggestionProps}>
                    <PlaceResult active={suggestion.active}>
                      {suggestion.description}
                    </PlaceResult>
                  </div>
                )
              })}
          </PlacesResultList>
        )}
      </PlacesSearch>
    )
  }

  render() {
    const { apiError } = this.state
    return (
      <Styles>
        <PlacesAutocomplete
          ref={this.onPlacesAutocompleteRef}
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          onError={this.onAutoCompleteError}
        >
          {this.renderPlacesSearch}
        </PlacesAutocomplete>
        <StyledErrorMessage message={apiError} />
      </Styles>
    )
  }
}
