import { takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { consoleApiUrl, defaultHeaders, financeApiUrl } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { isVariantActive } from '../utils'

export type FTFetchLightingControlsTypeGuideAssetUploadJobAction = {
  jobId: string
}
export type FTFetchLightingControlsTypeGuideAssetUploadJobMockAction = {
  jobId: string
  mockAxios: Record<string, any>
}
export type FTPostLightingControlsTypeGuideAssetAction = {
  file: any
}
type FTLightingControlsTypeGuideAssetUploadError = {
  message: string
}
type FTLightingControlsTypeGuideAssetErrors =
  Array<FTLightingControlsTypeGuideAssetUploadError>
export type FTLightingControlsTypeGuideAssetState = {
  job: {
    id: string
    progress: number
    reason: string
    status: string
  }
  errors: FTLightingControlsTypeGuideAssetErrors
  isPosting: boolean
  isCheckingJob: boolean
}
type FTLightingControlsTypeGuideAssetStateRoot = {
  lightingControlsTypeGuideAsset: FTLightingControlsTypeGuideAssetState
}
export const types = {
  FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB:
    'FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB',
  FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB_ERROR:
    'FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB_ERROR',
  FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS:
    'FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS',
  POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET:
    'POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET',
  POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_ERROR:
    'POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_ERROR',
  POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_SUCCESS:
    'POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_SUCCESS',
  RESET_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_STATE:
    'RESET_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_STATE',
  RESET_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_STATE_ERROR:
    'RESET_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_STATE_ERROR',
}
export const selectLightingControlsTypeGuideAsset = (
  state: FTLightingControlsTypeGuideAssetStateRoot,
): FTLightingControlsTypeGuideAssetState => state.lightingControlsTypeGuideAsset
export const initialState: FTLightingControlsTypeGuideAssetState = {
  job: {
    id: '',
    progress: 0,
    reason: '',
    status: 'INPROGRESS',
  },
  errors: [],
  isPosting: false,
  isCheckingJob: false,
}

const lightingControlsTypeGuideAssetReducer = (
  state: FTLightingControlsTypeGuideAssetState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB:
      return { ...state, isCheckingJob: true }

    case types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS:
      return { ...state, isCheckingJob: false, job: action.job }

    case types.POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET:
      return {
        ...state,
        isPosting: true,
        job: {
          id: '',
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_ERROR:
      return { ...state, isPosting: false, errors: action.errors }

    case types.POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_SUCCESS:
      return {
        ...state,
        isPosting: false,
        job: {
          id: action.payload,
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.RESET_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_STATE:
      return initialState

    default:
      return state
  }
}

export default lightingControlsTypeGuideAssetReducer
export const actions = {
  postLightingControlsTypeGuideAsset: (
    params: FTPostLightingControlsTypeGuideAssetAction,
  ) => ({
    type: types.POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET,
    ...params,
  }),
  postLightingControlsTypeGuideAssetError: (
    errors: FTLightingControlsTypeGuideAssetErrors,
  ) => ({
    type: types.POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_ERROR,
    errors,
  }),
  fetchLightingControlsTypeGuideAssetUploadJob: (
    params: FTFetchLightingControlsTypeGuideAssetUploadJobAction,
  ) => ({
    type: types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchLightingControlsTypeGuideAssetUploadJobMock: (
    params: FTFetchLightingControlsTypeGuideAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchLightingControlsTypeGuideAssetUploadJobMockError: (
    params: FTFetchLightingControlsTypeGuideAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB,
    ...params,
  }),
  resetLightingControlsTypeGuideAssetState: () => ({
    type: types.RESET_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_STATE,
  }),
}
type FTPostLightingControlsTypeGuideAssetException = {
  name: string
  errors: FTLightingControlsTypeGuideAssetErrors
  toString: string
}

const PostLightingControlsTypeGuideAssetException = ({
  errors,
}): FTPostLightingControlsTypeGuideAssetException => ({
  name: 'PostLightingControlsTypeGuideAssetError',
  errors,
  toString: 'PostLightingControlsTypeGuideAssetError',
})

const handlePostLightingControlsTypeGuideAssetError = (
  error: Record<string, any>,
) => {
  const { response } = error || {}
  const { status, data } = response || {}

  if (status === 400) {
    const dataFormatted = data instanceof Array ? data : [data]
    const errors = data ? dataFormatted : initialState.errors
    throw PostLightingControlsTypeGuideAssetException({
      errors,
    })
  } else {
    handleAxiosError(error)
  }
}

// API
export class API {
  static postLightingControlsTypeGuideAsset({ file }: Record<string, any>) {
    const url = `${financeApiUrl()}/admin/csv/ltg-control-type`
    const formData = new FormData()
    formData.set('file', file)

    if (isVariantActive('lighting-as-builts-asset-manager-mock-error')) {
      const mockAxios = axios.create()
      const mock = new MockAdapter(mockAxios, {
        delayResponse: 500,
      })

      mock
        .onPost(url)
        .reply(400, [
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
        ])
        .onAny()
        .passThrough()
      return mockAxios({
        method: 'post',
        url,
        data: formData,
        headers: defaultHeaders(),
      })
        .then(({ data: { jobId } }) => jobId)
        .catch(handlePostLightingControlsTypeGuideAssetError)
    }

    if (
      isVariantActive('lighting-as-builts-asset-manager-mock') ||
      isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
    ) {
      return Promise.resolve('mockJobId').then((data) => data)
    }

    return axios({
      method: 'post',
      url,
      data: formData,
      headers: defaultHeaders(),
    })
      .then(({ data: { jobId } }) => jobId)
      .catch(handlePostLightingControlsTypeGuideAssetError)
  }

  static fetchLightingControlsTypeGuideAssetUploadJob({
    jobId,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return axios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchLightingControlsTypeGuideAssetUploadJobMock({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchLightingControlsTypeGuideAssetUploadJobMockError({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        status: headers['job-status'] || 'ERROR',
        reason:
          headers['job-reason'] || 'MeasureCode with id F43ILLAA not found',
      }))
      .catch(handleAxiosError)
  }
}

function* postLightingControlsTypeGuideAssetSaga(
  params: FTPostLightingControlsTypeGuideAssetAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.postLightingControlsTypeGuideAsset, params)
    yield put({
      type: types.POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_SUCCESS,
      payload,
    })
  } catch (error) {
    if (
      error.name &&
      error.name === 'PostLightingControlsTypeGuideAssetError'
    ) {
      const { errors } = error
      yield put(actions.postLightingControlsTypeGuideAssetError(errors))
    } else {
      yield handleSagaError(
        'POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_ERROR_GENERAL',
        error,
      )
    }
  }
}

function* fetchLightingControlsTypeGuideAssetUploadJobSaga(
  params: FTFetchLightingControlsTypeGuideAssetUploadJobAction,
): Generator<any, void, any> {
  try {
    let job

    if (isVariantActive('lighting-as-builts-asset-manager-mock')) {
      job = yield call(
        API.fetchLightingControlsTypeGuideAssetUploadJobMock,
        params,
      )
    } else if (
      isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
    ) {
      job = yield call(
        API.fetchLightingControlsTypeGuideAssetUploadJobMockError,
        params,
      )
    } else {
      job = yield call(API.fetchLightingControlsTypeGuideAssetUploadJob, params)
    }

    yield put({
      type: types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS,
      job,
    })
  } catch (error) {
    yield handleSagaError(
      types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB_ERROR,
      error,
    )
  }
}

export const sagas = [
  takeEvery(
    types.POST_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET,
    postLightingControlsTypeGuideAssetSaga,
  ),
  takeEvery(
    types.FETCH_LIGHTING_CONTROLS_TYPE_GUIDE_ASSET_UPLOAD_JOB,
    fetchLightingControlsTypeGuideAssetUploadJobSaga,
  ),
]
