import * as Yup from 'yup'
import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Form, FastField, withFormik } from 'formik'
import Spinner from './Spinner'
import Input from './Input'
import Title from './Title'
import Description from './Description'
import Button from './Button'
import ErrorMessage from './ErrorMessage'

const StyledButton = styled(Button)`
  margin-top: 50px;
`
const StyledInput = styled(Input)`
  width: 447px;
`
const Styles = styled.div`
  width: 570px;
  min-width: 570px !important;

  b {
    font-weight: 600;
  }

  ${StyledButton} {
    display: inline-block;
  }

  ${StyledButton} + ${StyledButton} {
    margin-left: 40px;
  }

  & .Spinner {
    display: inline-block;
    vertical-align: sub;
  }
`
type FTProps = {
  formError: string
  loading: boolean
  customer: Record<string, any>
  history: Record<string, any>
  dirty: boolean
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
}) // https://github.com/redux-saga/redux-saga/issues/161

class CustomerForm extends PureComponent<FTProps> {
  goBack = () => this.props.history.goBack()

  render() {
    const { formError, loading, customer, dirty } = this.props
    let title = 'Add Customer'
    let description = (
      <div>
        <b>This form will create a new customer.</b>
        {
          ' Once it is created, you can add new sites and grant users access to the customer.'
        }
      </div>
    )
    let submitText = 'Add Customer'

    if (customer) {
      const customerName = customer.name || '(no name)'
      title = `Edit ${customerName}`
      description = (
        <div>
          <b>This form will update the customer&apos;s name.</b>
        </div>
      )
      submitText = 'Edit Customer'
    }

    return (
      <Styles>
        <Title>
          {title} {loading && <Spinner size='tiny' />}
        </Title>
        <Description>{description}</Description>

        <div>
          <ErrorMessage message={formError} />

          <Form>
            <div>
              <b>Customer Name</b>
            </div>
            <FastField
              aria-label='name'
              name='name'
              type='text'
              component={StyledInput}
            />
            <div>
              <StyledButton
                type='button'
                aria-label='cancel'
                onClick={this.goBack}
              >
                Cancel
              </StyledButton>
              <StyledButton
                primary
                aria-label='sign in'
                type='submit'
                disabled={loading || !dirty}
              >
                {submitText}
              </StyledButton>
            </div>
          </Form>
        </div>
      </Styles>
    )
  }
}

export default withRouter(
  withFormik({
    validationSchema,

    handleSubmit(values, { props }) {
      const { onSubmit } = props
      onSubmit(values)
    },

    mapPropsToValues: () => ({
      name: '',
    }),
  })(CustomerForm),
)
