import styled from 'styled-components'
import Input from './Input'
import MetaText from './MetaText'

const FieldTitleStyles = styled.div`
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 7px;
`
const MetaTextStyles = styled.div`
  padding-bottom: 6px;
`
type FTProps = {
  fieldTitle: string
  description?: string
  className?: string
  fieldComponent?: React$AbstractComponent<any, any>
}

const StackedInput = ({
  fieldTitle,
  description,
  className,
  fieldComponent: FieldComponent = Input,
  ...fieldProps
}: FTProps) => (
  <div className={className}>
    <FieldTitleStyles>{fieldTitle}</FieldTitleStyles>
    <MetaTextStyles>
      <MetaText>{description}</MetaText>
    </MetaTextStyles>
    <div>
      <FieldComponent {...fieldProps} />
    </div>
  </div>
)

StackedInput.defaultProps = {
  fieldComponent: Input,
  description: '',
  className: '',
}
export default StackedInput
