import { ComponentType, PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import type { FTMessages, FTMessageIconLookup } from '../ducks/messages'
import { actions as messagesActions } from '../ducks/messages'
import IonIcon from './IonIcon'
import { zIndices } from '../utils'
import { colors } from '../utils/themes'

const MessageIcon = styled.div`
  height: 20px;
  position: relative;
  left: 2px;
  top: -7px;
  width: 20px;
  .icon.mi {
    left: -3px;
    top: 6px;
  }
`
const MessageIconContainer = styled.div`
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex: none;
  justify-content: center;
  position: relative;
  width: 40px;
`
const MessageText = styled.div`
  color: #fff;
  font-family: 'Public Sans', sans-serif;
  font-weight: 600;
  letter-spacing: 0.02em;
  padding: 12px 12px 12px 0;
`
const MessageTextContainer = styled.div`
  flex-grow: 1;
`
const MessageCloseIcon = styled.div`
  cursor: pointer;
  height: 20px;
  left: 5px;
  position: relative;
  top: -4px;
  width: 20px;
  &:hover {
    opacity: 0.7;
  }
`
const MessageCloseIconContainer = styled.div`
  align-items: center;
  flex: none;
  justify-content: center;
  position: relative;
  display: flex;
  width: 62px;
`
const Message = styled.div`
  align-content: space-between;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  box-shadow: 0 0 9px 0 rgba(52, 65, 89, 0.26);
  display: flex;
  font-size: 14px;
  user-select: none;
  width: 100%;
`
const MessagesWrapperStyled: ComponentType<{
  position: string
}> = styled.div`
  display: flex;
  justify-content: center;
  position: ${({ position }) => position || 'absolute'};
  top: 120px;
  width: 100%;
  z-index: ${zIndices.Messages};
  * {
    box-sizing: border-box;
  }
`
const MessagesStyled = styled.div`
  min-width: 600px;

  ${Message} + ${Message} {
    margin-top: 10px;
  }
`
type FTProps = {
  messages: FTMessages
  actions: Record<string, any>
}
const icons: FTMessageIconLookup = {
  info: {
    className: 'icon mi mi-info-outline',
    title: 'Info',
    backgroundColor: '#0193D7',
  },
  error: {
    className: 'ion-alert-circled',
    title: 'Error',
    backgroundColor: '#C70D08',
  },
  success: {
    className: 'ion-checkmark',
    title: 'Success',
    backgroundColor: colors.green,
  },
  warning: {
    className: 'icon mi mi-info-outline',
    title: 'Warning',
    backgroundColor: '#F5B300',
  },
}

class Messages extends PureComponent<FTProps> {
  componentWillUnmount() {
    this.props.actions.hideMessages()
  }

  hideMessage = (messageId: string) => () => {
    const { actions } = this.props
    actions.hideMessage(messageId)
  }

  render() {
    const { messages } = this.props
    const { position } = messages[0] || ''

    if (messages.length === 0) {
      return ''
    }

    return (
      <MessagesWrapperStyled position={position}>
        <MessagesStyled>
          {messages.map((value) => (
            <Message
              key={value.messageId}
              backgroundColor={icons[value.type].backgroundColor}
            >
              <MessageIconContainer
                backgroundColor={icons[value.type].backgroundColor}
              >
                <MessageIcon>
                  <IonIcon
                    iconClass={icons[value.type].className}
                    color='#FFF'
                    fontSize='20px'
                    title={icons[value.type].title}
                  />
                </MessageIcon>
              </MessageIconContainer>
              <MessageTextContainer>
                <MessageText>
                  <div>
                    <b>{value.title}</b>
                  </div>
                  {value.description && <div>{value.description}</div>}
                </MessageText>
              </MessageTextContainer>
              <MessageCloseIconContainer>
                <MessageCloseIcon onClick={this.hideMessage(value.messageId)}>
                  <IonIcon
                    iconClass='ion-close'
                    color='#fff'
                    fontSize='17px'
                    title='Close Message'
                  />
                </MessageCloseIcon>
              </MessageCloseIconContainer>
            </Message>
          ))}
        </MessagesStyled>
      </MessagesWrapperStyled>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(messagesActions, dispatch),
})

const mapStateToProps = ({ messages: { messages } }) => ({
  messages,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages),
)
