import axios from 'axios'
import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { handleSagaError } from '../../sagas/utils'
import { roundToX } from '../../utils'
import { API_SOURCE, scenarioFieldsPrecision } from '../../utils/constants'
import { FTState } from '../circuits'
// Flow types
type FTFeasibleValue = number | null | undefined
export type FTImportedScenarioPayload = {
  materialCosts?: FTFeasibleValue
  laborCosts?: FTFeasibleValue
  otherCost?: FTFeasibleValue
  vendorSalesTax?: FTFeasibleValue
  metricTonsOfCo2AvoidedOverEul?: FTFeasibleValue
  metricTonsOfCo2AvoidedAnnually?: FTFeasibleValue
  metricTonsOfCo2AvoidedContractTerm?: FTFeasibleValue
  savedDollarsOnAvoidedRecPurchase?: FTFeasibleValue
  lightingReplaceFixtureCount?: FTFeasibleValue
  lightingRetrofitKitCount?: FTFeasibleValue
}
// Action Types
export const types = {
  REQUEST_IMPORTED_SCENARIO_FIELDS: 'REQUEST_IMPORTED_SCENARIO_FIELDS',
  SAVE_IMPORTED_SCENATRIO_FIELDS: 'SAVE_IMPORTED_SCENARIO_FIELDS',
  SAVE_IMPORTED_SCENATRIO_FIELDS_ERROR: 'SAVE_IMPORTED_SCENATRIO_FIELDS_ERROR',
}

function roundOffImportedValues(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in scenarioFieldsPrecision) {
    if (obj[key] !== null || typeof obj[key] !== 'undefined') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = roundToX(obj[key], scenarioFieldsPrecision[key])
    }
  }

  return obj
}

// API
const API = {
  postImportedScenario: (formData, opportunityId) => {
    const url = `${consoleBaseUrl()}/proposal/api/vendor-proposal-scenario/upload/${opportunityId}`
    return axios({
      method: 'post',
      url,
      data: formData,
      headers: defaultHeaders(API_SOURCE.scenario),
    })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
}
// Actions
export const actions = {
  saveImportedScenarioDetails: (data: FTImportedScenarioPayload) => ({
    type: types.SAVE_IMPORTED_SCENATRIO_FIELDS,
    payload: data,
  }),
  requestImportedScenarioFields: (props) => ({
    type: types.REQUEST_IMPORTED_SCENARIO_FIELDS,
    ...props,
  }),
}

// Sagas
function* postImportedScenario({ type, ...params }): Generator<any, void, any> {
  try {
    const { opportunityId } = params
    const formData = new FormData()
    formData.set('file', params?.file)
    const response = yield call(
      API.postImportedScenario,
      formData,
      opportunityId,
    )
    const todayDate = moment().format('MM-DD-YYYY')
    const scenarioId = `${params?.name} - ${todayDate}`
    yield put({
      type: types.SAVE_IMPORTED_SCENATRIO_FIELDS,
      payload: roundOffImportedValues({ ...response, name: scenarioId }),
    })
  } catch (e) {
    yield handleSagaError(types.SAVE_IMPORTED_SCENATRIO_FIELDS_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.REQUEST_IMPORTED_SCENARIO_FIELDS, postImportedScenario),
]
// State
export const initialState = {
  loading: false,
  error: '',
  data: {},
  file: null,
}

// Reducers
function importedSenario(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_IMPORTED_SCENARIO_FIELDS:
      return { ...state, error: '', loading: true }

    case types.SAVE_IMPORTED_SCENATRIO_FIELDS:
      return { ...state, data: action.payload, error: '', loading: false }

    case types.SAVE_IMPORTED_SCENATRIO_FIELDS_ERROR:
      return { ...state, error: action.error, loading: false }

    default:
      return state
  }
}

// Selectors
export const selectProposalImportedScenario = (
  state: FTState,
): FTImportedScenarioPayload => state.entities?.importedScenario
export default importedSenario
