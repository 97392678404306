import styled, { css } from 'styled-components'
import {
  TabPaneSelectorStyled,
  TabPaneStyled,
} from '../../../components/Tabs/TabPane2'
import {
  ReactTableWrapperStyles,
  ReactTableTopSectionStyles,
  ReactTableStyled,
  TdInnerStyled,
  TdStyled,
  tableBorder,
} from '../../../components/RedaptiveReactTable7'
import Title from '../../../components/Title'
import {
  IndicatorCardStyled,
  IndicatorCardTitleStyled,
} from '../../../components/IndicatorCard'
import { colors } from '../../../utils/themes'
import CircleOutlineIcon from '../../../components/Icons/CircleOutlineIcon'
import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon'
import RadioButtonCheckedIcon from '../../../components/Icons/RadioButtonCheckedIcon'
import GradingIcon from '../../../components/Icons/GradingIcon'
import RefreshIcon from '../../../components/Icons/RefreshIcon'
import ColorIndicator from '../../../components/ColorIndicator'
import Checkbox from '../../../components/Checkbox'
import MapsUgcOutlinedIcon from '../../../components/Icons/MapsUgcOutlinedIcon'
import CheckIcon from '../../../components/Icons/CheckIcon'
import SendIcon from '../../../components/Icons/SendIcon'
import Button2 from '../../../components/Button2'

export const BillingThisMonthMainPageStyled = styled.div`
  ${TabPaneStyled} {
    padding-bottom: 0;
  }

  ${TabPaneSelectorStyled} {
    padding-left: 36px;
  }
  margin-top: -30px;
`
export const HeaderStyled = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 25px;
`
export const ISRProcessorPageStyled = styled.div`
  margin-top: -30px;
  width: 100%;
`;
export const ISRFileUploadSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin:20px 32px;
`;
export const IconContainer = styled.span`
    padding-right: 2px;
`;
export const ConfirmationTitleStyled = styled(Title)`
  color: #3e5caa;
  font-family: 'Public Sans', sans-serif;
  font-size: 24px;
  line-height: 36px;
`
export const FilterCardsWrapperStyled = styled.div`
  display: flex;
  justify-content: space-around;
`
export const FilterCardStyled: ComponentType<{
  selected: boolean
}> = styled.div`
  cursor: pointer;
  width: 250px;

  ${IndicatorCardStyled} {
    background-color: ${({ selected }) => (selected ? colors.blue : '#FFFFFF')};
    color: ${({ selected }) => (selected ? '#FFFFFF' : '#6C6D6E')};
  }
  ${IndicatorCardTitleStyled} {
    color: ${({ selected }) => (selected ? '#FFFFFF' : '#6C6D6E')};
  }
`
export const CircleOutlineIconStyled = styled(CircleOutlineIcon)`
  color: #e5e5e5;
  cursor: pointer;
`
export const CheckCircleIconStyled = styled(CheckCircleIcon)`
  cursor: pointer;
  color: #9dbd79;
`
export const RadioButtonCheckedIconStyled = styled(RadioButtonCheckedIcon)`
  color: #9dbd79;
`
export const FilterCardResultsWrapperStyled: ComponentType<{
  selected: boolean
}> = styled.div`
  align-items: baseline;
  color: ${({ selected }) => (selected ? '#FFFFFF' : colors.blue5)};
  display: flex;
  font-family: 'Montserrat', sans-serif;
  justify-content: flex-end;
`
export const FilterCardTotalStyled = styled.strong`
  font-size: 18px;
  margin-right: 5px;
`
export const FilterCardTotalTextStyled = styled.p`
  font-size: 15px;
`
export const TableWrapperStyled = styled.div`
  margin-top: 20px;

  ${ReactTableWrapperStyles} {
    overflow-x: auto;
  }
`
export const DataCaptureStatusStatusIndicatorStyled = styled(ColorIndicator)`
  margin-right: 5px;
`
export const ActionsCellWrapperStyled = styled.div`
  display: flex;
`
export const ActionIconStyled = styled.div`
  width: 28px;
  height: 28px;
`
export const RefreshIconStyled = styled(RefreshIcon)`
  cursor: pointer;
  color: ${colors.blue};
`
export const GradingIconStyled = styled(GradingIcon)`
  cursor: pointer;
  color: ${colors.blue};
`
export const GradingRedIconStyled = styled(GradingIcon)`
  cursor: pointer;
  color: ${colors.red2};
`
export const ActionButtonStyled: ComponentType<{
  action: string
}> = styled.button`
  background-color: ${({ action }) =>
    action === 'ESTIMATE' ? '#9DBD79' : '#485DA0'};
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  color: #ffffff;
  cursor: pointer;
  padding: 5px;
  width: 100%;
`
export const BackgroundImageActive = css`
  background-image: radial-gradient(circle, #9dbd79 2px, transparent 2px);
`
export const VerticalEllipsisStyled: ComponentType<{
  active: boolean
}> = styled.div`
  background-image: radial-gradient(circle, #4a4a4a 2px, transparent 2px);
  background-size: 100% 33.33%;
  cursor: pointer;
  height: 20px;
  width: 20px;

  ${({ active }) => active && BackgroundImageActive}
`
export const getActionsPopMenuYPosition = (
  ellipsisClickedPosition,
  popupHeight,
) => {
  if (
    ellipsisClickedPosition.innerHeight - ellipsisClickedPosition.y >
    popupHeight
  ) {
    return `${ellipsisClickedPosition.y}px`
  }

  return 'unset'
}
export const ActionsPopupMenuStyled: ComponentType<{
  show: boolean
  ellipsisClickedPosition?: Record<string, any>
  popupHeight?: number
  headerBulkAction?: boolean
}> = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #dadce0;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  height: fit-content;
  padding: 8px;
  position: fixed;
  right: 50px;

  top: ${({ headerBulkAction, ellipsisClickedPosition, popupHeight }) =>
    headerBulkAction ? '120px' : (
      getActionsPopMenuYPosition(ellipsisClickedPosition, popupHeight)
    )};
  bottom: ${({ ellipsisClickedPosition, popupHeight }) =>
    (
      ellipsisClickedPosition.innerHeight - ellipsisClickedPosition.y >
      popupHeight
    ) ?
      'unset'
    : '10px'};
  width: 250px;
  z-index: 1;
`
export const ActivityHeaderStyled = styled.div`
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.2),
      rgba(224, 224, 224, 0.2)
    ),
    #ffffff;
  border-bottom: 1px solid #e0e0e0;
  color: #6c6d6e;
  display: flex;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  height: 38px;
  line-height: 14px;
  padding: 15px;
  text-transform: uppercase;
`
export const ActionsContainerStyled: ComponentType<{
  flagged: boolean
}> = styled.div`
  align-items: center;
  color: ${({ flagged }) => (flagged ? '#C02F2F' : '#162447')};
  cursor: pointer;
  display: flex;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin: 10px;
`
export const PageHeaderDropdownActionsStyled = styled.div`
  align-items: center;
  cursor: pointer;
  color: ${colors.blue5};
  display: flex;

  > button {
    color: ${colors.blue5};
    margin: 0;
    padding: 0;
    width: 100px;
  }
`
export const ConfirmationScreen = styled.div`
  padding: 100px;
  text-align: center;
`
export const TabCountStyled = styled.div`
  background-color: ${colors.blue5};
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  height: 20px;
  margin-left: 6px;
  min-width: 36px;
  text-align: center;
`
export const TabCheckedStyled = styled.div`
  background-color: #9dbd79;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  height: 20px;
  margin-left: 6px;
  min-width: 36px;
  text-align: center;

  ${CheckIcon} {
    font-size: 20px;
    line-height: 0;
    position: relative;
    top: 5px;
  }
`
export const CommentsIconStyled = styled(MapsUgcOutlinedIcon)`
  color: #485da0;
  cursor: pointer;
  margin: 0 5px;
`
export const CommentsIconGrayStyled = styled(MapsUgcOutlinedIcon)`
  color: #6c6d6e;
`
export const QuantityStyles = css`
  padding-left: 30px;
`
export const ActionsColumnStyles = css`
  z-index: 1;
  position: sticky;
  padding-right: 20px;
`
export const QuantityCellStyled = styled.div`
  padding-left: 30px;
`
export const SelectedActionText: ComponentType<{
  color: string
}> = styled.div`
  color: ${({ color }) => color};
  ${QuantityCellStyled};
`
export const SendIconStyled = styled(SendIcon)`
  cursor: pointer;
  color: ${colors.blue};
`
export const DownloadButton = styled(Button2)`
  float: right;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 30px;
  display: flex;
  align-items: center;
`
export const DownloadDropDownWrapper = styled.div`
  float: right;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0px;
`
export const LoadingSpinnerWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
`
export const MainContentStyled = styled.div`
  overflow: auto;

  ${ReactTableWrapperStyles} {
    border-top: 1px solid #e0e0e0;
  }

  ${ReactTableTopSectionStyles} {
    margin-bottom: 0;
  }

  ${ReactTableStyled} {
    border-right: 1px solid #e0e0e0;
  }

  ${TdInnerStyled} {
    display: block;
  }

  thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
`
export const TdStyledStyled = styled(TdStyled)`
  border-bottom: 0;
  border-top: ${tableBorder};
  padding: 0;

  &:first-child {
    border-top: ${({ row }) => (row.depth ? '0' : tableBorder)};
    border-right: 1px solid #e0e0e0;
  }

  tr:first-child > & {
    border-top: 0;
  }

  tr:last-child > & {
    border-bottom: ${tableBorder};
  }
`
export const ExpanderCellStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    position: relative;
    top: 4px;
    margin-left: 10px;
  }
`
export const OpportunityNameCellStyled = styled.div`
  border-left: 1px solid #e0e0e0;
  padding: 10px;
`
export const PercentageWrapperStyled = styled.div`
  color: #727272;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 0.02em;
  margin-right: 10px;
`
export const LocationHeaderStyles = css`
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
`
export const CustomerHeaderTopStyles = css`
  ${LocationHeaderStyles};
  position: sticky;
  border-top: 1px solid #e0e0e0;
  max-width: 270px;
  min-width: 270px;
  width: 270px;
  z-index: 1;
`
export const PerformanceWrapperStyled: ComponentType<{
  border: boolean | null | undefined
}> = styled.div`
  border-left: ${({ border }) => border && '1px solid #E0E0E0'};
  display: flex;
  height: 60px;
  padding: 20px;
`
export const PerformanceOverallTitleStyled = styled.div`
  color: #727272;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-right: 10px;
  text-transform: uppercase;
`
export const PerformancePercentageStyled: ComponentType<{
  color: string
}> = styled.div`
  color: ${({ color }) => color};
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;

  > span {
    font-size: 20px;
  }
`
export const PerformaceVerifiedTitleStyled = styled.div`
  color: #727272;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-right: 10px;
  text-transform: uppercase;
`
export const CellWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${Checkbox} {
    margin-left: 20px;
  }
`
export const ActionsPopupFullScreenBgAlphaLayer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff5c;
  display: block;
  z-index: -1;
`
export const MonthPickerWrapperStyles = styled.div`
  border-radius: 8px;
  color: #485da0;
  cursor: auto;
  padding-left: 10px;
  position: relative;
  text-align: left;
  float: right;
  margin-right: 15px;
`
export const OtherIssueOwnersCountStyles = styled.div`
  border-radius: 4px;
  background-color: ${colors.blue5};
  color: ${colors.white};
  width: 24px;
  height: 24px;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
`

export const NoDataTextWrapper = styled.div`
  position: absolute;
  width: 530px;
  top: 60%;
  left: 37%;
  display: flex;
  text-align: center;
`
