import moment from 'moment'
import Tippy from '@tippyjs/react'
import {
  DataCaptureStatusStatusIndicatorStyled,
  QuantityCellStyled,
  SelectedActionText,
  PerformanceWrapperStyled,
  PerformanceOverallTitleStyled,
  PerformancePercentageStyled,
  OtherIssueOwnersCountStyles,
} from '../BillingThisMonth/styles'
import type { FTRow } from '../BillingThisMonth/types'
import '../BillingThisMonth/types'
import { colors } from '../../../utils/themes'
import { REMAINING_SO_QUANTITY } from '../../../constants/strings'
import { CALCULATED_ACTUAL, CONTRACT_ESTIMATE } from '../constants/labels'
import { BILLING_METRIC_FIELD } from '../constants/fields'
import 'tippy.js/themes/light.css'

const ExpandedCell = ({ row, value }: any) =>
  row.isExpanded || row.depth ?
    <QuantityCellStyled>{value || '-'}</QuantityCellStyled>
  : ''

const SavingCellkWh = ({ row, value }: any) =>
  row.isExpanded || row.depth ?
    <QuantityCellStyled>{`${value} kWh`}</QuantityCellStyled>
  : ''

export const filterCards = [
  {
    title: 'Insufficient inputs',
    id: 'Insufficient Inputs',
  },
  {
    title: 'Performance in band',
    id: 'Performance In Band',
  },
  {
    title: 'Performance to notify',
    id: 'Performance For Notification Band',
  },
  {
    title: 'Performance not in band',
    id: 'Performance Not In Band',
  },
  {
    title: 'Greater of - Estimates',
    id: 'Greater of Estimates',
  },
  {
    title: 'Greater of - Actual',
    id: 'Greater of Actuals',
  },
]
export const getPercentageTextColor = (percentage) => {
  if (percentage < 60) return colors.red3
  if (percentage >= 60 && percentage < 80) return colors.blue
  if (percentage >= 80) return colors.green
  return ''
}

const SelectedActionDefaultCell = ({
  row: {
    original: { selectedAction },
  },
}: any) => {
  const getTextColor = () => {
    if (selectedAction) {
      if (selectedAction.toLowerCase() === 'estimate') return colors.green
      if (selectedAction.toLowerCase() === 'actual') return colors.blue
    }

    return ''
  }

  return selectedAction ?
      <SelectedActionText color={getTextColor()}>
        {selectedAction}
      </SelectedActionText>
    : ''
}

const SelectedActionCell = ({
  row: {
    isExpanded,
    depth,
    original: { selectedAction },
  },
}: any) => {
  const getTextColor = () => {
    if (selectedAction.toLowerCase() === 'estimate') return colors.green
    if (selectedAction.toLowerCase() === 'actual') return colors.blue
    return ''
  }

  return isExpanded || depth ?
      <SelectedActionText color={getTextColor()}>
        {selectedAction}
      </SelectedActionText>
    : ''
}

const TotalBillableSavingsCell = ({
  row: {
    isExpanded,
    depth,
    original: {
      selectedAction,
      contractEstimateFormatted,
      calculatedActualFormatted,
    },
  },
}: FTRow) => {
  const getBillableSavings = () => {
    if (selectedAction.toLowerCase() === 'estimate')
      return contractEstimateFormatted
    if (selectedAction.toLowerCase() === 'actual')
      return calculatedActualFormatted
    return ''
  }

  const totalBillableSaving = getBillableSavings()
  return isExpanded || depth ?
      <QuantityCellStyled>{`${totalBillableSaving} kWh`}</QuantityCellStyled>
    : '-'
}

const VerifiedPerformanceCell = ({
  row: {
    isExpanded,
    depth,
    original: { verifiedPerformance },
  },
  value,
}: any) =>
  isExpanded || depth ?
    <QuantityCellStyled>{value}</QuantityCellStyled>
  : <>
      {!isExpanded && (
        <PerformanceWrapperStyled border>
          <PerformanceOverallTitleStyled>
            Verified
            <br />
            Performance
          </PerformanceOverallTitleStyled>
          <PerformancePercentageStyled
            color={getPercentageTextColor(verifiedPerformance)}
          >
            {verifiedPerformance}
            <span>%</span>
          </PerformancePercentageStyled>
        </PerformanceWrapperStyled>
      )}
    </>

const DataCaptureCell = ({
  row: {
    isExpanded,
    depth,
    original: { dataCapture },
  },
}: any) => {
  const getColor = () => {
    if (dataCapture <= 60) return '#C25353'
    if (dataCapture > 60 && dataCapture < 80) return '#E7AC4C'
    if (dataCapture >= 80) return '#A9B988'
    return ''
  }

  return isExpanded || depth ?
      <>
        {dataCapture !== null && (
          <DataCaptureStatusStatusIndicatorStyled
            color={getColor()}
            type='line'
          />
        )}
        {dataCapture === null ? '-' : `${dataCapture}`}
      </>
    : ''
}

const removeComma = (value) => parseFloat(value.replaceAll(',', ''))

const contractEstimatedFilter = (tableData, id, filterValue) =>
  tableData.filter((row) => {
    const { original: { contractEstimateFormatted = 0 } = {} } = row
    const valueSanitized = removeComma(contractEstimateFormatted)
    const filterValueSanitized = removeComma(filterValue)
    return valueSanitized?.toString().includes(filterValueSanitized)
  })

const calculatedActualFilter = (tableData, id, filterValue) =>
  tableData.filter((row) => {
    const { original: { calculatedActualFormatted = 0 } = {} } = row
    const valueSanitized = removeComma(calculatedActualFormatted)
    const filterValueSanitized = removeComma(filterValue)
    return valueSanitized?.toString().includes(filterValueSanitized)
  })

const remainingQuantityFilter = (tableData, id, filterValue) =>
  tableData.filter((row) => {
    const { original: { remainingQuantityFormatted = 0 } = {} } = row
    const valueSanitized = removeComma(remainingQuantityFormatted)
    const filterValueSanitized = removeComma(filterValue)
    return valueSanitized?.toString().includes(filterValueSanitized)
  })

export const opportunitySummaryTabColumns = [
  {
    accessor: 'customer',
    Header: 'Customer',
    filter: 'text',
    hideSettings: false,
    id: 'customer',
  },
  {
    accessor: 'name',
    Header: 'Opportunity Name',
    filter: 'text',
    hideSettings: false,
    id: 'name',
  },
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
    filter: 'text',
    hideSettings: false,
    id: 'opportunityId',
  },
  {
    accessor: 'codDateFormatted',
    Cell: ({
      row: {
        original: { codDateFormatted },
      },
    }: FTRow) => moment(codDateFormatted).format('MMM D, YYYY'),
    Header: 'COD Date',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'codDateFormatted',
  },
  {
    accessor: 'billingStructure',
    Header: 'Billing Structure',
    filter: '',
    hideSettings: false,
    id: 'billingStructure',
  },
  BILLING_METRIC_FIELD,
  {
    accessor: 'opportunityMAndVGroupName',
    Header: 'M&V Group',
    filter: '',
    hideSettings: false,
    id: 'opportunityMAndVGroupName',
  },
  {
    accessor: 'category',
    Header: 'Category',
    filter: '',
    hideSettings: false,
    id: 'category',
  },
  {
    accessor: 'contractEstimateFormatted',
    Cell: ({
      row: {
        original: { contractEstimateFormatted },
      },
    }: FTRow) => `${contractEstimateFormatted} kWh`,
    Header: CONTRACT_ESTIMATE,
    filter: contractEstimatedFilter,
    hideSettings: false,
    id: 'contractEstimateFormatted',
  },
  {
    accessor: 'calculatedActualFormatted',
    Cell: ({
      row: {
        original: { calculatedActualFormatted },
      },
    }: FTRow) => `${calculatedActualFormatted} kWh`,
    Header: CALCULATED_ACTUAL,
    filter: calculatedActualFilter,
    hideSettings: false,
    id: 'calculatedActualFormatted',
  },
  {
    accessor: 'dataCapture',
    Cell: ({
      row: {
        original: { dataCapture },
      },
    }: FTRow) => {
      const getColor = () => {
        if (dataCapture <= 60) return '#C25353'
        if (dataCapture > 60 && dataCapture < 80) return '#E7AC4C'
        if (dataCapture >= 80) return '#A9B988'
        return ''
      }

      return (
        <>
          {dataCapture !== null && (
            <DataCaptureStatusStatusIndicatorStyled
              color={getColor()}
              type='line'
            />
          )}
          {dataCapture === null ? '-' : `${dataCapture}`}
        </>
      )
    },
    Header: 'Data Capture (%)',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    isVisible: false,
    id: 'dataCapture',
  },
  {
    accessor: 'actualPerformance',
    Cell: ({
      row: {
        original: { actualPerformance },
      },
    }: FTRow) => {
      const getColor = () => {
        if (actualPerformance <= 60) return '#C25353'
        if (actualPerformance > 60 && actualPerformance < 80) return '#E7AC4C'
        if (actualPerformance >= 80) return '#A9B988'
        return ''
      }

      return (
        <>
          {actualPerformance !== undefined && (
            <DataCaptureStatusStatusIndicatorStyled
              color={getColor()}
              type='line'
            />
          )}
          {actualPerformance === undefined ? '-' : `${actualPerformance}`}
        </>
      )
    },
    Header: 'Actual Performance (%)',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'actualPerformance',
  },
  {
    accessor: 'historical1Month',
    Header: 'Actual Performance - last month (%)',
    Cell: ({
      row: {
        original: { historical1Month },
      },
    }: FTRow) => historical1Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical1Month',
    isVisible: false,
  },
  {
    accessor: 'historical2Month',
    Header: 'Actual Performance - two months ago (%)',
    Cell: ({
      row: {
        original: { historical2Month },
      },
    }: FTRow) => historical2Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical2Month',
    isVisible: false,
  },
  {
    accessor: 'historical3Month',
    Header: 'Actual Performance - three months ago (%)',
    Cell: ({
      row: {
        original: { historical3Month },
      },
    }: FTRow) => historical3Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical3Month',
    isVisible: false,
  },
  {
    accessor: 'firstActualBill',
    Header: 'First Actual Bill',
    filter: '',
    hideSettings: false,
    id: 'firstActualBill',
    isVisible: false,
  },
  {
    accessor: 'loaDateFormatted',
    Cell: ({
      row: {
        original: { loaDateFormatted },
      },
    }: FTRow) => moment(loaDateFormatted).format('MMM D, YYYY'),
    Header: 'LOA Date',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'loaDateFormatted',
    isVisible: false,
  },
  {
    accessor: 'opportunityOwner',
    Header: 'Opportunity Owner',
    filter: '',
    hideSettings: false,
    id: 'opportunityOwner',
    isVisible: false,
  },
  {
    accessor: 'lastSavingsRunFormatted',
    Header: 'Last savings run',
    filter: '',
    hideSettings: false,
    id: 'lastSavingsRunFormatted',
    isVisible: false,
  },
  {
    accessor: 'selectedAction',
    Cell: SelectedActionDefaultCell,
    disableFilters: true,
    Header: 'Billed On',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'invoiceNumber',
    Header: 'Invoice Number',
    Cell: ({
      row: {
        original: { invoiceNumber },
      },
    }: FTRow) => <QuantityCellStyled>{invoiceNumber}</QuantityCellStyled>,
    disableFilters: true,
    minWidth: 120,
  },
  {
    accessor: 'invoiceCreatedDateTimeFormatted',
    Header: 'Invoice Creation Time',
    Cell: ({
      row: {
        original: { invoiceCreatedDateTimeFormatted },
      },
    }: FTRow) => (
      <QuantityCellStyled>{invoiceCreatedDateTimeFormatted}</QuantityCellStyled>
    ),
    disableFilters: true,
    minWidth: 120,
  },
]
export const approvalTabColumns = [
  {
    accessor: 'customer',
    Header: 'Customer',
    minWidth: 270,
    maxWidth: 270,
  },
  {
    accessor: 'name',
    disableFilters: true,
    Header: 'Opportunity Name',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    accessor: 'opportunityId',
    Cell: VerifiedPerformanceCell,
    disableFilters: true,
    Header: 'Opportunity Id',
    minWidth: 150,
    maxWidth: 180,
  },
  {
    accessor: 'billingStructure',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Bill Structure',
    minWidth: 120,
    maxWidth: 120,
  },
  { ...BILLING_METRIC_FIELD, Cell: ExpandedCell },
  {
    accessor: 'opportunityMAndVGroupName',
    Header: 'M&V Group',
    Cell: ExpandedCell,
    filter: '',
    hideSettings: false,
    id: 'opportunityMAndVGroupName',
  },
  {
    accessor: 'category',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Category',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    accessor: 'contractEstimateFormatted',
    Cell: SavingCellkWh,
    filter: contractEstimatedFilter,
    Header: CONTRACT_ESTIMATE,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'calculatedActualFormatted',
    Cell: SavingCellkWh,
    disableFilters: true,
    Header: CALCULATED_ACTUAL,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'dataCapture',
    Header: 'Data Capture (%)',
    Cell: DataCaptureCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'actualPerformance',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Actual Performance (%)',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'historical1Month',
    Header: 'Actual Performance - last month (%)',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'historical2Month',
    Header: 'Actual Performance - two months ago (%)',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'historical3Month',
    Header: 'Actual Performance - three months ago (%)',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'firstActualBill',
    Header: 'First Actual Bill',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'selectedAction',
    Cell: SelectedActionCell,
    disableFilters: true,
    Header: 'Billed On',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'totalBillableSavings',
    Cell: TotalBillableSavingsCell,
    disableFilters: true,
    Header: 'Total Billable Savings',
    minWidth: 120,
    maxWidth: 240,
  },
  {
    accessor: 'invoiceNumber',
    Header: 'Invoice Number',
    Cell: ({
      row: {
        original: { invoiceNumber },
      },
    }: FTRow) => <QuantityCellStyled>{invoiceNumber}</QuantityCellStyled>,
    disableFilters: true,
    minWidth: 120,
  },
  {
    accessor: 'invoiceCreationStatusFormatted',
    disableFilters: true,
    Header: 'Invoice Status',
    Cell: ({
      row: {
        original: { invoiceCreationStatusFormatted },
      },
    }: FTRow) => (
      <QuantityCellStyled>{invoiceCreationStatusFormatted}</QuantityCellStyled>
    ),
    minWidth: 120,
  },
  {
    accessor: 'invoiceCreatedDateTimeFormatted',
    Header: 'Invoice Creation Time',
    Cell: ({
      row: {
        original: { invoiceCreatedDateTimeFormatted },
      },
    }: FTRow) => (
      <QuantityCellStyled>{invoiceCreatedDateTimeFormatted}</QuantityCellStyled>
    ),
    disableFilters: true,
    minWidth: 120,
  },
]
export const sentToNetsuiteTabColumns = [
  {
    accessor: 'customer',
    Header: 'Customer',
    minWidth: 270,
    maxWidth: 270,
  },
  {
    accessor: 'name',
    disableFilters: true,
    Header: 'Opportunity Name',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    accessor: 'opportunityMAndVGroupName',
    Header: 'M&V Group',
    Cell: ExpandedCell,
    filter: '',
    hideSettings: false,
    id: 'opportunityMAndVGroupName',
  },
  {
    accessor: 'opportunityId',
    Cell: VerifiedPerformanceCell,
    disableFilters: true,
    Header: 'Opportunity Id',
    minWidth: 150,
    maxWidth: 180,
  },
  {
    accessor: 'billingStructure',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Bill Structure',
    minWidth: 120,
    maxWidth: 120,
  },
  { ...BILLING_METRIC_FIELD, Cell: ExpandedCell },
  {
    accessor: 'category',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Category',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    accessor: 'contractEstimateFormatted',
    Cell: SavingCellkWh,
    filter: contractEstimatedFilter,
    Header: CONTRACT_ESTIMATE,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'calculatedActualFormatted',
    Cell: SavingCellkWh,
    disableFilters: true,
    Header: CALCULATED_ACTUAL,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'dataCapture',
    Header: 'Data Capture (%)',
    Cell: DataCaptureCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'actualPerformance',
    Cell: ExpandedCell,
    disableFilters: true,
    Header: 'Actual Performance (%)',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'historical1Month',
    Header: 'Actual Performance - last month (%)',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'historical2Month',
    Header: 'Actual Performance - two months ago (%)',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'historical3Month',
    Header: 'Actual Performance - three months ago (%)',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'firstActualBill',
    Header: 'First Actual Bill',
    Cell: ExpandedCell,
    disableFilters: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'selectedAction',
    Cell: SelectedActionCell,
    disableFilters: true,
    Header: 'Billed On',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    accessor: 'totalBillableSavings',
    Cell: TotalBillableSavingsCell,
    disableFilters: true,
    Header: 'Total Billable Savings',
    minWidth: 120,
    maxWidth: 240,
  },
  {
    accessor: 'invoiceNumber',
    Header: 'Invoice Number',
    Cell: ({
      row: {
        original: { invoiceNumber },
      },
    }: FTRow) => <QuantityCellStyled>{invoiceNumber}</QuantityCellStyled>,
    disableFilters: true,
    minWidth: 120,
  },
  {
    accessor: 'invoiceCreationStatusFormatted',
    disableFilters: true,
    Header: 'Invoice Status',
    Cell: ({
      row: {
        original: { invoiceCreationStatusFormatted },
      },
    }: FTRow) => (
      <QuantityCellStyled>{invoiceCreationStatusFormatted}</QuantityCellStyled>
    ),
    minWidth: 120,
  },
  {
    accessor: 'invoiceCreatedDateTimeFormatted',
    Header: 'Invoice Creation Time',
    Cell: ({
      row: {
        original: { invoiceCreatedDateTimeFormatted },
      },
    }: FTRow) => (
      <QuantityCellStyled>{invoiceCreatedDateTimeFormatted}</QuantityCellStyled>
    ),
    disableFilters: true,
    minWidth: 120,
  },
]
export const investigationTabColumns = [
  {
    accessor: 'customer',
    Header: 'Customer',
    filter: 'text',
    hideSettings: false,
    id: 'customer',
  },
  {
    accessor: 'name',
    Header: 'Opportunity Name',
    filter: 'text',
    hideSettings: false,
    id: 'name',
  },
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
    filter: 'text',
    hideSettings: false,
    id: 'opportunityId',
  },
  {
    accessor: 'opportunityMAndVGroupName',
    Header: 'M&V Group',
    filter: '',
    hideSettings: false,
    id: 'opportunityMAndVGroupName',
  },
  {
    accessor: 'codDateFormatted',
    Cell: ({
      row: {
        original: { codDateFormatted },
      },
    }: FTRow) => moment(codDateFormatted).format('MMM D, YYYY'),
    Header: 'COD Date',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'codDateFormatted',
  },
  {
    accessor: 'billingStructure',
    Header: 'Billing Structure',
    filter: '',
    hideSettings: false,
    id: 'billingStructure',
  },
  BILLING_METRIC_FIELD,
  {
    accessor: 'category',
    Header: 'Category',
    filter: '',
    hideSettings: false,
    id: 'category',
  },
  {
    accessor: 'contractEstimateFormatted',
    Cell: ({
      row: {
        original: { contractEstimateFormatted },
      },
    }: FTRow) => `${contractEstimateFormatted} kWh`,
    Header: CONTRACT_ESTIMATE,
    filter: contractEstimatedFilter,
    hideSettings: false,
    id: 'contractEstimateFormatted',
  },
  {
    accessor: 'calculatedActualFormatted',
    Cell: ({
      row: {
        original: { calculatedActualFormatted },
      },
    }: FTRow) => `${calculatedActualFormatted} kWh`,
    Header: CALCULATED_ACTUAL,
    filter: calculatedActualFilter,
    hideSettings: false,
    id: 'calculatedActualFormatted',
  },
  {
    accessor: 'dataCapture',
    Cell: ({
      row: {
        original: { dataCapture },
      },
    }: FTRow) => {
      const getColor = () => {
        if (dataCapture <= 60) return '#C25353'
        if (dataCapture > 60 && dataCapture < 80) return '#E7AC4C'
        if (dataCapture >= 80) return '#A9B988'
        return ''
      }

      return (
        <>
          {dataCapture !== null && (
            <DataCaptureStatusStatusIndicatorStyled
              color={getColor()}
              type='line'
            />
          )}
          {dataCapture === null ? '-' : `${dataCapture}`}
        </>
      )
    },
    Header: 'Data Capture (%)',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    isVisible: false,
    id: 'dataCapture',
  },
  {
    accessor: 'actualPerformance',
    Cell: ({
      row: {
        original: { actualPerformance },
      },
    }: FTRow) => {
      const getColor = () => {
        if (actualPerformance <= 60) return '#C25353'
        if (actualPerformance > 60 && actualPerformance < 80) return '#E7AC4C'
        if (actualPerformance >= 80) return '#A9B988'
        return ''
      }

      return (
        <>
          {actualPerformance !== undefined && (
            <DataCaptureStatusStatusIndicatorStyled
              color={getColor()}
              type='line'
            />
          )}
          {actualPerformance === undefined ? '-' : `${actualPerformance}`}
        </>
      )
    },
    Header: 'Actual Performance (%)',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'actualPerformance',
  },
  {
    accessor: 'historical1Month',
    Header: 'Actual Performance - last month (%)',
    Cell: ({
      row: {
        original: { historical1Month },
      },
    }: FTRow) => historical1Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical1Month',
    isVisible: false,
  },
  {
    accessor: 'historical2Month',
    Header: 'Actual Performance - two months ago (%)',
    Cell: ({
      row: {
        original: { historical2Month },
      },
    }: FTRow) => historical2Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical2Month',
    isVisible: false,
  },
  {
    accessor: 'historical3Month',
    Header: 'Actual Performance - three months ago (%)',
    Cell: ({
      row: {
        original: { historical3Month },
      },
    }: FTRow) => historical3Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical3Month',
    isVisible: false,
  },
  {
    accessor: 'firstActualBill',
    Header: 'First Actual Bill',
    filter: '',
    hideSettings: false,
    id: 'firstActualBill',
    isVisible: false,
  },
  {
    accessor: 'issueOwners',
    Header: 'Issue Owner',
    disableSortBy: true,
    Cell: ({
      row: {
        original: { issueOwners },
      },
    }: FTRow) => (
      <span
        style={{
          display: 'flex',
        }}
      >
        {issueOwners?.length ?
          <>
            <div>{issueOwners[0]}</div>
            {issueOwners.length > 1 && (
              <Tippy content={issueOwners?.join(', ')} theme='light'>
                <OtherIssueOwnersCountStyles>
                  +{issueOwners?.length - 1}
                </OtherIssueOwnersCountStyles>
              </Tippy>
            )}
          </>
        : 'NA'}
      </span>
    ),
    filter: '',
    hideSettings: false,
    id: 'issueOwners',
  },
  {
    accessor: 'suggestedBillingAction',
    Header: 'Suggested Billing Action',
    filter: '',
    hideSettings: true,
    id: 'suggestedBillingAction',
    isVisible: false,
  },
  {
    accessor: 'loaDateFormatted',
    Cell: ({
      row: {
        original: { loaDateFormatted },
      },
    }: FTRow) => moment(loaDateFormatted).format('MMM D, YYYY'),
    Header: 'LOA Date',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'loaDateFormatted',
    isVisible: false,
  },
  {
    accessor: 'opportunityOwner',
    Header: 'Opportunity Owner',
    filter: '',
    hideSettings: false,
    id: 'opportunityOwner',
    isVisible: false,
  },
  {
    accessor: 'lastSavingsRunFormatted',
    Header: 'Last savings run',
    filter: '',
    hideSettings: false,
    id: 'lastSavingsRunFormatted',
    isVisible: false,
  },
]
export const verificationTabColumns = [
  {
    accessor: 'customer',
    Header: 'Customer',
    filter: 'text',
    hideSettings: false,
    id: 'customer',
  },
  {
    accessor: 'name',
    Header: 'Opportunity Name',
    filter: 'text',
    hideSettings: false,
    id: 'name',
  },
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
    filter: 'text',
    hideSettings: false,
    id: 'opportunityId',
  },
  {
    accessor: 'opportunityMAndVGroupName',
    Header: 'M&V Group',
    filter: '',
    hideSettings: false,
    id: 'opportunityMAndVGroupName',
  },
  {
    accessor: 'codDateFormatted',
    Cell: ({
      row: {
        original: { codDateFormatted },
      },
    }: FTRow) => moment(codDateFormatted).format('MMM D, YYYY'),
    Header: 'COD Date',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'codDateFormatted',
  },
  {
    accessor: 'billingStructure',
    Header: 'Billing Structure',
    filter: '',
    hideSettings: false,
    id: 'billingStructure',
  },
  BILLING_METRIC_FIELD,
  {
    accessor: 'category',
    Header: 'Category',
    filter: '',
    hideSettings: false,
    id: 'category',
  },
  {
    accessor: 'contractEstimateFormatted',
    Cell: ({
      row: {
        original: { contractEstimateFormatted },
      },
    }: FTRow) => `${contractEstimateFormatted} kWh`,
    Header: CONTRACT_ESTIMATE,
    filter: contractEstimatedFilter,
    hideSettings: false,
    id: 'contractEstimateFormatted',
  },
  {
    accessor: 'calculatedActualFormatted',
    Cell: ({
      row: {
        original: { calculatedActualFormatted },
      },
    }: FTRow) => `${calculatedActualFormatted} kWh`,
    Header: CALCULATED_ACTUAL,
    filter: calculatedActualFilter,
    hideSettings: false,
    id: 'calculatedActualFormatted',
  },
  {
    accessor: 'dataCapture',
    Cell: ({
      row: {
        original: { dataCapture },
      },
    }: FTRow) => {
      const getColor = () => {
        if (dataCapture <= 60) return '#C25353'
        if (dataCapture > 60 && dataCapture < 80) return '#E7AC4C'
        if (dataCapture >= 80) return '#A9B988'
        return ''
      }

      return (
        <>
          {dataCapture !== null && (
            <DataCaptureStatusStatusIndicatorStyled
              color={getColor()}
              type='line'
            />
          )}
          {dataCapture === null ? '-' : `${dataCapture}`}
        </>
      )
    },
    Header: 'Data Capture (%)',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    isVisible: false,
    id: 'dataCapture',
  },
  {
    accessor: 'actualPerformance',
    Cell: ({
      row: {
        original: { actualPerformance },
      },
    }: FTRow) => {
      const getColor = () => {
        if (actualPerformance <= 60) return '#C25353'
        if (actualPerformance > 60 && actualPerformance < 80) return '#E7AC4C'
        if (actualPerformance >= 80) return '#A9B988'
        return ''
      }

      return (
        <>
          {actualPerformance !== undefined && (
            <DataCaptureStatusStatusIndicatorStyled
              color={getColor()}
              type='line'
            />
          )}
          {actualPerformance === undefined ? '-' : `${actualPerformance}`}
        </>
      )
    },
    Header: 'Actual Performance (%)',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'actualPerformance',
  },
  {
    accessor: 'historical1Month',
    Header: 'Actual Performance - last month (%)',
    Cell: ({
      row: {
        original: { historical1Month },
      },
    }: FTRow) => historical1Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical1Month',
    isVisible: false,
  },
  {
    accessor: 'historical2Month',
    Header: 'Actual Performance - two months ago (%)',
    Cell: ({
      row: {
        original: { historical2Month },
      },
    }: FTRow) => historical2Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical2Month',
    isVisible: false,
  },
  {
    accessor: 'historical3Month',
    Header: 'Actual Performance - three months ago (%)',
    Cell: ({
      row: {
        original: { historical3Month },
      },
    }: FTRow) => historical3Month || '-',
    filter: '',
    hideSettings: false,
    id: 'historical3Month',
    isVisible: false,
  },
  {
    accessor: 'firstActualBill',
    Header: 'First Actual Bill',
    filter: '',
    hideSettings: false,
    id: 'firstActualBill',
    isVisible: false,
  },
  {
    accessor: 'suggestedBillingAction',
    Header: 'Suggested Billing Action',
    filter: '',
    hideSettings: true,
    id: 'suggestedBillingAction',
    isVisible: false,
  },
  {
    accessor: 'loaDateFormatted',
    Cell: ({
      row: {
        original: { loaDateFormatted },
      },
    }: FTRow) => moment(loaDateFormatted).format('MMM D, YYYY'),
    Header: 'LOA Date',
    disableFilters: true,
    filter: '',
    hideSettings: false,
    id: 'loaDateFormatted',
    isVisible: false,
  },
  {
    accessor: 'opportunityOwner',
    Header: 'Opportunity Owner',
    filter: '',
    hideSettings: false,
    id: 'opportunityOwner',
    isVisible: false,
  },
  {
    accessor: 'lastSavingsRunFormatted',
    Header: 'Last savings run',
    filter: '',
    hideSettings: false,
    id: 'lastSavingsRunFormatted',
    isVisible: false,
  },
  {
    accessor: 'remainingQuantity',
    Cell: ({
      row: {
        original: { remainingQuantityFormatted },
      },
    }: FTRow) => `${remainingQuantityFormatted} kWh`,
    Header: REMAINING_SO_QUANTITY,
    filter: remainingQuantityFilter,
    hideSettings: false,
    id: 'remainingQuantity',
    isVisible: true,
  },
]
export const areEqual = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true
      }

      return false
    })
  }

  return false
}
export const isSubset = (array1, array2) =>
  array1.every((element) => {
    if (array2.includes(element)) {
      return true
    }

    return false
  })
export const toCamelCase = (str = '') =>
  str
    .replace(/[^a-z0-9]/gi, ' ')
    .toLowerCase()
    .split(' ')
    .map((el, ind) =>
      ind === 0 ? el : el[0].toUpperCase() + el.substring(1, el.length),
    )
    .join('')
export const tabs = [
  {
    text: 'Opportunity Summary',
    tabId: 'opportunity-summary',
  },
  {
    text: 'Under Investigation',
    tabId: 'under-investigation',
  },
  {
    text: 'Performance Verification',
    tabId: 'performance-verification',
  },
  {
    text: 'Invoice Approval',
    tabId: 'invoice-approval',
  },
  {
    text: 'Sent to Netsuite',
    tabId: 'sent-to-netsuite',
  },
]
export const alwaysLockedColumns = ['selectAll', 'customer', 'name']
export const investigationVerificationDefaultSort = [
  {
    id: 'customer',
  },
]
export const initialHiddenColumns = [
  'loaDateFormatted',
  'suggestedBillingAction',
  'opportunityOwner',
  'lastSavingsRunFormatted',
  'billingStructure',
]
export const skipCSVColumns = ['Actions', 'Issue Owner', 'Last savings run']
export const initialSort = {
  id: '',
  desc: false,
}
export const defaultIssueOwnerList: Array<Record<string, any>> = [
  {
    id: 'option1',
    name: 'Accounts Receivables',
    selected: false,
  },
  {
    id: 'option2',
    name: 'Business Intelligence',
    selected: false,
  },
  {
    id: 'option3',
    name: 'Technical Solutions Engineering',
    selected: false,
  },
  {
    id: 'option4',
    name: 'Meter Maintenance',
    selected: false,
  },
  {
    id: 'option5',
    name: 'Meter Design',
    selected: false,
  },
  {
    id: 'option6',
    name: 'Project Management',
    selected: false,
  },
  {
    id: 'option7',
    name: 'Performance Management',
    selected: false,
  },
  {
    id: 'option8',
    name: 'Proposal Operations',
    selected: false,
  },
  {
    id: 'option9',
    name: 'Sales',
    selected: false,
  },
  {
    id: 'option10',
    name: 'Technology',
    selected: false,
  },
  {
    id: 'option11',
    name: 'Other',
    selected: false,
  },
]
