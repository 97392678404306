import { combineReducers } from 'redux'
import { takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { consoleApiUrl, defaultHeaders } from '../api'
import { makeActionTypes } from './utils'
import { handleAxiosError, queryStringify } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { naturallySort } from '../utils'

export type FTPhaseGroupEntity = {
  id: string
  name: string
  meterId: string
}
export type FTPhaseGroupSummary = {
  id: string
  name: string
}
export type FTFetchPhaseGroupListAction = {
  meterId?: string
}
export type FTAddPhaseGroupAction = {
  name: string
  meterId: string
}
export type FTUpdatePhaseGroupAction = {
  id: string
  name: string
}
export type FTDeletePhaseGroupAction = {
  id: string
}
const entity = 'phaseGroups'
type FTPhaseGroupEntityState = {
  byId: Record<string, any>
  allIds: Array<string>
  meta: {
    pageNumber: number | null | undefined
    pageSize: number | null | undefined
    next: string | null | undefined
    previous: string | null | undefined
    error: string | null | undefined
    loading: boolean
    addLoading: boolean
    updateLoading: boolean
    deleteLoading: boolean
  }
}
type FTStatePhaseGroups = {
  entities: {
    phaseGroups: FTPhaseGroupEntityState
  }
}

const naturallySortPhaseGroupItems = (
  a: FTPhaseGroupSummary,
  b: FTPhaseGroupSummary,
) => naturallySort(a.name, b.name)

// Action Types
export const types = {
  ...makeActionTypes('FETCH_PHASE_GROUP_LIST'),
  ...makeActionTypes('ADD_PHASE_GROUP'),
  ...makeActionTypes('UPDATE_PHASE_GROUP'),
  ...makeActionTypes('DELETE_PHASE_GROUP'),
}
// Selectors
export const getPhaseGroups = (
  state: FTStatePhaseGroups,
): Array<FTPhaseGroupSummary> =>
  state.entities[entity].allIds
    .map((id) => state.entities[entity].byId[id])
    .sort(naturallySortPhaseGroupItems)
export const getPhaseGroupsById = ({
  entities: {
    [entity]: { byId: phaseGroupsById },
  },
}: FTStatePhaseGroups): Record<string, any> => phaseGroupsById
export const getPhaseGroupListEntity = (state: FTStatePhaseGroups) => ({
  items: getPhaseGroups(state),
  meta: state.entities[entity].meta,
})
// Reducers
export const initialState = {
  byId: {},
  allIds: [],
  meta: {
    pageNumber: 1,
    pageSize: 20,
    next: null,
    previous: null,
    loading: false,
    addLoading: false,
    updateLoading: false,
    deleteLoading: false,
    updates: [],
    error: '',
  },
}

function entityById(action, state) {
  const entitiesById = {}
  action.payload.forEach((phaseGroup: FTPhaseGroupSummary) => {
    entitiesById[phaseGroup.id] = { ...phaseGroup }
  })
  return { ...state, ...entitiesById }
}

function entityAllIds(action, state) {
  const newIds = action.payload.map<FTPhaseGroupSummary>(
    (phaseGroup) => phaseGroup.id,
  )
  return [...new Set(state.concat(newIds))]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PHASE_GROUP_LIST:
      return {}

    case types.ADD_PHASE_GROUP_SUCCESS:
    case types.UPDATE_PHASE_GROUP_SUCCESS:
    case types.FETCH_PHASE_GROUP_LIST_SUCCESS:
      return entityById(action, state)

    case types.DELETE_PHASE_GROUP_SUCCESS:
      if (action.payload.id) {
        const { [action.payload.id]: deleted, ...newState } = state
        return newState
      }

      return state

    default:
      return state
  }
}

function allIds(state = initialState.allIds, action) {
  switch (action.type) {
    case types.FETCH_PHASE_GROUP_LIST:
      return []

    case types.ADD_PHASE_GROUP_SUCCESS:
    case types.UPDATE_PHASE_GROUP_SUCCESS:
    case types.FETCH_PHASE_GROUP_LIST_SUCCESS:
      return entityAllIds(action, state)

    case types.DELETE_PHASE_GROUP_SUCCESS:
      if (action.payload.id) {
        return state.filter((p) => p !== action.payload.id)
      }

      return state

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PHASE_GROUP_LIST:
      return { ...state, loading: true, error: '' }

    case types.FETCH_PHASE_GROUP_LIST_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PHASE_GROUP_LIST_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    case types.ADD_PHASE_GROUP:
      return { ...state, addLoading: true, error: '' }

    case types.ADD_PHASE_GROUP_SUCCESS:
      return { ...state, addLoading: false }

    case types.ADD_PHASE_GROUP_ERROR:
      return { ...state, addLoading: false, error: action.error }

    case types.UPDATE_PHASE_GROUP:
      return {
        ...state,
        updateLoading: true,
        updates: [...state.updates, action.id],
        error: '',
      }

    case types.UPDATE_PHASE_GROUP_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updates: state.updates.filter((id) => id !== action.payload[0].id),
      }

    case types.UPDATE_PHASE_GROUP_ERROR:
      return {
        ...state,
        updateLoading: false,
        updates: state.updates.filter((id) => id !== action.id),
        error: action.error,
      }

    case types.DELETE_PHASE_GROUP:
      return { ...state, deleteLoading: true, error: '' }

    case types.DELETE_PHASE_GROUP_SUCCESS:
      return { ...state, deleteLoading: false }

    case types.DELETE_PHASE_GROUP_ERROR:
      return { ...state, deleteLoading: false, error: action.error }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
}) // Action Creators

export const actions = {
  fetchPhaseGroupList: (params: FTFetchPhaseGroupListAction) => ({
    type: types.FETCH_PHASE_GROUP_LIST,
    ...params,
  }),
  addPhaseGroup: (props: FTAddPhaseGroupAction) => ({
    type: types.ADD_PHASE_GROUP,
    ...props,
  }),
  updatePhaseGroup: (props: FTUpdatePhaseGroupAction) => ({
    type: types.UPDATE_PHASE_GROUP,
    ...props,
  }),
  deletePhaseGroup: (props: FTDeletePhaseGroupAction) => ({
    type: types.DELETE_PHASE_GROUP,
    ...props,
  }),
}
// API
export class API {
  static fetchPhaseGroupList({ meterId }: Record<string, any>) {
    const query = queryStringify({
      meterId,
    })
    const baseUrl = `${consoleApiUrl()}/phasegroups`
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data: { results } }) => results)
      .catch(handleAxiosError)
  }

  static addPhaseGroup({ meterId, name }: FTAddPhaseGroupAction) {
    const url = `${consoleApiUrl()}/phasegroups`
    const body: {
      meterId: string
      name: string
    } = {
      meterId,
      name,
    }
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTPhaseGroupEntity }) => [data])
      .catch(handleAxiosError)
  }

  static updatePhaseGroup({ id, name }: FTUpdatePhaseGroupAction) {
    const url = `${consoleApiUrl()}/phasegroups/${id}`
    const body: {
      name: string
    } = {
      name,
    }
    return axios
      .patch(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTPhaseGroupSummary }) => [data])
      .catch(handleAxiosError)
  }

  static deletePhaseGroup({ id }: FTDeletePhaseGroupAction) {
    const url = `${consoleApiUrl()}/phasegroups/${id}`
    return axios
      .delete(url, {
        headers: defaultHeaders(),
      })
      .then(() => ({
        id,
        success: true,
      }))
      .catch(handleAxiosError)
  }
}

function* fetchPhaseGroupListSaga(
  params: FTFetchPhaseGroupListAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.fetchPhaseGroupList, params)
    yield put({
      type: types.FETCH_PHASE_GROUP_LIST_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_PHASE_GROUP_LIST_ERROR, error)
  }
}

function* addPhaseGroupSaga(
  params: FTAddPhaseGroupAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.addPhaseGroup, params)
    yield put({
      type: types.ADD_PHASE_GROUP_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.ADD_PHASE_GROUP_ERROR, e)
  }
}

function* updatePhaseGroupSaga(
  params: FTUpdatePhaseGroupAction,
): Generator<any, void, any> {
  const { id } = params

  try {
    const payload = yield call(API.updatePhaseGroup, params)
    yield put({
      type: types.UPDATE_PHASE_GROUP_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PHASE_GROUP_ERROR, e, {
      id,
    })
  }
}

function* deletePhaseGroupSaga(
  params: FTDeletePhaseGroupAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.deletePhaseGroup, params)
    yield put({
      type: types.DELETE_PHASE_GROUP_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.DELETE_PHASE_GROUP_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_PHASE_GROUP_LIST, fetchPhaseGroupListSaga),
  takeEvery(types.ADD_PHASE_GROUP, addPhaseGroupSaga),
  takeEvery(types.UPDATE_PHASE_GROUP, updatePhaseGroupSaga),
  takeEvery(types.DELETE_PHASE_GROUP, deletePhaseGroupSaga),
]
