import { PureComponent } from 'react'
import styled from 'styled-components'
import clickOutside from 'react-click-outside'
import type { FTActionOption } from './ActionsDropdown'
import ActionsDropdown, { StyledAction } from './ActionsDropdown'
import IonIcon from './IonIcon'

const Styles = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  line-height: 36px;
  padding-left: 22px;
  padding-right: 22px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  width: 64px;
  background-color: #fff;

  &:hover {
    background-color: #f5f5f5;
  }

  & a,
  & a:hover {
    text-decoration: none;
    color: #337ab7;
  }

  & .IonIcon {
    width: 25px;
    height: 18px;
    line-height: unset;

    & > span {
      left: 8px;
      top: -5px;
    }

    font-size: 18px;
  }

  & .Dropdown .List {
    display: inline-block;
    :before {
      right: 18px;
    }

    :after {
      left: unset;
      right: 19px;
    }

    :after {
      top: -7px;
    }

    font-weight: normal;

    cursor: default;
    text-align: left;
    border-radius: 4px;

    background-color: #ffffff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #d7d7d7;
    padding: 0;
    font-size: 14px;
    width: unset;
    min-width: 97px;
    top: 13px;

    a,
    ${StyledAction} {
      white-space: nowrap;
      padding: 0 20px;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        padding-top: 6px;
      }

      &:last-child {
        padding-bottom: 6px;
      }
    }
  }
`
type FTProps = {
  actions: Array<FTActionOption>
  title?: string
  icon?: Record<string, any>
  iconBefore?: boolean
  style?: Record<string, any>
}
type FTState = {
  open: boolean
}

class Component extends PureComponent<FTProps, FTState> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      open: false,
    }
  }

  toggleMenu = () => {
    this.setState((prev) => ({ ...prev, open: !prev.open }))
  }

  // This is required by react-click-outside.
  handleClickOutside = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const { open } = this.state
    const { actions } = this.props
    const { title } = this.props
    const { icon } = this.props
    const { iconBefore } = this.props
    const { style } = this.props
    return (
      <Styles onClick={this.toggleMenu} style={style}>
        {!iconBefore && (title || 'Actions')}
        {icon || <IonIcon iconClass='ion-android-arrow-dropdown' />}
        {iconBefore && (title || 'Actions')}
        {open && <ActionsDropdown options={actions} />}
      </Styles>
    )
  }
}

export default clickOutside(Component)
