import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { consoleApiUrl, loginToken } from '../api'

const SwaggerPage = () => (
  <SwaggerUI
    url={`${consoleApiUrl()}/swagger.json`}
    requestInterceptor={(
      req, // If the auth token is already present...
    ) =>
      (
        req.headers.Authorization && // And if the token doesn't include the placeholder text...
        !req.headers.Authorization.includes('<authorization token>') // Uses the original request.
      ) ?
        req // Else uses the current user's login token.
      : {
          ...req,
          headers: {
            ...req.headers,
            Authorization: `Token ${loginToken() || ''}`,
          },
        }
    }
  />
)

export default SwaggerPage
