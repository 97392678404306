import styled from 'styled-components'
import AddCircleOutlineIcon from '../Icons/AddCircleOutlineIcon'
import Button2 from '../Button2'
import GasMeterIcon from './media/icons/gas-meter-icon.png'
import RedaptiveReactTable7 from '../RedaptiveReactTable7'
import type { FTMeterKFactorHistory } from '../../ducks/meters'
import { colors } from '../../utils/themes'

type GasMeterConfigurationProps = {
  data: Array<FTMeterKFactorHistory> | null | undefined
  lastModified: string | null | undefined
  openKFactorModal: (...args: Array<any>) => any
  canEditMeterConfig: boolean
}
const KFactorContainer = styled.div`
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const LastModifiedContainer = styled.p`
  text-align: right;
`
const AddButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`
const KFactorTableContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 10px;
`
const AddANewKfactorRow = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 25px;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid #e0e0e0;
  background: linear-gradient(
      0deg,
      rgba(72, 93, 160, 0.16) 0%,
      rgba(72, 93, 160, 0.16) 100%
    ),
    #fff;
  color: #485da0;
  font-size: 14px;
  text-transform: capitalize;
  :hover {
    cursor: pointer;
    background: #f0f2f8;
  }
`
const AddCircleOutlineIconStyled = styled(AddCircleOutlineIcon)`
  font-size: 14px;
  color: #485da0;
  margin-right: 5px;
`
const columns = [
  {
    accessor: 'status',
    Header: 'Status',
  },
  {
    accessor: 'createdFormattedDate',
    Header: 'Config Date',
  },
  {
    accessor: 'calculationType',
    Header: 'Type',
  },
  {
    accessor: 'kFactor',
    Header: 'K-Factor (revs per CCF)',
  },
  {
    accessor: 'comment',
    Header: 'Comment',
  },
]

const GasMeterConfiguration = ({
  data,
  lastModified,
  openKFactorModal,
  canEditMeterConfig,
}: GasMeterConfigurationProps) => {
  const getRowProps = (row: Record<string, any>) => {
    const {
      original: { status },
    } = row
    const fields: Record<string, any> = {}

    if (status === 'Active') {
      fields.highlightColor = colors.green
    }

    return fields
  }

  const lastModifiedSection = (
    <LastModifiedContainer>
      Last Modified: {lastModified}{' '}
    </LastModifiedContainer>
  )
  const toRender =
    data && data.length > 0 ?
      <>
        <KFactorTableContainer>
          {lastModified && lastModifiedSection}
          <RedaptiveReactTable7
            globalFilterable={false}
            columns={columns}
            data={data}
            getRowProps={getRowProps}
          />
        </KFactorTableContainer>
        {canEditMeterConfig && (
          <AddANewKfactorRow onClick={() => openKFactorModal(true)}>
            <AddCircleOutlineIconStyled />
            Add a new k-factor
          </AddANewKfactorRow>
        )}
      </>
    : <>
        <img src={GasMeterIcon} alt='Gas Meter' />
        <p>Select manufacturer k-factor for gas meter</p>
        {canEditMeterConfig && (
          <AddButtonContainer>
            <Button2 type='secondary' onClick={() => openKFactorModal(false)}>
              Add A New K-Factor
            </Button2>
          </AddButtonContainer>
        )}
      </>
  return <KFactorContainer>{toRender}</KFactorContainer>
}

export default GasMeterConfiguration
