import type { ComponentType } from 'react'
import styled from 'styled-components'

const LoadingStyled: ComponentType<{
  color: string
}> = styled.div`
  align-items: center;
  display: flex;
  font-size: 10px;
  justify-content: center;
  width: 50px;

  > div {
    animation: ra-stretchdelay 1s infinite ease-in-out;
    background-color: ${({ color }) => color};
    border-radius: 2px;
    display: inline-block;
    margin: 1.5px;
    width: 4px;

    &:nth-child(1) {
      animation-delay: -1.1s;
      height: 12px;
      opacity: 0.8;
    }

    &:nth-child(2) {
      animation-delay: -1.1s;
      height: 20px;
    }

    &:nth-child(3) {
      height: 12px;
      opacity: 0.8;
    }
  }

  @keyframes ra-stretchdelay {
    20% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0.5);
    }
  }
`
type FTProps = {
  color: string
}
export default ({ color = '#fff' }: FTProps) => (
  <LoadingStyled color={color}>
    <div />
    <div />
    <div />
  </LoadingStyled>
)
