import styled from 'styled-components'
import { ReactTableWrapperStyles } from '../../components/RedaptiveReactTable7'
import { colors } from '../../utils/themes'

export const Wrapper = styled.div``

export const PanelHeading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dbdbdb;
  color: #4a4a4a;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
`

export const Actions = styled.span`
  display: flex;
  justify-content: space-between;
  width: 28%;
  color: ${colors.blue15};
  cursor: pointer;
`

export const PageSize = styled.div`
  position: relative;
  right: 0;
`

export const SpinnerWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 60%;
`

export const NoData = styled.div`
  display: grid;
  place-items: center;
`

export const TableWrapperStyled = styled.div`
  td {
    background-color: transparent;
    border: none;
  }
  thead {
    border: none;
  }
  th {
    background-color: white;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: ${colors.gray};
    font-family: 'Avenir Next';

    input {
      width: 65%;
      padding-left: 10px !important;
      margin: 0;
    }
    .material-icons {
      display: none;
    }
  }

  ${ReactTableWrapperStyles} {
    border: none;
  }
`

export const NoDataText = styled.div`
  font-size: 16px;
  margin-top: 30px;
`

export const Link = styled.div`
  display: flex;
  align-items: center;
`

export const Cell = styled.span<{ danger: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => props.danger && colors.red};
`

export const Error = styled.div`
  display: grid;
  place-items: center;
  margin-top: 100px;
  font-size: 16px;
  color: ${colors.red};
`

export const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-left: 60px;
`
