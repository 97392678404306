import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { STORAGE_LOGIN_TOKEN } from '../constants'

type FTProps = {
  children: React.ReactNode
}
/* TODO: Provided this approach continues to hold water, move away from multiple
   PrivateRoute definitions and instead nest regular Routes under this.

   TODO: Pass in authenticated as a prop, derived from the Redux store, rather
   than the hacky approach of hitting localStorage (let the reducer handle that)
*/

export default class AuthenticatedRoutes extends React.Component<FTProps> {
  static LOGIN_PATH = '/login'

  static HELP_PATH = '/help'

  static FORGOT_PATH = '/forgot'

  static UNAUTHENTICATED = [
    '/version',
    AuthenticatedRoutes.LOGIN_PATH,
    AuthenticatedRoutes.HELP_PATH,
    AuthenticatedRoutes.FORGOT_PATH,
  ]

  static isAuthenticated() {
    return !!localStorage.getItem(STORAGE_LOGIN_TOKEN)
  }

  render() {
    const { children, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={(props) => {
          if (AuthenticatedRoutes.isAuthenticated()) {
            return children
          }

          if (
            AuthenticatedRoutes.UNAUTHENTICATED.includes(
              props.location.pathname,
            )
          ) {
            return null
          }

          const search =
            props.location.pathname === '/not-authorized' ?
              ''
            : `?destination=${props.location.pathname}`
          return (
            <Redirect
              to={{
                pathname: AuthenticatedRoutes.LOGIN_PATH,
                state: {
                  from: props.location,
                },
                search,
              }}
            />
          )
        }}
      />
    )
  }
}
