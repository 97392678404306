import styled from 'styled-components'
import Input from '../Input'
import { fieldNameMap } from '../../ducks/meters/generation'
import TextArea from '../TextArea'

export const FieldContainerStyled = styled.div`
  max-width: 450px;
  position: relative;
  margin-top: 24px;
  &.w-100 {
    max-width: 100%;
  }
  .ListSelector-Dropdown {
    &--wrapper {
      margin-top: 4px;
    }
  }
`
export const ErrorStyled = styled.p`
  display: block;
  font-size: 12px;
  color: #c62b2b;
  margin-top: 4px;
`
export const LabelStyled = styled.label`
  font-family: 'Avenir Next';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  span {
    color: #c02f2f;
  }
`

export const LableSupportText = styled.p`
  font-family: 'Avenir Next';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #4a4a4a;
  margin: 8px 0 0;
`
export const InputStyled = styled(Input)<{
  disabled?: boolean
  shiftError?: boolean
}>`
  display: block;
  width: 100%;
  height: 34px;
  background: ${(props) =>
    props.disabled ?
      'linear-gradient(0deg, rgba(224, 224, 224, 0.32) 0%, rgba(224, 224, 224, 0.32) 100%), #FFF;'
    : '#FFF'};
  color: #162447;
  margin-top: 4px;
  .input__error {
    display: block;
    font-size: 12px;
    color: #c62b2b;
    margin: 0px;
    top: 50%;
    right: 5px;
    left: auto;
    transform: translateY(-50%);
    ${(props) =>
      props.shiftError &&
      `
      right: 20px;
    `}
  }
`
export const TextAreaStyled = styled(TextArea)`
  display: block;
  width: 100%;
  padding: 2px 8px;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  backgground: #fff;
  font-family: 'Public Sans', sans-serif;
  margin-top: 4px;
`

export const RadioButtonContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  div {
    margin-right: 16px;
    ${LabelStyled} {
      margin-left: 8px;
    }
  }
`

export const PageHeaderStyled = styled.div`
  width: 100%;
  height: 38px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  p {
    color: #4a4a4a;
    font-family: 'Avenir Next';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
`
export const ClearBtnStyled = styled.div<{
  disabled?: boolean
}>`
  display flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  color: #337AB7;
  margin-right: 12px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity:${({ disabled }) => (disabled ? '0.5' : '1')};
  svg {
    margin-left: 5px;
    margin-top: 7px;
  }
`

export const PageBodyStyled = styled.div`
  width: 100%;
  &.last {
    margin-bottom: 100px;
  }
`

export const validPanelTypes = [
  {
    id: 'SINGLE_PHASE_3_WIRE',
    name: fieldNameMap.get('SINGLE_PHASE_3_WIRE'),
  },
  {
    id: 'THREE_PHASE_3_WIRE_DELTA',
    name: fieldNameMap.get('THREE_PHASE_3_WIRE_DELTA'),
  },
  {
    id: 'THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA',
    name: fieldNameMap.get('THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA'),
  },
  {
    id: 'THREE_PHASE_3_WIRE_WYE',
    name: fieldNameMap.get('THREE_PHASE_3_WIRE_WYE'),
  },
  {
    id: 'THREE_PHASE_4_WIRE_DELTA',
    name: fieldNameMap.get('THREE_PHASE_4_WIRE_DELTA'),
  },
  {
    id: 'THREE_PHASE_4_WIRE_WYE',
    name: fieldNameMap.get('THREE_PHASE_4_WIRE_WYE'),
  },
  {
    id: 'OTHER',
    name: fieldNameMap.get('OTHER'),
  },
]

export const validPanelVoltages = {
  SINGLE_PHASE_3_WIRE: [
    {
      id: '_120_240V',
      name: fieldNameMap.get('_120_240V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_4_WIRE_WYE: [
    {
      id: '_208Y_120V',
      name: fieldNameMap.get('_208Y_120V'),
    },
    {
      id: '_400Y_230V',
      name: fieldNameMap.get('_400Y_230V'),
    },
    {
      id: '415Y_240V',
      name: fieldNameMap.get('_415Y_240V'),
    },
    {
      id: '_480Y_277V',
      name: fieldNameMap.get('_480Y_277V'),
    },
    {
      id: '_600Y_347V',
      name: fieldNameMap.get('_600Y_347V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_3_WIRE_DELTA: [
    {
      id: '_230V',
      name: fieldNameMap.get('_230V'),
    },
    {
      id: '_240V',
      name: fieldNameMap.get('_240V'),
    },
    {
      id: '_400V',
      name: fieldNameMap.get('_400V'),
    },
    {
      id: '_480V',
      name: fieldNameMap.get('_480V'),
    },
    {
      id: '_600V',
      name: fieldNameMap.get('_600V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_4_WIRE_DELTA: [
    {
      id: '_120_208_240V_SINGLE_PHASE',
      name: fieldNameMap.get('_120_208_240V_SINGLE_PHASE'),
    },
    {
      id: '_120_208_240V_THREE_PHASE',
      name: fieldNameMap.get('_120_208_240V_THREE_PHASE'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_3_WIRE_WYE: [
    {
      id: '_480V',
      name: fieldNameMap.get('_480V'),
    },
    {
      id: '_600V',
      name: fieldNameMap.get('_600V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA: [
    {
      id: '_240V',
      name: fieldNameMap.get('_240V'),
    },
    {
      id: '_480V',
      name: fieldNameMap.get('_480V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  OTHER: [
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
}

export interface PanelFieldValues {
  name: string
  description: string
  opportunityId: string
  floorNumber: string
  location: string
  networkAvailability: boolean | undefined
  networkStrength: string | null
  type: string
  voltage: string
  amperage: string
  isNumbered: boolean
  powerSourceLevel: string
  parentPanelId: string | null
  numberOfCircuits: number | null
  leftSidePanel: string | null
  rightSidePanel: string | null
  phasingPattern: string | null
  numberOfSwitches: number | null
  auditorName: string
  auditDate: string
  auditNotes: string
}
