import * as consts from '../../constants'
import moment from 'moment'

export const getProgramStartDate = () =>
  moment('20220101', consts.URL_DATE_FORMAT)
export const format = 'MM/DD/YYYY'

export const baseOptions = [
  {
    label: 'Last 24 hours',
    value: consts.LAST_24_HOURS,
  },
  {
    label: 'Last 3 Days',
    value: consts.LAST_3_DAYS,
  },
  {
    label: 'Last 7 Days',
    value: consts.LAST_7_DAYS,
  },
  {
    label: 'This Month',
    value: consts.THIS_MONTH,
  },
  {
    label: 'All',
    value: consts.PROGRAM_TO_DATE,
  },
]

export const breadcrumbs = [
  {
    href: '/reports',
    text: 'Tools & Reports',
  },
  {
    href: '/reports/electron-verify-dashboard',
    text: 'Electron Verify Dashboard',
  },
]

export const defaultElectronMeterVerifyHeaders = [
  { id: 'macAddress', label: 'Meter MAC' },
  { id: 'evTestDate', label: 'EV Test Date' },
  { id: 'siteAddress', label: 'Site Address' },
  { id: 'customerName', label: 'Customer' },
  { id: 'roid', label: 'ROID' },
  { id: 'primaryPanelName', label: 'Panel Name' },
  { id: 'testRunBy', label: 'Test Run By' },
  { id: 'labelCheckStatus', label: 'Label Check Status' },
  { id: 'dataCheckStatus', label: 'Data Check Status' },
  { id: 'meterStatus', label: 'Online Status' },
  { id: 'lastReportedDate', label: 'Last Reported Date' },
]

export const defaultElectronMeterVerifyWidths = {
  macAddress: {
    minWidth: 150,
    maxWidth: 200,
  },
  evTestDate: {
    minWidth: 240,
    maxWidth: 220,
  },
  siteAddress: {
    minWidth: 360,
    maxWidth: 280,
  },
  customerName: {
    minWidth: 210,
    maxWidth: 230,
  },
  roid: {
    minWidth: 120,
    maxWidth: 130,
  },
  primaryPanelName: {
    minWidth: 160,
    maxWidth: 160,
  },
  labelCheckStatus: {
    minWidth: 150,
    maxWidth: 160,
  },
  dataCheckStatus: {
    minWidth: 150,
    maxWidth: 160,
  },
  testRunBy: {
    minWidth: 250,
    maxWidth: 220,
  },
  meterStatus: {
    minWidth: 120,
    maxWidth: 120,
  },
  lastReportedDate: {
    minWidth: 240,
  },
}
