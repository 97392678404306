import { takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import { defaultHeaders, consoleBaseUrl } from '../../api'
import { handleSagaError } from '../../sagas/utils'
import { handleAxiosError } from '../../api/utils'
import { makeActionTypes } from '../utils'
import { isVariantActive } from '../../utils'
import variantData from './variantData.json'

export const types = {
  ...makeActionTypes('FETCH_UTILITY_DATA'),
  ...makeActionTypes('GENERATE_PRE_SIGNED_URL'),
  CHANGE_SORT: 'CHANGE_SORT',
  CHANGE_PAGEABLE: 'CHANGE_PAGEABLE',
}
export const fetchUtilityDataEntity = (state) =>
  state.uploadUtilityData.fetchUtilityData
export const initialState = {
  data: {
    items: [],
    downloadUrls: null,
    sort: {
      property: 'created',
      order: 'desc',
    },
    pageable: {
      page: 0,
      size: 10,
      totalPages: 0,
      totalElements: 0,
    },
  },
  meta: {
    error: '',
    isLoading: false,
  },
}

const fetchUtilityDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_UTILITY_DATA:
      return {
        data: { ...state.data, items: [], downloadUrls: null },
        meta: {
          error: '',
          isLoading: true,
        },
      }

    case types.FETCH_UTILITY_DATA_SUCCESS:
      return {
        ...state,
        meta: {
          error: '',
          isLoading: false,
        },
        data: { ...action.payload },
      }

    case types.FETCH_UTILITY_DATA_ERROR:
      return {
        ...state,
        meta: {
          error: action.error,
          isLoading: false,
        },
      }

    case types.GENERATE_PRE_SIGNED_URL:
      return {
        data: { ...state.data, downloadUrls: null },
        meta: {
          error: '',
          isLoading: true,
        },
      }

    case types.GENERATE_PRE_SIGNED_URL_SUCCESS:
      return {
        data: { ...state.data, downloadUrls: { ...action.payload } },
        meta: {
          error: '',
          isLoading: false,
        },
      }

    case types.GENERATE_PRE_SIGNED_URL_ERROR:
      return {
        ...state,
        meta: {
          error: action.error,
          isLoading: false,
        },
      }

    case types.CHANGE_SORT:
      return { ...state, data: { ...state.data, sort: { ...action.payload } } }

    case types.CHANGE_PAGEABLE:
      return {
        ...state,
        data: { ...state.data, pageable: { ...action.payload } },
      }

    default:
      return state
  }
}

export default fetchUtilityDataReducer
export const actions = {
  fetchUtilityData: (params) => ({
    type: types.FETCH_UTILITY_DATA,
    ...params,
  }),
  fetchPreSignedUrls: (params) => ({
    type: types.GENERATE_PRE_SIGNED_URL,
    ...params,
  }),
  changeSort: (params) => ({
    type: types.CHANGE_SORT,
    payload: { ...params },
  }),
  changePageable: (params) => ({
    type: types.CHANGE_PAGEABLE,
    payload: { ...params },
  }),
}
export class API {
  static fetchUtilityData({
    pageable: { page, size },
    sort: { order, property },
  }) {
    if (isVariantActive('ORN38mock')) {
      return Promise.resolve(variantData.fetchUtilityData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 500)),
      )
    }

    const url = `${consoleBaseUrl()}/utility/api/utility/data/history`
    const params = {
      page,
      size,
      sort: `${property},${order}`,
    }
    return axios
      .get(url, {
        headers: defaultHeaders(),
        params,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static fetchPreSignedUrls(id) {
    const url = `${consoleBaseUrl()}/utility/api/utility/data/${id}/pre-signed-url/generate`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

const fetchUtilityDataMapper = (data) => ({
  items: data.content,
  sort: {
    property: data.sort[0].property,
    order: data.sort[0].direction.toLowerCase(),
  },
  pageable: {
    page: data.pageable.pageNumber,
    size: data.pageable.pageSize,
    totalPages: data.totalPages,
    totalElements: data.totalElements,
  },
})

function* fetchUtilityDataSaga({ type, ...params }) {
  try {
    const payload = yield call(API.fetchUtilityData, params)
    yield put({
      type: types.FETCH_UTILITY_DATA_SUCCESS,
      payload: fetchUtilityDataMapper(payload),
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_UTILITY_DATA_ERROR, error)
  }
}

function* fetchPreSignedUrlsSaga({ id }) {
  try {
    const payload = yield call(API.fetchPreSignedUrls, id)
    yield put({
      type: types.GENERATE_PRE_SIGNED_URL_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.GENERATE_PRE_SIGNED_URL_ERROR, error)
  }
}

export const sagas = [
  takeEvery(types.FETCH_UTILITY_DATA, fetchUtilityDataSaga),
  takeEvery(types.GENERATE_PRE_SIGNED_URL, fetchPreSignedUrlsSaga),
]
