import { ComponentType, PureComponent, SyntheticEvent } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DateRangePicker } from 'react-dates'
import { START_DATE, END_DATE } from 'react-dates/constants'
import {
  selectSiteEntity,
  selectSiteListEntity,
  actions as siteActions,
} from '../../ducks/sites'
import {
  selectCustomerEntity,
  selectCustomerListEntity,
  actions as customerActions,
} from '../../ducks/customers'
import { actions as modalActions } from '../../ducks/modal'
import { actions as exportActions, JOB_TYPES } from '../../ducks/meterExport'
import Breadcrumbs from '../../components/Breadcrumbs'
import Title from '../../components/Title'
import WarningMessage from '../../components/WarningMessage'
import Description from '../../components/Description'
import Button from '../../components/Button'
import FormButtons from '../../components/FormButtons'
import Spinner from '../../components/Spinner'
import IonIcon from '../../components/IonIcon'
import FormSection from '../../components/FormSection'
import FormField from '../../components/FormField'
import ListSelector, { SelectStyles } from '../../components/ListSelector'
import Link from '../../components/StyledLink'
import UnsavedChanges from '../../components/UnsavedChanges'
import { makeListSelectorItem, naturallySortEmptyLast } from '../../utils'
import { SITE_SOURCES } from '../../constants/strings'
import type { FTRouterLocation, FTSite, FTSiteSource } from '../../types'
import Checkbox from '../../components/Checkbox'
import type { FTMeasurementTypeMeta } from '../../ducks/circuits'
import {
  getCombinedLabelFromMeasurementType,
  measurementTypesBigBangMap,
  measurementTypesNebulaMap,
} from '../../ducks/circuits'
import type { FTCustomer } from '../../ducks/customers'
import type { FTMeterExportState } from '../../ducks/meterExport'
import type { FTMeter } from '../../ducks/meters'
import {
  actions as meterActions,
  selectMeterList,
  selectMeterListEntity,
} from '../../ducks/meters'
import ButtonLink from '../../components/ButtonLink'

const EXPECTED_MAX_WAIT = '10 minutes'
const ExportInfoStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-shadow: 0 1px 0 0 #ffffff;
  user-select: none;
`
const DownloadSectionStyled = styled.div`
  > .Spinner {
    float: left;
    margin-right: 20px;
  }
`
const FormSectionStyled = styled(FormSection)`
  float: left;
  background-color: purple;
`
const MeterExportFormPageStyled = styled.div`
  ${WarningMessage} {
    margin-top: 20px;
  }

  ${DownloadSectionStyled} ${WarningMessage} {
    margin-bottom: 15px;
    font-weight: 600;
  }

  .FormButtons {
    margin-top: 42px;
  }

  ${ExportInfoStyled} {
    margin-bottom: 80px;
    min-height: 71px;
  }

  .DateInput_input {
    width: 150px;
    font-weight: normal;
  }

  .DateInput {
    width: auto;
  }

  ${SelectStyles},
  .DateRangePicker {
    max-width: 370px;
  }
`
const MeasurementTypesSectionStyled = styled.div``
const MeasurementTypesTopSectionStyled = styled.div`
  margin-bottom: 12px;
`
const MeasurementTypesLabelStyled = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  padding-right: 20px;
`
const MeasurementTypesSectionsStyled = styled.div`
  display: flex;
`
const MeasurementTypesSectionFirstStyled = styled.div`
  padding-right: 30px;
`
const MeasurementTypesCheckboxesFirstStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 30px;
`
const CheckboxWithLabelStyled: ComponentType<{
  disabled: boolean
}> = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  justify-content: flex-start;
`
const DownloadSuccessStyled = styled.div`
  margin-top: 10px;
  font-weight: 200;

  .IonIcon {
    margin-right: 21px;
    color: #0194d7;
  }

  .IonIcon > span {
    top: 2px;
  }
`
const LinkStyled = styled(Link)`
  display: inline;
`
const FormStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 60px;

  > ${Description} {
    color: #4a4a4a;
    margin-top: 0;
    margin-left: 40px;

    ul {
      padding-left: 27px;
      line-height: 28px;
      margin-top: 5px;
      li {
        padding-left: 6px;
      }
    }
  }

  > div:first-child {
    min-width: 390px;
  }
`
const DateRangeStyled = styled.div`
  margin-bottom: 30px;

  .DateRangePicker {
    width: 100%;
  }

  .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    width: calc(100% - 1px);
    box-sizing: border-box;
  }

  .DateRangePickerInput input {
    width: 129px;
    font-size: 14px;
    font-family: 'Avenir Next';
    color: #4a4a4a;
    font-weight: 400;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }

  .DateRangePickerInput_arrow {
    margin-left: 3px;
    margin-right: 10px;
    margin-top: 2px;
    max-width: 13px;
  }

  .ion-android-arrow-forward {
    font-size: 20px;
    font-weight: 500;
  }

  .DateRangePickerInput_calendarIcon {
    margin-left: 0;
    margin-right: 0;
    outline: none;
    padding-top: 7px;
    padding-bottom: 8px;
  }
`
const ConfirmModalContentStyled = styled.div`
  font-weight: 600;
  text-align: center;
  margin-bottom: 22px;
`
type FTMeasurementTypesMapWithCombinedLabel = Record<
  string,
  FTMeasurementTypeMeta & {
    labelWithUnit: string
  }
>
const measurementTypesMap: FTMeasurementTypesMapWithCombinedLabel = {}
Object.keys(measurementTypesNebulaMap).forEach((measurementType) => {
  measurementTypesMap[measurementType] = {
    ...measurementTypesNebulaMap[measurementType],
    labelWithUnit: getCombinedLabelFromMeasurementType(
      measurementType,
      measurementTypesNebulaMap,
    ),
  }
})
Object.keys(measurementTypesBigBangMap).forEach((measurementType) => {
  measurementTypesMap[measurementType] = {
    ...measurementTypesBigBangMap[measurementType],
    labelWithUnit: getCombinedLabelFromMeasurementType(
      measurementType,
      measurementTypesBigBangMap,
    ),
  }
})
const intervalItems = [
  makeListSelectorItem('15', 'Fifteen-minute Interval Data'),
  makeListSelectorItem('1', 'One-minute Interval Data'),
]
const intervalItemsById = intervalItems.reduce(
  (acc, cur) => ({ ...acc, [cur.id]: cur }),
  {},
)
const defaultIntervalItem = intervalItems[0]
type FTProps = FTRouterLocation & {
  actions: Record<string, any>
  customerEntity: {
    item?: FTCustomer
  }
  customerListEntity: {
    items: Array<FTCustomer>
  }
  customersById: Record<string, FTCustomer>
  isSingleSiteView: boolean
  meterExport: FTMeterExportState
  meters: Array<FTMeter>
  metersLoading: boolean
  siteEntity: {
    item?: FTSite
  }
  siteListEntity: {
    items: Array<FTSite>
  }
  sitesById: Record<string, FTSite>
}
type FTState = {
  startDate: Moment | null | undefined
  endDate: Moment | null | undefined
  focusedInput: START_DATE | END_DATE | null
  metersRequested: boolean
  selectedCustomerId: string | null | undefined
  selectedIntervalId: string | null | undefined
  selectedIntervalName: string | null | undefined
  selectedSiteId: string | null | undefined
  selectedCustomerName: string
  selectedMeasurementTypes: Array<string>
  selectedSiteName: string
  siteHasBigBangMeters: boolean
  siteHasEnertivMeters: boolean
  siteHasNebulaMeters: boolean
  siteHasNonRedaptiveMeters: boolean
}

class MeterExportFormPage extends PureComponent<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      endDate: null,
      focusedInput: null,
      metersRequested: false,
      selectedCustomerId: null,
      selectedCustomerName: '',
      selectedIntervalId: defaultIntervalItem.id,
      selectedIntervalName: defaultIntervalItem.name,
      selectedMeasurementTypes: [],
      selectedSiteId: null,
      selectedSiteName: '',
      siteHasBigBangMeters: false,
      siteHasEnertivMeters: false,
      siteHasNebulaMeters: false,
      siteHasNonRedaptiveMeters: false,
      startDate: null,
    }
  }

  getDefaultMeasurementTypes = (
    isNebula: boolean = false,
    isEnertiv: boolean = false,
  ) =>
    (
      this.state.siteHasNebulaMeters ||
      this.state.siteHasEnertivMeters ||
      isNebula ||
      isEnertiv
    ) ?
      ['activePower']
    : ['power']

  componentDidMount() {
    const {
      actions,
      match: {
        params: { siteId },
      },
      location: { pathname },
      history,
    } = this.props

    if (siteId) {
      actions.fetchSite({
        siteId,
      })
      actions.fetchMeterList({
        useDeprecatedMeterList: true,
        siteId,
        pageNumber: 1,
        pageSize: 1000,
      })
    } else {
      // Clear any existing sites first if we're on the Tools & Reports view
      actions.clearSites()
    }

    this.props.actions.clearJob()
    actions.fetchAllCustomers({
      orderBy: {
        field: 'name',
        sort: 'ASC',
      },
      pageSize: 1000,
    })

    if (pathname.endsWith('/download')) {
      history.push(pathname.replace(/\/meter-export.*$/, '/meter-export'))
    }
  }

  componentDidUpdate(prevProps: FTProps) {
    const {
      meters: metersPrev,
      siteEntity: { item: prevSite },
      sitesById: prevSitesById,
    } = prevProps
    const {
      actions,
      match: {
        params: { siteId },
      },
      meters,
      siteEntity: { item: site },
      sitesById,
    } = this.props

    if (!isEqual(meters, metersPrev)) {
      const siteHasNebulaMeters = !!meters.find((meter) => meter.isNebula)
      const siteHasEnertivMeters = !!meters.find(
        (meter) => meter.source === 'ENERTIV',
      )
      this.setState({
        selectedMeasurementTypes: this.getDefaultMeasurementTypes(
          siteHasNebulaMeters,
          siteHasEnertivMeters,
        ),
        siteHasBigBangMeters: !!meters.find((meter) => meter.isBigBang),
        siteHasNonRedaptiveMeters: !!meters.find(
          (meter) => meter.source !== 'REDAPTIVE',
        ),
        siteHasNebulaMeters,
        siteHasEnertivMeters,
      })
    }

    const siteIds = Object.keys(sitesById)

    if (sitesById !== prevSitesById && siteIds.length === 1) {
      const siteFirst = sitesById[siteIds[0]]
      const { id: siteFirstId } = siteFirst
      this.setState({
        selectedMeasurementTypes: this.getDefaultMeasurementTypes(),
        siteHasBigBangMeters: false,
        siteHasNebulaMeters: false,
        siteHasEnertivMeters: false,
        selectedSiteId: siteFirstId,
        selectedSiteName: siteFirst.validName || siteFirst.display,
      })
      actions.fetchMeterList({
        useDeprecatedMeterList: true,
        siteId: siteFirstId,
        pageNumber: 1,
        pageSize: 1000,
      })
    }

    const { selectedSiteName } = this.state
    const prevName = prevSite ? prevSite.validName : ''
    const name = site ? site.validName : ''

    if (siteId && ((!prevName && name) || (name && !selectedSiteName))) {
      this.setState({
        selectedSiteName: name,
      })
    }
  }

  componentWillUnmount() {
    this.props.actions.clearJob()
  }

  getMeasurementTypesOrdered = () =>
    (this.state.siteHasNebulaMeters && [
      'rmsCurrent',
      'activePower',
      'rmsVoltage',
      'fundamentalPower',
      'phaseAngle',
      'reactivePower',
      'powerFactor',
      'apparentPower',
      'frequency',
      'rmsApparentPower',
    ]) ||
    (this.state.siteHasEnertivMeters && ['activePower']) || ['power']

  getBigBangMeasurementTypes = () => Object.keys(measurementTypesBigBangMap)

  onDatesChange = ({ startDate, endDate }) => {
    const start = startDate
    let end = endDate

    if (startDate && endDate) {
      const count = endDate.diff(startDate, 'days')

      if (count > 31) {
        end = startDate.clone().add(31, 'days')
      }
    }

    this.setState({
      startDate: start,
      endDate: end,
    })
  }

  onFocusChange = (focusedInput) =>
    this.setState({
      focusedInput,
    })

  onCustomerChange = (props) => {
    const { value: customerId, label: selectedCustomerName } = props
    this.props.actions.clearSites()

    if (customerId !== null) {
      this.props.actions.fetchAllSites({
        customerId,
        pageSize: 1000,
        pageNumber: 1,
      })
    }

    this.setState((prev) => ({
      ...prev,
      selectedCustomerId: customerId,
      selectedCustomerName,
      selectedSiteId: null,
      selectedSiteName: '',
      selectedIntervalId: defaultIntervalItem.id,
      selectedIntervalName: defaultIntervalItem.name,
    }))
  }

  onSiteChange = ({ value: selectedSiteId, label: selectedSiteName }) => {
    const { actions } = this.props

    if (this.state.selectedSiteId !== selectedSiteId) {
      this.setState({
        selectedMeasurementTypes: [],
        siteHasBigBangMeters: false,
        siteHasNebulaMeters: false,
        siteHasEnertivMeters: false,
        selectedSiteId,
        selectedSiteName,
      })
      actions.fetchMeterList({
        useDeprecatedMeterList: true,
        siteId: selectedSiteId,
        pageNumber: 1,
        pageSize: 1000,
      })
    }
  }

  onIntervalChange = ({
    value: selectedIntervalId,
    label: selectedIntervalName,
  }) => {
    this.setState((prev) => {
      const unsetEndDate =
        prev.endDate &&
        selectedIntervalId === '1' &&
        this.isOutsideRange(prev.endDate, END_DATE, selectedIntervalId)
      const endDate = unsetEndDate ? null : prev.endDate
      return { ...prev, endDate, selectedIntervalId, selectedIntervalName }
    })
  }

  onSubmit = () => {
    const {
      history,
      location: { pathname },
      match: {
        params: { siteId },
      },
      actions,
    } = this.props
    const {
      endDate,
      selectedIntervalId,
      selectedMeasurementTypes,
      selectedSiteId,
      siteHasBigBangMeters,
      siteHasNebulaMeters,
      startDate,
    } = this.state
    const format = 'YYYY-MM-DD'

    const submitAction = () => {
      actions.submitJob({
        endDate: !!endDate && endDate.format(format),
        jobType: this.getJobType(siteId || selectedSiteId, selectedIntervalId),
        measurementTypes: selectedMeasurementTypes.join(','),
        siteId: siteId || selectedSiteId,
        startDate: !!startDate && startDate.format(format),
      })
      history.push(`${pathname}/download`)
    }

    if (siteHasBigBangMeters && siteHasNebulaMeters) {
      if (!selectedMeasurementTypes.includes('activePower')) {
        actions.showConfirmModal({
          modalWidth: '430px',
          onPrimaryAction: actions.hideModal,
          primaryActionText: 'OK',
          renderContent: () => (
            <ConfirmModalContentStyled>
              {`The selected site has Redaptive RPM-E-4801 meters installed.
              Please make sure that Active Power (kW) is one of
              the selected data parameters.`}
            </ConfirmModalContentStyled>
          ),
        })
      } else {
        actions.showConfirmModal({
          modalWidth: '430px',
          onPrimaryAction: submitAction,
          onSecondaryAction: actions.hideModal,
          primaryActionText: 'OK',
          renderContent: () => (
            <ConfirmModalContentStyled>
              {`The selected site has Redaptive RPM-E-4801 meters installed.
            Only Power (kW) will be returned for those meters.`}
            </ConfirmModalContentStyled>
          ),
          secondaryActionText: 'Cancel',
        })
      }
    } else {
      submitAction()
    }
  }

  onDownload = () => {
    const {
      meterExport: {
        job: { id: jobId },
      },
      actions,
    } = this.props
    const { selectedIntervalId, selectedSiteName } = this.state
    const { startDate, endDate } = this.state
    let filename = 'meter-data-export'

    if (selectedSiteName && startDate && endDate) {
      const format = 'YYYY-MM-DD'
      filename = `${selectedSiteName} ${startDate.format(
        format,
      )} - ${endDate.format(format)}`

      if (selectedIntervalId === '1') {
        filename = `1m ${filename}`
      }
    }

    actions.getJobResults({
      jobId,
      filename,
    })
  }

  onCancel = () => {
    const {
      history,
      actions,
      location: { pathname },
    } = this.props
    actions.clearJob()
    history.push(pathname.replace(/\/meter-export.*$/, ''))
  }

  getErrorMessage = () => {
    const { isSingleSiteView } = this.props
    const { startDate, endDate, selectedCustomerId, selectedSiteId } =
      this.state
    const messages = []

    if (!isSingleSiteView && !selectedCustomerId) {
      messages.push(<div key='exportCustomer'>* Please select a customer.</div>)
    }

    if (!isSingleSiteView && !selectedSiteId) {
      messages.push(<div key='exportSite'>* Please select a site.</div>)
    }

    if (!startDate || !endDate) {
      messages.push(
        <div key='exportDates'>
          * Please select a start date and an end date.
        </div>,
      )
    }

    return messages
  }

  getExportDescription = () => {
    const {
      siteEntity: { item: site },
      customerEntity: { item: customer },
      isSingleSiteView,
    } = this.props
    const { selectedCustomerName, selectedIntervalName, selectedSiteName } =
      this.state
    let customerName
    let siteName

    if (isSingleSiteView) {
      customerName = customer ? customer.validName : ''
      siteName = site ? site.validName : ''
    } else {
      customerName = selectedCustomerName || 'All Customers'
      siteName = selectedSiteName || 'All Sites'
    }

    return [customerName, siteName, selectedIntervalName].join(', ')
  }

  getSelectedSiteMeterType = (): FTSiteSource | null | undefined => {
    const { isSingleSiteView, siteEntity, sitesById } = this.props
    const { selectedSiteId } = this.state

    if (isSingleSiteView) {
      return siteEntity.item ? siteEntity.item.source : null
    }

    if (selectedSiteId && sitesById[selectedSiteId]) {
      return sitesById[selectedSiteId].source
    }

    return null
  }

  getJobType = (siteId, intervalId) => {
    if (intervalId === '1') {
      return JOB_TYPES.oneMinute
    }

    if (siteId) {
      return JOB_TYPES.singleSite
    }

    return JOB_TYPES.allSites
  }

  canSubmit = () => {
    const { isSingleSiteView } = this.props
    const {
      startDate,
      endDate,
      selectedCustomerId,
      selectedMeasurementTypes,
      selectedSiteId,
    } = this.state

    if (!startDate || !endDate || !selectedMeasurementTypes.length) {
      return false
    }

    if (isSingleSiteView) {
      return true
    }

    return !!(selectedCustomerId && selectedSiteId)
  }

  // isOutsideRange is passed to the DatePicker to let it know which days are
  // not selectable. Each rule is associated with a comment in this function.
  isOutsideRange = (
    baseDay,
    focusedInputOverride = null,
    selectedIntervalIdOverride = null,
  ) => {
    const {
      startDate: baseStartDate,
      focusedInput: focusedInputState,
      selectedIntervalId: selectedIntervalIdState,
    } = this.state
    const focusedInput = focusedInputOverride || focusedInputState
    const selectedIntervalId =
      selectedIntervalIdOverride || selectedIntervalIdState
    // Utilize startOf('day') to provide a baseline for comparison
    // the `baseDay` provided by DatePicker is at noon for each day.
    const day = baseDay.clone().startOf('day')
    const today = moment().startOf('day')
    const yesterday = moment().startOf('day').subtract(1, 'days')

    // startDate can never be later than yesterday.
    if (focusedInput === START_DATE && day.isAfter(yesterday)) {
      return true
    }

    if (focusedInput === END_DATE) {
      // endDate can never be later than today.
      if (day.isAfter(today)) {
        return true
      }

      if (baseStartDate) {
        const startDate = baseStartDate.clone().startOf('day')

        // endDate can never be on or before startDate
        if (day.isSameOrBefore(startDate)) {
          return true
        }

        // For fifteen-minute intervals, endDate can never be greater than 31
        // days after startDate. For one-minute intervals, endDate can never be
        // greater than 7 days after startDate.
        const endDate = selectedIntervalId === '1' ? 7 : 31

        if (day.isAfter(startDate.clone().add(endDate, 'days'))) {
          return true
        }
      }
    }

    return false
  }

  renderBreadcrumbs = () => {
    let items = []
    const {
      match: {
        params: { customerId, siteId },
      },
      customerEntity: { item: customer },
      siteEntity: { item: site },
    } = this.props

    if (customerId && customer) {
      items = [
        {
          href: '/account-management',
          text: 'Accounts',
        },
        {
          href: '/account-management/customers',
          text: 'Customers',
        },
        {
          href: `/account-management/customers/${customer.id}`,
          text: customer.name,
        },
        {
          href: `/account-management/customers/${customer.id}/sites/${site.id}`,
          text: site.display || site.validName,
        },
      ]
    } else if (siteId && site) {
      items = [
        {
          href: '/account-management',
          text: 'Accounts',
        },
        {
          href: '/account-management/sites',
          text: 'Sites',
        },
        {
          href: `/account-management/sites/${site.id}`,
          text: site.display || site.validName,
        },
      ]
    } else if (!customerId && !siteId) {
      items = [
        {
          href: '/reports',
          text: 'Tools & Reports',
        },
        {
          href: '/reports/meter-export',
          text: 'Meter Data Export',
        },
      ]
    }

    return <Breadcrumbs items={items} />
  }

  renderDownloadSection = () => {
    const {
      meterExport,
      siteEntity: { item: site },
    } = this.props
    const {
      loading,
      downloaded,
      error,
      job: { status, id },
    } = meterExport
    const { startDate, endDate } = this.state
    const polling = status !== 'SUCCESS'
    // eslint-disable-next-line
    const descInProgress = `Your data is being generated. Please do not refresh the page or navigate away during this process, otherwise your request will be lost. It may take up to ${EXPECTED_MAX_WAIT} for this process to complete.`
    const descReady = 'Your meter data is ready.'
    const message = status !== 'SUCCESS' ? descInProgress : descReady
    const exportDescription = this.getExportDescription()
    return (
      <DownloadSectionStyled>
        {!error && <Description>{message}</Description>}
        {((!polling && !id) || error) && (
          <WarningMessage>
            {error ||
              'Something went wrong with your request. Please cancel & try again.'}
          </WarningMessage>
        )}
        {polling && !error && <Spinner size='small' />}
        {site && startDate && endDate && (
          <ExportInfoStyled>
            <div>{exportDescription}</div>
            <div>
              {startDate.format('MMM D, YYYY')}
              {' - '}
              {endDate.format('MMM D, YYYY')}
            </div>
            {downloaded && (
              <DownloadSuccessStyled>
                <IonIcon iconClass='ion-checkmark-circled' />
                Downloaded
              </DownloadSuccessStyled>
            )}
          </ExportInfoStyled>
        )}

        {polling && <Button onClick={this.onCancel}>Cancel</Button>}
        {!polling && id && (
          <Button primary onClick={this.onDownload} loading={loading}>
            Download
          </Button>
        )}
      </DownloadSectionStyled>
    )
  }

  renderSingleSiteDescription = () => (
    <Description>
      {'Use this tool to export up to one month of fifteen-minute interval '}
      or one week of one-minute interval meter data:
      <ul>
        <li>
          {'The data will be delivered as a .zip file suitable for '}
          <b>in-depth site analysis</b>, including .csv data for all meters and
          all circuits.
        </li>
        <li>
          {`It may take up to ${EXPECTED_MAX_WAIT} to generate the .zip file.`}
        </li>
        <li>File size can be up to 50MB.</li>
        <li>
          {'To export billing data for all customers, all sites, '}
          <LinkStyled href='/reports/meter-export' to='/reports/meter-export'>
            go here.
          </LinkStyled>
        </li>
      </ul>
    </Description>
  )

  renderAllSitesDescription = () => (
    <Description>
      {'Use this tool to export up to one month of fifteen-minute interval '}
      or one week of one-minute interval meter data:
      <ul>
        <li>
          {
            'Selecting a single customer and site will output a .zip file suitable for'
          }{' '}
          <b>in-depth site analysis</b>, including .csv data for all meters and
          all circuits.
        </li>
        <li>
          {`It may take up to ${EXPECTED_MAX_WAIT} to generate the .zip file.`}
        </li>
        <li>File size can be up to 50MB.</li>
      </ul>
    </Description>
  )

  handleMeasurementTypeCheckboxWrapperClick = (event: SyntheticEvent<any>) => {
    const { target } = event
    const { attributes: targetAttributes, id: targetId, parentElement } = target
    const {
      attributes: parentAttributes,
      id: parentId,
      parentElement: parentParentElement,
    } = parentElement || {}
    const { attributes: parentParentAttributes, id: parentParentId } =
      parentParentElement || {}
    const checkboxMeasurementType = targetId || parentId || parentParentId || ''
    // $FlowFixMe
    const disabled =
      targetAttributes.getNamedItem?.('disabled') || // $FlowFixMe
      parentAttributes.getNamedItem?.('disabled') || // $FlowFixMe
      parentParentAttributes.getNamedItem?.('disabled')
    const { selectedMeasurementTypes, siteHasNonRedaptiveMeters } = this.state

    if (
      !checkboxMeasurementType || // Ensures that Active Power is always selected for sites that have
      // Non-Redaptive meters installed.
      (checkboxMeasurementType === 'activePower' &&
        siteHasNonRedaptiveMeters) || // Ensures that if the option is disabled, it is always selected.
      disabled
    ) {
      return
    }

    this.setState({
      selectedMeasurementTypes:
        selectedMeasurementTypes.includes(checkboxMeasurementType) ?
          selectedMeasurementTypes.filter(
            (measurementType) => measurementType !== checkboxMeasurementType,
          )
        : [...selectedMeasurementTypes, checkboxMeasurementType],
    })
  }

  renderFormSection = () => {
    const {
      isSingleSiteView,
      customerListEntity: { items: customers },
      customersById,
      match: {
        params: { siteId },
      },
      metersLoading,
      siteListEntity: { items: sites },
      sitesById,
    } = this.props
    const {
      selectedCustomerId,
      selectedIntervalId,
      selectedMeasurementTypes,
      selectedSiteId,
    } = this.state
    sites.sort((a, b) =>
      naturallySortEmptyLast(
        a.validName || a.displayName,
        b.validName || b.displayName,
      ),
    )
    const renderDescription =
      isSingleSiteView ?
        this.renderSingleSiteDescription
      : this.renderAllSitesDescription
    const selectedCustomerName =
      (selectedCustomerId && customersById[selectedCustomerId].name) || null
    const selectedSiteName =
      (selectedSiteId &&
        (sitesById[selectedSiteId].display ||
          sitesById[selectedSiteId].validName)) ||
      null
    const selectedCustomerItem = makeListSelectorItem(
      selectedCustomerId,
      selectedCustomerName,
    )
    const selectedIntervalItem =
      (selectedIntervalId && intervalItemsById[selectedIntervalId]) ||
      defaultIntervalItem
    const selectedSiteItem = makeListSelectorItem(
      selectedSiteId,
      selectedSiteName,
    )
    const selectedSiteMeterType = this.getSelectedSiteMeterType()
    let warningMessage = ''

    if (!this.canSubmit()) {
      warningMessage = this.getErrorMessage()
    }

    const enabledMeasurementTypes = this.getMeasurementTypesOrdered()

    const handleMeasurementTypesSelectAllClick = () => {
      this.setState({
        selectedMeasurementTypes: [...enabledMeasurementTypes],
      })
    }

    const handleMeasurementTypesSelectNoneClick = () => {
      this.setState({
        selectedMeasurementTypes:
          enabledMeasurementTypes.length === 1 ?
            this.getDefaultMeasurementTypes()
          : [],
      })
    }

    return (
      <FormStyled>
        <FormSectionStyled>
          {!isSingleSiteView && (
            <FormField
              renderField={() => (
                <ListSelector
                  alwaysDraw
                  inFormSection
                  items={customers}
                  notSetItemText='Select Customer'
                  notSetLabelText='Select Customer'
                  selectedItem={selectedCustomerItem}
                  showRequired={false}
                  unsettable
                  updateValue={this.onCustomerChange}
                />
              )}
              requiredText='*'
              title='Customer'
            />
          )}
          {!isSingleSiteView && (
            <FormField
              renderField={() => (
                <ListSelector
                  alwaysDraw
                  disabled={!selectedCustomerId}
                  inFormSection
                  items={sites.map(({ id, display, validName }) =>
                    makeListSelectorItem(id, validName || display),
                  )}
                  notSetItemText='Select Site'
                  notSetLabelText='Select Site'
                  selectedItem={selectedSiteItem}
                  unsettable
                  updateValue={this.onSiteChange}
                />
              )}
              requiredText='*'
              showRequired={false}
              title='Site'
            />
          )}
          <FormField
            renderField={() => (
              <ListSelector
                disabled={selectedSiteMeterType !== SITE_SOURCES.REDAPTIVE}
                items={intervalItems}
                selectedItem={selectedIntervalItem}
                inFormSection
                updateValue={this.onIntervalChange}
                unsettable={false}
                showRequired={false}
              />
            )}
            requiredText='*'
            showRequired={false}
            title='Export Type'
          />
          <DateRangeStyled>
            <FormField
              renderField={() => (
                <DateRangePicker
                  startDate={this.state.startDate}
                  startDateId='site-meter-form-start'
                  endDate={this.state.endDate}
                  endDateId='site-meter-form-end'
                  onDatesChange={this.onDatesChange}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={this.onFocusChange}
                  isOutsideRange={this.isOutsideRange}
                  customArrowIcon={<i className='ion-android-arrow-forward' />}
                  showDefaultInputIcon
                />
              )}
              requiredText='*'
              showRequired={false}
              title='Date Range'
            />
          </DateRangeStyled>

          {(siteId || selectedSiteId) && (
            <MeasurementTypesSectionStyled>
              <MeasurementTypesSectionsStyled>
                <MeasurementTypesSectionFirstStyled>
                  <MeasurementTypesTopSectionStyled>
                    <MeasurementTypesLabelStyled>
                      Data Parameters
                    </MeasurementTypesLabelStyled>
                    {enabledMeasurementTypes.length > 1 && (
                      <>
                        <ButtonLink
                          onClick={handleMeasurementTypesSelectAllClick}
                        >
                          Select All
                        </ButtonLink>
                        {' / '}
                        <ButtonLink
                          onClick={handleMeasurementTypesSelectNoneClick}
                        >
                          Select None
                        </ButtonLink>
                      </>
                    )}
                  </MeasurementTypesTopSectionStyled>
                  <MeasurementTypesCheckboxesFirstStyled>
                    {metersLoading && <Spinner inline size='micro' />}
                    {!metersLoading &&
                      enabledMeasurementTypes.map((measurementType) => {
                        if (
                          !enabledMeasurementTypes.includes(measurementType)
                        ) {
                          return ''
                        }

                        const disabled = enabledMeasurementTypes.length === 1
                        return (
                          <CheckboxWithLabelStyled
                            disabled={disabled}
                            id={measurementType}
                            key={measurementType}
                            onClick={
                              this.handleMeasurementTypeCheckboxWrapperClick
                            }
                          >
                            <Checkbox
                              checked={selectedMeasurementTypes.includes(
                                measurementType,
                              )}
                              readOnly={disabled}
                              disabled={disabled}
                            />
                            <div>
                              {
                                measurementTypesMap[measurementType]
                                  .labelWithUnit
                              }
                            </div>
                          </CheckboxWithLabelStyled>
                        )
                      })}
                  </MeasurementTypesCheckboxesFirstStyled>
                </MeasurementTypesSectionFirstStyled>
              </MeasurementTypesSectionsStyled>
            </MeasurementTypesSectionStyled>
          )}

          <WarningMessage>{warningMessage}</WarningMessage>

          <FormButtons
            onCancel={this.onCancel}
            cancelText='Cancel'
            onSubmit={this.onSubmit}
            submitText='Next'
            submitDisabled={!this.canSubmit()}
          />
        </FormSectionStyled>
        {renderDescription()}
      </FormStyled>
    )
  }

  render() {
    const {
      siteEntity: { item: site },
      location: { pathname },
      meterExport: {
        error,
        downloaded,
        job: { status, id },
        loading,
      },
    } = this.props
    let renderFunc

    if (pathname.endsWith('/download')) {
      renderFunc = this.renderDownloadSection
    } else {
      renderFunc = this.renderFormSection
    }

    const pollingMsg = 'Are you sure you want to cancel this request?'
    const readyMsg = 'Are you sure you want to leave your download?'
    const title = status === 'SUCCESS' ? readyMsg : pollingMsg
    const when = loading || (id && !downloaded && !error)
    return (
      <MeterExportFormPageStyled>
        {site && this.renderBreadcrumbs()}
        <Title>Meter Data Export</Title>
        {renderFunc()}
        <UnsavedChanges when={when} message={title} />
      </MeterExportFormPageStyled>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(exportActions, dispatch),
    ...bindActionCreators(meterActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
  },
})

const mapStateToProps = (
  state,
  {
    match: {
      params: { siteId, customerId },
    },
  },
) => {
  const siteEntity = selectSiteEntity(state, siteId)
  const { item: site } = siteEntity
  const custId =
    !customerId && site && site.customerId ? site.customerId : customerId
  const {
    meta: { loading: metersLoading = false },
  } = selectMeterListEntity(state)
  return {
    customerEntity: selectCustomerEntity(state, custId),
    customerListEntity: selectCustomerListEntity(state),
    customersById: state.entities.customers.byId,
    isSingleSiteView: !!siteId,
    meterExport: state.meterExport,
    meters: selectMeterList(state),
    metersLoading,
    siteEntity,
    siteListEntity: selectSiteListEntity(state),
    sitesById: state.entities.sites.byId,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterExportFormPage)
