import { takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import { defaultHeaders, consoleBaseUrl } from '../../api'
import { handleSagaError } from '../../sagas/utils'
import { handleAxiosError } from '../../api/utils'
import type { FTPostFileAction } from './uploadUtilityUsageData'

type FTUtilityProfileDataMeta = {
  error: string
  isUploading: boolean
}
export type FTUtilityProfileDataState = {
  uploadResponse: Record<string, any>
  meta: FTUtilityProfileDataMeta
}
type FTState = {
  uploadUtilityData: {
    utilityProfileData: FTUtilityProfileDataState
  }
}
export const types = {
  POST_UTILITY_PROFILE_DATA: 'POST_UTILITY_PROFILE_DATA',
  POST_UTILITY_PROFILE_DATA_ERROR: 'POST_UTILITY_PROFILE_DATA_ERROR',
  POST_UTILITY_PROFILE_DATA_SUCCESS: 'POST_UTILITY_PROFILE_DATA_SUCCESS',
  RESET_UTILITY_PROFILE_DATA_STATE: 'RESET_UTILITY_PROFILE_DATA_STATE',
}

export const selectUtilityProfileDataEntity = (
  state: FTState,
): FTUtilityProfileDataState => state.uploadUtilityData.utilityProfileData
export const initialState: FTUtilityProfileDataState = {
  uploadResponse: null,
  meta: {
    error: '',
    isUploading: false,
  },
}

const utilityProfileDataReducer = (
  state: FTUtilityProfileDataState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.POST_UTILITY_PROFILE_DATA:
      return {
        ...state,
        uploadResponse: null,
        meta: {
          error: '',
          isUploading: true,
        },
      }

    case types.POST_UTILITY_PROFILE_DATA_ERROR:
      return {
        ...state,
        meta: {
          error: action.error,
          isUploading: false,
        },
        uploadResponse: null,
      }

    case types.POST_UTILITY_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        meta: {
          error: '',
          isUploading: false,
        },
        uploadResponse: action.payload,
      }

    case types.RESET_UTILITY_PROFILE_DATA_STATE:
      return initialState

    default:
      return state
  }
}

export default utilityProfileDataReducer
export const actions = {
  postUtilityProfileData: (params: FTPostFileAction) => ({
    type: types.POST_UTILITY_PROFILE_DATA,
    ...params,
  }),
  resetUtilityProfileDataState: () => ({
    type: types.RESET_UTILITY_PROFILE_DATA_STATE,
  }),
}
// API
export class API {
  static postUtilityProfileData({ file }: Record<string, any>) {
    const url = `${consoleBaseUrl()}/utility/api/utility-profile/csv/upload`
    const formData = new FormData()
    formData.set('file', file)
    return axios({
      method: 'post',
      url,
      data: formData,
      headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
    })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* postUtilityProfileDataSaga(
  params: FTPostFileAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.postUtilityProfileData, params)
    yield put({
      type: types.POST_UTILITY_PROFILE_DATA_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.POST_UTILITY_PROFILE_DATA_ERROR, error)
  }
}

export const sagas = [
  takeEvery(types.POST_UTILITY_PROFILE_DATA, postUtilityProfileDataSaga),
]
