import styled from 'styled-components'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import FormField from '../FormField'
import FormButtons from '../FormButtons'

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
`
const FieldLabel = styled.div`
  width: 300px;
  font-weight: 600;
`
const FieldValue = styled.div`
  width: 300px;
  margin-left: 20px;
`

const UpdateMeterUtility = ({ handleSubmit, dirty, status }) => (
  <>
    <FieldWrapper>
      <FieldLabel>Utility Name</FieldLabel>
      <FieldValue>
        <FormField name='utilityName' />
      </FieldValue>
    </FieldWrapper>
    <FieldWrapper>
      <FieldLabel>Utility Account ID</FieldLabel>
      <FieldValue>
        <FormField name='utilityAccountId' />
      </FieldValue>
    </FieldWrapper>
    <FieldWrapper>
      <FieldLabel>Utility Meter Make</FieldLabel>
      <FieldValue>
        <FormField name='utilityMeterMake' />
      </FieldValue>
    </FieldWrapper>
    <FieldWrapper>
      <FieldLabel>Utility Meter Model</FieldLabel>
      <FieldValue>
        <FormField name='utilityMeterModel' />
      </FieldValue>
    </FieldWrapper>
    <FieldWrapper>
      <FieldLabel>Utility Meter Serial Number</FieldLabel>
      <FieldValue>
        <FormField name='utilityMeterSerialNumber' />
      </FieldValue>
    </FieldWrapper>
    <FormButtons
      onSubmit={handleSubmit}
      onCancel={status.cancelHandler}
      submitText='Save & Update'
      cancelText='Cancel'
      submitDisabled={!dirty}
    />
  </>
)

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  utilityName: Yup.string(),
  utilityAccountId: Yup.string(),
  utilityMeterMake: Yup.string(),
  utilityMeterModel: Yup.string(),
  utilityMeterSerialNumber: Yup.string(),
})
export default withFormik({
  validationSchema,
  mapPropsToValues: ({ utilityAccountResponseDto }) =>
    utilityAccountResponseDto ?
      {
        id: utilityAccountResponseDto.id || '',
        utilityName: utilityAccountResponseDto.utilityName || '',
        utilityAccountId: utilityAccountResponseDto.utilityAccountId || '',
        utilityMeterMake: utilityAccountResponseDto.utilityMeterMake || '',
        utilityMeterModel: utilityAccountResponseDto.utilityMeterModel || '',
        utilityMeterSerialNumber:
          utilityAccountResponseDto.utilityMeterSerialNumber || '',
      }
    : {
        utilityName: '',
        utilityAccountId: '',
        utilityMeterMake: '',
        utilityMeterModel: '',
        utilityMeterSerialNumber: '',
      },
  mapPropsToStatus: ({ cancelHandler }) => ({
    cancelHandler,
  }),
  handleSubmit: (values, { props }) => {
    props.handleUpdate(values)
  },
})(UpdateMeterUtility)
