import { combineReducers } from 'redux'
import { schema } from 'normalizr'
import axios from 'axios'
import FileSaver from 'file-saver'
import { takeEvery, put, call } from 'redux-saga/effects'
import { getOptionalSearchBy, makeActionTypes } from '../utils'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError, queryStringify } from '../../api/utils'
import { handleSagaError } from '../../sagas/utils'
import type { FTSaga } from '../../types'
import type { FTSearchBy } from '../types'
import { SOMETHING_WENT_WRONG } from '../../constants/strings'

export type FTDownloadCustomerSitesMeta = {
  loading: boolean
  error?: string
}
export type FTDownloadCustomerSitesAction = {
  customerId: string
  customerName: string
}
export type FTUtilityCustomer = {
  id: string
  name: string
}
type FTUtilityCustomerEntityState = {
  items: Array<FTUtilityCustomer>
  meta: {
    loading: boolean
    error: string
    searchBy: FTSearchBy
  }
  downloadMeta: FTDownloadCustomerSitesMeta
}
type FTState = {
  entities: {
    utilityCustomerSites: FTUtilityCustomerEntityState
  }
}
type FTFetchCustomerListResponse = {
  results: Array<FTUtilityCustomer>
  totalCount: number
}
// Action Types
export const types = {
  ...makeActionTypes('DOWNLOAD_CUSTOMER_SITES'),
  ...makeActionTypes('FETCH_CUSTOMERS'),
}
// Reducers
export const initialState = {
  items: [],
  meta: {
    loading: true,
    error: null,
    searchBy: {
      field: 'filterName',
      contractTermMonths: '',
    },
  },
  downloadMeta: {
    loading: true,
    error: null,
  },
}
export const entitySchema = new schema.Entity('utilityCustomerSites')
export const selectUtilityCustomerListEntity = (
  state: FTState,
): FTUtilityCustomerEntityState => state.entities.utilityCustomerSites

function downloadMeta(state = initialState.downloadMeta, action) {
  switch (action.type) {
    case types.DOWNLOAD_CUSTOMER_SITES:
      return { ...state, loading: true, error: null }

    case types.DOWNLOAD_CUSTOMER_SITES_ERROR:
      return { ...state, error: SOMETHING_WENT_WRONG, loading: false }

    case types.DOWNLOAD_CUSTOMER_SITES_SUCCESS:
      return { ...state, loading: false, error: null }

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMERS:
      return state

    case types.FETCH_CUSTOMERS_SUCCESS:
      return action.payload.results

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMERS:
      return state

    case types.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        searchBy: {
          field: 'filterName',
          contractTermMonths: action.payload.filterName,
        },
      }

    case types.FETCH_CUSTOMERS_ERROR:
      return { ...state, error: SOMETHING_WENT_WRONG, loading: false }

    default:
      return state
  }
}

export default combineReducers({
  items,
  downloadMeta,
  meta,
}) // Action Creators

export const actions = {
  downloadCustomerSites: (props: FTDownloadCustomerSitesAction) => ({
    type: types.DOWNLOAD_CUSTOMER_SITES,
    ...props,
  }),
  fetchCustomerSitesList: (props: FTSearchBy) => ({
    type: types.FETCH_CUSTOMERS,
    ...props,
  }),
}
// API
export class API {
  static downloadCustomerSites({
    customerId,
    customerName,
  }: FTDownloadCustomerSitesAction) {
    const url = `${consoleBaseUrl()}/utility/api/utility/site/csv?customerId=${customerId}`
    return axios
      .get(url, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => {
        FileSaver.saveAs(response.data, `${customerName}.csv`)
      })
      .catch(handleAxiosError)
  }

  static fetchCustomerSitesList(params: FTSearchBy) {
    const query = queryStringify(getOptionalSearchBy(params))
    const url = `${consoleBaseUrl()}/utility/api/utility/customer?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => ({
        ...response.data,
        ...getOptionalSearchBy(params),
      }))
      .catch(handleAxiosError)
  }
}

// Sagas
function* downloadCustomerSitesSaga({
  customerId,
  customerName,
}: FTDownloadCustomerSitesAction): Generator<any, void, any> {
  try {
    yield call(API.downloadCustomerSites, {
      customerId,
      customerName,
    })
    yield put({
      type: types.DOWNLOAD_CUSTOMER_SITES_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.DOWNLOAD_CUSTOMER_SITES_ERROR, e)
  }
}

function* fetchCustomerSitesListSaga(params: FTSearchBy): FTSaga {
  try {
    const response: FTFetchCustomerListResponse = yield call(
      API.fetchCustomerSitesList,
      params,
    )
    yield put({
      type: types.FETCH_CUSTOMERS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_CUSTOMERS_ERROR, e)
  }
}

export const sagas = [
  takeEvery(types.DOWNLOAD_CUSTOMER_SITES, downloadCustomerSitesSaga),
  takeEvery(types.FETCH_CUSTOMERS, fetchCustomerSitesListSaga),
]
