import React, { useState } from 'react'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import DownloadAccordion from '../DownloadAccordion'
import Modal from '../Modal'
import { actions as modalActions } from '../../ducks/modal'
import { selectPanelMetaData } from '../../ducks/panelPhotos'
import Spinner from '../Spinner'

const WrapperStyles = styled.div`
  .Modal {
    .box {
      box-sizing: border-box;
      padding: 0;
      min-height: 600px;
    }
  }
  .header {
    padding: 20px;
  }
`

const Header = styled.div`
  padding: 12px 31px 15px 31px;
  border-bottom: 1px solid #dadce0;
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #6c6d6e;
  line-height: 20px;
`

const CloseIcon = styled.div`
  span::before {
    font-size: 24px;
  }
  width: 16px;
  height: 16px;
  cursor: pointer;
`

const Content = styled.div`
  padding: 40px 32px;
`

const NormalText = styled.span`
  color: #6c6d6e;
  font-size: 14px;
`

const HighlightedText = styled.span`
  font-size: 16px;
`

const ImgWrapper = styled.div`
  margin: 10px 0 20px;
`

const Img = styled.img`
  margin-right: 10px;
`

const Loader = styled.div`
  display: grid;
  place-items: center;
  padding: 227px;
`

type PhotoType = {
  id: string
  objectId: string
  objectType: string
  fileName: string
  fileUploadPath: string
  preSignedUrl: string
  preSignedThumbnailUrl: string
  responseStatus: string
}

const getPhotos = (photos: Array<PhotoType>) => {
  const photosObj: Record<string, Array<Record<string, string>>> = {}
  const addPhotos = (type: string, thumbnail: string, img: string) => {
    if (photosObj[type]) {
      photosObj[type].push({ thumbnail, preSignedUrl: img })
    } else {
      photosObj[type] = [{ thumbnail, preSignedUrl: img }]
    }
  }
  if (photos.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const photo of photos) {
      const type = photo.objectId.split('/')[1]
      addPhotos(type, photo.preSignedThumbnailUrl, photo.preSignedUrl)
    }
    return photosObj
  }
  return {}
}

const sections = [
  {
    id: 'Location',
    label: 'Location',
  },
  {
    id: 'Box',
    label: 'Box',
  },
  {
    id: 'Configuration',
    label: 'Configuration',
  },
  {
    id: 'Schedule',
    label: 'Schedule',
  },
  {
    id: 'BreakerTypes',
    label: 'Breaker Types',
  },
]

export default function ModalPanelPhotosView() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<string | null>(null)

  const { photsDownloadUrlLoading, photosDownloadUrl } =
    useSelector(selectPanelMetaData)
  const photos = getPhotos(photosDownloadUrl)
  const hideModal = () => {
    dispatch(modalActions.hideModal())
  }

  const onDownload = (type: string) => {
    setLoading(type)
    const zip = new JSZip()
    const allPhotos = type !== 'ALL' ? photos[type] : photosDownloadUrl
    // Create promises for each image download
    const downloadPromises = allPhotos.map(
      async (photo: PhotoType | string, index: number) => {
        const imgURL = photo?.preSignedUrl
        const splitData = imgURL?.split('.')
        // eslint-disable-next-line no-unsafe-optional-chaining
        const lastIndex = splitData[splitData?.length - 1]
        const ext = lastIndex?.split('?')[0]
        return fetch(imgURL, {
          mode: 'cors',
          method: 'GET',
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response.blob()
          })
          .then((blob) => {
            // Add the blob to the zip file
            zip.file(`Image_${index + 1}.${ext}`, blob)
          })
      },
    )
    // After all promises are resolved, create and save the zip file
    Promise.all(downloadPromises).then(() => {
      zip
        .generateAsync({ type: 'blob' })
        .then((zipBlob) => {
          // Save the zip file
          saveAs(zipBlob, 'Images.zip')
          setLoading(null)
        })
        .catch(() => {
          setLoading(null)
        })
    })
  }

  const renderImages = (imgs: Array<Record<string, string>>) =>
    imgs.map((img) => (
      <a href={img.preSignedUrl} target='_blank' rel='noreferrer'>
        <Img
          src={img.thumbnail}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = img.preSignedUrl
          }}
          alt='img'
          width='135px'
          height='135px'
          crossOrigin='anonymous'
        />
      </a>
    ))

  const renderAllMenus = () =>
    sections.map((section) => (
      <DownloadAccordion
        title={<NormalText>{section.label}</NormalText>}
        closeText='See Less'
        downloadText='Download Images'
        viewText='See More'
        onDownload={() => onDownload(section.id)}
        showDownload={!!photos[section.id]}
        downloading={loading === section.id}
        // eslint-disable-next-line react/no-unstable-nested-components
        Content={() => (
          <ImgWrapper>
            {photos[section.id] ?
              renderImages(photos[section.id])
            : 'No image exists for this section'}
          </ImgWrapper>
        )}
        isExpandedInitially
      />
    ))

  const renderContent = () => (
    <Content>
      <DownloadAccordion
        closeText='Close All'
        downloadText='Download All Images'
        viewText='Expand All'
        onDownload={() => onDownload('ALL')}
        title={<HighlightedText>Audit Photos</HighlightedText>}
        Content={() => renderAllMenus()}
        downloading={loading === 'ALL'}
        isExpandedInitially
        showDownload={!!photosDownloadUrl.length}
      />
    </Content>
  )
  const renderBody = () =>
    photsDownloadUrlLoading ?
      <Loader>
        <Spinner />
      </Loader>
    : <>
        <Header>
          <Title>PHOTOS</Title>
          <CloseIcon onClick={hideModal}>
            <span className='ion-android-close' />
          </CloseIcon>
        </Header>
        {renderContent()}
      </>

  return (
    <WrapperStyles>
      <Modal modalWidth='982px' onClose={hideModal} renderBody={renderBody} />
    </WrapperStyles>
  )
}
