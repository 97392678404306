import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { handleAxiosError } from '../../api/utils'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleSagaError } from '../../sagas/utils'
import { isVariantActive } from '../../utils'
import siteMockData from '../../mockData/proposals/site'

type FTProposalSiteResponse = {
  id: string
  name: string
  customerLocationCode1: string
  customerLocationCode2: string | null | undefined
  shippingStreet: string
  shippingState: string
  shippingCity: string
  shippingPostalCode: string
  shippingCountry: string
  squareFeet: number
  shippingStateCode: string
  latitude: number
  longitude: number
}
export type FTProposalSite = FTProposalSiteResponse
export type FTFetchProposalSiteAction = {
  id: string
}
export type FTProposalSiteMetaState = {
  error: string
  loading: boolean
}
export type FTProposalSiteEntityState = {
  byId: Record<string, FTProposalSite>
  items: Array<FTProposalSite>
  meta: FTProposalSiteMetaState
}
type FTState = {
  entities: {
    proposalSites: FTProposalSiteEntityState
  }
}
// Action Types
export const types = {
  FETCH_PROPOSAL_SITE: 'FETCH_PROPOSAL_SITE',
  FETCH_PROPOSAL_SITE_SUCCESS: 'FETCH_PROPOSAL_SITE_SUCCESS',
  FETCH_PROPOSAL_SITE_ERROR: 'FETCH_PROPOSAL_SITE_ERROR',
}
export const actions = {
  fetchProposalSite: (props: FTFetchProposalSiteAction) => ({
    type: types.FETCH_PROPOSAL_SITE,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('proposalSites')

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalSite> = Object.values(action.payload.entities.proposalSites)
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalSites }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_SITE:
      return {}

    case types.FETCH_PROPOSAL_SITE_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_SITE:
      return initialState.items

    case types.FETCH_PROPOSAL_SITE_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_SITE:
      return { ...state, error: '', loading: true }

    case types.FETCH_PROPOSAL_SITE_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PROPOSAL_SITE_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
export const selectProposalSiteEntity = (
  state: FTState,
): FTProposalSiteEntityState => state.entities.proposalSites
export const API = {
  fetchProposalSite: ({ id }: FTFetchProposalSiteAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(siteMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/salesforce-site/${id}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalSiteResponse }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchProposalSiteSaga(
  params: FTFetchProposalSiteAction,
): Generator<any, void, any> {
  try {
    const response: FTProposalSiteResponse = yield call(
      API.fetchProposalSite,
      params,
    )
    const normalized = normalize([response], [entitySchema])
    yield put({
      type: types.FETCH_PROPOSAL_SITE_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_SITE_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_PROPOSAL_SITE, fetchProposalSiteSaga),
]
