
import { takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { consoleApiUrl, defaultHeaders } from '../../../api';
import { handleAxiosError } from '../../../api/utils';

export type FTUploadISRFileAction = {
    file: any;
};
export type FTISRProcessingState = {
    isUploading: boolean;
    isrFileName: string;
    error: string | null;
};
type FTISRProcessingStateRoot = {
    isrProcessing: FTISRProcessingState;
};

export const types = {
    UPLOAD_ISR_FILE_REQUEST: 'UPLOAD_ISR_FILE_REQUEST',
    UPLOAD_ISR_FILE_SUCCESS: 'UPLOAD_ISR_FILE_SUCCESS',
    UPLOAD_ISR_FILE_FAILURE: 'UPLOAD_ISR_FILE_FAILURE',
    RESET_ISR_PROCESSING_STATE: 'RESET_ISR_PROCESSING_STATE',
};

export const selectISRProcessing = (state: FTISRProcessingStateRoot): FTISRProcessingState => state.isrProcessing;

export const initialState: FTISRProcessingState = {
    isUploading: false,
    isrFileName: '',
    error: null,
};

const isrProcessingReducer = (
    state: FTISRProcessingState = initialState,
    action: Record<string, any>,
) => {
    switch (action.type) {
        case types.UPLOAD_ISR_FILE_REQUEST:
            return { ...state, isUploading: true, error: null };

        case types.UPLOAD_ISR_FILE_SUCCESS:
            return { ...state, isUploading: false, isrFileName: action.payload };

        case types.UPLOAD_ISR_FILE_FAILURE:
            return { ...state, isUploading: false, error: action.payload };

        case types.RESET_ISR_PROCESSING_STATE:
            return initialState;

        default:
            return state;
    }
};

export default isrProcessingReducer;

export const actions = {
    uploadISRFile: (params: FTUploadISRFileAction) => ({
        type: types.UPLOAD_ISR_FILE_REQUEST,
        ...params,
    }),
    uploadISRFileSuccess: (fileName: string) => ({
        type: types.UPLOAD_ISR_FILE_SUCCESS,
        payload: fileName,
    }),
    uploadISRFileFailure: (error: string) => ({
        type: types.UPLOAD_ISR_FILE_FAILURE,
        payload: error,
    }),
    resetISRProcessingState: () => ({
        type: types.RESET_ISR_PROCESSING_STATE,
    }),
};

// API
export class API {
    static uploadISRFile({ file }: Record<string, any>) {
        const url = `${consoleApiUrl()}/billing/isr/isr-files`;
        const formData = new FormData()
        formData.set('file', file)
        return axios({
            method: 'post',
            url,
            data: formData,
            headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
        })
            .then(({ data }) => data)
            .catch(handleAxiosError)
    }
}

function* uploadISRFileSaga(params: FTUploadISRFileAction): Generator<any, void, any> {
    try {
        const payload = yield call(API.uploadISRFile, params);
        yield put(actions.uploadISRFileSuccess(payload));
    } catch (error) {
        yield put(actions.uploadISRFileFailure(error.message));
    }
}

export const sagas = [
    takeEvery(types.UPLOAD_ISR_FILE_REQUEST, uploadISRFileSaga),
];
