import axios from 'axios'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import * as actionTypes from '../constants/actionTypes'
import * as status from '../constants/status'
import { handleError, handleSagaError } from '../sagas/utils'
import '../types'
import type { FTSaga, FTSortable } from '../types'
import { naturallySort } from '../utils'
import {
  addMetaToResponse,
  initialMeta,
  makeActionTypes,
  makeApiQueryString,
  makeSagaPayload,
} from './utils'
// ACTION TYPES
export const types = {
  ...makeActionTypes('FETCH_ALL_CUSTOMERS'),
  ...makeActionTypes('FETCH_CUSTOMER'),
  ...makeActionTypes('ADD_CUSTOMER'),
  ...makeActionTypes('UPDATE_CUSTOMER'),
  ...makeActionTypes('GET_CUSTOM_LOGO_PRESIGNED_URL'),
  ...makeActionTypes('UPLOAD_CUSTOM_LOGO'),
  CLEAR_CUSTOMER_FORM: 'CLEAR_CUSTOMER_FORM',
  CLEAR_CUSTOMERS: 'CLEAR_CUSTOMERS',
  CLEAR_CUSTOM_LOGO: 'CLEAR_CUSTOM_LOGO',
}
export type FTCustomer = {
  id: string
  name: string
  validName: string
  customerLogoUrl?: string
  initials: string
  logoBackgroundColor: string
}
type FTFetchCustomerListAction = {
  pageNumber: number | null | undefined
  pageSize: number | null | undefined
  orderBy?: FTSortable
}
export type FTFetchCustomerAction = {
  customerId: string
}
type FTCustomerFormAction = {
  customerId?: string
  name: string
}

type FTCustomLogoPresignUrlResponse = {
  url: string
  keyName: string
  bucketName: string
}

type FTCustomerEntityState = {
  byId: Record<string, FTCustomer>
  allIds: Array<string>
  meta: {
    pageNumber: number | null | undefined
    pageSize: number | null | undefined
    next: string | null | undefined
    previous: string | null | undefined
    error: string | null | undefined
    loading: boolean
    orderBy?: FTSortable
  }
  form: {
    success: boolean
    loading: boolean
    error: string
  }
  customLogoPresignUrl: FTCustomLogoPresignUrlResponse
  customLogoPresignUrlMeta: {
    loading: boolean
    error: string
  }
  customLogoUploadMeta: {
    loading: boolean
    error: string
  }
  updateCustomerMeta: {
    loading: boolean
    error: string
  }
}
export type FTState = {
  entities: {
    customers: FTCustomerEntityState
  }
}
export const naturallySortCustomers = (a: FTCustomer, b: FTCustomer) =>
  naturallySort(a.validName || a.name, b.validName || b.name)
// REDUCERS
const initialState = {
  meta: { ...initialMeta },
  form: {
    success: false,
    loading: false,
    error: '',
  },
  customLogoPresignUrl: {
    url: '',
    keyName: '',
    bucketName: '',
  },
  customLogoPresignUrlMeta: {
    loading: false,
    error: '',
  },
  customLogoUploadMeta: {
    loading: false,
    error: '',
  },
  updateCustomerMeta: {
    loading: false,
    error: '',
  },
}

export const duckKey = 'customers'
export const customerSchema = new schema.Entity(duckKey)

function customerById(action, state) {
  return { ...state, ...action.payload.entities[duckKey] }
}

function customerAllIds(action, state) {
  return [...new Set(state.concat(action.payload.result))]
}

function byId(state = {}, action) {
  switch (action.type) {
    case types.FETCH_ALL_CUSTOMERS:
    case types.FETCH_CUSTOMER:
      return {}

    case types.FETCH_ALL_CUSTOMERS_SUCCESS:
    case types.FETCH_CUSTOMER_SUCCESS:
      return customerById(action, state)

    default:
      return state
  }
}

function allIds(state = [], action) {
  switch (action.type) {
    case types.FETCH_ALL_CUSTOMERS:
    case types.FETCH_CUSTOMER:
      return []

    case types.FETCH_ALL_CUSTOMERS_SUCCESS:
    case types.FETCH_CUSTOMER_SUCCESS:
      return customerAllIds(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_ALL_CUSTOMERS:
    case types.FETCH_CUSTOMER:
      return { ...state, loading: true }

    case types.FETCH_ALL_CUSTOMERS_ERROR:
    case types.FETCH_CUSTOMER_ERROR:
      return { ...state, loading: false }

    case types.FETCH_ALL_CUSTOMERS_SUCCESS:
    case types.FETCH_CUSTOMER_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    default:
      return state
  }
}

function form(state = initialState.form, action) {
  switch (action.type) {
    case types.ADD_CUSTOMER_SUCCESS:
    case types.UPDATE_CUSTOMER_SUCCESS:
      return { ...state, loading: false, error: '', success: true }

    case types.ADD_CUSTOMER_ERROR:
    case types.UPDATE_CUSTOMER_ERROR:
      return { ...state, loading: false, error: action.error, success: false }

    case types.ADD_CUSTOMER:
    case types.UPDATE_CUSTOMER:
      return { ...state, loading: true, error: '', success: false }

    case types.CLEAR_CUSTOMER_FORM:
      return initialState.form

    default:
      return state
  }
}

function customLogoPresignUrl(
  state = initialState.customLogoPresignUrl,
  action,
) {
  switch (action.type) {
    case types.GET_CUSTOM_LOGO_PRESIGNED_URL_SUCCESS:
      return action.response
    case types.GET_CUSTOM_LOGO_PRESIGNED_URL_ERROR:
      return {
        customLogoPresignUrlMeta: {
          loading: false,
          error: action.error,
        },
      }
    case types.CLEAR_CUSTOM_LOGO:
      return initialState.customLogoPresignUrl

    default:
      return state
  }
}

function customLogoPresignUrlMeta(
  state = initialState.customLogoPresignUrlMeta,
  action,
) {
  switch (action.type) {
    case types.GET_CUSTOM_LOGO_PRESIGNED_URL:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case types.GET_CUSTOM_LOGO_PRESIGNED_URL_SUCCESS:
      return {
        loading: false,
        error: '',
      }
    case types.GET_CUSTOM_LOGO_PRESIGNED_URL_ERROR:
      return {
        loading: false,
        error: action.error,
      }
    case types.CLEAR_CUSTOM_LOGO:
      return initialState.customLogoPresignUrlMeta

    default:
      return state
  }
}

function customLogoUploadMeta(
  state = initialState.customLogoUploadMeta,
  action,
) {
  switch (action.type) {
    case types.UPLOAD_CUSTOM_LOGO:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case types.UPLOAD_CUSTOM_LOGO_SUCCESS:
      return {
        loading: false,
        error: '',
      }
    case types.UPLOAD_CUSTOM_LOGO_ERROR:
      return {
        loading: false,
        error: action.error,
      }
    case types.CLEAR_CUSTOM_LOGO:
      return initialState.customLogoPresignUrlMeta

    default:
      return state
  }
}

function updateCustomerMeta(state = initialState.updateCustomerMeta, action) {
  switch (action.type) {
    case types.UPDATE_CUSTOMER:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case types.UPDATE_CUSTOMER_SUCCESS:
      return {
        loading: false,
        error: '',
      }
    case types.UPDATE_CUSTOMER_ERROR:
      return {
        loading: false,
        error: action.error,
      }
    case types.CLEAR_CUSTOM_LOGO:
      return initialState.customLogoPresignUrlMeta

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
  form,
  customLogoPresignUrl,
  customLogoPresignUrlMeta,
  customLogoUploadMeta,
  updateCustomerMeta,
}) // Action Creators

export const actions = {
  fetchAllCustomers: (params: FTFetchCustomerListAction) => ({
    type: types.FETCH_ALL_CUSTOMERS,
    ...params,
  }),
  fetchCustomer: (params: FTFetchCustomerAction) => ({
    type: types.FETCH_CUSTOMER,
    ...params,
  }),
  addCustomer: ({ name }: { name: string }) => ({
    type: types.ADD_CUSTOMER,
    name,
  }),
  updateCustomer: (
    customerId: string,
    {
      name,
      customerLogoUrl,
      initials,
      logoBackgroundColor,
    }: {
      name: string
      customerLogoUrl?: string
      initials?: string
      logoBackgroundColor?: string
    },
  ) => ({
    type: types.UPDATE_CUSTOMER,
    customerId,
    name,
    customerLogoUrl,
    initials,
    logoBackgroundColor,
  }),
  getCustomLogoPresignedUrl: (costumerId: string, fileName: string) => ({
    type: types.GET_CUSTOM_LOGO_PRESIGNED_URL,
    costumerId,
    fileName,
  }),
  uploadCustomLogo: (file: File) => ({
    type: types.UPLOAD_CUSTOM_LOGO,
    file,
  }),
  clearCustomLogo: () => ({
    type: types.CLEAR_CUSTOM_LOGO,
  }),
  clearCustomerForm: () => ({
    type: 'CLEAR_CUSTOMER_FORM',
  }),
}
// Selectors
export const selectCustomers = (state: FTState): Array<FTCustomer> =>
  state.entities[duckKey].allIds.map((id) => state.entities[duckKey].byId[id])
export const selectCustomerIds = (state: FTState): Array<string> =>
  state.entities[duckKey].allIds
export const selectCustomersById = (
  state: FTState,
): Record<string, FTCustomer> => state.entities[duckKey].byId
export const selectCustomer = (state: FTState, id: string) =>
  state.entities[duckKey].byId[id]
export const selectCustomerMeta = (state: FTState) =>
  state.entities[duckKey].meta
export const selectCustomerListEntity = (state: FTState) => ({
  items: selectCustomers(state),
  meta: state.entities[duckKey].meta,
})
export const selectCustomerEntity = (state: FTState, id: string) => ({
  item: selectCustomer(state, id),
  meta: state.entities[duckKey].meta,
  form: state.entities[duckKey].form,
})
export const selectCustomerPresignUrl = (state: FTState) =>
  state.entities[duckKey].customLogoPresignUrl

export const selectCustomLogoPresignUrlMeta = (state: FTState) =>
  state.entities[duckKey].customLogoPresignUrlMeta

export const selectCustomLogoUploadMeta = (state: FTState) =>
  state.entities[duckKey].customLogoUploadMeta

export const selectCustomerUpdateState = (state: FTState) =>
  state.entities[duckKey].updateCustomerMeta

class API {
  static getCustomers(params: FTFetchCustomerListAction) {
    const query = makeApiQueryString(params)
    const url = `${consoleApiUrl()}/admin/customers?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }

  static getCustomer(customerId: string) {
    const url = `${consoleApiUrl()}/admin/customers/${customerId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static createCustomer(name: string) {
    return axios
      .post(
        `${consoleApiUrl()}/admin/customers`,
        {
          name,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => ({
        id: data.id,
      }))
      .catch(handleAxiosError)
  }

  static updateCustomer(
    customerId: string,
    name: string,
    customerLogoUrl?: string,
    initials?: string,
    logoBackgroundColor?: string,
  ) {
    return axios
      .put(
        `${consoleApiUrl()}/admin/customers/${customerId}`,
        {
          name,
          customerLogoUrl,
          initials,
          logoBackgroundColor,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => ({
        id: data.id,
      }))
      .catch(handleAxiosError)
  }

  static getCustomLogoPresignedUrl(customerId: string, fileName: string) {
    return axios
      .post(
        `${consoleApiUrl()}/admin/customers/${customerId}/upload-logo-url/generate`,
        {
          fileName,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static uploadCustomLogo(url: string, file: File) {
    const config = {
      headers: {
        'Content-Type': 'image/png', // Set the content type to the file's type
      },
    }

    return axios
      .put(url, file, config)
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
} // Sagas

export function* fetchCustomerListSaga(
  params: FTFetchCustomerListAction,
): FTSaga {
  try {
    yield put({
      type: actionTypes.UPDATE_STATUS,
      customers: {
        status: status.LOADING,
      },
    })
    const response = yield call(API.getCustomers, params)
    const payload = makeSagaPayload(response, customerSchema)
    yield put({
      type: types.FETCH_ALL_CUSTOMERS_SUCCESS,
      payload,
    })
    yield put({
      type: actionTypes.UPDATE_STATUS,
      customers: {
        status: status.LOADED,
      },
    })
  } catch (e) {
    yield handleError('fetchAllCustomers', e)
  }
}

function* fetchCustomerSaga({ customerId }: FTCustomerFormAction): FTSaga {
  try {
    const customer = yield call(API.getCustomer, customerId)
    const payload = normalize([customer], [customerSchema])
    yield put({
      type: types.FETCH_CUSTOMER_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_CUSTOMER_ERROR, error)
  }
}

export function* addCustomerSaga({ name }: { name: string }): FTSaga {
  try {
    yield call(API.createCustomer, name)
    yield put({
      type: types.ADD_CUSTOMER_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.ADD_CUSTOMER_ERROR, e)
  }
}
export function* updateCustomerSaga({
  name,
  customerLogoUrl,
  customerId,
  initials,
  logoBackgroundColor,
}: FTCustomerFormAction): FTSaga {
  try {
    yield call(
      API.updateCustomer,
      customerId,
      name,
      customerLogoUrl,
      initials,
      logoBackgroundColor,
    )
    yield put({
      type: types.UPDATE_CUSTOMER_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_CUSTOMER_ERROR, e)
  }
}
function* getCustomLogoPresignedUrlSaga({
  costumerId,
  fileName,
}: {
  costumerId: string
  fileName: string
}): FTSaga {
  try {
    const response: FTCustomLogoPresignUrlResponse = yield call(
      API.getCustomLogoPresignedUrl,
      costumerId,
      fileName,
    )
    yield put({
      type: types.GET_CUSTOM_LOGO_PRESIGNED_URL_SUCCESS,
      response,
    })
  } catch (e) {
    yield handleSagaError(types.GET_CUSTOM_LOGO_PRESIGNED_URL_ERROR, e)
  }
}
function* uploadCustomLogoSaga({ file }: { file: File }): FTSaga {
  try {
    const { url } = yield select(selectCustomerPresignUrl)
    yield call(API.uploadCustomLogo, url, file)
    yield put({
      type: types.UPLOAD_CUSTOM_LOGO_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.UPLOAD_CUSTOM_LOGO_ERROR, e)
  }
}
export const sagas = [
  takeEvery(types.UPDATE_CUSTOMER, updateCustomerSaga),
  takeEvery(types.ADD_CUSTOMER, addCustomerSaga),
  takeEvery(types.FETCH_ALL_CUSTOMERS, fetchCustomerListSaga),
  takeEvery(types.FETCH_CUSTOMER, fetchCustomerSaga),
  takeEvery(types.GET_CUSTOM_LOGO_PRESIGNED_URL, getCustomLogoPresignedUrlSaga),
  takeEvery(types.UPLOAD_CUSTOM_LOGO, uploadCustomLogoSaga),
]
