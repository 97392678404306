import { put, call, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as types from '../constants/actionTypes'
import * as status from '../constants/status'
import api from '../api/mainApi'
import { handleError } from './utils'
import {
  STORAGE_LOGIN_TOKEN,
  STORAGE_USERNAME,
  STORAGE_USER_ID,
} from '../constants'
import type { FTAuthCreds } from '../types'
import '../types'

export function* loginRequest({
  username,
  password,
}: FTAuthCreds): Generator<any, void, any> {
  try {
    yield put({
      type: types.UPDATE_STATUS,
      auth: {
        status: status.LOADING,
      },
    })
    const user = yield call(api.authenticate, {
      username,
      password,
    })
    localStorage.setItem(STORAGE_LOGIN_TOKEN, user.sessionToken)
    localStorage.setItem(STORAGE_USERNAME, username)
    localStorage.setItem(STORAGE_USER_ID, user.userId)
    user.username = username
    yield put({
      type: types.LOGIN_SUCCESS,
      user,
      notice: null,
    })
    yield put({
      type: types.UPDATE_STATUS,
      auth: {
        status: status.LOADED,
      },
    })
  } catch (error) {
    localStorage.removeItem(STORAGE_LOGIN_TOKEN)
    localStorage.removeItem(STORAGE_USERNAME)
    localStorage.removeItem(STORAGE_USER_ID)

    if (error.name && error.name === 'PasswordExpiredException') {
      yield put({
        type: types.PASSWORD_EXPIRED,
        error,
      })
    } else {
      yield handleError('auth', error)
    }
  }
}
export function* watchLoginRequest(): Generator<any, void, any> {
  yield takeEvery(types.LOGIN_REQUEST, loginRequest)
}
// logoutRequest deletes the user's session from the API, if a token is found,
// and clears Local Storage, before redirecting the user to /login.
export function* logoutRequest(): Generator<any, void, any> {
  try {
    const token = localStorage.getItem(STORAGE_LOGIN_TOKEN)
    localStorage.clear()

    if (token) {
      yield call(api.logout, {
        loginToken: token,
      })
    }

    yield put({
      type: types.LOGOUT_SUCCESS,
    })
    yield put(push('/login'))
    yield put({
      type: types.UPDATE_STATUS,
      auth: {
        status: status.LOADED,
      },
    })
  } catch (error) {
    yield handleError('auth', error)
  }
}
export function* watchLogoutRequest(): Generator<any, void, any> {
  yield takeEvery(types.LOGOUT_REQUEST, logoutRequest)
}
