import styled from 'styled-components';
import {colors} from "../../../utils/themes";
import {UploadAssetRecordsListStyled, DropzoneStyles, FilePathStyled} from "../../ToolsAndReports/LightingAsBuiltsManagerPage";

export const ModalHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4px;
`;

export const ISRFileDropZone = styled(DropzoneStyles)<{isError:boolean}>`
    border-color: ${({isError}:{isError: boolean}) => isError && colors.red};
`;
export const FileNameStyled =  styled(FilePathStyled)<{isError:boolean}>`
    color: ${({isError}:{isError: boolean}) => isError && colors.red};
`;
export const UploadAssetListItems = styled(UploadAssetRecordsListStyled)`
    padding-left: 20px;
`;

export const modalCustomStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(94, 94, 94, 0.75)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: '1rem',
        width: '40rem',
        height: '24rem',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.gray5,
    },
};

export const ErrorMessageContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
color: ${colors.red};`;
export const ErrorText = styled.div`
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding-left: 4px;
`;
