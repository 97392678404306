import { useEffect } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import FeatureValidator from '../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../authorization/features'
import useWindowSize from '../../hooks/useWindowSize'

import './style.css'
import { FTHistory } from '../../types'

type FTProps = FTHistory & {
  open: boolean
  onClose: () => void
  logoutUser: (...args: T[]) => T
}

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: #35a0cd;
  border-radius: 50%;
`

const Drawer = (props: FTProps) => {
  const { open, onClose, location, logoutUser } = props
  const [width] = useWindowSize()

  useEffect(() => {
    if (width >= 1347 && open) {
      onClose()
    }
  }, [width])

  const { pathname } = location
  const isAccountManagement = pathname.startsWith('/account-management')
  const isReports =
    pathname.startsWith('/reports') ||
    pathname.startsWith('/integration/energy-star/')
  const isBilling = pathname.startsWith('/billing')
  const isProposal = pathname.startsWith('/proposal-operations')

  const customersClass = classNames({
    selected: isAccountManagement,
  })
  const reportsClass = classNames({
    selected: isReports,
  })

  const billingsClass = classNames({
    selected: isBilling,
  })
  const proposalOperationsClass = classNames({
    selected: isProposal,
  })
  return (
    <>
      <div
        className={`overlay ${!open && 'overlayHidden'} ${
          open && 'overlayOpen'
        }`}
        onClick={onClose}
        aria-hidden='true'
      />
      <div
        className={`drawer ${open && 'animate'} ${!open && 'hidden'}  right`}
      >
        <div className='header'>
          <div>Menu</div>
          <div className='close' onClick={onClose}>
            <span className='ion-android-close' />
          </div>
        </div>
        <section className='link'>
          <ul>
            <FeatureValidator feature={AuthorizedFeatures.accountManagement}>
              <li>
                {isAccountManagement && <Dot />}
                <Link
                  href='/customers'
                  to='/account-management'
                  className={customersClass}
                >
                  Account Management
                </Link>
              </li>
            </FeatureValidator>
            <FeatureValidator feature={AuthorizedFeatures.toolsAndReports}>
              <li>
                {isReports && <Dot />}
                <Link href='/reports' to='/reports' className={reportsClass}>
                  Tools & Reports
                </Link>
              </li>
            </FeatureValidator>
            <FeatureValidator feature={AuthorizedFeatures.billing}>
              <li>
                {isBilling && <Dot />}
                <Link href='/billing' to='/billing' className={billingsClass}>
                  Billing
                </Link>
              </li>
            </FeatureValidator>
            <FeatureValidator feature={AuthorizedFeatures.proposalOperations}>
              <li>
                {isProposal && <Dot />}
                <Link
                  href='/proposal-operations'
                  to='/proposal-operations'
                  className={proposalOperationsClass}
                >
                  Proposal Operations
                </Link>
              </li>
            </FeatureValidator>
            <li>
              <Link to='/' onClick={logoutUser}>
                Sign Out
              </Link>
            </li>
          </ul>
        </section>
      </div>
    </>
  )
}

export default Drawer
