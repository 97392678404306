import * as React from 'react'
import { Form, FastField, Field } from 'formik'
import styled from 'styled-components'
import debounce from 'debounce'
import Description from './Description'
import Title from './Title'
import Button from './Button'
import DeprecatedEntityListPane from './DeprecatedEntityListPane'
import Link from './StyledLink'
import Checkbox from './Checkbox'
import UnsavedChanges from './UnsavedChanges'
import type { FTUnassignedListEntity } from '../ducks/unassignedMeters'
import '../ducks/unassignedMeters'
import type { FTHistory, FTFormFieldEvent } from '../types'
import '../types'
import { SearchStyles } from '../pages/AccountManagement/MainPage'
import Input from './Input'
import { normalizeMACAddress } from '../utils'

const StyledButton = styled(Button)`
  & + & {
    margin: 40px 0 60px 40px;
  }
`
const FormError = styled.div`
  color: #c70d08;
  margin-bottom: 20px;
`
const Styles = styled.div``
const SelectMetersStyles = styled.div`
  b {
    font-weight: 500;
  }
  ${Title} {
    font-weight: 600;
    line-height: 33px;
  }

  ${Description} {
    width: 400px;
    font-size: 14px;
    line-height: 20px;
    b {
      display: block;
      margin-top: 15px;
    }
  }

  .Table {
    thead {
      td:first-child {
        width: 10px;
      }

      td:not(:first-child) {
        max-width: 50px;
      }
    }

    tbody {
      tr {
        height: 44px;
      }

      td:first-child {
        width: 10px;
      }

      tr:hover {
        box-shadow: inset 0 0 0 1px #dcdcdc;
        border-radius: 3px;
        cursor: pointer;
      }
      a {
        width: fit-content;
      }
    }
  }
`
const Selector = styled.div`
  display: block;
  width: 475px;

  & + & {
    margin-top: 10px;
  }

  select {
    width: 375px;
    float: right;
  }

  b {
    font-weight: 600;
  }
`

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const VerifySelection = styled.div`
  margin-bottom: 30px;
  b {
    font-weight: 600;
  }
  td:nth-child(2) {
    padding-left: 30px;
  }
  tr:not(:first-child) td {
    padding-top: 10px;
  }
`
type FTProps = {
  customers: Array<Object>
  error?: string
  fetchSites: Function
  history: FTHistory
  isSubmitting: boolean
  loadAction: Function
  loadActionProps: Object
  loading: boolean
  renderSelectedMeters: () => React.Node
  selectedCustomer: Object
  selectedSite: Object
  selectedOpportunityId: string
  setFieldValue: Function
  setSubmitting: Function
  sites: Array<Object>
  step?: number
  unassignedListEntity: FTUnassignedListEntity
  updateMeterState: Function
  updateState: Function
  values: Object
}

type FTState = {
  hasSubmissionError: boolean
  meterEntityListPaneEntity: Record<string, any>
  search: string
  unassignedListEntity: FTUnassignedListEntity
}

const TableRow = ({
  id,
  checked: checkedProp,
  handleMeterListTableRowClick,
  name,
  meterType,
  firstReported,
}: {
  id: string
  checked: boolean
  handleMeterListTableRowClick: (...args: Array<any>) => any
  name: string
  meterType: string
  firstReported: string
}) => {
  const [checked, setChecked] = React.useState(checkedProp)

  const handleClick = (event) => {
    setChecked(!checked)
    handleMeterListTableRowClick(event)
  }

  return (
    <tr data-id={id} onClick={handleClick}>
      <td>
        <Checkbox
          name={`selected.${id}`}
          value={`selected.${id}`}
          checked={checked}
        />
      </td>
      <td>
        <div>
          <Link
            to={`/account-management/meters/${id}`}
            href={`/account-management/meters/${id}`}
            onClick={(e) => e.stopPropagation()}
          >
            {name}
          </Link>
        </div>
      </td>
      <td>{meterType}</td>
      <td>{firstReported}</td>
    </tr>
  )
}

export default class MeterAssignmentForm extends React.PureComponent<
  FTProps,
  FTState
> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      hasSubmissionError: false,
      search: '',
      unassignedListEntity: props.unassignedListEntity,
      meterEntityListPaneEntity: props.unassignedListEntity,
    }
    this.handleMeterListTableRowClick =
      this.handleMeterListTableRowClick.bind(this)
  }

  static defaultProps = {
    error: undefined,
    step: 1,
  }

  componentDidUpdate(prevProps: FTProps, prevState: FTState) {
    if (!prevProps.error && this.props.error) {
      this.props.setSubmitting(false)
    }

    if (prevProps.unassignedListEntity !== this.props.unassignedListEntity) {
      this.setState({
        meterEntityListPaneEntity: this.getMeterEntityListPaneEntity(),
        unassignedListEntity: this.props.unassignedListEntity,
      })
    }

    if (prevState.search !== this.state.search) {
      this.setState({
        meterEntityListPaneEntity: this.getMeterEntityListPaneEntity(),
      })
    }
  }

  getMeterEntityListPaneEntity = (): any => {
    const data = [...this.props.unassignedListEntity.items].filter((row) => {
      const searchLower = this.state.search.toLowerCase()
      return (
        row.meterType.toLowerCase().includes(searchLower) ||
        normalizeMACAddress(row.name.toLowerCase()).includes(
          normalizeMACAddress(searchLower),
        )
      )
    })
    return { ...this.props.unassignedListEntity, items: data }
  }

  handleUnassignedSearchKeyUp = (event: FTFormFieldEvent) => {
    const searchTerm = event.target.value
    this.setState((prev) => ({ ...prev, search: searchTerm }))
  }

  onSearchUnassigned = debounce(this.handleUnassignedSearchKeyUp, 500)

  meterEntityListPaneTableHeaders = [
    {
      fieldName: 'selected',
      displayName: '',
      sortable: false,
    },
    {
      fieldName: 'name',
      displayName: 'Meter Identifier',
    },
    {
      fieldName: 'source',
      displayName: 'Meter Type',
    },
    {
      fieldName: 'firstReportDate',
      displayName: 'First Reported',
    },
  ]

  handleMeterListTableRowClick = ({
    currentTarget: {
      dataset: { id },
    },
  }: any) => {
    const { setFieldValue, updateMeterState, values } = this.props
    const checked = values.selected[id] ? !values.selected[id] : true
    setFieldValue(`selected.${id}`, checked)
    updateMeterState(id, checked)
  }

  renderMeterListTableRow = ({ id, name, meterType, firstReported }: any) => {
    const { values } = this.props
    return (
      <TableRow
        id={id}
        checked={values.selected[id]}
        handleMeterListTableRowClick={this.handleMeterListTableRowClick}
        key={id}
        name={name}
        meterType={meterType}
        firstReported={firstReported}
      />
    )
  }

  renderConfirmAssignment = () => {
    const {
      selectedCustomer,
      selectedOpportunityId,
      selectedSite,
      renderSelectedMeters,
      isSubmitting,
      loading,
      history,
    } = this.props
    return (
      <div>
        <VerifySelection>
          <table>
            <tbody>
              <tr>
                <td>
                  <b>Customer:</b>
                </td>
                <td>{selectedCustomer.validName}</td>
              </tr>
              <tr>
                <td>
                  <b>Site:</b>
                </td>
                <td>{selectedSite.validName}</td>
              </tr>
              <tr>
                <td>
                  <b>Opportunity:</b>
                </td>
                <td>{selectedOpportunityId}</td>
              </tr>
            </tbody>
          </table>
        </VerifySelection>
        {renderSelectedMeters()}
        <StyledButton
          type='button'
          disabled={isSubmitting}
          onClick={() => history.push('/account-management/unassigned')}
        >
          Cancel
        </StyledButton>
        <StyledButton
          primary
          type='submit'
          disabled={isSubmitting}
          loading={loading || isSubmitting}
        >
          Assign
        </StyledButton>
      </div>
    )
  }

  renderSelectSite = () => {
    const {
      renderSelectedMeters,
      customers,
      sites,
      fetchSites,
      setFieldValue,
      isSubmitting,
      loading,
      values,
      history,
      updateState,
    } = this.props
    const canSubmit =
      values.siteId !== null &&
      values.siteId !== undefined &&
      values.siteId !== ''
    const { contracts: availableContracts = [] } =
      (sites || []).find((site) => site?.id === values?.siteId) || {}
    return (
      <div>
        <Selector>
          <b>Customer: </b>
          <FastField
            component='select'
            name='customerId'
            onChange={({ target: { value: customerId } }) => {
              fetchSites(customerId)
              setFieldValue('siteId', '')
              setFieldValue('customerId', customerId)
              updateState({
                customerId,
                siteId: '',
              })
            }}
          >
            <option value=''>-- Select a Customer--</option>
            {customers.map(({ id, validName }) => (
              <option key={id} value={id}>
                {validName}
              </option>
            ))}
          </FastField>
        </Selector>
        <Selector>
          <b>Site: </b>
          <Field
            component='select'
            name='siteId'
            disabled={sites.length < 1}
            onChange={({ target: { value: siteId } }) => {
              setFieldValue('siteId', siteId)
              updateState({
                siteId,
              })
            }}
          >
            <option value=''>-- Select a Site --</option>
            {sites.map(({ id, validName }) => (
              <option key={id} value={id}>
                {validName}
              </option>
            ))}
          </Field>
        </Selector>
        <Selector>
          <Wrapper>
            <b>Opportunity: </b>
            <Field
              component='select'
              name='opportunity'
              disabled={availableContracts.length < 1}
              onChange={({ target: { value: opportunityId } }) => {
                setFieldValue('opportunityId', opportunityId)
                updateState({ opportunityId })
              }}
            >
              <option value=''>-- Select a Opportunity --</option>
              {availableContracts.map(({ opportunityId, opportunityName }) => (
                <option key={opportunityId} value={opportunityId}>
                  {opportunityName ?
                    `${opportunityId} - ${opportunityName}`
                  : opportunityId}
                </option>
              ))}
            </Field>
          </Wrapper>
        </Selector>
        {renderSelectedMeters()}
        <StyledButton
          type='button'
          disabled={isSubmitting}
          onClick={() => history.push('/account-management/unassigned')}
        >
          Cancel
        </StyledButton>
        <StyledButton
          primary
          type='submit'
          disabled={isSubmitting || !canSubmit}
          loading={loading}
        >
          Continue
        </StyledButton>
      </div>
    )
  }

  renderSelectMeters = () => {
    const {
      history,
      isSubmitting,
      loadAction,
      loadActionProps,
      loading,
      values,
    } = this.props
    const canSubmit =
      Object.entries(values.selected).filter(([, set]) => set).length < 1
    return (
      <div>
        <SearchStyles>
          <Input placeholder='Search' onKeyUp={this.onSearchUnassigned} />
        </SearchStyles>
        <SelectMetersStyles>
          <DeprecatedEntityListPane
            striped
            description=''
            showPageSizer={false}
            entity={this.state.meterEntityListPaneEntity}
            loadEntity={loadAction}
            loadEntityProps={loadActionProps}
            tableHeaders={this.meterEntityListPaneTableHeaders}
            renderTableRow={this.renderMeterListTableRow}
          />
        </SelectMetersStyles>
        <StyledButton
          type='button'
          disabled={isSubmitting}
          onClick={() => history.push('/account-management/unassigned')}
        >
          Cancel
        </StyledButton>
        <StyledButton
          primary
          type='submit'
          disabled={isSubmitting || canSubmit}
          loading={loading}
        >
          Continue
        </StyledButton>
      </div>
    )
  }

  render() {
    const { step, error, values } = this.props
    let renderFunc

    switch (step) {
      case 2:
        renderFunc = this.renderSelectSite
        break

      case 3:
        renderFunc = this.renderConfirmAssignment
        break

      default:
        renderFunc = this.renderSelectMeters
    }

    const when = Object.entries(values.selected).some(([, set]) => set)
    return (
      <Styles>
        <UnsavedChanges
          when={(_, nextLocation) =>
            when &&
            !nextLocation.pathname.includes(
              '/account-management/meter-assignment',
            )
          }
          message='Are you sure you want to exit the meter assignment tool?'
        />
        {error && (
          <FormError>
            There was a problem assigning these meters. Please try again.
          </FormError>
        )}
        <Form>{renderFunc()}</Form>
      </Styles>
    )
  }
}
