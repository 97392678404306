import styled from 'styled-components'
import Button from './Button'

type FTProps = {
  cancelDisabled?: boolean
  cancelText: string
  isSubmitting?: boolean
  onCancel: (...args: Array<any>) => any
  onSubmit: (...args: Array<any>) => any
  submitDisabled?: boolean
  submitText: string
}
export const StyledButtons = styled.div`
  button {
    min-width: 140px;

    + button {
      margin-left: 40px;
    }
  }
`

const FormButtons = ({
  cancelDisabled,
  cancelText,
  isSubmitting,
  onCancel,
  onSubmit,
  submitDisabled,
  submitText,
}: FTProps) => (
  <StyledButtons className='FormButtons'>
    <Button onClick={onCancel} disabled={cancelDisabled}>
      {cancelText}
    </Button>
    <Button
      disabled={submitDisabled}
      loading={isSubmitting}
      onClick={onSubmit}
      primary
      type='submit'
    >
      {submitText}
    </Button>
  </StyledButtons>
)

FormButtons.defaultProps = {
  cancelDisabled: false,
  isSubmitting: false,
  submitDisabled: false,
}
export default FormButtons
