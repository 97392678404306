import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { STORAGE_LOGIN_TOKEN } from '../constants'
import HeaderContainer from '../containers/HeaderContainer'
import MessageFlasher from '../components/MessageFlasher'
import { selectors as authSelectors } from '../ducks/auth'
import type { FTRouteProps, FTWithRouter } from '../types'
import Messages from '../components/Messages'

type FTProps = FTWithRouter & FTRouteProps

class PrivateRoute extends PureComponent<FTProps> {
  static defaultProps = {
    nextState: null,
  }

  renderComponent = (props) => {
    const {
      component: PrivateComponent,
      isLoadingPermissions,
      location,
    } = this.props
    const { pathname = '' } = location || {}

    if (isLoadingPermissions) {
      return <div />
    }

    return localStorage.getItem(STORAGE_LOGIN_TOKEN) ?
        <div className='PrivateRoute'>
          <HeaderContainer pathname={pathname} />
          <div>
            <MessageFlasher />
            <PrivateComponent {...props} />
            <Messages />
          </div>
        </div>
      : <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
            },
          }}
        />
  }

  render() {
    const { component: _, nextState: __, ...rest } = this.props
    window.scrollTo(0, 0)
    return <Route {...rest} render={this.renderComponent} />
  }
}

const mapStateToProps = (state) => ({
  isLoadingPermissions: authSelectors.isLoadingPermissions(state),
})

export default withRouter(connect(mapStateToProps)(PrivateRoute))
