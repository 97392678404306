import { takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { consoleApiUrl, defaultHeaders, financeApiUrl } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { isVariantActive } from '../utils'

export type FTFetchMeasureCodeGuideAssetUploadJobAction = {
  jobId: string
}
export type FTFetchMeasureCodeGuideAssetUploadJobMockAction = {
  jobId: string
  mockAxios: Record<string, any>
}
export type FTPostMeasureCodeGuideAssetAction = {
  file: any
}
type FTMeasureCodeGuideAssetUploadError = {
  message: string
}
type FTMeasureCodeGuideAssetErrors = Array<FTMeasureCodeGuideAssetUploadError>
export type FTMeasureCodeGuideAssetState = {
  job: {
    id: string
    progress: number
    reason: string
    status: string
  }
  errors: FTMeasureCodeGuideAssetErrors
  isPosting: boolean
  isCheckingJob: boolean
}
type FTMeasureCodeGuideAssetStateRoot = {
  measureCodeGuideAsset: FTMeasureCodeGuideAssetState
}
export const types = {
  FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB:
    'FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB',
  FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB_ERROR:
    'FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB_ERROR',
  FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS:
    'FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS',
  POST_MEASURE_CODE_GUIDE_ASSET: 'POST_MEASURE_CODE_GUIDE_ASSET',
  POST_MEASURE_CODE_GUIDE_ASSET_ERROR: 'POST_MEASURE_CODE_GUIDE_ASSET_ERROR',
  POST_MEASURE_CODE_GUIDE_ASSET_SUCCESS:
    'POST_MEASURE_CODE_GUIDE_ASSET_SUCCESS',
  RESET_MEASURE_CODE_GUIDE_ASSET_STATE: 'RESET_MEASURE_CODE_GUIDE_ASSET_STATE',
  RESET_MEASURE_CODE_GUIDE_ASSET_STATE_ERROR:
    'RESET_MEASURE_CODE_GUIDE_ASSET_STATE_ERROR',
}
export const selectMeasureCodeGuideAsset = (
  state: FTMeasureCodeGuideAssetStateRoot,
): FTMeasureCodeGuideAssetState => state.measureCodeGuideAsset
export const initialState: FTMeasureCodeGuideAssetState = {
  job: {
    id: '',
    progress: 0,
    reason: '',
    status: 'INPROGRESS',
  },
  errors: [],
  isPosting: false,
  isCheckingJob: false,
}

const measureCodeGuideAssetReducer = (
  state: FTMeasureCodeGuideAssetState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB:
      return { ...state, isCheckingJob: true }

    case types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS:
      return { ...state, isCheckingJob: false, job: action.job }

    case types.POST_MEASURE_CODE_GUIDE_ASSET:
      return {
        ...state,
        isPosting: true,
        job: {
          id: '',
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.POST_MEASURE_CODE_GUIDE_ASSET_ERROR:
      return { ...state, isPosting: false, errors: action.errors }

    case types.POST_MEASURE_CODE_GUIDE_ASSET_SUCCESS:
      return {
        ...state,
        isPosting: false,
        job: {
          id: action.payload,
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.RESET_MEASURE_CODE_GUIDE_ASSET_STATE:
      return initialState

    default:
      return state
  }
}

export default measureCodeGuideAssetReducer
export const actions = {
  postMeasureCodeGuideAsset: (params: FTPostMeasureCodeGuideAssetAction) => ({
    type: types.POST_MEASURE_CODE_GUIDE_ASSET,
    ...params,
  }),
  postMeasureCodeGuideAssetError: (errors: FTMeasureCodeGuideAssetErrors) => ({
    type: types.POST_MEASURE_CODE_GUIDE_ASSET_ERROR,
    errors,
  }),
  fetchMeasureCodeGuideAssetUploadJob: (
    params: FTFetchMeasureCodeGuideAssetUploadJobAction,
  ) => ({
    type: types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchMeasureCodeGuideAssetUploadJobMock: (
    params: FTFetchMeasureCodeGuideAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchMeasureCodeGuideAssetUploadJobMockError: (
    params: FTFetchMeasureCodeGuideAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB,
    ...params,
  }),
  resetMeasureCodeGuideAssetState: () => ({
    type: types.RESET_MEASURE_CODE_GUIDE_ASSET_STATE,
  }),
}
type FTPostMeasureCodeGuideAssetException = {
  name: string
  errors: FTMeasureCodeGuideAssetErrors
  toString: string
}

const PostMeasureCodeGuideAssetException = ({
  errors,
}): FTPostMeasureCodeGuideAssetException => ({
  name: 'PostMeasureCodeGuideAssetError',
  errors,
  toString: 'PostMeasureCodeGuideAssetError',
})

const handlePostMeasureCodeGuideAssetError = (error: Record<string, any>) => {
  const { response } = error || {}
  const { status, data } = response || {}

  if (status === 400) {
    const dataFormatted = data instanceof Array ? data : [data]
    const errors = data ? dataFormatted : initialState.errors
    throw PostMeasureCodeGuideAssetException({
      errors,
    })
  } else {
    handleAxiosError(error)
  }
}

// API
export class API {
  static postMeasureCodeGuideAsset({ file }: Record<string, any>) {
    const url = `${financeApiUrl()}/admin/csv/ltg-measure-code`
    const formData = new FormData()
    formData.set('file', file)

    if (isVariantActive('lighting-as-builts-asset-manager-mock-error')) {
      const mockAxios = axios.create()
      const mock = new MockAdapter(mockAxios, {
        delayResponse: 500,
      })

      /* eslint-disable max-len */
      mock
        .onPost(url)
        .reply(400, [
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
        ])
        .onAny()
        .passThrough()
      return mockAxios({
        method: 'post',
        url,
        data: formData,
        headers: defaultHeaders(),
      })
        .then(({ data: { jobId } }) => jobId)
        .catch(handlePostMeasureCodeGuideAssetError)
    }

    if (
      isVariantActive('lighting-as-builts-asset-manager-mock') ||
      isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
    ) {
      return Promise.resolve('mockJobId').then((data) => data)
    }

    return axios({
      method: 'post',
      url,
      data: formData,
      headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
    })
      .then(({ data: { jobId } }) => jobId)
      .catch(handlePostMeasureCodeGuideAssetError)
  }

  static fetchMeasureCodeGuideAssetUploadJob({ jobId }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return axios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchMeasureCodeGuideAssetUploadJobMock({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchMeasureCodeGuideAssetUploadJobMockError({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason:
          headers['job-reason'] || 'MeasureCode with id F43ILLAA not found',
        status: headers['job-status'] || 'ERROR',
      }))
      .catch(handleAxiosError)
  }
}

function* postMeasureCodeGuideAssetSaga(
  params: FTPostMeasureCodeGuideAssetAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.postMeasureCodeGuideAsset, params)
    yield put({
      type: types.POST_MEASURE_CODE_GUIDE_ASSET_SUCCESS,
      payload,
    })
  } catch (error) {
    if (error.name && error.name === 'PostMeasureCodeGuideAssetError') {
      const { errors } = error
      yield put(actions.postMeasureCodeGuideAssetError(errors))
    } else {
      yield handleSagaError(
        'POST_MEASURE_CODE_GUIDE_ASSET_ERROR_GENERAL',
        error,
      )
    }
  }
}

function* fetchMeasureCodeGuideAssetUploadJobSaga(
  params: FTFetchMeasureCodeGuideAssetUploadJobAction,
): Generator<any, void, any> {
  try {
    let job

    if (isVariantActive('lighting-as-builts-asset-manager-mock')) {
      job = yield call(API.fetchMeasureCodeGuideAssetUploadJobMock, params)
    } else if (
      isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
    ) {
      job = yield call(API.fetchMeasureCodeGuideAssetUploadJobMockError, params)
    } else {
      job = yield call(API.fetchMeasureCodeGuideAssetUploadJob, params)
    }

    yield put({
      type: types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB_SUCCESS,
      job,
    })
  } catch (error) {
    yield handleSagaError(
      types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB_ERROR,
      error,
    )
  }
}

export const sagas = [
  takeEvery(types.POST_MEASURE_CODE_GUIDE_ASSET, postMeasureCodeGuideAssetSaga),
  takeEvery(
    types.FETCH_MEASURE_CODE_GUIDE_ASSET_UPLOAD_JOB,
    fetchMeasureCodeGuideAssetUploadJobSaga,
  ),
]
