/* eslint react/no-did-update-set-state: 0 */
// Reason: AirBnb has since removed requirement, but hasn't released yet.
// More info: https://github.com/airbnb/javascript/issues/684#issuecomment-391814056
import React from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import Tippy from '@tippyjs/react'
import Checkbox from './Checkbox'
import ChannelTagsPlotView from './ChannelTagsPlotView'
import CloseButtonTableSubComponent from './CloseButtonTableSubComponent'
import Disabled from './Disabled'
import ErrorMessage from './ErrorMessage'
import FieldWithPending from './FieldWithPending'
import Input from './TableInput'
import { InputComponent } from './Input'
import type { FTItem } from './ListSelector'
import ListSelector from './ListSelector'
import RedaptiveReactTable, {
  booleanFilter,
  exactFilter,
} from './RedaptiveReactTable'
import UnsavedChanges from './UnsavedChanges'
import { getValueFromEvent, makeListSelectorItem } from '../utils'
import type { FTCircuit } from '../ducks/circuits'
import { breakerNumbers } from '../ducks/circuits'
import type { FTEntity } from '../ducks/types'
import '../ducks/types'
import type {
  FTFormFieldEvent,
  FTFormSubmitEvent,
  FTSite,
  FTWithFormik,
} from '../types'
import type { FTMeter } from '../ducks/meters'
import type { FTPhaseGroupSummary } from '../ducks/phaseGroups'
import type { FTPanel } from '../ducks/panels'
import { naturallySortContracts } from '../ducks/sites'
import Button from './Button'
import {
  CTOrientationCellStyles,
  ReportProblemIconStyled,
} from './CircuitTable'
import HelpIcon from './Icons/HelpIcon'
import Spinner from './Spinner'

const ButtonGroup = styled.div`
  margin-top: 20px;
  height: 150px;
`
const FormMeta = styled.div`
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  margin-bottom: 0px;
`
export const PlainCellStyles = styled.div`
  padding: 0 10px;
`
const ExpandedMeterChannelStyles = styled(PlainCellStyles)`
  font-size: 16px;
  padding: 0 10px 0 20px;
`
const ExpandedMeterChannelLabelStyles = styled.span`
  display: inline-block;
  font-weight: 600;
  padding-right: 10px;
`
const TableStyles = styled.div`
  .ReactTable {
    .rt-tbody .rt-tr-group.rt-tr-group-expanded {
      margin: 12px 0;

      &:first-child {
        margin-top: 0;
      }

      + .rt-tr-group-expanded {
        margin-top: 0;
      }
    }

    &.-highlight .rt-tbody {
      .rt-tr-group-expanded {
        > .rt-tr,
        > .rt-tr:not(.-padRow):hover {
          background: #f7f7f7;
        }
      }
    }

    .rt-tbody,
    .rt-table,
    .rt-td {
      overflow: visible;
    }

    .rt-td {
      align-self: center;
      padding: 0;
    }
  }

  .rt-td {
    ${InputComponent} {
      width: 100%;
    }
  }

  .rt-tr-group-expanded {
    > .rt-tr > .rt-td {
      display: flex;
      align-items: center;
      height: 55px;

      &.rt-expandable {
        justify-content: center;
      }
    }
  }
`
const MeteredUpstreamSelectorStyled = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #337ab7;

  span {
    cursor: pointer;
  }
`
export const MeteredUpstreamInfoStyled = styled.span`
  display: flex;
  align-items: center;
`
export const MeteredUpstreamHelpContentStyled = styled.span`
  display: flex;
  flex-direction: column;
  padding: 10px;
  div {
    text-align: center;
  }
`

const InputWrapper = styled.div`
  position: relative;
`

const SpinnerWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 10px;
`

type FTFormState = {
  circuits: Array<FTCircuit>
}
type FTFormStatus = {
  meter: FTMeter
}
type FTById = Record<string, FTItem | null | undefined>
type FTPanelsById = Record<string, FTPanel | null | undefined>
type FTCell = {
  index: number
  isExpanded: boolean
  original: FTCircuit
  value: any
}
type BuildingEquipmentsType = Record<string, string>
type FTProps = {
  bsById: FTById
  buildingSystems: Array<Record<string, any>>
  conditionalFields: Record<string, any>
  circuitListEntity: FTEntity
  circuits: Array<FTCircuit>
  editableFields: Array<string>
  equipment: Array<Record<string, any>>
  equipmentById: FTById
  error: string
  errors: Record<string, any>
  goBack: (...args: Array<any>) => any
  meter: FTMeter
  meterStartDate: string
  panelById: FTPanelsById
  panels: Array<Record<string, any>>
  phaseGroups: Array<FTPhaseGroupSummary>
  phaseGroupsById: Record<string, any>
  showCircuitUpdateConfirm: (...args: Array<any>) => any
  showEquipmentEditor: (...args: Array<any>) => any
  showModalPanelForm: (...args: Array<any>) => any
  showModalPanelListEditor: (...args: Array<any>) => any
  showPhaseGroupEditor: (...args: Array<any>) => any
  site: FTSite
  status: FTFormStatus
  values: FTFormState
  touched: FTFormState
  buildingEquipments: BuildingEquipmentsType
  fetchBuildingEquipments: ({
    circuitDescription: string,
    customerId: string,
  }) => void
  onAddEquipment: (str: string) => void
  buildingEquipmentsLoading: boolean
} & FTWithFormik
type FTState = {
  columns: Array<Record<string, any>>
  contractSummaryItems: Array<Record<string, any>>
  equipmentItems: Array<Record<string, any>>
  phaseGroupItems: Array<Record<string, any>>
  subComponent: (...args: Array<any>) => any
  touched: FTFormState
  circuitDescriptionIndex: number
  circuitDescriptionName: string
  circuitDescription: string
}

export default class CircuitListForm extends React.PureComponent<
  FTProps,
  FTState
> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      columns: this.getColumns(),
      contractSummaryItems: this.getContractSummaryItems(),
      equipmentItems: this.getEquipmentItems(),
      meteredUpstreamSelectAllChecked: false,
      phaseGroupItems: this.getPhaseGroupItems(),
      subComponent: this.getSubcomponent(),
      touched: {
        circuits: [],
      },
      equipmentUpdateIndex: null,
      circuitDescriptionIndex: 0,
      circuitDescription: '',
      circuitDescriptionName: '',
    }
  }

  componentDidUpdate(prev: FTProps) {
    const {
      equipmentUpdateIndex,
      circuitDescriptionIndex,
      circuitDescription,
      circuitDescriptionName,
    } = this.state
    const {
      equipment,
      error,
      phaseGroups,
      setTouched,
      buildingEquipments,
      site,
    } = this.props
    const { customerId } = site

    const {
      equipment: prevEquipment,
      phaseGroups: prevPhaseGroups,
      buildingEquipments: prevBuildingEquipments,
    } = prev

    if (!prev.error && error) {
      const { touched } = this.state
      this.props.setSubmitting(false)
      setTouched(touched)
      window.scrollTo(0, 0)
      return
    }

    const newState = {}

    if (phaseGroups !== prevPhaseGroups || equipment !== prevEquipment) {
      newState.columns = this.getColumns()
      newState.subComponent = this.getSubcomponent()
    }

    if (phaseGroups !== prevPhaseGroups) {
      newState.phaseGroupItems = this.getPhaseGroupItems()
    }

    if (buildingEquipments !== prevBuildingEquipments) {
      const key = `${customerId}_${circuitDescription.toLowerCase()}`
      if (circuitDescription && buildingEquipments[key]) {
        this.handleSetBuildingSystemAndEquipmentName(
          buildingEquipments[key],
          circuitDescriptionName,
          circuitDescriptionIndex,
          circuitDescription,
        )
      }
    }

    if (equipment !== prevEquipment) {
      newState.equipmentItems = this.getEquipmentItems()
      if (equipmentUpdateIndex !== null) {
        const item = equipment.filter(
          ({ id: id1 }) => !prevEquipment.some(({ id: id2 }) => id2 === id1),
        )
        if (item?.[0]) {
          this.listSelectorUpdateHandlers.equipmentId[equipmentUpdateIndex]({
            value: item[0].id,
          })
          newState.equipmentUpdateIndex = null
        }
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState)
    }
  }

  isRedaptive = () => {
    const {
      status: {
        meter: { source },
      },
    } = this.props
    return source === 'REDAPTIVE'
  }

  onInputChange =
    (index: number, fieldName: string) => (event: FTFormFieldEvent) => {
      const { target } = event
      const { buildingEquipments } = this.props
      if (fieldName === 'description' && buildingEquipments) {
        if (target.value === '') {
          this.listSelectorUpdateHandlers.buildingSystemId[index]({
            value: null,
          })
          this.listSelectorUpdateHandlers.equipmentId[index]({
            value: null,
          })
        }
      }
      this.updateField(index, target.name, fieldName, target.value)
    }

  handleSetBuildingSystemAndEquipmentName = (
    buildingSystemName: string,
    name: string,
    index: number,
    description: string,
  ) => {
    const { equipmentItems, circuitDescription } = this.state
    const { onAddEquipment } = this.props
    const buildingSystem = Object.values(this.props.bsById).find(
      (val) => val?.name?.trim() === buildingSystemName.trim(),
    )
    if (buildingSystem?.id) {
      this.listSelectorUpdateHandlers.buildingSystemId[index]({
        value: buildingSystem.id,
      })
      const { id: equipmentId } =
        equipmentItems.find(
          ({ name }) => name.toLowerCase() === description.toLowerCase(),
        ) || {}
      if (equipmentId) {
        this.listSelectorUpdateHandlers.equipmentId[index]({
          value: equipmentId,
        })
      } else {
        onAddEquipment(description)
        this.setState((prev) => ({ ...prev, equipmentUpdateIndex: index }))
      }
      this.updateField(
        index,
        name,
        'description',
        description || circuitDescription,
      )
    } else {
      this.updateField(
        index,
        name,
        'description',
        description || circuitDescription,
      )
    }
    this.setState((prev) => ({
      ...prev,
      circuitDescriptionIndex: 0,
      circuitDescription: '',
      circuitDescriptionName: '',
    }))
  }

  onInputBlur =
    (index: number, fieldName: string) => (event: FTFormFieldEvent) => {
      const { target } = event
      const { buildingEquipments, site, fetchBuildingEquipments } = this.props
      const { customerId } = site
      if (fieldName === 'description') {
        if (target.value) {
          const key = `${customerId}_${target.value.toLowerCase()}`
          if (buildingEquipments[key]) {
            this.handleSetBuildingSystemAndEquipmentName(
              buildingEquipments[key],
              target.name,
              index,
              target.value,
            )
          } else {
            fetchBuildingEquipments({
              circuitDescription: target.value,
              customerId,
            })
            this.setState((prev) => ({
              ...prev,
              circuitDescriptionIndex: index,
              circuitDescription: target.value,
              circuitDescriptionName: target.name,
            }))
          }
        }
      } else {
        this.updateField(index, target.name, fieldName, target.value)
      }
    }

  onCheckboxChange =
    (index: number, fieldName: string) => (event: FTFormFieldEvent) => {
      const {
        target: { checked, name: inputName },
      } = event
      this.updateField(index, inputName, fieldName, checked)
    }

  onPanelIdFooterClick = (event: {
    target: {
      dataset: {
        index: number
      }
    }
  }) => {
    const {
      target: {
        dataset: { index },
      },
    } = event
    const {
      conditionalFields,
      meter: { siteId, generation: meterGeneration },
      panels,
      showModalPanelForm,
      showModalPanelListEditor,
    } = this.props

    const showModalPanelListEditorInstance = () =>
      showModalPanelListEditor({
        onSelectEntity: (value) => {
          this.updateField(
            index,
            `circuits.${index.toString()}.panelId`,
            'panelId',
            value,
          )
        },
        reload: showModalPanelListEditorInstance,
      })

    if (panels.length) {
      showModalPanelListEditorInstance()
    } else {
      showModalPanelForm({
        conditionalFields,
        meterGeneration,
        handleSuccess: showModalPanelListEditorInstance,
        siteId,
      })
    }
  }

  onEquipmentFooterClick = (event: {
    target: {
      dataset: {
        index: number
      }
    }
  }) => {
    const {
      target: {
        dataset: { index },
      },
    } = event
    const { showEquipmentEditor } = this.props
    showEquipmentEditor((value) => {
      this.updateField(
        index,
        `circuits.${index.toString()}.equipmentId`,
        'equipmentId',
        value,
      )
    })
  }

  onPhaseGroupFooterClick = (event: {
    target: {
      dataset: {
        index: number
      }
    }
  }) => {
    const {
      target: {
        dataset: { index },
      },
    } = event
    const { showPhaseGroupEditor } = this.props
    showPhaseGroupEditor((value) => {
      this.updateField(
        index,
        `circuits.${index.toString()}.phaseGroupId`,
        'phaseGroupId',
        value,
      )
    })
  }

  onCancel = () => this.props.goBack()

  onSubmit = (event: FTFormSubmitEvent) => {
    const { handleSubmit, touched, showCircuitUpdateConfirm } = this.props
    this.setState((prev) => ({ ...prev, touched }))
    showCircuitUpdateConfirm({
      primaryActionText: 'Yes',
      onPrimaryAction: () => handleSubmit(event),
      secondaryActionText: 'No',
      title: 'Save Changes?',
      modalWidth: '418px',
    })
  }

  getEdited = (index: number, field: string) => {
    const { isSubmitting, touched } = this.props
    return (
      !isSubmitting &&
      touched.circuits &&
      touched.circuits[index] &&
      touched.circuits[index][field]
    )
  }

  getDirectionUp = (index: number) => {
    const {
      values: { circuits },
    } = this.props
    return index > 6 && index >= circuits.length - 8
  }

  isFormTouched = () => {
    const { circuits, editableFields, touched } = this.props

    if (circuits.length < 1 || !touched.circuits) {
      return false
    }

    for (let i = 0; i < touched.circuits.length; i += 1) {
      const res = editableFields
        .map((k) => (touched.circuits[i] ? touched.circuits[i][k] : false))
        .some((x) => x)

      if (res) {
        return true
      }
    }

    return false
  }

  updateField = (
    index: number,
    inputName: string,
    fieldName: string,
    value: any,
  ) => {
    const { circuits, setFieldValue, setFieldTouched } = this.props
    const initial = circuits[index][fieldName]
    let current

    if (typeof value === 'boolean') {
      current = value
    } else {
      current = value || null
    }
    setFieldTouched(inputName, !isEqual(initial, current)).then(() => {
      setFieldValue(inputName, value)
    })
  }

  updateListSelector =
    (index: number, field: string) =>
    ({ value }: FTFormFieldEvent) => {
      const newValue = value === null ? '' : value
      this.updateField(
        index,
        `circuits.${index.toString()}.${field}`,
        field,
        newValue,
      )
    }

  updatePhaseGroup =
    (index: number) =>
    ({ value }: FTFormFieldEvent) => {
      this.updateField(
        index,
        `circuits.${index.toString()}.phaseGroupId`,
        'phaseGroupId',
        value || '',
      )
    }

  handleSelectAll = (checked: boolean) => {
    const { values } = this.props
    const { circuits } = values
    circuits.forEach((circuit, index) => {
      this.updateField(
        index,
        `circuits.${index.toString()}.meteredUpstream`,
        'meteredUpstream',
        checked,
      )
    })
  }

  onUpdateMeteredUpstream =
    (index: number, field: string) => (event: FTFormFieldEvent) => {
      const value = getValueFromEvent(event)
      this.updateField(
        index,
        `circuits.${index.toString()}.meteredUpstream`,
        field,
        value,
      )
    }

  listSelectorUpdateHandlers = {
    breakerNumber: [...Array(48).keys()].map<(...args: Array<any>) => any>(
      (index) => this.updateListSelector(index, 'breakerNumber'),
    ),
    buildingSystemId: [...Array(48).keys()].map<(...args: Array<any>) => any>(
      (index) => this.updateListSelector(index, 'buildingSystemId'),
    ),
    contractId: [...Array(48).keys()].map<(...args: Array<any>) => any>(
      (index) => this.updateListSelector(index, 'contractId'),
    ),
    equipmentId: [...Array(48).keys()].map<(...args: Array<any>) => any>(
      (index) => this.updateListSelector(index, 'equipmentId'),
    ),
    panelId: [...Array(48).keys()].map<(...args: Array<any>) => any>((index) =>
      this.updateListSelector(index, 'panelId'),
    ),
    panelFeedId: [...Array(48).keys()].map<(...args: Array<any>) => any>(
      (index) => this.updateListSelector(index, 'panelFeedId'),
    ),
    phaseGroupId: [...Array(48).keys()].map<(...args: Array<any>) => any>(
      (index) => this.updatePhaseGroup(index),
    ),
  }

  getPanelFooterLinkText = () => {
    const { panels } = this.props
    return (panels.length && 'Edit Panels') || 'Add Panel'
  }

  getPanelNameField = (
    {
      index,
      panelId,
      value,
      hiddenMode = true,
    }: {
      index: number
      panelId: string
      value: string
      hiddenMode?: boolean
    }, // Disables the field for Redaptive meters. See RDP-5661.
  ) =>
    this.isRedaptive() ?
      <PlainCellStyles>
        <Disabled>{value}</Disabled>
      </PlainCellStyles>
    : <ListSelector
        editDotLeft={hiddenMode ? '0' : '-10px'}
        hideEditDotOnHover={hiddenMode}
        index={index}
        hiddenMode={hiddenMode}
        footerLinkText={this.getPanelFooterLinkText()}
        onFooterClick={this.onPanelIdFooterClick}
        edited={this.getEdited(index, 'panelId')}
        items={this.props.panels}
        selectedItem={this.props.panelById[panelId]}
        fieldName={`circuits.${index.toString()}.panelId`}
        updateValue={this.listSelectorUpdateHandlers.panelId[index]}
        directionUp={this.getDirectionUp(index)}
        key={`${index}.panelId`}
      />

  getBreakerNumberField = ({
    index,
    value,
    hiddenMode = true,
  }: {
    index: number
    value: string
    hiddenMode?: boolean
  }) =>
    this.isRedaptive() ?
      <PlainCellStyles>
        <Disabled>{value}</Disabled>
      </PlainCellStyles>
    : <ListSelector
        editDotLeft={hiddenMode ? '0' : '-10px'}
        hideEditDotOnHover={hiddenMode}
        hiddenMode={hiddenMode}
        edited={this.getEdited(index, 'breakerNumber')}
        items={breakerNumbers}
        selectedItem={
          value ?
            {
              id: value,
              name: value,
            }
          : null
        }
        fieldName={`circuits.${index.toString()}.breakerNumber`}
        updateValue={this.listSelectorUpdateHandlers.breakerNumber[index]}
        directionUp={this.getDirectionUp(index)}
        key={`${index}.breakerNumber`}
      />

  getPhaseGroupItems = () => {
    const { phaseGroups } = this.props
    return phaseGroups.map<Record<string, any> | null | undefined>(
      ({ id: value, name }) => makeListSelectorItem(value, name),
    )
  }

  getPhaseGroupField = ({
    index,
    phaseGroupId = '',
    hiddenMode = true,
  }: {
    index: number
    phaseGroupId?: string
    hiddenMode?: boolean
  }) => (
    <ListSelector
      editDotLeft={hiddenMode ? '0' : '-10px'}
      hideEditDotOnHover={hiddenMode}
      fieldName={`phaseGroup${index}`}
      index={index}
      hiddenMode={hiddenMode}
      footerLinkText='Edit Phase Groups'
      onFooterClick={this.onPhaseGroupFooterClick}
      edited={this.getEdited(index, 'phaseGroupId')}
      items={this.state.phaseGroupItems}
      selectedItem={this.props.phaseGroupsById[phaseGroupId] || null}
      updateValue={this.listSelectorUpdateHandlers.phaseGroupId[index]}
      directionUp={this.getDirectionUp(index)}
      key={`${index}.phaseGroupId`}
      notSetItemText='None'
      notSetLabelText='None'
    />
  )

  getCTTypeAmps = (circuit: FTCircuit) => {
    const {
      status: { meter },
    } = this.props
    let ctTypeAmps = ''

    if (meter.hybridConfig && meter.hybridConfig.circuitConfigurationsById) {
      const {
        hybridConfig: { circuitConfigurationsById: byId },
      } = meter
      const channel = byId[circuit.id]

      if (channel) {
        ;({ ctTypeAmps } = channel)
      }
    }

    return ctTypeAmps
  }

  getCtTypeAmpsField = ({ circuit }: { circuit: FTCircuit }) => (
    <PlainCellStyles>
      <Disabled>
        <FieldWithPending field={this.getCTTypeAmps(circuit)} />
      </Disabled>
    </PlainCellStyles>
  )

  getCircuitDescriptionField = ({
    index,
    value,
    hiddenMode = true,
  }: {
    index: number
    value: string
    hiddenMode?: boolean
  }) => {
    const { circuitDescriptionIndex } = this.state
    const { buildingEquipmentsLoading } = this.props
    return (
      <InputWrapper>
        <Input
          alwaysDraw={!hiddenMode}
          editDotLeft={hiddenMode ? '0' : '-10px'}
          hideEditDotOnHover={hiddenMode}
          key={`${index}.description`}
          name={`circuits.${index.toString()}.description`}
          edited={this.getEdited(index, 'description')}
          defaultValue={value}
          onBlur={this.onInputBlur(index, 'description')}
          onChange={this.onInputChange(index, 'description')}
          disabled={buildingEquipmentsLoading}
        />
        {buildingEquipmentsLoading && index === circuitDescriptionIndex && (
          <SpinnerWrapper>
            <Spinner size='tiny' />
          </SpinnerWrapper>
        )}
      </InputWrapper>
    )
  }

  getInitialChannelValue = (id: string, field: string) => {
    const { initialValues } = this.props
    const channelsById = initialValues.circuits.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {},
    )
    const initialChannel = channelsById[id]

    if (!initialChannel) {
      return undefined
    }

    return initialChannel[field]
  }

  channelFieldIsEdited = (id: string, field: string) => {
    const {
      values: { circuits },
    } = this.props
    const initial = this.getInitialChannelValue(id, field)
    const circuitsById = circuits.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {},
    )
    const current = circuitsById[id] ? circuitsById[id][field] : undefined

    if (initial == null && current === false) {
      return false
    }

    return initial !== current
  }

  getMeteredUpstreamField = (value: string, id: string, index: number) => (
    <PlainCellStyles>
      <Checkbox
        name='meteredUpstream'
        checked={value}
        value={value}
        onChange={this.onUpdateMeteredUpstream(index, 'meteredUpstream')}
        edited={this.channelFieldIsEdited(id, 'meteredUpstream')}
      />
      <span>{value ? ' Yes' : ' No'}</span>
    </PlainCellStyles>
  )

  getPanelFeedField = ({
    index,
    panelFeedId = '',
    hiddenMode = true,
  }: {
    index: number
    panelFeedId?: string
    hiddenMode?: boolean
  }) => (
    <ListSelector
      hiddenMode={hiddenMode}
      editDotLeft={hiddenMode ? '' : '-10px'}
      hideEditDotOnHover={hiddenMode}
      edited={this.getEdited(index, 'panelFeedId')}
      updateValue={this.listSelectorUpdateHandlers.panelFeedId[index]}
      selectedItem={this.props.panelById[panelFeedId]}
      items={this.props.panels}
      directionUp={this.getDirectionUp(index)}
      notSetItemText='None'
      notSetLabelText='None'
      key={`${index}.panelFeedId`}
    />
  )

  getBuildingSystemField = ({
    index,
    buildingSystemId,
    hiddenMode = true,
  }: {
    index: number
    buildingSystemId: string
    hiddenMode?: boolean
  }) => (
    <ListSelector
      hiddenMode={hiddenMode}
      editDotLeft={hiddenMode ? '' : '-10px'}
      hideEditDotOnHover={hiddenMode}
      edited={this.getEdited(index, 'buildingSystemId')}
      updateValue={this.listSelectorUpdateHandlers.buildingSystemId[index]}
      selectedItem={this.props.bsById[buildingSystemId]}
      items={this.props.buildingSystems}
      directionUp={this.getDirectionUp(index)}
      notSetItemText='Uncategorized'
      notSetLabelText='Uncategorized'
      key={`${index}.buildingSystemId`}
      disabled={this.props.buildingEquipmentsLoading}
    />
  )

  getEquipmentItems = () => {
    const { equipment } = this.props
    return equipment.map<Record<string, any> | null | undefined>(
      ({ id: value, name }) => makeListSelectorItem(value, name),
    )
  }

  getEquipmentField = ({
    index,
    equipmentId,
    hiddenMode = true,
  }: {
    index: number
    equipmentId: string
    hiddenMode?: boolean
  }) => (
    <ListSelector
      editDotLeft={hiddenMode ? '0' : '-10px'}
      hiddenMode={hiddenMode}
      hideEditDotOnHover={hiddenMode}
      index={index}
      footerLinkText='Edit Equipment'
      onFooterClick={this.onEquipmentFooterClick}
      edited={this.getEdited(index, 'equipmentId')}
      items={this.state.equipmentItems}
      selectedItem={this.props.equipmentById[equipmentId] || null}
      fieldName={`circuits.${index.toString()}.equipmentId`}
      updateValue={this.listSelectorUpdateHandlers.equipmentId[index]}
      directionUp={this.getDirectionUp(index)}
      key={`${index}.equipmentId`}
      disabled={this.props.buildingEquipmentsLoading}
    />
  )

  getBuildingAreaField = ({
    index,
    value,
    hiddenMode = true,
  }: {
    index: number
    value: string
    hiddenMode?: boolean
  }) => (
    <Input
      alwaysDraw={!hiddenMode}
      editDotLeft={hiddenMode ? '0' : '-10px'}
      hideEditDotOnHover={hiddenMode}
      key={`${index}.buildingArea`}
      name={`circuits.${index.toString()}.buildingArea`}
      edited={this.getEdited(index, 'buildingArea')}
      defaultValue={value}
      onBlur={this.onInputBlur(index, 'buildingArea')}
    />
  )

  getContractSummaryItems = () => {
    const {
      site: { contracts = [] },
    } = this.props
    return [...contracts]
      .sort(naturallySortContracts)
      .map<Record<string, any> | null | undefined>(
        ({ id, opportunityId, type }) =>
          makeListSelectorItem(id, `${opportunityId} - ${type}`),
      )
  }

  getContractField = ({
    index,
    value,
    hiddenMode = true,
  }: {
    index: number
    value: string
    hiddenMode?: boolean
  }) => {
    const selectedItem =
      value ?
        this.state.contractSummaryItems.find(({ id }) => value === id)
      : null
    return (
      <ListSelector
        hiddenMode={hiddenMode}
        editDotLeft={hiddenMode ? '0' : '-10px'}
        hideEditDotOnHover={hiddenMode}
        notSetItemText='None'
        notSetLabelText='None'
        edited={this.getEdited(index, 'contractId')}
        items={this.state.contractSummaryItems}
        selectedItem={selectedItem}
        fieldName={`circuits.${index.toString()}.contractId`}
        updateValue={this.listSelectorUpdateHandlers.contractId[index]}
        directionUp={this.getDirectionUp(index)}
        key={`${index}.contractId`}
      />
    )
  }

  meterChannelCell = ({ value, isExpanded }: FTCell) =>
    isExpanded ?
      <ExpandedMeterChannelStyles>
        <ExpandedMeterChannelLabelStyles>
          {'Meter Channel: '}
        </ExpandedMeterChannelLabelStyles>
        {value}
      </ExpandedMeterChannelStyles>
    : <PlainCellStyles>{value}</PlainCellStyles>

  panelAccessor = (row: FTCircuit) =>
    this.props.panelById[row.panelId] ?
      this.props.panelById[row.panelId].name
    : null

  panelCell = ({ index, isExpanded, original: { panelId }, value }: FTCell) =>
    isExpanded ? '' : (
      this.getPanelNameField({
        index,
        panelId,
        value,
      })
    )

  breakerNumberCell = ({ index, isExpanded, value }: FTCell) =>
    isExpanded ? '' : (
      this.getBreakerNumberField({
        index,
        value,
      })
    )

  phaseCell = ({ isExpanded, value }: FTCell) => (isExpanded ? '' : value)

  phaseGroupAccessor = (row: FTCircuit) =>
    this.props.phaseGroupsById[row.phaseGroupId] ?
      this.props.phaseGroupsById[row.phaseGroupId].name
    : null

  phaseGroupCell = ({
    index,
    isExpanded,
    original: { phaseGroupId },
  }: FTCell) =>
    isExpanded ? '' : (
      this.getPhaseGroupField({
        index,
        phaseGroupId,
      })
    )

  ctTypeAmpsCell = ({ isExpanded, original: circuit }: FTCell) =>
    isExpanded ? '' : (
      this.getCtTypeAmpsField({
        circuit,
      })
    )

  circuitDescriptionCell = ({ index, isExpanded, value }: FTCell) =>
    isExpanded ? '' : (
      this.getCircuitDescriptionField({
        index,
        value,
      })
    )

  meteredUpstreamCell = ({
    index,
    isExpanded,
    value,
    original: { id },
  }: FTCell) =>
    isExpanded ? value : this.getMeteredUpstreamField(value, id, index)

  buildingSystemAccessor = (row: FTCircuit) =>
    this.props.bsById[row.buildingSystemId] ?
      this.props.bsById[row.buildingSystemId].name
    : null

  panelFeedAccessor = (row: FTCircuit) =>
    this.props.panelById[row.panelFeedId || ''] ?
      this.props.panelById[row.panelFeedId || '']?.name
    : null

  buildingSystemCell = ({
    index,
    isExpanded,
    original: { buildingSystemId },
  }: FTCell) =>
    isExpanded ? '' : (
      this.getBuildingSystemField({
        index,
        buildingSystemId,
      })
    )

  panelFeedCell = ({ index, isExpanded, original: { panelFeedId } }: FTCell) =>
    isExpanded ? '' : (
      this.getPanelFeedField({
        index,
        panelFeedId,
      })
    )

  equipmentAccessor = (row: FTCircuit) =>
    this.props.equipmentById[row.equipmentId] ?
      this.props.equipmentById[row.equipmentId].name
    : null

  equipmentCell = ({ index, isExpanded, original: { equipmentId } }: FTCell) =>
    isExpanded ? '' : (
      this.getEquipmentField({
        index,
        equipmentId,
      })
    )

  buildingAreaCell = ({ index, isExpanded, value }: FTCell) =>
    isExpanded ? '' : (
      this.getBuildingAreaField({
        index,
        value,
      })
    )

  contractCell = ({ index, isExpanded, value }: FTCell) =>
    isExpanded ? '' : (
      this.getContractField({
        index,
        value,
      })
    )

  flippedCTCell = ({
    isExpanded,
    original: { flippedCTLabel },
    value,
  }: FTCell) => {
    if (isExpanded) {
      return ''
    }

    const labelWarning = ['NOT_LABELED', 'NEEDS_REVIEW'].includes(value)
    return (
      <PlainCellStyles>
        <Disabled>
          <CTOrientationCellStyles warning={labelWarning}>
            {labelWarning && <ReportProblemIconStyled />}
            {flippedCTLabel}
          </CTOrientationCellStyles>
        </Disabled>
      </PlainCellStyles>
    )
  }

  plotViewExpander = ({ isExpanded }: FTCell) =>
    isExpanded ? <CloseButtonTableSubComponent /> : <span>Plot View</span>

  getMeteredUpstreamHelpContent = () => (
    <MeteredUpstreamHelpContentStyled>
      <div>
        Check this box to exclude the load from the total consumption calculated
        at the site.{' '}
      </div>
      <div>
        For use when the load is accounted for by another metered circuit.
      </div>
    </MeteredUpstreamHelpContentStyled>
  )

  getColumns = () => {
    const {
      meter: { isNebula },
    } = this.props
    const isRedaptive = this.isRedaptive()
    const nebulaColumns =
      isNebula ?
        [
          {
            accessor: 'flippedCTStatusCurrent',
            Cell: this.flippedCTCell,
            Header: 'CT Orientation',
            minWidth: 200,
            sortable: false,
          },
        ]
      : []
    return [
      {
        accessor: 'meterChannel',
        Cell: this.meterChannelCell,
        Header: 'Meter Channel',
        sortable: false,
        minWidth: (isRedaptive && 74) || 130,
      },
      {
        accessor: this.panelAccessor,
        Cell: this.panelCell,
        filterMethod: exactFilter,
        Header: 'Panel',
        id: 'panelId',
        minWidth: 162,
        sortable: false,
      },

      {
        accessor: 'breakerNumber',
        Cell: this.breakerNumberCell,
        filterMethod: exactFilter,
        Header: 'Breaker Number',
        sortable: false,
        minWidth: 74,
      },
      {
        accessor: 'phase',
        Cell: this.phaseCell,
        Header: 'Phase',
        minWidth: 60,
        sortable: false,
        show: isRedaptive,
      },
      {
        accessor: this.phaseGroupAccessor,
        Cell: this.phaseGroupCell,
        Header: 'Phase Group',
        id: 'phaseGroupId',
        minWidth: 160,
        show: isRedaptive,
        sortable: false,
      },
      {
        accessor: 'ctTypeAmps',
        Cell: this.ctTypeAmpsCell,
        Header: 'CT Type',
        minWidth: 140,
        sortable: false,
      },
      {
        accessor: 'description',
        Cell: this.circuitDescriptionCell,
        Header: (
          <>
            Circuit Description
            <br />
            (from Panel Label)
          </>
        ),
        minWidth: 162,
        sortable: false,
      },
      {
        accessor: this.panelFeedAccessor,
        Cell: this.panelFeedCell,
        Header: 'Panel Feed',
        id: 'panelFeedId',
        minWidth: 162,
        sortable: false,
      },
      {
        accessor: 'meteredUpstream',
        Cell: this.meteredUpstreamCell,
        Header: (
          <>
            Exclude from Site-Level Total Consumption
            <br />
            <MeteredUpstreamInfoStyled>
              (Metered Upstream)
              <Tippy content={this.getMeteredUpstreamHelpContent()} delay={500}>
                <HelpIcon />
              </Tippy>
            </MeteredUpstreamInfoStyled>
            <MeteredUpstreamSelectorStyled>
              <span onClick={() => this.handleSelectAll(true)}>
                Select All{' '}
              </span>
              /<span onClick={() => this.handleSelectAll(false)}> None</span>
            </MeteredUpstreamSelectorStyled>
          </>
        ),
        sortable: false,
        minWidth: 162,
      },
      {
        accessor: this.buildingSystemAccessor,
        Cell: this.buildingSystemCell,
        Header: (
          <>
            Building System
            <br />
            (In Energy Dashboard)
          </>
        ),
        id: 'buildingSystemId',
        minWidth: 162,
        sortable: false,
      },
      {
        accessor: this.equipmentAccessor,
        Cell: this.equipmentCell,
        Header: (
          <>
            Equipment Name
            <br />
            (In Energy Dashboard)
          </>
        ),
        id: 'equipmentId',
        minWidth: 162,
        sortable: false,
      },
      {
        accessor: 'buildingArea',
        Cell: this.buildingAreaCell,
        Header: 'Building Area',
        minWidth: 162,
        sortable: false,
      },
      {
        accessor: 'contractId',
        Cell: this.contractCell,
        filterMethod: booleanFilter,
        Header: 'Billable Contract',
        minWidth: 160,
        sortable: false,
      },
      ...nebulaColumns,
      {
        expander: true,
        Expander: this.plotViewExpander,
        Header: 'Plot View',
        resizable: true,
        style: {
          color: '#337ab7',
          cursor: 'pointer',
        },
        width: 100,
        sortable: false,
      },
    ]
  }

  getSubcomponent = () => {
    const {
      bsById,
      equipmentById,
      meterStartDate,
      panelById,
      phaseGroupsById,
      status: { meter },
    } = this.props
    const { isBigBang, siteTimezone } = meter
    const isRedaptive = this.isRedaptive()
    return ({
      index,
      original: circuit,
      row,
    }: {
      index: number
      original: FTCircuit
      row: Record<string, any>
    }) => (
      <ChannelTagsPlotView
        data={{
          contractId: circuit.contractId,
          contractField: this.getContractField({
            index,
            value: row.contractId,
            hiddenMode: false,
          }),
          breakerNumber: circuit.breakerNumber,
          breakerNumberField: this.getBreakerNumberField({
            index,
            value: row.breakerNumber,
            hiddenMode: false,
          }),
          buildingArea: circuit.buildingArea,
          buildingAreaField: this.getBuildingAreaField({
            index,
            value: row.buildingArea,
            hiddenMode: false,
          }),
          buildingSystem:
            (bsById[circuit.buildingSystemId] &&
              bsById[circuit.buildingSystemId].name) ||
            '',
          buildingSystemField: this.getBuildingSystemField({
            index,
            buildingSystemId: circuit.buildingSystemId,
            hiddenMode: false,
          }),
          circuitDescription: circuit.description,
          circuitDescriptionField: this.getCircuitDescriptionField({
            index,
            value: row.description,
            hiddenMode: false,
          }),
          circuitId: circuit.id,
          ctTypeAmps: this.getCTTypeAmps(circuit),
          ctTypeAmpsField: this.getCtTypeAmpsField({
            circuit,
          }),
          equipment:
            (equipmentById[circuit.equipmentId] &&
              equipmentById[circuit.equipmentId].name) ||
            '',
          isBigBang,
          isRedaptive,
          equipmentField: this.getEquipmentField({
            index,
            equipmentId: circuit.equipmentId,
            hiddenMode: false,
          }),
          flippedCTLabel: circuit.flippedCTLabel,
          meterChannel: (isRedaptive && circuit.meterChannel) || circuit.name,
          meterId: meter.id,
          meterName: meter.name,
          meterStartDate,
          panelDescription:
            (panelById[circuit.panelId] &&
              panelById[circuit.panelId].description) ||
            '',
          panelName:
            (panelById[circuit.panelId] && panelById[circuit.panelId].name) ||
            '',
          panelNameField: this.getPanelNameField({
            index,
            panelId: circuit.panelId,
            value: row.panelId,
            hiddenMode: false,
          }),
          phase: circuit.phase,
          phaseGroup:
            (circuit.phaseGroupId &&
              phaseGroupsById[circuit.phaseGroupId] &&
              phaseGroupsById[circuit.phaseGroupId].name) ||
            '',
          phaseGroupField: this.getPhaseGroupField({
            index,
            phaseGroupId: circuit.phaseGroupId || '',
            hiddenMode: false,
          }),
          siteTimezone,
        }}
        editMode
      />
    )
  }

  render() {
    const { circuitListEntity, errors, isSubmitting, values } = this.props
    const { columns, subComponent } = this.state
    const isFormTouched = this.isFormTouched()
    const entity = { ...circuitListEntity, items: values.circuits }
    return (
      <div>
        <FormMeta>
          <ErrorMessage
            message={
              errors.circuits ||
              (isFormTouched ? 'This page has unsaved edits.' : '')
            }
          />
          <UnsavedChanges when={!isSubmitting && this.isFormTouched()} />
        </FormMeta>
        <TableStyles>
          <RedaptiveReactTable
            columns={columns}
            data={entity.items}
            SubComponent={subComponent}
          />
        </TableStyles>
        <ButtonGroup>
          <Button type='button' onClick={this.onCancel}>
            Cancel
          </Button>
          <Button
            type='submit'
            primary
            onClick={this.onSubmit}
            disabled={!isFormTouched || isSubmitting || errors.circuits}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    )
  }
}
