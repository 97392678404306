import Checkbox from 'rc-checkbox'
import styled from 'styled-components'
import 'rc-checkbox/assets/index.css'

const StyledRadioButton = styled(Checkbox)`
  & .rc-checkbox-inner {
    height: 16px;
    width: 16px;
    border: 1px solid #979797;
    border-radius: 100px;
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }

  & input[type='checkbox'] {
    margin: 0;
    height: 18px;
    width: 18px;
  }

  &.rc-checkbox-checked:hover .rc-checkbox-inner,
  &.rc-checkbox:hover .rc-checkbox-inner {
    border-color: #4a90e2;
  }

  &.rc-checkbox-checked .rc-checkbox-inner {
    border: 1px solid #979797;
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }

  & .rc-checkbox-inner:after {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-name: none !important;
  }

  &.rc-checkbox-checked .rc-checkbox-inner:after {
    background-color: #4a90e2;
    border-radius: 25px;
    transform: none;
    border: none;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.rc-checkbox-checked {
    &.rc-checkbox-disabled
    .rc-checkbox-inner{
      &:after {
        background-color: #979797;
        
      }
    }
    
  }
`
export default StyledRadioButton
