import { combineReducers } from 'redux'
import { takeEvery, put, call } from 'redux-saga/effects'
import { schema, normalize } from 'normalizr'
import axios from 'axios'
import { initialMeta } from './utils'
import { defaultHeaders, consoleApiUrl } from '../api'
import { handleAxiosError, queryStringify } from '../api/utils'
import { handleSagaError } from '../sagas/utils'

export type FTBuildingSystemSummary = {
  id: string
  name: string
}
const ENTITY_KEY = 'buildingSystems'
const systemsSchema = new schema.Entity(ENTITY_KEY)
// Action Types
export const types = {
  FETCH_BUILDING_SYSTEMS: 'FETCH_BUILDING_SYSTEMS',
  FETCH_BUILDING_SYSTEMS_SUCCESS: 'FETCH_BUILDING_SYSTEMS_SUCCESS',
  FETCH_BUILDING_SYSTEMS_ERROR: 'FETCH_BUILDING_SYSTEMS_ERROR',
  CLEAR_BUILDING_SYSTEMS: 'CLEAR_BUILDING_SYSTEMS',
}
// Action Creators
export const actions = {
  fetchBuildingSystems: () => ({
    type: types.FETCH_BUILDING_SYSTEMS,
  }),
  clearBuildingSystems: () => ({
    type: types.CLEAR_BUILDING_SYSTEMS,
  }),
}
// Reducers
export const initialState = {
  allIds: [],
  byId: {},
  meta: initialMeta,
}

function systemsById(action, state) {
  return { ...state, ...action.payload.entities[ENTITY_KEY] }
}

function systemsAllIds(action, state) {
  return [...new Set(state.concat(action.payload.result))]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.CLEAR_BUILDING_SYSTEMS:
    case types.FETCH_BUILDING_SYSTEMS:
      return initialState.byId

    case types.FETCH_BUILDING_SYSTEMS_SUCCESS:
      return systemsById(action, state)

    default:
      return state
  }
}

function allIds(state = initialState.allIds, action) {
  switch (action.type) {
    case types.CLEAR_BUILDING_SYSTEMS:
    case types.FETCH_BUILDING_SYSTEMS:
      return initialState.allIds

    case types.FETCH_BUILDING_SYSTEMS_SUCCESS:
      return systemsAllIds(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_BUILDING_SYSTEMS:
      return { ...state, loading: true }

    case types.FETCH_BUILDING_SYSTEMS_ERROR:
      return { ...state, loading: false }

    case types.FETCH_BUILDING_SYSTEMS_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
}) // Selectors

export const selectors = {
  selectList: (state) =>
    state.entities[ENTITY_KEY].allIds.map(
      (id) => state.entities[ENTITY_KEY].byId[id],
    ),
  selectById: (state) => state.entities[ENTITY_KEY].byId,
  selectItem: (state, id) => state.entities[ENTITY_KEY].byId[id],
  selectListEntity: (state) => ({
    items: selectors.selectList(state),
    meta: state.entities[ENTITY_KEY].meta,
  }),
  selectItemEntity: (state, id) => ({
    item: selectors.selectItem(state, id),
    meta: state.entities[ENTITY_KEY].meta,
  }),
}
// API
export class API {
  static fetchBuildingSystems() {
    const params = queryStringify({
      pageSize: 100,
    })
    const url = `${consoleApiUrl()}/buildingsystems?${params}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}
// Sagas
export function* fetchBuildingSystemsSaga(): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchBuildingSystems)
    const normalized = normalize(response.results, [systemsSchema])
    yield put({
      type: types.FETCH_BUILDING_SYSTEMS_SUCCESS,
      payload: normalized,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_BUILDING_SYSTEMS_ERROR, error)
  }
}
export const sagas = [
  takeEvery(types.FETCH_BUILDING_SYSTEMS, fetchBuildingSystemsSaga),
]
