import * as Yup from 'yup'
import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, FastField, withFormik } from 'formik'
import Alert from './Alert'
import Spinner from './Spinner'
import SimpleBackButton from './SimpleBackButton'
import './CreateLinkForm.css'

type FTProps = {
  errorMsg: string
  loading: boolean
  customer: Record<string, any>
  isSignup: boolean
}
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
}) // https://github.com/redux-saga/redux-saga/issues/161

class CreateLinkForm extends PureComponent<FTProps> {
  render() {
    const { errorMsg, loading, customer, isSignup } = this.props
    let title = isSignup ? 'Create Signup Link' : 'Create Reset Password Link'

    if (customer) {
      title = `${title} for ${customer.name}`
    }

    const linkType = isSignup ? 'signup link' : 'reset password link'
    return (
      <div className='Form CreateLinkForm'>
        <div className='form'>
          <SimpleBackButton />
          <div className='logo'>
            <h2 className='title'>{title}</h2>
            {'Enter the '}
            <b>email address</b>
            {' of the user you wish to generate a '}
            {linkType}
            {' for.'}
          </div>

          <Form>
            <FastField
              aria-label='email'
              name='email'
              type='email'
              placeholder='Email'
            />
            <div className='info'>
              {!loading && errorMsg && (
                <Alert level='error' text={[errorMsg]} />
              )}
            </div>

            <button aria-label='sign in' type='submit' disabled={loading}>
              Submit
              {loading && <Spinner size='tiny' />}
            </button>
          </Form>
        </div>
      </div>
    )
  }
}

export default withRouter(
  withFormik({
    validationSchema,

    handleSubmit(values, { props }) {
      const { onSubmit } = props
      onSubmit(values)
    },

    mapPropsToValues: () => ({
      email: '',
    }),
  })(CreateLinkForm),
)
