import { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { QueryParams } from '../../ducks/configHistory/models'
import ChevronDown from '../Icons/svg/ChevronDown'
import ChevronUp from '../Icons/svg/ChevronUp'
import {
  selectors,
  actions as configHistoryActions,
} from '../../ducks/configHistory'
import RedaptiveReactTable from '../RedaptiveReactTable'

const DateStyled = styled.div`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #337ab7;
  cursor: pointer;
`
const ChevronContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;
`
const TdStyled = styled.div`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
`
const ExpandedChildStyled = styled.div`
  padding: 14px;
  h4 {
    font-family: Avenir Next;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  ul {
    max-height: 140px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    li {
      font-family: Avenir Next;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 14px;
      margin-right: 10px;
      min-width: 30%;
      max-width: 45%;
    }
  }
`
const CheckboxContainerStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  input[type='checkbox'] {
    margin-right: 4px;
  }
  label {
    font-family: Avenir Next;
    font-size: 14px;
    font-weight: 500;
    color: #4e4f50;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const NoContentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  p {
    font-family: Avenir Next;
    font-size: 16px;
    font-weight: 500;
    color: #4e4f50;
  }
`
type FTConfigListProps = {
  siteTimezone: string
  results: Array<Record<string, any>>
  total: number
  setActiveVersion: (...args: Array<any>) => any
  fetchMeterConfigHistory: (...args: Array<any>) => any
  queryParams: QueryParams
}

const MeterConfigList = (props: FTConfigListProps) => {
  const {
    siteTimezone,
    results,
    total,
    queryParams,
    setActiveVersion,
    fetchMeterConfigHistory,
    actions: { updateQueryParams },
  } = props

  const setPageSize = useCallback(
    (size) => {
      updateQueryParams({ pageNumber: 1, pageSize: size })
      fetchMeterConfigHistory(size, 1, queryParams.onlyMeterDataImpacted)
    },
    [updateQueryParams, fetchMeterConfigHistory],
  )

  const gotoPage = useCallback(
    (page) => {
      updateQueryParams({ pageNumber: page })
      fetchMeterConfigHistory(
        queryParams.pageSize,
        page,
        queryParams.onlyMeterDataImpacted,
      )
    },
    [updateQueryParams, fetchMeterConfigHistory],
  )

  const updateOnlyMeterDataImpacted = useCallback(
    (checked) => {
      updateQueryParams({
        pageNumber: 1,
        pageSize: 20,
        onlyMeterDataImpacted: checked,
      })
      fetchMeterConfigHistory(20, 1, checked)
    },
    [updateQueryParams, fetchMeterConfigHistory],
  )

  const camelCaseToTitleCase = (str) => {
    if (str === 'phaseCorrectionAngles') {
      return 'Phase Angle Correction Factor'
    }

    const result = str.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
  }

  const convertToSiteTimezone = (date) => {
    const convertedDate = moment(date).tz(siteTimezone)
    const formattedDate = convertedDate.format('MMMM DD, YYYY hh:mm:ss A z')
    return formattedDate
  }

  const columns = [
    {
      accessor: 'created',
      Cell: ({ original }: { original: Record<string, any> }) => (
        <DateStyled onClick={() => setActiveVersion(original.version)}>
          {(original &&
            original.created &&
            convertToSiteTimezone(original.created)) ||
            '-'}
        </DateStyled>
      ),
      Header: 'Time Stamp',
      id: 'created',
      disableFilters: true,
    },
    {
      accessor: 'userEmail',
      Cell: ({ original }: { original: Record<string, any> }) => (
        <TdStyled>{(original && original.userEmail) || '-'}</TdStyled>
      ),
      Header: 'User ID',
      id: 'userEmail',
      disableFilters: true,
    },
    {
      accessor: 'isMeterDataImpacted',
      Cell: ({ original }: { original: Record<string, any> }) => (
        <TdStyled>
          {original && original.isMeterDataImpacted ? 'Yes' : 'No'}
        </TdStyled>
      ),
      Header: 'Meter Data Impact',
      id: 'isMeterDataImpacted',
      disableFilters: true,
      maxWidth: 130,
    },
    {
      expander: true,
      Expander: ({
        isExpanded,
        original,
      }: {
        isExpanded: boolean
        original: Record<string, any>
      }) => {
        if (original) {
          return (
            <ChevronContainerStyled>
              {isExpanded ?
                <ChevronUp color='#337AB7' />
              : <ChevronDown color='#337AB7' />}
            </ChevronContainerStyled>
          )
        }

        return ''
      },
    },
  ]

  const renderSubComponent = ({
    original,
  }: {
    original: Record<string, any>
  }) => {
    const keysNotToDisplay = [
      'id',
      'applied',
      'created',
      'userEmail',
      'userId',
      'isMeterDataImpacted',
      'status',
      'version',
    ]
    // const changes = [];
    const allKeys = Object.keys(original)
    const filteredKeys = allKeys.filter(
      (key) => !keysNotToDisplay.includes(key),
    )
    let changes = filteredKeys.map((key) => {
      const isArray = Array.isArray(original[key])
      const isObject = typeof original[key] === 'object'

      if (key === 'configDelta') {
        const subChanges = Object.keys(original[key])
        return {
          name: key,
          value: subChanges.map((subChange) => {
            const subChangeValue = original[key][subChange]
            const subChangeIsArray = Array.isArray(subChangeValue)
            const subChangeIsObject = typeof subChangeValue === 'object'

            if (subChangeIsArray) {
              const subChangesArr = JSON.parse(JSON.stringify(subChangeValue))

              if (subChangesArr.length > 0) {
                return {
                  name: subChange,
                  value: subChangesArr,
                }
              }

              return {
                name: subChange,
                value: null,
              }
            }

            if (subChangeIsObject) {
              return {
                name: subChange,
                value: Object.keys(subChangeValue),
              }
            }

            return {
              name: subChange,
              value: subChangeValue,
            }
          }),
        }
      }

      if (isArray) {
        const subChangesArr = JSON.parse(JSON.stringify(original[key]))

        if (subChangesArr.length > 0) {
          return {
            name: key,
            value: subChangesArr,
          }
        }

        return {
          name: key,
          value: null,
        }
      }

      if (isObject) {
        return {
          name: key,
          value: Object.keys(original[key]),
        }
      }

      return {
        name: key,
        value: null,
      }
    })
    const configDeltaChanges = changes.find(
      (change) => change.name === 'configDelta',
    )

    if (configDeltaChanges) {
      changes.splice(
        changes.indexOf(configDeltaChanges),
        1,
        ...configDeltaChanges.value,
      )
    }

    changes = changes
      .filter((change) => change.name !== 'referenceFrequency')
      .filter((change) => change.name !== 'dataResolution')
    const parsedChanges = changes.map((change) => ({
      name: camelCaseToTitleCase(change.name),
      value:
        change.value && Array.isArray(change.value) ?
          change.value
            .map((val) => camelCaseToTitleCase(val))
            .sort()
            .join(', ')
        : change.value,
    }))
    const powerSourceIndex = parsedChanges.findIndex(
      (change) => change.name === 'Power Source',
    )
    let panelChange = null

    if (powerSourceIndex > -1) {
      const powerSource = parsedChanges[powerSourceIndex]
      const powerSourceValue = powerSource.value
      const powerSourceValueArr = powerSourceValue.split(', ')
      const panelChangeIndex = powerSourceValueArr.findIndex(
        (val) => val === 'Panel Id',
      )

      if (panelChangeIndex > -1) {
        panelChange = {
          name: 'Panel',
        }
      }

      parsedChanges[powerSourceIndex].value = powerSourceValueArr
        .filter((val) => val !== 'Panel Id')
        .join(', ')

      if (panelChange) {
        parsedChanges.splice(powerSourceIndex + 1, 0, panelChange)
      }

      parsedChanges.sort((a, b) => a.name.localeCompare(b.name))
    }

    return (
      <ExpandedChildStyled>
        <h4>Changes Made In:</h4>
        <ul>
          {parsedChanges.map((change) => (
            <li key={change.name}>{change.name}</li>
          ))}
        </ul>
      </ExpandedChildStyled>
    )
  }
  return (
    <div>
      <CheckboxContainerStyled>
        <label htmlFor='onlyDataImpact'>
          <input
            id='onlyDataImpact'
            type='checkbox'
            checked={queryParams.onlyMeterDataImpacted}
            onChange={(e) =>
              updateOnlyMeterDataImpacted(e.currentTarget.checked)
            }
          />
          See only meter data impact changes
        </label>
      </CheckboxContainerStyled>
      {results && results.length > 0 ?
        <RedaptiveReactTable
          className='-config-history'
          data={results}
          columns={columns}
          filterable={false}
          sortable={false}
          resizable={false}
          setPageSize={setPageSize}
          pageSize={queryParams.pageSize}
          pageIndex={queryParams.pageNumber - 1}
          totalResults={total}
          showCustomPagination
          SubComponent={renderSubComponent}
          gotoPage={gotoPage}
        />
      : <NoContentStyled>
          <p>No configuration history available.</p>
        </NoContentStyled>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  total: selectors.getData(state) && selectors.getData(state).totalCount,
  queryParams: selectors.getQueryParams(state),
  loading: selectors.getLoading(state),
  error: selectors.getError(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(configHistoryActions, dispatch) },
})

MeterConfigList.propTypes = {
  siteTimezone: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape()),
  setOnlyMeterDataImpacted: PropTypes.func.isRequired,
  onlyMeterDataImpacted: PropTypes.bool.isRequired,
  setActiveVersion: PropTypes.func.isRequired,
}
export default connect(mapStateToProps, mapDispatchToProps)(MeterConfigList)
